import React, { Component } from 'react'
import img from '../../../../src/assets/images/img.png'

export class NotificationCard extends Component {
    render() {
        const { image, title, notification, date } = this.props
        
        let icon = image ? image:img;
        if (date){
            return (
                <div className="card notification-card animated fadeInUp faster">
                    <div className="notification-body">
                        <img src={icon} className="bg-dark icon-circle icon-lg align-self-start" />
                        <div className="detail">
                            <h4 className="bold notification-title">{title}</h4>
                            {this.props.children}
                            <span className="notification-date">{date}</span>
                        </div>
                    </div>
                </div>
            )
        }else{
            return (
                <div className="card notification-card">
                    <div className="notification-body">
                        <img src={icon} className="bg-dark icon-circle icon-lg align-self-start" />
                        <div className="detail">
                            <h4 className="bold notification-title">Nurse Jackey</h4>
                            <p className="notification-desc">Registered a new template, <a className="cta cta-tertiary">[Positioning name/Positioning equipment name/Room setup name]</a>. Check it out under <a className="cta cta-tertiary">[Positioning/Positioning Equipment/ Room setup]</a>. </p>
                            <span className="notification-date">11:34pm March 2018</span>
                        </div>
                    </div>
                </div>
            )
        }

    }
}

export default NotificationCard
