import React, { Component } from 'react'
import { SurgeonCard, MediaCard } from '../../components/commons/cards'
import $ from 'jquery'
import axios from 'axios'
import Inbodyloader from '../../components/loader/Inbodyloader'
import _ from 'lodash'
import Pagination from 'react-js-pagination'
import ViewItemModal from '../../components/modals/ViewItemModal'
import ViewItemCard from '../../components/commons/cards/ViewItemCard'; 

import { showLoader, hideLoader, setLoaderProg } from '../../components/loader/loading_screen';
import FullMediaView from '../../components/modals/FullMediaView'
export class GlobalSearch extends Component {
  constructor(props) {
    super(props)

    this.state = {
      search_value: '',
      results: {
        surgeons: [],
        items: [],
        gallery: [],
        procedures:[]
      },
      active: 'all',
      loading: 0,
      page: 1,
      selectedItem: {},
      unique: '',
    }
  }

  componentDidMount() {
    this.setState(
      {
        search_value: this.props.location.query
          ? this.props.location.query.q
          : '',
      },
      () => {
        this.searchData()
      }
    )
    setTimeout(() => {
      $('input').blur()
    }, 500)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props != nextProps) {
      this.setState({
        search_value: nextProps.location.query
          ? nextProps.location.query.q
          : '',
      })

      $('input').blur()
    }
  }

  searchData = () => {
    this.setState({
      loading: 5,
    })
    let pages = ''
    if (this.state.active != 'all') {
      pages = '&paginate=12'
    }
    let config = { 

      onDownloadProgress: progressEvent => {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        ) 
        showLoader(percentCompleted >= 95 ? 95 : percentCompleted);
        setLoaderProg(percentCompleted >= 95 ? 95 : percentCompleted) 
      },
    }
    axios
      .get(`api/client/global?keyword=${this.state.search_value}&page=${
          this.state.page
        }&type=${this.state.active}${pages}`, config)
      .then(result => {
        this.setState({
          results: result.data,
          loading: 0,
        })
        setLoaderProg(99)
        setTimeout(() => {
          hideLoader()
        }, 800);
      })
      .catch(error => {
        console.log(error.response)
        setLoaderProg(99)
        setTimeout(() => {
          hideLoader()
        }, 800);
      })
  }

  searchItem() {
    this.setState(
      {
        search_value: this.searchKeyword.value,
        page: 1,
        loading: true,
        data: [],
      },
      () => {
        if (this.searchKeyword.value)
        this.searchData()
      }
    )
  }

  toSurgeonPage(data) {
    this.props.router.push(`/quicknotes/${data.id}`)
  }

  surgeonsResult() {
    const { results } = this.state
    const { surgeons } = results
    const data = surgeons.map((surgeon, index) => {
      return (
        <div className="col-lg-4 col-md-6 mb28" key={`surgeon-key-${index}`}>
          <SurgeonCard
            key={`surgeon-data-${index}`}
            surgeon={surgeon}
            onClick={() => {
              this.toSurgeonPage(surgeon)
            }}
          />
        </div>
      )
    })
    return (
      <div className="row mb24">
        <div className="col-12">
          <h6 className="h3bold mb16">Surgeons</h6>
          <div className="row">
            {/* MANIPULATE SURGEON RESULTS HERE */}

            {data}

            {/* END OF SURGEON RESULTS HERE */}
          </div>
        </div>
      </div>
    )
  }
  proceduresResult() {
    const { results } = this.state
    const { procedures } = results
    const data = procedures.map((procedure, index) => {
      return (
        <h3 className="mb30 link">
          {`${procedure.name}`}  {`${procedure.category.specialty ?  `| ${procedure.category.specialty.name}`:''}`}
          {' '}
          {`${
            procedure.surgeon
              ? `| [${procedure.surgeon.firstname} ${
                  procedure.surgeon.firstname
                }]`
              : ''
          }`}
        </h3>
      )
    })
    return (
      <div className="row mb24">
        <div className="col-12">
          <h6 className="h3bold mb16">Procedures</h6>
          <div className="row">
            {/* MANIPULATE RESULTS HERE */}
            <div className="col-lg-12 mb28">{data}</div>
            {/* END RESULTS HERE */}
          </div>
        </div>
      </div>
    )
  }

  itemsResult() {
    const { results } = this.state
    const { items } = results
    const data = items.map((item, index) => {
      let sam = `<span style="color:red;">Sample</span>`;
      return (
        <div
          className="col-lg-7"
          key={`item-${index}`}
          onClick={() => {
            this.setState(
              {
                selectedItem: item,
                unique:
                  Math.random()
                    .toString(36)
                    .substring(2, 15) +
                  Math.random()
                    .toString(36)
                    .substring(2, 15),
              },
              () => {
                $(`#preview-modal-${this.state.unique}`).modal('show')
              }
            ) 
          }}
        >
          <h3 className="mb8 link">
            {`${item.name}  ${item.company ? ' | ' + item.company : ''} ${item.catalog_number ? ' | ' +item.catalog_number:''} ${
              item.size ? ' | ' +item.size:''
            }`}
          </h3>
          <p className="mb28" >
            <div
              dangerouslySetInnerHTML={{
                __html: item.used_for.length ? item.used_for : item.description
              }}
            />  
          </p>
        </div>
      )
    })

    return (
      <div className="row mb24">
        <div className="col-12">
          <h6 className="h3bold mb16">Medical inventory</h6>
          <div className="row">
            {/* MANIPULATE RESULTS HERE */}

            {data}

            {/* END OF RESULTS HERE */}
          </div>
        </div>
      </div>
    )
  }

  mediaResult() {
    const { results } = this.state
    const { gallery } = results
    const data = gallery.map((media, index) => {
      console.log('mediaResult', media)
      let type = media.type
      let source = media.source

      if (type == 'video') {
        if (media.disk == 'vimeo') {
          type = 'vimeo'
          const video_id = source.split('/').pop()
          // source = `https://vimeo.com/${video_id}`
        }
      }

      let thumbnail = media.thumbnail
      if (thumbnail.split('.').pop() == 'mp4') {
        thumbnail = 'http://lorempixel.com/400/200/sports/5'
      }
      return (

        <div
          className="col-lg-3 col-md-6 mb28 c-pointer "  
          onClick={() => {
            this.setState({
              selected_item: {
                title: media.title,
                type: type,
                source: source
              }
            }, () => {
              $("#media_type").val(type).change()
              $("#media_title").val(media.title).change()
              $("#media_source").val(source).change()
              $("#fullMediaViewModal").modal('show')
            })
          }}
          key={`media-${index}`}
        > 
          <MediaCard media={media} />
        </div>
      )
    })
    return (
      <div className="row mb24">
        <div className="col-12">
          <h6 className="h3bold mb16">Media</h6>
        </div>
        <div className="col-12">
          <div className="row">
            {/* MANIPULATE RESULTS HERE */}

            {data}

            {/* END OF RESULTS HERE */}
          </div>
        </div>
      </div>
    )
  }

  onTabChange = type => {
    this.setState(
      {
        active: type,
      },
      () => {
        this.searchData()
      }
    )
  }

  onSearchInputChange = text => {
    this.setState({
      search_value: text,
    })
  }

  handlePageChange = pageNumber => {
    this.setState(
      {
        page: pageNumber,
      },
      () => {
        this.searchData()
        window.scrollTo({
          top: 0,
          left: 0,
        })
      }
    )
    console.log(`active page is ${pageNumber}`)
  }
  getTotal(){
    let total = 0 
    if(this.state.active == 'all'){
      total += this.state.results.gallery.length
      total += this.state.results.items.length
      total += this.state.results.surgeons.length
    }else{
      total = this.state.results.total_items
    }
    return total;
  }
  render() {
    const { results, active } = this.state
    const search = _.debounce(keyword => {
      this.searchItem(keyword)
    }, 1000)
    return (
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-6 mt10 mb24 d-flex">
            <div className="line-form-group-v2 search-bar">
              <input
                type="text"
                className="line-form-v2"
                ref={el => (this.searchKeyword = el)}
                value={this.state.search_value}
                onChange={event => {
                  this.onSearchInputChange(event.target.value)
                  search()
                }}
              />
              <label>Search</label>
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="mdi mdi-magnify" />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <p className="mb24 search-results">
              <b>{this.getTotal()}</b> Results
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12"> 

            <div>
              <ul
                className="nav nav-tabs custom-tabs"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className={`nav-link ${active == 'all' ? 'active' : ''}`}
                    id="all-tab"
                    data-toggle="tab"
                    href="#all"
                    role="tab"
                    aria-controls="all"
                    aria-selected="true"
                    onClick={() => {
                      this.onTabChange('all')
                    }}
                  >
                    All
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      active == 'surgeons' ? 'active' : ''
                    }`}
                    id="surgeon-tab"
                    data-toggle="tab"
                    href="#surgeon"
                    role="tab"
                    aria-controls="surgeon"
                    aria-selected="false"
                    onClick={() => {
                      this.onTabChange('surgeons')
                    }}
                  >
                    Surgeon
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${active == 'items' ? 'active' : ''}`}
                    id="medical-inventory-tab"
                    data-toggle="tab"
                    href="#medical-inventory"
                    role="tab"
                    aria-controls="medical-inventory"
                    aria-selected="false"
                    onClick={() => {
                      this.onTabChange('items')
                    }}
                  >
                    Medical Inventory
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      active == 'gallery' ? 'active' : ''
                    }`}
                    id="media-tab"
                    data-toggle="tab"
                    href="#media"
                    role="tab"
                    aria-controls="media"
                    aria-selected="false"
                    onClick={() => {
                      this.onTabChange('gallery')
                    }}
                  >
                    Media
                  </a>
                </li>
              </ul>
              <div className="tab-content pt24">
                <div
                  className="tab-pane active"
                  id="all"
                  role="tabpanel"
                  aria-labelledby="all-tab"
                >
                  {results.surgeons.length > 0 ? this.surgeonsResult() : []}
                  {results.items.length > 0 ? this.itemsResult() : []}
                  {results.gallery.length > 0 ? this.mediaResult() : []}
                </div>
              </div> 
              <ViewItemCard id={`preview-modal-${this.state.unique}`} onRef={ref => (this.viewItemRef = ref)} item={this.state.selectedItem} ></ViewItemCard>
              {this.state.active == 'all' ? (
                ''
              ) : (
                <Pagination
                  activePage={this.state.page}
                  itemsCountPerPage={12}
                  totalItemsCount={results.total_items}
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange.bind(this)}

                    hideDisabled={true}
                    hideFirstLastPages={true}
                    linkClassPrev="prev"
                    linkClassNext="next"
                    prevPageText={<i className='mdi mdi-chevron-left'></i>}
                    nextPageText={<i className='mdi mdi-chevron-right'></i>}
                />
              )}
            </div>
          </div>
        </div>
        <FullMediaView />
      </div>
    )
  }
}

export default GlobalSearch
