import axios from 'axios'
import $ from 'jquery'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getItemsPerCategory } from '../../actions/ItemAction'
import img from '../../assets/images/no_image.png'
import { Button, TextInput } from '../../components/commons/forms'
import { dataURItoBlob, getError, isBase64 } from '../../utilities'
import { Event } from '../../utilities/analytics'
import { procedureAction } from '../../utilities/notifier'
import DataCard from '../commons/cards/DataCard'
import ImagePicker from '../commons/elements/inputs/ImagePicker'
import EditMenu from '../commons/misc/EditMenu'
import { ConfirmationModal } from '../commons/modals'
import Notifier from '../commons/notifier/Notifier'
import ItemList from '../ItemList'
import { hideLoader, setLoaderProg, showLoader } from '../loader/loading_screen'
import PositioningEquipmentSkeleton from '../skeletons/PositioningEquipmentSkeleton'
import { StandardElementor } from '../StandardElementor'

export class RoomSetupLayout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      edit: false,
      preview: false,
      errors: [],
      selected_items: [],
      setup_name: '',
      image: '',
      standardData: [],
      selected_media: [],
      progress: 0,
      showUpdateSuccess: false,
      progressList: []
    }
  }

  componentDidMount = () => {
    this.props.getItemsPerCategory('all', this.props.stage_details.items, 3, '')
    this.setSelectedItems(this.props)

    if (this.props.enableDraft) {
      this.setState({
        edit: true,
        errors: [],
      })
      this.props.getProcedureDetail(1)
    }

    this.props.setNewState({
      settings: false,
    })
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.stage_details != nextProps.stage_details) {
      this.setSelectedItems(nextProps)
    }
  }

  setSelectedItems = props => {
    console.log('peqr', props)
    this.setState({
      setup_name: props.stage_details.name ? props.stage_details.name : '',
      image: props.stage_details.path ? props.stage_details.path : '',
      selected_items: props.stage_details.items
        ? props.stage_details.items
        : [],
      standardData: props.stage_details.elements
        ? props.stage_details.elements
        : [],
    })
  }

  updateRoomSetup = (draft = 0) => {
    if(draft == 0){
      Event('Procedure', 'Update', 'Room Set-up')
    }
    const { setup_name, image, selected_items, standardData } = this.state
    const { stage_master } = this.props
    procedureAction(stage_master.id, draft)

    let data = new FormData()
    data.append('name', setup_name)
    if (isBase64(image) > 0) {
      data.append('floor_plan', dataURItoBlob(image))
    }
    data.append('draft', draft)
    data.append('_method', 'PATCH')
    selected_items.map(item => {
      data.append('item_ids[]', item.id)
      data.append('qty[]', item.qty)
    })

    standardData.map((standardData, index) => {
      data.append(
        `elements[${index}][title]`,
        standardData.title ? standardData.title : ''
      )
      data.append(`elements[${index}][type]`, standardData.type)
      switch (standardData.type) {
        case 'text':
          data.append(
            `elements[${index}][values]`,
            standardData.contents.caption ? standardData.contents.caption:''
          )
          break
        case 'standard':
          data.append(
            `elements[${index}][media-title]`,
            standardData.contents.title ? standardData.contents.title:''
          )
          data.append(
            `elements[${index}][media-text]`,
            standardData.contents.caption ? standardData.contents.caption:''
          )
          data.append(`elements[${index}][media-id]`, 0)
          data.append(`elements[${index}][media-updated]`, 1)
          data.append(
            `elements[${index}][media-value]`,
            standardData.contents.gallery.source ? standardData.contents.gallery.source:''
          )
          data.append(
            `elements[${index}][media-original]`,
            standardData.contents.gallery.originSource
          )
          data.append(
            `elements[${index}][media-type]`,
            standardData.contents.gallery.type
          )
          break
        case 'media':
          standardData.contents.map((content, i) => {
            data.append(
              `elements[${index}][media-text][${i}]`,
              content.gallery.caption ? content.gallery.caption:''
            )
            data.append(
              `elements[${index}][media-title][${i}]`,
              content.gallery.title ? content.gallery.title : ''
            )
            data.append(
              `elements[${index}][media-type][${i}]`,
              content.gallery.type
            )
            data.append(`elements[${index}][media-id][${i}]`, 0)
            data.append(`elements[${index}][media-updated][${i}]`, 1)
            data.append(
              `elements[${index}][media-value][${i}]`,
              content.gallery.source ? content.gallery.source:''
            )
            data.append(
              `elements[${index}][media-original][${i}]`,
              content.gallery.originSource
            )
          })

          break
        default:
          break
      }
    })

    let url = `/api/client/room/${stage_master.id}`

    let config = {
      headers: {
        'content-type': 'multipart/form-data',
      },

      onUploadProgress: progressEvent => {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        showLoader(percentCompleted >= 95 ? 95 : percentCompleted)
        setLoaderProg(percentCompleted >= 95 ? 95 : percentCompleted)
      },
    }
    axios
      .post(url, data, config)
      .then(res => {
        setLoaderProg(99)
        setTimeout(() => {
          hideLoader()
        }, 800)
        if (!draft) {
          this.props.getProcedureDetail()
          this.setState({
            edit: false,
            preview: false,
            progress: 0,
            showUpdateSuccess: true,
            errors: [],
          })
        } else {
          this.props.getProcedureDetail(1)
          this.setState({
            errors: [],
            progress: 0,
          })
          this.refNotifier4.showThis()
        }
      })
      .catch(err => {
        setLoaderProg(99)
        setTimeout(() => {
          hideLoader()
        }, 800)
        console.log(err)

        console.log(err.response)
        if (err && err.response && err.response.status != 422) {
          this.setState({
            progress: 0,
          })
          return
        }
        if (err && err.response) {
          this.setState(
            {
              progress: 0,
              errors: err.response.data.errors,
            },
            () => {
              let targetOffset = $('.is-invalid').offset().top
              $('html, body').animate({ scrollTop: targetOffset }, 100)
            }
          )
        }
      })
  }

  setNewState = (state, action) => {
    this.setState(state, action)
  }

  removeItemFromSelected = id => { 
    var filtered = this.state.selected_items.filter(item => item.id != id);
    this.setState({
      selected_items: filtered.splice(0)
    })
  }

  editActions = () => {

    const progressList = $('.pie-wrapper').length
    console.log('progressList',progressList,this.props.video_progress)

    const { preview } = this.state
    if (!preview) {
      return (
        <>
          <ConfirmationModal
            title="Save to draft"
            message="Do you want to save draft?"
            confirmText="Yes"
            cancelText="Cancel"
            confirm={()=>{  
              this.updateRoomSetup(1)
            }}
            idModal="save-to-draftmodal"
            cancel={() => {
              $('.modal').modal('hide')
            }}
          />
          <div className="col-md-12">
            <EditMenu>
              <li>
                <Button
                  primary
                  onClick={() => {
                    this.updateRoomSetup()

                    this.props.setNewState({
                      procedure_stage_edit: false
                    })
                  }}
                  disabled={progressList}
                >
                  Update
                </Button>
              </li>
              <li>
                <Button
                  tertiary
                  onClick={e => {
                    $("#save-to-draftmodal").modal('show')
                  }}
                  disabled={progressList}
                >
                  Save Draft
                </Button>
              </li>
              <li>
                <Button
                  tertiary
                  onClick={() => {
                    this.setState({
                      preview: true,
                      edit: false,
                    })
                  }}
                  disabled={progressList}
                >
                  Preview
                </Button>
              </li>
              <li className="has-line">
                <Button
                  tertiary
                  onClick={() => {
                    this.setState({ edit: false, draft_success: false }, () => {
                      this.props.getProcedureDetail()
                    })

                    this.props.setNewState({
                      procedure_stage_edit: false
                    })
                  }}
                  disabled={progressList}

                >
                  Cancel
                </Button>
              </li>
            </EditMenu>
          </div>
          <div className="col-md-12 mt10">
            <Notifier
              message="Draft has been saved!"
              onRef={ref => (this.refNotifier4 = ref)}
              type="success"
              styleClass="mb30 mt0"
              hideThisNotif={() => {
                this.refNotifier4.hideThis()
              }}
            ></Notifier>
          </div>
        </>
      )
    } else {
      return (
        <div className="col-md-12 mt20">
          <Notifier
            show={true}
            onRef={ref => (this.refNotifier3 = ref)}
            type="warning"
            styleClass="mb30 mt0"
            hideThisNotif={() => {
              this.refNotifier3.hideThis()
              this.setState({ preview: false, editor: false })
            }}
          >
            <div>
              <b>Preview mode</b> - If you like what you see, you can{' '}
              <u
                className="font-weight-bold c-pointer"
                onClick={() => {
                  this.updateRoomSetup()
                  this.props.setNewState({
                    procedure_stage_edit: false
                  })
                }}
              >
                Update positioning
              </u>{' '}
              or make edits by{' '}
              <u
                className="font-weight-bold c-pointer"
                onClick={() => {
                  this.setState({
                    preview: false,
                    edit: true,
                  })
                }}
              >
                Closing preview
              </u>
            </div>
          </Notifier>
        </div>
      )
    }
  }

  edit = () => {
    const { preview } = this.state
    if (!preview) {
      return (
        <div className="row mt32 mb20">
          <div className="col-md-12">
            <h2 className="page-description bold">
              Editing - Equipment / Room setup
            </h2>
          </div>
          {this.editActions()}
          <div className="col-lg-12">
            { this.props.loading ? <PositioningEquipmentSkeleton /> :<DataCard
              inputHeader={() => {
                return (
                  <div className="row">
                    <div className="col-lg-6">
                      <TextInput
                        label={'Title'}
                        value={this.state.setup_name}
                        placeholder={'Title'}
                        errorMessage={getError(this.state.errors, 'name')}
                        containerClass={`mb0 w-100`}
                        onValueChange={event => {
                          this.setState({ setup_name: event.target.value })
                        }}
                      />
                    </div>
                  </div>
                )
              }}
              bodyClass="p0"
            >
              <div
                className="w-100"
                style={{ position: 'relative', paddingTop: '66.67%' }}
              >
                <ImagePicker
                  aspectRatio={3 / 2}
                  imageButton={true}
                  imagePreview={this.state.image}
                  getImage={image => {
                    this.setState({ image: image })
                  }}
                />
              </div>
            </DataCard>}
          </div>
        </div>
      )
    } else {
      return (
        <div className="row mt32">
          <div className="col-md-12">
            <h2 className="page-description bold mb0">
              Equipment / Room setup
            </h2>
          </div>
          {this.editActions()}
          <div className="col-lg-12 mb20">
            <DataCard title={this.state.setup_name} bodyClass="p0">
              <div className="ar32 w-100">
                {this.state.image ? (
                  <img
                    src={this.state.image}
                    alt="IMAGE NI"
                    className="bg-dark w-100 "
                  />
                ) : (
                  <img src={img} alt="IMAGE NI" className="bg-dark w-100 " />
                )}
              </div>
            </DataCard>
          </div>
        </div>
      )
    }
  }

  view = () => {
    const { stage_details } = this.props
    return (
      <div className="row mt32 mb20">
        <div className="col-md-12 mx-auto d-flex justify-content-between align-items-center mb20 flex-wrap">
          <h2 className="page-description mb-2 mb-sm-0 bold">Equipment / Room setup</h2>
          <Button className="ml-0 mr-2 mr-sm-0"
            tertiary
            onClick={() => {
              this.props.getProcedureDetail(1)
              this.setState({ edit: true, errors: [], preview: false })

              this.props.setNewState({
                procedure_stage_edit: true
              })
            }}
          >
            Edit {this.props.title}
          </Button>
        </div>
        <div className="col-md-12">
          <Notifier
            show={this.state.showUpdateSuccess}
            message="Room set up & Equipment successfully updated"
            onRef={ref => (this.refNotifier = ref)}
            type="success"
            styleClass="mb30 mt0"
            hideThisNotif={() => {
              this.refNotifier.hideThis()
              this.setState({
                showUpdateSuccess: false,
              })
            }}
          ></Notifier>
        </div>
        <div className="col-lg-12">
          { this.props.loading ? <PositioningEquipmentSkeleton /> : 
          <DataCard
            title={
              stage_details.name ? stage_details.name : '[Add a floor plan]'
            }
            bodyClass="p0"
          >
            <div className="ar32 w-100">
              {stage_details.path ? (
                <img
                  src={stage_details.path ? stage_details.path : img}
                  alt=""
                  className="bg-dark c-pointer"
                  onClick={() => {
                    this.setState(
                      {
                        selected_media: {
                          title: stage_details.name ? stage_details.name : '-',
                          type: 'image',
                          source: stage_details.path ? stage_details.path : img,
                        },
                      },
                      () => {
                        //$("#bigPictureModal1").modal('show')
                        $('#media_type')
                          .val('image')
                          .change()
                        $('#media_title')
                          .val(stage_details.name ? stage_details.name : '-')
                          .change()
                        $('#media_source')
                          .val(stage_details.path ? stage_details.path : img)
                          .change()
                        $('#fullMediaViewModal').modal('show')
                      }
                    )
                  }}
                />
              ) : (
                <label
                  className={
                    'd-flex justify-content-center align-items-center bg-light of-hidden m-0 h-100 font-weight-normal'
                  }
                  style={{
                    border: '2px dashed rgb(132, 134, 136)',
                    width: '100%',
                    position: 'absolute',
                    top: 0,
                  }}
                >
                  <i>
                    'Click edit notes to upload a floor plan for this room
                    setup'
                  </i>
                </label>
              )}
            </div>
          </DataCard>
          }
        </div>
      </div>
    )
  }

  render() {
    const { edit, preview, selected_items, standardData } = this.state

    console.log('thisstate', this.state,this.props.video_progress)
    return (
      <div className="w-100 float-left mx-auto">
        {/* <BigPictureModal idModal={'bigPictureModal1'} data={this.state.selected_media} /> */}
        {edit || preview ? this.edit() : this.view()}
        { this.props.loading ? '' : <ItemList
            removeItemFromSelected={this.removeItemFromSelected}
            edit={edit}
            preview={preview}
            setNewState={this.setNewState}
            selected_items={selected_items ? selected_items : []}
          /> 
        }
        { this.props.loading ? '' : <StandardElementor
            edit={edit}
            selected_items={this.state.selected_items}
            setNewState={this.setNewState}
            standardData={standardData ? standardData : []}
            errors={this.state.errors}
            progressList={ () => {
              this.setState({
                progressList:[...this.state.progressList,true]
              })
            }}
          /> 
        }
        {/* <WholeBodyloader message="Saving! please wait..." progress={this.state.progress} type="spinner" /> */}
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
  
    video_progress: state.video.progress
  }
}
export default connect(
  mapStateToProps,
  { getItemsPerCategory }
)(RoomSetupLayout)
