import $ from 'jquery';
import React, { Component } from 'react';
import DataCard from './commons/cards/DataCard';
import ViewItemCard from './commons/cards/ViewItemCard';
import { Button } from './commons/forms';
import { Table, TData, THead, TRow } from './commons/table';
import AssignItemsDefault from './modals/AssignItemsDefault';

export class ItemList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      index: -1,
      item: {},
      change: false,
      selectedItem: []
    }
  }

  setSelectedItems = () => {
    const { stage_details } = this.props
  }

  setNewState = (state) => {
    this.setState(state)
  }

  render() {
    const { edit, selected_items, preview } = this.props
 
    return (
      <DataCard title="Room equipment" className="mb20" stickyHeader headerAction={() => {
        return (<div className="d-flex ml-auto">
          {
            !preview && edit ?
              <Button secondary
                onClick={() => {
                  this.setState({ change: !this.state.change });
                  $("#assignItemsDefault").modal('show')
                  $('#assignItemsDefault').on('shown.bs.modal', function () {
                    document.querySelectorAll('.tippy-popper').forEach(popper => {
                      popper._tippy.hide()
                    })
                  })
                }}
              >
                Assign items
              </Button> : null
          }
        </div>)
      }}>
        <Table minWidth={655}
          noResults={this.props.selected_items.length > 0 ? 0 : 1}

          renderModal={() => {
            return (<ViewItemCard id={`room-eq-iL`} onRef={ref => (this.viewItemRef = ref)} item={this.state.selectedItem} ></ViewItemCard>)
          }}

          renderHeaders={() => {
            return (
              <TRow>
                <THead className="w150" text="Catalogue #" />
                <THead className="w300" text="Name" />
                <THead className="w100" text="Size" />
                <THead className="w100" text="Qty" />
                {edit ?
                <THead className="w80" text="" /> :null}
              </TRow>
            )
          }}
          renderRows={() => {
            let rows = this.props.selected_items.map(item => {
 

              return (
                <TRow>
                  <TData className="w150">{item.catalog_number}</TData>
                  <TData className="w250 has-action" item={item} popover onClick={() => {
                    this.setState({ selectedItem: item })
                    $(`#room-eq-iL`).modal('show')
                  }}>{item.name}</TData> 
                  <TData className="w100">{item.size}</TData>
                  <TData className="w100">{item.qty}</TData>
                  {edit ?
                    <TData className="w80"><Button tertiary
                      onClick={(event) => {
                        event.preventDefault()
                        event.stopPropagation()
                        this.props.removeItemFromSelected(item.id)
                        return;
                      }}>Remove</Button></TData>
                  :null}
                </TRow>
              )
            })
            return rows
          }}
        />

        <AssignItemsDefault selected_items={selected_items} setNewState={(state) => { this.props.setNewState(state) }} change={this.state.change} />
      </DataCard> 
    )
  }
}

export default ItemList
