import React, { Component } from 'react'
import CKEditor from 'ckeditor4-react'
import { Cropper } from 'rc-cropping'
import Dialog from 'rc-dialog'
import img from '../../../assets/images/img.png'

import placeholder_img_dark from '../../../assets/images/add-media-placeholder-dark.png'
import placeholder_img from '../../../assets/images/add-media-placeholder.png'


import placeholder_img_dark_err from '../../../assets/images/add-media-placeholder-dark-err.png'
import placeholder_img_err from '../../../assets/images/add-media-placeholder-err.png'

import VideoThumbnail from 'react-video-thumbnail'
import { TextInput } from '../../commons/forms'
import { getSubElementError } from '../../../utilities'
import { SortableHandle } from 'react-sortable-hoc'
import ImageCropper from '../../commons/elements/inputs/ImageCropper'
import $ from 'jquery'
import InfoLabel from './InfoLabel'
import Icon from '../icons/Icon'
import CircleLoading from '../loading/CircleLoading'
import { uploadVideo } from '../../../utilities/upload-video'
import { uploadImage } from '../../../utilities/upload-image'
import axios from 'axios'
import { progressVideo } from '../../../actions/VideoAction'
import { connect } from 'react-redux'

const DragHandle = SortableHandle(() => {
  return (
    <a
      href="#"
      onClick={event => {
        event.preventDefault()
      }}
      className="move-icon"
    >
      <Icon icon="Move" height={30} width={30} />
    </a>
  )
})

class MediaEditorItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      source: '',
      file: null,
      file_raw: '',
      croppedFile: null,
      show: false,
      filename: '',
      selected: null,
      image: img,
      type: 'image',
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      loading: false,
      progress: 0,
    }

    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount = () => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    this.setState({
      source,
    })
  }

  handleClick(e) {
    this.refs.file.click()
  }

  onFileChange = () => {
    if (this.refs.file.files[0].type.split('/')[0] == 'image') {
      $(
        `#imageCropper${this.props.validation.index}${this.props.index}`
      ).modal({ backdrop: 'static', keyboard: false, show: true })
      var reader = new FileReader()
      var url = reader.readAsDataURL(this.refs.file.files[0])
      reader.onloadend = () => {
        this.setState({
          file_raw: reader.result,
          type: 'image',
        })
      }
    } else {
      if (!this.refs.file.files[0]) {
        this.setState({
          show: false,
          file: null,
          croppedFile: null,
        })
        return
      }
      this.setState(
        {
          loading: true,
        },
        () => {
          this.props.progressVideo()
          this.props.progressMedia()
        }
      )

      const fileData = this.refs.file.files[0]
      uploadVideo(
        fileData,
        progress => {
          this.setState({
            progress: Math.round((progress.loaded * 100) / progress.total),
          })
        },
        this.state.source.token
      )
        .then(result => {
          this.props.thumbnailChange(result.thumbnail)
          this.props.onMediaChange({
            data: result.source,
            type: 'video',
          })
          this.setState(
            {
              loading: false,
              progress: 0,
            },
            () => {
              this.props.progressVideo()
              this.props.progressMedia()
            }
          )
        })
        .catch(error => {
          this.setState(
            {
              loading: false,
              progress: 0,
            },
            () => {
              this.props.progressVideo()
              this.props.progressMedia()
            }
          )
          console.log(error)
        })
      this.setState({
        show: false,
        file: null,
        croppedFile: null,
        isNext: false,
        page: 1,
        type: 'video',
      })
    }
  }

  croppeFile = fileBlob => {
    if (!fileBlob) {
      this.setState({
        show: false,
        file: null,
        croppedFile: null,
        isNext: false,
        page: 1,
      })
      return
    }
    const reader = new FileReader()
    reader.readAsDataURL(fileBlob)
    reader.onload = () => {
      this.props.onMediaChange({ data: reader.result, type: 'image' })
      this.setState({
        show: false,
        file: null,
        croppedFile: null,
        isNext: false,
        page: 1,
        image: reader.result,
      })
    }
  }

  renderCropper() {
    const { file, loading, progress } = this.state
    const { media_data, validation, index } = this.props
    let error = getSubElementError(
      validation.errors,
      validation.prefix,
      validation.index,
      'media-value',
      index
    )
    //console.log(error)
    if (loading) return <CircleLoading progress={progress} />

    if (!file) {
      if (media_data.source) {
        return (
          <InfoLabel
            changeable
            style={
              error
                ? { border: '2px dashed red' }
                : { border: '2px dashed rgb(132, 134, 136)' }
            }
          >
            <span className="change-label">
              {' '}
              Change {this.state.type == 'image' ? 'image' : 'video'}{' '}
            </span>
            <input
              type="file"
              name="image"
              accept="video/*,image/*"
              style={{ zIndex: -1, position: 'absolute' }}
              onChange={this.onFileChange}
              ref="file"
              required
            />
            <img src={media_data.thumbnail} />
          </InfoLabel>
        )
      }

      return (
        <InfoLabel
          className={error ? 'is-invalid' : ''}
          style={
            error
              ? { border: '2px dashed red' }
              : { border: '2px dashed rgb(132, 134, 136)' }
          }
        >
          {error ? (
            <>

              <img src={localStorage.getItem('darkmode') == 'true' ? placeholder_img_dark_err : placeholder_img_err} />
            </>
          ) : (

              <img src={localStorage.getItem('darkmode') == 'true' ? placeholder_img_dark : placeholder_img} />
          )}
          <input
            type="file"
            name="image"
            accept="video/*,image/*"
            className="w300"
            style={{ zIndex: -1, position: 'absolute' }}
            onChange={this.onFileChange}
            ref="file"
            required
          />
        </InfoLabel>
      )
    }
  }
  render() {
    const { validation, index } = this.props
    let items = this.props.mentions ? this.props.mentions : []

    let dataFeed = (opts, callback) => {
      var matchProperty = 'name',
        data = items.filter(function(item) {
          return (
            item[matchProperty]
              .toLowerCase()
              .indexOf(opts.query.toLowerCase()) !== -1
          )
        })
      callback(data)
    }
    return (
      <div
        className="card media-item media-editor-item"
        key={`${validation.index} ${index}`}
      >
        <div className="card-header">
          <DragHandle />

          <TextInput
            value={
              this.props.media_data.title ? this.props.media_data.title : ''
            }
            placeholder={'Title here...'}
            errorMessage={
              getSubElementError(
                validation.errors,
                validation.prefix,
                validation.index,
                validation.field,
                index
              )
                ? 'This field is required!'
                : ''
            }
            errorClass="mt0"
            containerClass={`mb0 col-lg-6 col-md-8 mx10 px0`}
            onValueChange={this.props.onTitleChange}
            inputClass="has-val active show-placeholder"
          />

          <a
            href="#"
            className="trash-icon"
            onClick={event => {
              const { source } = this.state
              source.cancel('Operation canceled by the user.')
              this.props.onDeleteMediaElement(event, validation.index, index)
            }}
          >
            <Icon icon="Delete" height={30} width={30} />
          </a>
        </div>
        <div className="card-body standard-body">
          <div className="card-body img-body d-flex justify-content-center align-items-center ">
            {this.renderCropper()}
            {/* <div className="ar32">
              <img src={img} />
            </div> */}
          </div>
          <div className="card-body editor-body">
            <CKEditor
              data={this.props.media_data.caption}
              onChange={this.props.onCaptionChange}
              config={{
                pasteFilter: 'h1 h2 h3 h4 h5 h6 p ul ol li; img[!src, alt]; a[!href]',
                allowedContent: {
                  $1: {
                    attributes: true,
                    styles: true,
                    classes: true,
                  },
                },
                toolbar: [
                  'mentions',
                  ['Bold', 'Italic', 'Underline', '-'],
                  [
                    'NumberedList',
                    'BulletedList',
                    '-',
                    // 'Outdent',
                    // 'Indent',
                    // '-',
                    'JustifyLeft',
                    'JustifyCenter',
                    'JustifyRight',
                    // 'JustifyBlock',
                    '-',
                  ],
                  [
                    //'Table', 'HorizontalRule',
                    'Link',
                    // 'Smiley',
                  ],
                  ['FontSize'],
                  ['TextColor', 'BGColor'],
                ],

                mentions: [
                  {
                    feed: dataFeed,
                    itemTemplate:
                      '<li data-id="{id}">' +
                      '<strong class="username">{name}</strong>' +
                      '<span class="fullname"> ({catalog_number})</span>' +
                      '</li>',
                    outputTemplate: `<a href="#" class="tippy-hover" data-id="{id}">  
                                {name}
                              </a>`,
                    minChars: 1,
                  },
                ],
                removePlugins: 'elementspath',
                resize_enabled: false,
              }}
            />
          </div>
        </div>

        <ImageCropper
          key={this.state.unique}
          id={`imageCropper${validation.index}${index}`}
          image={this.state.file_raw}
          aspectRatio={3 / 2}
          width={720}
          height={480}
          getImage={image => {
            this.setState(
              {
                loading: false,
                progress: 0,
              },
              () => {
                this.props.progressVideo()
                this.props.progressMedia()
              }
            )
            uploadImage(
              image,
              progress => {
                this.setState({
                  progress: Math.round(
                    (progress.loaded * 100) / progress.total
                  ),
                })
              },
              this.state.source.token
            ).then(result => {
              this.props.onMediaChange({ data: result.data, type: 'image' })
              this.props.thumbnailChange
                ? this.props.thumbnailChange(image)
                : this.props.onContentThumbnailChange(image)
              this.props.progressComplete()
              this.setState(
                {
                  loading: false,
                  progress: 0,
                },
                () => {
                  this.props.progressVideo()
                  this.props.progressMedia()
                }
              )
            })
            this.setState({
              show: false,
              file: null,
              croppedFile: null,
              isNext: false,
              page: 1,
              image: image,
            })
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

export default connect(mapStateToProps, {
  progressVideo,
})(MediaEditorItem)
