import axios from 'axios'
import $ from 'jquery'
import React, { Component } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { connect } from 'react-redux'
import { clearQuicknotesData } from '../../actions/QuicknotesActions'
import { decrementSurgeon } from '../../actions/UserActions'
import { Table, TableNotificationCard, TData, THead, TRow } from '../../components/commons/table'
import { Button } from '../commons/forms'
import { Pills } from '../commons/misc'
import { ConfirmationModal } from '../commons/modals'


class Surgeons extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      items: [],
      loading: false,
      dataLength: 1,
      selected_surgeon: null,
      successAppear: false,
      successMessage: '',
    }
  }

  componentDidMount() {
    console.log(this.props.activeTab)
    this.makeRemoteRequest()
  }
  makeRemoteRequest = () => {
    this.setState({ loading: true })
    const { page } = this.state
    axios
      .get(`api/my-surgeons?page=${page}`)
      .then(res => {
        console.log(res)
        this.setState({
          items:
            page === 1
              ? res.data.data
              : [...this.state.items, ...res.data.data],
          loading: false,
        })
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
        this.setState({ loading: false })
      })
  }
  renderLoading() {
    if (this.state.loading)
      return (
        <div className="d-flex justify-content-center pt30">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )
    return null
  }

  loadMoreData() {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => {
        this.makeRemoteRequest()
      }
    )
  }
  
  deleteSurgeon = () => {
    const { selected_surgeon } = this.state

    this.surgeonTableRef.showThis()
    const data = {
      _method: 'DELETE',
    }
    axios.post(`api/client/surgeon/${selected_surgeon.id}`, data).then(result => {
      const res = this.state.items.filter(i => i.id != selected_surgeon.id)
      this.props.decrementSurgeon()
      this.setState(
        {
          items: res.splice(0),
          successAppear: true,
          successMessage: 'Surgeon have been successfully deleted',
        },
        () => {
          this.surgeonTableRef.showThis()
        }
      )
    })
  }
  render() {
    console.log('records', this.state.items) 

    return (
      <div>
      
        <ConfirmationModal
          title="Delete Surgeon"
          message="Deleting surgeon will remove it from the database and will have to be added back manually."
          confirmText="Delete"
          cancelText="Cancel"
          idModal={`procedure-modal-delete`}
          confirm={this.deleteSurgeon.bind(this)}
          cancel={() => {
            $('#procedure-modal-delete').modal('hide')
          }}
        />
        <div className="row animated fadeIn pt4">
          <div className="col-md-12">
            <div className="table-responsive">
              <InfiniteScroll
                className=""
                dataLength={this.state.items.length}
                next={this.loadMoreData.bind(this)}
                hasMore={true}
              > 
                <Table minWidth={`${this.state.isEditMode ? 1160 : 860}`} 

                  renderHeaders={() => {
                    return (
                      <TRow>
                        <THead className="w250" text="Surgeon Name" sortable onClick={() => this.sortBy('catalog_number')} />
                        <THead className="w300" text="Specialties" sortable onClick={() => this.sortBy('name')} /> 
                        <THead className="w100 text-center" text="Edits"/> 
                      </TRow>
                    )
                  }}

                  renderRows={() => {
                    let rows = this.state.items.map(surgeon => {

                      const specialties = surgeon.specialties.map((specialty, x) => {
                        if (!specialty.specialty) return null
                        return (
                          <Pills bg={specialty.specialty.color}>{specialty.specialty.name}</Pills>
                        )
                      })
                      return (
                        <TRow>
                          <TData className="w250">{`${surgeon.firstname} ${surgeon.lastname}`}</TData>
                          <TData className="w300">{specialties}</TData> 
                          <TData className="w150 actions text-center">
                            <div className="d-flex w-100 h-100 justify-content-center">
                              <Button link tertiary url={{ pathname: `/surgeon-update/${surgeon.id}` }}>
                                Edit
                              </Button>
                              <Button tertiary
                                datatarget="#procedure-modal-delete"
                                datatoggle="modal"
                                onClick={() => this.setState({ selected_surgeon: surgeon })}>
                                Delete
                              </Button> 
                            </div>
                          </TData>
                           
                        </TRow>
                      )
                    })
                    return rows
                  }}
                  
                  renderNotification={() => {
                     
                    return (<TableNotificationCard type="success" colSpan={3} message={this.state.successMessage} onRef={ref => (this.surgeonTableRef = ref)} />) 
                  }}

                />
                
              </InfiniteScroll>
            </div>
          </div>
        </div>
        {this.renderLoading()}
      </div>
    )
  }
}


const mapStateToProps = state => {
  
  return {  }
}

export default  connect(mapStateToProps,
  { decrementSurgeon, clearQuicknotesData
    
  })(Surgeons)