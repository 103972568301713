import React, { Component } from 'react'
import axios from 'axios'
import ReactDOMServer from 'react-dom/server'
import $ from 'jquery'
import ViewItemModal from '../modals/ViewItemModal'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link } from 'react-router'
import { NotificationCard } from '../commons/cards'
import { Button } from '../commons/forms'
import default_img from '../../assets/images/img.png'
import {
  Tabs,
  TabBody,
  TabHead,
  TabNavigation,
  TabContent,
} from '../commons/tabs'
import Text from '../commons/texts/Text'
import ViewItemCard from '../commons/cards/ViewItemCard'
import { getSurgeonQuicknotes } from '../../actions/QuicknotesActions'
import { connect } from 'react-redux'
import TrayItemModal from '../modals/TrayItemModal'
import surgeon_placeholder from '../../assets/images/default_profile.jpg'

class Notification extends Component {
  constructor(props) {
    super(props)

    this.state = {
      notifications: [],
      selectedItem: null,
      page: 1,
      type: 'me',
      loading: false,
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      tray_unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
    }
  }

  componentDidMount = () => {
    $('[data-toggle="popover"]').popover()
    $('[data-toggle="popover"]').popover('hide')
    $('#viewItemModal').modal('hide')
    this.makeRemoteRequest()
  }

  renderTags(data) {
    return ReactDOMServer.renderToStaticMarkup(
      data.map((data, index) => {
        return (
          <span
            key={`tag-${data.id}-key-${index}`}
            className="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10"
          >
            {data.name}
          </span>
        )
      })
    )
  }
  makeRemoteRequest = () => {
    const { page, type } = this.state
    this.setState({
      loading: true,
    })
    axios
      .get(`api/my-notifications?page=${page}&type=${type}`)
      .then(result => {
        this.setState({
          notifications:
            page === 1
              ? result.data.data
              : [...this.state.notifications, ...result.data.data],
          loading: false,
        })
        $('[data-toggle="popover"]').popover()
        $('[data-toggle="popover"]').popover('hide')
        $('#viewItemModal').modal('hide')
      })
      .catch(error => {
        console.log(error)
      })
  }

  renderLoading() {
    if (this.state.loading)
      return (
        <div class="row">
          <div class="col-md-12 text-center pb30">
            <div className="d-flex justify-content-center mb30">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      )
    return null
  }
  renderItem = (data, key) => {
    const item = data.data
    const nurse = data.by_user
    let medical_inventory = 'N/A'
    let intro = 'Register new item'
    if (item.category) {
      switch (item.category.medical_inventory_id) {
        case 1:
          medical_inventory = 'Supplies'
          break
        case 2:
          medical_inventory = 'Instruments'
          break

        default:
          medical_inventory = 'Equipments'
          break
      }
    }
    if (data.action_type == 'Updated') {
      intro = 'Update an Item'
    }
    return (
      <NotificationCard
        image={item.icon}
        title={nurse.name ? nurse.name : ''}
        date={data.date}
        key={`item-key-${key}`}
      >
        <p className="notification-desc"> 
          {`${intro}`}{' '}
          <Text
            item={item}
            className="cta cta-tertiary"
            onClick={() => {
              this.setState({
                selectedItem: item,
              })
              $(`#preview-modal-notification`).modal('show')
            }}
          >
            <u>{`${item.name}`}</u>
          </Text>{' '}
          for{medical_inventory} Medical Inventory
        </p>
      </NotificationCard>
    )
  }

  renderClientStage = (data, key) => {
    const notification = data.data
    const nurse = data.by_user

    const changes = data.changes.map(change => {
      const new_items = change.new_items.map((item, index) => {
        return (
          <li>
            <Text
              className="cta cta-tertiary"
              item_id={item.id}
              setSelectedItem={sel_item => { 
                this.setState({
                  selectedItem: sel_item,
                })
              }}
              onClick={() => { 
                if(change.key_new && change.key_new.length > 0){
                  this.setState(
                    {
                      tray: {
                        id: change.key_new[index],
                        name: item,
                      },
                      tray_unique:
                        Math.random()
                          .toString(36)
                          .substring(2, 15) +
                        Math.random()
                          .toString(36)
                          .substring(2, 15),
                    },
                    () => {
                      $('#openTrayDetail').modal('show')
                    }
                  )
                } else { 
                  axios.get(`api/items/${item.id}`).then((e) => { 
                    this.setState({
                      selectedItem: e.data.data
                    })
                    $("#preview-modal-notification").modal('show')
                  })
                }
               
              }}
            >
              {change.name == 'Tray' ? item : item.name}
            </Text>
          </li>
        )
      })

      const remove_items = change.remove_items.map((item,index) => {
        return (
          <li>
            <Text
              className="cta cta-tertiary"
              item_id={item.id}
              setSelectedItem={sel_item => {
                this.setState({
                  selectedItem: sel_item,
                })
              }}
              onClick={() => {
                if(change.key_remove && change.key_remove.length > 0){
                  this.setState(
                  {
                    tray: {
                      id: change.key_remove[index],
                      name: item,
                    },
                    tray_unique:
                      Math.random()
                        .toString(36)
                        .substring(2, 15) +
                      Math.random()
                        .toString(36)
                        .substring(2, 15),
                  },
                  () => {
                    $('#openTrayDetail').modal('show')
                  }
                )
                } else {
                  axios.get(`api/items/${item.id}`).then((e) => {
                    this.setState({
                      selectedItem: e.data.data
                    })
                    $("#preview-modal-notification").modal('show')
                  })
                }
              }}
            >
              {change.name == 'Tray' ? item : item.name}
            </Text>
          </li>
        )
      })
      return (
        <p>
          <b>{change.name}</b>
          <br />

          {change.new_items ? (
            change.new_items.length > 0 ? (
              <span>
                {change.name == 'Tray' ? (
                  <small>Added</small>
                ) : (
                  <small>
                    Added item
                    {change.new_items
                      ? change.new_items.length > 1
                        ? 's'
                        : ''
                      : ''}
                  </small>
                )}
                <ul
                  className="list-unstyled"
                  style={{ listStyleType: 'disc', textIndent: '1.3em' }}
                >
                  {new_items}
                </ul>
                <br />
              </span>
            ) : (
              ''
            )
          ) : (
            ''
          )}
          {change.remove_items ? (
            change.remove_items.length > 0 ? (
              <span>
                {change.name == 'Tray' ? (
                  <small>Removed</small>
                ) : (
                  <small>
                    Removed item
                    {change.remove_items
                      ? change.remove_items.length > 1
                        ? 's'
                        : ''
                      : ''}
                  </small>
                )}
                <ul
                  className="list-unstyled"
                  style={{ listStyleType: 'disc', textIndent: '1.3em' }}
                >
                  {remove_items}
                </ul>
              </span>
            ) : (
              ''
            )
          ) : (
            ''
          )}
        </p>
      )
    })

    return (
      <NotificationCard
        image={notification.surgeon.avatar != '' && notification.surgeon.avatar != null ? data.data.surgeon.avatar: surgeon_placeholder }
        title={nurse.name ? nurse.name : ''}
        key={`client-stage-key-data-${key}`}
        date={data.date}
      >
        <p className="notification-desc">
          {`Update surgeon procedure for `}
          {notification.deleted_at ||
          notification.surgeon.deleted ||
          notification.procedure.deleted_at ? (
            <u className="font-weight-bold">
              <span>{`${notification.surgeon.firstname} ${notification.surgeon.lastname}`}</span>
            </u>
          ) : (
            <Text
              link
              url={{
                pathname: `procedure/${notification.procedure.id}`,
                state: {
                  procedureData: notification.stage,
                  settings: false,
                  type: notification.type,
                },
                search: `?surgeon_id=${
                  notification.surgeon ? notification.surgeon.id : ''
                }&scroll=${true}&settings=${false}&stage=${data.model_id}`,
              }}
            >
              <u className="font-weight-bold">
                {`${notification.surgeon.firstname} ${notification.surgeon.lastname}`}
              </u>
            </Text>
          )}
          {` in ${notification.procedure.name}`}
          {notification.action_type} - {notification.data}
        </p>
        {data.changes && data.changes.length > 0 ? changes : ''}
      </NotificationCard>
    )
  }

  renderSurgeon = (data, key) => {
    const item = data.data
    const nurse = data.by_user
    let intro = 'Register new surgeon'
    console.log('data surgeon', item.avatar == '', data)
    if (data.action_type == 'Updated') {
      intro = 'Updated a surgeon'
    }
    if (data.action_type == 'Deleted') {
      intro = 'Deleted a surgeon'
    }
    return (
      <NotificationCard
        image={item.avatar == '' ? surgeon_placeholder:item.avatar}
        title={nurse.name ? nurse.name : ''}
        key={`surgeon-key-data-${key}`}
        date={data.date}
      >
        <p className="notification-desc">
          {`${intro}`}{' '}
          {item.deleted ? (
            <u className="font-weight-bold">
              <span>{`${item.firstname} ${item.lastname}`}</span>
            </u>
          ) : (
            <Text
              link
              url={{
                pathname: `/quicknotes/${item.id}`,
              }}
              >{`${item.firstname} ${item.lastname}`}</Text>
          )}
        </p>
      </NotificationCard>
    )
  }
  renderQuicknotes = (data, key) => {
    const item = data.data
    const nurse = data.by_user
    let intro = 'Update surgeon quicknotes for '

    return (
      <NotificationCard
        image={data.surgeon.avatar}
        title={nurse.name ? nurse.name : ''}
        key={`surgeon-key-data-${key}`}
        date={data.date}
      >
        <p className="notification-desc">
          {`${intro}`}{' '}
          {item.surgeon.deleted ? (
            <u className="font-weight-bold">
              <span>{`${item.surgeon.firstname} ${item.surgeon.lastname}`}</span>
            </u>
          ) : (
            <Text
              link
              url={{
                pathname: `/quicknotes/${item.surgeon_id}`,
                state: {
                  activeQuicknotes: true,
                },
              }}
              onClick={() => {
                this.props.getSurgeonQuicknotes(item.quicknotes_id, 0)
              }}
            >
              <u className="font-weight-bold">
                {`${item.surgeon.firstname} ${item.surgeon.lastname}`}
              </u>
            </Text>
          )}
          {` in ${item.quicknotes.name}`}
        </p>
      </NotificationCard>
    )
  }
  renderProcedure(data, key) {
    const nurse = data.by_user
    console.log('renderProcedure', data.data.surgeon.avatar )
    return (
      <NotificationCard
        image={data.data.surgeon ? data.data.surgeon.avatar != '' && data.data.surgeon.avatar != null ? data.data.surgeon.avatar : surgeon_placeholder : surgeon_placeholder}
        title={nurse.name ? nurse.name : ''}
        date={data.date}
      >
        <p className="notification-desc">
          {`${data.action_type} Procedure`}{' '}
          <u className="font-weight-bold tertiary">{`${data.data.name}`}</u> for{' '}
          {data.data.surgeon && !data.data.surgeon.deleted ? (
            <Text
              link
              url={{
                pathname: `/quicknotes/${data.data.surgeon.id}`,
              }}
            >
              {`${data.data.surgeon.firstname} ${data.data.surgeon.lastname}`}
            </Text>
          ) : null}
        </p>
      </NotificationCard>
    )
  }
  loadMoreData() {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => {
        this.makeRemoteRequest()
      }
    )
  }
  renderSurgeonProcedure = (data, key) => {
    const item = data.data
    const nurse = data.by_user
    let intro = 'Deleted surgeon procedure '

    return (
      <NotificationCard
        image={data.surgeon.avatar}
        title={nurse.name ? nurse.name : ''}
        key={`surgeon-key-data-${key}`}
        date={data.date}
      >
        <p className="notification-desc">
          {`${intro}`} {`${item.procedure.name} for `}
          <u className="font-weight-bold">
            <span>{`${item.surgeon.firstname} ${item.surgeon.lastname}`}</span>
          </u>
        </p>
      </NotificationCard>
    )
  }

  render() {
    const { notifications } = this.state
    const notificationList = notifications.map((data, index) => {
      switch (data.model) {
        case 'Item':
          if (data.data) return this.renderItem(data, index)
          break
        case 'Procedure':
          if (data.data) return this.renderProcedure(data, index)
        case 'Surgeon':
          if (data.data) return this.renderSurgeon(data, index)
        case 'ClientStage':
          if (data.data) return this.renderClientStage(data, index)
        case 'SurgeonQuickNotes':
          if (data.data) return this.renderQuicknotes(data, index)
        case 'SurgeonProcedure':
          if (data.data) return this.renderSurgeonProcedure(data, index)
        default:
          return null
          break
      }
    })

    return (
      <div className="col-lg-8 col-md-7">
        <TrayItemModal key={this.state.tray_unique} tray={this.state.tray} />
        <ViewItemModal
          item={this.state.selectedItem}
          key={this.state.unique}
          cancel={() => {
            $('viewItemModal').modal('hide')
          }}
        />
        <ViewItemCard
          id={`preview-modal-notification`}
          onRef={ref => (this.viewItemRef = ref)}
          item={this.state.selectedItem}
        ></ViewItemCard>
        <h1 className="page-title d-none d-md-block">The Lounge</h1>
        <div className="row">
          <div className="col-md-12">
            <Tabs
              navs={() => {
                return (
                  <TabHead>
                    <TabNavigation target="activity-logs" active={true} onClick={()=>{
                      this.setState({
                        type: 'me',
                        page:1,
                        notifications:[]
                      }, () => {
                        this.makeRemoteRequest();
                      })
                    }}>
                      My Activity logs
                    </TabNavigation>
                    <TabNavigation target="corh-logs" onClick={() => {
                      this.setState({
                        type: 'admin',
                        page:1,
                        notifications:[]
                      }, ()=>{
                          this.makeRemoteRequest();
                      })
                    }}>
                      CORH Updates
                    </TabNavigation>
                  </TabHead>
                )
              }}
              contents={() => {
                return (
                  <TabBody>
                    <TabContent idTab="activity-logs" active={true}>
                      <div className="row pt20">
                        <div className="col-md-12">
                          <InfiniteScroll
                            className="pr5 of-hidden"
                            dataLength={this.state.notifications.length}
                            next={this.loadMoreData.bind(this)}
                            hasMore={true}
                          >
                            <ul className="list-unstyled">
                              {notificationList}
                            </ul>
                          </InfiniteScroll>
                          {this.renderLoading()}
                        </div>
                      </div>
                    </TabContent>

                    <TabContent idTab="corh-logs">
                      <div className="row pt20">
                        <div className="col-md-12">
                          <InfiniteScroll
                            className="pr5 of-hidden"
                            dataLength={this.state.notifications.length}
                            next={this.loadMoreData.bind(this)}
                            hasMore={true}
                          >
                            <ul className="list-unstyled">
                              {notificationList}
                            </ul>
                          </InfiniteScroll>
                          {this.renderLoading()}
                        </div>
                      </div>
                    </TabContent>
                  </TabBody>
                )
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {}
}
export default connect(
  mapStateToProps,
  {
    getSurgeonQuicknotes,
  }
)(Notification)
