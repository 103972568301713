import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { getSurgeonQuicknotes } from '../../actions/QuicknotesActions'
import { SavedDraftCard } from '../commons/cards';

class Draft extends Component {
  constructor(props) {
    super(props)

    this.state = {
      drafts: [],
      procedures:[]
    }
  }

  componentDidMount = () => {
    axios
      .get(`api/my-drafts`)
      .then(result => {
        console.log(result)
        this.setState({
          drafts: result.data.data,
        })
      })
      .catch(error => {
        console.log(error)
      })
    
      axios
      .get(`api/client/procedure-draft-notification`)
      .then(result => {
        console.log(result)
        this.setState({
          procedures: result.data.data,
        })
      })
      .catch(error => {
        console.log(error)
      })
  }
  noDrafts(){
    return(
      <li className="item text-center">
        <i>Nothing to show...</i>
      </li>
    )
  }
  render() {
    const { drafts,procedures } = this.state
    const proceduresDraftList = procedures.map((item,index) => {
      return (
        <Link className="item"
          to={{
            pathname: `procedure/${item.procedure ? item.procedure.id:''}`,
            state: {
              stage: item.stage,
              surgicalTree: item.surgicalTree,
              scrollTab: true,
            },
            search: `?surgeon_id=${item.surgeon ? item.surgeon.id : ''}&scroll=${true}&stage=${item.stage.id}`
          }}
          key={'draft-item-procedure-key-'+index}
        >
          <span> {`[${item.procedure ? item.procedure.name:'N/A'}] , [${item.surgicalTree ? item.surgicalTree.name:''}], [${item.surgeon ? `${item.surgeon.lastname} ${item.surgeon.firstname}`:'N/A'}]`}</span>
          <small className="saved-drafts-date">{item.date}</small>
        </Link>
      )
    })
    const draftList = drafts.map((item, index) => {
      return (
        <Link className="item"
          to={{
            pathname: `/quicknotes/${item.surgeon.id}`,
            state: {
              edit: true
            },
            search: `?quicknotes_data_id=${item.quicknotes.id}`
          }}
          onClick={() => { this.props.getSurgeonQuicknotes(item.quicknotes.id, 1) }}
          key={'draft-item-key-'+index}
        >
          <span> {`[Quicknotes],[${item.quicknotes.name}], [${item.surgeon.firstname} ${item.surgeon.lastname}]`}</span>
          <small className="saved-drafts-date">{item.date}</small>
        </Link>
      )
    })
    return (
      <>  
        <div className="card saved-drafts-card mb24">
          <h3 className="bold saved-drafts-title">Quicknotes drafts</h3>
          <ul className="list-unstyled saved-drafts-list">
            {drafts.length ? draftList : this.noDrafts()}
          </ul>
        </div>

        <div className="card saved-drafts-card mb24">
          <h3 className="bold saved-drafts-title">Procedures drafts</h3>
          <ul className="list-unstyled saved-drafts-list">
            {procedures.length ? proceduresDraftList : this.noDrafts()}
          </ul>
        </div>
      </>
    )
  }
}

export default connect(null, { getSurgeonQuicknotes })(Draft)
