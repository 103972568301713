import React, { Component } from 'react'
import InfiniteScroll from "react-infinite-scroll-component"
import axios from 'axios'
import EndOfLoading from '../loading/EndOfLoading';
import { Button } from '../forms';
import { Link } from 'react-router'
import $ from 'jquery'
export class Table extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            ready: 0,
            cols: 1,
            unique:
                Math.random()
                    .toString(36)
                    .substring(2, 12) +
                Math.random()
                    .toString(36)
                    .substring(2, 13),
            
        }
    }
    componentDidMount() {
        setTimeout(() => {
            this.setState({
                ready: 1
            })
            if(this.props.afterRender){
                this.props.afterRender()    
            }
        }, 1000)
    }

    renderLoading(){
        return (
            <tr className="table-loading">
                <td>
                    <div className="spinner-grow text-dark" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    Loading...
                </td>
            </tr>
        )
    }

    renderNodata(){
        if (this.props.renderHeaders() && !this.props.renderLastRow){
            let cs_count = $(`#table-${this.state.unique} thead tr > th`).length 
            return (
                <tr className="table-nodata">
                    <td colSpan={cs_count}>
                        No data found.
                    </td>
                </tr>
            )
        }else{
            return null
        }
    }
    onLoading = () => {
        return (
            <tr>
                <td colSpan={this.props.cols} className="p0">
                    <div className="row m0">
                        <div className="col-md-12 text-center py32 loading-infi">
                            <div className="d-flex justify-content-center mb30">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        )
    }

    render() {
        const { className='', renderHeaders = [], renderRows = null, renderLastRow = false, renderNotification = null, minWidth, dataText, noData = this.renderNodata, noResults, 
            infiniteScroll = false, infiniteDataLength, infiniteNext, infiniteHasMore, infiniteHeight, bodyStyle, cols, endOfMessageTitle, endOfMessageMessage, endOfMessageLink, endOfMessageOnClick, endOfMessageLinkMessage, renderModal, loading = false, tableHeight=0 } = this.props 
        return (
            <div className={`corh-table ${className} h${tableHeight != 0 ? tableHeight:''}`} data-text={dataText ? dataText:null}>
                {renderModal ? renderModal():null }
                <table className={`min-table-width-${minWidth}`} id={`table-${this.state.unique}`}>
                    <thead>
                        {renderHeaders()}
                    </thead>
                    <tbody className={`${infiniteScroll ? 'infinite-tbody' : ''}`} id={`tbody-${this.state.unique}`} style={bodyStyle}>

                        {renderNotification ? renderNotification():null}
                        {
                            loading ? this.onLoading() :(
                            infiniteScroll ?  
                                <InfiniteScroll
                                    dataLength={ infiniteDataLength }
                                    next={() => { infiniteNext() }}
                                    hasMore={ infiniteHasMore }
                                    hasChildren={true}
                                    scrollableTarget={`tbody-${this.state.unique}`}
                                    loader={
                                        <div class="row mt16 mx0">
                                            <div class="col-md-12 text-center py32 loading-infi">
                                                <div className="d-flex justify-content-center mb30">
                                                    <div className="spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    height={infiniteHeight}
                                    endMessage={ 
                                        <div className="row mt16 mx0">
                                            <div className="col-md-12 text-center end-of-loading">
                                                <h2 className="end-of-loading-title">{endOfMessageTitle}</h2>
                                                <p className="text-center end-of-loading-description">
                                                    {endOfMessageMessage} {" "}

                                                    <Link to={endOfMessageLink} onClick={endOfMessageOnClick} className="float-left float-md-none w-xs-100">
                                                        {endOfMessageLinkMessage}
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                    }
                                >

                                    {loading ? this.onLoading() : noResults ? this.renderNodata() : renderRows()}


                                </InfiniteScroll>
                                : loading ? this.onLoading() : noResults ? this.renderNodata() : renderRows()
                        )
                        }

                        {renderLastRow ? renderLastRow() : null}
                        
                    </tbody>
                </table>
            </div>
        )
    }
}

export default Table
