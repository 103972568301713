import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  getMedicalInventories,
  getMedicalInventoryCategories,
  getItems,
  setItems,
  getItemsPerCategory,
  itemLoading,
} from '../../actions/ItemAction'
import ReactDOMServer from 'react-dom/server'
import { Link } from 'react-router'
import { Picker, TextInput } from '../commons/forms'
import $ from 'jquery'
import _ from 'lodash'
import AllocateItemsProcedureInstrument from './AllocateItemsProcedureInstrument'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  Table,
  THead,
  TRow,
  TData,
  TableNotificationCard,
} from '../commons/table'
import { Modal } from '../commons/modals'
import ViewItemCard from '../commons/cards/ViewItemCard'
import axios from 'axios'

export class AssignItemsProcedureInstrument extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected_items: [],
      category: 'all',
      inventory: 2,
      isReverse: false,
      keyword: '',
      page: 1,
    }
    this.searchData = _.debounce(this.getItemResults, 600)
  }

  componentDidMount = () => {
    this.props.getMedicalInventoryCategories(2)
    this.props.getItemsPerCategory(
      'all',
      this.props.selected_items,
      this.state.inventory,
      '',
      this.state.page
    )
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.change != nextProps.change) {
      var selected_ = nextProps.selected_items.filter(item => {
        return item.item_category == nextProps.selected_category
      })

      var unselected_ = nextProps.selected_items.filter(item => {
        return item.item_category != nextProps.selected_category
      })
      this.setState({
        inventory: 2,
        keyword: '',
        category: 'all',
        selected_items: _.cloneDeep(selected_),
        un_selected_items: _.cloneDeep(unselected_),
      })
      this.props.getItemsPerCategory(
        'all',
        _.cloneDeep(selected_),
        this.state.inventory,
        '',
        this.state.page
      )
    }
  }

  getMoreItems = () => {
    const { page } = this.state

    this.setState(
      {
        page: page + 1,
      },
      () => {
        this.props.getItemsPerCategory(
          this.state.category,
          this.state.selected_items,
          this.state.inventory,
          '',
          this.state.page
        )
      }
    )
  }

  checkItem = id => {
    let { selected_items } = this.state
    const { items, selected_category } = this.props

    let item = items.find(e => e.id === id)
    if (item) {
      if (!item.qty) {
        item.qty = 1
      }
    } else {
      item = selected_items.find(e => e.id === id && e.item_category == selected_category)
    }

    if (selected_items.find(e => e.id === id && e.item_category == selected_category)) {
      this.setState({
        selected_items: selected_items.filter(x => x.id !== id),
      })
    } else {
      item['item_category'] = selected_category
      selected_items.unshift(item)
      this.setState({
        selected_items: selected_items,
      })
    }
  }

  checkItemExistence = id => {
    let { selected_items } = this.state
    let { selected_category } = this.props

    let exist = false
    if (selected_items.filter(e => e.id === id && e.item_category == selected_category).length > 0) {
      exist = true
    } else {
      exist = false
    }

    return exist
  }
  
  changeQty = (qty, item, item_id) => {

    let { selected_items } = this.state
    let { selected_category } = this.props

    item.qty = qty

    let sel_index = selected_items.findIndex((d) => {
      // console.log('d', d)
      return d.id == item.id && d.item_category == selected_category
    })
    // console.log('itemmmmmmmmmmmmmmmmm', item, selected_items[sel_index] ? 't':'f')
  //  let selected_index = selected_items.findIndex(item => item.id == item.id && item.item_category == selected_category);
    if (selected_items[sel_index]) {
      selected_items[sel_index] = item
    } else {
      // item['item_category'] = selected_category
      // selected_items.push(item)
    } 
    this.setSelected(selected_items)
  }

  sortBy(key) {
    let arrayCopy = [...this.props.items]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }

    this.props.setItems(arrayCopy)

    this.setState({ isReverse: !this.state.isReverse })
  }

  setSelected = selected_items => {
    this.setState({
      selected_items: selected_items,
    })
  }

  compareBy(key) {
    if (key == 'category') {
      return function(a, b) {
        if (a.category.name < b.category.name) return -1
        if (a.category.name > b.category.name) return 1
        return 0
      }
    }
    return function(a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }

  getCategories = () => {
    let i = this.props.item_categories
    let x = _.find(i, e => {
      return e.id == ''
    })
    if (!x) {
      i.unshift({ id: '', name: 'Select category' })
    }

    return i
  }

  getItemResults = () => {
    this.props.getItemsPerCategory(
      this.state.category,
      this.state.selected_items,
      this.state.inventory,
      this.state.keyword,
      this.state.page
    )
  }
  searchItems = ({ target: { value } }) => {
    this.props.itemLoading()
    this.setState({ keyword: value, page: 1 }, () => {
      this.searchData()
    })
  }


  assignItem = () => {
    //const { stage_master, item_categories, selected_items } = this.props

    const { stage_master, selected_category } = this.props
    const { selected_items } = this.state
    let item_categories = this.getCategories()
    
    const formData = new FormData()
    formData.append(`_method`, 'PATCH')
    formData.append('draft', 1)
    let index = 0;
    //item_categories.map((category, index) => {
      //if (index > 0) {
        formData.append(`instruments[${index}]`, selected_category)
        selected_items.map(item => {
          if (selected_category == item.item_category) {
            formData.append(`item_ids[${index}][]`, item.id)
            formData.append(`qty[${index}][]`, item.qty)
          }
        })
      //}
    //})
    axios
      .post(`api/client/stage-instrument-add-items/${stage_master.id}`, formData)
      .then(result => {
        this.props.assignNewState({
          selected_items: this.state.selected_items,
          selected_flag: true,
        })
        $('.modal').modal('hide')
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
      })
  }

  render() {
    let itemlist = this.props.items.map((item, index) => {
      let tags = item.tags.map(tag => {
        return ReactDOMServer.renderToStaticMarkup(
          <span className="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">
            {tag.name}
          </span>
        )
      })
      return (
        <tr key={`item-${index}`}>
          <td className="w150 px10">{item.catalog_number}</td>
          <td
            className="w250 font-weight-bold c-pointer text-dark fs-16 px10"
            data-container="body"
            data-trigger="hover"
            data-toggle="popover"
            data-placement="right"
            data-html="true"
            data-content={`
                              <div class="row">
                              <div class="col-md-4 text-center pr-0">
                              <img src=${item.icon} class="bg-dark w-100">
                              </div>
                              <div class="col">
                              <h6 class="mb15">${item.name}</h6>
                              <p>${item.used_for}</p>
                              </div>
                              <div class="col-md-12 mt15">
                              <p class=" mb10">Tags</p>
                              ${tags}
                              </div>
                              </div>`}
            onClick={() => {
              this.setState(
                {
                  selectedItem: item,
                  unique:
                    Math.random()
                      .toString(36)
                      .substring(2, 15) +
                    Math.random()
                      .toString(36)
                      .substring(2, 15),
                },
                () => {
                  $(`#${this.state.unique}`).modal('show')
                }
              )
            }}
          >
            {item.name}
          </td>
          <td className=" w150 px10">
            {' '}
            <p className="m-0 p-0 float-left w-100 fs14">
              {item.company}{' '}
            </p>{' '}
          </td>
          <td className=" w150 px10">
            {' '}
            <p className="m-0 p-0 float-left w-100 fs14">
              {item.category ? item.category.name : ''}
            </p>{' '}
          </td>
          <td className=" text-center w80">{item.size}</td>
          <td className="w80 text-center pt15 px10">
            <a
              href="#"
              className="text-dark mt15"
              onClick={event => {
                event.preventDefault()
                this.checkItem(item.id)
              }}
            >
              {this.checkItemExistence(item.id) ? 'Remove' : 'Add'}
            </a>
          </td>
        </tr>
      )
    })

    return (
      <div>
        <Modal
          idModal="assignItemsModalCenter"
          size="modal-xl"
          title="Step 1 - Assign items"
          confirmText="Continue"
          cancelText="Cancel"
          confirm={e => {
            // this.assignItem();
            $('#assignItemsModalCenter').modal('hide')
            $('#allocateItemsInstrument').modal('show')
          }}
          cancel={e => {
            //fn here
          }}
          bodyClass="assign-items-body"
        >

          <div className="container">
            <div className="row py15">
              <div className="col-lg-5">
                <TextInput
                  label={'Search item database'}
                  placeholder={'Start typing name, catalogue #…'}
                  className="active"
                  onChange={this.searchItems.bind(this)}
                  value={this.state.keyword}
                  search
                />
              </div>
              <div className="col-lg-5">
                <Picker
                  onChange={event => {
                    this.setState(
                      { category: event.target.value, keyword: '', page: 1 },
                      () => {
                        this.props.getItemsPerCategory(
                          this.state.category,
                          this.state.selected_items,
                          this.state.inventory,
                          '',
                          this.state.page
                        )
                      }
                    )
                  }}
                  label={`Filter by category`}
                  value={this.state.category}
                  data={this.props.inventory_categories}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <Table
                  minWidth={890} className="modal-table infi-table"
                  renderModal={() => {
                    return (
                      <ViewItemCard
                        id={`aip-c-preview-modal-${this.state.unique}`}
                        onRef={ref => (this.viewItemRef = ref)}
                        item={this.state.selectedItem}
                      ></ViewItemCard>
                    )
                  }}
                  infiniteScroll infiniteHeight={307}
                  infiniteDataLength={this.props.items.length} //This is important field to render the next data
                  infiniteNext={() => {
                    this.getMoreItems()
                  }}
                  loading={this.props.loading}
                  infiniteHasMore={this.props.more}
                  // endOfMessageTitle="Nothing else to see..."
                  endOfMessageMessage="Nothing else to see..."
                  // endOfMessageLinkMessage="Edit database"
                  renderHeaders={() => {
                    return (
                      <TRow>
                        <THead className="w40" text="" />
                        <THead
                          className="w150"
                          text="Catalogue #"
                          sortable
                          onClick={() => this.sortBy('catalog_number')}
                        />
                        <THead
                          className="w290"
                          text="Name"
                          sortable
                          onClick={() => this.sortBy('name')}
                        />
                        <THead
                          className="w100"
                          text="Size"
                          sortable
                          onClick={() => this.sortBy('size')}
                        />
                        <THead
                          className="w150"
                          text="Company"
                          sortable
                          onClick={() => this.sortBy('company')}
                        />
                        <THead
                          className="w150"
                          text="Category"
                          sortable
                          onClick={() => this.sortBy('category')}
                        />
                        <THead className="w90" text="Qty" />
                      </TRow>
                    )
                  }}
                  renderRows={() => {
                    let rows = this.props.items.map((item, index) => {
                      return (
                        <TRow
                          hasChoices
                          key={`row-aitt-${item.id}`}
                          
                          autoCheck={false}
                        >
                          <TData className="w40" onClick={() => {
                            this.checkItem(item.id)
                          }}>
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={`checkbox-aitt-${item.id}`}
                                checked={this.checkItemExistence(item.id)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`checkbox-aitt-${item.id}`}
                                onClick={e => {
                                  e.preventDefault()
                                }}
                              ></label>
                            </div>
                          </TData>
                          <TData className="w150">{item.catalog_number}</TData>
                          <TData
                            className="w290 has-action"
                            item={item}
                            popover
                            onClick={e => {
                              e.stopPropagation()
                              this.setState({
                                selectedItem: item,
                                unique:
                                  Math.random()
                                    .toString(36)
                                    .substring(2, 15) +
                                  Math.random()
                                    .toString(36)
                                    .substring(2, 15),
                              })
                              $(
                                `#aip-c-preview-modal-${this.state.unique}`
                              ).modal('show')
                            }}
                          >
                            {item.name}
                          </TData>
                          <TData className="w100">{item.size}</TData>
                          <TData className="w150">{item.company}</TData>
                          <TData className="w150">
                            {item.category ? item.category.name : ''}
                          </TData>
                          <TData className="w90" onClick={(e) => {
                            e.stopPropagation()
                          }}>
                            <input
                              type="number"
                              defaultValue={1}
                              className="form-control text-center form-control-sm px-0"
                              value={item.qty}
                              onChange={(e) => {
                                if (/^[1-9][0-9]*$/.test($(e.target).val()) || !$(e.target).val().length) {

                                this.changeQty(e.target.value, item, item.id)
                                }
                              }}
                              onBlur={(e) => {
                                if (!$(e.target).val().length) { 
                                  this.changeQty(1, item, item.id)
                                }
                              }}
                            />
                          </TData>
                        </TRow>
                      )
                    })
                    return rows
                  }}
                  // renderNotification={() => {
                  //     return (<TableNotificationCard type="success" message="Success message here..." />)
                  // }}
                />
              </div>
            </div>
          </div>
        </Modal>
        <div
          className="modal fade"
          id="assignItemsModalCenter2"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="assignItemsModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-xl"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body pt30 px30 pb0">
                <h5 className="m-0 h3bold pb0">Step 1 - Assign items</h5>
              </div>
              <div className="modal-body px30 pt-0 pb30">
                <div className="row">
                  <div className="col-lg-4 form-group mb30">
                    <label className="mb10">Search</label>
                    <input
                      type="text"
                      name="search"
                      className="form-control"
                      placeholder="Start typing name, catalogue #..."
                      onChange={this.searchItems.bind(this)}
                      value={this.state.keyword}
                      search
                    />
                  </div>
                  <div className="col-lg-4 form-group m-0">
                    <Picker
                      containerClass={`mb30`}
                      onChange={event => {
                        this.setState(
                          {
                            category: event.target.value,
                            keyword: '',
                            page: 1,
                          },
                          () => {
                            this.props.getItemsPerCategory(
                              this.state.category,
                              this.state.selected_items,
                              this.state.inventory,
                              '',
                              this.state.page
                            )
                          }
                        )
                      }}
                      label={`Filter by category`}
                      value={this.state.category}
                      data={this.props.inventory_categories}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="w-100 of-auto">
                      <table className="table table-striped table-fixed border min-table-width-870">
                        <thead className="bg-dark text-white text-left">
                          <tr>
                            <th
                              onClick={() => this.sortBy('catalog_number')}
                              className="w150 px10 c-pointer"
                            >
                              Catalogue # <i className="fa fa-sort ml5"></i>
                            </th>
                            <th
                              onClick={() => this.sortBy('name')}
                              className="w250 px10 c-pointer"
                            >
                              Name # <i className="fa fa-sort ml5"></i>
                            </th>
                            <th
                              onClick={() => this.sortBy('company')}
                              className="w150 px10 c-pointer"
                            >
                              Company # <i className="fa fa-sort ml5"></i>
                            </th>
                            <th
                              onClick={() => this.sortBy('category')}
                              className="w150 px10 c-pointer"
                            >
                              Category <i className="fa fa-sort ml5"></i>
                            </th>
                            <th className="px10 w80 text-center">Size</th>
                            <th className="px10 w85" />
                          </tr>
                        </thead>
                        <tbody
                          style={{
                            float: 'left',
                            width: '100%',
                            overflow: 'hidden',
                            maxHeight: '400px',
                            height: '400px',
                          }}
                        >
                          <InfiniteScroll
                            dataLength={this.props.items.length}
                            next={() => {
                              this.getMoreItems()
                            }}
                            hasMore={this.props.more}
                            loader={
                              <p className="px5 py5 text-center">
                                <b>Loading</b>
                              </p>
                            }
                            height={400}
                            endMessage={
                              <p className="px5 py5 text-center">
                                <b>Nothing to load</b>
                              </p>
                            }
                          >
                            {itemlist}
                          </InfiniteScroll>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-body mb-0 px30 pb0 pt0 border-0 justify-content-start">
                <div
                  className="btn btn-dark mr30 mt-0 px30 mb-0 float-left"
                  onClick={() => {
                    $('#assignItemsModalCenter').modal('hide')
                    $('#allocateItemsInstrument').modal('show')
                  }}
                >
                  Allocate items
                </div>
                <div
                  className="btn btn-light border-dark px30 mt-0 mb-0 float-left m-xst30"
                  data-dismiss="modal"
                >
                  Cancel
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <AllocateItemsProcedureInstrument
          checkItem={this.checkItem}
          checkItemExistence={this.checkItemExistence}
          setSelected={this.setSelected}
          assignNewState={this.props.assignNewState}
          selected_items={this.state.selected_items}
          un_selected_items={this.state.un_selected_items}
          item_categories={this.getCategories()}
          stage_master={this.props.stage_master}
          selected_category={this.props.selected_category}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    medical_inventories: state.item.medical_inventories,
    inventory_categories: state.item.inventory_categories,
    items: state.item.items,
    more: state.item.more,
    loading: state.item.loading,
  }
}

export default connect(
  mapStateToProps,
  {
    getItems,
    setItems,
    getMedicalInventories,
    getMedicalInventoryCategories,
    getItemsPerCategory,
    itemLoading,
  }
)(AssignItemsProcedureInstrument)
