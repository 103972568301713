import {
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGIN_USER,
  LOGIN_UPDATE,
  GET_LOGIN_USER,
  SURGEON_DECREMENT,
  SURGEON_INCREMENT,
} from '../actions/types'

const INITIAL_STATE = {
  email: '',
  password: '',
  user: {
    surgeons: 0,
  },
  error: '',
  photo: '',
  loading: false,
  token: null,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LOGIN_USER:
      return { ...state, user: action.user }
    case LOGIN_UPDATE:
      return { ...state, [action.payload.prop]: action.payload.value }
    case LOGIN_USER:
      return { ...state, loading: true, error: '' }
    case LOGIN_USER_FAIL:
      return {
        ...state,
        error: 'These credentials do not match our records.',
        password: '',
        loading: false,
      }
    case 'SET_USER':
      return { ...state, ...INITIAL_STATE, user: action.payload }
    case 'LOGOUT_USER':
      return { ...state, ...INITIAL_STATE }
    case SURGEON_DECREMENT:
      let de_user = state.user
      de_user.surgeons = de_user.surgeons - 1
      return { ...state, user: de_user }
    case SURGEON_INCREMENT:
      let in_user = state.user
      in_user.surgeons = in_user.surgeons + 1
      return { ...state, user: in_user }
    default:
      return state
  }
}
