import React, { Component } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import _ from 'lodash'
import $ from 'jquery'

export class QuicknotesSkeleton extends Component {
  
  
  constructor(props) {
    super(props)
  
    this.state = {
      array: [this.textElement(), this.mediaElement(), this.textMediaElement()],
      color: '#d2d2d2',
      highlightColor: '#dedede'
    }
  }

  
  componentWillMount() {
    if ($('body').hasClass('dark-theme')) {
      this.setState({
        color: '#1d212e',
        highlightColor: '#151821'
      })
    }
  }

  

  textElement = () => {
    return(
      <div className={'card text-preview w-100 mb10'}>
        <div className="card-header text-capitalize ">
          <Skeleton width={200} height={30} />
        </div>
            
        <div className="card-body">
          <Skeleton count={5}/>
        </div>
      </div>
    )
  }

  mediaElement = () => {
    return(
      <div className={`card media-element w-100 mb10`}>
     
        <div className="card-header">
          <Skeleton width={200} height={30}/>
        </div>
        <div className="card-body">

          <div className="row">
            <div className="col-lg-6">
              <Skeleton height={300}/>
            </div>
            <div className="col-lg-6">
              <Skeleton count={5}/>
            </div>
          </div>

          <div className="row mt10">
            <div className="ml10">
              <Skeleton height={100} width={100}/> 
            </div>
            <div className="ml10">
              <Skeleton height={100} width={100}/> 
            </div>
            <div className="ml10">
              <Skeleton height={100} width={100}/> 
            </div>
   
          </div>
        </div>
      </div>
    )
  }

  textMediaElement = () => {
    return(
      <div className={`card standard-preview mb10`}>
        <div className="card-body">
          <div className="col-lg-6 p0">
            <div className="card-header">
              <Skeleton width={200} height={30}/>
            </div>
            <div className="card-content">
              <Skeleton count={5}/>
            </div>
          </div>
          <div className="col-lg-6 p0 media-preview">
            <Skeleton height={300}/>
          </div>
        </div>
      </div>
    )
  }


  render() {
    return (
      <SkeletonTheme color={this.state.color} highlightColor={this.state.highlightColor} className="col-md-12"> 
          <div className="col-md-12 mb20 pt32">
              <Skeleton height={24} width='25%'/>
          </div>
          <div className="col-md-12">
            {
              this.state.array[0]
            }
            {
              this.state.array[1]
            }
            {
              this.state.array[2]
            } 
          </div> 
      </SkeletonTheme>
    )
  }
}

export default QuicknotesSkeleton
