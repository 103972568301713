import React, { Component } from 'react'
import axios from 'axios'
import TextInput from '../../components/commons/forms/TextInput'
import { Link } from 'react-router'
import { getError } from '../../utilities'
import Notification from '../../components/Notification'
import $ from 'jquery'
import { BASE_URL } from '../../config/constant';
import { Button } from '../../components/commons/forms'
import logo from '../../assets/images/logos/logo-mono-reverse.png'
import { ConfirmationModal } from '../../components/commons/modals'

class ConfirmResetPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: [],
      new_password: '',
      new_password_confirmation: '',
      loading: false,
      resetDetail: null,
      message: '',
    }
  }

  componentWillMount() {
    
    axios.defaults.baseURL = BASE_URL
  }
  componentDidMount = () => {
    const { token } = this.props.router.params

    axios
      .get(`api/find/${token}`)
      .then(result => {
        this.setState({
          resetDetail: result.data,
        })
      })
      .catch(error => {
        console.log('error', error)
        // this.props.router.push({
        //   location: '/login',
        // })
      })  

    $('.line-form-group-v2 input').blur((e) => {
      if (!$(e.currentTarget).val()) {
        $(e.currentTarget).removeClass('has-val')
      } else {
        $(e.currentTarget).addClass('has-val')
      }
    })
    $('.line-form-group-v2 input').blur()
  }
  onSubmit = () => {
    const { resetDetail, new_password, new_password_confirmation } = this.state

    axios
      .post(`api/reset`, {
        email: resetDetail.email,
        token: resetDetail.token,
        password: new_password,
        password_confirmation: new_password_confirmation,
      })
      .then(result => {
        console.log(result)
        this.setState(
          {
            message: 'Your Password successfully reset you can now login!',
          },
          () => {
            $('#notificationModal').modal('show')
          }
        )
      })
      .catch(error => {
        if (error.response.status == 422) {
          this.setState({
            errors: error.response.data.errors,
          })
        } else {
          this.setState(
            {
              message:
                'Something went wrong please contact the customer service',
            },
            () => {
              $('#notificationModal').modal('show')
            }
          )
        }
      })
  }
  render() {
    return (
      <main role="main" className="light-theme flex-shrink-0 d-flex login-page">
        <div className="container align-self-center">
          <div className="row justify-content-center">
            <div className="col-md-5">
              <div className="card shadow">
                <div className="card-body p30 text-center">
                  <div className="font-weight-bold d-flex justify-content-center mb30">
                    <img src={logo} className="logo" />
                  </div>
                  <div className="row">
                    <div className="col-md-10 mx-auto">
                      <p className="mx-auto mb40 bold mt0 sub-title">
                        Please enter your new password
                      </p>
                    </div>
                  </div>


                  <div className="input-holder">

                    <TextInput
                      icon={'mdi mdi-lock'}
                      label={'New password'}
                      inputType={'password'}
                      value={this.state.new_password}
                      errorMessage={getError(this.state.errors, 'password')}
                      containerClass={`mb30 text-left`}
                      onValueChange={event => {
                        this.setState({
                          new_password: event.target.value,
                        })
                      }}
                      onKeyPress={event => {
                        if (event.key == 'Enter') this.onSubmit()
                      }}
                    /> 
                  </div>


                  <div className="input-holder">
                    <TextInput
                      icon={'mdi mdi-lock'}
                      label={'Confirm new password'}
                      inputType={'password'}
                      value={this.state.new_password_confirmation}
                      errorMessage={''}
                      containerClass={`mb30 text-left`}
                      onValueChange={event => {
                        this.setState({
                          new_password_confirmation: event.target.value,
                        })
                      }}
                      onKeyPress={event => {
                        if (event.key == 'Enter') this.onSubmit()
                      }}
                    />
                  </div>

                  <div className="mb10">
                    <Button primary onClick={this.onSubmit.bind(this)} className="submit-btn">
                      Reset Password
                    </Button>
                  </div>
 

                </div>
              </div>
            </div>
          </div>
          <ConfirmationModal
            idModal="notificationModal"
            title="Password Reset"
            message={this.state.message}
            confirmText="OK"
            confirm={() => {
              this.props.router.push({
                location: '/login',
              })
            }}
          />
        </div>
      </main>
    )
  }
}

export default ConfirmResetPassword
