import React, { Component } from 'react'

export class THead extends Component {
  render() {
    const {text, sortable, className, onClick} = this.props
    return (
      <th className={`${className} ${sortable ? 'sortable-thead':''}`} onClick={onClick}>
        {text}
        {sortable ? <i className="fa fa-sort"></i> : ''}
      </th>
    )
  }
}

export default THead
