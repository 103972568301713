import React, { Component } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import $ from 'jquery'
import ReactDOMServer from 'react-dom/server'
import axios from 'axios'
import _ from 'lodash'
import { connect } from 'react-redux'
import ItemCard from '../../components/commons/cards/ItemCard'
import { Link } from 'react-router'
import Create from './Create'
import Confirmation from '../../components/Confirmation'
import ViewItemModal from '../../components/modals/ViewItemModal'
import { setItemMedicalInventory } from '../../actions/ItemAction'
import Notifier from '../../components/commons/table/Notifier'
import {
  Table,
  TRow,
  THead,
  TData,
  TableNotificationCard,
} from '../../components/commons/table'
import { ConfirmationModal, Modal } from '../../components/commons/modals'
import { Button, TextInput, Picker } from '../../components/commons/forms'
import Icon from '../../components/commons/icons/Icon'
import EndOfLoading from '../../components/commons/loading/EndOfLoading'
import ViewItemCard from '../../components/commons/cards/ViewItemCard'
import { Event } from '../../utilities/analytics'

export class Items extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      loading: false,
      page: 1,
      loading: 1,
      dataLength: 1,
      isEditMode: false,
      medical_categories: [],
      medical_inventory_name: '',
      selectedCategory: 'all',
      searchKeyword: '',
      inventory: '',
      cardView: false,
      selectedItem: {},
      sizes: [{ catalogue: '', size: '', show: true }],
      successAppear: false,
      isCreate: false,
      successMessage: '',
      isReverse: false,
      errors: [],
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      notificationType: '', //success, warning, error
      notificationMessage: '',
    }
    this.searchData = _.debounce(this.getItemResults, 600)
    this.compareBy.bind(this)
    this.sortBy.bind(this)
  }

  componentDidMount() {
    this.checkMedicalInventoryId()
    this.props.router.setRouteLeaveHook(
      this.props.route,
      this.routerWillLeave.bind(this)
    )
    this.makeRemoteRequest()
    window.onbeforeunload = this.handleOnBeforeUnload
  }

  routerWillLeave(nextLocation) {

    if (nextLocation.action === 'POP') {
      if ($('.modal').hasClass('show')) {
          $('.modal').modal('hide')
          this.props.router.replace(this.props.router.location.pathname)
          return false
        }
        return true
      }

    if (this.state.isCreate)
      return 'Your work is not saved! Are you sure you want to leave?'
  }

  handleOnBeforeUnload = e => {
    console.log('eeeeeeeeee',e)
    if (this.state.isCreate) {
      const message = 'Your work is not saved! Are you sure you want to leave?'
      e.returnValue = message
      return message
    }
  }

  checkMedicalInventoryId = () => {
    let medical_inventory_id = null
    switch (this.props.params.inventory) {
      case 'supplies':
        medical_inventory_id = 1
        break
      case 'instruments':
        medical_inventory_id = 2
        break
      case 'equipments':
        medical_inventory_id = 3
        break
      default:
        medical_inventory_id = 1
        break
    }

    console.log(medical_inventory_id)
    this.props.setItemMedicalInventory(medical_inventory_id)
  }

  compareBy(key) {
    if (key == 'category') {
      return function(a, b) {
        if (a.category.name < b.category.name) return -1
        if (a.category.name > b.category.name) return 1
        return 0
      }
    }
    return function(a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }

  sortBy(key) {
    let arrayCopy = [...this.state.data]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }
    this.setState({ data: arrayCopy, isReverse: !this.state.isReverse })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      isCreate: false,
      selectedItem: {},
    })
    this.checkMedicalInventoryId()
    if (nextProps.params.inventory === this.props.params.inventory) {
      return
    } else {
      this.refresh()
    }
  }

  addSizesSubmit() {
    const { selectedItem, sizes } = this.state

    const form = new FormData()
    sizes.map(data => {
      if (data.show) {
        form.append('catalog_number[]', data.catalogue)
        form.append('size[]', data.size)
      }
    })
    axios
      .post(`v1/save-duplicate-supply/${selectedItem.id}`, form)
      .then(result => {
        if (result.data.success) {
          $('.modal').modal('hide')
          this.setState({
            successAppear: true,
            successMessage: 'Items have been successfully duplicated!',
            notificationType: 'success',
            notificationMessage: 'Items have been successfully duplicated!',
            show: true,
            data: [...result.data.data, ...this.state.data],
            sizes: [
              {
                catalogue: '',
                size: '',
                show: true,
              },
            ],
          })
          $('#sizesModalCenter').modal('hide')
          setTimeout(() => {
            this.itemTableNotif.showThis()
          }, 100)
        } else {
          console.log(result.data.errors)
          this.setState(
            {
              errors: result.data.errors,
            },
            () => {
              console.log('errors', this.state.errors)
            }
          )
        }
      })
      .catch(error => {})
  }

  addMoreSizes() {
    const { sizes } = this.state
    sizes.push({ catalogue: '', size: '', show: true })
    this.setState({
      sizes: sizes.splice(0),
    })
  }

  sizeRemove(index) {
    const { sizes } = this.state
    //sizes[index].show = false
    sizes.splice(index, 1)
    this.setState({
      sizes: sizes.splice(0),
    })
  }

  setEditMode(value) {
    console.log(value)
    this.setState({
      isEditMode: value,
      cardView: false,
    })
  }

  makeRemoteRequest = () => {
    this.setState({ loading: true })
    let inventory = ''
    console.log('propsss here', this.props.params.inventory)
    if (this.props.params.inventory == 'supplies') {
      inventory = 1
      this.setState({
        medical_inventory_name: 'Supply',
      })
    } else if (this.props.params.inventory == 'instruments') {
      inventory = 2
      this.setState({
        medical_inventory_name: 'Instrument',
      })
    } else if (this.props.params.inventory == 'equipments') {
      inventory = 3
      this.setState({
        medical_inventory_name: 'Equipment',
      })
    }
    this.setState(
      {
        inventory,
      },
      () => {
        axios
          .get(`api/medical-categories-by-inventory/${inventory}`)
          .then(result => {
            console.log(result)
            this.setState(
              {
                medical_categories: result.data.data,
              },
              () => {
                this.getItemResults()
              }
            )
          })
          .catch(error => {
            console.log(error.response)
          })
      }
    )

    console.log('props')

    console.log(this.props)

    this.props.setItemMedicalInventory(inventory, this.props.params.inventory)
  }

  getItemResults = () => {
    const { page, selectedCategory, searchKeyword, inventory } = this.state

    let url = `v1/items?medical_inventory_id=${inventory}&medical_category_id=${selectedCategory}&query=${searchKeyword}&paginate=15&page=${page}`
    axios
      .get(url)
      .then(res => {
        this.setState({
          data:
            page === 1 ? res.data.data : [...this.state.data, ...res.data.data],
          loading: false,
          hasMore: res.data.links.next != null,
          dataLength: res.data.data.length,
        })
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
        this.setState({ loading: false, hasMore: false })
      })
  }

  deleteItem() {
    const { selectedItem } = this.state
    axios
      .post(`api/items/${selectedItem.id}`, {
        _method: 'DELETE',
      })
      .then(() => {
        this.setState({
          data: this.state.data.filter(i => i.id != selectedItem.id),
          isCreate: false,
          successAppear: true,
          successMessage: 'Items have been successfully deleted!',
          show: true,
          notificationType: 'success',
          notificationMessage: 'Item have been successfully deleted',
        })
        setTimeout(() => {
          this.itemTableNotif.showThis()
        }, 100)
      })
      .catch(error => {
        console.log(error.response)
      })
  }

  addItemSucces(item) {
    const { data } = this.state
    data.unshift(item)
    this.setState({
      data: data.splice(0),
      isCreate: false,
      successAppear: true,
      successMessage: 'Items have been registered successfully',
      notificationType: 'success',
      notificationMessage: 'Items have been registered successfully',
    })
    setTimeout(() => {
      this.itemTableNotif.showThis()
    }, 100)
  }

  editItemSuccess(item) {
    console.log('edit', item)
    const { selectedItem } = this.state
    console.log('edsuccess')

    this.setState(
      {
        data: this.state.data.filter(i => i.id != selectedItem.id),
      },
      () => {
        const { data } = this.state

        data.unshift(item)

        this.setState({
          data: data.splice(0),
          isCreate: false,
          successAppear: true,
          successMessage: 'Items have been registered successfully',
          notificationType: 'success',
          notificationMessage: 'Item have been updated successfully',
        })
        setTimeout(() => {
          this.itemTableNotif.showThis()
        }, 100)
      }
    )
  }

  loadMoreData() {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => {
        this.getItemResults()
      }
    )
  }
  refresh() {
    this.setState(
      {
        page: 1,
        loading: true,
        data: [],
        searchKeyword: '',
        selectedCategory: 'all',
      },
      () => {
        this.getItemResults()
      }
    )
  }

  renderTags(data) {
    return ReactDOMServer.renderToStaticMarkup(
      data.map(data => {
        return (
          <span
            key={`tag_${data.id}`}
            className="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10"
          >
            {data.name}
          </span>
        )
      })
    )
  }

  categoryChange(event) {
    console.log()
    event.preventDefault()
    this.setState(
      {
        page: 1,
        loading: true,
        data: [],
        selectedCategory: event.target.value,
      },
      () => {
        this.getItemResults()
      }
    )
  }

  searchItem({ target: { value } }) {
    this.setState(
      {
        searchKeyword: value,
        page: 1,
        loading: true,
        data: [],
      },
      () => {
        this.searchData()
      }
    )
  }

  renderTable() {
    return (
      <div className="col-md-12">
        <Table
          minWidth={`${this.state.isEditMode ? 1060 : 760}`}
          renderModal={() => {
            return (
              <ViewItemCard
                id={`preview-modal-${this.state.unique}`}
                onRef={ref => (this.viewItemRef = ref)}
                item={this.state.selectedItem}
              ></ViewItemCard>
            )
          }}
          className="infi-table"
          infiniteScroll
          infiniteHeight={600}
          infiniteDataLength={this.state.data.length} //This is important field to render the next data
          infiniteNext={this.loadMoreData.bind(this)}
          infiniteHasMore={this.state.hasMore}
          endOfMessageTitle="Nothing else to see..."
          endOfMessageMessage="Missing something? Add new items via"
          endOfMessageLinkMessage="Edit database"
          endOfMessageOnClick={this.setEditMode.bind(this, true)}
          loading={this.state.loading}
          renderHeaders={() => {
            return (
              <TRow>
                <THead
                  className="w150"
                  text="Catalogue #"
                  sortable
                  onClick={() => this.sortBy('catalog_number')}
                />
                <THead
                  className="w240"
                  text="Name"
                  sortable
                  onClick={() => this.sortBy('name')}
                />
                <THead
                  className="w150"
                  text="Company"
                  sortable
                  onClick={() => this.sortBy('company')}
                />
                <THead
                  className="w150"
                  text="Category"
                  sortable
                  onClick={() => this.sortBy('category')}
                />
                <THead
                  className="w100 text-center"
                  text="Size"
                  sortable
                  onClick={() => this.sortBy('size')}
                />

                {this.state.isEditMode ? (
                  <THead className="w270" text="Edits" />
                ) : (
                  ''
                )}
              </TRow>
            )
          }}
          renderRows={() => {
            let rows = this.state.data.map(item => {
              return (
                <TRow>
                  <TData className="w150">{item.catalog_number}</TData>
                  <TData
                    className="w240 has-action"
                    item={item}
                    popover
                    onClick={() => {
                      this.setState({ selectedItem: item })
                      $(`#preview-modal-${this.state.unique}`).modal('show')
                      Event('Medical Inventory','Click',`[${item.id}] - ${item.name}  [Size ${item.size}]`)
                    }}
                  >
                    {item.name}
                  </TData>
                  <TData className="w150">
                    {item.company ? item.company : ''}
                  </TData>
                  <TData className="w150">
                    {item.category ? item.category.name : ''}
                  </TData>
                  <TData className="w100 text-center">{item.size}</TData>

                  {this.state.isEditMode ? (
                    <TData className="w270 text-center actions">
                      <div className="d-flex justify-content-start">
                        <Button
                          tertiary
                          onClick={event => {
                            event.preventDefault()
                            this.setState({
                              selectedItem: item,
                              isCreate: true,
                            })
                          }}
                        >
                          Edit content
                        </Button>
                        <Button
                          tertiary
                          datatarget="#sizesModalCenter"
                          datatoggle="modal"
                          onClick={() =>
                            this.setState({
                              selectedItem: item,
                              sizes: [
                                {
                                  catalogue: '',
                                  size: '',
                                  show: true,
                                },
                              ],
                              errors: [],
                            })
                          }
                        >
                          Add sizes
                        </Button>
                        {item.manageable ? (
                          <Button
                            tertiary
                            datatarget="#confirmModal"
                            datatoggle="modal"
                            onClick={() =>
                              this.setState({ selectedItem: item })
                            }
                          >
                            Delete
                          </Button>
                        ) : null}
                      </div>
                    </TData>
                  ) : (
                    ''
                  )}
                </TRow>
              )
            })
            return rows
          }}
          renderNotification={() => {
            return (
              <TableNotificationCard
                type={this.state.notificationType}
                message={this.state.notificationMessage}
                onRef={ref => (this.itemTableNotif = ref)}
              />
            )
          }}
        />
      </div>
    )
  }

  renderCard = () => {
    const cards = this.state.data.map(item => {
      return (
        <div className="col-lg-3 col-md-4 col-sm-6">
          <ItemCard
            key={`item_card_${item.id}`}
            item={item}
            preview={true}
            onClick={() => {
              this.setState({
                selectedItem: item,
              })
              $(`#preview-modal2-${this.state.unique}`).modal('show')
            }}
            style={{ height: 'calc(100% - 20px)' }}
          />
        </div>
      )
    })
    return (
      <>
        <ViewItemCard
          id={`preview-modal2-${this.state.unique}`}
          onRef={ref => (this.viewItemRef = ref)}
          item={this.state.selectedItem}
        ></ViewItemCard>
        <div className="d-flex flex-wrap">{cards}</div>
      </>
    )
  }

  renderView() {
    if (this.state.cardView) {
      return (
        <InfiniteScroll
          className=""
          dataLength={this.state.data.length} //This is important field to render the next data
          next={this.loadMoreData.bind(this)}
          hasMore={true}
        >
          {this.renderCard()}
        </InfiniteScroll>
      )
    }
    return this.renderTable()
  }

  changeView() {
    this.setState({
      cardView: !this.state.cardView,
    })
  }
  toggleIcon() {
    console.log('this.state.cardView', this.state.cardView)
    return (
      <div className="p-0 pt6 mt29 m-xst0 m-smt0 m-mdt0">
        {this.state.cardView ? (
          <Icon icon="list" onClick={this.changeView.bind(this)} />
        ) : (
          <Icon icon="card-view" onClick={this.changeView.bind(this)} />
        )}
      </div>
    )
  }
  onSearch = () => {
    this.setState({})
  }
  renderIndex() {
    const categories = this.state.medical_categories.map(item => {
      return (
        <option value={item.id} key={`item_${item.id}`}>
          {item.name}
        </option>
      )
    })

    return (
      <div className="container">
        {/* TITLE ROW */}

        <div className="row">
          <div className="col-md-12">
            <h1 className="page-title">
              {this.props.params.inventory.charAt(0).toUpperCase() +
                this.props.params.inventory.slice(1)}{' '}
              database
            </h1>

            {/*  <p className="page-detail">
              [Define what supplies is and what items are to be found]
            </p> */}
          </div>
        </div>

        {/* END TITLE ROW */}

        <div className="row page-toolbar">
          <div className="col-lg-3 col-md-6 col-lg-3 col-md-6 d-flex align-items-end sub-div">
            <TextInput
              id="search_items"
              inputClass="active"
              placeholder="Start typing name, catalogue #…"
              onChange={this.searchItem.bind(this)}
              label="Search"
              value={this.state.searchKeyword}
            />
          </div>
          <div className="col-lg-4 col-md-6 col-lg-3 col-md-6 d-flex align-items-end sub-div">
            <Picker
              onChange={this.categoryChange.bind(this)}
              label="Filter supplies by"
            >
              <option value="all">All</option>
              {categories}
            </Picker>
          </div>

          {this.state.isEditMode ? (
            <div className="col ml-auto text-right d-flex justify-content-end justify-content-lg-end align-items-start has-expand">
              <Button
                secondary
                expand
                onClick={event => {
                  event.preventDefault()
                  this.setState({
                    isCreate: true,
                    selectedItem: null,
                  })
                }}
              >
                Register new item
              </Button>

              <Button
                primary
                expand
                onClick={this.setEditMode.bind(this, false)}
              >
                Update
              </Button>
            </div>
          ) : (
            <div className="col ml-auto text-right d-flex justify-content-end justify-content-lg-end align-items-start">
              <Button secondary onClick={this.setEditMode.bind(this, true)}>
                Edit database
              </Button>

              <div className="p-0 ml-3 ml-lg-0">
                {this.state.cardView ? (
                  <Icon
                    icon="list"
                    onClick={this.changeView.bind(this)}
                    key="list-icon-items"
                  />
                ) : (
                  <Icon
                    icon="card-view"
                    onClick={this.changeView.bind(this)}
                    key="card-icon-items"
                  />
                )}
              </div>
            </div>
          )}
        </div>

        <div className="row mt0 mb16 d-flex flex-column">
          {this.renderView()}
        </div>
      </div>
    )
  }

  getArrayError(field, index) {
    const { errors } = this.state

    if (errors[`${field}.${index}`]) {
      return errors[`${field}.${index}`][0]
    }
    return ''
  }

  render() {
    const filter = this.state.sizes.filter(data => {
      return data.show
    })

    const sizes = filter.map((data, index) => {
      return (
        <div className="row" key={`size_item_${index}`}>
          <div className="col-xl-10 col-lg-9 col-md-8 col-9 card-body">
            <div className="row">
              <div className="col-lg-8 col-y-7">
                <TextInput
                  errorMessage={this.getArrayError('catalog_number', index)}
                  label="Catalog"
                  defaulValue={''}
                  value={data.catalogue}
                  onChange={event => {
                    const { sizes } = this.state
                    sizes[index].catalogue = event.target.value
                    this.setState({
                      sizes: sizes.splice(0),
                    })
                  }}
                />
              </div>
              <div className="col-lg-4 col-y-5">
                <TextInput
                  errorMessage={
                    this.getArrayError('size', index) ? 'Required' : ''
                  }
                  label="Size"
                  defaulValue={''}
                  value={data.size}
                  onChange={event => {
                    const { sizes } = this.state
                    sizes[index].size = event.target.value ? event.target.value :'     '
                    this.setState({
                      sizes: sizes.splice(0),
                    })
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-4 d-flex align-items-center col-3">
            {index != 0 ? (
              <a
                href="javascript:;"
                className="cta cta-tertiary"
                onClick={event => {
                  event.preventDefault()
                  this.sizeRemove(index)
                }}
              >
                <i className="mdi mdi-trash-can"></i>
              </a>
            ) : (
              ''
            )}
          </div>
        </div>
      )
    })
    if (this.state.isCreate)
      return (
        <Create
          addItemSucces={item => this.addItemSucces(item)}
          editItemSuccess={item => this.editItemSuccess(item)}
          modalCancel={() => {
            this.setState({
              isCreate: false,
            })
          }}
          item={this.state.selectedItem}
          medicalInventoryName={this.state.medical_inventory_name}
        />
      )
    return (
      <div className="section">
        <ConfirmationModal
          idModal="confirmModal"
          title="Delete Item"
          message="Deleting item will remove it from the database and will have to be added back manually."
          confirmText="Delete item"
          cancelText="Cancel"
          confirm={this.deleteItem.bind(this)}
          cancel={() => {
            $('#confirmModal').modal('hide')
          }}
        />

        <ViewItemModal
          key={this.state.unique}
          item={this.state.selectedItem}
          cancel={() => {
            $('viewItemModal').modal('hide')
          }}
        />
        <Modal
          idModal="sizesModalCenter"
          size="modal-sm"
          title="Add sizes"
          confirmText="Add"
          cancelText="Cancel"
          confirm={e => {
            this.addSizesSubmit()
          }}
          cancel={e => {
            //fn here
          }}
        >
          <p className="modal-sub-title mb12">
            Adding additional sizes for
            <b>
              &nbsp;
              {this.state.selectedItem ? this.state.selectedItem.name : ''}
            </b>
            <br /> <br />
            <b>Catalogue</b>{' '}
            {this.state.selectedItem
              ? this.state.selectedItem.catalog_number
              : ''}
            &nbsp;&nbsp;&nbsp;
            <b>Size</b>{' '}
            {this.state.selectedItem ? this.state.selectedItem.size : ''}
          </p>
          <div className="add-size-card">
            {sizes}
            <div className="row">
              <div
                className="col-xl-10 col-lg-9 col-md-8 add-card"
                onClick={this.addMoreSizes.bind(this)}
              >
                Add another size +
              </div>
            </div>
          </div>
        </Modal>

        {this.renderIndex()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    medical_inventory_id: state.item.medical_inventory_id,
  }
}

export default connect(
  mapStateToProps,
  { setItemMedicalInventory }
)(Items)
