import React, { Component } from 'react'
import Icon from '../icons/Icon';
import tippy from 'tippy.js'

export class PopoverIcon extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      tippyInstance: [],
      unique:
        Math.random()
          .toString(36)
          .substring(2, 12) +
        Math.random()
          .toString(36)
          .substring(2, 11),
    }
  }

  popOverItem() {
    const { title, content } = this.props 

    let usedForContent = `<div class="row">
                        <div class="col-12">
                            <h3 class="title">${title}</h3>
                            <div class="description">
                            <p>
                                ${content}
                            </p> 
                            </div>
                        </div>
                      </div>`;

    return `<div class="popover-item-card" style="width: 450px"> 
              ${usedForContent} 
            </div>`;
  }
  createTippy() { 
    if (this.state.tippyInstance.length == 0) {
      this.setState({
        tippyInstance: tippy(`#${this.state.unique}`, {
          trigger: 'click',
          placement: 'right',
          arrow: true,
          theme: "material",
          boundary: "window",
          duration: [100, 100],
          content: this.popOverItem(this.props.item),
          distance: 24, 
        })
      })
    }  
  } 
  componentDidMount() {
    this.createTippy()
  }

  render() { 
    return (
      <div className="popover icon ml-auto c-pointer position-relative" style={{background:'transparent'}} id={this.state.unique}>
        <i className="mdi mdi-information-outline fs25"></i>
      </div>
    )
  }
}

export default PopoverIcon
