import axios from 'axios'
import CKEditor from 'ckeditor4-react'
import $ from 'jquery'
import { Cropper } from 'rc-cropping'
import Dialog from 'rc-dialog'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SortableHandle } from 'react-sortable-hoc'
import VideoThumbnail from 'react-video-thumbnail'
import { progressVideo } from '../../../actions/VideoAction'
import img from '../../../assets/images/img.png'
import placeholder_img_dark from '../../../assets/images/add-media-placeholder-dark.png'
import placeholder_img from '../../../assets/images/add-media-placeholder.png'


import placeholder_img_dark_err from '../../../assets/images/add-media-placeholder-dark-err.png'
import placeholder_img_err from '../../../assets/images/add-media-placeholder-err.png'

import { getElementError } from '../../../utilities'
import { uploadImage } from '../../../utilities/upload-image'
import { uploadVideo } from '../../../utilities/upload-video'
import ImageCropper from '../../commons/elements/inputs/ImageCropper'
import { TextInput } from '../../commons/forms'
import Icon from '../icons/Icon'
import CircleLoading from '../loading/CircleLoading'
import InfoLabel from './InfoLabel'
const DragHandle = SortableHandle(() => {
  // let randomKey =
  //   Math.random()
  //     .toString(36)
  //     .substring(2, 12) +
  //   Math.random()
  //     .toString(36)
  //     .substring(2, 15)
  // return (
  //   <a href="#" className="move-icon" key={randomKey}>
  //     <Icon icon="move-icon" className="negative" height={30} width={15} />
  //   </a>
  // )
  return (
    <a
      href="javascript:;"
      onClick={event => {
        event.preventDefault()
      }}
      className="move-icon"
    >
      <Icon icon="Move" className="negative" height={30} width={30} />
    </a>
  )
})

export class StandardEditor extends Component {
  constructor(props) {
    super(props)

    this.state = {
      file: null,
      file_raw: '',
      croppedFile: null,
      show: false,
      filename: '',
      selected: null,
      image: img,
      type: 'image',
      unique:
        Math.random()
          .toString(36)
          .substring(2, 11) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      loading: false,
      progress: 0,
      xhr: new XMLHttpRequest(),
      source: '',
    }

    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount = () => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    this.setState({
      source: source,
    })

    if(this.props.elementRef){
      this.props.elementRef(this.refs.element)
    }
  }

  handleClick = e => {
    this.refs.file.click()
  }

  onFileChange = () => {
    if (this.refs.file.files[0].type.split('/')[0] == 'image') {
      $(`#imageCropperStandard${this.state.unique}`).modal('show')
      var reader = new FileReader()
      var url = reader.readAsDataURL(this.refs.file.files[0])

      reader.onloadend = () => {
        this.setState({
          file_raw: reader.result,
          type: 'image',
          // image: reader.result
        })
      }
    } else {
      if (!this.refs.file.files[0]) {
        this.setState({
          show: false,
          file: null,
          croppedFile: null,
          isNext: false,
          page: 1,
        })
        return
      }
      this.setState(
        {
          loading: true,
          progress: 0,
        },
        () => {
          this.props.progressVideo()
        }
      )
      const fileData = this.refs.file.files[0]

      uploadVideo(
        fileData,
        progress => {
          this.setState({
            progress: Math.round((progress.loaded * 100) / progress.total),
          })
        },
        this.state.source.token
      )
        .then(result => {
          this.props.progressVideo()
          console.log(' this.props', result)
          if (typeof this.props.thumbnailChange === 'function') {
            this.props.thumbnailChange(result.thumbnail)
            console.log('this.props.thumbnailChange(result.thumbnail)')
          } else {
            this.props.onStandardThumbnailChange(result.thumbnail)
            console.log(
              'this.props.onStandardThumbnailChange(result.thumbnail)'
            )
          }

          this.props.onMediaChange({
            data: result.source,
            type: 'video',
          })
          this.setState(
            {
              loading: false,
              progress: 0,
            },
            () => {
              this.props.progressVideo()
            }
          )
        })
        .catch(error => {
          console.log('Request ', error)

          this.setState(
            {
              loading: false,
              progress: 0,
            },
            () => {
              this.props.progressVideo()
            }
          )
          if (axios.isCancel(error)) {
            console.log('Request canceled', error.message)
          } else {
          }
        })

      return

      const reader = new FileReader()
      reader.readAsDataURL(this.refs.file.files[0])
      reader.onload = e => {
        this.props.onMediaChange({
          data: e.target.result,
          type: 'video',
        })
        this.setState({
          show: false,
          file: null,
          croppedFile: null,
          isNext: false,
          page: 1,
          type: 'video',
        })
        return
      }
    }
  }

  croppeFile = fileBlob => {
    if (!fileBlob) {
      this.setState({
        show: false,
        file: null,
        croppedFile: null,
      })
      return
    }

    const reader = new FileReader()
    reader.readAsDataURL(fileBlob)
    reader.onload = () => {
      this.props.onMediaChange({ data: reader.result, type: 'image' })
      this.setState({
        show: false,
        file: null,
        croppedFile: null,
        image: reader.result,
      })
    }
  }

  renderVideoPreview(passed_data) {
    console.log('passed_data', passed_data)

    let data = passed_data.media ? passed_data.media : passed_data
    return data.disk == 'vimeo' ? (
      <img src={data.thumbnail} />
    ) : (
      <VideoThumbnail
        videoUrl={data.source}
        thumbnailHandler={thumbnail =>
          this.props.onStandardThumbnailChange(thumbnail)
        }
      />
    )
  }

  renderCropper() {
    const { file, loading, progress } = this.state
    const { data, validation } = this.props

    let error = getElementError(
      validation.errors,
      validation.prefix,
      validation.index,
      validation.itemable ? 'images' : 'media-value'
    )

    if (loading) return <CircleLoading progress={progress} />

    if (!file) {
      if (data.media || data.source) {
        return (
          <InfoLabel
            className={error ? 'is-invalid' : ''}
            style={
              error
                ? { border: '2px dashed red' }
                : { border: '2px dashed rgb(132, 134, 136)' }
            }
          >
            {error ? (
              <>
                {/* {' '}
                <span className="bold text-danger pb40">
                  {' '}
                  Image or Video is required!{' '}
                </span>{' '}
                <span>(Click here to add image or video)</span> */}

                <img src={localStorage.getItem('darkmode') == 'true' ? placeholder_img_dark_err : placeholder_img_err} />
              </>
            ) : (
                <img src={localStorage.getItem('darkmode') == 'true' ? placeholder_img_dark:placeholder_img} />
            )}

            <input
              type="file"
              name="image"
              accept="video/*,image/*"
              style={{ zIndex: -1, position: 'absolute' }}
              onChange={this.onFileChange}
              ref="file"
              required
            />
            <img src={data.media ? data.media.thumbnail : data.thumbnail} />
          </InfoLabel>
        )
      }

      return (
        <InfoLabel
          style={
            error
              ? { border: '2px dashed red' }
              : { border: '2px dashed rgb(132, 134, 136)' }
          }
        >
          <span> Add image or video + </span>
          <input
            type="file"
            name="image"
            accept="video/*,image/*"
            className="w300"
            style={{ zIndex: -1, position: 'absolute' }}
            onChange={this.onFileChange}
            ref="file"
            required
          />
        </InfoLabel>
      )
    } else {
      if (this.state.show) {
        return (
          <Cropper
            size={[80, 45]}
            file={this.state.file}
            getSpinContent={() => <span>loading...</span>}
            renderModal={() => <Dialog className="modal-dialog-centered" />}
            onChange={this.croppeFile}
            circle={false}
            options={{
              aspectRatio: 7 / 4,
            }}
          />
        )
      }
    }
  }

  render() {
    const { data, validation } = this.props
    let items = this.props.mentions ? this.props.mentions : []

    let dataFeed = (opts, callback) => {
      var matchProperty = 'name',
        data = items.filter(function(item) {
          return (
            item[matchProperty]
              .toLowerCase()
              .indexOf(opts.query.toLowerCase()) !== -1
          )
        })
      callback(data)
    }
    return (
      <div className="card standard-editor" ref='element'>
        <video id={`video-${this.state.unique}`} className="d-none" controls>
          <source src="" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="card-header">
          <DragHandle />
          <a
            href="#"
            className={`copy-icon ${this.state.loading ? 'disabled' : ''}`}
            onClick={event => {
              event.preventDefault()
              this.props.onDuplicate(event)
            }}
          >
            <Icon icon="Copy-2" className="negative" height={30} width={30} />
          </a>
          <a
            href="#"
            className="trash-icon"
            onClick={event => {
              event.preventDefault()
              this.setState(
                {
                  loading: false,
                  progress: 0,
                },
                () => {
                  this.props.progressVideo()
                  this.props.onDelete(event)
                  const { source } = this.state
                  source.cancel('Operation canceled by the user.')
                }
              )
            }}
          >
            <Icon icon="Delete" className="negative" height={30} width={30} />
          </a>
        </div>
        <div className="card-body">
          <div className="row pt12">
            <div className="col-lg-5 col-md-6">
              <TextInput
                value={this.props.data.title ? this.props.data.title : ''}
                placeholder={'Title here...'}
                errorMessage={
                  getElementError(
                    validation.errors,
                    validation.prefix,
                    validation.index,
                    validation.field
                  )
                    ? 'This field is required!'
                    : ''
                }
                errorClass="mt0"
                containerClass={`mb0 w-100 non-rounded-input`}
                onValueChange={event => {
                  this.props.onTitleChange(event)
                }}
                label={
                  <>
                    Title <span className="text-danger ml4">*</span>
                  </>
                }
                inputClass="has-val active show-placeholder"
                labelClass="bolder"
              />
            </div>
          </div>
        </div>
        <div className="card-body standard-body">
          <div className="card-body editor-body">
            <CKEditor
              data={data.caption ? data.caption : data.text}
              onChange={this.props.onStandardTextChange}
              config={{
                pasteFilter: 'h1 h2 h3 h4 h5 h6 p ul ol li; img[!src, alt]; a[!href]',
                allowedContent: {
                  $1: {
                    attributes: true,
                    styles: true,
                    classes: true,
                  },
                },
                extraPlugins: 'mentions',
                toolbar: [
                  ['Bold', 'Italic', 'Underline', '-'],
                  [
                    'NumberedList',
                    'BulletedList',
                    '-',
                    // 'Outdent',
                    // 'Indent',
                    // '-',
                    'JustifyLeft',
                    'JustifyCenter',
                    'JustifyRight',
                    // 'JustifyBlock',
                    '-',
                  ],
                  [
                    //'Table', 'HorizontalRule',
                    'Link',
                    // 'Smiley',
                  ],
                  ['FontSize'],
                  ['TextColor', 'BGColor'],
                ],

                mentions: [
                  {
                    feed: dataFeed,
                    itemTemplate:
                      '<li data-id="{id}">' +
                      '<strong class="username">{name}</strong>' +
                      '<span class="fullname"> ({catalog_number})</span>' +
                      '</li>',
                    outputTemplate: `<a href="#" class="tippy-hover" data-id="{id}">  
                                {name}
                              </a>`,
                    minChars: 1,
                  },
                ],
                removePlugins: 'elementspath',
                resize_enabled: false,
              }}
            />
          </div>
          <div className="card-body img-body d-flex justify-content-center align-items-center ">
            {this.renderCropper()}
          </div>
        </div>

        <ImageCropper
          key={this.state.unique}
          id={`imageCropperStandard${this.state.unique}`}
          image={this.state.file_raw}
          aspectRatio={3 / 2}
          width={720}
          height={480}
          getImage={image => {
            this.setState(
              {
                loading: true,
                progress: 0,
              },
              () => {
                this.props.progressVideo()
              }
            )
            uploadImage(
              image,
              progress => {
                this.setState({
                  progress: Math.round(
                    (progress.loaded * 100) / progress.total
                  ),
                })
              },
              this.state.source.token
            )
              .then(result => {
                this.props.onMediaChange({ data: result.data, type: 'image' })

                this.props.thumbnailChange(image)
                this.setState(
                  {
                    loading: false,
                    progress: 0,
                  },
                  () => {
                    this.props.progressVideo()
                  }
                )
              })
              .catch(error => {
                this.setState(
                  {
                    loading: false,
                    progress: 0,
                  },
                  () => {
                    this.props.progressVideo()
                  }
                )
              })
            this.setState({
              show: false,
              file: null,
              croppedFile: null,
              isNext: false,
              page: 1,
            })
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

export default connect(mapStateToProps, {
  progressVideo,
})(StandardEditor)
