import { combineReducers } from 'redux'
import SpecialtyReducer from './SpecialtyReducer'
import SurgeonReducer from './SurgeonReducer'
import ItemReducer from './ItemReducer'
import QuicknotesReducer from './QuicknotesReducer'
import UserReducer from './UserReducer'
import PositioningReducer from './PositioningReducer'
import PaymentReducer from './PaymentReducer'
import TrayReducer from './TrayReducer'
import VideoReducer from './VideoReducer'

const rootReducer = combineReducers({
  specialty: SpecialtyReducer,
  surgeon: SurgeonReducer,
  item: ItemReducer,
  quicknotes: QuicknotesReducer,
  auth: UserReducer,
  positioning: PositioningReducer,
  payment: PaymentReducer,
  tray: TrayReducer,
  video:VideoReducer
})

export default rootReducer
