import arrayMove from 'array-move'
import axios from 'axios'
import $ from 'jquery'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { getError } from '../../utilities'
import { Event } from '../../utilities/analytics'
import { procedureAction } from '../../utilities/notifier'
import DataCard from '../commons/cards/DataCard'
import ViewItemCard from '../commons/cards/ViewItemCard'
import { Button, TextInput } from '../commons/forms'
import Icon from '../commons/icons/Icon'
import EditMenu from '../commons/misc/EditMenu'
import { ConfirmationModal, FormModal } from '../commons/modals'
import Notifier from '../commons/notifier/Notifier'
import Collapsible from '../commons/panel/Collapsible'
import { Table, TData, THead, TRow } from '../commons/table'
import { hideLoader, setLoaderProg, showLoader } from '../loader/loading_screen'
import AssignItemsProcedure from '../modals/AssignItemsProcedure'
import ConsumableSkeleton from '../skeletons/ConsumableSkeleton'

const SortableItem = SortableElement(({ item, setNewState }) => (
  <li className="draggable-item">
    <div className="icon-holder">
      <Icon icon="move-icon" height={28} width={15} className="negative" />
    </div>
    <span className="pl10 work-break-all">{item.name}</span>
    <div className="action-holder">
      <Button tertiary datatoggle="modal"
        datatarget="#confirmationDeleteSectionModal"
        onClick={e => {
          e.preventDefault()
          setNewState({
            section: item,
          })
        }}><i className="mdi mdi-trash-can-outline fs20"></i></Button>
    </div>

  </li>
))

const SortableList = SortableContainer(({ items, setNewState }) => {
  return (
    <ul className="list-unstyled mb0 p0 d-flex" style={{flexWrap: 'wrap'}}>
      {items.map((item, index) => {
        return (
          <SortableItem
            key={`item-${index}`}
            index={index}
            axis="xy"
            item={item}
            setNewState={setNewState}
          />
        )
      })}
    </ul>
  )
})

export class ConsumableLayout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected_items: [],
      create_category: false,
      section_name: '',
      section: {},
      errors: [],
      change: false,
      edit: false,
      selected_flag: false,
      detail_stage: [],
      custom_stage: [],
      selectedItem: null,
      draft_success: false,
      showNotification: false,
      notificationMessage: '',
      activeIndex: null,
      deleteCategoryId: null,
      deleteItemId: null,
      selectedCategory: null,
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      adding_category: false,
    }

    this.toggleShow = this.toggleShow.bind(this)
  }

  toggleShow(val, message = '') {
    this.setState({
      showNotification: val,
      notificationMessage: message
    })
  }

  componentDidMount = () => {
    this.scrollToTop()
    if (this.props.enableDraft) {
      this.setState({
        edit: true,
        draft_success: false
      })
      this.props.getProcedureDetail(1)

    }
    this.props.setNewState({
      settings: false
    })
  }
  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  componentDidUpdate() {

    $("#confirmRemoveItem").on('shown.bs.modal', function (e) {
      e.stopPropagation()
    });
    $("#confirmRemoveItem").on('show.bs.modal', function (e) {
      e.stopPropagation()
    });
    
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.stage_master != nextProps.stage_master) {
      if (!nextProps.stage_master.draft) {
        this.setState({
          edit: false,
          item_list: nextProps.item_list
        })
      }
    }

    if (!this.state.selected_flag) {
      this.generateSelected(nextProps.stage_details)
    }

    if (nextProps.stage_details != this.props.stage_details) {
      this.setState({
        detail_stage: nextProps.stage_details.filter(item => {
          return item.default_value == 1
        }),
        custom_stage: nextProps.stage_details.filter(item => {
          return item.default_value == 0
        }),
      })
    }
  }

  scrollToTop = () => {
    $('.scroll-to-top').fadeOut(0)
    $(window).scroll(function () {
      if ($(this).scrollTop() > 350) {
        $('.scroll-to-top').fadeIn()
      } else {
        $('.scroll-to-top').fadeOut()
      }
    })

    $(document).on('click', '.scroll-to-top', function () {
      $('html, body').animate({ scrollTop: $('#home').offset().top - 100 }, 400)
      return false
    })

    setTimeout(() => {
      $('[data-toggle="popover"]').popover()
      $('.popover').popover('hide')
    }, 200);
  }

  addSection = () => {
    const { stage_master } = this.props
    this.setState({
      errors: [],
      adding_category: true
    })

    const data = {
      name: this.state.section_name,
      stage_id: stage_master.id,
      create_category: !this.state.create_category
    }

    axios.post(`api/client/stage-consumable`, data).then((result) => {
      this.setState({
        create_category: !this.state.create_category,
        adding_category: false
      })
      $("#addNewCategory").modal('hide')
      this.props.getProcedureDetail(1)
    }).catch(error => {
      console.log(error)
      console.log(error.response)
      this.setState({
        errors: error.response.data.errors,
        adding_category: false
      })
    })
  }
  sortBy(key) {
    let arrayCopy = [...this.state.selected_items]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }

    this.setState({ selected_items: arrayCopy, isReverse: !this.state.isReverse })
  }

  compareBy(key) {
    if (key == 'category') {
      return function (a, b) {
        if (a.category.name < b.category.name) return -1
        if (a.category.name > b.category.name) return 1
        return 0
      }
    }
    return function (a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }

  setNewState = (states) => {
    this.setState(states)
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ custom_stage }) => ({
      custom_stage: arrayMove(custom_stage, oldIndex, newIndex),
    }), () => {
      const combind = [...this.state.detail_stage, ...this.state.custom_stage]
      const data = new FormData
      console.log('combind', combind)
      combind.map((item, index) => {
        if (item.items)
          data.append(`item_ids[]`, item.id)
      })
      axios.post('api/client/stage-consumable-sort', data).then(result => {
        console.log(result)
        this.props.getProcedureDetail(1)
      }).catch(error => {
        console.log(error)
        console.log(error.response)
      })
    })
  }

  deleteSection = () => {
    const { section } = this.state

    let url = `/api/client/stage-consumable/${section.id}`

    axios.post(url, { _method: 'DELETE' }).then((res) => {
      this.props.getProcedureDetail(1)
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
    })
  }

  editSection = () => {
    const data = {
      name: this.state.section_name,
    }

    let url = `api/client/stage-consumable/${this.state.section.id}`
    axios.post(url, data).then((res) => {
      this.setState({
        create_category: false
      })
      this.props.getProcedureDetail(1)
    }).catch((err) => {
      console.log(err)
      console.log(err.response)
      this.setState({
        errors: err.reponse.data.errors
      })
    })
  }

  generateSelected = (stage_details) => {
    let temp = []
    stage_details.map(detail => {
      if (detail.items) {
        detail.items.map(item => {
          item.item_category = detail.id
          temp.push(item)
        })
      }
    })

    this.setState({
      selected_items: temp,
    })
  }

  renderIndex() {
    const { stage_details } = this.props

    let item_count = 0;
    stage_details.map((detail) => {
      item_count += detail.items ? detail.items.length : 0;
    })
    console.log('item_count', item_count)

    let detaillist = stage_details.map((detail, index) => {
      let modal_ID = this.makeid(10)
      if (detail.items ? detail.items.length : false) {
        let itemlist = detail.items
        return (
 
          <div className="col-md-12 mx-auto mb20" id={`detail-supplies-${detail.id}-${index}`} >
            <Collapsible title={detail.name} stickyHeader key={`detail-supplies-${detail.id}-${index}`} headerClass={` ${this.state.activeIndex == index ? 'font-weight-bold text-underline' : ''}`}>


              <Table minWidth={650}
                noResults={itemlist.length > 0 ? 0 : 1}

                  renderModal={() => {
                    return null
                  }}

                renderHeaders={() => {
                  return (
                    <TRow>
                      <THead className="w150" text="Catalogue #" />
                      <THead className="w280" text="Name" />
                      <THead className="w100" text="Size" />
                      <THead className="w100" text="Qty" />
                    </TRow>
                  )
                }}
                renderRows={() => {
                  let rows = itemlist.map(item => {

                    return (
                      <TRow>
                        <TData className="w150">{item.catalog_number}</TData>
                        <TData className="w280 has-action" item={item} popover onClick={() => {
                          this.setState({ selectedItem: item })
                          $(`#view-item-${this.state.unique}`).modal('show')
                        }}>{item.name}</TData>
                        <TData className="w100">{item.size}</TData>
                        <TData className="w100">{item.qty}</TData>
                      </TRow>
                    )
                  })
                  return rows
                }}
                renderLastRow={() => {
                  if (itemlist.length) { return null }
                  return (
                    <tr>
                      <td className="w-100 text-center" colSpan={4}>
                        No item(s) added. Add new items via <a href="javascript:;"
                          onClick={e => {
                            e.preventDefault()
                            this.setState({
                              edit: true,
                              draft_success: false
                            })
                            this.props.getProcedureDetail(1)
                          }}>Edit notes</a>.
                      </td>
                    </tr>
                  )
                }}
              />
            </Collapsible>
          </div>
        )
      }
    })
    return (
      <div className="row mt32">
        <div className="col-md-12 mx-auto d-flex justify-content-between align-items-center page-description">
          <h2 className="bold mb-2 mb-sm-0">Consumables</h2>
          <Button tertiary className="ml-0" onClick={e => {
            e.preventDefault()
            this.setState({
              edit: true,
            })
            this.props.getProcedureDetail(1)
            this.props.setNewState({
              loading:true,
              procedure_stage_edit: true
            })
          }} >
            Edit {this.props.title}
          </Button>
        </div>
        <div className="col-md-12 mt10">
          <Notifier message={this.state.notificationMessage} onRef={ref => (this.refNotifier = ref)} type="success" styleClass="mb30 mt0" hideThisNotif={() => { this.refNotifier.hideThis() }} />
 
          {this.props.loading ? 
              <ConsumableSkeleton /> : 
              <DataCard title="Consumable Categories" className="jump-to-section-card d-none">
                <ul className="list">
                  {
                    stage_details.map((detail, index) => {
                      if (detail.items ? detail.items.length : '') {
                        if (this.state.activeIndex == null){
                          this.setState({
                            activeIndex: index
                          })
                        }
                        return (
                          <li className={`list-item ${this.state.activeIndex == index ? 'font-weight-bold text-underline':''}`}>
                            <Button className={`${this.state.activeIndex == index ? 'font-weight-bold text-underline' : ''}`} tertiary onClick={() => {
                              console.log('index', index);
                              this.setState({
                                activeIndex: index
                              })
                              $(`#detail-supplies-${detail.id}-${index} .collapse`).collapse('show');
                              $('html, body').animate({
                                scrollTop: $(`#detail-supplies-${detail.id}-${index}`).offset().top
                              }, 400);
                            }}>
                              {detail.name}
                            </Button>
                            {/* <a href={`#${detail.name}`} className="jumpToSectionAction">{detail.name}</a> */}
                          </li>
                        )
                      }
                    })
                  }
                </ul>
              </DataCard>
          
            }
        </div>

        {this.props.loading ? '' : item_count > 0 ? detaillist : (
          <div className=" col-md-12">
            <div className="data-card">
              <div className="card-body pt20 ">
                <p className="m0">
                  No item(s) added. Add new items via <a href="javascript:;" onClick={e => {
                    e.preventDefault()
                    this.setState({
                      edit: true,
                    })
                    this.props.getProcedureDetail(1)
                    this.props.setNewState({
                      loading: true,
                      procedure_stage_edit: true
                    })
                  }} >
                    Edit {this.props.title}</a>.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
  openRemoveConfirm(detail_id, item_id) {
    $('#confirmRemoveItem').on('shown.bs.modal', () => {
      this.setDeleteState(detail_id, item_id)
    })
    $('#confirmRemoveItem').modal('show')
  }
  setDeleteState(detail_id, item_id) {
    this.setState({
      deleteCategoryId: detail_id,
      deleteItemId: item_id
    })
  }

  editContent() { 
    const custom_data = this.state.custom_stage
    const { stage_details } = this.props
    
    let details = stage_details.filter(d => d.name != 'Select category')

    let detaillist = details.map((detail, index) => {
      let modal_ID = this.makeid(10)
      
      if (true) {
        let itemlist = this.state.selected_items.filter(item => item.item_category == detail.id)//detail.items
        return (

          <div className="col-md-12 mx-auto mb20" id={`edit-detail-supplies-${detail.id}-${index}`} > 

              <Collapsible title={detail.name} stickyHeader key={`edit-detail-supplies12-${index}`} headerClass={` ${this.state.activeIndex == index ? 'font-weight-bold text-underline' : ''}`}>
              <div className="w-100 d-flex justify-content-end pb16">
                <Button secondary  
                className="mr-0"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    this.setState({
                      change: !this.state.change,
                      selectedCategory: detail.id,
                      change: !this.state.change,
                    }, () => {
                      $('#assignItemsModalCenter').modal({
                        show: true,
                        keyboard: false
                      })
                      $('#assignItemsModalCenter').on('shown.bs.modal',  () => {
                        document.querySelectorAll('.tippy-popper').forEach(popper => {
                          popper._tippy.hide()  
                        }) 
                      })
                    })
                  }}
                >
                  Assign items
                </Button>
              </div> 

              <Table minWidth={650}
                noResults={itemlist ? itemlist.length > 0 ? 0 : 1 : 1}

                renderModal={() => {
                  return null
                }}

                renderHeaders={() => {
                  return (
                    <TRow>
                      <THead className="w150" text="Catalogue #" />
                      <THead className="w280" text="Name" />
                      <THead className="w100" text="Size" />
                      <THead className="w100" text="Qty" />
                      <THead className="w80" text="" />
                    </TRow>
                  )
                }}
                renderRows={() => {
                  let rows = itemlist.map(item => {

                    return (
                      <TRow>
                        <TData className="w150">{item.catalog_number}</TData>
                        <TData className="w280 has-action" item={item} popover onClick={() => {
                          this.setState({ selectedItem: item })
                          $(`#view-item-${this.state.unique}`).modal('show')
                        }}>{item.name}</TData>
                        <TData className="w100">{item.size}</TData>
                        <TData className="w100">{item.qty}</TData>
                        <TData className="w80"><Button tertiary
                          onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            this.openRemoveConfirm(detail.id, item.id)
                            return;
                          }}>Remove</Button></TData>
                      </TRow>
                    )
                  })
                  return rows
                }}
                
              />
            </Collapsible>
          </div>
        )
      }
    })

    return (
      <div className="row mt32">
        <ConfirmationModal
          title="Save to draft"
          message="Draft will be saved."
          confirmText="Yes"
          cancelText="Cancel"
          confirm={()=>{
            const { stage_master} = this.props
            procedureAction(stage_master.id,1)
            this.refNotifier2.showThis()
            this.setState({ 
              draft_success: true,
              showNotification: true,
              notificationMessage: 'Draft has been saved!'
            })
          }}
          idModal="save-to-draftmodal"
          cancel={() => {
            $('.modal').modal('hide')
          }}
        />
        <div className="col-md-12">
          <h2 className="page-description bold">Editing - Consumables</h2>
        </div>
        <div className="col-md-12">
          <EditMenu>
            <li>
              <Button primary onClick={() => { 
                this.onSubmmit(0) 

                this.props.setNewState({
                  procedure_stage_edit: false
                })
              }}>
                Update
              </Button>
            </li>
            <li>
              <Button tertiary onClick={(e) => {
                e.preventDefault()
                $("#save-to-draftmodal").modal('show')
              }}>Save Draft</Button>
            </li>
            <li className="has-line">
              <Button tertiary onClick={e => {
                e.preventDefault()
                this.props.getProcedureDetail(0)
                this.setState({
                  edit: false,
                  draft_success: false
                })
                this.props.setNewState({ 
                  procedure_stage_edit: false
                })
              }}>Cancel</Button>
            </li>
          </EditMenu>
        </div>

        <div className="col-md-12 mx-auto mt10"> 

          <Notifier message={this.state.notificationMessage} onRef={ref => (this.refNotifier2 = ref)} type="success" styleClass="mb30 mt0" hideThisNotif={() => { this.refNotifier2.hideThis() }} />
          {this.props.loading ? 
              <ConsumableSkeleton /> :
              <DataCard title="Edit - Consumable Categories " className="jump-to-section-card edit">
                <ul className="list">
                  {
                    this.state.detail_stage.map(category => {
                      if (category.items && category.default_value == 1) {
                        return (
                          <li className="list-item">
                            {category.name}
                          </li>
                        )
                      }
                    })
                  }
                  <SortableList
                    items={custom_data}
                    pressDelay={50}
                    onSortEnd={this.onSortEnd}
                    setNewState={this.setNewState}
                    lockToContainerEdges={false}
                    disableAutoscroll={true}
                    axis="xy"
                    
                  /> 
                  <li className="list-item add-new-secion"
                    onClick={() => {
                      this.setState({
                        section_name: '',
                        errors: [],
                        addSection: !this.state.addSection,
                        section: {},
                      }, () => {
                        $("#addNewCategory").modal('show')
                      })
                    }}
                  >
                    + Add new category
                        </li>
                </ul>
              </DataCard> 
            }
        </div>

        {this.props.loading ? '':detaillist} 
        
      </div>
    )
  }

  onSubmmit = (draft = 0) => {
    if(draft == 0){
      Event('Procedures', 'Update', 'Consumables')
    }
    const { selected_items } = this.state
    const { stage_details, stage_master, surgeon } = this.props
    procedureAction(stage_master.id,0)

    const formData = new FormData()
    formData.append(`draft`, draft)
    formData.append(`_method`, 'PATCH')
    stage_details.map((stage, index) => {
      if (index > 0) {
        formData.append(`consumables[${index}]`, stage.id)
        selected_items.map(item => {
          if (stage.id == item.item_category) {
            console.log('true')
            formData.append(`item_ids[${index}][]`, item.id)
            formData.append(`qty[${index}][]`, item.qty)
          }
        })
      }
    })


    let config = {
      headers: {
        'content-type': 'multipart/form-data',
      },

      onUploadProgress: progressEvent => {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        showLoader(percentCompleted >= 95 ? 95 : percentCompleted);
        setLoaderProg(percentCompleted >= 95 ? 95 : percentCompleted)
      },
    }
    axios
      .post(`api/client/stage-consumable-add-item/${stage_master.id}`, formData, config)
      .then(result => {
        this.props.getProcedureDetail(0)
        if (draft == 0) {
          this.setState({
            edit: false,
            draft_success: false,
            showNotification: true,
            notificationMessage: 'Consumables updated!'
          })
          this.refNotifier.showThis()
        }
        setLoaderProg(99)
        setTimeout(() => {
          hideLoader()
        }, 800);

      })
      .catch(error => {
        setLoaderProg(99)
        setTimeout(() => {
          hideLoader()
        }, 800);
        console.log(error)
        console.log(error.response)
      })
  }


  removeItem = () => {
 
    const { deleteCategoryId, deleteItemId, detail_stage } = this.state
    const { stage_master } = this.props
    const rem_index = this.state.selected_items.findIndex( item => {
      return item.id == deleteItemId && item.item_category == deleteCategoryId;
    })
    let sel_items1 = this.state.selected_items;
    sel_items1.splice(rem_index, 1);
    this.setState({
      selected_items: sel_items1
    })
    const stage_details_changed = [];

    const formData = new FormData()
    formData.append(`_method`, 'PATCH')
    formData.append('draft', 1)
    var sel_items = [];
    detail_stage.map((category, index) => { 
      var cat = category;
      formData.append(`consumables[${index}]`, category.id)
      var items = []; 
      this.state.selected_items.map(item => {
          if(item.item_category == category.id){
            items.push(item);
            formData.append(`item_ids[${index}][]`, item.id)
            formData.append(`qty[${index}][]`, item.qty)
            sel_items.push(item); 
          } 
        })
        cat['items'] = items; 
        sel_items.push(items);
        stage_details_changed.push(cat); 
    })
    console.log('stage_details_changed', stage_details_changed)
    axios.post(`api/client/stage-consumable-add-item/${stage_master.id}`, formData).then((res) => {
      this.setState({
        detail_stage: stage_details_changed,
        selected_items: sel_items
      })
      $('.modal').modal('hide')
    }).catch((err) => {
      console.log(err)
      console.log(err.response)
    })
  } 

  
  render() {
     
    return (
      <div className="w-100 float-left ">
        <ViewItemCard id={`view-item-${this.state.unique}`} item={this.state.selectedItem} ></ViewItemCard>
        <div className="scroll-to-top c-pointer">
          <i className="fa fa-chevron-up fs20" />
        </div> 
        {this.state.edit ? this.editContent() : this.renderIndex()}
        <AssignItemsProcedure
          setNewState={this.setNewState}
          selected_items={this.state.selected_items}
          change={this.state.change}
          stage_master={this.props.stage_master}
          selected_category={this.state.selectedCategory}
          item_categories={this.props.stage_details}
        />

        <ConfirmationModal
          idModal="confirmationDeleteSectionModal"
          title="Delete"
          message="Are you sure you want to delete?"
          confirmText="Yes"
          cancelText="No"
          confirm={() => {
            this.deleteSection()
          }}
          cancel={() => {
            $('#confirmationModal').modal('hide')
          }}
        />

        <FormModal
          idModal="addNewCategory"
          closeModalOnSubmit={false}
          title="Add new category"
          confirmText="Save"
          cancelText="Close"
          confirmDisabled={this.state.adding_category}
          confirm={(e) => {
            this.addSection()
          }}
          cancel={(e) => {
            //fn here
          }}
        >
          <div className="row">
            <div className="col-12"> 

              <TextInput
                label={'Category name'}
                value={this.state.section_name}
                placeholder={'Type name here...'}
                errorMessage={getError(this.state.errors, 'name')}
                containerClass={''}
                onValueChange={event => {
                  this.setState({ section_name: event.target.value })
                }}
              />
            </div>
          </div>
        </FormModal>

        <ConfirmationModal
          idModal="confirmRemoveItem"
          title="Remove item?"
          message="Are you sure you want to remove item?"
          confirmText="Yes"
          cancelText="No"
          confirm={() => {
            this.removeItem()
          }}
          cancel={() => {
            $('#confirmRemoveItem').modal('hide')
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return ({
    surgeon: state.surgeon.surgeon
  })
}

export default connect(mapStateToProps)(ConsumableLayout)
