import axios from 'axios'
import $ from 'jquery'
import moment from 'moment'
import React, { Component } from 'react'
import ReactGA from 'react-ga'
import MessengerCustomerChat from 'react-messenger-customer-chat'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router'
import { getUser, logoutUser } from '../actions'
import '../assets/css/app.css'
import '../assets/scss/App.scss'
import { Button } from '../components/commons/forms'
import PromoBanner from '../components/commons/misc/PromoBanner'
import { Modal } from '../components/commons/modals'
import Text from '../components/commons/texts/Text'
import Footer from '../components/footer'
import Header from '../components/header'
import Loading from '../components/loader/loading_screen'
import FullMediaView from '../components/modals/FullMediaView'
import { BASE_URL } from '../config/constant'

class Main extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      hasCookie: false
    }
  }
  

  componentWillMount() {
    const { token,user } = this.props
    if (!token) {
      this.props.router.push('/login')
      return
    }

    ReactGA.set({
      userId: user.name,
    })
 
    axios.defaults.baseURL = BASE_URL
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

    axios.interceptors.response.use(
      response => {
        return response
      },
      error => {
        if (error && error.response) {
          if (error.response.status == 402) {
            this.props.getUser()
            $('#upgradePlanModal').modal('show')
          }
          if (error.response.status == 404) {
             $('.modal').modal('hide')
             this.props.router.replace('/404')
          }
          const urlArray = error.response.config.url.split('/')
          if (!urlArray.includes('login')) {
            if (error.response.status == 401 || error.response.status == 403) {
              $('.modal').modal('hide')
              this.props.router.push('/login')
              this.props.logoutUser()
            }
          }
        }

        return Promise.reject(error)
      }
    )
  }
  componentDidMount() {
    this.checkNavHighlight()
    // $('#upgradePlanModal').modal('show')
    //  $('#thankYouModal').modal('show')
    $(document).on('click', '.navbar-collapse.show', e => {
      if ($('#corh-header2').hasClass('show')) {
        $('.navbar-toggler.mobile-nav').click()
      }
    })
    this.props.getUser()
    if(!window.localStorage.getItem('cookie-accept')){
      this.setState({
        hasCookie:false
      })
    }else{
      this.setState({
        hasCookie: true
      })
    }
    $('.modal').on('shown.bs.modal', function (e) { 
      $('input').blur()
    })
  }
  checkNavHighlight() {
    $(`.navigation .nav-item`).removeClass('active')
    $(`.navigation .nav-link[href="${this.props.router.location.pathname}"]`)
      .closest('.nav-item')
      .addClass('active')
    $(
      `.navigation .dropdown-item[href="${this.props.router.location.pathname}"]`
    )
      .closest('.nav-item')
      .addClass('active')
    $(
      `.navigation .dropdown-item[href="${this.props.router.location.pathname}"]`
    ).addClass('active')
  }

  componentWillReceiveProps(nextProps) {
    console.log('nextProps.router, this.props.router.location.pathname')
    this.checkNavHighlight()
    $('textarea').autoResize()
    $('textarea').change()
    setTimeout(() => {
      $('input').blur()
      if (!$('.sidebar').is(':visible')) {
        $('main').removeClass('has-sidebar')
        $('body').removeClass('sidebar-open')
      }
    }, 500)
    if ($('body').width() <= 768) {
      if ($('#corh-header2').hasClass('show')) {
        $('.navbar-toggler.mobile-nav').click()
      }
    }
  }

  getRestriction = () => {
    const { user } = this.props

    if (!user.premium) {
      if (user.surgeons >= 2 && user.videos >= 3) {
        return (
          <PromoBanner>
            <p>
              You’ve reached your free for limit Surgeon and video uploads. Get
              unlimited surgeons profiles and videos by
              <Button
                tertiary
                datatoggle="modal"
                datatarget="#upgradePlanModal"
                className="ml5"
              >
                Upgrading now
              </Button>
            </p>
          </PromoBanner>
        )
      } else {
        if (user.surgeons >= 2) {
          return (
            <PromoBanner>
              <p>
                Looks like you’ve reached your free limit for Surgeons. Get
                unlimited surgeons profiles by
                <Button
                  tertiary
                  datatoggle="modal"
                  datatarget="#upgradePlanModal"
                  className="ml5"
                >
                  Upgrading now
                </Button>
              </p>
            </PromoBanner>
          )
        }
        if (user.videos >= 3) {
          return (
            <PromoBanner>
              <p>
                You’ve reached your free limit video uploads. Get unlimited
                video uploads by
                <Button
                  tertiary
                  datatoggle="modal"
                  datatarget="#upgradePlanModal"
                  className="ml5"
                >
                  Upgrading now
                </Button>
              </p>
            </PromoBanner>
          )
        }
      }
    } else {
      if (user.paymentFailed > 0) {
        return (
          <PromoBanner error={true}>
            <p>
              Drats! Looks like we have a problem with your card. You may need
              to
              <Text
                className={`pl4`}
                link
                url={{
                  pathname: `account-settings`,
                  search: `?billing=${true}`,
                }}
              >
                Update your card details
              </Text>
            </p>
          </PromoBanner>
        )
      }
      if (user.onTrial) {
        const expire = moment(user.trialEndsAt.date)
        const now = moment()
        const dayExpire = expire.diff(now, 'days')
        if (dayExpire >= 0 && dayExpire <= 10)
          return this.tenDaysLeft(dayExpire)
      }
    }
  }

  tenDaysLeft(dayExpire) {
    return (
      <PromoBanner error={true}>
        <p>
          Looks like your free premium Trial is about to end in{' '}
          <b>{dayExpire}</b> days.
          <Button
            tertiary
            datatoggle="modal"
            datatarget="#upgradePlanModal"
            className="ml5"
          >
            Upgrade now
          </Button>
        </p>
      </PromoBanner>
    )
  }

  render() {
    if (!this.props.token) {
      return null
    }
    return (
      <>
       <Loading key="loading12345" />
        <div className="section">
          <Header router={this.props.router} />

          {this.getRestriction()}

          <main role="main" className="d-flex">
            {this.props.children}
            <MessengerCustomerChat
            pageId="101710551408953"
            appId="1285042545017819"
          />
          </main>

          {
            this.state.hasCookie ? '':
              <div className="cookie-element">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      
                      <div className="card">
                        <div className="row">
                          <div className="col-md-7">
                            <h1>Cookies!</h1>
                            <p className="mt8 mb0">By using our app  you agree to our use of cookies to deliver a better site experience. For more information please read our <Link to="/cookie-policy">cookie policy</Link>.</p>
                          </div>
                          <div className="col-md-5 d-flex justify-content-start flex-wrap flex-row align-items-center pt8">
                            <div className={`cta cta-primary px12`} onClick={() => {

                              window.localStorage.setItem('cookie-accept', true)
                              this.setState({
                                hasCookie: true
                              })
                            }}>I accept it</div>
                            <div className={`cta cta-secondary px12`} onClick={() => {
                              this.setState({
                                hasCookie: true
                              })
                              window.localStorage.setItem('cookie-accept', true)
                            }}>Decline</div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

          }
          <FullMediaView />
           
          <Footer />
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user,
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    { logoutUser, getUser }
  )(Main)
)
