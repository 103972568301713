import React, { Component } from 'react'
import { Tabs, TabContent, TabNavigation, TabHead, TabBody } from '../../components/commons/tabs'; 
import { Items, Surgeons, Procedures, Media } from '../../components/records'
import Create from '../items/Create';
class Record extends Component {
  constructor(props) {
    super(props)

    this.state = {
      active: 'surgeons',
      item: null,
      isCreate: false,
      selectedItem: null,
      key:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
    }
  }

  componentDidMount() {
    
    if (typeof this.props.router.location.state != 'undefined') {
      switch (this.props.router.location.state.tab) {
        case 'items':
          this.setState({
            active: 'items',
          })
          if (this.props.router.location.state.data) {
            this.setState({
              item: this.props.router.location.state.data,
              key:
                Math.random()
                  .toString(36)
                  .substring(2, 15) +
                Math.random()
                  .toString(36)
                  .substring(2, 15),
            })
          }
          break
        case 'procedures':
          this.setState({
            active: 'procedures',
          })

          break

        case 'galleries':
          this.setState({
            active: 'galleries',
          })

          break

        default:
          this.setState({
            active: 'surgeons',
          })
          break
      }
    }
  }

  setIsCreate = (item, value) => {
    console.log('itemss',item)
    this.setState({
      selectedItem: item,
      isCreate: value
    })
  }

  addItemSucces(item) {
    this.setState({
      selectedItem: item,
      isCreate: false
    })
  }
  editItemSuccess(item) {
    this.setState({
      selectedItem: item,
      isCreate: false
    })
  }
  render() {

    if (this.state.isCreate)
      return (
        <Create
          addItemSucces={item => this.addItemSucces(item)}
          editItemSuccess={item => this.editItemSuccess(item)}
          modalCancel={() => {
            this.setState({
              isCreate: false,
            })
          }}
          item={this.state.selectedItem}
          medical_inventory_id={this.state.selectedItem.medical_category_id}
          medicalInventoryName={this.state.medical_inventory_name}
          key={this.state.key}
        />
      )

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12"> 
            <h1 className="page-title">My records</h1>
          </div>
          <div className="col-md-6">
            <p className="page-detail">
              You’ll find all of the items, procedures, surgeons, photos and videos you’ve registered into the system
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12"> 
            <Tabs 
              navs = { () => {
                return (
                  <TabHead>
                    <TabNavigation target="surgeons" active={this.state.active == 'surgeons'} onClick={()=>{
                      this.setState({
                        active: 'surgeons'
                      })
                    }}>Surgeons</TabNavigation> 
                    <TabNavigation target="items" active={this.state.active == 'items'} onClick={()=>{
                      this.setState({
                        active: 'items'
                      })
                    }}>Items</TabNavigation> 
                    <TabNavigation target="procedures" active={this.state.active == 'procedures'} onClick={()=>{
                      this.setState({
                        active: 'procedures'
                      })
                    }}>Procedures</TabNavigation> 
                    <TabNavigation target="medias" active={this.state.active == 'galleries'} onClick={()=>{
                      this.setState({
                        active: 'galleries'
                      })
                    }}>My media uploads</TabNavigation> 
                  </TabHead>
                )
              }}
              contents = { () => {
                return (
                  <TabBody className="pt20">
                    <TabContent idTab="surgeons" active={this.state.active == 'surgeons'} >
                      {
                        this.state.active == 'surgeons' ? 
                          <Surgeons activeTab={this.state.active == 'surgeons' ? true : false} /> : null
                      }
                    </TabContent>
                    <TabContent idTab="items" active={this.state.active == 'items'}>
                      {this.state.active == 'items'  ? <Items
                        setIsCreate={this.setIsCreate}
                        activeTab={this.state.active == 'items' ? true : false}
                        item={this.state.item}
                        key={this.state.key}
                        removeState={() => {
                          this.setState(
                            {
                              item: null,
                            },
                            () => {
                              this.props.router.replace({
                                pathname: this.props.location.pathname,
                                state: {
                                  tab: 'items',
                                },
                              })
                            }
                          )
                        }} 
                      /> : null }
                    </TabContent>
                    <TabContent idTab="procedures" active={this.state.active == 'procedures'}>
                      {
                        this.state.active == 'procedures' ? 
                          <Procedures activeTab={this.state.active == 'procedures' ? true : false} /> : null
                      }
                    </TabContent>
                    <TabContent idTab="medias" active={this.state.active == 'galleries'} className="m-h450 of-auto">
                      {
                        this.state.active == 'galleries' ? 
                        <Media activeTab={this.state.active == 'galleries' ? true : false} /> : null
                      }
                    </TabContent>
                  </TabBody>
                )
              }}
            />
            
          </div>
        </div>
      </div>
    )
  }
}

export default Record
