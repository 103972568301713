import React, { Component } from 'react'
import { Button, TextInput } from '../../../components/commons/forms'
import { scrollToTop, getParams, makeid } from '../procedure_helpers'
import NewConsumableSkeleton from '../skeleton/NewConsumableSkeleton'
import { Table, TRow, THead, TData } from '../../../components/commons/table';
import Collapsible from '../../../components/commons/panel/Collapsible'
import $ from 'jquery'
import EditMenu from '../../../components/commons/misc/EditMenu';
import DataCard from '../../../components/commons/cards/DataCard';
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import Icon from '../../../components/commons/icons/Icon';
import { FormModal, ConfirmationModal } from '../../../components/commons/modals';
import { getError } from '../../../utilities'
import { procedureAction } from '../../../utilities/notifier'
import { AssignItemsProcedure } from '../../../components/modals/AssignItemsProcedure';
import axios from 'axios'

const SortableItem = SortableElement(({ item, setNewState }) => (
  <li className="draggable-item">
    <div className="icon-holder">
      <Icon icon="move-icon" height={28} width={15} className="negative" />
    </div>
    <span className="pl10 work-break-all">{item.name}</span>
    <div className="action-holder">
      <Button tertiary datatoggle="modal"
        datatarget="#confirmationDeleteSectionModal"
        onClick={e => {
          e.preventDefault()
          setNewState({
            section: item,
          })
        }}><i className="mdi mdi-trash-can-outline fs20"></i></Button>
    </div>

  </li>
))

const SortableList = SortableContainer(({ items, setNewState }) => {
  return (
    <ul className="list-unstyled mb0 p0 d-flex" style={{ flexWrap: 'wrap' }}>
      {items.map((item, index) => {
        return (
          <SortableItem
            key={`item-${index}`}
            index={index}
            axis="xy"
            item={item}
            setNewState={setNewState}
          />
        )
      })}
    </ul>
  )
})

export class NewConsumable extends Component {
  
  constructor(props) {
    super(props)

    this.state = {
      selected_items: [],
      create_category: false,
      section_name: '',
      section: {},
      errors: [],
      change: false,
      edit: false,
      selected_flag: false,
      detail_stage: [],
      custom_stage: [],
      selectedItem: null,
      draft_success: false,
      showNotification: false,
      notificationMessage: '',
      activeIndex: null,
      deleteCategoryId: null,
      deleteItemId: null,
      selectedCategory: null,
      unique: makeid(11),
      onceLoaded: false
    }

  }
  async componentDidMount() {
    scrollToTop()
    if (!this.state.onceLoaded) { 
      this.props.getProcedureDetail(0, 'main', true, getParams('stage_id'))

      console.log('stage_details componentDidMount cons')
    }  
  }

  removeItem = () => {

    const { deleteCategoryId, deleteItemId, detail_stage } = this.state
    const { stage_details } = this.props
    const rem_index = this.state.selected_items.findIndex(item => {
      return item.id == deleteItemId && item.item_category == deleteCategoryId;
    })
    let sel_items1 = this.state.selected_items;
    sel_items1.splice(rem_index, 1);
    this.setState({
      selected_items: sel_items1
    })
    const stage_details_changed = [];

    const formData = new FormData()
    formData.append(`_method`, 'PATCH')
    formData.append('draft', 1)
    var sel_items = [];
    stage_details.detail.map((category, index) => {
      var cat = category;
      formData.append(`consumables[${index}]`, category.id)
      var items = [];
      this.state.selected_items.map(item => {
        if (item.item_category == category.id) {
          items.push(item);
          formData.append(`item_ids[${index}][]`, item.id)
          formData.append(`qty[${index}][]`, item.qty)
          sel_items.push(item);
        }
      })
      cat['items'] = items;
      sel_items.push(items);
      stage_details_changed.push(cat);
    })
    console.log('stage_details_changed', stage_details_changed)
    axios.post(`api/client/stage-consumable-add-item/${stage_details.stage.id}`, formData).then((res) => {
      this.setState({
        detail_stage: stage_details_changed,
        selected_items: sel_items
      })
      $('.modal').modal('hide')
    }).catch((err) => {
      console.log(err)
      console.log(err.response)
    })
  } 

  addSection = () => {
    const { stage_details } = this.props
    this.setState({
      errors: []
    })

    const data = {
      name: this.state.section_name,
      stage_id: stage_details.stage.id,
      create_category: !this.state.create_category
    }

    axios.post(`api/client/stage-consumable`, data).then((result) => {
      this.setState({
        create_category: !this.state.create_category
      })
      $("#addNewCategory").modal('hide')
      this.props.getProcedureDetail(1)
    }).catch(error => {
      console.log(error)
      console.log(error.response)
      this.setState({
        errors: error.response.data.errors
      })
    })
  }
  sortBy(key) {
    let arrayCopy = [...this.state.selected_items]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }

    this.setState({ selected_items: arrayCopy, isReverse: !this.state.isReverse })
  }

  compareBy(key) {
    if (key == 'category') {
      return function (a, b) {
        if (a.category.name < b.category.name) return -1
        if (a.category.name > b.category.name) return 1
        return 0
      }
    }
    return function (a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }
  categoryTabs() {
    const { stage, loading, stage_details } = this.props

    const custom_data = this.state.custom_stage
    return (
      <div className="col-md-12">
        <DataCard title="Edit - Consumable Categories " className="jump-to-section-card edit">
          <ul className="list">
            {
              stage_details.detail.map(category => {
                if (category.items && category.default_value == 1) {
                  return (
                    <li className="list-item">
                      {category.name}
                    </li>
                  )
                }
              })
            }
            <SortableList
              items={custom_data}
              pressDelay={50}
              onSortEnd={this.onSortEnd}
              setNewState={this.setNewState}
              lockToContainerEdges={false}
              disableAutoscroll={true}
              axis="xy"

            />
            <li className="list-item add-new-secion"
              onClick={() => {
                this.setState({
                  section_name: '',
                  errors: [],
                  addSection: !this.state.addSection,
                  section: {},
                }, () => {
                  $("#addNewCategory").modal('show')
                })
              }}
            >
              + Add new category
                        </li>
          </ul>
        </DataCard>
      </div>
    )
  }
  setNewState = (states) =>{
    this.setState(states)
  }

  openRemoveConfirm(detail_id, item_id) {
    $('#confirmRemoveItem').on('shown.bs.modal', () => {
      this.setDeleteState(detail_id, item_id)
    })
    $('#confirmRemoveItem').modal('show')
  }
  setDeleteState(detail_id, item_id) {
    this.setState({
      deleteCategoryId: detail_id,
      deleteItemId: item_id
    })
  }
  onSubmmit = (draft = 0) => {
    // if (draft == 0) {
    //   new Event('Procedures', 'Update', 'Consumables')
    // }
    const { selected_items } = this.state
    const { stage_details, stage_master, surgeon } = this.props
    procedureAction(stage_details.stage.id, 0)

    const formData = new FormData()
    formData.append(`draft`, draft)
    formData.append(`_method`, 'PATCH')
    stage_details.detail.map((stage, index) => {
      if (index > 0) {
        formData.append(`consumables[${index}]`, stage.id)
        stage.items.map(item => {
          if (stage.id == item.item_category) {
            console.log('true')
            formData.append(`item_ids[${index}][]`, item.id)
            formData.append(`qty[${index}][]`, item.qty)
          }
        })
      }
    })


    let config = {
      headers: {
        'content-type': 'multipart/form-data',
      }, 
      onUploadProgress: progressEvent => {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        ) 
      },
    }
    axios
      .post(`api/client/stage-consumable-add-item/${stage_details.stage.id}`, formData, config)
      .then(result => {
        this.props.getProcedureDetail(0)
        if (draft == 0) {
          this.setState({
            edit: false,
            draft_success: false,
            showNotification: true,
            notificationMessage: 'Consumables updated!'
          })
          this.refNotifier.showThis()
        } 

      })
      .catch(error => { 
        console.log(error)
        console.log(error.response)
      })
  }


  render() {
    
    const { stage, loading, stage_details } = this.props
    const { edit } = this.state
    const custom_data = this.state.custom_stage
    
    let detaillist = !stage_details.detail ? [] : stage_details.detail.map((detail, index) => {
      let modal_ID = makeid(10)
      if (detail.items ? detail.items.length : false) {
        let itemlist = detail.items
        return (

          <div className="col-md-12 mx-auto mb20" id={`detail-supplies-${detail.id}-${index}`} >
            <Collapsible title={detail.name} stickyHeader key={`detail-supplies-${detail.id}-${index}`} headerClass={` ${this.state.activeIndex == index ? 'font-weight-bold text-underline' : ''}`}>

              {
                !edit ? '':
                  <div className="w-100 d-flex justify-content-end pb16">
                    <Button secondary
                      className="mr-0"
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        this.setState({
                          change: !this.state.change,
                          selectedCategory: detail.id,
                          change: !this.state.change,
                        }, () => {
                          $('#assignItemsModalCenter').modal({
                            show: true,
                            keyboard: false
                          })
                          $('#assignItemsModalCenter').on('shown.bs.modal', () => {
                            document.querySelectorAll('.tippy-popper').forEach(popper => {
                              popper._tippy.hide()
                            })
                          })
                        })
                      }}
                    >
                      Assign items
                </Button>
                  </div>  
              }
              <Table minWidth={650}
                noResults={itemlist.length > 0 ? 0 : 1}

                renderModal={() => {
                  return null
                }}

                renderHeaders={() => {
                  return (
                    <TRow>
                      <THead className="w150" text="Catalogue #" />
                      <THead className="w280" text="Name" />
                      <THead className="w100" text="Size" />
                      <THead className="w100" text="Qty" />
                      {!edit ? '' : <THead className="w80" text="" />}
                    </TRow>
                  )
                }}
                renderRows={() => {
                  let rows = itemlist.map(item => {

                    return (
                      <TRow>
                        <TData className="w150">{item.catalog_number}</TData>
                        <TData className="w280 has-action" item={item} popover onClick={() => {
                          this.setState({ selectedItem: item })
                          $(`#view-item-${this.state.unique}`).modal('show')
                        }}>{item.name}</TData>
                        <TData className="w100">{item.size}</TData>
                        <TData className="w100">{item.qty}</TData>
                        {!edit ? '' :  <TData className="w80"><Button tertiary
                          onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()
                            this.openRemoveConfirm(detail.id, item.id)
                            return;
                          }}>Remove</Button></TData> }
                      </TRow>
                    )
                  })
                  return rows
                }}
                renderLastRow={() => {
                  if (itemlist.length) { return null }
                  return (
                    <tr>
                      <td className="w-100 text-center" colSpan={4}>
                        No item(s) added. Add new items via <a href="javascript:;"
                          onClick={e => {
                            e.preventDefault()
                            this.setState({
                              edit: true,
                              draft_success: false
                            })
                            this.props.getProcedureDetail(1)
                          }}>Edit notes</a>.
                      </td>
                    </tr>
                  )
                }}
              />
            </Collapsible>
          </div>
        )
      }
    })

    if(loading){
      return <NewConsumableSkeleton />
    }
    return (
      <>
        <div className="row mb16">
          <div className="col-md-12 d-flex justify-content-between align-items-center">
            <h2 className="page-sub-title">Consumables</h2>
            <Button tertiary onClick={e => {
              e.preventDefault()
              this.setState({
                edit: true,
              })
              this.props.getProcedureDetail(1)
              this.props.setNewState({
                loading_component: true
              })
              setTimeout(() => {
                this.props.setNewState({
                  loading_component: false
                })
              }, 1000)
            }} >
              Edit Consumables
            </Button>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12"> 
            <div className="scroll-to-top c-pointer">
              <i className="fa fa-chevron-up fs20" />
            </div> 
          </div>
          {this.state.edit ? <div className="col-md-12">
            <EditMenu>
              <li>
                <Button primary onClick={() => { this.onSubmmit(0) }}>
                  Update
              </Button>
              </li>
              <li>
                <Button tertiary onClick={(e) => {
                  e.preventDefault()
                  $("#save-to-draftmodal").modal('show')
                }}>Save Draft</Button>
              </li>
              <li className="has-line">
                <Button tertiary onClick={e => {
                  e.preventDefault()
                  this.props.getProcedureDetail(0)
                  this.setState({
                    edit: false,
                    draft_success: false
                  })
                }}>Cancel</Button>
              </li>
            </EditMenu>
          </div> : ''}
          
          {
            edit ? this.categoryTabs() : ''
          }

          {detaillist}
        </div> 


        {/* <AssignItemsProcedure
          setNewState={this.setNewState}
          selected_items={this.state.selected_items}
          change={this.state.change}
          stage_master={this.props.stage_master}
          selected_category={this.state.selectedCategory}
          item_categories={this.props.stage_details}
        /> */}


        <ConfirmationModal
          idModal="confirmationDeleteSectionModal"
          title="Delete"
          message="Are you sure you want to delete?"
          confirmText="Yes"
          cancelText="No"
          confirm={() => {
            this.deleteSection()
          }}
          cancel={() => {
            $('#confirmationModal').modal('hide')
          }}
        />

        <FormModal
          idModal="addNewCategory"
          closeModalOnSubmit={false}
          title="Add new category"
          confirmText="Save"
          cancelText="Close"
          confirm={(e) => {
            this.addSection()
          }}
          cancel={(e) => {
            //fn here
          }}
        >
          <div className="row">
            <div className="col-12">

              <TextInput
                label={'Category name'}
                value={this.state.section_name}
                placeholder={'Type name here...'}
                errorMessage={getError(this.state.errors, 'name')}
                containerClass={''}
                onValueChange={event => {
                  this.setState({ section_name: event.target.value })
                }}
              />
            </div>
          </div>
        </FormModal>

        <ConfirmationModal
          idModal="confirmRemoveItem"
          title="Remove item?"
          message="Are you sure you want to remove item?"
          confirmText="Yes"
          cancelText="No"
          confirm={() => {
            this.removeItem()
          }}
          cancel={() => {
            $('#confirmRemoveItem').modal('hide')
          }}
        />
      </>
    )
  }
}

export default NewConsumable
