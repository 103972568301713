import Axios from 'axios'

export const procedureAction = (client_stage_id, draft = 1) => {
  Axios.post(`api/client/notify/procedure-action`, {
    client_stage_id,
    draft,
  })
    .then(result => {
      console.log(result)
    })
    .catch(error => {
      console.log(error)
      console.log(error.response)
    })
}
