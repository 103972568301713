import React, { Component } from 'react'
import { Picker, TextInput } from '../commons/forms'
import { connect } from 'react-redux'
import _ from 'lodash'
import $ from 'jquery'
import ReactDOMServer from 'react-dom/server'
import { getMedicalInventories, getItemsPerCategory, setItems, getMedicalInventoryCategories, itemLoading } from '../../actions/ItemAction'
import { getBodyAreas } from '../../actions/PostioningActions'
import { Link } from 'react-router'
import ReviewItems from './ReviewItemsPositioning'
import ViewItemModal from '../../components/modals/ViewItemModal'
import InfiniteScroll from "react-infinite-scroll-component"
import { Modal } from '../commons/modals';
import { Tabs, TabHead, TabNavigation, TabBody, TabContent } from '../commons/tabs';
import { Pills } from '../commons/misc';
import { Table, TRow, THead, TData } from '../commons/table';
import ViewItemCard from '../commons/cards/ViewItemCard';

export class AssignItemsPositioning extends Component {
  constructor(props) {
    super(props)

    this.state = {
      inventory: 1,
      category: 'all',
      categories: [],
      counters: {
        supplies: 0,
        instruments: 0,
        equipments: 0
      },
      keyword: '',
      selected_items: [],
      selected_items_review: [],
      qtyfiltered: [],
      qtyunfiltered: [],
      posfiltered: [],
      posunfiltered: [],
      change: false,
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      modalkey:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      page: 1
    }
    this.searchData = _.debounce(this.getItemResults, 600)

  }

  componentDidMount = () => {
    this.props.getBodyAreas()
    this.props.getMedicalInventories()
    this.props.getItemsPerCategory('all', this.state.selected_items, this.state.inventory, '', this.state.page)

    this.setState({
      selected_items: this.props.selected_items,
    })
    $("#assignItemsModalCenter").on('show.bs.modal', () => {
      setTimeout(() => {
        this.setState({
          unique:
            Math.random()
              .toString(36)
              .substring(2, 15) +
            Math.random()
              .toString(36)
              .substring(2, 15),
          inventory: this.props.medical_inventories[0].id,
          category: 'all',
          keyword: '',
          page: 1
        }, () => {
          this.props.itemLoading()
          this.props.getMedicalInventoryCategories(this.state.inventory)
          this.props.getItemsPerCategory(this.state.category, this.state.selected_items, this.state.inventory, '', this.state.page)
        })
      }, 500);
    });

  }

  componentWillReceiveProps = (nextProps) => {
    if (this.props.change != nextProps.change) {
      this.setState({
        inventory: 1,
        category: 'all',
        page: 1,
        keyword: '',
      })

      console.log('nextPropsnextProps', nextProps)

      var sel_ = _.cloneDeep(nextProps.selected_qty);
      var qty_selected = sel_.map(x => {
        return x
      })
      
      console.log('qty_selected', qty_selected)
      console.log('selected_items_filtered', nextProps.selected_items_filtered) 
      
      this.setState({
        selected_items: _.cloneDeep(nextProps.selected_items_filtered),
        selected_items_filtered: _.cloneDeep(nextProps.selected_items_un_filtered),
        inventory: nextProps.medical_inventories[0].id,
        category: 'all',
        keyword: '',
        page: 1,
        qty_selected: qty_selected
      }, () => {
        this.props.itemLoading()
        this.props.getMedicalInventoryCategories(this.state.inventory)
        this.props.getItemsPerCategory(this.state.category, this.state.selected_items, this.state.inventory, '', this.state.page)
      })

      $('#supplies-tab').tab('show')
    }
  }

  componentDidUpdate = () => {
    $('[data-toggle="popover"]').popover()
    $('[data-toggle="popover"]').popover('hide')

    let counters = {
      supplies: 0,
      instruments: 0,
      equipments: 0
    }
    let itemss = this.state.selected_items.filter(item => item.body_area == this.props.selected_body_area)
    itemss.map((item) => {
      switch (item.category.medical_inventory_id) {
        case 1:
          counters.supplies++
          break
        case 2:
          counters.instruments++
          break
        case 3:
          counters.equipments++
          break
        default:
          break
      }
    })

    if (!_.isEqual(counters, this.state.counters)) {
      this.setState({
        counters: counters
      })
    }
  }

  getMoreItems = () => {
    const { page } = this.state

    this.setState({
      page: page + 1
    }, () => { this.props.getItemsPerCategory(this.state.category, this.state.selected_items, this.state.inventory, '', this.state.page) })
  }

  checkItem = (id, qty = 1) => {
    let { selected_items } = this.state

    const { items } = this.props
    let item = items.find((e) => e.id === id)
    if (item) {
      if (!item.qty) {
        item.qty = 1
      }
    } else {
      item = selected_items.find((e) => e.id === id && e.body_area == this.props.selected_body_area)
    }


    if (selected_items.find((e) => e.id === id && e.body_area == this.props.selected_body_area)) {
      this.setState({
        selected_items: selected_items.filter(x => x.id !== id && x.body_area == this.props.selected_body_area),
        counters: {
          supplies: this.state.counters.supplies + (item.category.medical_inventory_id == 1 ? -1 : 0),
          instruments: this.state.counters.instruments + (item.category.medical_inventory_id == 2 ? -1 : 0),
          equipments: this.state.counters.equipments + (item.category.medical_inventory_id == 3 ? -1 : 0),
        },
      })
    } else {
      let new_i = item;
      new_i['body_area'] = this.props.selected_body_area
      let qty_ = this.state.qty_selected
      qty_.unshift(qty)
      selected_items.unshift(new_i)
      this.setState({
        selected_items: selected_items.splice(0),
        qty_selected: qty_.splice(0),
        counters: {
          supplies: this.state.counters.supplies + (item.category.medical_inventory_id == 1 ? 1 : 0),
          instruments: this.state.counters.instruments + (item.category.medical_inventory_id == 2 ? 1 : 0),
          equipments: this.state.counters.equipments + (item.category.medical_inventory_id == 3 ? 1 : 0),
        },
      })
    }
  }

  checkItemExistence = (id) => {
    let { selected_items } = this.state

    let exist = false
    if (selected_items.filter((e) => e.id === id).length > 0) {
      exist = true
    } else {
      exist = false
    }

    return exist
  }

  isChecked = (id) => {
    let { selected_items } = this.state
    const { items } = this.props

    let item_local = selected_items.find((e) => e.id === id)
    let item_store = items.find((e) => e.id === id)

    let checked = false
    if (item_local) {
      if (item_local.prn) {
        checked = true
      }
    } else {
      if (item_store) {
        if (item_store.prn) {
          checked = true
        }
      }

    }

    return checked
  }

  sortBy(key) {
    let arrayCopy = [...this.props.items]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }

    this.props.setItems(arrayCopy)

    this.setState({ isReverse: !this.state.isReverse })
  }

  compareBy(key) {
    if (key == 'category') {
      return function (a, b) {
        if (a.category.name < b.category.name) return -1
        if (a.category.name > b.category.name) return 1
        return 0
      }
    }
    return function (a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }

  setSelected = (selected_items) => {
    this.setState({
      selected_items: selected_items
    })
  }

  getItemResults = () => {
    console.log('this.state.inventory', this.state.inventory)
    this.props.getItemsPerCategory(this.state.category, this.state.selected_items, this.state.inventory, this.state.keyword, this.state.page)
  }
  searchItems = ({ target: { value } }) => {
    this.props.itemLoading()
    this.setState({ keyword: value, page: 1 }, () => {
      this.searchData()
    })
  }

  render() {
    const { medical_inventories, items } = this.props
    let { counters } = this.state

    // console.log('qwe', this.state)

    const search = _.debounce(() => {
      this.props.getItemsPerCategory(this.state.category, this.state.selected_items, this.state.inventory.id, this.state.keyword, this.state.page)
    }, 500)


    return (
      <div>

        <ViewItemCard id={`aip-pm-modal`} item={this.state.selectedItem} className="zindex-5k" ></ViewItemCard>

        <Modal
          idModal="assignItemsModalCenter"
          size="modal-xl"
          title="Assign items"
          confirmText="Review items"
          cancelText="Cancel"
          confirm={(e) => {
            this.setState({
              selected_items: this.state.selected_items,
              inventory: 'all',
              change: !this.state.change,

            })
            $("#assignItemsModalCenter").modal('hide')
            $("#reviewPEItemsModal").modal('show')
          }}
          cancel={(e) => {
            //fn here
          }}
          bodyClass="assign-items-body"
        >

          <div className="container">
            <Tabs
              onRef={ref => (this.tabsRef = ref)}
              className=""
              key={`tabs-${this.state.unique}`}
              navs={() => {
                return (
                  <TabHead className="col-lg-12">
                    <TabNavigation target="consumables" active={true} onClick={(event) => {
                      this.setState({ inventory: medical_inventories[0].id, category: 'all', keyword: '', page: 1 }, () => {
                        this.props.itemLoading()
                        this.props.getMedicalInventoryCategories(this.state.inventory)
                        this.props.getItemsPerCategory(this.state.category, this.state.selected_items, this.state.inventory, '', this.state.page)
                      })

                    }}>
                      <span className="d-flex">
                        Supply
                        {/* <Pills className="mt0 mr0 ml8 sm specialty-span">{counters.supplies ? counters.supplies : '0'}</Pills> */}
                      </span>
                    </TabNavigation>
                    <TabNavigation target="instruments" onClick={(event) => {
                      this.setState({ inventory: medical_inventories[1].id, category: 'all', keyword: '', page: 1 }, () => {
                        this.props.itemLoading()
                        this.props.getMedicalInventoryCategories(this.state.inventory)
                        this.props.getItemsPerCategory(this.state.category, this.state.selected_items, this.state.inventory, '', this.state.page)
                      })
                    }}>
                      <span className="d-flex">
                        Instruments
                        {/* <Pills className="mt0 mr0 ml8 sm specialty-span">{counters.instruments ? counters.instruments : '0'}</Pills> */}
                      </span>
                    </TabNavigation>
                    <TabNavigation target="equipments" onClick={(event) => {
                      this.setState({ inventory: medical_inventories[2].id, category: 'all', keyword: '', page: 1 }, () => {
                        this.props.itemLoading()
                        this.props.getMedicalInventoryCategories(this.state.inventory)
                        this.props.getItemsPerCategory(this.state.category, this.state.selected_items, this.state.inventory, '', this.state.page)
                      })
                    }}>
                      <span className="d-flex">Equipment
                        {/* <Pills className="mt0 ml8 sm specialty-span">{counters.equipments ? counters.equipments : '0'}</Pills> */}
                      </span>
                    </TabNavigation>
                  </TabHead>
                )
              }}
              contents={() => {
                return (
                  <TabBody className="col-md-12 px0">
                    <TabContent idTab="consumables" active={true} key={'consumable=tab'}>
                      <div className="row pt8">
                        <div className="col-lg-5">
                          <TextInput
                            label={'Search item database'}
                            placeholder={'Start typing name, catalogue #…'}
                            className="active"
                            onChange={this.searchItems.bind(this)}
                            value={this.state.keyword}
                            search
                          />
                        </div>
                        <div className="col-lg-5">
                          <Picker
                            onChange={event => {
                              this.setState({ category: event.target.value, keyword: '', page: 1 }, () => {
                                this.props.getItemsPerCategory(this.state.category, this.state.selected_items, this.state.inventory, this.state.keyword, this.state.page)
                              })
                            }}
                            label={`Filter by category`}
                            value={this.state.category}
                            data={this.props.inventory_categories}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12">
                          <Table minWidth={790}
                            minWidth={890} className="modal-table infi-table"
                            key={'consumable-table'}
                            renderModal={() => {
                              return null
                            }}

                            infiniteScroll infiniteHeight={307}
                            infiniteDataLength={this.props.items.length} //This is important field to render the next data
                            infiniteNext={() => { this.getMoreItems() }}
                            infiniteHasMore={this.props.more}
                            loading={this.props.loading}
                            // endOfMessageTitle='Nothing else to see...'
                            endOfMessageMessage="Nothing else to see..."
                            // endOfMessageLinkMessage="Edit database"

                            renderHeaders={() => {
                              return (
                                <TRow>
                                  <THead className="w40" text="" />
                                  <THead className="w150" text="Catalogue #" sortable onClick={() => this.sortBy('catalog_number')} />
                                  <THead className="w250" text="Name" sortable onClick={() => this.sortBy('name')} />
                                  <THead className="w100" text="Company" sortable onClick={() => this.sortBy('company')} />
                                  <THead className="w100" text="Category" sortable onClick={() => this.sortBy('category')} />
                                  <THead className="w100" text="Size" sortable onClick={() => this.sortBy('size')} />
                                  <THead className="w100" text="Qty" />
                                </TRow>
                              )
                            }}
                            renderRows={() => {

                              let rows = this.props.items.map((item, index) => {
                                let index_of_sel = this.state.selected_items.findIndex(x => { return x.id == item.id })
                                return (
                                  <TRow hasChoices key={`row-aip1-${index}-${item.id}`} autoCheck={false}>
                                    <TData className="w40" onClick={() => {
                                      this.checkItem(item.id);
                                      // this.tabsRef.reInit();
                                    }} >
                                      <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id={`checkbox-aip1-${item.id}`} checked={this.checkItemExistence(item.id)} />
                                        <label className="custom-control-label" htmlFor={`checkbox-aip1-${item.id}`} onClick={(e) => { e.preventDefault() }}></label>
                                      </div>
                                    </TData>
                                    <TData className="w150">{item.catalog_number}</TData>
                                    <TData className="w250 has-action" item={item} popover onClick={(e) => {
                                      e.stopPropagation()
                                      this.setState({
                                        selectedItem: item,
                                      })
                                      $(`#aip-pm-modal`).modal('show')
                                    }}>{item.name}</TData>
                                    <TData className="w150">{item.company}</TData>
                                    <TData className="w150">{item.category ? item.category.name : ''}</TData>
                                    <TData className="w80">{item.size}</TData>
                                    <TData className="w100" onClick={e => e.stopPropagation()}>
                                      <input
                                        type="text"
                                        name="search"
                                        className="form-control text-center"
                                        placeholder=""
                                        defaultValue={1}
                                        value={index_of_sel && this.state.qty_selected >= 0 ? this.state.qty_selected[index_of_sel] : undefined}
                                        onChange={event => {
                                          if (/^[1-9][0-9]*$/.test($(event.target).val()) || !$(event.target).val().length) {

                                            if (index_of_sel < 0) {
                                              // this.checkItem(item.id)
                                            }else{ 
                                              const { qty_selected } = this.state
                                              qty_selected[index_of_sel] = event.target.value
                                              this.setState({ qty_selected: qty_selected.splice(0) })
                                            }
                                          }
                                        }}
                                        onBlur={(event) => {
                                          if (/\D+/g.test(event.target.value) || !$(event.target).val().length) {
                                            $(event.target).val(1)
                                            if (index_of_sel < 0) {
                                              // this.checkItem(item.id)
                                            }
                                            const { qty_selected } = this.state
                                            qty_selected[index_of_sel] = 1
                                            this.setState({ qty_selected: qty_selected.splice(0) })
                                          }
                                        }}
                                      />
                                    </TData>
                                  </TRow>
                                )
                              })
                              return rows
                            }}
                          // renderNotification={() => {
                          //     return (<TableNotificationCard type="success" message="Success message here..." />)
                          // }}

                          />

                        </div>

                      </div>
                    </TabContent>
                    <TabContent idTab="instruments" active={false} key={'instrument=tab'}>
                      <div className="row pt8">
                        <div className="col-lg-5">
                          <TextInput
                            label={'Search item database'}
                            placeholder={'Start typing name, catalogue #…'}
                            className="active"
                            onChange={this.searchItems.bind(this)}
                            value={this.state.keyword}
                            search
                          />
                        </div>
                        <div className="col-lg-5">
                          <Picker
                            onChange={event => {
                              this.setState({ category: event.target.value, keyword: '', page: 1 }, () => {
                                this.props.getItemsPerCategory(this.state.category, this.state.selected_items, this.state.inventory, this.state.keyword, this.state.page)
                              })
                            }}
                            label={`Filter by category`}
                            value={this.state.category}
                            data={this.props.inventory_categories}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12">
                          <Table minWidth={790}
                            minWidth={890} className="modal-table infi-table"
                            key={'instrument-table'}
                            renderModal={() => {
                              return null
                            }}

                            infiniteScroll infiniteHeight={307}
                            infiniteDataLength={this.props.items.length} //This is important field to render the next data
                            infiniteNext={() => { this.getMoreItems() }}
                            infiniteHasMore={this.props.more}
                            loading={this.props.loading}
                            // endOfMessageTitle='Nothing else to see...'
                            endOfMessageMessage="Nothing else to see..."
                            // endOfMessageLinkMessage="Edit database"

                            renderHeaders={() => {
                              return (
                                <TRow>
                                  <THead className="w40" text="" />
                                  <THead className="w150" text="Catalogue #" sortable onClick={() => this.sortBy('catalog_number')} />
                                  <THead className="w250" text="Name" sortable onClick={() => this.sortBy('name')} />
                                  <THead className="w100" text="Company" sortable onClick={() => this.sortBy('company')} />
                                  <THead className="w100" text="Category" sortable onClick={() => this.sortBy('category')} />
                                  <THead className="w100" text="Size" sortable onClick={() => this.sortBy('size')} />
                                  <THead className="w100" text="Qty" />
                                </TRow>
                              )
                            }}
                            renderRows={() => {
                              let rows = this.props.items.map((item, index) => {
                                let index_of_sel = this.state.selected_items.findIndex(x => { return x.id == item.id })
                                return (
                                  <TRow hasChoices key={`row-aip2-${index}-${item.id}`} autoCheck={false}>
                                    <TData className="w40" onClick={() => {
                                      this.checkItem(item.id);
                                      // this.tabsRef.reInit();
                                    }}>
                                      <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id={`checkbox-a2-${item.id}`} checked={this.checkItemExistence(item.id)} />
                                        <label className="custom-control-label" htmlFor={`checkbox-a2-${item.id}`} onClick={(e) => { e.preventDefault() }}></label>
                                      </div>
                                    </TData>
                                    <TData className="w150">{item.catalog_number}</TData>
                                    <TData className="w250 has-action" item={item} popover onClick={(e) => {
                                      e.stopPropagation()
                                      this.setState({
                                        selectedItem: item,
                                      })
                                      $(`#aip-pm-modal`).modal('show')
                                    }}>{item.name}</TData>
                                    <TData className="w100">{item.company}</TData>
                                    <TData className="w100">{item.category ? item.category.name : ''}</TData>
                                    <TData className="w100">{item.size}</TData>
                                    <TData className="w100" onClick={e => e.stopPropagation()}>
                                      <input
                                        type="text"
                                        name="search"
                                        className="form-control text-center"
                                        placeholder=""
                                        defaultValue={1}
                                        value={index_of_sel && this.state.qty_selected >= 0 ? this.state.qty_selected[index_of_sel] : undefined}
                                        onChange={event => {
                                          if (/^[1-9][0-9]*$/.test($(event.target).val()) || !$(event.target).val().length) {

                                            if (index_of_sel < 0) {
                                              // this.checkItem(item.id)
                                            } else {
                                              const { qty_selected } = this.state
                                              qty_selected[index_of_sel] = event.target.value
                                              this.setState({ qty_selected: qty_selected.splice(0) })
                                            }
                                          }
                                        }}
                                        onBlur={(event) => {
                                          if (/\D+/g.test(event.target.value) || !$(event.target).val().length) {
                                            $(event.target).val(1)
                                            if (index_of_sel < 0) {
                                              // this.checkItem(item.id)
                                            }
                                            const { qty_selected } = this.state
                                            qty_selected[index_of_sel] = 1
                                            this.setState({ qty_selected: qty_selected.splice(0) })
                                          }
                                        }}
                                      />
                                    </TData>
                                  </TRow>
                                )
                              })
                              return rows
                            }}
                          // renderNotification={() => {
                          //     return (<TableNotificationCard type="success" message="Success message here..." />)
                          // }}

                          />

                        </div>

                      </div>
                    </TabContent>
                    <TabContent idTab="equipments" active={false} key={'equipment=tab'}>
                      <div className="row pt8">
                        <div className="col-lg-5">
                          <TextInput
                            label={'Search item database'}
                            placeholder={'Start typing name, catalogue #…'}
                            className="active"
                            onChange={this.searchItems.bind(this)}
                            value={this.state.keyword}
                            search
                          />
                        </div>
                        <div className="col-lg-5">
                          <Picker
                            onChange={event => {
                              this.setState({ category: event.target.value, keyword: '', page: 1 }, () => {
                                this.props.getItemsPerCategory(this.state.category, this.state.selected_items, this.state.inventory, this.state.keyword, this.state.page)
                              })
                            }}
                            label={`Filter by category`}
                            value={this.state.category}
                            data={this.props.inventory_categories}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12">
                          <Table minWidth={790}
                            minWidth={890} className="modal-table infi-table"
                            key={'equipment-table'}
                            renderModal={() => {
                              return null
                            }}
                            infiniteScroll infiniteHeight={307}
                            infiniteDataLength={this.props.items.length} //This is important field to render the next data
                            infiniteNext={() => { this.getMoreItems() }}
                            infiniteHasMore={this.props.more}
                            loading={this.props.loading}
                            // endOfMessageTitle='Nothing else to see...'
                            endOfMessageMessage="Nothing else to see..."
                            // endOfMessageLinkMessage="Edit database"

                            renderHeaders={() => {
                              return (
                                <TRow>
                                  <THead className="w40" text="" />
                                  <THead className="w150" text="Catalogue #" sortable onClick={() => this.sortBy('catalog_number')} />
                                  <THead className="w250" text="Name" sortable onClick={() => this.sortBy('name')} />
                                  <THead className="w100" text="Company" sortable onClick={() => this.sortBy('company')} />
                                  <THead className="w100" text="Category" sortable onClick={() => this.sortBy('category')} />
                                  <THead className="w100" text="Size" sortable onClick={() => this.sortBy('size')} />
                                  <THead className="w100" text="Qty" />
                                </TRow>
                              )
                            }}
                            renderRows={() => {
                              let rows = this.props.items.map((item, index) => {
                                let index_of_sel = this.state.selected_items.findIndex(x => { return x.id == item.id })
                                return (
                                  <TRow hasChoices key={`row-aip-${index}-${item.id}`}  autoCheck={false}>
                                    <TData className="w40" onClick={() => {
                                      this.checkItem(item.id);
                                      // this.tabsRef.reInit();
                                    }}>
                                      <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id={`checkbox-a33-${item.id}`} checked={this.checkItemExistence(item.id)} />
                                        <label className="custom-control-label" htmlFor={`checkbox-a3-${item.id}`} onClick={(e) => { e.preventDefault() }}></label>
                                      </div>
                                    </TData>
                                    <TData className="w150">{item.catalog_number}</TData>
                                    <TData className="w250 has-action" item={item} popover onClick={(e) => {
                                      e.stopPropagation()
                                      this.setState({
                                        selectedItem: item,
                                      })
                                      $(`#aip-pm-modal`).modal('show')
                                    }}>{item.name}</TData>
                                    <TData className="w100">{item.company}</TData>
                                    <TData className="w100">{item.category ? item.category.name : ''}</TData>
                                    <TData className="w100">{item.size}</TData>
                                    <TData className="w100" onClick={e => e.stopPropagation()}>
                                      <input
                                        type="text"
                                        name="search"
                                        className="form-control text-center"
                                        placeholder=""
                                        defaultValue={1}
                                        value={index_of_sel && this.state.qty_selected >= 0 ? this.state.qty_selected[index_of_sel] : undefined}
                                        onChange={event => {
                                          if (/^[1-9][0-9]*$/.test($(event.target).val()) || !$(event.target).val().length) {

                                            if (index_of_sel < 0) {
                                              // this.checkItem(item.id)
                                            } else {
                                              const { qty_selected } = this.state
                                              qty_selected[index_of_sel] = event.target.value
                                              this.setState({ qty_selected: qty_selected.splice(0) })
                                            }
                                          }
                                        }}
                                        onBlur={(event) => {
                                          if (/\D+/g.test(event.target.value) || !$(event.target).val().length) {
                                            $(event.target).val(1)
                                            if (index_of_sel < 0) {
                                              // this.checkItem(item.id)
                                            }
                                            const { qty_selected } = this.state
                                            qty_selected[index_of_sel] = 1
                                            this.setState({ qty_selected: qty_selected.splice(0) })
                                          }
                                        }}
                                      />
                                    </TData>
                                  </TRow>
                                )
                              })
                              return rows
                            }}
                          // renderNotification={() => {
                          //     return (<TableNotificationCard type="success" message="Success message here..." />)
                          // }}

                          />

                        </div>

                      </div>
                    </TabContent>
                  </TabBody>
                )
              }}
            />


          </div>
        </Modal>

        <ReviewItems
          selected_items={this.state.selected_items}
          selected_items_filtered={this.state.selected_items_filtered}
          selected_items_review={this.state.selected_items_review}
          qtyfiltered={this.state.qtyfiltered}
          qtyunfiltered={this.state.qtyunfiltered}
          posfiltered={this.state.posfiltered}
          posunfiltered={this.state.posunfiltered}
          checkItem={this.checkItem}
          checkItemExistence={this.checkItemExistence}
          setSelected={this.setSelected}
          body_areas={this.props.body_areas}
          assign={this.props.assign}
          change={this.state.change}
          key={this.state.modalkey}
          selected_body_area={this.props.selected_body_area}
          qty_selected={this.state.qty_selected}
        />

        <ViewItemModal
          idModal={this.state.unique}
          key={this.state.unique}
          item={this.state.selectedItem}
          cancel={() => {
            $('#viewItemModal').modal('hide')
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return ({
    inventory_categories: state.item.inventory_categories,
    medical_inventories: state.item.medical_inventories,
    items: state.item.items,
    body_areas: state.positioning.body_areas,
    more: state.item.more,
    loading: state.item.loading
  })
}

export default connect(mapStateToProps, { getMedicalInventories, getItemsPerCategory, setItems, getBodyAreas, getMedicalInventoryCategories, itemLoading })(AssignItemsPositioning)
