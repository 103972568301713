import React, { Component } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import _ from 'lodash'
import $ from 'jquery'

export class SettingsSkeleton extends Component {

  constructor(props) {
    super(props)

    this.state = {
      counter: _.range(_.random(1, 3)),
      color: '#d2d2d2',
      highlightColor: '#dedede'
    }
  }
  componentWillMount() {
    if ($('body').hasClass('dark-theme')) {
      this.setState({
        color: '#1d212e',
        highlightColor: '#151821'
      })
    }
  }


  titleElement = () => {
    return (
      <Skeleton height={450} />
    )
  }
  render() {
    return (
      <>
        <div className="row mb16" id="settings">
          <div className="col-md-12 mx-auto">
            <h2 className="page-sub-title">Settings</h2>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            {this.titleElement()}
          </div>
        </div> 
      </>
    )
  }
}

export default SettingsSkeleton
