import React, { Component } from 'react'

export class TabBody extends Component {
  render() {
    const { id = 'nav-tabContent', className='' } = this.props
    return (
      <div className={`tab-content ${className}`} id={id}>
        {this.props.children}
      </div>
    )
  }
}

export default TabBody
