import arrayMove from 'array-move'
import axios from 'axios'
import $ from 'jquery'
import _ from 'lodash'
import { Cropper } from 'rc-cropping'
import Dialog from 'rc-dialog'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import sanitizeHtml from 'sanitize-html'
import ImagePicker from '../../components/commons/elements/inputs/ImagePicker'
import { Button, Picker, TextBox, TextInput } from '../../components/commons/forms'
import AddNewSection from '../../components/commons/media_elements/AddNewSection'
import StandardEditor from '../../components/commons/media_elements/StandardEditor'
import PopoverIcon from '../../components/commons/misc/PopoverIcon'
import { ConfirmationModal } from '../../components/commons/modals'
import { hideLoader, setLoaderProg, showLoader } from '../../components/loader/loading_screen'
import WholeBodyloader from '../../components/loader/WholeBodyloader'
import { dataURItoBlob, getError } from '../../utilities'

class HubCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      categories: [{
        id:'basic',
        name: 'Basic Plan ( 5 members)'
      },
      {
        id:'premium',
        name: 'Premium Plan  ( 10 members)'
      },{
        id:'gold',
        name: 'Gold Plan  ( 50 members)'
      }],
      file: null,
      croppedFile: null,
      show: false,
      isNext: false,
      filename: '',
      selected: null,
      image: '',
      name: '',
      address: '',
      plan: '',
      size: '',
      company: '',
      category: '',
      description: '',
      used: '',
      tags: [],
      isMedia: false,
      skip: false,
      medias: [],
      errors: [],
      progressMedia: [],
      progress: 0,
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
    }

    this.elementsRef = []

    const SortableItem = SortableElement(({ media, index2, key }) => {
      let index = index2
      return media.show ? (
        <li key={`media_key_${index}`}>
          <StandardEditor
            elementRef={(e) => { this.elementsRef[index2] = e }}
            validation={{
              errors: this.state.errors,
              index: index,
              field: 'img_name',
              itemable: true,
            }}
            data={media}
            media_data={media}
            onTitleChange={event => this.titleChange(event, index)}
            onStandardTextChange={event => this.captionChange(event, index)}
            onMediaChange={event => this.mediaChange(event, index)}
            onDelete={event => this.onDelete(event, index)}
            onDuplicate={event => this.onDuplicate(event, index)}
            thumbnailChange={thumbnail =>
              this.thumbnailChange(thumbnail, index)
            }
            progressMedia={() => {}}
            progressComplete={() => {}}
          />
        </li>
      ) : null
    })

    this.SortableList = SortableContainer(({ items }) => {
      return (
        <ul className="list-unstyled">
          {items.map((value, index) => (
            <SortableItem
              key={`item-${index}`}
              index={index}
              index2={index}
              media={value}
            />
          ))}
        </ul>
      )
    })

    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {
    
  }

  onSaveItem(validate = false) {
    const {
    
      image,
      name,
      plan,
      address,
    } = this.state

    const form = new FormData()
    if (image.indexOf('base64') >= 0) {
      form.append('icon', dataURItoBlob(image))
    }
    form.append('name', name)
    form.append('plan', plan)
    form.append('address', address)
    

    let config = {
      headers: {
        'content-type': 'multipart/form-data',
      },

      onUploadProgress: progressEvent => {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        console.log('percentCompleted', percentCompleted)
        showLoader(percentCompleted >= 95 ? 95 : percentCompleted)
        setLoaderProg(percentCompleted >= 95 ? 95 : percentCompleted)
        // this.setState({
        //   progress: percentCompleted >= 95 ? 95 : percentCompleted,
        // })
      },
    }

    let uri = `v1/hub`


    axios
      .post(uri, form, config)
      .then(result => {
        console.log(result)
        this.setState({
          errors: [],
          skip: false,
        })

        setLoaderProg(99)
        setTimeout(() => {
          hideLoader()
        }, 800)

      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
        setLoaderProg(99)
        setTimeout(() => {
          hideLoader()
        }, 800)
        if (error.response.status != 422) {
          this.setState({
            progress: 0,
          })
          return
        }
        this.setState(
          {
            progress: 0,
            errors: error.response.data.errors,
          },
          () => {
            console.log(this.state.errors)
            let targetOffset = $('.is-invalid').offset()
              ? $('.is-invalid').offset().top
              : ''
            $('html, body').animate({ scrollTop: targetOffset }, 100)
          }
        )
      })
  }

  addMoreMedia() {
    const { medias } = this.state
    medias.push({
      show: true,
      title: '',
      media: '',
      type: '',
      caption: '',
      id: 0,
      thumbnail: '',
      progressMedia:false
    })
    this.setState({
      medias:medias.splice(0),
    })
  }

  handleClick(e) {
    this.setState({
      show: true,
    })
    this.refs.file.click()
  }
  onFileChange = () => {
    this.setState({ file: this.refs.file.files[0] })
  }
  croppeFile = fileBlob => {
    if (!fileBlob) {
      this.setState({
        show: false,
        file: null,
        croppedFile: null,
        isNext: false,
        page: 1,
      })
      return
    }
    const reader = new FileReader()
    reader.readAsDataURL(fileBlob)
    let filename = this.state.file.name
    reader.onload = () => {
      this.setState({
        show: false,
        file: null,
        croppedFile: null,
        isNext: false,
        page: 1,
        filename: filename,
        image: reader.result,
      })
    }
  }
  renderCropper() {
    const { file } = this.state

    if (!file) {
      if (this.state.filename) {
        return (
          <div className="d-inline-flex aling-items-center">
            <p className="m0">{this.state.filename}</p>
            <input
              type="file"
              onChange={this.onFileChange}
              ref="file"
              className="d-none"
            />
            <button
              className="btn btn-link ml20 text-dark font-weight-bold py0"
              onClick={this.handleClick}
            >
              Edit
            </button>
          </div>
        )
      }

      return (
        <div>
          <input
            type="file"
            onChange={this.onFileChange}
            ref="file"
            className="d-none"
          />
          <button className="btn btn-dark mw150" onClick={this.handleClick}>
            Upload
          </button>
        </div>
      )
    } else {
      if (this.state.show) {
        return (
          <Cropper
            size={[64, 64]}
            file={this.state.file}
            getSpinContent={() => <span>loading...</span>}
            renderModal={() => <Dialog className="modal-dialog-centered" />}
            onChange={this.croppeFile}
            locale="en-US"
            circle={false}
          />
        )
      }
    }
  }
  handleChange(tags) {
    this.setState({ tags })
  }

  stripHtml(html) {
    var tmp = document.createElement('DIV')
    tmp.innerHTML = html
    return tmp.textContent || tmp.innerText || ''
  }

  tagHelper() {
    const { name, description } = this.state
    const nameTag = name.split(' ')
    const deschar = sanitizeHtml(description, {
      allowedTags: [],
      allowedAttributes: {},
    }).replace('/\r?\n|\r/g')

    const descs = deschar.split(' ')
    let desc = []
    let desc_fil = ''
    descs.map(d => {
      // desc.push($(`<div> ${d} </div>`).text().replace(/(<([^>]+)>)/ig, ""))
      console.log('desscccdesscccdessccc', d, this.stripHtml(d))
      desc.push(d)
      desc_fil += d
    })
    const tag = [...nameTag, ...desc]
    var filtered = tag.filter(function(el) {
      return el.length != 0
    })
    console.log(filtered)
    
    this.setState({
      tags: filtered,
    }, () => {
      this.setState({
        tags: [...new Set(this.state.tags)]
      })
    })
  }

  renderInputTags({ addTag, ...props }) {
    let { onChange, value, ...other } = props
    return (
      <textarea
        className="form-control"
        rows={4}
        onChange={onChange}
        value={value}
        {...other}
      />
    )
  }

  clean(html) {
    return sanitizeHtml(html, {
      allowedTags: [
        'p',
        'div',
        'i',
        'b',
        'strong',
        'u',
        'ul',
        'li',
        'ol',
        'table',
        'thead',
        'tbody',
        'tfoot',
        'td',
        'th',
        'tr',
      ],
      allowedAttributes: {
        a: ['href'],
      },
    })
  }

  renderForm() {
    const categoryOptions = this.state.categories.map(item => {
      return {
        id: item.id,
        name: item.name,
      }
    })

    categoryOptions.unshift({
      id: '',
      name: 'Select a Plan',
    })
    return (
      <div className="col-lg-5 col-md-6">
        <h2 className="page-sub-title">Hub Information</h2>
        <p className="page-description">
          Fields required are labelled with <span className="error">*</span>
        </p>
        <div className="input-holder">
          <label>Upload photo</label>
          <ImagePicker
            aspectRatio={3 / 2}
            getImage={image => {
              this.setState({ image: image })
            }}
            width={480}
            height={480}
            key={`image-picker-${this.state.unique}`}
          />
        </div>
        <div className="input-holder">
          <TextInput
            label={'Name *'}
            value={this.state.name}
            placeholder={'Hub name'}
            errorMessage={getError(this.state.errors, 'name')}
            containerClass={`mb30`}
            onValueChange={event => {
              this.setState({ name: event.target.value })
            }}
          />
        </div>

        <div className="input-holder">
          <TextInput
            label={'Address *'}
            value={this.state.address}
            placeholder={'Address'}
            errorMessage={getError(this.state.errors, 'address')}
            containerClass={`mb30`}
            onValueChange={event => {
              this.setState({ address: event.target.value })
            }}
          />
        </div>

        

        <div className="input-holder">
          <Picker
            errorMessage={getError(this.state.errors, 'plan')}
            containerClass={`mb30`}
            onChange={event => {
              this.setState({ plan: event.target.value })
            }}
            label={`Select Plan *`}
            value={this.state.plan}
            data={categoryOptions}
          />
        </div>
        
        <div className="form-group mb-0 pb-0 d-flex">
          <Button
            primary
            className="w150 px10"
            onClick={event => {
              event.preventDefault()
              this.onSaveItem()
              $('[data-toggle="popover"]').popover('hide')
            }}
          >
            Save
          </Button>
          <Button
            secondary
            className="w100 px10"
            datatarget="#cancelConfirmModal"
            datatoggle="modal"
          >
            Cancel
          </Button>
        </div>
      </div>
    )
  }
  titleChange(event, index) {
    const { medias } = this.state

    medias[index].title = event.target.value

    this.setState({
      medias: medias.splice(0),
    })
  }

  titleChange(event, index) {
    const { medias } = this.state

    medias[index].title = event.target.value

    this.setState({
      medias: medias.splice(0),
    })
  }
  captionChange(event, index) {
    const { medias } = this.state

    medias[index].caption = event.editor.getData()

    this.setState({
      medias: medias.splice(0),
    })
  }

  onDelete(event, index) {
    event.preventDefault()
    const { medias } = this.state

    medias[index].progressMedia = false
    medias[index].show = false

    this.setState({
      medias: medias.splice(0),
    })
  }
  onDuplicate(event, index) {
    event.preventDefault()
    const { medias } = this.state
    const media = _.cloneDeep(medias[index])
    medias.push(media)
    const newMedia = medias
    this.setState({
      medias: newMedia,
    }, () => {
      this.elementsRef[newMedia.length - 1].scrollIntoView({behavior: 'smooth'});
    })
  }

  mediaChange(event, index) {
    const { medias } = this.state

    medias[index].source = event.data
    medias[index].type = event.type

    this.setState({
      medias: medias.splice(0),
    })
  }

  thumbnailChange(thumbnail, index) {
    const { medias } = this.state
    medias[index].thumbnail = thumbnail
    this.setState({
      medias: medias.splice(0),
    })
  }
  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ medias }) => ({
      errors: [],
      medias: arrayMove(medias, oldIndex, newIndex),
    }))
  }

  renderMedia() {
    const media_items = (
      <this.SortableList
        items={this.state.medias}
        onSortEnd={this.onSortEnd}
        distance={1}
        useDragHandle
        useWindowAsScrollContainer={true}
      />
    )

    const { video_progress } = this.props

    const progressList = $('.pie-wrapper').length
    console.log('progressList', progressList, video_progress)

    return (
      <div className="col-md-8">
        <h2 className="page-sub-title">Add media and instructions</h2>
        <p className="page-description w-50">
          Here you can add images or videos to provide additional information on
          how your hospital uses the item.
        </p>

        <div className="row mb-0">
          <div className="col">{media_items}</div>
        </div>
        <div className="row mb-0">
          <div className="col-12">
            <AddNewSection
              title="Add more image / video +"
              single
              onClick={this.addMoreMedia.bind(this)}
            />
          </div>
        </div>
        <div className="form-group pt30 pb-0 mb-0 d-flex">
          <Button
            primary
            datatarget="#saveModal"
            datatoggle="modal"
            disabled={progressList}
          >
            Save item
          </Button>
          <Button secondary onClick={() => this.setState({ isMedia: false })}>
            Back
          </Button>
          <Button tertiary datatarget="#skipModal" datatoggle="modal">
            Skip step
          </Button>
        </div>
      </div>
    )
  }

  renderView() {
    if (this.state.isMedia) return this.renderMedia()
    else return this.renderForm()
  }
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-lg-9 col-md-8 offset-lg-3 offset-md-4">
            <h1 className="page-title">
              Create Hub
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-4 mb30">
            <div className="card side-card border-0">
              <div className="card-body border-0 p-0">
                <img
                  src={
                    this.state.image
                      ? this.state.image
                      : require('../../assets/images/item.jpg')
                  }
                  className="bg-white w-100 media-card"
                />
              </div>
            </div>
          </div>
          {this.renderView()}
          <ConfirmationModal
            idModal="cancelConfirmModal"
            title="Cancel"
            message="Are you sure you want to cancel?"
            confirmText="Yes"
            cancelText="No"
            confirm={this.props.modalCancel}
            cancel={() => {
              $('#cancelConfirmModal').modal('hide')
            }}
          />

          <ConfirmationModal
            title="Skip and Save"
            message="Are you sure you want to skip this process?"
            confirmText="Yes"
            cancelText="No"
            idModal={'skipModal'}
            confirm={event => {
              event.preventDefault()
              this.setState(
                {
                  skip: true,
                },
                () => {
                  this.onSaveItem()
                }
              )
            }}
            cancel={() => {
              $('#skipModal').modal('hide')
            }}
          />

          <WholeBodyloader
            message="Saving! please wait..."
            progress={this.state.progress}
            type="progress"
          />

          <ConfirmationModal
            title="Save"
            message="Are you sure you want to save?"
            confirmText="Yes"
            cancelText="No"
            idModal={'saveModal'}
            confirm={event => {
              event.preventDefault()
              this.onSaveItem(false)
            }}
            cancel={() => {
              $('#saveModal').modal('hide')
            }}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    medical_inventory_id: state.item.medical_inventory_id,
    video_progress: state.video.progress,
  }
}

export default connect(mapStateToProps, null)(HubCreate)
