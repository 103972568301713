import axios from 'axios'
import { dataURItoBlob } from './helpers'
export const uploadImage = (fileData, onProgress,cancelToken) => {

  console.log(fileData)
  const uri = `api/upload-image`
  const config = {
    cancelToken,
    headers: {
      'content-type': 'multipart/form-data',
    },

    onUploadProgress: progressEvent => onProgress(progressEvent),
  }

  const form = new FormData()
  const file = dataURItoBlob(fileData)
  form.append('name', file.name)
  form.append('type', file.type)
  form.append('size', file.size)
  form.append('image', file,file.name)

  return axios
    .post(uri, form, config)
    .then(result => {
      console.log(result)
      return Promise.resolve(result)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}
