import React, {Component} from 'react';
import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe
} from 'react-stripe-elements'
import axios from 'axios'
import $ from 'jquery'
import Button from './Button';
import { getUser } from '../../../actions';
import { connect } from 'react-redux'
import { getPaymentDetail } from '../../../actions/PaymentActions';
import { showLoader, setLoaderProg, hideLoader } from '../../loader/loading_screen';
import Notifier from '../notifier/Notifier';

class CheckoutForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: '',
      duration: '',
      durations: [
        {id: 1, value: 'monthly', name: 'Monthly', amount: '9.99', label: 'mo'},
        {id: 2, value: 'annually', name: 'Annually', amount: '99.99', label: 'yr'},
      ],
      errors: [],
      paymentError: false,
    }

    this.submit = this.submit.bind(this);
  }

  async submit(ev) {
    showLoader(1);
    setLoaderProg(1)
    let { token } = await this.props.stripe.createToken({name: this.state.name, })
    
    if(token){
      console.log(token)
      let url = '/api/client/subscribe'
      const { trialOnly} = this.props
      let data = new FormData()
      data.append('stripeToken', token.id)
      if(trialOnly){
        data.append('duration', 'trial')
      }
      else{
        if(this.state.duration == '')
          {
          alert('Select Plan')
          return
          }
        data.append('duration', this.state.durations[this.state.duration].value)
      }

      let config = { 
        onUploadProgress: progressEvent => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          showLoader(percentCompleted >= 95 ? 95 : percentCompleted);
          setLoaderProg(percentCompleted >= 95 ? 95 : percentCompleted)
        },
      }
      axios.post(url, data, config).then((res) => {
        this.props.getUser()
        this.props.getPaymentDetail()
        $('#upgradePlanModal').modal('hide')

        if(this.props.trialOnly){
          this.props.setTrialOnly(true)
          $('#upgradeToFreeTrialModal').modal('show')
        }
        else{
          $('#thankYouModal').modal('show')
        }

        setLoaderProg(99)
        setTimeout(() => {
          hideLoader()
        }, 800);
      }).catch((err) => {
        setLoaderProg(99)
        setTimeout(() => {
          hideLoader()
        }, 800);
        console.log('stripe', err)
        console.log('stripe', err.response)
      })
    }else{
      showLoader(0);
      setLoaderProg(0)
      this.setState({
        paymentError: true
      })
      this.props.setPaymentError(true)
      //alert('Payment error!') 
    }
    
  }

  render() {
    const { durations } = this.state
    const { trialOnly = false } = this.props 

    let duration_list = durations.map((duration, index) => {
      return (<option value={index} key={`dl-${duration.id}-${index}`}>{duration.name}</option>)
    })
    if (trialOnly){
      duration_list = <option value={0} key={`dl-0-0`}>Trial Free</option>;
    }
    return (
      <div className="checkout">
        <div className={`row mb32 ${trialOnly ? 'd-none':''}`}>
          <div className="col-md-8">
            <div className="w-100 float-left d-flex align-items-center">
              <span className="mr12">You will be billed</span> 
              <div className="line-form-group-v2 w120 bold" onChange={(event) => { this.setState({ duration: event.target.value }) }} defaultValue=''>
                <select> 
                  <option>Duration</option>
                  {duration_list}
                </select> 
              </div>
            </div>                              
          </div>
          <div className="col-md-4 text-right d-flex justify-content-end align-items-center">
            {this.state.duration &&  this.state.duration != '' ? <h3>${this.state.durations[this.state.duration] ?this.state.durations[this.state.duration].amount:'XX.XX'} <small>/ {this.state.durations[this.state.duration] ? this.state.durations[this.state.duration].label:''}</small></h3> : <h3>$ XX.XX</h3>}
          </div>
          {/* <div className="col-md-12">
            <div className="w-100 float-left">
              <p className="m-0 text-success">Save 10% with annual billing.</p>
            </div>
          </div> */} 
        </div>
        <div className='mb32'> 
          <div className="line-form-group-v2">
            <input type="text" className="line-form-v2" value={this.state.name} onChange={(event) => { this.setState({ name: event.target.value }) }} />
            <label>Name on card</label> 
          </div>
        </div>
        <div className="row">
          <label className="col-md-12 mb32 floaty-label">
            <span>Card number</span>
            <span className="brand"><i className="fa fa-credit-card" id="brand-icon"></i></span>
            <CardNumberElement onChange={(event)=>{
              $('#brand-icon').attr('class','')
              console.log(event.brand)
              if (!event.empty){
                $('#brand-icon').addClass(`fa fa-cc-${event.brand}`)
              } else {
                $('#brand-icon').attr('class', 'fa fa-credit-card')
              }
            }} />
          </label> 
          <label className="col-md-6 mb32 floaty-label">
            <span>Expiry date (MM / YY)</span>
            <CardExpiryElement />
          </label> 
          <label className="col-md-6 mb32 floaty-label">
            <span>CVC / CVV</span>
            <CardCVCElement />
          </label>
        </div>
        <div className="row mb32">
          <div className="col-12 text-left">
            <p>
              <span className="total-billed">Total billed</span>
              <b className="bill">${trialOnly ? '0.00':this.state.duration ? this.state.durations[this.state.duration] ? this.state.durations[this.state.duration].amount:'XX.XX':'XX.XX'}</b>
            </p>
          </div>
        </div>
       
      
        <div className="row">
          <div className="col-md-12 text-center"> 
            <Button primary onClick={() => { this.submit() }}>Upgrade</Button>
          </div>
        </div>

      </div>
    )
  }
}

const mapStateToProps = state => {

  return {  }
}

export default  connect(mapStateToProps,
  { getUser,getPaymentDetail })(injectStripe(CheckoutForm))