import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { applyMiddleware } from 'redux'
import storage from 'redux-persist/lib/storage'
import createCompressEncryptor from 'redux-persist-transform-compress-encrypt'

let data = {}

  data.devTools = process.env.NODE_ENV === 'production'
  ? applyMiddleware(thunk)
  : composeWithDevTools(applyMiddleware(thunk))

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  

  data.persistConfig = {
    key: 'staging-Rf5ofoVjyEgHVgr0MCYPbapFxnl9GBNsOW9qpI1Zh9xrn8kB1o',
    storage,
    whitelist: ['spcialty', 'surgeon', 'item', 'quicknotes', 'auth'],
  }
  data.stripeKey = `pk_test_ugcuD3jta7E0VBovSCcjqqzw00OthxBawv`
  data.vimeoKey = `a414e807eff23826daf0f6b294dee56f`
} else {

  const transformer = createCompressEncryptor({
    secretKey: '7DgjrwWNp970AD2nChYPbSyC6Zt4crGzKNtKQ25gFcaLtgOxCFSUVhhNVSHsZ8EA64xeXOGiHdmfUUkOqi6YiwArKnc89Fbk3DsQHzSZeDV4Ld12',
    whitelist:['auth'],
    onError: function(error) {
        
    }
  })
  data.vimeoKey = `a414e807eff23826daf0f6b294dee56f`
  data.persistConfig = {
    key: 'XUMF9HpwtJPMyaDCk7Hx39uRsnmIWgWaXCwuAfCdtCUHynf7zI9lfz3H0Qxl12',
    storage,
    whitelist: ['spcialty', 'surgeon', 'item', 'quicknotes', 'auth'],
    transforms: [transformer],
  }
  data.stripeKey = `pk_test_ugcuD3jta7E0VBovSCcjqqzw00OthxBawv`

}

export const ENVIROMENT = data
