import React, { Component } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import $ from 'jquery'

export class PositioningSkeleton extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      color: '#d2d2d2',
      highlightColor: '#dedede'
    }
  }

  componentWillMount() {
    if ($('body').hasClass('dark-theme')) {
      this.setState({
        color: '#1d212e',
        highlightColor: '#151821'
      })
    }
  }
  
  textElement = () => {
    return (
    <div className={'card text-preview w-100 mb20'}>
        <div className="card-header text-capitalize ">
          <Skeleton width={'50%'} height={30} />
        </div>
      </div>
    )
  }


  render() {
    return (

      <SkeletonTheme color={this.state.color} highlightColor={this.state.highlightColor}>
      <div className="row">
        <div className="col-md-12">
          <h4 className="mb-0 d-flex align-items-end fs20 d-none"></h4>
        </div>
        <div className="col-md-12 profile-card pt-0">

          <div class="data-card mb20">
            <div class="card-header  ">
                <h3 class="  bold w-100"><Skeleton height={20} width={'50%'} /></h3>
            </div>

            <div class="card-body undefined">

              <div class="row">

                <div class="col-lg-6">
                  <h4 class="mb8"><Skeleton height={20} width={'50%'} /></h4>
                  <div className="ar32 border skeleton"><Skeleton height={'100%'} /></div>
                  
                </div>

                <div class="col-lg-6">
                  <h4 class="mb8"><Skeleton height={20} width={'50%'} /></h4>
                  <div className="ar32 border skeleton"><Skeleton height={'100%'} /></div>
                </div>

              </div>
            </div>

          </div>
        </div>

        <div className="col-md-12 ">
          {this.textElement()}
        </div>

      </div>
      </SkeletonTheme>
    )
  }
}

export default PositioningSkeleton
