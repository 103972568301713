import Vimeo from '@u-wave/react-vimeo'
import $ from 'jquery'
import React, { Component } from 'react'
import error_img from '../../../../src/assets/images/error_img.png'


export class StandardElement extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      id: this.makeid(10), 
      height: 300,
      key: this.makeid(15)
    }
  }

  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  
  checkImage(src) {


    return src;

  }
   


  componentDidMount() {
    this.setState({
      key: this.makeid(15),
      height: $(`#${this.state.id} .media-preview`)[0].offsetHeight
    })
  }

  isUrl(s) {
    var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
    return regexp.test(s)
  }

  renderVideo(gallery) {
    let vimeoError = false;
    // console.log('gallery', gallery)
    if (!this.isUrl(gallery.source)) {
      return (
        <div className="ar32 in-progress">
          <img src={gallery.thumbnail} />
        </div>
      )
    }
    
    console.log('gallery', gallery)
    if (gallery.disk == 'local') {
      return (
        <video className="bg-dark w-100 align-items-center h-100" controls>
          <source src={gallery.source} type="video/mp4" />
          <source src={gallery.source} type="video/webM" />
          <source src={gallery.source} type="video/ogg" />
          Your browser does not support the video tag.
				</video>
      )
    }

    if (vimeoError) {
      return <img class="bg-light w-100 h-100" src={error_img} />
    } else {
      if (gallery.preview) {
        return (
          <div className="ar32 limit-reached">
            <img src={gallery.thumbnail} />
          </div>
        )
      } else {
        if (gallery.status == 'in_progress') {
          return (
            <div className="ar32 in-progress">
              <img src={gallery.thumbnail} />
            </div>
          )
        } else {
          return (
            <div className="ar32 vimeo">
              <Vimeo
                video={gallery.source} className="w-100 align-items-center"
                height={this.state.height}

                onError={() => {
                  vimeoError = false;
                  this.setState({
                    errorInVimeo: true,
                    video_loaded: false
                  })
                }}

                onLoaded={() => {
                  this.setState({
                    video_loaded: false
                  })
                }}
              /></div>
          )
        }

      }

    }
  }
  render() { 
    const { data, className, quicknotes = null } = this.props
    let title, content, img, type='image', disk='', gallery = null;
    if(quicknotes){ 
      title = quicknotes.title;
      content = quicknotes.contents[0].elements[0].content;
      img = quicknotes.contents[1].elements[0] ? quicknotes.contents[1].elements[0].gallery.thumbnail:'';

      type = quicknotes.contents[1].elements[0] ? quicknotes.contents[1].elements[0].gallery.type : '';
      disk = quicknotes.contents[1].elements[0] ? quicknotes.contents[1].elements[0].gallery.disk:'';
      
      if (type == 'video' || disk == 'vimeo'){
        // disk = quicknotes.contents[1].elements[0] ? quicknotes.contents[1].elements[0].gallery.disk : ''; 
        gallery = quicknotes.contents[1].elements[0] ? quicknotes.contents[1].elements[0].gallery:null;
      }
    } else {
      title = data.title;
      content = data.contents ? data.contents.caption : data.caption;
      img = data.contents.gallery ? data.contents.gallery.source : data.source;

      type = data.contents.gallery ? data.contents.gallery.type : '';
      disk = data.contents.gallery ? data.contents.gallery.disk : ''; 
      if (type == 'video' || disk == 'vimeo') {
        // disk = quicknotes.contents[1].elements[0] ? quicknotes.contents[1].elements[0].gallery.disk : ''; 
        gallery = data.contents ? data.contents.gallery : null;
      }
    }
    

    
    return (
      <div className={`card standard-preview ${className}`} id={this.state.id} key={this.state.key}>
        <div className="card-body">
          <div className="col-lg-6 pl0">
            { title ? <div className="card-header">{title}</div>:null }
            <div className="card-content" dangerouslySetInnerHTML={{
              __html: content ? content:''
            }}> 
            </div>
          </div>
          <div className="col-lg-6 pr0 media-preview">
            {
              gallery ? this.renderVideo(gallery) :  
                <div className="ar32" onClick={() => {
                  $("#media_type").val('image').change()
                  $("#media_title").val(title).change()
                  $("#media_source").val(img).change()
                  $("#fullMediaViewModal").modal('show')
                }}>
                  <img src={img} />
                </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default StandardElement
