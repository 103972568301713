import React, { Component } from 'react'
import img from '../../../src/assets/images/emma.png'
import TextElement from '../commons/media_elements/TextElement';
import StandardElement from '../commons/media_elements/StandardElement';

import MediaElement from '../commons/media_elements/MediaElement'; 
import AddNewSection from '../commons/media_elements/AddNewSection';
import Icon from '../commons/icons/Icon';
import { TextInput } from '../commons/forms';
import CKEditor from 'ckeditor4-react'
import InfoLabel from '../commons/media_elements/InfoLabel';

export default class ContentEditor extends Component {
   constructor(props) {
     super(props)
   
     this.state = {
       unique: Math.random()
                .toString(36)
                .substring(2, 15) +
              Math.random()
                .toString(36)
                .substring(2, 15),
       textContentChange: '',
      textContentChange2:'',
     }
   }
   
  

  render() {
    
    return (
      <div className="row">
        <div className="col-md-12">
          <h1>Content editor</h1>
          <hr />
          <hr />
          
          <h3>PREVIEW MODES</h3>
          <hr />

          <h1 className="mb20">Text element</h1>
          <TextElement data={{ title: 'Before you start', content: '<p>Sample text here.</p>' }} />
          <hr />

          <h1 className="mb20">Standard element</h1>
          {/* <StandardElement data={{ title: 'Before you start', content: '<p>Sample text here.</p>', source: null }} /> */}
          <hr />

          <h1 className="mb20">Media element</h1>
          {/* <MediaElement /> */}
          <hr />

          <h1 className="mb20">Add a new section</h1> 
          <AddNewSection  
            addTextOnly={()=>{
              alert('Click AddTextOnly')
            }}
            addMediaGallery={()=>{
              alert('Click AddMediaGallery')
            }}
            addTextAndMedia={()=>{
              alert('Click AddTextAndMedia')
            }}
          />
          <hr />
          <hr />

          <h3>EDITOR MODES</h3>
          <hr />

          <h1 className="mb20">Text editor</h1>
          <div className="card text-editor">
            <div className="card-header">
              <a href="#" className="move-icon"><Icon icon="move-icon" className="negative" height={30} width={15} /></a>
              <a href="#" className="copy-icon"><Icon icon="copy" className="negative" height={30} width={30} /></a>
              <a href="#" className="trash-icon"><Icon icon="trash" className="negative" height={30} width={30} /></a>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-5 col-md-6">
                  <TextInput placeholder="Title here..." />
                </div>
              </div>
            </div>
            <div className="card-body editor-body">
              <CKEditor
                timestamp={this.state.unique}
                onChange={this.state.textContentChange}
                config={{
                  allowedContent: {
                    $1: {
                      attributes: true,
                      styles: true,
                      classes: true
                    }
                  },
                  toolbar: [
                    ['Bold', 'Italic', 'Underline', '-'],
                    [
                      'NumberedList',
                      'BulletedList',
                      '-',
                      // 'Outdent',
                      // 'Indent',
                      // '-',
                      'JustifyLeft',
                      'JustifyCenter',
                      'JustifyRight',
                      // 'JustifyBlock',
                      '-',
                    ],
                    [//'Table', 'HorizontalRule', 
                      'Link',
                      'Smiley'
                    ],
                    ['Format'],
                    ['TextColor', 'BGColor'],
                  ],
                  removePlugins: 'elementspath',
                  resize_enabled: false

                }}
              />
            </div>
          </div>


          <hr />
          <h1 className="mb20">Standard editor</h1>
          <div className="card standard-editor">
            <div className="card-header">
              <a href="#" className="move-icon"><Icon icon="move-icon" className="negative" height={30} width={15} /></a>
              <a href="#" className="copy-icon"><Icon icon="copy" className="negative" height={30} width={30} /></a>
              <a href="#" className="trash-icon"><Icon icon="trash" className="negative" height={30} width={30} /></a>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-5 col-md-6">
                  <TextInput placeholder="Title here..." />
                </div>
              </div>
            </div>
            <div className="card-body standard-body">
              <div className="card-body editor-body w-50 float-left ">
                <CKEditor
                  data="<p>Some initial data</p>"
                  timestamp={12321312312312312}
                  onChange={this.state.textContentChange2}
                  config={{
                    allowedContent: {
                      $1: {
                        attributes: true,
                        styles: true,
                        classes: true
                      }
                    },
                    toolbar: [
                      ['Bold', 'Italic', 'Underline', '-'],
                      [
                        'NumberedList',
                        'BulletedList',
                        '-',
                        // 'Outdent',
                        // 'Indent',
                        // '-',
                        'JustifyLeft',
                        'JustifyCenter',
                        'JustifyRight',
                        // 'JustifyBlock',
                        '-',
                      ],
                      [//'Table', 'HorizontalRule', 
                        'Link',
                        'Smiley'
                      ],
                      ['Format'],
                      ['TextColor', 'BGColor'],
                    ],
                    removePlugins: 'elementspath',
                    resize_enabled: false

                  }}
                />
              </div>
              <div className="card-body img-body">
                <InfoLabel changeable>
                  <span className="change-label"> Add image or video + </span>
                </InfoLabel>
                {/* 
                <div className="ar32">
                  <img src={img} />
                </div>
                 */}
              </div>
            </div>
          </div>


          <hr />

          <hr />

          <h1 className="mb20">Media editor</h1>
          <div className="card media-editor">
            <div className="card-header">
              <a href="#" className="move-icon"><Icon icon="move-icon" className="negative" height={30} width={15} /></a>
              <a href="#" className="copy-icon"><Icon icon="copy" className="negative" height={30} width={30} /></a>
              <a href="#" className="trash-icon"><Icon icon="trash" className="negative" height={30} width={30} /></a>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-5 col-md-6 pt10">
                  <TextInput label="Carousel title" />
                </div>
              </div>
            </div>
            <div className="card-body media-body">

              <div className="card media-item">
                <div className="card-header">
                  <a href="#" className="move-icon"><Icon icon="move-icon" height={30} width={15} /></a>
                  <TextInput placeholder="Title here..." />
                  <a href="#" className="trash-icon"><Icon icon="trash" height={30} width={30} /></a>
                </div>
                <div className="card-body standard-body">
                  <div className="card-body img-body">
                    <div className="ar32">
                      <img src={img} />
                    </div>
                  </div>
                  <div className="card-body editor-body w-50 float-left ">
                    <CKEditor
                      data="<p>Some initial data</p>"
                      timestamp={12321312312312312}
                      onChange={this.state.textContentChange2}
                      config={{
                        allowedContent: {
                          $1: {
                            attributes: true,
                            styles: true,
                            classes: true
                          }
                        },
                        toolbar: [
                          ['Bold', 'Italic', 'Underline', '-'],
                          [
                            'NumberedList',
                            'BulletedList',
                            '-',
                            // 'Outdent',
                            // 'Indent',
                            // '-',
                            'JustifyLeft',
                            'JustifyCenter',
                            'JustifyRight',
                            // 'JustifyBlock',
                            '-',
                          ],
                          [//'Table', 'HorizontalRule', 
                            'Link',
                            'Smiley'
                          ],
                          ['Format'],
                          ['TextColor', 'BGColor'],
                        ],
                        removePlugins: 'elementspath',
                        resize_enabled: false

                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="card media-item">
                <div className="card-header">
                  <a href="#" className="move-icon"><Icon icon="move-icon" height={30} width={15} /></a>
                  <TextInput placeholder="Title here..." />
                  <a href="#" className="trash-icon"><Icon icon="trash" height={30} width={30} /></a>
                </div>
                <div className="card-body standard-body">
                  <div className="card-body img-body">
                    <div className="ar32">
                      <img src={img} />
                    </div>
                  </div>
                  <div className="card-body editor-body">
                    <CKEditor
                      data="<p>Some initial data</p>"
                      timestamp={12321312312312312}
                      onChange={this.state.textContentChange2}
                      config={{
                        allowedContent: {
                          $1: {
                            attributes: true,
                            styles: true,
                            classes: true
                          }
                        },
                        toolbar: [
                          ['Bold', 'Italic', 'Underline', '-'],
                          [
                            'NumberedList',
                            'BulletedList',
                            '-',
                            // 'Outdent',
                            // 'Indent',
                            // '-',
                            'JustifyLeft',
                            'JustifyCenter',
                            'JustifyRight',
                            // 'JustifyBlock',
                            '-',
                          ],
                          [//'Table', 'HorizontalRule', 
                            'Link',
                            'Smiley'
                          ],
                          ['Format'],
                          ['TextColor', 'BGColor'],
                        ],
                        removePlugins: 'elementspath',
                        resize_enabled: false

                      }}
                    />
                  </div>
                </div>
              </div>
              
              <AddNewSection title="Add another image / video +" single />

            </div>
          </div>

        </div>
      </div>
    )
  }
}
