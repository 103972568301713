

import axios from 'axios';
import _ from 'lodash';
import { hideLoader } from '../components/loader/loading_screen';
import { CLEAR_QUICKNOTES_DATA, GET_QUICKNOTES_CATEGORIES, GET_QUICKNOTES_ITEM_LIST, GET_SURGEON_QUICKNOTES, SET_QUICKNOTES_ID, UPDATE_QUICKNOTES_LIST } from './types';


export const updateQuicknotesList = (data) => {

  console.log('update',data)
  return(dispatch) => {
    dispatch({
      type: UPDATE_QUICKNOTES_LIST,
      quicknotes_list: data
    })
  }
}

export const getQuicknotesCategories = () => {
  return(dispatch) => {
    let url = '/api/quicknotes'
    axios.get(url).then((res) => {
      console.log(res)
      dispatch({
        type: GET_QUICKNOTES_CATEGORIES,
        categories: res.data.data
      })
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
    })
  }
}

//id = quicknotes_id
export const getSurgeonQuicknotes = (id, draft = 0 ,callback = null) => {
  return(dispatch, getState) => {
    dispatch({
      type: GET_SURGEON_QUICKNOTES,
      quicknotes_list: [],
      quicknotes_id: [],
      draft_count:0,
      loading: true
    })

    const { surgeon } = getState().surgeon
    const { item_list } = getState().quicknotes

    // let config = {
    //   onDownloadProgress: progressEvent => {
    //     var percentCompleted = Math.round(
    //       (progressEvent.loaded * 100) / progressEvent.total
    //     )
    //     showLoader(percentCompleted >= 95 ? 95 : percentCompleted); 
    //   },
    // }

    let url = `/api/quicknotes-element?surgeon_id=${surgeon.id}&quicknotes_id=${id}&draft=${draft}`

    axios.get(url/* , config */).then((res) => {
      if(callback != null){
        callback()
      }
      dispatch({
        type: GET_SURGEON_QUICKNOTES,
        quicknotes_id: id,
        quicknotes_list: res.data.quicknotes_list,
        surgeon_quicknotes_id: res.data.surgeon_quicknotes_id,
        draft_count:res.data.draft_count,
        loading: true
      })

      url = `/api/quicknotes-item-categories?surgeon_quick_note_id=${res.data.surgeon_quicknotes_id}&draft=${draft}`
      
      axios.get(url).then((res) => {
        let temp = res.data.data

        //set item prn
        temp.map((e, index) => {
          e.details.map((item) => {
            if(index == 0){
              item.prn = temp[0].id
            }
          })
        })

        temp.map((list, index) => {
          temp[index].details = list.details
        })

        dispatch({
          type: GET_QUICKNOTES_ITEM_LIST,
          item_list: temp,
          loading: false
        }) 
        
         hideLoader() 

      }).catch((error) => {

         hideLoader() 

        console.log(error)
        console.log(error.response)
      })
    }).catch((error) => {
       hideLoader() 
      console.log(error)
      console.log(error.response)
    })
  }
}

export const clearQuicknotesData = (id) => {
  return(dispatch, getState) => {
    const { surgeon } = getState().surgeon
    if(surgeon.id !== id){

      dispatch({
        type: CLEAR_QUICKNOTES_DATA
      })
    }
  }
}

//id = quicknotes_id
export const setQuicknotesId = (id) => {
  return(dispatch) => {
    dispatch({
      type: SET_QUICKNOTES_ID,
      quicknotes_id: id
    })
    
  }
}

export const incrementItemQty = (id, item_id) => {
  return(dispatch, getState) => {
    const { item_list } = getState().quicknotes
    let details = item_list.find((item) => item.id === id)
    let item_index = item_list.findIndex((item) => item.id === id)
    let index = details.details.findIndex((item) => item.item.id === item_id)

    item_list[item_index].details[index].qty++
    // debounce(item_list[item_index].details[index])
    dispatch({
      type: GET_QUICKNOTES_ITEM_LIST,
      item_list: item_list.splice(0),
      loading: false
    })
  }
}

// const debounce = _.debounce(item => {
//   onSubmit(item)
// }, 500)

// const onSubmit = item => {
//   const data = { qty: item.qty, _method: 'PATCH' }
//   axios
//     .post(`api/quicknotes-item-list/${item.id}`, data)
//     .then(result => {
//       console.log(result)
//     })
//     .catch(error => {
//       console.log(error)
//     })
// }

export const decrementItemQty = (id, item_id) => {
  return(dispatch, getState) => {
    const { item_list } = getState().quicknotes
    let details = item_list.find((item) => item.id === id)
    let item_index = item_list.findIndex((item) => item.id === id)
    let index = details.details.findIndex((item) => item.item.id === item_id)

    if(item_list[item_index].details[index].qty > 1){
      item_list[item_index].details[index].qty--
    }
    // debounce(item_list[item_index].details[index])

    dispatch({
      type: GET_QUICKNOTES_ITEM_LIST,
      item_list: item_list.splice(0),
      loading: false
    })
  }
}

export const removeItem = (id, item_id) => {
  return(dispatch, getState) => {
    let { item_list } = getState().quicknotes
    let details = item_list.find((item) => item.id === id)
    let item_index = item_list.findIndex((item) => item.id === id)

    _.remove(details.details, (e) => {
      return e.item.id === item_id
    })

    item_list[item_index].details = details.details
    
    dispatch({
      type: GET_QUICKNOTES_ITEM_LIST,
      item_list: item_list.splice(0),
      loading: false
    })
  }
}

export const loadSurgeonQuicknotes = (id) => {
  return(dispatch, getState) => {
    let { quicknotes_id, surgeon_quicknotes_id, item_list } = getState().quicknotes

    dispatch({
      type: GET_QUICKNOTES_ITEM_LIST,
      item_list: [],
      loading: false
    })

    let url = `/api/quicknotes-element?surgeon_id=${id}&quicknotes_id=${quicknotes_id}&draft=0`

    axios.get(url).then((res) => {
      dispatch({
        type: UPDATE_QUICKNOTES_LIST,
        quicknotes_list: res.data.quicknotes_list
      })

      url = `/api/quicknotes-item-categories?surgeon_quick_note_id=${res.data.surgeon_quicknotes_id}&draft=0`
      


      axios.get(url).then((res) => {
        console.log('result', res)
        let temp = res.data.data

        //set item prn
        temp.map((e, index) => {
          e.details.map((item) => {
            if(index == 0){
              item.prn = item_list[0].id
            }
          })
        })

        console.log('temp', temp)

        temp.map((list, index) => {
          item_list[index].details = list.details
        })

        dispatch({
          type: GET_QUICKNOTES_ITEM_LIST,
          item_list: item_list,
          loading: false
        })
      }).catch((error) => {
        console.log(error)
        console.log(error.response)
      })

    }).catch((error) => {
      console.log(error)
      console.log(error.response)
    })
  }
}

export const updateQuicknotesItemList = (items) => {
  return(dispatch, getState) => {
    let { item_list } = getState().quicknotes
    dispatch({
      type: GET_QUICKNOTES_ITEM_LIST,
      loading: false,
      item_list: []
    })
    let prn_items = []
    let or_items = []
    items.map((item) => {
      console.log(item)
      let e = {
        qty: item.qty,
        item: item
      }
      if(item.prn){
        prn_items.push(e)
      }else{
        or_items.push(e)
      }
    })

    item_list[0].details = prn_items
    item_list[1].details = or_items

    console.log(item_list)

    dispatch({
      type: GET_QUICKNOTES_ITEM_LIST,
      loading: false,
      item_list: item_list.splice(0)
    })
  }
}

export const saveQuicknotesItemList = (new_list,draft = 0) => {
  return(dispatch, getState) => {
    const { item_list, surgeon_quicknotes_id } = getState().quicknotes
    console.log('popo', new_list)

    let data = new FormData()
    data.append('surgeon_quick_note_id', surgeon_quicknotes_id)
    data.append('draft', draft)
    new_list.map((list) => {
      list.details.map((item) => {
        data.append('item_id[]', item.item.id)
        data.append('qty[]', item.qty)
        if(!item.prn){
          data.append('category_id[]', item_list[1].id)
        }else{
          data.append('category_id[]', item.prn)
        }
      })
    })

    // let config = {
    //   onDownloadProgress: progressEvent => {
    //     var percentCompleted = Math.round(
    //       (progressEvent.loaded * 100) / progressEvent.total
    //     )
    //     showLoader(percentCompleted >= 95 ? 95 : percentCompleted);
    //   },
    // }

    let url = '/api/quicknotes-item-list'

    axios.post(url, data/* , config */).then((res) => {
      console.log(res)
      dispatch({
        type: GET_QUICKNOTES_ITEM_LIST,
        loading: false,
        item_list: res.data.data
      })
     // hideLoader()
    }).catch((error) => {
      hideLoader()
      console.log(error)
      console.log(error.response)
    })

  }
}