import React, { Component } from 'react'
import $ from 'jquery'
import { Modal } from '../commons/modals';
import error_img from '../../assets/images/error_img.png'
import Vimeo from '@u-wave/react-vimeo'
export class FullMediaView extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      title: '',
      key: this.makeid(10),
      media_type: '',
      media_source: '',
      height: null
    }
  }
  
  componentDidMount() {
    $(document).on('change', '#media_type', (e) => {
      this.setState({
        media_type: e.target.value
      })
    })

    $(document).on('change', '#media_title', (e) => {
      this.setState({
        title: e.target.value
      })
    })

    $(document).on('change', '#media_source', (e) => {
      this.setState({
        media_source: e.target.value
      })
    })
    
    $('#fullMediaViewModal').on('shown.bs.modal', ()=>{
      $(".modal-backdrop").last().css('z-index', '3000')
      $("#fullMediaViewModal").css('z-index', '4000')   
      this.setState({
        height: Math.floor($("#fullMediaViewModal .vimeo").width() * 0.666667)
      })
    })
    
  }

  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  
  renderVimeo() { 
    let vimeoError = false;
    if (this.state.media_type == 'video') {
      return (
        <video className="bg-dark w-100 align-items-center h-100" controls>
          <source src={this.state.media_source} type="video/mp4" />
          <source src={this.state.media_source} type="video/webM" />
          <source src={this.state.media_source} type="video/ogg" />
          Your browser does not support the video tag.
				</video>
      )
    }

    const source = this.state.media_source//.split('/').pop()
    console.log('sourcesource', this.state)
    if (vimeoError) {
      return <img class="bg-light w-100 h-100" src={error_img} />
    } else {
      if (source) {
        return (
          <div className="ar32 vimeo">
            {
              this.state.height > 0 ?
                <Vimeo
                  video={source} className="bg-transparent w-100 align-items-center "
                  onError={() => {
                    vimeoError = false;
                    this.setState({
                      errorInVimeo: true,
                      video_loaded: false
                    })
                  }}

                  onLoaded={() => {
                    this.setState({
                      video_loaded: false
                    })
                  }}
                  height={this.state.height}
                />
                : null
            }
          </div>
        )

      }
    }
  } 
  render() {
    return (
      <>
        <input type="hidden" value="" id="media_type" />
        <input type="hidden" value="" id="media_title" />
        <input type="hidden" value="" id="media_source" />
        <Modal
          idModal="fullMediaViewModal"
          size="modal-lg"
          title={this.state.title}
          noFooter
          key={this.state.key}
        >
        {
          this.state.media_type == 'image' ? 
            <img src={this.state.media_source} className="w-100" />
          :  this.renderVimeo() 
        }
        </Modal>
      </>
    )
  }
}

export default FullMediaView
