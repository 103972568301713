import React, { Component } from 'react'

export class Pills extends Component {
    render() {
        const { content, background, bg, className } = this.props
        let bg_ = bg ? bg : background ? background: null;
        let style = bg_ ? { background: `${bg} !important` }:null;
        return (
            <div className={`${className ? className:'specialty-span'}`} style={style}>
                {this.props.children ? this.props.children:content}
            </div>
        )
    }
}

export default Pills
