import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import $ from 'jquery'
import arrayMove from 'array-move'
import { getSurgeon, setLoading } from '../../actions/SurgeonActions'
import ProcedureSkeleton from '../../components/skeletons/ProcedureSkeleton';
import { getError } from '../../utilities'

import { Tabs, TabHead, TabNavigation, TabBody, TabContent } from '../../components/commons/tabs'

import {
	BrowserRouter as Router,
	Switch,
	Route,
  Link, 
  Redirect
} from "react-router-dom";
import { createBrowserHistory } from "history";

import NewConsumable from './procedures/NewConsumable'
import Settings from './procedures/Settings'
import TabNavLink from '../../components/commons/tabs/TabNavLink'
import { TextInput, Picker } from '../../components/commons/forms'
import { Modal } from '../../components/commons/modals'


function getParams(param) {
	const searchParams = new URLSearchParams(window.location.search);
	return searchParams.get(param) || '';
}

const history = createBrowserHistory();

let  CancelGetProcedureDetailToken = null;
export class NewProcedures extends Component {
  
  constructor(props) {
    super(props)

    this.state = {
			loading: true,
			loading_component: true,
      settings: true,
      progressLoading: 0,
      procedure: {id:0},
      errors: [],
      restriction: -1,
      notifications: [],
      notifications_meta: [],
      progress_loading: 0,
      surgical_trees: [],
      stage_name: '',
      stage_types: [
        // { id: '', name: 'Please Select' },
        { id: 'standard', name: 'Standard' },
      ],
      surgical_tree_first: [],
      surgical_tree_second: [],
      custom_stages: [],
      stage_details: {
        stage:{
          draft:0
        }
      },
      name: '',
      stage: {
        id: '',
      },
      enableDraft: false,
      unique:this.makeKey(),
      key_load:this.makeKey(),
      swiperunique: this.makeKey(),
      key: this.makeKey(),
      showSuccess: false,
      scrollTab: 0,
    }
  }
 
  makeKey() {
    return (
      Math.random()
        .toString(36)
        .substring(2, 15) +
      Math.random()
        .toString(36)
        .substring(2, 15)
    )
  }

  componentDidMount() {
	  this.props.setLoading(true)
	  if(this.state.procedure.id != getParams('procedure_id')){
			this.getProcedure();
	  }
		// this.getProcedureDetail() 
	  this.getSettings(true)
  }

  getProcedure = props => {  
 
    let procedure_id = getParams('procedure_id')

    let url = `/api/specialty-procedure/${procedure_id}`
    axios
      .get(url)
      .then(res => {
        this.setState({
          procedure: res.data.data,
          stage_details: [],
          // key: this.makeKey(),
				}) 
        this.getRestriction(procedure_id)
        this.getCustomStages(procedure_id)
				this.props.setLoading(false)
      })
      .catch(err => { 
				this.props.setLoading(false)
      })
  }
  
  getRestriction = (procedure_id) => {
    const { surgeon } = this.props
 
	let url = `/api/client/surgeon-procedure
				?procedure_id=${getParams('procedure_id')}&surgeon_id=${surgeon.id}`

    axios
      .get(url)
      .then(res => {
        this.setState({
					restriction: res.data.current_active,  
				})
				this.props.setLoading(false)
      })
      .catch(err => {
        console.log(err)
				this.props.setLoading(false)
        // this.props.router.push(`/quicknotes/${surgeon.id}`)
      })
  }
  
	getCustomStages = id => {
		const { surgeon } = this.props
		let url = `/api/client/custom-stage?procedure_id=${id}&surgeon_id=${surgeon.id}`

    axios
      .get(url)
      .then(res => {
        this.setState({
          custom_stages: res.data.data,
					loading: false,
					loading_component: false,
        })
        if (this.state.scrollTab) {
          setTimeout(() => {
            this.refTabsProcedure.goToScroll()
            this.setState({
              scrollTab: false,
            })
          }, 700)
        }
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
      })
	}
	deleteProcedure = () => {
		const { surgeon } = this.props 
		
		let data = new FormData()
		data.append('surgeon_id', surgeon.id)
		data.append('procedure_id', getParams('procedure_id'))
		data.append('_method', 'DELETE')

		let url = '/api/client/setting-procedure-delete'

		axios
			.post(url, data)
			.then(res => {
				console.log('popo', this.props.router)
				this.props.router.replace(`/quicknotes/${surgeon.id}`)
			})
			.catch(err => {
				console.log('popo', err)
				console.log('popo', err.response)
			})
	}

	onSortEnd = ({ oldIndex, newIndex }) => {
		this.setState(({ custom_stages }) => ({
			custom_stages: arrayMove(custom_stages, oldIndex, newIndex),
		}))
	}

	saveStage = () => {
		const { icon, stage_name, stage_type } = this.state
		const { surgeon, procedure } = this.props

		let data = new FormData()
		data.append('procedure_id', procedure.id)
		data.append('surgeon_id', surgeon.id)
		data.append('name', stage_name)
		if (icon && icon.source) {
			data.append('icon', icon.source)
		}
		data.append('type', stage_type)

		let url = '/api/client/custom-stage'

		axios
			.post(url, data)
			.then(res => {
				this.getCustomStages(procedure.id, 1)
				// this.props.changeTabsKey()
				$('#addStageModalProc').modal('hide')
			})
			.catch(error => {
				console.log(error)
				console.log(error.response)
				this.setState({
					errors: error.response.data.errors,
				})
			})
	}

  getSettings(enable = true) {
    this.setState({ loading: 1 })

    let url = `/api/surgical-trees`
    axios
      .get(url)
      .then(res => {
        let surgical_trees = res.data.data
        let out = surgical_trees.findIndex(stage => {
          return stage.name == 'Draping'
        })
        let temp = surgical_trees

        this.setState(
          {
            surgical_trees: surgical_trees,
            surgical_tree_first: temp.slice(0, out + 1),
            surgical_tree_second: temp.slice(out + 1, temp.length),
          },
          () => {
            this.setState({
              // key: this.makeKey()
            })
            // this.tabsRef.reInit()

            if (this.refTabsProcedure) {
              this.refTabsProcedure.getWidth()
            }

            if (!enable) {
            }
          }
        ) 
        this.getNotifications()
      })
      .catch(error => {
        console.log(error)
      })

    setTimeout(() => {
      this.setState({
        settings: enable,
        loading: 0,
      })
    }, 1000)
  }
  checkTabActive(condition, el) {
    if (condition) {
      return true
    }
    return false
  }

  getNotifications = () => {
    const { procedure } = this.state
    const { surgeon } = this.props

    this.setState({
			loading: true,
			loading_settings: true
    })
    let url = `/api/client/setting-notification?surgeon_id=${
      surgeon.id
    }&procedure_id=${getParams('procedure_id')}`

    axios
      .get(url)
      .then(res => {
        this.setState({
          notifications: res.data.data,
					notifications_meta: res.data,
					loading_settings: false
				})
				this.props.setLoading(false)
      })
      .catch(err => {
				console.log('popop', err)
				this.props.setLoading(false)
        console.log('popop', err.response)
      })
  }

	
  getProcedureDetail = (draft = 0, type = 'main', loading = false, stage_id = 0) => {
    const { stage } = this.state
    const { surgeon } = this.props
    const data = new FormData()
    data.append('procedure_id',  getParams('procedure_id'))
    if (type == 'custom') {
      data.append('stage_id', stage.stage.id)
    } else {
			data.append('stage_id', stage_id ? stage_id : stage.id)
    }
    data.append('surgeon_id', surgeon.id)
    data.append('stage_type', type)
		data.append('draft', draft)
		
		if (CancelGetProcedureDetailToken) {
			CancelGetProcedureDetailToken.cancel()
		} 
		console.log('stage_details componentDidMount cons CancelGetProcedureDetailToken ', CancelGetProcedureDetailToken)
		const CancelToken = axios.CancelToken;
		CancelGetProcedureDetailToken = CancelToken.source();
    let config = {}
    if (loading) {
      config = {
        headers: {
          'content-type': 'multipart/form-data',
				},
				cancelToken: CancelGetProcedureDetailToken.token,
        onUploadProgress: progressEvent => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          ) 
        },
      }
			this.setState({ 
				loading: true,
				loading_component: true
			})
    } else {
			config = {
				cancelToken: CancelGetProcedureDetailToken.token,
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
    }
    this.setState({ 
			loading: true,
			loading_component: true
    })
    
    axios
      .post(`api/client/stage`, data, config)
      .then(res => {
        this.getRestriction()
        this.setState({
          stage_details: res.data,
          settings: false, 
        })
        if (!draft) {
          this.getNotifications()
          this.getCustomStages(this.state.procedure.id)
        }
      })
      .catch(err => { 
				
      })
	}
	saveSorting = () => {
		const { custom_stages, procedure } = this.state
		let data = new FormData()
		custom_stages.map(stage => {
			data.append('stage_ids[]', stage.id)
		})
		data.append('procedure_id', procedure.id)
		data.append('_method', 'PATCH')

		let url = '/api/client/custom-stage-sort'

		axios
			.post(url, data)
			.then(res => {
				this.setState({
					showSuccess: true,
				})
				this.getCustomStages(procedure.id)
				if (this.refTabsProcedure) {
					this.refTabsProcedure.getWidth()
				}
			})
			.catch(error => {
				console.log(error)
				console.log(error.response)
			})
	}
	setNewState = (states) => {
		this.setState(states)
	}
  render() {
    const { surgeon, loading } = this.props
		const { procedure, notifications, notifications_meta,
						surgical_tree_first, custom_stages, surgical_tree_second,
			loading_settings, stage, stage_details, loading_component } = this.state
    return (
	
			<Router history={history} basename={`/core/${surgeon.id}/procedures`}>
      <div className="row"> 
        <div className="col-md-12 order-1 order-md-2" id="home">
          
				{ loading ? <div className="row d-flex flex-column"><ProcedureSkeleton /></div> : <div className="row">
					<div className="col-md-12 d-none d-md-block">
						<h1 className="page-title">
							{this.state.procedure
							? this.state.procedure.name
							: 'Procedure name'}
							<Link to={{
									pathname: "/setting",
									search: '?procedure_id='+this.state.procedure.id
								}}
							onClick={e => { 
								this.getSettings()
								this.setState({
								settings: true,
								enableDraft: false,
								stage: {},
								stage_details: [],
								})
							}}
							>
							<i className="mdi mdi-settings ml15" />{' '}
							</Link>{' '}
						</h1>
					</div>
					<div className="col-md-10 mt8">
						<Tabs
							className=""
							key={this.state.key}
							onRef={ref => (this.refTabsProcedure = ref)}
							navLinks={() => {
								return (
									<TabHead className="col-md-12 px0">
										{this.state.surgical_tree_first.map(
											(surgical_tree, index) => {
												return (
													<TabNavLink to={{
														pathname: '/'+surgical_tree.name.toLowerCase()+'-',
														search: `?procedure_id=${this.state.procedure.id}&stage_id=${surgical_tree.id}`
													}}
													 
														key={`surgical-main-${index}`}
														id={`surgical_tree_first-${index}`}
														active={this.checkTabActive(
															surgical_tree.id == this.state.stage.id,
															`surgical_tree_first-${index}`
														) || getParams('stage_id') == surgical_tree.id}
														onClick={e => {
															// this.tabsRef.scrollOnclick()
															if($(e.target).hasClass('disabled')){
																$("#unlockModal").modal('show') 
																return;
															}
															$(e.target).closest(`.scrollable-tabs`).animate({
																		scrollLeft: `${e.target.offsetLeft - 55}px`,
															}, 150 )
															
															this.setState(
																{
																	stage: surgical_tree,
																	loading: true,
																	settings: false,
																	enableDraft: false,
																	name: surgical_tree.name,
																},
																() => {
																	this.getProcedureDetail()  
																}
															)
															
														}}
														disabled={
															this.state.restriction < index &&
															this.state.restriction < 5
																? true
																: false
														}
													>
														{/* <span className="icon">
															<img
																src={surgical_tree.icon.replace(
																	'public',
																	''
																)}
																className="h28 w28"
															/>
														</span> */} 
														{surgical_tree.name}
													</TabNavLink>
												)
											}
										)}
										{this.state.custom_stages.map(
											(surgical_tree, index) => {
												return (
													<TabNavigation
														target="my-profile"
														key={`surgical-main-${index}`}
														id={`custom_stages-${index}`}
														active={this.checkTabActive(
															this.state.stage &&
																this.state.stage.stage &&
																surgical_tree.stage.id ==
																	this.state.stage.stage.id,
															`custom_stages-${index}`
														)}
														onClick={e => {
															// this.tabsRef.scrollOnclick()
															if ($(e.target).hasClass('disabled')) {
																$("#unlockModal").modal('show')
																return;
															}
															$(e.target)
																.closest(`.scrollable-tabs`)
																.animate(
																	{
																		scrollLeft: `${e.target.offsetLeft -
																			55}px`,
																	},
																	150
																)
															this.setState(
																{
																	stage: surgical_tree,
																	name: surgical_tree.stage.name,
																	enableDraft: false,
																	settings: false,
																},
																() => {
																	this.getProcedureDetail(0, 'custom', true)

																	$('#specialty-card').removeClass('active')
																	$(`#specialty-card-${index}`).addClass(
																		'active'
																	)
																}
															)
														}}
														disabled={
															this.state.restriction >= 5 ? false : true
														}
													>
															{/* <span className="icon">
																<img
																	src={
																		surgical_tree.stage.icon
																			? surgical_tree.stage.icon.replace(
																					'public',
																					''
																				)
																			: ''
																	}
																	className="h28 w28"
																/>
															</span> */}
														{surgical_tree.stage.name}
													</TabNavigation>
												)
											}
										)}

										{this.state.surgical_tree_second.map(
											(surgical_tree, index) => {
												return (
													<TabNavigation
														target="my-profile"
														key={`surgical-main-${index}`}
														id={`surgical_tree_second-${index}`}
														active={this.checkTabActive(
															surgical_tree.id == this.state.stage.id,
															`surgical_tree_second-${index}`
														)}
														onClick={e => {
															// this.tabsRef.scrollOnclick()
															if ($(e.target).hasClass('disabled')) {
																$("#unlockModal").modal('show')
																return;
															}
															$(e.target)
																.closest(`.scrollable-tabs`)
																.animate(
																	{
																		scrollLeft: `${e.target.offsetLeft -
																			55}px`,
																	},
																	150
																)
															this.setState(
																{
																	stage: surgical_tree,
																	settings: false,
																	enableDraft: false,
																	name: surgical_tree.name,
																},
																() => {
																	this.getProcedureDetail(0, 'main', true)
																}
															)
														}}
														disabled={
															this.state.restriction >= 5 ? false : true
														}
													>
														{/* <span className="icon">
															<img
																src={surgical_tree.icon.replace(
																	'public',
																	''
																)}
																className="h28 w28"
															/>
														</span> */}
														{surgical_tree.name}
													</TabNavigation>
												)
											}
										)}
									</TabHead>
								)
							}}
							contents={() => {
								return (
									<TabBody className="col-md-12">
										<TabContent
											idTab="my-profile"
											active={true}
										></TabContent>
									</TabBody>
								)
							}}
						/>
					</div>
					<div className="col-md-2 mt-8">
						<div
							className="list-item add-new-secion"
							data-toggle="modal"
							data-target="#addStageModalProc"
							onClick={() => {
								this.setState({ icon: '', stage_name: '', stage_type: 'standard' })
							}}
							>
								+ Add new stage
						</div>
					</div>
				</div>
				}
					<div className={`col-md-12 px0 pt24`}>
							{ !loading ? 
							<Switch>
												
								<Route path={`/consumables`} exact={true} 
										render={() => (
											<> 
												<NewConsumable surgeon={surgeon}
													procedure={procedure}
													stage={stage}
													setNewState={this.setNewState}
													getProcedureDetail={this.getProcedureDetail}
													loading={loading_component}
													stage={stage}
													key="cons-123"
													stage_details={stage_details}
												/>
											</>
										)} 

								/>



								<Route path={`/settings`} exact={true} children={
									<Settings surgeon={surgeon} 
														procedure={procedure} 
														notifications={notifications}
														notifications_meta={notifications_meta}
														surgical_tree_first={surgical_tree_first}
														custom_stages={custom_stages}
														surgical_tree_second={surgical_tree_second}
														saveSorting={this.saveSorting}
														onSortEnd={this.onSortEnd}
														getCustomStages={this.getCustomStages}
														loading_settings={loading_settings} />} />
								        
								{/*<Redirect from='*' to={`/quicknotes`} />           */}
							 	<Redirect from={`/consumables-`}
								 exact
									to={{
										pathname: "/consumables",
										search: `?procedure_id=${getParams('procedure_id')}&stage_id=${getParams('stage_id')}`
									}} />
								<Redirect from='*' to={{
									pathname: "/settings",
									search: '?procedure_id='+getParams('procedure_id') 
								}} />
							</Switch>
							:''
							}
					</div>
        </div>

				
					<Modal
						idModal="addStageModalProc"
						size="modal-sm"
						title="Add new stage"
						confirmText="Add stage"
						confirmDisabled={this.state.stage_type == ''}
						cancelText="Cancel"
						bodyClass="add-glove-modal-body"
						confirm={e => {
							this.saveStage()
						}}
						cancel={e => {
							//fn here
						}}
					>  
								<div className="form-group mt40 mb10">
									<TextInput
										label={'Name new stage'}
										value={this.state.stage_name}
										placeholder={'Name'}
										errorMessage={getError(this.state.errors, 'name')}
										containerClass={`mb0`}
										onValueChange={event => {
											this.setState({ stage_name: event.target.value })
										}}
									/>
								</div> 
					</Modal>
      </div>
			</Router>
    )
  }
}
const mapStateToProps = state => {
	console.log('loading', state)
	return {
	  surgeon: state.surgeon.surgeon,
	  loading: state.surgeon.loading,
	}
  }
  
  export default connect(
	mapStateToProps,
	{  setLoading }
  )(NewProcedures)
   
