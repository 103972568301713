import React, { Component } from 'react'
import {
	Elements
} from 'react-stripe-elements'
import CheckoutForm from '../commons/forms/CheckoutForm'
import { Modal } from '../commons/modals';
import Notifier from '../commons/notifier/Notifier';
import $ from 'jquery'

export class UpgradeModal extends Component {

	constructor(props) {
		super(props)
	
		this.state = {
			 trial: false,
			 paymentError: false
		}
	} 
	
	setTrialOnly(val){
		this.setState({
			trial:val
		})
	}

	setPaymentError(val){
		this.setState({
			paymentError:val
		})
	}

	render() {
		return (
			<div>
				<Modal idModal="upgradePlanModal" size="modal-full" className="upgrade-plan-modal" noFooter >
					<div className="container h-100">
						<div className="row h-100">
								<div className="col-lg-6 col-md-8 offset-lg-6 offset-md-2 payment-form text-center">
								<h1 className="title">Let's level up you plan!</h1>
								<p className="desc">
									By upgrading to premium you'll have <br/> 
										<b>unlimited surgeon cards & video uploads.</b>
								</p>
								<Elements>
									<CheckoutForm name={''} setPaymentError={this.setPaymentError.bind(this)} />
								</Elements>
							</div>
						</div>
					</div>
				</Modal>


				<Modal idModal="upgradeToFreeTrialModal" size="modal-full" className="upgrade-plan-modal" noFooter >
					<div className="container h-100">
						<div className="row h-100">
							<div className="col-lg-6 col-md-8 offset-lg-6 offset-md-2 payment-form text-center">
							
								<h1 className="title">Lets level up your plan!</h1>
								<p className="desc">
									Here's your <b>free trial premium account!</b>
								</p>
								<p className="desc">
									Enjoy a full <b>30 days</b> to try out all our features <br /> and discover the value of CORH surgeon preference
								</p>
								<p className="desc">
									If you wish not to continue with CORH please remember  <br /> cancel the trial before the <b>30 days</b>
								</p>
								
								{
									this.state.paymentError ? 
									<Notifier styleClass="mb20" show={true} onRef={ref => (this.refNotifier = ref)} type="error" hideThisNotif={() => { this.refNotifier.hideThis() }}>
										<span className="fit-this">
										Sorry! We have trouble processing your payment.  <br/>Please check your details and try again.
										</span>
									</Notifier> : ''
								}

								<Elements>
									<CheckoutForm name={''} trialOnly={true} setTrialOnly={this.setTrialOnly.bind(this)} setPaymentError={this.setPaymentError.bind(this)} />
								</Elements>
							</div>
						</div>
					</div>
				</Modal>
				 

				<Modal idModal="thankYouModal" size="modal-full" className="thank-you-modal" noFooter closeAction={() => { $('.modal').modal('hide') }}>
					<div className="container h-100">
						<div className="row h-100">
							<div className="col-lg-7 text-center thank-you">
								{
									this.state.trial ? 
									<>
									<h1 className="title">You have signed up to out 30 day  <br /> Premium account trial!</h1>
									<p className="desc">
										Should you you choose not to continue after the 30 days please cancel your free trial  <br /> before the date or you will be charged for the following month. 
									</p> 
									</>:
									<>
									<h1 className="title">Thanks - on behalf of the team at Global OR</h1>
									<p className="desc">
												By subscribing you are helping us develop future tools that will help build <br /> and support the next generation of OR teams.<br /><br />
										Most of all you will always be "3 Steps Ahead" with CORH
									</p> 
									</>
								}
							</div>
						</div>
					</div>
				</Modal>

			</div>
		)
	}
}

export default UpgradeModal
