import React, { Component } from 'react';
import { connect } from 'react-redux'
import SpecailtyCard from '../../components/specailty_card'

class Specialties extends Component {

    constructor( props){
        super(props)
        this.state = {
            redirect: false,
            toBePath:''
        }
    }

    moveTo(id){
        this.props.router.push({
            pathname: '/surgeon/'+id,
          })
       
    }

    render() {
      
        const { specialty } = this.props
        const cards = specialty.items.map(data => {
            return <SpecailtyCard key={data.id} icon={data.icon} name={data.name} getSpecialtyCategories={ () => this.moveTo(data.id)}/>
        })
        return (
            <div className="container animated fadeIn" style={{marginBottom:-15}}>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h1 className="font-weight-bold title">Surgeon preferences</h1>
                        <h4 className="sub-title">Select a specialty to view a list of surgeons</h4>
                    </div>
                    <div className="col-md-8 mx-auto d-flex flex-wrap justify-content-center">
                        {cards}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ specialty }) => {
    return { specialty };
};

export default connect(mapStateToProps, {})(Specialties);