import React, { Component } from 'react'
import AssignItemsModalInstruments from '../../components/modals/AssignItemsModalInstruments'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import ReactDOMServer from 'react-dom/server'
import { TextInput, Picker, Button } from '../../components/commons/forms'
import {
  getItemsPerCategory,
  setItems
} from '../../actions/ItemAction'
import Confirmation from '../../components/Confirmation'
import { getError, getArrayError } from '../../utilities'
import axios from 'axios'
import $ from 'jquery'
import _ from 'lodash'
import Inbodyloader from '../../components/loader/Inbodyloader'
import ViewItemModal from '../../components/modals/ViewItemModal' 

import { Table, THead, TRow, TData, TableNotificationCard } from '../../components/commons/table';
import { Modal, ConfirmationModal } from '../../components/commons/modals';
import ViewItemCard from '../../components/commons/cards/ViewItemCard';

export class RegisterNewTray extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      name: '',
      specialty: '',
      selected_items: [],
      change: false,
      errors: [],
      isOnEdit: false,
      editName: '',
      tray_id: null,
      loading: 0,
      isReverse: false,
      selectedItem: [],
      unique:
        Math.random().toString(36)
          .substring(2, 15) +
        Math.random().toString(36)
          .substring(2, 15),
    }
  }

  componentDidMount = () => {
    let id = this.props.router.params.id
    if(id){
      let url = `/api/tray/${id}`
      axios.get(url).then((res) => {
        
        let tray = res.data.data
        console.log('tray', tray)
        this.setState({
          name: tray.name,
          selected_items: tray.items,
          specialty: tray.specialty.id,
          isOnEdit:true,
          editName: tray.name,
          tray_id: tray.id,
        })
      }).catch((error) => {
        console.log(error)
        console.log(error.response)
      })
    }
  }

  componentDidUpdate = () => {
    $('[data-toggle="popover"]').popover()
    $('[data-toggle="popover"]').popover('hide')
  }

  removeItem = (id) => {
    let { selected_items } = this.state
    _.remove(selected_items, (e) => { return e.id == id})

    this.setState({
      selected_items: selected_items
    })
  }

  setItems = (items) => {
    console.log('return', items)
    this.setState({
      selected_items: items
    })
  }

  saveTray = () => {
    const { name,specialty,selected_items } = this.state
    let edit = false
    let id = this.props.router.params.id
    if(id){
      edit = true
    }

   
    console.log('sp2', specialty)

    const form = new FormData
    form.append('name',name)
      form.append('specialty_id',specialty)

    selected_items.map ( item => {
      form.append('items[]', item.id)
      form.append('qty[]', item.qty)
    })
    if(!edit){
      axios.post(`api/tray`,form).then( result => {
        console.log(result)
        this.props.router.push({
          pathname: '/medical-inventory-instruments',
          state: {
            active: 'instrument-trays'
          }
        })
      }).catch( error => {
        console.log(error)
        console.log(error.response)
        this.setState({
          errors: error.response.data.errors
        })
      })
    }else{
      let id = this.props.router.params.id
      form.append('_method', 'PATCH')
      let url = `/api/tray/${id}`

      axios.post(url, form).then((res) => {
        this.props.router.push({
          pathname: '/medical-inventory-instruments',
          state: {
            active: 'instrument-trays'
          }
  
        })
      }).catch((error) => {
        console.log(error)
        console.log(error.response)
        this.setState({
          errors: error.response.data.errors
        })
        
      })
    }
  }

  sortBy(key) {
    let arrayCopy = [...this.state.selected_items]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }
    this.setState({ selected_items: arrayCopy, isReverse: !this.state.isReverse })
  }

  compareBy(key) {
    if (key == 'category') {
      return function (a, b) {
        if (a.category.name < b.category.name) return -1
        if (a.category.name > b.category.name) return 1
        return 0
      }
    }
    return function (a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }
 
  render() {
    const { name, specialty, selected_items, errors } = this.state 
    const { items, loading } = this.props
    const options = items.map ( item => {
      return {id:item.id,name:item.name}
    })
    options.unshift({id:'',name:'Select Specialty'})
 

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12"> 
            <h1 className="page-title">
              {
                this.state.isOnEdit ? `Edit tray [${this.state.editName}]` : 'Register a new tray' 
              }
              
            </h1>
            <h4 className="page-description">Tray information</h4>
          </div>
        </div>
        <div className="row page-toolbar">
          <div className="col-md-5 mb24">
            <TextInput
              label={'Tray name'}
              value={name}
              placeholder={'Tray name'}
              errorMessage={getError(errors, 'name')}
              onValueChange={event => {
                this.setState({ name: event.target.value })
              }}
              inputClass="active"
              required
            /> 
          </div>
          <div className="col-md-5 mb24"> 
              <Picker
                errorMessage={getError(errors, 'specialty_id')} 
                onChange={event => {
                  this.setState({ specialty: event.target.value })
                }}
                label={`Assign specialty`}
                value={specialty}
                data={options} 
                required
              />  
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="w-100 d-flex align-items-center mb32">
              <h4 className="mb0 page-description">Tray item contents</h4>
              <Button secondary datatoggle="modal" className="ml-auto" datatarget="#assignItemsModalCenter" onClick={() => { this.setState({ change: !this.state.change }) }}>Assign Items</Button>
            </div>

            <Table minWidth={870} className="modal-table has-placeholder" key="renderItemsTable"

              renderModal={() => {
                return (<ViewItemCard id={`tray-r-preview-modal-${this.state.unique}`} onRef={ref => (this.viewItemRef = ref)} item={this.state.selectedItem} ></ViewItemCard>)
              }}

              renderHeaders={() => {
                return (
                  <TRow>
                    <THead className="w120" text="Catalogue #" sortable onClick={() => { this.sortByItems('catalog_number') }} />
                    <THead className="w230" text="Item Name" sortable onClick={() => { this.sortByItems('name') }} />
                    <THead className="w120" text="Company" sortable onClick={() => { this.sortByItems('category') }} />
                    <THead className="w120" text="Category" sortable onClick={() => { this.sortByItems('category') }} />
                    <THead className="w80" text="Size" />
                    <THead className="w80 text-center" text="Qty" />
                    <THead className="w115" text="Edits" />
                  </TRow>
                )
              }}
              renderRows={() => {
                let rows = selected_items.map(item => {
                    return (
                      <TRow key={`item-row-tray-${item.id}`} > 
                        <TData className="w120">{item.catalog_number}</TData>
                        <TData className="w230 has-action" item={item} popover onClick={() => {
                          this.setState({ selectedItem: item })
                          $(`#tray-r-preview-modal-${this.state.unique}`).modal('show')
                        }}>{item.name}</TData>
                        <TData className="w120">{item.company }</TData>
                        <TData className="w120">{item.category ? item.category.name : null}</TData>
                        <TData className="w80">{item.size}</TData> 
                        <TData className="w80 text-center">{item.qty}</TData>
                        <TData className="w120 has-action">
                          <div className="d-flex">
                            <Button tertiary onClick={() => { this.removeItem(item.id) }}>
                              Remove
                            </Button>
                          </div>
                        </TData>
                      </TRow>
                    )
                  }) 
                return rows
              }}
            // renderNotification={() => {
            //   return (<TableNotificationCard type="error" message="No procedure selected" onRef={ref => (this.categoryTableNotif = ref)} />)
            // }}

            /> 

            <div className="w-100 d-flex pt32">
              <Button primary onClick={this.saveTray.bind(this)}>Update</Button>
              <Button secondary datatoggle="modal" datatarget="#cancelModal">Cancel</Button> 
            </div>
          </div>
        </div>

        <ViewItemModal
          key={this.state.unique}
          item={this.state.selectedItem}
          cancel={() => {
            $('viewItemModal').modal('hide')
          }}
        />
        
        <ConfirmationModal
          title="Cancel"
          message="Are you sure you want to cancel?"
          confirmText="Yes"
          cancelText="Cancel"
          confirm={() => {
            this.props.router.push({
                pathname: '/medical-inventory-instruments',
                state:{
                  active: 'instrument-trays'
                }
              })
          }}
          idModal="cancelModal"
          cancel={() => {
            $('.modal').modal('hide')
          }}
        />
        <AssignItemsModalInstruments assign={this.setItems} selected_items={this.state.selected_items} change={this.state.change}/>
      </div>

    )
  }
}

const mapStateToProps = (state) => {
  return({
    errors: {},
    items: state.specialty.items,
    loading: state.item.loading
  })
}

export default connect(mapStateToProps, { getItemsPerCategory, setItems })(RegisterNewTray)
