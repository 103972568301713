import React, { Component } from 'react'
import {
  Contribution,
  Greeting,
  Media,
  Draft,
  Notification,
} from '../../components/lounge'

class Longue extends Component {
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="page-title d-block d-md-none">The Lounge</h1> 
          </div>
          <div className="col-lg-4 col-md-5">
            <Greeting />
            <Media />
            <Contribution />
            <Draft />
          </div>
          <Notification />
        </div>
      </div>
    )
  }
}

export default Longue
