import CKEditor from 'ckeditor4-react'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SortableHandle } from 'react-sortable-hoc'
import { getElementError } from '../../../utilities'
import { TextInput } from '../../commons/forms'
import Icon from '../icons/Icon'

const DragHandle = SortableHandle(() => {
  return (
    <a
      href="javascript:;"
      onClick={event => {
        event.preventDefault()
      }}
      className="move-icon"
    >
      <Icon icon="Move" className="negative" height={30} width={25} />
    </a>
  )
})

export class TextEditor extends Component {
  constructor(props) {
    super(props)

    this.state = {
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
    }
  }

  componentDidUpdate = () => {}

  componentDidMount() {
    if(this.props.elementRef){
      this.props.elementRef(this.refs.element)
    }
  }

  componentWillReceiveProps = nextProps => {
    this.setState({
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
    })
  }

  headerAction = () => {
    return (
      <div className="card-header">
        <DragHandle />
        <a
          href="javascript:;"
          className="copy-icon"
          onClick={this.props.onDuplicate}
        >
          <Icon icon="Copy-2" className="negative" height={30} width={30} />
        </a>
        <a
          href="javascript:;"
          className="trash-icon"
          onClick={this.props.onDelete}
        >
          <Icon icon="Delete" className="negative" height={30} width={30} />
        </a>
      </div>
    )
  }

  titleBody() {
    const { validation } = this.props
    return (
      <div className="card-body">
        <div className="row">
          <div className="col-lg-5 col-md-6 pt12">
            <TextInput
              value={this.props.data.title}
              placeholder={'Title here...'}
              errorMessage={
                getElementError(
                  validation.errors,
                  validation.prefix,
                  validation.index,
                  validation.field
                )
                  ? 'This field is required!'
                  : ''
              }
              errorClass="mt0"
              onValueChange={event => {
                this.props.onTitleChange(event)
              }}
              label={
                <>
                  Title <span className="text-danger ml4">*</span>
                </>
              }
              inputClass="has-val active show-placeholder"
              labelClass="bolder"
            />
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { data, item_list } = this.props
    let items = this.props.mentions ? this.props.mentions : []

    let dataFeed = (opts, callback) => {
      var matchProperty = 'name',
        data = items.filter(function(item) {
          return (
            item[matchProperty]
              .toLowerCase()
              .indexOf(opts.query.toLowerCase()) !== -1
          )
        })
      callback(data)
    }

    return (
      <div className="card text-editor" ref='element'>
        {this.props.onTitleChange ? this.headerAction() : null}
        {this.props.onTitleChange ? this.titleBody() : null}

        <div className="card-body editor-body">
          <CKEditor
            data={data.content}
            onChange={this.props.textContentChange}
            config={{
              pasteFilter: 'h1 h2 h3 h4 h5 h6 p ul ol li; img[!src, alt]; a[!href]',
              allowedContent: {
                $1: {
                  attributes: true,
                  styles: true,
                  classes: true,
                },
              },
              extraPlugins: 'mentions',
              toolbar: [
                ['Bold', 'Italic', 'Underline', '-'],
                [
                  'NumberedList',
                  'BulletedList',
                  '-',
                  // 'Outdent',
                  // 'Indent',
                  // '-',
                  'JustifyLeft',
                  'JustifyCenter',
                  'JustifyRight',
                  // 'JustifyBlock',
                  '-',
                ],
                [
                  //'Table', 'HorizontalRule',
                  'Link',
                  // 'Smiley',
                ],
                ['FontSize'],
                ['TextColor', 'BGColor'],
              ],

              mentions: [
                {
                  marker: '#',
                  feed: dataFeed,
                  itemTemplate:
                    '<li data-id="{id}">' +
                    '<strong class="username">{name}</strong>' +
                    '<span class="fullname"> ({catalog_number})</span>' +
                    '</li>',
                  outputTemplate: `<a href="#" class="tippy-hover" data-id="{id}">  
                                {name}
                              </a>`,
                  minChars: 1,
                },
              ],
              removePlugins: 'elementspath',
              resize_enabled: false,
            }}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    item_list: state.quicknotes.item_list,
  }
}

export default connect(mapStateToProps)(TextEditor)
