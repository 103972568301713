import React, { Component } from 'react'
import { Items, Trays } from '../../components/instruments'
 
import InfiniteScroll from 'react-infinite-scroll-component'
import $ from 'jquery'
import ReactDOMServer from 'react-dom/server'
import axios from 'axios'
import _ from 'lodash'
import { connect } from 'react-redux'
import ItemCard from '../../components/ItemCard'
import { Link } from 'react-router'
import Create from './Create'
import Confirmation from '../../components/Confirmation'
import ViewItemModal from '../../components/modals/ViewItemModal'
import { setItemMedicalInventory } from '../../actions/ItemAction'
import { Table, TRow, THead, TData, TableNotificationCard } from '../../components/commons/table';

import { Tabs, TabBody, TabNavigation, TabHead, TabContent } from '../../components/commons/tabs/';

class Instruments extends Component {
  constructor(props) {
    super(props)

    this.state = {
      active: 'instruments', 
        data: [],
        loading: false,
        page: 1,
        loading: 1,
        dataLength: 1,
        isEditMode: false,
        medical_categories: [],
        medical_inventory_name: '',
        selectedCategory: 'all',
        searchKeyword: '',
        inventory: '',
        cardView: false,
        selectedItem: null,
        sizes: [{ catalogue: '', size: '', show: true }],
        successAppear: false,
        isCreate: false,
        successMessage: '',
        isReverse: false,
        errors: [],
        unique:
          Math.random()
            .toString(36)
            .substring(2, 15) +
          Math.random()
            .toString(36)
            .substring(2, 15), 
    }
    this.compareBy.bind(this)
    this.sortBy.bind(this) 
  }

  compareBy(key) {
    if (key == 'category') {
      return function (a, b) {
        if (a.category.name < b.category.name) return -1
        if (a.category.name > b.category.name) return 1
        return 0
      }
    }
    return function (a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }
  
  setIsCreate(is_create) {
    this.setState({
      isCreate: is_create
    })
  }
 
  sortBy(key) {
    let arrayCopy = [...this.state.data]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }
    this.setState({ data: arrayCopy, isReverse: !this.state.isReverse })
  }

  componentDidUpdate() {
    $('[data-toggle="popover"]').popover()
    $('[data-toggle="popover"]').popover('hide')
  }
  
  checkMedicalInventoryId = () => {
    this.props.setItemMedicalInventory(2)
  }
  componentDidMount() {
    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave.bind(this))
    console.log('current active tab', this.props.router.location.state)
    if (typeof this.props.router.location.state != 'undefined') {
      switch (this.props.router.location.state.active) {  
         
        case 'instrument-trays':
          this.setState({
              active: 'instrument-trays',
          })

          break

        default:
          this.setState({
            active: 'instruments',
          })
          break
      }
    }
  }

  routerWillLeave(nextLocation) {
    if (this.state.isCreate)
      return 'Your work is not saved! Are you sure you want to leave?'
  }

  addItemSucces(item) {
    const { data } = this.state

    data.unshift(item)
    this.setState({
      data: data.splice(0),
      isCreate: false,
      successAppear: true,
      successMessage: 'Items have been registered successfully',
    })
    setTimeout(() => {
      this.setState({
        successAppear: false,
      })
    }, 5000)
  }

  editItemSuccess(item) {
    const { selectedItem } = this.state

    this.setState(
      {
        data: this.state.data.filter(i => i.id != selectedItem.id),
      },
      () => {
        const { data } = this.state

        data.unshift(item)
        this.setState({
          data: data.splice(0),
          isCreate: false,
          successAppear: true,
          successMessage: 'Items have been successfully updated',
        })
        setTimeout(() => {
          this.setState({
            successAppear: false,
          })
        }, 5000)
      }
    )
  }


  render() {
    if (this.state.isCreate)
      return (
        <Create
          addItemSucces={item => this.addItemSucces(item)}
          editItemSuccess={item => this.editItemSuccess(item)}
          modalCancel={() => {
            this.setState({
              isCreate: false,
            })
          }}
          item={this.state.selectedItem}
          medicalInventoryName={this.state.medical_inventory_name}
        />
      )
    return (
      <div className="container mb16">
        <div className="row">
          <div className="col-md-12">
            <h1 class="page-title">Instruments database</h1>
            {/* <p className="page-detail">
              [Define what instruments is and what items are to be found]
            </p> */}
          </div>
          <div className="col-md-12">

            <Tabs
              className=""
              navs={() => {
                return (
                  <TabHead className="col-md-12 px0">
                    <TabNavigation target="instruments" active={this.state.active == 'instruments'} onClick={() => { this.setState({ active: 'instruments' }) }}>Instruments</TabNavigation>
                    <TabNavigation target="instrument-trays" active={this.state.active == 'instrument-trays'} onClick={() => { this.setState({ active: 'instrument-trays' }) }}>Instrument trays</TabNavigation>
                  </TabHead>
                )
              }}
              contents={() => {
                return (
                  <TabBody>
                    <TabContent idTab="instruments" active={this.state.active == 'instruments'}>
                      <Items setIsCreate={(e) => { this.setState({ isCreate: e }) }} activeTab={this.state.active == 'instruments' ? true : false} setActiveItem={(item) => { this.setState({ selectedItem: item }) }} />
                    </TabContent>
                    <TabContent idTab="instrument-trays" active={this.state.active == 'instrument-trays'}>
                      <Trays activeTab={this.state.active == 'instrument-trays' ? true : false} />
                    </TabContent>
                  </TabBody>
                )
              }}
            /> 
          </div>
        </div>

      </div>
    )
  }
}


const mapStateToProps = state => {
  return {
    medical_inventory_id: state.item.medical_inventory_id,
  }
}
export default connect(
  mapStateToProps,
  { setItemMedicalInventory }
)(Instruments)
