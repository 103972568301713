import axios from 'axios'
import $ from 'jquery'
import _ from 'lodash'
import React, { Component } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { connect } from 'react-redux'
import { setSpecialty } from '../../actions'
import { clearQuicknotesData } from '../../actions/QuicknotesActions'
import { setIsNext, setSurgeon } from '../../actions/SurgeonActions'
import { Button } from '../../components/commons/forms'
import EndOfLoading from '../../components/commons/loading/EndOfLoading'
import { ConfirmationModal } from '../../components/commons/modals'
import { ShareSurgeon } from '../../components/modals/ShareSurgeonModal'
import SurgeonCard from '../../components/surgeon_card'
class Surgeon extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      items: [],
      loading: false,
      hasMore: false,
      searchKeyword: '',
      dataLength: 1,
      meta: {},
      selected_id: 0,
      shares: [],
    }
  }

  componentDidMount() {
    console.log('this.props', this.props)
    this.makeRemoteRequest()
    this.setSelectedSpecialty()
  }

  loadMoreData() {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => {
        this.makeRemoteRequest()
      }
    )
  }

  refresh() {
    this.setState(
      {
        page: 1,
        loading: true,
        items: [],
      },
      () => {
        this.setSelectedSpecialty()
        this.makeRemoteRequest()
      }
    )
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.params.id === this.props.params.id) {
      return
    } else {
      this.refresh()
    }
  }

  makeRemoteRequest = () => {
    this.setState({ loading: true })
    const { page, searchKeyword } = this.state
    const { params } = this.props

    if (params.id == undefined) {
      params.id = 'all'
    }
    axios
      .get(
        `api/client/surgeon?s_id=${params.id}&page=${page}&search=${searchKeyword}`
      )
      .then(res => {
        console.log(res)
        this.setState(
          {
            items:
              page === 1
                ? res.data.data
                : [...this.state.items, ...res.data.data],
            loading: false,
            hasMore: res.data.meta.last_page > res.data.meta.current_page,
            dataLength: res.data.meta.total,
          },
          () => {
            console.log(this.state.hasMore)
          }
        )
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
        this.setState({ loading: false, hasMore: false })
      })
  }

  renderLoading() {
    if (this.state.loading)
      return (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )

    return (
      <EndOfLoading
        title="Nothing else to see..."
        message="Missing Someone?"
        link="/surgeon-create"
        linkMessage="Add new Surgeon here."
        onClick={this.props.setIsNext(false)}
      />
    )
  }

  setSelectedSpecialty() {
    const { specialty, params } = this.props

    const spec = specialty.items.find(data => {
      return data.id == params.id
    })

    if (specialty.items.length > 0) {
      this.props.setSpecialty(spec)
    }
  }

  searchItem() {
    this.setState(
      {
        searchKeyword: this.searchKeyword.value,
        page: 1,
        loading: true,
        data: [],
      },
      () => {
        this.makeRemoteRequest()
      }
    )
  }
  renderSearchBar() {
    const search = _.debounce(keyword => {
      this.searchItem(keyword)
    }, 500)
    const { params } = this.props
    return (
      <div className="row">
        <div className="col-lg-4 col-md-5 d-flex mb16 mt12">
          <div className="line-form-group-v2">
            <input
              type="text"
              className="line-form-v2"
              ref={el => (this.searchKeyword = el)}
              onChange={search}
            />
            <label>
              <i className="mdi mdi-magnify mr4 ml0"></i> Search for Surgeon
            </label>
          </div>
        </div>
      </div>
    )
  }
  toSurgeonPage(data) {
    this.props.setSurgeon(data)
    // this.props.router.push({
    //   pathname:`/core/${data.id}`,
    //   state: { surgeon:data}
    // })
    this.props.router.push({
      pathname: `/quicknotes/${data.id}`,
      state: { surgeon: data }
    })
    // this.props.router.push(`/quicknotes/${data.id}`)
  }
  setDeleteSurgeon(id) {
    this.setState(
      {
        selected_id: id,
      },
      () => {
        $(`#surgeon-modal-delete`).modal('show')
      }
    )
  }

  setShareSurgeon(id) {
    axios
      .get(`api/client/shares?surgeon_id=${id}`)
      .then(result => {
        console.log(result.data)
        this.setState(
          {
            selected_id: id,
            shares: result.data,
          },
          () => {
            $(`#surgeon-modal-share`).modal('show')
          }
        )
      })
      .catch(error => {
        console.log(error)
      })
  }

  deleteSurgeon = () => {
    const { selected_id, items } = this.state

    const data = {
      _method: 'DELETE',
    }
    console.log(selected_id)
    $(`#surgeon-card-${selected_id}`).addClass('loading-surgeon-card');
    axios.post(`api/client/surgeon/${selected_id}`, data).then(result => {
      setTimeout(() => {
        this.setState({
          items: items.filter(
            surgeon => {
              return surgeon.id != selected_id
            },
            () => {
              console.log('Notification here')
            }
          ),
        })
      }, 500);
    })
  }

  getRestriction = () => {
    const { auth } = this.props
    let user = auth
    if (!user.premium) {
      if (user.surgeons >= 2) {
        return (
          <p className="page-detail">
            Yikes - you’ve reached your free limit for surgeons and cannot add
            any more until you .{' '}
            <Button tertiary link url="#">
              {' '}
              Upgrade{' '}
            </Button>
          </p>
        )
      }
    }
    return (
      <p className="page-detail">
        Missing Someone?{' '}
        <a
          className="px-1 w-xs-100 p-xsx0 m-xsb20"
          href="/surgeon-create"
        >
          Add new Surgeon here.
        </a>
      </p>
    )
  }

  render() {
    const cards = this.state.items.map((data, index) => {
      return (
        <SurgeonCard
          cardClass="h-100"
          className={`mb30 ${data.grayed ? 'grayed-card' : ''}`}
          key={data.id}
          cardID={`surgeon-card-${data.id}`}
          surgeonData={data}
          setDeleteSurgeon={id => {
            this.setDeleteSurgeon(id)
          }}
          setShareSurgeon={id => {
            this.setShareSurgeon(id)
          }}
          onClick={() => {
            if (!data.grayed) {
              this.props.clearQuicknotesData(data.id)
              this.toSurgeonPage(data)
            } else {
              $('#upgradePlanModal').modal('show')
            }
          }}
        />
      )
    })
    const specialty = this.props.location.state
      ? this.props.location.state.specialty
      : null
    console.log('specialty')

    console.log(specialty)
    return (
      <>
        <ShareSurgeon shares={this.state.shares} surgeonId={this.state.selected_id}/>
        <ConfirmationModal
          title="Delete Surgeon"
          message="Deleting surgeon will remove the surgeon profile and all the content in their procedure cards."
          confirmText="Delete"
          cancelText="Cancel"
          idModal={`surgeon-modal-delete`}
          confirm={this.deleteSurgeon.bind(this)}
          cancel={() => {
            $(`#surgeon-modal-delete`).modal('hide')
          }}
        />
        <div className="container animated fadeIn">
          <div className="row">
            <div className="col-md-12">
              <h1 className="font-weight-bold page-title">
                {specialty ? specialty.name : 'Surgeon list'}
              </h1>
              {this.renderSearchBar()}
              {this.getRestriction()}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <InfiniteScroll
                className="row"
                dataLength={this.state.items.length} //This is important field to render the next data
                next={this.loadMoreData.bind(this)}
                hasMore={true}
              >
                {cards}
              </InfiniteScroll>
            </div>
          </div>
          {this.renderLoading()}
        </div>
      </>
    )
  }
}

const mapStateToProps = ({ specialty, auth }) => {
  return { 
    specialty, 
    auth }
}

export default connect(
  mapStateToProps,
  { setSpecialty, setIsNext, clearQuicknotesData, setSurgeon}
)(Surgeon)
