import React, { Component } from 'react'
import ValidationMessage from './ValidationMessage'
import $ from 'jquery'
class TextInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      blurred: false, 
      focused: false,
    }
  }
  componentDidMount() {
    setTimeout(() => {

      $(`#${this.props.id ? this.props.id : `_${this.state.unique}`}`).blur()
    }, 500); 
  }
  
  componentDidUpdate(prevProps, prevState) {
    if(this.props != prevProps){
      // if(!this.state.blurred){ 
      //   $('input').blur();
      //   this.setState({
      //     blurred: true
      //   })
      // }
    }
  }
  
  render() {
    const { label, errorMessage = '', containerClass = '', placeholder = '', 
      required, inputClass = '', search, labelClass = '', errorClass='', icon='' } = this.props
    const red_label = () =>{
      return (
        <span className="error">&nbsp;*</span>
      )
    }
    const search_label = () => {
      return (<i className="mdi mdi-magnify"></i>)
    }
    return (
      <div className={`line-form-group-v2  ${errorMessage.length > 0 ? `is-invalid ${errorClass}` : null} ${containerClass}`}>
      
        <input
          {...this.props}
          type="text"
          className={`line-form-v2 ${inputClass}`}
          name={`_${this.state.unique}`}
          id={this.props.id ? this.props.id : `_${this.state.unique}`}
          onChange={ this.props.onValueChange ? this.props.onValueChange:this.props.onChange}
          placeholder={placeholder}
          type={this.props.inputType ? this.props.inputType : this.props.type}
        />
        {label ? (
          <label htmlFor={`_${this.state.unique}`} className={labelClass}> {icon ? <i className={icon}></i> : ''}  {search ? search_label() : null} {label} {required ? red_label() : ''}</label>
        ) : null}
        {errorMessage.length > 0 ? (
          <ValidationMessage type="invalid" message={errorMessage} />
        ) : (
          ''
        )}
      </div>
    )
  }
}

export default TextInput
