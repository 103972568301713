import React, { Component } from 'react'
import logo from '../assets/images/logo-gradient.png'

import cellphone from '../assets/images/welcome/Cellphone.png'
import desktop from '../assets/images/welcome/Desktop.png'
import tablet from '../assets/images/welcome/Tablet.png'

import web from '../assets/images/welcome/web.png'
import apple from '../assets/images/welcome/apple.png'
import play from '../assets/images/welcome/play.png'

export class welcome extends Component {
    render() {
        return (
            <section className="welcome-page">
                <img src={logo} className="logo" />
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h2 className="title2">Awesome!</h2>
                        <p className="subtitle">You have successfully signed up to CORH</p>
                        <h1 className="title1">Where to now?</h1>
                    </div>
                    <div className="col-lg-3 col-md-4 three-cards">
                        <div className="card">
                            <div className="card-body">
                                <p>If you want to go ahead and use our desktop version</p>
                                <img src={desktop} className="big-icon" />
                                <a href="#" className="small-icon"><img src={web} /></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 three-cards d-none">
                        <div className="card">
                            <div className="card-body">
                                <p>If you want to go ahead and use our mobile version</p>
                                <img src={tablet} className="big-icon" />
                                <a href="#" className="small-icon"><img src={apple} /></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 three-cards">
                        <div className="card">
                            <div className="card-body">
                                <p>If you want to go ahead and use our desktop version</p>
                                <img src={cellphone} className="big-icon" />
                                <a href="#" className="small-icon"><img src={play} /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default welcome
