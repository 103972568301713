import axios from 'axios'
import $ from 'jquery'
import 'rc-cropping/assets/index.css'
import 'rc-dialog/assets/index.css'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getUser, loginUpdate } from '../../actions'
import { getPaymentDetail } from '../../actions/PaymentActions'
import img from '../../assets/images/img.png'
import failedtoload from '../../assets/images/failedtoload.png'
import UserProfileCard from '../../components/commons/cards/UserProfileCard'
import ImagePicker from '../../components/commons/elements/inputs/ImagePicker'
import { Button, TextInput } from '../../components/commons/forms'
import { ConfirmationModal, Modal } from '../../components/commons/modals'
import Notifier from '../../components/commons/notifier/Notifier'
import { TabBody, TabContent, TabHead, TabNavigation, Tabs } from '../../components/commons/tabs'
import { hideLoader, setLoaderProg, showLoader } from '../../components/loader/loading_screen'
import CardChangeModal from '../../components/modals/CardChangeModal'
import AccountBillingSkeleton from '../../components/skeletons/AccountBillingSkeleton'
import { BASE_URL } from '../../config/constant'
import { dataURItoBlob, getError } from '../../utilities'
import CardIcon from './CardIcon'



export class AccountSettings extends Component {
  constructor(props) {
    super(props)

    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      specialties: [],
      firstname: '',
      job_title: '',
      errors: [],
      old_password: '',
      new_password: '',
      new_password_confirmation: '',
      avatar: null,
      image: '',
      croppedFile: null,
      show: false,
      filename: '',
      progress: 0,
      loading: 0,
      showNotification: false,
      notificationMessage: '',
      loading: false,
      billingData: null,
      hasOldSubscription: false,
      key: this.makeKey(),
    }
    this.handleClick = this.handleClick.bind(this)
    this.toggleShow = this.toggleShow.bind(this)
  }

  makeKey() {
    return (
      Math.random()
        .toString(36)
        .substring(2, 15) +
      Math.random()
        .toString(36)
        .substring(2, 15)
    )
  }

  toggleShow(val, message = '') {
    this.setState({
      showNotification: val,
      message: message,
    })
  }

  handleClick(e) {
    this.setState({
      show: true,
    })
    this.refs.file.click()
  }

  croppeFile = fileBlob => {
    if (!fileBlob) {
      this.setState({
        show: false,
        file: null,
        croppedFile: null,
        isNext: false,
        page: 1,
      })
      return
    }
    const reader = new FileReader()
    reader.readAsDataURL(fileBlob)
    let filename = this.state.file.name
    reader.onload = () => {
      this.setState({
        show: false,
        file: null,
        croppedFile: null,
        isNext: false,
        page: 1,
        filename: filename,
        avatar: reader.result,
      })
    }
    setTimeout(() => {
      this.saveProfile()
    }, 500)
  }

  renderCropper() {
    return (
      <input
        type="file"
        ref="file"
        onChange={() => {
          this.setState({
            image: this.refs.file.file[0],
          })
        }}
        className="d-none"
      />
    )
  }

  componentDidMount() {
    this.setState({
      job_title: this.props.auth.user.job_title,
      firstname: this.props.auth.user.firstname,
      lastname: this.props.auth.user.lastname,
      email: this.props.auth.user.email,
      avatar: this.props.auth.user.avatar,
      specialties: this.props.auth.user.specialties,
    })
    this.runCardSelectedMode()
    this.setState({
      key: this.makeKey(),
    })
    if (this.props.router.location.query.billing) {
      this.getBillingDetails(() => {
        return setTimeout(() => {
          $('#changeCardModal').modal('show')
          console.log(
            'changeCardModalchangeCardModalchangeCardModal',
            $('#changeCardModal').modal('show')
          )
        }, 1000)
      })
    }
  }
  componentWillReceiveProps(nextProps) {
    setTimeout(() => {
      this.runCardSelectedMode()
    }, 1000)
  }
  runCardSelectedMode() {
    this.state.specialties.map(data => {
      $(`#specialty-card-${data.id}`).addClass('selected')
    })
  }

  componentWillMount() {
    axios.defaults.baseURL = BASE_URL
  }

  saveProfile() {
    this.setState({
      progress: 0,
      errors: [],
    })
    const { job_title, firstname, lastname, email, avatar } = this.state

    let config = {
      headers: {
        'content-type': 'multipart/form-data',
      },

      onUploadProgress: progressEvent => {
        if (avatar) {
          if (avatar.indexOf('base64') >= 0) {
            var percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
            showLoader(percentCompleted >= 95 ? 95 : percentCompleted)
            setLoaderProg(percentCompleted >= 95 ? 95 : percentCompleted)
          }
        }
      },
    }
    const form = new FormData()
    if (avatar) {
      if (avatar.indexOf('base64') >= 0) {
        form.append('avatar', dataURItoBlob(avatar))
      }
    }
    form.append('job_title', job_title)
    form.append('firstname', firstname)
    form.append('lastname', lastname)
    form.append('email', email)
    axios
      .post('api/save-account-settings', form, config)
      .then(result => {
        console.log(result.data.data)
        this.props.loginUpdate({
          prop: 'user',
          value: result.data.data,
        })

        if (avatar) {
          if (avatar.indexOf('base64') >= 0) {
            this.setState({
              progress: 100,
              showNotification: true,
              notificationMessage: 'Profile updated!',
            })
          }
        }
        setLoaderProg(99)
        setTimeout(() => {
          hideLoader()
        }, 500)
      })
      .catch(errors => {
        console.log('errors', errors)
        if (errors.response.status == 422) {
          setLoaderProg(99)
          setTimeout(() => {
            hideLoader()
          }, 500)
          this.setState({
            errors: errors.response.data.errors,
            loading: false,
          })
          this.setState({
            progress: 0,
          })
        }
      })
  }

  setSpecialtySelected(specialty) {
    const data = {
      specialty: specialty.id,
    }
    axios
      .post('api/nurse/specialty', data)
      .then(result => {
        console.log('result.data.specialties', result.data)
        this.props.loginUpdate({
          prop: 'user',
          value: result.data,
        })
        this.setState({
          specialties: result.data.specialties,
        })
      })
      .catch(errors => {
        console.log('errors', errors)
      })
    var hasSelectedClass = $(`#specialty-card-${specialty.id}`).hasClass(
      'selected'
    )
    if (hasSelectedClass) {
      $(`#specialty-card-${specialty.id}`).removeClass('selected')
    } else {
      $(`#specialty-card-${specialty.id}`).addClass('selected')
    }
  }

  saveChangePassword() {
    const data = {
      old_password: this.state.old_password,
      new_password: this.state.new_password,
      new_password_confirmation: this.state.new_password_confirmation,
    }
    axios
      .post('api/change-password', data)
      .then(result => {
        console.log(result)
        $('#changePasswordModal').modal('hide')

        $('#successChangePasswordModal').modal('show')
        this.setState({
          old_password: '',
          new_password: '',
          new_password_confirmation: '',
        })
      })
      .catch(errors => {
        this.setState({
          errors: errors.response.data.errors,
          loading: false,
        })
      })
  }

  checkSpecialty(id) {
    let found = false
    this.state.specialties.map(spec => {
      if (spec.id == id) {
        found = true
      }
    })
    return found
  }

  loadSpecialyCards() {
    const { specialty } = this.props
    const cards = specialty.items.map(data => {
      return (
        <Button
          secondary
          large
          className="ml0 mb10 col-lg-3 col-md-5"
          key={`specialty-btn-${data.id}`}
          onClick={() => {
            this.setSpecialtySelected(data)
          }}
          id={`specialty-card-${data.id}`}
          active={this.checkSpecialty(data.id)}
        >
          <img src={data.icon} className="specialty-btn-img" onError={(event)=>{ 
            $(event.target).prop('src', failedtoload);
          }}  />
          {data.name}
        </Button>
      )
    })
    /* 	< SpecialtyCards
		icon = { data.icon }
		name = { data.name }
		styleClass = "my15 m-lgr30 m-xlr30 m-mdr20 mr30"
		id = {`specialty-card-${data.id}`
	}
	onClick = {() => {
	this.setSpecialtySelected(data)
}}
/> */
    return <div className="col-md-12 d-flex flex-wrap pr0">{cards}</div>
  }
  loadNurseSpecialties() {
    if (this.state.specialties.length == 0) {
      return null
    } else {
      const specialties = this.state.specialties.map(specialty => {
        return (
          <span className="badge badge-secondary badge-pill mr10 mb10 p10">
            {specialty.name}
          </span>
        )
      })
      return (
        <div className="row mt20">
          <div className="col-12">
            <label>Specialties</label>
            <div className="w-100 tags">{specialties}</div>
          </div>
        </div>
      )
    }
  }

  loadUserCard() {
    return (
      <div className="card shadow-sm border-0">
        <div className="card-body">
          <div className="media">
            {this.state.avatar ? (
              <img
                src={this.state.avatar}
                onError={(event)=>{ 
                  $(event.target).prop('src', failedtoload);
                }}
                className="align-self-center bg-dark rounded-circle"
                alt="..."
                style={{ width: '100px', height: '100px' }}
              />
            ) : (
              <img
                src={img}
                className="align-self-center bg-dark rounded-circle"
                alt="..."
                style={{ width: '100px', height: '100px' }}
              />
            )}

            <div className="media-body pl20 align-self-center">
              <h5 className="m-0 fs20 font-weight-bold">
                {this.props.auth.user.firstname
                  ? `${this.props.auth.user.firstname} ${this.props.auth.user.lastname}`
                  : `${this.props.auth.user.name}`}
              </h5>
              {this.state.job_title ? (
                <p className="mb-0 lead fs16 mt5">{this.state.job_title}</p>
              ) : null}
            </div>
          </div>
          {this.loadNurseSpecialties()}
        </div>
      </div>
    )
  }

  changePasswordModal() {
    return (
      <Modal
        idModal="changePasswordModal"
        size="modal-sm"
        title="Change password"
        confirmText="Save"
        cancelText="Close"
        bodyClass="add-glove-modal-body"
        confirm={e => {
          this.saveChangePassword()
        }}
        cancel={e => {
          //fn here
        }}
      >
        <div className="input-holder pt20">
          <TextInput
            inputType="password"
            label={'Old password'}
            labelclassName="fs16 font-weight-bold w-100"
            value={this.state.old_password}
            errorMessage={getError(this.state.errors, 'old_password')}
            containerClass={`form-group mb20`}
            onValueChange={event => {
              this.setState({
                old_password: event.target.value,
              })
            }}
          />
        </div>
        <div className="input-holder">
          <TextInput
            inputType="password"
            label={'New password'}
            labelclassName="fs16 font-weight-bold w-100"
            value={this.state.new_password}
            errorMessage={getError(this.state.errors, 'new_password')}
            containerClass={`form-group mb20`}
            onValueChange={event => {
              this.setState({
                new_password: event.target.value,
              })
            }}
          />
        </div>
        <div className="mb28">
          <TextInput
            inputType="password"
            label={'Confirm new password'}
            labelclassName="fs16 font-weight-bold w-100"
            value={this.state.new_password_confirmation}
            errorMessage={getError(
              this.state.errors,
              'new_password_confirmation'
            )}
            containerClass={`form-group mb0`}
            onValueChange={event => {
              this.setState({
                new_password_confirmation: event.target.value,
              })
            }}
          />
        </div>
      </Modal>
    )
  }
  getBillingDetails = (callback = () => {}) => {
    console.log(callback)
    this.props.getPaymentDetail(callback)
  }
  gracePeriodLayout(billingData) {
    return (
      <>
        <h1 className="bold text-center mb32 animated tada">
          Unsubscribed! But not forgotten
        </h1>

        <h3 className="mb8 regular text-center animated fadeInUp delay-400">
          Sorry to hear you wont be continuing your premium account.
        </h3>
        <h3 className="mb32 regular text-center animated fadeInUp delay-400">
          We still value your support so we will keep your content and surgeons
          on hold for you when you decide to come back!
        </h3>

        <h4 className="mb32 regular text-center animated fadeInUp delay-600">
          You will revert back to free user account at the end of your
          subscription cycle{' '}
        </h4>

        <h3 className="mb32 regular text-center animated fadeInUp delay-800">
          Your subscription will end on {billingData.ends_at}
        </h3>

        <h4 className="mb32 regular text-center animated fadeInUp delay-600">
          To resume your subscription click Resume now!{' '}
        </h4>

        <div className="d-flex justify-content-center animated fadeInUp delay-1000">
          <Button secondary datatoggle="modal" datatarget="#resumeModal">
            Resume now
          </Button>
        </div>
      </>
    )
  }
  failPayment = billingData => {
    return (
      <>
        <h1 className="bold text-center mb32 animated tada">
          Oh drats! Your payment Failed
        </h1>

        <h3 className="mb32 regular text-center animated fadeInUp delay-400">{`Well try again for the next ${billingData.daysLeft} days`}</h3>

        <h3 className="mb8 regular text-center animated fadeInUp delay-600">
          To continue with your premium Account you
        </h3>
        <h3 className="mb32 regular text-center animated fadeInUp delay-800">
          may need to update your payment details
        </h3>

        <div className="d-flex justify-content-center animated fadeInUp delay-1000">
          <Button secondary datatoggle="modal" datatarget="#changeCardModal">
            Update Details
          </Button>
        </div>
      </>
    )
  }

  onSubscribe = billingData => {
    {
      return (
        <>
          {billingData.onTrial ? (
            <>
              <h1 className="bold text-center mb32 animated tada">
                Be 3 Steps Ahead now!
              </h1>

              <h3 className="mb8 regular text-center">
                Like our CORH Surgeon Preference?
              </h3>
              <h3 className="mb32 regular text-center">Want more?</h3>

              <h3 className="mb32 regular text-center">You are currently on</h3>
              <h3 className="mb32 regular text-center">
                Free Premium Account Trial
              </h3>

              <h3 className="mb8 regular text-center">
                You have {billingData.trial_ends_at} days left{' '}
              </h3>
              {/*  <h3 className="mb8 regular text-center">
                  to try out all what CORH Surgeon </h3>
                <h3 className="mb32 regular text-center">
                  Preference has to offer
                </h3> */}

              <div className="row">
                <div className="col-md-6 mx-auto mb32">
                  <div className="card pricing-card animated fadeInUp faster premium">
                    <div className="card-header animated fadeIn fast">
                      Recommended
                    </div>
                    <div className="card-body">
                      <h1 className="title">Premium</h1>
                      <p className="mb12"></p>
                      <ul className="list-unstyled">
                        <li className="animated fadeInUp delay-600">
                          {' '}
                          <i className="fa fa-check"></i>Access to CORH
                          procedure templates
                        </li>
                        <li className="animated fadeInUp delay-800">
                          {' '}
                          <i className="fa fa-check"></i>Able to create your own
                          procedure cards
                        </li>
                        <li className="animated fadeInUp delay-1000">
                          {' '}
                          <i className="fa fa-check"></i>Create surgeon profile
                        </li>
                        <li className="animated fadeInUp delay-1200">
                          {' '}
                          <i className="fa fa-check"></i>Unlimited surgeon
                          profile
                        </li>
                        <li className="animated fadeInUp delay-1400">
                          {' '}
                          <i className="fa fa-check"></i>Unlimited video upload
                          and playback
                        </li>
                        <li className="animated fadeInUp delay-1600">
                          {' '}
                          <i className="fa fa-check"></i> 24/7 Support{' '}
                        </li>
                        <li className="animated fadeInUp delay-1800">
                          {' '}
                          <i className="fa fa-check"></i> Dark mode theme{' '}
                        </li>
                      </ul>
                      <div className="d-flex justify-content-center animated fadeInUp delay-1400">
                        <Button
                          primary
                          className="w235 mr0 px5"
                          datatoggle="modal"
                          datatarget="#upgradePlanModal"
                        >
                          Upgrade to Premium
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <h1 className="bold text-center mb32 animated tada">
                Your Awesome!
              </h1>

              <h3 className="mb8 regular text-center">
                You are currently a Premium User Account
              </h3>
              <p className="mb32 regular text-center">
                Your plan will automatically renew monthly and you will be
                charged <b>${billingData.amount}</b>
              </p>
              <h3 className="mb32 regular text-center">{`Your next bill will be on ${billingData.next_billing} for $${billingData.amount}`}</h3>
              <p className="mb32 regular text-center">
                Thank you for supporting us! Your subscription will help in
                making CORH bigger and better!
              </p>
            </>
          )}

          <p className="d-flex mb8 w300 mx-auto">
            <b>Card Details</b>
          </p>
          <p className="d-flex mb32 p16 card-details w300 mx-auto">
            {billingData.card_brand ? (
              <CardIcon icon={billingData.card_brand} key={1} />
            ) : (
              ''
            )}
            <span className="fs20">
              ***********{billingData.card_last_four}{' '}
            </span>
            <Button
              tertiary
              onClick={() => {
                $('#changeCardModal').modal('show')
              }}
            >
              Edit
            </Button>
          </p>

          <p className="text-center d-flex justify-content-center">
            <Button
              secondary
              onClick={() => {
                $('#cancelModal').modal('show')
              }}
            >
              Cancel
            </Button>
          </p>
        </>
      )
    }
  }
  alreadySubscriptionButExpire = () => {
    return (
      <>
        <h1 className="bold text-center mb32 animated tada">We miss you!</h1>

        <h3 className="mb40 regular text-center">
          We are keeping all your content safe for you for when you decide to go
          back to Premium account
        </h3>
        <p className="mb40 regular text-center">
          At this time you will not be able to view videos you have uploaded and
          can only access the first 2 surgeons on your list
        </p>
        <h3 className="mb40 regular text-center">
          Subscribe to Premium account again and pick up where you left off!
        </h3>

        <p className="text-center d-flex justify-content-center">
          <Button
            secondary
            onClick={() => {
              $('#upgradePlanModal').modal('show')
            }}
          >
            Upgrade Now
          </Button>
        </p>
      </>
    )
  }

  billingView = () => {
    const { loading, billingData, hasOldSubscription } = this.props.payment
    const { user } = this.props.auth
    if (loading)
      return (
        <div className="col-12">
          <AccountBillingSkeleton />
          {/* <Inbodyloader progress={5} /> */}
        </div>
      )
    if (!user.beta) {
      if (billingData) {
        if (billingData.paymentFailed) {
          return (
            <>
              <div className="col-12">{this.failPayment(billingData)}</div>
            </>
          )
        }
        if (billingData.onGracePeriod) {
          return (
            <>
              <div className="col-12">
                {this.gracePeriodLayout(billingData)}
              </div>
            </>
          )
        }
        return (
          <>
            <div className="col-12">{this.onSubscribe(billingData)}</div>
          </>
        )
      } else {
        if (hasOldSubscription) {
          return (
            <>
              <div className="col-12">
                {this.alreadySubscriptionButExpire()}
              </div>
            </>
          )
        }
      }
    } else {
      return (
        <>
          <div className="col-12">
            <h1 className="bold text-center mb32 animated tada">
              Be 3 Steps Ahead now!
            </h1>
            <h3 className="mb8 regular text-center">
              Enjoy free premium for lifetime
            </h3>
          </div>
        </>
      )
    }

    return (
      <>
        <div className="col-12">
          <h1 className="bold text-center mb32 animated tada">
            Be 3 Steps Ahead now!
          </h1>

          <h3 className="mb8 regular text-center">
            Like our CORH Surgeon Preference?
          </h3>
          <h3 className="mb8 regular text-center">Want more?</h3>
          <h3 className="mb32 regular text-center">
            Try our{' '}
            <u
              onClick={() => {
                $('#upgradeToFreeTrialModal').modal('show')
              }}
            >
              30 day Premium Trial
            </u>{' '}
            Now!
          </h3>

          <div className="row">
            <div className="col-md-6">
              <div className="card pricing-card animated fadeInUp faster free-plan">
                <div className="card-header animated fadeIn fast">
                  Your current plan
                </div>
                <div className="card-body">
                  <h1 className="title">Free plan</h1>
                  <ul className="list-unstyled">
                    <li className="animated fadeInUp delay-600">
                      {' '}
                      <i className="fa fa-check"></i>Access to CORH procedure
                      templates
                    </li>
                    <li className="animated fadeInUp delay-800">
                      {' '}
                      <i className="fa fa-check"></i>Able to create your own
                      procedure cards
                    </li>
                    <li className="animated fadeInUp delay-1000">
                      {' '}
                      <i className="fa fa-check"></i>Create surgeon profile
                    </li>
                    <li className="animated fadeInUp delay-1200 disabled">
                      {' '}
                      <i className="fa fa-times"></i>Unlimited surgeon profile
                    </li>
                    <li className="animated fadeInUp delay-1400 disabled">
                      {' '}
                      <i className="fa fa-times"></i>Unlimited video upload and
                      playback
                    </li>
                    <li className="animated fadeInUp delay-1600 disabled">
                      {' '}
                      <i className="fa fa-times"></i> 24/7 Support{' '}
                    </li>
                    <li className="animated fadeInUp delay-1800 disabled">
                      {' '}
                      <i className="fa fa-times"></i> Dark mode theme{' '}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="card pricing-card animated fadeInUp faster premium">
                <div className="card-header animated fadeIn fast">
                  Recommended
                </div>
                <div className="card-body">
                  <h1 className="title">Premium</h1>
                  <p className="mb12">
                    <Button
                      tertiary
                      datatoggle="modal"
                      datatarget="#upgradeToFreeTrialModal"
                    >
                      Try it free for 30 days
                    </Button>
                  </p>
                  <ul className="list-unstyled">
                    <li className="animated fadeInUp delay-600">
                      {' '}
                      <i className="fa fa-check"></i>Access to CORH procedure
                      templates
                    </li>
                    <li className="animated fadeInUp delay-800">
                      {' '}
                      <i className="fa fa-check"></i>Able to create your own
                      procedure cards
                    </li>
                    <li className="animated fadeInUp delay-1000">
                      {' '}
                      <i className="fa fa-check"></i>Create surgeon profile
                    </li>
                    <li className="animated fadeInUp delay-1200">
                      {' '}
                      <i className="fa fa-check"></i>Unlimited surgeon profile
                    </li>
                    <li className="animated fadeInUp delay-1400">
                      {' '}
                      <i className="fa fa-check"></i>Unlimited video upload and
                      playback
                    </li>
                    <li className="animated fadeInUp delay-1600">
                      {' '}
                      <i className="fa fa-check"></i> 24/7 Support{' '}
                    </li>
                    <li className="animated fadeInUp delay-1800">
                      {' '}
                      <i className="fa fa-check"></i> Dark mode theme{' '}
                    </li>
                  </ul>
                  <div className="d-flex justify-content-center animated fadeInUp delay-1400">
                    <Button
                      primary
                      className="w235 mr0 px5"
                      datatoggle="modal"
                      datatarget="#upgradePlanModal"
                    >
                      Upgrade to Premium
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
  cancelSubscription = () => {
    const url = `api/client/subscribe/cancel`
    const data = {
      _method: 'DELETE',
    }
    axios
      .post(url, data)
      .then(result => {
        console.log(result)
        this.props.getUser()
        this.getBillingDetails()
      })
      .catch(error => {
        console.log(error.response)
      })
  }
  resumeSubscription = () => {
    const url = `api/client/subscribe/resume`
    const data = {
      _method: 'PATCH',
    }
    axios
      .post(url, data)
      .then(result => {
        this.props.getUser()
        this.getBillingDetails()
      })
      .catch(error => {
        console.log(error.response)
      })
  }
  render() {
    const { user } = this.props.auth
    const { billingData } = this.props.payment
    return (
      <div className="container">
        {user.premium ? (
          <CardChangeModal updateInfo={() => this.getBillingDetails()} />
        ) : (
          ''
        )}
        {user.premium && billingData && !billingData.onGracePeriod ? (
          <ConfirmationModal
            idModal="cancelModal"
            title="Cancel Subscription"
            message="Cancel your subscription make us sad <i class='fa fa-frown-o'></i> "
            confirmText="Confirm"
            cancelText="Cancel"
            confirm={() => this.cancelSubscription()}
          />
        ) : (
          ''
        )}

        {user.premium && billingData && billingData.onGracePeriod ? (
          <ConfirmationModal
            idModal="resumeModal"
            title="Resume Subscription"
            message="Yes we are almost there resume now!"
            confirmText="Confirm"
            cancelText="Cancel"
            confirm={() => this.resumeSubscription()}
          />
        ) : (
          ''
        )}
        {this.changePasswordModal()}
        <ConfirmationModal
          idModal="successChangePasswordModal"
          title="Password changed"
          message="Your password has been successfully changed."
          confirmText="Okay"
          confirm={() => {
            $('#successChangePasswordModal').modal('hide')
          }}
        />
        <div className="row">
          <div className="col-xl-6 col-lg-7 col-md-7 mx-xl-auto">
            <h1 className="page-title">Account settings</h1>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-6 col-lg-7 col-md-7 mx-xl-auto">
            <Tabs
              autoScrollOff={true}
              className="px0"
              key={this.state.key}
              navs={() => {
                return (
                  <TabHead className="col-lg-12">
                    <TabNavigation
                      target="my-profile"
                      active={!this.props.router.location.query.billing}
                    >
                      My profile
                    </TabNavigation>
                    <TabNavigation target="my-account-settings">
                      My account settings
                    </TabNavigation>
                    <TabNavigation
                      target="billing-details"
                      active={this.props.router.location.query.billing}
                      onClick={() => {
                        this.getBillingDetails()
                      }}
                    >
                      Billing details
                    </TabNavigation>
                  </TabHead>
                )
              }}
              contents={() => {
                return (
                  <TabBody className="col-md-12 px0 pt16">
                    <TabContent
                      idTab="my-profile"
                      active={!this.props.router.location.query.billing}
                    >
                      <div className="setting-profile-card">
                        <UserProfileCard
                          user={user}
                          img={this.state.avatar}
                          centerProfile
                        />
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          {this.state.showNotification ? (
                            <Notifier
                              show={true}
                              message={this.state.notificationMessage}
                              onRef={ref => (this.refNotifier = ref)}
                              type="success"
                              styleClass="mb30 mt0"
                              hideThisNotif={() => {
                                this.refNotifier.hideThis()
                                this.setState({
                                  showNotification: false,
                                })
                              }}
                            />
                          ) : null}
                          <div className="row mt16">
                            <div className="col-lg-6 col-md-8">
                              <div className="input-holder">
                                <TextInput
                                  label="First name"
                                  labelclassName="fs16 font-weight-bold w-100"
                                  value={
                                    this.state.firstname == 'null'
                                      ? '-'
                                      : this.state.firstname
                                  }
                                  errorMessage={getError(
                                    this.state.errors,
                                    'firstname'
                                  )}
                                  containerClass={``}
                                  onValueChange={event => {
                                    this.setState({
                                      firstname: event.target.value,
                                    })
                                  }}
                                  onBlur={() => {
                                    this.saveProfile()
                                  }}
                                />
                              </div>

                              <div className="input-holder">
                                <TextInput
                                  label="Last name"
                                  labelclassName="fs16 font-weight-bold w-100"
                                  value={
                                    this.state.lastname == 'null'
                                      ? '-'
                                      : this.state.lastname
                                  }
                                  errorMessage={getError(
                                    this.state.errors,
                                    'lastname'
                                  )}
                                  containerClass={``}
                                  onValueChange={event => {
                                    this.setState({
                                      lastname: event.target.value,
                                    })
                                  }}
                                  onBlur={() => {
                                    this.saveProfile()
                                  }}
                                />
                              </div>

                              <div className="input-holder">
                                <label className="fs16 font-weight-bold w-100">
                                  Upload a profile photo
                                </label>
                                <ImagePicker
                                  aspectRatio={3 / 3}
                                  getImage={image => {
                                    this.setState({ avatar: image }, () => {
                                      this.saveProfile()
                                    })
                                  }}
                                />
                              </div>

                              <div className="input-holder">
                                <TextInput
                                  label={'Add a job title'}
                                  labelclassName="fs16 font-weight-bold w-100"
                                  value={
                                    this.state.job_title == 'null'
                                      ? '-'
                                      : this.state.job_title
                                  }
                                  errorMessage={getError(
                                    this.state.errors,
                                    'job_title'
                                  )}
                                  containerClass={`form-group`}
                                  onValueChange={event => {
                                    this.setState({
                                      job_title: event.target.value,
                                    })
                                  }}
                                  onBlur={() => {
                                    this.saveProfile()
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mb20">
                        <div className="col-md-12">
                          <label className="fs16 font-weight-bold w-100">
                            Add your specialties
                          </label>
                        </div>
                        {this.loadSpecialyCards()}
                      </div>
                    </TabContent>
                    <TabContent idTab="my-account-settings">
                      <div className="row mt16">
                        <div className="col-lg-7 col-md-8">
                          <div className="row">
                            <div className="col-md-12 input-holder">
                              <TextInput
                                label={'Email address'}
                                labelclassName="fs16 font-weight-bold w-100"
                                value={
                                  this.state.email == 'null'
                                    ? '-'
                                    : this.state.email
                                }
                                errorMessage={getError(
                                  this.state.errors,
                                  'email'
                                )}
                                containerClass={``}
                                onValueChange={event => {
                                  this.setState({
                                    email: event.target.value,
                                  })
                                }}
                                onBlur={() => {
                                  this.saveProfile()
                                }}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 input-holder">
                              <label className="w-100 mb10">
                                Password management
                              </label>
                              <div>
                                <Button
                                  secondary
                                  className="w200"
                                  onClick={() => {
                                    $('#changePasswordModal').modal('show')
                                  }}
                                >
                                  Change password
                                </Button>
                              </div>
                            </div>
                          </div>

                         {/* < div className="row">
                            <div className="col-md-12">
                              <label className="w-100 mb10">
                                Receive E-mail notifications for:
                              </label>
                            </div>

                            <div className="col-md-12">
                              <ButtonGroup
                                label="Announcements from Global OR"
                                error={''}
                              >
                                <label className={`btn btn-secondary active`}>
                                  <input type="radio" autoComplete="off" /> Yes
                                </label>
                                <label className={`btn btn-secondary`}>
                                  <input type="radio" autoComplete="off" /> No
                                  thanks
                                </label>
                              </ButtonGroup>
                            </div>

                            <div className="col-md-12">
                              <ButtonGroup label="Procedure updates" error={''}>
                                <label className={`btn btn-secondary`}>
                                  <input type="radio" autoComplete="off" /> Yes
                                </label>
                                <label className={`btn btn-secondary`}>
                                  <input type="radio" autoComplete="off" /> No
                                  thanks
                                </label>
                              </ButtonGroup>
                            </div>

                            <div className="col-md-12">
                              <ButtonGroup
                                label="Global OR’s marketing partners"
                                error={''}
                              >
                                <label className={`btn btn-secondary`}>
                                  <input type="radio" autoComplete="off" /> Yes
                                </label>
                                <label className={`btn btn-secondary active`}>
                                  <input type="radio" autoComplete="off" /> No
                                  thanks
                                </label>
                              </ButtonGroup>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </TabContent>
                    <TabContent
                      idTab="billing-details"
                      active={this.props.router.location.query.billing}
                    >
                      <div className="row mt8" style={{ minHeight: 400 }}>
                        {this.billingView()}
                      </div>
                    </TabContent>
                  </TabBody>
                )
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ specialty, auth, payment }) => {
  return { specialty, auth, payment }
}

export default connect(mapStateToProps, {
  loginUpdate,
  getUser,
  getPaymentDetail,
})(AccountSettings)
