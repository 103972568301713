import React, { Component } from 'react'
import err_404 from '../assets/images/404.svg'

export class pagenotfound extends Component {
  render() {
    return (
      <div className="page-not-found d-flex justify-content-end align-items-end">
        <div className="container text-center">
          <h1 className="font-weight-bold text-danger display-1">404</h1>
          <h4 className="mb-5">Sorry Page not found</h4>
          <img src={err_404} className="w-100 mb-5" />
        </div>
      </div>
    )
  }
}

export default pagenotfound

