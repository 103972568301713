import React, { Component } from 'react'
import img from '../../../../src/assets/images/item.jpg'
import $ from 'jquery'
export class ItemCard extends Component {
    render() {
        
        const { item, className, style, onClick, preview = false } = this.props
        if (item) {
            return (
                <div className={`card item-preview-sm-card ${className}`} onClick={onClick} style={style}>
                    <div className="ar32 item-img bg-white" onClick={()=>{ 
                        if (preview){
                            // this.props.onClick()
                            return;
                        }else{

                            $("#media_type").val('image').change()
                            $("#media_title").val(item.name).change()
                            $("#media_source").val(item.icon ? item.icon : img).change()
                            $("#fullMediaViewModal").modal('show')
                        }
                    }}>
                        <img src={item.icon ? item.icon : img} />
                    </div>
                    <div className="item-body">
                        <h3 className="bold item-name">{item.name}</h3>
                        <h4 className="item-detail">{item.catalog_number ? item.catalog_number:'-'}</h4>
                        <h4 className="item-detail">{item.size ? item.size:'-'}</h4>
                        <h4 className="item-detail light">{item.company ? item.company:'-'}</h4>
                        <h4 className="item-detail">{item.category ? item.category.name:'-'}</h4>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={`card item-preview-sm-card ${className}`} onClick={() => {
                    this.props.onClick()
                }}>
                    <div className="ar32 item-img bg-white">
                        <img src={img} />
                    </div>
                    <div className="item-body">
                        <h3 className="bold item-name">[Item name]</h3>
                        <h4 className="item-detail">[Catalogue number]</h4>
                        <h4 className="item-detail">[Size]</h4>
                        <h4 className="item-detail light">[Company]</h4>
                        <h4 className="item-detail">[Category]</h4>
                    </div>
                </div>
            )
        }
        return (
            <div className="card item-preview-sm-card" onClick={() => {
                this.props.onClick()
            }}>
                <div className="ar32 item-img bg-white">
                    <img src={img} />
                </div>
                <div className="item-body">
                    <h3 className="bold item-name">[Item name]</h3>
                    <h4 className="item-detail">[Catalogue number]</h4>
                    <h4 className="item-detail">[Size]</h4>
                    <h4 className="item-detail light">[Company]</h4>
                    <h4 className="item-detail">[Category]</h4>
                </div>
            </div>
        )
    }
}

export default ItemCard
