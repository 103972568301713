import React, { Component } from 'react'
import ValidationMessage from './ValidationMessage';

export class ButtonGroup extends Component {
  render() { 

    const { label, error = null, required } = this.props 

    const red_label = () => {
      return (
        <span className="error">*</span>
      )
    }
    return (
      <div className={`segmented-control-primary ${error ? 'is-invalid':''}`}>
        <label>{label} {required ? red_label() : null}</label>
        <div className="btn-group btn-group-toggle" data-toggle="buttons">
          {this.props.children}
        </div>
        {
          error ? <ValidationMessage type="invalid" message={error} />:null
        }
      </div> 
    )
  }
}

export default ButtonGroup

{/* <label className="btn btn-secondary">
            <input type="radio" name="options" id="option1" autoComplete="off" defaultChecked /> Quick notes
          </label>
          <label className="btn btn-secondary">
            <input type="radio" name="options" id="option2" autoComplete="off" /> Procedures
          </label> */}