export const dataURItoBlob = dataURI => {
  if (dataURI == '' || typeof dataURI === 'undefined') return ''
  let byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1])
  else byteString = unescape(dataURI.split(',')[1])
  let mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0]
  let ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  const randomKey =
    Math.random()
      .toString(36)
      .substring(2, 12) +
    Math.random()
      .toString(36)
      .substring(2, 15)
    
    const type = mimeString.split('/').pop()
    const blobResult = new Blob([ia], {
      type: mimeString,
    })
    console.log('blobResult',blobResult)
    blobResult.name =`CORH-MEDIA-${randomKey}.${type}`
    blobResult.hashName = blobResult.name
  return blobResult
}

export const isBase64 = dataURI => {
  return dataURI.indexOf('base64')
}

export const arrayInsert = (arr, index, newItem) => [
  ...arr.slice(0, index),

  newItem,

  ...arr.slice(index),
]
