import React, { Component } from 'react'

export default class Notifier extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
             show: false
        }
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this)
        }

        if (this.props.show){
            this.setState({
                show: this.props.show
            })
        }
    }
    
    
    componentWillReceiveProps(nextProps) {
        // if (this.props != nextProps){
            
        // }
        if(nextProps.show){
            this.setState({
                show: nextProps.show
            })
        }
    }

    showThis() {
        this.setState({
            show: true
        })
    }

    hideThis() {
        this.setState({
            show: false
        })
    }

    render() {
        const { show, message = '', type, styleClass } = this.props
        
        var icon = 'mdi-information';
        if (this.state.show) {
            if (type == 'success') {
                icon = 'mdi-check-circle'
            } else if (type == 'error') { 
                icon = 'mdi-information'
            } else if (type == 'info') {
                icon = 'mdi-information'
            } else if (type == 'warning') {
                icon = 'mdi-information'
            } 
            return (
                <div className={`notifier-div ${type} fs16 font-weight-bold ${styleClass} d-flex sticky-top`}>
                    <div className="d-flex justify-content-center align-items-center w-100 float-left">
                        <div className="body mx-auto">
                            <i className={`mdi ${icon} ${type} mr5 fs20`}></i>
                            {this.props.children}
                            {message}
                        </div>
                        <a href="javascript:;" className="close-notification" onClick={() => {
                            this.props.hideThisNotif()
                        }}>
                            <i className="mdi mdi-close"></i>
                        </a>
                    </div>
                    
                </div>
            )
        }else{
            return null;
        }
    }
}
