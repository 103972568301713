import React, { Component } from 'react'

class ItemCard extends Component {
  render() {
    const { itemData } = this.props
    return (
      <div className="col-lg-3 col-md-4 col-sm-6 mb30 p-xsx0">
        <div className="card h-100 c-pointer" onClick={ () =>{
          this.props.onClick()
        }}>
          <div className="card-img-top of-hidden d-flex align-items-center">
            <div className="image-div">
              <img src={itemData.icon} className=" bg-dark w-100" alt="..." />
            </div>
          </div>
          <div className="card-body text-center py15">
            <h5 className="card-title mb15 fs16"><b>{itemData.name}</b></h5>
            <p className="card-text mb5 fs14 text-left d-flex"><span className="mr10" style={{ fontWeight: 500 }}>Catalog:</span><span >{itemData.catalog_number ? itemData.catalog_number:'-'}</span></p>
            <p className="card-text mb5 fs14 text-left d-flex"><span className="mr10" style={{ fontWeight: 500 }}>Size:</span><span >{itemData.size ? itemData.size:'-'}</span></p>
            <p className="card-text mb5 fs14 text-left d-flex"><span className="mr10" style={{ fontWeight: 500 }}>Company:</span><span >{itemData.company ? itemData.company: '-'}</span></p>
            <p className="card-text mb5 fs14 text-left d-flex"><span className="mr10" style={{fontWeight: 500}}>Category:</span><span >{itemData.category.name}</span></p>
            {/* <h5 className="card-title fs20">{itemData.catalog_number}</h5>
            <h5 className="card-title mb15 fs20">{itemData.size}</h5>
            <p className="card-text">{itemData.company}</p>
            <h5 className="card-title mb15">{itemData.category.name}</h5> */}
          </div>
        </div>
      </div>
    )
  }
}

export default ItemCard
