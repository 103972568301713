import $ from 'jquery'
import _ from 'lodash'
import React, { Component } from 'react'
import ReactDOMServer from 'react-dom/server'
import { connect } from 'react-redux'
import ViewItemModal from './ViewItemModal';
import ViewItemCard from '../commons/cards/ViewItemCard';
import { Table, TRow, THead, TData } from '../commons/table';
import { Modal } from '../commons/modals';
import ReviewItemsModal from './ReviewItemsModal'
import { getItems, getItemsPerCategory, getMedicalInventoryCategories, itemLoading, setItems } from '../../actions/ItemAction'
import { Picker, TextInput } from '../../components/commons/forms'

export class AssignItemsDefault extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      category: 'all',
      selected_items: [],
      keyword: '',
      inventory:3,
      page: 1,
      isReverse: false
    }
  }

  componentDidMount = () => {
    this.props.getMedicalInventoryCategories(3)
    this.props.getItemsPerCategory('all', this.props.selected_items, this.state.inventory, '', this.state.page)
    this.searchData = _.debounce(this.getItemResults, 600)

  }

  componentWillReceiveProps = (nextProps) => {
    if(this.props.change != nextProps.change){
      this.setState({
        paage: 1,
        keyword: '',
        category: 'all',
        selected_items: nextProps.selected_items
      }, () => {
        this.props.getItemsPerCategory('all', this.state.selected_items, this.state.inventory, '',this.state.page)
      })
    }
  }

  componentDidUpdate() {
    $('[data-toggle="popover"]').popover()
    $('[data-toggle="popover"]').popover('hide')
  }

  getMoreItems = () => {
    const { page } = this.state

    this.setState({
      page: page + 1
    }, () => { this.props.getItemsPerCategory(this.state.category, this.state.selected_items, this.state.inventory, '', this.state.page) })
  }

  checkItem = (id) => {
    let { items } = this.props
    let { selected_items } = this.state

    let index = _.findIndex(selected_items, (e) => { return e.id == id })
    if(index >= 0){
      _.remove(selected_items, (e) => { return e.id == id})
    }else{
      index = _.findIndex(items, (e) => { return e.id == id})
      if(!items[index].qty){
        items[index].qty = 1
      }
      selected_items.unshift(items[index])
    }

    this.setState({
      selected_items: selected_items
    })
  }

  checkItemExistence = (id) => {
    let { selected_items } = this.state

    let index = _.findIndex(selected_items, (e) => { return e.id == id })

    if(index >= 0){
      return true
    }

    return false
  }

  incrementQty = (id) => {
    let { items } = this.props
    let { selected_items } = this.state

    let index = _.findIndex(selected_items, (e) => { return e.id == id})

    if(index >= 0){
      if(selected_items[index].qty){
        selected_items[index].qty += 1
      }else{
        selected_items[index].qty = 1 
      }
    }

    index = _.findIndex(items, (e) => { return e.id == id})
    if(items[index].qty){
      items[index].qty += 1
    }else{
      items[index].qty = 1
    }
    
    
    this.setState({
      selected_items: selected_items
    })

    this.props.setItems(items)
  }

  decrementQty = (id) => {
    let { items } = this.props
    let { selected_items } = this.state

    let index = _.findIndex(selected_items, (e) => { return e.id == id})

    if(index >= 0){
      if(selected_items[index].qty){
        if(selected_items[index].qty >= 0){
          selected_items[index].qty -= 1
        }
      }else{
        selected_items[index].qty = 1
      }
    }

    index = _.findIndex(items, (e) => { return e.id == id})
    if(items[index].qty){
      if(items[index].qty >= 0){
        items[index].qty -= 1
      }
    }else{
      items[index].qty = 1
    }

    this.setState({
      selected_items: selected_items
    })

    this.props.setItems(items)
  }

  getItemResults = () => {
    this.props.getItemsPerCategory(this.state.category, this.state.selected_items, this.state.inventory, this.state.keyword, this.state.page)
  }
  searchItems = ({ target: { value } }) => {
    this.props.itemLoading()
    this.setState({ keyword: value, page: 1 }, () => {
      this.searchData()
    })
  }

  sortBy(key) {
    let arrayCopy = [...this.props.items]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }

    this.props.setItems(arrayCopy)

    this.setState({ isReverse: !this.state.isReverse })
  }

  compareBy(key) {
    if (key == 'category') {
      return function (a, b) {
        if (a.category.name < b.category.name) return -1
        if (a.category.name > b.category.name) return 1
        return 0
      }
    }
    return function (a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }

  render() {

    const { items } = this.props 
     

    return (
      <div>
      <ViewItemModal
          idModal="viewItemConsumableLayout"
          key={this.state.unique}
          item={this.state.selectedItem}
          cancel={() => {
            $('#viewItemConsumableLayout').modal('hide')
          }}
        />
        <Modal
          idModal="assignItemsDefault"
          size="modal-xl"
          title="Assign items"
          confirmText="Continue"
          cancelText="Cancel"
          confirm={(e) => {
            this.props.setNewState({ selected_items: this.state.selected_items }) 
            $("#assignItemsDefault").modal('hide')
            $("#reviewPEItemsModal").modal('show')
            
          }}
          cancel={(e) => {
            //fn here
          }}
          bodyClass="assign-items-body"
        >
          <div className="row">
            <div className="col-lg-5">
              <TextInput
                label={'Search item database'}
                placeholder={'Start typing name, catalogue #…'}
                className="active"
                onChange={this.searchItems.bind(this)}
                value={this.state.keyword}
                search
              />
            </div>
            <div className="col-lg-5">
              <Picker
                onChange={event => {
                  this.setState({ category: event.target.value, keyword: '', page: 1 }, () => {
                    this.props.getItemsPerCategory(this.state.category, this.state.selected_items, this.state.inventory, '', this.state.page)
                  })
                }}
                label={`Filter by category`}
                value={this.state.category}
                data={this.props.inventory_categories}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Table minWidth={990} className="modal-table infi-table"

                renderModal={() => {
                  return (<ViewItemCard id={`preview-item-aid-1234`} onRef={ref => (this.viewItemRef = ref)} item={this.state.selectedItem} ></ViewItemCard>)
                }}

                infiniteScroll infiniteHeight={307}
                infiniteDataLength={this.props.items.length} //This is important field to render the next data
                infiniteNext={() => { this.getMoreItems() }}
                infiniteHasMore={this.props.more} 
                loading={this.props.loading}
                // endOfMessageTitle='Nothing else to see...'
                endOfMessageMessage="Nothing else to see..."
                // endOfMessageLinkMessage="Edit database"

                renderHeaders={() => {
                  return (
                    <TRow>
                      <THead className="w40" text="" />
                      <THead className="w150" text="Catalogue #" sortable onClick={() => this.sortBy('catalog_number')} />
                      <THead className="w290" text="Name" sortable onClick={() => this.sortBy('name')} />
                      <THead className="w100" text="Company" sortable onClick={() => this.sortBy('company')} />
                      <THead className="w100" text="Category" sortable onClick={() => this.sortBy('category')} />
                      <THead className="w80" text="Size"/>
                      <THead className="w100" text="Qty"/>
                    </TRow>
                  )
                }}
                renderRows={() => {
                  let rows = this.props.items.map(item => {
                    return (
                      <TRow hasChoices key={`row-aitt-${item.id}`}  autoCheck={false}>
                        <TData className="w40" onClick={() => { this.checkItem(item.id) }}>
                          <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id={`checkbox-aitt-${item.id}`} checked={this.checkItemExistence(item.id)} />
                            <label className="custom-control-label" htmlFor={`checkbox-aitt-${item.id}`} onClick={(e)=>{e.preventDefault()}}></label>
                          </div>
                        </TData>
                        <TData className="w150">{item.catalog_number}</TData>
                        <TData className="w290 has-action" item={item} popover onClick={(e) => {
                          e.stopPropagation()
                          this.setState({
                            selectedItem: item,
                            unique:
                              Math.random()
                                .toString(36)
                                .substring(2, 15) +
                              Math.random()
                                .toString(36)
                                .substring(2, 15),
                          })
                          $(`#preview-item-aid-1234`).modal('show')
                        }}>{item.name}</TData>
                        <TData className="w100">{item.company}</TData>
                        <TData className="w100">{item.category ? item.category.name : ''}</TData>
                        <TData className="w80">{item.size}</TData>
                        <TData className="w100" onClick={(e)=>{e.stopPropagation()}}>
                          <div className="w-100 d-flex align-items-center justify-content-center">
                            <input type="text" name className="form-control text-center bg-white" pattern="^[0-9]*$" value={item.qty != null ? item.qty : 1}
                              onClick={(e)=>{e.stopPropagation()}}
                              onChange={(event) => { 
                                // console.log('$(event.target).val().length', $(event.target).val().length)
                                if (/^[1-9][0-9]*$/.test($(event.target).val()) || !$(event.target).val().length) {
                                 
                                  let { selected_items } = this.state 
                                  let index = _.findIndex(selected_items, (e) => { return e.id == item.id }) 
                                  if (index > -1) {
                                    selected_items[index].qty = event.target.value
                                  }

                                  this.setState({
                                    selected_items: selected_items
                                  })

                                  index = _.findIndex(items, (e) => { return e.id == item.id })

                                  items[index].qty = event.target.value
                                  this.props.setItems(items)
                                } 
                              }}
                              onBlur={(event)=>{
                                if (!$(event.target).val().length) {
                                  let { selected_items } = this.state
                                  let index = _.findIndex(selected_items, (e) => { return e.id == item.id })
                                  if (index > -1) {
                                    selected_items[index].qty = 1
                                  }

                                  this.setState({
                                    selected_items: selected_items
                                  })

                                  index = _.findIndex(items, (e) => { return e.id == item.id })

                                  items[index].qty = 1
                                  this.props.setItems(items) 
                                }
                              }}
                            />
                          </div>
                        </TData>
                      </TRow>
                    )
                  })
                  return rows
                }}
              // renderNotification={() => {
              //     return (<TableNotificationCard type="success" message="Success message here..." />)
              // }}

              />

            </div>

          </div>
        </Modal>
      
        <ReviewItemsModal 
          selected_items={this.state.selected_items} 
          checkItem={this.checkItem}
          checkItemExistence={this.checkItemExistence} 
          setNewState={this.props.setNewState}  
          />
    </div>

    )
  }
}

const mapStateToProps = (state) => {
  return({
    items: state.item.items,
    inventory_categories: state.item.inventory_categories,
    more: state.item.more,
    loading:state.item.loading
  })
}

export default connect(mapStateToProps, { getItems, getMedicalInventoryCategories, getItemsPerCategory, setItems,itemLoading })(AssignItemsDefault)
