import React, { Component } from 'react'

export class MyRecordsCard extends Component {

    render() {

        const { 
                title='My contributions', 
                updates = 51, 
                items = 81, 
                procedures = 29, 
                surgeons = 7} = this.props
        
        
        return (
            <div className="card my-records-card">
                <h3 className="bold my-records-title">{title}</h3>
                <div className="row">
                    <div className="col-md-6 my-records-tile">
                        <h1 className="my-records-value">{updates}</h1>
                        <p className="my-records-desc">Updates made</p>
                    </div>
                    <div className="col-md-6 my-records-tile">
                        <h1 className="my-records-value">{items}</h1>
                        <p className="my-records-desc">Items registered</p>
                    </div>
                    <div className="col-md-6 my-records-tile">
                        <h1 className="my-records-value">{surgeons}</h1>
                        <p className="my-records-desc">Surgeons added</p>
                    </div>
                    <div className="col-md-6 my-records-tile">
                        <h1 className="my-records-value">{procedures}</h1>
                        <p className="my-records-desc">Procedures added</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default MyRecordsCard
