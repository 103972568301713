import React, { Component } from 'react'
import ItemCard from './ItemCard';
import TagsCard from './TagsCard';
import DataCard from './DataCard';
import MediaElement from '../media_elements/MediaElement';
import { Modal } from '../modals';
import $ from 'jquery' 
import { Event } from '../../../utilities/analytics';

export class ViewItemCard extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      item: [],
      ready: 0,
      key: Math.random(10).toString(26).substring(2, 7) + Math.random(10).toString(26).substring(2, 7)
    }
  }
  

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this)
    } 
    $(`#${this.props.id}`).on('hidden.bs.modal', () => {
      this.setState({
        ready: 0
      })
    })
    console.log('remounting the item')
  } 

    
  componentWillReceiveProps(nextProps) {
    if (this.props != nextProps && nextProps.item && this.state.ready == 0) {
      setTimeout(() => {
        this.setState({
          ready: 1,
          key: Math.random(10).toString(26).substring(2, 7) + Math.random(10).toString(26).substring(2, 7)
        }) 
      }, 1000);
     
    }
    if( nextProps.item != this.props.item ){
      if(nextProps.item.id){
        console.log('new item selected',nextProps.item.id)
        Event('Medical Inventory','Click',`[${nextProps.item.id}] - ${nextProps.item.name}  [Size ${nextProps.item.size}]`)
      }
      
    }
  }
  loadMedias() {
    const { id, item } = this.props 
    // console.log('itemitemitemitemitemitemitemitemitem', item)
    if (this.state.ready) {
      if (item.parent_media && item.media) {
        if (item.parent_media.length > 0 && !item.media.length) {
          return ( 
            <MediaElement data={item.parent_media} title="CORH Media" className="mb20" key={this.state.key}></MediaElement> 
          )
        } else if (item.media.length > 0) {
          return (
            <> 
              <MediaElement data={item.parent_media} title="CORH Media" className="mb20" key={this.state.key}></MediaElement> 
              <MediaElement data={item.media} title="My Media" className="mb20" key={`${this.state.key}2234`}></MediaElement>  
            </>
          )
        }
      }
      if (!item.parent_media && item.media) {
        let title = item.manageable ? 'My Media' : 'CORH Media';
        if (item.media.length)
          return (
            <MediaElement data={item.media} title={title} className="mb20" key={this.state.key}></MediaElement>
          )
      }
      if (item.media){
        if (item.media.length)
          return ( 
            <MediaElement data={item.media} title="CORH Media" className="mb20" key={this.state.key}></MediaElement>
          )
      }
    }
  }
  render() {
    const { id, item, className='' } = this.props
    // console.log('itemitemitemitem', item)
    return (
      <div>
        <Modal
          idModal={id}
          size="modal-full"
          title={item ? item.name:''}
          className={`preview-item-modal ${className}`}
          bodyClass="preview-item-body"
          headerClass="container"
          noFooter 
        >
          {
            item ? 

              <div className="container">

                <div className="row">
                  <div className="col-lg-3 col-md-4">
                    <ItemCard item={item} />
                    <TagsCard item={item}></TagsCard>
                  </div>
                  <div className="col-lg-9 col-md-8">
                    <div className="row mb20">
                      <div className="col-md-6">
                        <DataCard title="Used for" className="item-card h-100 color-overite" html={item.used_for ? item.used_for : '-'}>
                          
                        </DataCard>
                      </div>
                      <div className="col-md-6">
                        <DataCard title="Description" className="item-card h-100 color-overite" html={item.description ? item.description : '-'} >
                        
                          
                        </DataCard>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        {
                          this.loadMedias()
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              :null
          }
        </Modal>
      </div>
    )
  }
}

export default ViewItemCard
