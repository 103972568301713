import React, { Component } from 'react'
import { Link } from 'react-router'

export class EndOfLoading extends Component {
  render() {
    const { title = 'Nothing else to see...', message = 'Missing Someone?', link, linkMessage, onClick } = this.props
    return (
      <div className="row">
        <div className="col-md-12 text-center">
          <h2 className="end-of-loading-title">{title}</h2>
          <p className="text-center end-of-loading-description">
            {message} {" "}
            <Link to={link} onClick={onClick} className="float-left float-md-none w-xs-100">
              {linkMessage}
            </Link>
          </p>
        </div>
      </div>
    )
  }
}

export default EndOfLoading
