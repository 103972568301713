import React, { Component } from 'react'
import img from '../../../../src/assets/images/img.png'
import { BASE_URL } from '../../../config/constant'
import $ from 'jquery'
export class MediaCard extends Component {

    checkImage(src) {

        var http = new XMLHttpRequest();

        http.open('HEAD', src, false);
        http.send();

        return http.status != 404 ? src : `${BASE_URL}storage/${src}`;
      
    }

    render() {
        const { media } = this.props
        console.log("MEDEEEAAAAAAAAAAAAA", media)
        let image = media ? media.source:img;
        let title = media ? media.title:'-';

        let type = media.type
        let source = media.source

        if (type == 'video') {
            if (media.disk == 'vimeo') {
                type = 'vimeo'
                const video_id = source.split('/').pop()
                source = `https://vimeo.com/${video_id}`
            }
            image = media.thumbnail
        }
        let img_source = image
        console.log('img_source', img_source)
        return (
            <div className="media-card">
                <div className="media-image-container">
                    <img src={img_source} className="media-image" />
                </div>
                <div className="media-title" title={title}>
                    {title}
                </div>
            </div>
        )
    }
}

export default MediaCard
