import AOS from 'aos'
import 'aos/dist/aos.css'
import $ from 'jquery'
import React, { Component } from 'react'
import ReactGA from 'react-ga'
import { browserHistory, IndexRoute, Route, Router } from 'react-router'
import Components from './components/collection/Components'
import Wrapper from './components/loader/Wrapper'
import VimeoPlayer from './components/VimeoPlayer'
//Account Settings
import AccountSettings from './containers/account/AccountSettings'
import VerificationAccount from './containers/account/VerificationAccount'
import VerifyAccount from './containers/account/VerifyAccount'
import ConfirmResetPassword from './containers/auth/ConfirmResetPassword'
import Login from './containers/auth/Login'
import Register from './containers/auth/Register'
import ResetPassword from './containers/auth/ResetPassword'
import core from './containers/core/index'
import cookie from './containers/faq/cookie'
import Manual from './containers/faq/manual'
import Privacy from './containers/faq/privacy'
import Terms from './containers/faq/terms'
import Instruments from './containers/instruments'
import RegisterNewTray from './containers/instruments/RegisterNewTray'
import Items from './containers/items'
import ItemCreate from './containers/items/Create'
import ItemEdit from './containers/items/Edit'
// items
import Item from './containers/items/Item'
import Lounge from './containers/lounge/Lounge'
import Main from './containers/main'
import MedicalInventories from './containers/medical_inventories'
import Record from './containers/my_record/Record'
import Procedure from './containers/procedure/Procedure'
import SurgeonQuicknotes from './containers/quicknotes/SurgeonQuicknotes'
import GlobalSearch from './containers/search/GlobalSearch'
import Specialties from './containers/specialties'
import Surgeon from './containers/surgeon'
import SurgeonCreate from './containers/surgeon/create'
import SurgeonEdit from './containers/surgeon/edit'
import Share from './containers/surgeon/Share'
import Welcome from './containers/welcome'

import PageNotFound from './containers/pagenotfound'
import HubCreate from './containers/hub/HubCreate'
import HubManage from './containers/hub/HubManage'






class RouterLink extends Component {
  componentDidMount() {

    AOS.init();


    ReactGA.initialize('UA-158757051-1')
  }


  render() {
    return (
      <Router
        history={browserHistory}
        onUpdate={() => {
          ReactGA.pageview(window.location.pathname + window.location.search)
          window.scrollTo({
            top: 0,
            left: 0,
          })

          $('textarea').autoResize()
          $('textarea').change()
          $('input').blur()

          if ($('.sidebar').hasClass('collapsed') || !$('.sidebar').length) {
            $('main').removeClass('sidebar-open')
            $('body').removeClass('sidebar-open')
          }

          $(document).ready(() => {
            setTimeout(() => {
              let exemptions = [
                'verify-account',
                'quicknotes',
                'do-verification',
                'share-surgeon',
                ''
              ]
              var in_exemp = exemptions.find(x => {
                console.log("window.location.pathname.split('/')[1]", window.location.pathname.split('/')[1] == x)
                return x == window.location.pathname.split('/')[1] ? true : false
              })
              console.log('in_exemp', in_exemp)
              if (!in_exemp) {
                $('body').removeClass('on')
              }
              if (
                $('.sidebar').hasClass('collapsed') ||
                !$('.sidebar').length
              ) {
                $('main').removeClass('sidebar-open')
                $('body').removeClass('sidebar-open')
              }
            }, 1500)
          })
        }}
      >
        <Route path="/register" component={Wrapper(Register)} />
        <Route path="/login" component={Wrapper(Login)} />
        <Route path="/reset-password" component={Wrapper(ResetPassword)} />



        <Route path="/vimeo-player" component={VimeoPlayer} />

        <Route path="/welcome" component={Welcome} />
        
        <Route path="/404" component={PageNotFound} />



        <Route
          path="/reset-password-confirm/:token"
          component={Wrapper(ConfirmResetPassword)}
        />

        <Route
          path="verify-account/:id/:name"
          component={Wrapper(VerifyAccount)}
        />
        <Route
          path="do-verification/:token"
          component={Wrapper(VerificationAccount)}
        />

        <Route path="/" component={Wrapper(Main)}>
          <IndexRoute component={Wrapper(Surgeon)} />
          <Route path="/manual" component={Wrapper(Manual)} />
          <Route path="/create-hub" component={Wrapper(HubCreate)} />
          <Route path="/hub/:id" component={Wrapper(HubManage)} />
          
          <Route path="/surgeon" component={Wrapper(Surgeon)} />
          <Route path="/share-surgeon/:id" component={Wrapper(Share)} />
          <Route path="/surgeon/:id" component={Wrapper(Surgeon)} />
          <Route path="/surgeon-create" component={Wrapper(SurgeonCreate)} />
          <Route path="/surgeon-update/:id" component={Wrapper(SurgeonEdit)} />

          <Route path="/privacy-policy" component={Wrapper(Privacy)} />
          <Route path="/terms-of-use" component={Wrapper(Terms)} />
          <Route path="/cookie-policy" component={Wrapper(cookie)} />


          <Route
            path="/medical-inventory"
            component={Wrapper(MedicalInventories)}
          />
          <Route path="/surgeon-preferences" component={Wrapper(Specialties)} />
          <Route
            path="/medical-inventory/:inventory"
            component={Wrapper(Items)}
          />

          <Route
            path="/medical-inventory-instruments"
            component={Wrapper(Instruments)}
          />
          <Route
            path="/medical-inventory-instruments/new-tray"
            component={Wrapper(RegisterNewTray)}
          />
          <Route
            path="/medical-inventory-instruments/edit-tray/:id"
            component={Wrapper(RegisterNewTray)}
          />
          <Route
            path="/medical-inventory-create"
            component={Wrapper(ItemCreate)}
          />
          <Route path="/update-item" component={Wrapper(ItemEdit)} />
          <Route path="/item/:id" component={Wrapper(Item)} />
          <Route
            path="/quicknotes/:id"
            component={SurgeonQuicknotes} //
          />
          {/* <Route path="/procedure/:id" component={Procedure} /> */}
          <Route path="/procedure/:id" component={Procedure} />

          <Route path="/core/:id(/:pathParam1)(/:pathParam2)(/:pathParam3)" exact component={core} />


          <Route path="/my-records" component={Wrapper(Record)} />
          <Route path="/lounge" component={Wrapper(Lounge)} initial />

          <Route
            path="/account-settings"
            component={Wrapper(AccountSettings)}
          />

          <Route path="search" component={Wrapper(GlobalSearch)} />

          <Route path="components" component={Wrapper(Components)} />

        </Route>

        <Route path="*" component={PageNotFound} />

      </Router>
    )
  }
}

export default RouterLink
