import React, { Component } from 'react'

export class TableNotificationCard extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            show: false
        }

        this.hideThis = this.hideThis.bind(this)
        this.showThis = this.showThis.bind(this)

    }
    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this)
        }
    }

    showThis(){
        if(!this.state.show){
            this.setState({
                show: true
            }) 
        }
    }

    hideThis() {
        if(this.state.show){
            this.setState({
                show: false
            })
        }
    }
    
    render() {
        const { type, message, colSpan, cols } = this.props
 
        let icon = 'mdi mdi-check-circle'
        let _type = 'success'
        if(type == 'success' || type == 'valid'){
            icon = 'mdi mdi-check-circle';
            _type = 'success'
        }
        if (type == 'warning' || type == 'info') {
            icon = 'mdi mdi-information';
            _type = 'warning'
        }
        if (type == 'error' || type == 'danger' || type == 'invalid') { 
            icon = 'mdi mdi-information mdi-rotate-180';
            _type = 'error'
        }
        if(this.state.show){

            return (
                <tr className={`table-notification ${_type}`}>
                    <td className={`${_type}`} colspan={colSpan ? colSpan : cols}>
                        <div className="notif-container">
                            <i className={`icon ${icon} ${_type}`}></i>
                            <p className={`message ${_type}`}>{message}</p>
                            <a className="close-action" onClick={() => { this.hideThis() }}><i className="mdi mdi-close"></i></a>
                        </div>
                    </td>
                </tr>
            )
        }else{
            return null
        }
    }
}

export default TableNotificationCard
