  import React, { Component } from 'react'
  import $ from 'jquery'
  export class TabNavigation extends Component {
    render() {
      const { target, active = false, disabled = false, id} = this.props 
      return (
        <div className={`link nav-item nav-link ${active ? 'active' : ''} ${disabled ? 'disabled':''}`}
         id={id ? id:`nav-${target}-tab`} 
         data-toggle="tab" 
         data-target={`#nav-${target}`} 
         role="tab" 
         aria-controls={`nav-${target}`} 
         aria-selected="true" 
         onClick={this.props.onClick}>

          {this.props.children}
        </div>
      )
    }
  }
  
  export default TabNavigation
  