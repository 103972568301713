import React, { Component } from 'react'
import ReactDOMServer from 'react-dom/server'
import { Link } from 'react-router'
import $ from 'jquery'
import { Picker } from '../commons/forms'
import { getArrayError} from '../../utilities'
import axios from 'axios'
import { Table, THead, TRow, TData, TableNotificationCard } from '../commons/table';
import { Modal } from '../commons/modals';
import ValidationMessage from '../commons/forms/ValidationMessage';
import ViewItemCard from '../commons/cards/ViewItemCard';
export class AllocateItemsProcedureInstrument extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errors: [],
      isReverse: false
    }
  }

  sortBy(key) {
    let arrayCopy = [...this.props.selected_items]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }

    this.props.setSelected(arrayCopy)
    this.setState({ isReverse: !this.state.isReverse })
  }

  compareBy(key) {
    if (key == 'item_category'){
      return function (a, b) {
        if (a.item_category < b.item_category) return -1
        if (a.item_category > b.item_category) return 1
      }
    }
    if (key == 'category') {
      return function(a, b) {
        if (a.category.name < b.category.name) return -1
        if (a.category.name > b.category.name) return 1
        return 0
      }
    }
    return function(a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }

  subtractQty = (item, index) => {
    let { selected_items } = this.props

    if (item.qty > 1) {
      item.qty -= 1
    }

    selected_items[index] = item
    this.props.setSelected(selected_items)
  }

  addQty = (item, index) => {
    let { selected_items } = this.props

    item.qty += 1

    selected_items[index] = item
    this.props.setSelected(selected_items)
  }

  assignItem = () => {
    const { stage_master, item_categories, selected_items, un_selected_items } = this.props

    const formData = new FormData()
    formData.append(`_method`, 'PATCH')
    formData.append('draft', 1)
    item_categories.map((category, index) => {
      if (index > 0) {
        formData.append(`instruments[${index}]`, category.id)
        selected_items.map(item => {
          if (category.id == item.item_category) {
            formData.append(`item_ids[${index}][]`, item.id)
            formData.append(`qty[${index}][]`, item.qty)
          }
        })
        un_selected_items.map(item => {
          if (category.id == item.item_category) {
            formData.append(`item_ids[${index}][]`, item.id)
            formData.append(`qty[${index}][]`, item.qty)
          }
        })
        
      }
    })
    axios
      .post(`api/client/stage-instrument-add-items/${stage_master.id}`, formData)
      .then(result => {
        this.props.assignNewState({
          selected_items: [...selected_items, ...un_selected_items],
          selected_flag: true,
        })
        $('.modal').modal('hide')
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
      })
  }

  validate = () => {
    this.assignItem()
    return
    const { selected_items } = this.props
    this.setState({
      errors: []
    })

    let data = new FormData()
    data.append('_method', 'PATCH')
    selected_items.map((item) => {
      data.append('instruments[]', item.item_category ? item.item_category : '')
    })

    let url = '/api/client/stage-instrument-validate'

    axios.post(url, data).then((res) => {
      console.log('popo5', res)
      this.assignItem()
    }).catch((err) => {
      console.log(err)
      console.log(err.response)
      this.setState({
        errors: err.response.data.errors
      })
    })
  }

  render() {
      
    return (
      <div>

        <Modal
          idModal="allocateItemsInstrument"
          size="modal-xl"
          title="Step 2 - Allocate items"
          confirmText="Assign items"
          midButtonText="Go Back"
          cancelText="Cancel"
          confirm={(e) => {
            this.validate()
          }}
          midButtonAction={(e) => {
            $('#allocateItemsInstrument').modal('hide')
            $("#assignItemsModalCenter").modal('show')
          }}
          cancel={(e) => {
            $('.modal').modal('hide')
          }}
          bodyClass="assign-items-body"
        >
          <div className="container">
            <div className="row pb10">
              <div className="col-md-12 form-group m-0">
                <p className="m-0">
                  Please allocate new items to a consumable category.{' '}
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <Table
                  minWidth={890} className="modal-table infi-table"

                  renderModal={() => {
                    return (<ViewItemCard id={`aip-2-c-preview-${this.state.unique}`} onRef={ref => (this.viewItemRef = ref)} item={this.state.selectedItem} ></ViewItemCard>)
                  }}

                  infiniteScroll infiniteHeight={307}
                  infiniteDataLength={10} //This is important field to render the next data
                  infiniteNext={() => { }}
                  infiniteHasMore={false}

                  // endOfMessageTitle='Nothing else to see...'
                  endOfMessageMessage="Nothing else to see..."
                  // endOfMessageLinkMessage="Go back"
                  endOfMessageOnClick={() => {
                    $('#allocateItemsInstrument').modal('hide')
                    $('#assignItemsModalCenter').modal('show')
                  }}
                  renderHeaders={() => {
                    return (
                      <TRow>
                        <THead className="w40" text="" />
                        {/* <THead className="w200" text="Category" sortable onClick={() => this.sortBy('item_category')} /> */}
                        <THead className="w150" text="Catalogue #" sortable onClick={() => this.sortBy('catalog_number')} />
                        <THead className="w250" text="Name" sortable onClick={() => this.sortBy('name')} />
                        <THead className="w90" text="Size" />
                        <THead className="w90" text="Qty" />
                      </TRow>
                    )
                  }}
                  renderRows={() => {

                    let sel_items = this.props.selected_items.filter(item => {
                      return item.item_category == this.props.selected_category
                    })
                    let rows = sel_items.map((item, index) => {
                      return (
                        <TRow hasChoices key={`row-aitt-${item.id}`} autoCheck={false}>
                          <TData className="w40" onClick={() => { this.props.checkItem(item.id) }}>
                            <div className="custom-control custom-checkbox">
                              <input type="checkbox" className="custom-control-input" id={`checkbox-aitt-${item.id}`} checked={this.props.checkItemExistence(item.id)} />
                              <label className="custom-control-label" htmlFor={`checkbox-aitt-${item.id}`}></label>
                            </div>
                          </TData>
                          {/* <TData className="w200" onClick={(e) => { e.stopPropagation() }}>
                            <div className={`form-group table-form ${getArrayError(this.state.errors, 'consumables', index) ? 'is-invalid' : ''}`}>
                              <select className={`form-control ${getArrayError(this.state.errors, 'consumables', index) ? 'is-invalid' : ''}`}
                                onChange={event => {
                                  let i = this.props.selected_items
                                  item.item_category = event.target.value
                                  i[index] = item
                                  this.props.setSelected(i)
                                }}
                                value={item.item_category ? item.item_category : ''}>
                                {dropDownData.map(item => {
                                  return (
                                    <option value={item.id}>{item.name}</option>
                                  )
                                })}
                              </select>
                              {
                                getArrayError(this.state.errors, 'consumables', index) ?
                                  <ValidationMessage type="error" message="This field is required" /> : null
                              }
                            </div>
                          </TData> */}
                          <TData className="w150">{item.catalog_number}</TData>
                          <TData className="w250 has-action" item={item} popover onClick={(e) => {
                            e.stopPropagation()
                            this.setState({
                              selectedItem: item,
                              unique:
                                Math.random()
                                  .toString(36)
                                  .substring(2, 15) +
                                Math.random()
                                  .toString(36)
                                  .substring(2, 15),
                            })
                            $(`#aip-2-c-preview-${this.state.unique}`).modal('show')
                          }}>{item.name}</TData>
                          <TData className="w90">{item.size}</TData>
                          <TData className="w90">
                            <input
                              type="text"
                              defaultValue={1}
                              className="form-control text-center form-control-sm px-0"
                              value={item.qty}
                              onChange={(event) => {
                                if (/^[1-9][0-9]*$/.test($(event.target).val()) || !$(event.target).val().length) {

                                  let { selected_items } = this.props

                                  item.qty = event.target.value

                                  selected_items[index] = item
                                  this.props.setSelected(selected_items)
                                }
                              }}
                              onBlur={(event) => {
                                if (!$(event.target).val().length) { 
                                  let { selected_items } = this.props 
                                  item.qty = 1 
                                  selected_items[index] = item
                                  this.props.setSelected(selected_items)
                                }
                              }}
                              onClick={e => e.stopPropagation()}
                            />
                          </TData>
                        </TRow>
                      )
                    })
                    return rows
                  }}
                // renderNotification={() => {
                //     return (<TableNotificationCard type="success" message="Success message here..." />)
                // }}

                />

              </div>

            </div>
          </div>
        </Modal>
        
     </div>
    )
  }
}

export default AllocateItemsProcedureInstrument
