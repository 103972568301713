import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import ReactDOMServer from 'react-dom/server'
import $ from 'jquery'
import _ from 'lodash'
import axios from 'axios'
import {
  incrementItemQty,
  decrementItemQty,
  removeItem
} from '../actions/QuicknotesActions'
import AssignItemsModal from './modals/AssignItemsModal'
import CollapsePanel from './commons/panel/CollapsePanel';
import Collapsible from './commons/panel/Collapsible';
import DataCard from './commons/cards/DataCard';
import { Button } from './commons/forms';
import { Table, TRow, THead, TData } from './commons/table';
import ViewItemCard from './commons/cards/ViewItemCard';
import QtyInput from './commons/forms/QtyInput'

export class QuicknotesItemList extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      change: false,
      key: this.makeid(9)
    }

    this.modal = React.createRef()
  }
  
  componentDidMount = () => {
    function UpdateTableHeaders() {
      $(".persist-area").each(function () {

        var el = $(this),
          offset = el.offset(),
          scrollTop = $(window).scrollTop(),
          floatingHeader = $(".floatingHeader", this)

        if ((scrollTop > offset.top) && (scrollTop < offset.top + el.height() - 50)) {
          floatingHeader.css({
            "visibility": "visible"
          });
        } else {
          floatingHeader.css({
            "visibility": "hidden"
          });
        };
      });
    }

    var clonedHeaderRow;

    $(".persist-area").each(function () {
      clonedHeaderRow = $(this).find(".persist-header");
      var w = clonedHeaderRow.width()
      clonedHeaderRow
        .before(clonedHeaderRow.clone())
        .css("width", '100%')
        .addClass("floatingHeader bg-white");
    });

    $(window)
      .scroll(UpdateTableHeaders)
      .trigger("scroll");
  }
   
  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  componentWillReceiveProps(nextProps) {
    if(this.props != nextProps){
      this.setState({
        key: this.makeid(10)
      })
    }
  }
  
  onSubmit = item => {
    const data = { qty: item.qty, _method: 'PATCH' }
    axios
      .post(`quicknotes-item-list/${item.id}`, data)
      .then(result => {
        console.log(result)
      })
      .catch(error => {
        console.log(error)
      })
  }

  
  
  render() {
    const { item_list } = this.props
    
    let item_count = 0;
      item_list.map((category) => { 
          item_count += category.details.length; 
      })
    console.log('item_count', item_count)
      
    return (
      <>
        {
          item_count == 0 && !this.props.edit ? (
            <Collapsible className="mb20" title="Show items needed"> 
            <div className="data-card">
              <div className="card-body pt20">
                <p className="m0">
                  No item(s) added. Add new items via <a href="javascript:;" onClick={()=>{
                     
                    this.props.setNewState({
                      edit: true,
                      loadFrom: false,
                      qnlist: [],
                      errors: [],
                    })
                  }}>Edit database</a>.
                </p>
              </div>
            </div>
            </Collapsible>
          ) :
        <Collapsible className="mb20" title="Show items needed"> 
          {
            
            item_list.map((category, index) => {
              if (!category.details.length && !this.props.edit){
                return null;
              }
              return (
                <DataCard title={category.category_name} className="mb20" stickyHeader headerAction={() => {
                  return (<div className="d-flex ml-auto">
                    {this.props.edit  ? <Button secondary
                      onClick={() => {
                        this.setState({
                          change: !this.state.change,
                          selected_category: category.category_name
                        }, () => {
                          $('#assignItemsModalCenter').modal({
                            show: true,
                            keyboard: false
                          })
                        })
                      }}
                    >
                      Assign items
              </Button> : ''}
                  </div>)
                }}> 
                  <Table minWidth={655}
                    noResults={category.details.length > 0 ? 0 : 1}

                    renderModal={() => {
                      return (<ViewItemCard id={`preview-qil-modalprev`} onRef={ref => (this.viewItemRef = ref)} item={this.state.selectedItem} ></ViewItemCard>)
                    }}

                    renderHeaders={() => {
                      return (
                        <TRow>
                          <THead className="w120" text="Catalogue #" />
                          <THead className="w250" text="Name" />
                          <THead className="w120" text="Company" />
                          <THead className="w80" text="Qty" />
                          <THead className="w80" text="Size" />
                          {this.props.edit ? <THead className="w80" text=" " /> : null}
                        </TRow>
                      )
                    }}
                    renderRows={() => {
                      let rows = category.details.map((item, i) => {

                        if (index == 0) {
                          category.details[i].prn = category.id
                        }

                        return (
                          <TRow>
                            <TData className="w120">{item.item.catalog_number}</TData>
                            <TData className="w250 has-action" item={item.item} popover onClick={() => {
                              this.setState({ selectedItem: item.item })
                              $(`#preview-qil-modalprev`).modal('show')
                            }}>{item.item.name}</TData>
                            <TData className="w120"> {item.item.company} </TData>
                            <TData className="w80">
                              {/* {
                                this.props.edit ?
                                  <input
                                    type="text"
                                    name
                                    className="form-control text-center p0 w50"
                                    value={item.qty}
                                  /> : item.qty
                              } */}
                              {item.qty}
                            </TData>
                            <TData className="w80">{item.item.size}</TData>
                            {this.props.edit ? <TData className="w80">
                              <Button tertiary onClick={(event) => {
                                event.preventDefault()
                                let temp = _.remove(category.details, (e) => {
                                  // console.log('e', e)
                                  return e.item.id != item.item.id
                                })
                                // console.log('tempppp', temp)
                                item_list[index].details = temp
                                this.setState({
                                  change: !this.state.change,
                                  item_list: item_list
                                })
                              }}
                              >
                                Remove
                            </Button>
                            </TData> : null}
                          </TRow>
                        )
                      })
                      return rows
                    }}
                  />
                </DataCard>
              )
            })

          }
          
        </Collapsible>
        
        }
      {/* <div className="card p-0 shadow-sm border-0"> 

        <CollapsePanel
          idPanel="quicknotes_item_list"
          title="Show item list"
          content={this.loadContents()}
        />
        </div> */}
        <AssignItemsModal selected_category={this.state.selected_category} change={this.state.change} />
      </>

    )
  }
}

const mapStateToProps = state => {
  return {
    item_list: state.quicknotes.item_list,
    quicknotes_id: state.quicknotes.quicknotes_id
  }
}

export default connect(
  mapStateToProps,
  { incrementItemQty, decrementItemQty, removeItem }
)(QuicknotesItemList)
