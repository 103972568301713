import axios from 'axios'

import {
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGIN_USER,
  LOGIN_UPDATE,
  GET_LOGIN_USER,
} from './types'
import { persistStore } from 'redux-persist'

export const loginUpdate = ({ prop, value }) => {
  return {
    type: LOGIN_UPDATE,
    payload: { prop, value },
  }
}

export const setUser = user => {
  return {
    type: 'SET_USER',
    payload: user,
  }
}

export const logoutUser = () => {
  window.persistor.purge()
  console.log('success')
  
}

export const loginUser = ({ email, password }) => {
  return dispatch => {
    let data = new FormData()

    data.append('username', email)
    data.append('password', password)

    dispatch({ type: LOGIN_USER })

    axios
      .post(``, data)
      .then(result => {
        console.log(result)
        dispatch({ type: LOGIN_USER_SUCCESS})
      })
      .catch(error => {
        console.log(error.response)
        loginUserFail(dispatch)
      })
  }
}

export const getUser = () => {
  return(dispatch) => {
    let url = 'api/me'

    axios.get(url).then((res) => {
      console.log(res)
      dispatch({
        type: GET_LOGIN_USER,
        user: res.data.data
      })
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
    })
  }
}

const loginUserFail = dispatch => {
  dispatch({ type: LOGIN_USER_FAIL })
}
