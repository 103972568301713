import { 
  CREATE_ITEM, 
  SEARCH_ITEM,
  GET_ITEMS,
  GET_ITEMS_CATEGORIES,
  SET_ITEM_MEDICAL_INVENTORY,
  GET_MEDICAL_INVENTORIES,
  GET_INVENTORY_CATEGORIES,
  GET_STANDARD_ITEMS,
} from "../actions/types"


const INITIAL_STATE = {
  items: [],
  standard_items: [],
  loading: false,
  categories: [],
  medical_inventories: [],
  inventory_categories: [],
  medical_inventory_id: null,
  medical_inventory_name: null,
  more: true
}

export default (state = INITIAL_STATE, action) => {
  switch(action.type){
    case GET_ITEMS:
      return {...state, loading: action.loading, items: action.items, more: action.more}
    case GET_STANDARD_ITEMS:
      return {...state, standard_items: action.standard_items}
    case GET_ITEMS_CATEGORIES:
      return {...state, loading: action.loading, items: action.items, inventory_categories: action.categories, more: action.more}
    case SET_ITEM_MEDICAL_INVENTORY:
      return {...state, medical_inventory_id: action.medical_inventory_id, medical_inventory_name: action.medical_inventory_name}
    case CREATE_ITEM:
      return {...state}
    case GET_MEDICAL_INVENTORIES:
      return {...state, medical_inventories: action.medical_inventories}
    case SEARCH_ITEM:
      return {...state, items: action.items}
    case GET_INVENTORY_CATEGORIES:
      return {...state, inventory_categories: action.inventory_categories}
    case 'ITEM_LOADING':
      return {...state, loading:true}
    default:
      return {...state}
  }
}