import React, { Component } from 'react'
import MediaOnly from './MediaOnly';

export default class StandarMediaView extends Component { 
    render() {
        const { data, title, innerProps, classStyle } = this.props 
        return (
            <div className={`card float-left w-100 border-0 shadow-sm ${classStyle}`}>
                <div className="h3bold card-header bg-white border-0 pb0">
                    {title}
                </div>
                <div className="card-body px5 pb5 pt0">
                    {data.length > 0 ? <MediaOnly data={data} innerProps={innerProps} styleClass="shadow-none" /> : null}
                </div>
            </div>

        )
    }
}
