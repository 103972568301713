import React, { Component } from 'react'

export class EditMenu extends Component {
  render() {
    return (
      <ul className="edit-action-menu">
        {this.props.children}
      </ul>
    )
  }
}

export default EditMenu
