import axios from 'axios'
import $ from 'jquery'
import React, { Component } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { connect } from 'react-redux'
import { clearQuicknotesData } from '../../actions/QuicknotesActions'
import { decrementSurgeon } from '../../actions/UserActions'
import {
  Table,
  TableNotificationCard,
  TData,
  THead,
  TRow,
} from '../../components/commons/table'
import { ConfirmationModal } from '../../components/commons/modals'
import Axios from 'axios'
import { Button, TextInput } from '../../components/commons/forms'
import { getError } from '../../utilities'

class ManageHub extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      items: [],
      loading: false,
      dataLength: 1,
      selected_surgeon: null,
      successAppear: false,
      successMessage: '',
      email: '',
      errors: [],
      hub: {},
    }
  }

  componentDidMount() {
    console.log(this.props.activeTab)
    this.getHub()
  }
  makeRemoteRequest = () => {
    this.setState({ loading: true })
    const { page, hub } = this.state
    axios
      .get(`v1/hub-member/?hub_id=${hub.id}`)
      .then(res => {
        console.log(res)
        this.setState({
          items:
            page === 1
              ? res.data.data
              : [...this.state.items, ...res.data.data],
          loading: false,
        })
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
        this.setState({ loading: false })
      })
  }
  getHub = () => {
    this.setState({ loading: true })

    axios
      .get(`v1/hub/${this.props.params.id}`)
      .then(res => {
        console.log(res)
        this.setState(
          {
            hub: res.data,
          },
          () => {
            this.makeRemoteRequest()
          }
        )
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
        this.setState({ loading: false })
      })
  }

  renderLoading() {
    if (this.state.loading)
      return (
        <div className="d-flex justify-content-center pt30">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )
    return null
  }

  loadMoreData() {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => {
        this.makeRemoteRequest()
      }
    )
  }

  deleteSurgeon = () => {
    const { selected_surgeon } = this.state

    this.surgeonTableRef.showThis()
    const data = {
      _method: 'DELETE',
    }
    axios
      .post(`api/client/surgeon/${selected_surgeon.id}`, data)
      .then(result => {
        const res = this.state.items.filter(i => i.id != selected_surgeon.id)
        this.props.decrementSurgeon()
        this.setState(
          {
            items: res.splice(0),
            successAppear: true,
            successMessage: 'Surgeon have been successfully deleted',
          },
          () => {
            this.surgeonTableRef.showThis()
          }
        )
      })
  }
  submit = () => {
    const { email, hub } = this.state
    axios
      .post(`v1/hub-member/${hub.id}`, {
        email: email,
        _method: 'PATCH'
      })
      .then(result => {
        this.setState(
          {
            keys: [result.data, ...this.state.keys],
          },
          () => {
            $('#addModal').modal('hide')
          }
        )
      })
      .catch(errors => {
        this.setState({
          errors: errors.response.data.errors
        })
      })
  }
  renderData = () => {
    return (
      <div>
        <ConfirmationModal
          title="Delete Surgeon"
          message="Deleting surgeon will remove it from the database and will have to be added back manually."
          confirmText="Delete"
          cancelText="Cancel"
          idModal={`procedure-modal-delete`}
          confirm={this.deleteSurgeon.bind(this)}
          cancel={() => {
            $('#procedure-modal-delete').modal('hide')
          }}
        />
        {this.registerModal()}
        <div className="col ml-auto text-right d-flex justify-content-end justify-content-lg-end align-items-start has-expand">
          <Button
            primary
            expand
            datatoggle="modal"
            datatarget="#addModal"
            onClick={() => {
              this.setState({
                email: '',
                errors: [],
              })
            }}
          >
            Add Member
          </Button>
        </div>
        <div className="row animated fadeIn pt4">
          <div className="col-md-12">
            <div className="table-responsive">
              <InfiniteScroll
                className=""
                dataLength={this.state.items.length}
                next={this.loadMoreData.bind(this)}
                hasMore={true}
              >
                <Table
                  minWidth={`${this.state.isEditMode ? 1160 : 860}`}
                  renderHeaders={() => {
                    return (
                      <TRow>
                        <THead
                          className="w250"
                          text="Name"
                          sortable
                          onClick={() => this.sortBy('catalog_number')}
                        />
                        <THead
                          className="w300"
                          text="Email"
                          sortable
                          onClick={() => this.sortBy('name')}
                        />
                        <THead className="w100 text-center" text="Edits" />
                      </TRow>
                    )
                  }}
                  renderRows={() => {
                    let rows = this.state.items.map(surgeon => {
                      const specialties = ''
                      return (
                        <TRow>
                          <TData className="w250">{`${surgeon.firstname} ${surgeon.lastname}`}</TData>
                          <TData className="w300">{surgeon.email}</TData>
                          <TData className="w150 actions text-center">
                            <div className="d-flex w-100 h-100 justify-content-center">
                              Manage
                            </div>
                          </TData>
                        </TRow>
                      )
                    })
                    return rows
                  }}
                  renderNotification={() => {
                    return (
                      <TableNotificationCard
                        type="success"
                        colSpan={3}
                        message={this.state.successMessage}
                        onRef={ref => (this.surgeonTableRef = ref)}
                      />
                    )
                  }}
                />
              </InfiniteScroll>
            </div>
          </div>
        </div>
        {this.renderLoading()}
      </div>
    )
  }
  registerModal = () => {
    const { email, errors } = this.state

    return (
      <div
        className="modal fade"
        id="addModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="cancelModalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content rounded-0">
            <div className="modal-body pt30 px30">
              <h5 className="m-0 mx-auto font-weight-bold pb30 fs28">
                Invite Member
              </h5>

              <div className="row">
                <div className="col-md-12">
                  <div className="form-group mb30">
                    <TextInput
                      label={'Email'}
                      value={email}
                      placeholder={'Email'}
                      errorMessage={getError(errors, 'email')}
                      containerClass={`mb30 fs16`}
                      onValueChange={event => {
                        this.setState({ email: event.target.value })
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-body rounded-0">
              <div
                className="btn btn-dark  mr30"
                onClick={() => {
                  this.submit()
                }}
              >
                Invite
              </div>
              <div className="btn btn-outline-dark " data-dismiss="modal">
                Cancel
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  render() {
    console.log('records', this.state.items)

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="page-title">{this.state.hub?.name}</h1>
          </div>
          <div className="col-md-6">
            <p className="page-detail">
              You’ll find all of the items, procedures, surgeons, photos and
              videos registered into the hub
            </p>
          </div>
        </div>
        {this.renderData()}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

export default connect(mapStateToProps, {
  decrementSurgeon,
  clearQuicknotesData,
})(ManageHub)
