import React, { Component } from 'react'

export class Notification extends Component {
  render() {
    return (
      <div className="modal fade" id="notificationModal" tabIndex={-1} role="dialog" aria-labelledby="notificationModalTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div className="modal-content">
            <div className="modal-body text-center">
              <h5 className="m-0 mx-auto font-weight-bold text-center pb30 modal-title">{this.props.title}</h5>
              <p className="m-0 modal-text">{this.props.message}</p>
            </div>
            <div className="modal-body text-center">
              <div className="btn btn-primary" data-dismiss="modal" onClick={this.props.confirm}>{this.props.confirmText}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Notification
