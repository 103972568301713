import React, { Component } from 'react'
import { Pills } from '../commons/misc';
import TableNotificationCard from '../commons/table/TableNotificationCard';
import { ConfirmationModal, FormModal, Modal } from '../commons/modals';
import { Table, THead, TData, TRow } from '../commons/table';
import $ from 'jquery'
import ItemCard from '../commons/cards/ItemCard';
import TagsCard from '../commons/cards/TagsCard';
import DataCard from '../commons/cards/DataCard';
import MediaElement from '../commons/media_elements/MediaElement';

export class Modals extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    <h1 className="mt30">Modals</h1>
                </div>
                <div className="col-md-12 mt60">
                    <div className="cta primary-cta w150" data-toggle="modal" data-target="#deleteModal">Delete</div>
                </div>
                <div className="col-md-12 mt60">
                    <div className="cta primary-cta w150" data-toggle="modal" data-target="#addSizeModal">Add size</div>
                </div>
                <div className="col-md-12 mt60">
                    <div className="cta primary-cta w150" data-toggle="modal" data-target="#addGlovesModal">Add gloves</div>
                </div>
                <div className="col-md-12 mt60">
                    <div className="cta primary-cta w250" data-toggle="modal" data-target="#addNewStage">Add new stage</div>
                </div>
                <div className="col-md-12 mt60">
                    <div className="cta primary-cta w250" data-toggle="modal" data-target="#selectProceduresModal">Selecte procedure</div>
                </div>
                <div className="col-md-12 mt60">
                    <div className="cta primary-cta w250" data-toggle="modal" data-target="#assignItemsModal">Assign items</div>
                </div>
                <div className="col-md-12 mt60">
                    <div className="cta primary-cta w250" data-toggle="modal" data-target="#previewItemModal">PREVIEW items</div>
                </div>



                {/* DELETE MODAL  == modal-xs*/}
                <ConfirmationModal
                    idModal="deleteModal"
                    title="Delete item"
                    message="Deleting item will remove it from the database and will have to be added back manually."
                    confirmText="Save changes"
                    cancelText="Close"
                    confirm={(e) => {
                        //fn here
                    }}
                    cancel={(e) => {
                        //fn here
                    }}
                />


                {/*  Add new stage == modal-xs*/}
                <FormModal
                    idModal="addNewStage"
                    title="Add new stage"
                    confirmText="Save phase"
                    cancelText="Close"
                    confirm={(e) => {
                        //fn here
                    }}
                    cancel={(e) => {
                        //fn here
                    }}
                >
                    <div className="row">
                        <div className="col-12">
                            <div className="line-form-group-v2">
                                <input type="text" className="line-form-v2" />
                                <label>Stage name</label>
                            </div>
                        </div>
                    </div>
                </FormModal>

                {/* ADD SIZES MODAL  == modal-sm*/}
                <Modal
                    idModal="addSizeModal"
                    size="modal-sm"
                    title="Add sizes"
                    confirmText="Add"
                    cancelText="Cancel"
                    confirm={(e) => {
                        //fn here
                    }}
                    cancel={(e) => {
                        //fn here
                    }}
                >
                    <p className="modal-sub-title">Adding additional sizes for <b>[Item name]</b></p>
                    <div className="add-size-card">

                        <div className="row">
                            <div className="col-xl-10 col-lg-9 col-md-8 card-body">
                                <div className="row">
                                    <div className="col-lg-8 col-y-7">
                                        <div className="line-form-group-v2">
                                            <input type="text" className="line-form-v2" />
                                            <label>Catalog</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-y-5">
                                        <div className="line-form-group-v2">
                                            <input type="text" className="line-form-v2" />
                                            <label>Size</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-3 col-md-4 d-flex align-items-center">
                                <a href="#" className="cta cta-tertiary"><i className="mdi mdi-trash-can"></i></a>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-xl-10 col-lg-9 col-md-8 card-body">
                                <div className="row">
                                    <div className="col-lg-8 col-md-7">
                                        <div className="line-form-group-v2">
                                            <input type="text" className="line-form-v2" />
                                            <label>Catalog</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-5">
                                        <div className="line-form-group-v2">
                                            <input type="text" className="line-form-v2" />
                                            <label>Size</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-3 col-md-4 d-flex align-items-center">
                                <a href="#" className="cta cta-tertiary"><i className="mdi mdi-trash-can"></i></a>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-xl-10 col-lg-9 col-md-8 add-card">
                                Add another size +
                            </div>
                        </div>

                    </div>
                </Modal>



                {/* ADD GLOVE MODAL  == modal-md*/}
                <Modal
                    idModal="addGlovesModal"
                    size="modal-md"
                    title="Add Glove"
                    confirmText="Save glove"
                    cancelText="Cancel"
                    bodyClass="add-glove-modal-body"
                    confirm={(e) => {
                        //fn here
                    }}
                    cancel={(e) => {
                        //fn here
                    }}
                >

                    <div className="row">
                        <div className="col-lg-7 col-md-10">
                            <div className="line-form-group-v2">
                                <input type="text" className="line-form-v2" />
                                <label>Search item database <i className="mdi mdi-magnify"></i></label>
                            </div>
                        </div>
                    </div>
                    <Table minWidth={550} className="modal-table"
                        renderHeaders={() => {
                            return (
                                <TRow>
                                    <THead className="w40" text="" />
                                    <THead className="w150" text="Catalog #" sortable />
                                    <THead className="w200" text="Name" sortable />
                                    <THead className="w100" text="Size" sortable />
                                </TRow>
                            )
                        }}
                        renderRows={() => {
                            let items = [111, 222, 333, 444, 2222, 3333, 4444, 5555, 678, 113];
                            let rows = items.map(item => {
                                return (
                                    <TRow hasChoices key={`row-${item}`}>
                                        <TData className="w40">
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id={`checkbox-${item}`} />
                                                <label className="custom-control-label" htmlFor={`checkbox-${item}`}></label>
                                            </div>
                                        </TData>
                                        <TData className="w150">1234567890123</TData>
                                        <TData className="w200 has-action">Baba yetu spielgen</TData>
                                        <TData className="w100">XXXX</TData>
                                    </TRow>
                                )
                            })
                            return rows
                        }}
                        // renderNotification={() => {
                        //     return (<TableNotificationCard type="success" message="Success message here..." />)
                        // }}

                    />  
                </Modal>


                {/* ADD PROCEDURE MODAL  == modal-lg*/}

                <Modal
                    idModal="selectProceduresModal"
                    size="modal-lg"
                    title="Select Procedures"
                    confirmText="Save procedures"
                    cancelText="Cancel"
                    confirm={(e) => {
                        //fn here
                    }}
                    cancel={(e) => {
                        //fn here
                    }}
                    bodyClass="select-procedures-body"
                >

                        <p>You can add multiple categories and procedures below</p>

                        <div className="row">
                            <div className="col-lg-6 pr10">

                                <Table minWidth={370} className="modal-table"
                                    renderHeaders={() => {
                                        return (
                                            <TRow>
                                                <THead className="w40" text="" />
                                                <THead className="w270" text="Category" sortable /> 
                                                <THead className="w50" text="" sortable />
                                            </TRow>
                                        )
                                    }}
                                    renderRows={() => {
                                        let items = ['pc1', 'pc2', 'pc3', 'pc4', 'pc5', 'pc6', 'pc7', 'pc8', 'pc9', 'pc10', 'pc11', 'pc12'];
                                        let rows = items.map(item => {
                                            return (
                                                <TRow hasChoices key={`row-${item}`}>
                                                    <TData className="w40"> 
                                                        <div className="custom-control custom-radio custom-control-inline">
                                                            <input type="radio" id={`radio-${item}`} name={`radio-gaga`} className="custom-control-input" />
                                                            <label className="custom-control-label" htmlFor={`radio-${item}`}></label>
                                                        </div>
                                                    </TData>
                                                    <TData className="w270">Baba yetu spielgen</TData> 
                                                    <TData className="w50"><Pills content={`xxx`} /></TData>
                                                </TRow>
                                            )
                                        })
                                        return rows
                                    }}
                                    // renderNotification={() => {
                                    //     return (<TableNotificationCard type="success" message="Success message here..." />)
                                    // }}

                                />    
                            </div>
                            <div className="col-lg-6 pl10">

                                <Table minWidth={370} className="modal-table has-placeholder" dataText="Select a surgical area to view a list of procedures"
                                    renderHeaders={() => {
                                        return (
                                            <TRow>
                                                <THead className="w40" text="" />
                                                <THead className="w320" text="Category" sortable /> 
                                            </TRow>
                                        )
                                    }}
                                    renderRows={() => {
                                        let items = ['pc1', 'pc2', 'pc3', 'pc4', 'pc5', 'pc6', 'pc7', 'pc8', 'pc9', 'pc10', 'pc11', 'pc12'];
                                        let rows = items.map(item => {
                                            return (
                                                <TRow hasChoices key={`row-chck-${item}`}>
                                                    <TData className="w40">

                                                        <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input" id={`checkbox-chck-${item}`} />
                                                            <label className="custom-control-label" htmlFor={`checkbox-chck-${item}`}></label>
                                                        </div> 
                                                    </TData>
                                                    <TData className="w320">Baba yetu spielgen</TData> 
                                                </TRow>
                                            )
                                        })
                                        return rows
                                    }}
                                // renderNotification={() => {
                                //     return (<TableNotificationCard type="success" message="Success message here..." />)
                                // }}

                                />   

                            </div>
                        </div>

                </Modal>


                {/* ASSIGN ITEMS MODAL  == modal-xl*/}
                <Modal
                    idModal="assignItemsModal"
                    size="modal-xl"
                    title="Step 1 - Assign items to tray"
                    confirmText="Continue"
                    cancelText="Cancel"
                    confirm={(e) => {
                        $("#assignItemsModal").modal('hide');
                        $("#allocateItemsModal").modal('show');
                    }}
                    cancel={(e) => {
                        //fn here
                    }}
                    bodyClass="assign-items-body"
                >
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="line-form-group-v2">
                                <input type="text" className="line-form-v2" />
                                <label>Search item database <i className="mdi mdi-magnify"></i></label>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="line-form-group-v2">
                                <select>
                                    <option>Select category</option>
                                    <option>Sample 1</option>
                                    <option>Sample 2</option>
                                </select>
                                <label>Filter by category</label>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-lg-12">
                            <Table minWidth={990} className="modal-table"
                                renderHeaders={() => {
                                    return (
                                        <TRow> 
                                            <THead className="w40" text="" />
                                            <THead className="w150" text="Catalogue #" sortable/>
                                            <THead className="w340" text="Name" sortable /> 
                                            <THead className="w150" text="Company" sortable /> 
                                            <THead className="w200" text="Category" sortable /> 
                                            <THead className="w90" text="Size" sortable/> 
                                        </TRow>
                                    )
                                }}
                                renderRows={() => {
                                    let items = ['aitt1', 'aitt2', 'aitt3', 'aitt4', 'aitt5', 'aitt6', 'aitt7', 'aitt8', 'aitt9', 'aitt10', 'aitt11', 'aitt12'];
                                    let rows = items.map(item => {
                                        return (
                                            <TRow hasChoices key={`row-aitt-${item}`}> 
                                                <TData className="w40"> 
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id={`checkbox-aitt-${item}`} />
                                                        <label className="custom-control-label" htmlFor={`checkbox-aitt-${item}`}></label>
                                                    </div>
                                                </TData>
                                                <TData className="w150">1234567890</TData>
                                                <TData className="w340 has-action">Baba yetu spielgen</TData>
                                                <TData className="w150">Johnson & Johnson</TData>
                                                <TData className="w200">Category 1</TData>
                                                <TData className="w90">XXX</TData>
                                            </TRow>
                                        )
                                    })
                                    return rows
                                }}
                            // renderNotification={() => {
                            //     return (<TableNotificationCard type="success" message="Success message here..." />)
                            // }}

                            />   
                            
                        </div>

                    </div>
                </Modal>
                
                <Modal
                    idModal="allocateItemsModal"
                    size="modal-xl"
                    title="Step 2 - Assign items to tray"
                    confirmText="Assign items"
                    cancelText="Cancel"
                    confirm={(e) => {
                    }}
                    cancel={(e) => {
                        $("#allocateItemsModal").modal('hide');
                        $("#assignItemsModal").modal('show');
                    }}
                    bodyClass="assign-items-body"
                >
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="line-form-group-v2">
                                <input type="text" className="line-form-v2" />
                                <label>Search item database <i className="mdi mdi-magnify"></i></label>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="line-form-group-v2">
                                <select>
                                    <option>Select category</option>
                                    <option>Sample 1</option>
                                    <option>Sample 2</option>
                                </select>
                                <label>Filter by category</label>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <Table minWidth={990} className="modal-table"
                                renderHeaders={() => {
                                    return (
                                        <TRow> 
                                            <THead className="w250" text="Consumable category" />
                                            <THead className="w120" text="Qty" />
                                            <THead className="w150" text="Catalogue #" sortable />
                                            <THead className="w340" text="Name" sortable />
                                            <THead className="w90" text="Size" sortable />
                                        </TRow>
                                    )
                                }}
                                renderRows={() => {
                                    let items = ['aitt1', 'aitt2', 'aitt3', 'aitt4', 'aitt5', 'aitt6', 'aitt7', 'aitt8', 'aitt9', 'aitt10', 'aitt11', 'aitt12'];
                                    let rows = items.map(item => {
                                        return (
                                            <TRow hasChoices key={`row-aitt-${item}`}>
 
                                                <TData className="w250">
                                                    <div className="form-group table-form">
                                                        <select className="form-control">
                                                            <option>1234567891234</option>
                                                            <option>1234567891234</option>
                                                            <option>1234567891234</option>
                                                        </select>
                                                    </div>
                                                </TData>
                                                <TData className="w120"> 
                                                    <div className="form-group table-form">
                                                        <input type="text" className="form-control" />
                                                    </div>
                                                </TData>
                                                <TData className="w150">1234567890</TData>
                                                <TData className="w340 has-action">Baba yetu spielgen</TData>
                                                <TData className="w90">XXX</TData>
                                            </TRow>
                                        )
                                    })
                                    return rows
                                }}
                            // renderNotification={() => {
                            //     return (<TableNotificationCard type="success" message="Success message here..." />)
                            // }}

                            />    
                        </div>

                    </div>
                </Modal>

                <Modal
                    idModal="previewItemModal"
                    size="modal-full"
                    title="[ITEM NAME]"   
                    className="preview-item-modal"
                    bodyClass="preview-item-body"
                    headerClass="container"
                    noFooter
                >
                    <div className="container">

                        <div className="row">
                            <div className="col-lg-3 col-md-4">
                                <ItemCard />
                                <TagsCard item={{tags:[{name:'a'}]}}></TagsCard>
                            </div>
                            <div className="col-lg-9 col-md-8">
                                <div className="row mb20">
                                    <div className="col-md-6">
                                        <DataCard title="Used for" className="item-card">
                                            <p>Show description of use here. Description not necessessary as we want to impart pure use and context of the item instead.</p>
                                            <p>Show description of use here. Description not necessessary as we want to impart pure use and context of the item instead.</p>
                                        </DataCard>
                                    </div>
                                    <div className="col-md-6">
                                        <DataCard title="Description" className="item-card">
                                            <p>Show description of use here. Description not necessessary as we want to impart pure use and context of the item instead.</p>
                                            <p>Show description of use here. Description not necessessary as we want to impart pure use and context of the item instead.</p>
                                        </DataCard>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <MediaElement></MediaElement>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Modal>
            </div>
        )
    }
}

export default Modals
