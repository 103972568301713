import React, { Component } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import $ from 'jquery'

export class PositioningEquipmentSkeleton extends Component {
	constructor(props) {
		super(props)
	
		this.state = {
			color: '#d2d2d2',
			highlightColor: '#dedede'
		}
	}

	componentWillMount() {
		if ($('body').hasClass('dark-theme')) {
			this.setState({
				color: '#1d212e',
				highlightColor: '#151821'
			})
		}
	}

	textElement = () => {
		return (
		<div className={'card text-preview w-100 mb20 skeletoncard'}>
			<div className="card-header text-capitalize pb5 ">
			  <Skeleton width={'30%'} height={30} />
			</div>
		  </div>
		)
	  }

	render() {
		return ( 
				<SkeletonTheme color={this.state.color} highlightColor={this.state.highlightColor}>
				<div class="data-card mb20">
					<div class="card-header  ">
						<h3 class=" w-100"><Skeleton height={30} width={'35%'} /></h3>
					</div>
					<div class="card-body "> 
						<Skeleton height={470} />
					</div>
				</div>
				{this.textElement()}
				
				</SkeletonTheme>
		)
	}


}

export default PositioningEquipmentSkeleton
