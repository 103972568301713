import React, { Component } from 'react'

export class TabContent extends Component {
  render() {
    const { idTab, active = false, className='' } = this.props 
    return (
      <div className={`tab-pane fade ${active ? 'show active' : ''} ${className}`} id={`nav-${idTab}`} role="tabpanel">
        {this.props.children}
      </div>
    )
  }
}

export default TabContent
