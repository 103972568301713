import React, { Component } from 'react'

import img from '../../../../src/assets/images/img.png'
import default_surgeon_img from '../../../../src/assets/images/default_profile.jpg'

export class SurgeonCard extends Component {
  render() {
    const { surgeon } = this.props

    if (surgeon) {
      const specialities = surgeon.specialties.map(data => {
        if (data.specialty) {
          return (
            <span
              className="specialty-span"
              style={{ background: `${data.specialty.color}` }}
            >
              {data.specialty ? data.specialty.name : null}
            </span>
          )
        }
      })
      return (
        <div
          className="card surgeon-card"
          onClick={surgeon => {
            this.props.onClick(surgeon)
          }}
        >
          <div className="card-body surgeon-card-body">
            <img
              src={surgeon.avatar ? surgeon.avatar : default_surgeon_img}
              className="surgeon-image icon-circle icon-circle-lg"
            />
            <div className="">
              <h3 className="bold surgeon-name">{`${surgeon.firstname} ${
                surgeon.lastname
              }`}</h3>
              <span className="surgeon-title">{surgeon.title}</span>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <ul className="list-unstyled surgeon-details with-btm-border">
                  <li className="item">
                    <b>Gloves </b>
                    {`${
                      surgeon.inner_glove == undefined
                        ? ''
                        : surgeon.inner_glove.name
                    }`}
                  </li>
                  <li className="item skip">
                    <b>Size </b>
                    {`${
                      surgeon.inner_glove == undefined
                        ? ''
                        : surgeon.inner_glove.size
                    }`}
                  </li>
                  <li className="item">
                    <b>Outer Gloves </b>
                    {`${
                      surgeon.outer_glove == undefined
                        ? ''
                        : surgeon.outer_glove.name
                    }`}
                  </li>
                  <li className="item">
                    <b>Size </b>
                    {`${
                      surgeon.outer_glove == undefined
                        ? ''
                        : surgeon.outer_glove.size
                    }`}
                  </li>
                </ul>
                <ul className="list-unstyled surgeon-details with-btm-border">
                  <li className="item">
                    <b>Prefers</b>
                  </li>
                  <li className="item">
                    <span className="item-detail">
                      {`Music ${surgeon.music ? 'on' : 'off'}`}{' '}
                    </span>
                    <span className="item-detail">
                      {surgeon.enviroment_on ? '' : 'Silent OR'}
                    </span>
                  </li>
                </ul>
                <ul className="list-unstyled surgeon-details">
                  <li className="item mb0">
                    <b>Specialties</b>
                  </li>
                  <li className="item mb0">{specialities}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="card surgeon-card">
          <div className="card-body surgeon-card-body">
            <img
              src={img}
              className="surgeon-image icon-circle icon-circle-lg"
            />
            <div className="">
              <h3 className="bold surgeon-name">Radney Awaykin</h3>
              <span className="surgeon-title">Surgeon</span>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <ul className="list-unstyled surgeon-details with-btm-border">
                  <li className="item">
                    <b>Gloves </b>
                    Glove's full name
                  </li>
                  <li className="item skip">
                    <b>Size </b>
                    XXXX
                  </li>
                  <li className="item">
                    <b>Outer Gloves </b>
                    Glove's full name
                  </li>
                  <li className="item">
                    <b>Size </b>
                    XXXX
                  </li>
                </ul>
                <ul className="list-unstyled surgeon-details with-btm-border">
                  <li className="item">
                    <b>Prefers</b>
                  </li>
                  <li className="item">
                    <span className="item-detail">Music on</span>
                    <span className="item-detail">Silent OR</span>
                  </li>
                </ul>
                <ul className="list-unstyled surgeon-details">
                  <li className="item mb0">
                    <b>Specialties</b>
                  </li>
                  <li className="item mb0">
                    <span className="specialty-span">Orthopedics</span>
                    <span className="specialty-span">Pediatrics</span>
                    <span className="specialty-span">ENT</span>
                    <span className="specialty-span">Thyroid</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default SurgeonCard
