import React, { Component } from 'react' 
import img from '../../assets/images/img.png'
import { Pills } from '../commons/misc'
import { Button } from '../commons/forms'
import ValidationMessage from '../commons/forms/ValidationMessage';

export class Forms extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-md-6">
                    <h1>Forms</h1>
                    <div className="line-form-group-v2">
                        <input type="text" className="line-form-v2" />
                        <label>Firstname</label>
                    </div>
                    <div className="line-form-group-v2 is-valid">
                        <input type="text" className="line-form-v2" />
                        <label>Firstname</label>
                        <ValidationMessage type="success" message="Success message here..." />
                    </div>
                    <div className="line-form-group-v2 is-warning">
                        <input type="text" className="line-form-v2" />
                        <label>Firstname</label>
                        <ValidationMessage type="warning" message="Warning message here..." />
                    </div>
                    <div className="line-form-group-v2 is-invalid">
                        <input type="text" className="line-form-v2" />
                        <label>Firstname</label>
                        <ValidationMessage type="invalid" message="This field is required!" />
                    </div>
                    <hr className="mt60" />
                    <h1>Dropdowns</h1>
                    <div className="line-form-group-v2">
                        <select>
                            <option>Please select here...</option>
                            <option>Sample 1</option>
                            <option>Sample 2</option>
                        </select>
                        <label>Filter supplies by category</label>
                    </div>

                    <div className="line-form-group-v2 is-valid">
                        <select>
                            <option>Please select here...</option>
                            <option>Sample 1</option>
                            <option>Sample 2</option>
                        </select>
                        <label>Filter supplies by category</label>
                        <ValidationMessage type="success" message="Success message here..." />
                    </div>

                    <div className="line-form-group-v2 is-warning">
                        <select>
                            <option>Please select here...</option>
                            <option>Sample 1</option>
                            <option>Sample 2</option>
                        </select>
                        <label>Filter supplies by category</label>
                        <ValidationMessage type="warning" message="Warning message here..." />
                    </div>

                    <div className="line-form-group-v2 is-invalid">
                        <select>
                            <option>Please select here...</option>
                            <option>Sample 1</option>
                            <option>Sample 2</option>
                        </select>
                        <label>Filter supplies by category</label>
                        <ValidationMessage type="invalid" message="Error message here..." />
                    </div>

                    <hr className="mt60" />
                    <h1>Text boxes</h1>

                    <div className="form-group">
                        <label>Name</label>
                        <textarea className="form-control" defaultValue="Baba yetu spielgen over 2 lines because this has a very long name"></textarea>
                    </div>

                    <div className="form-group is-valid">
                        <label>Name</label>
                        <textarea className="form-control" defaultValue="Baba yetu spielgen over 2 lines because this has a very long name"></textarea>
                        <ValidationMessage type="valid" message="Success message here..." />
                    </div>
 
                    <div className="form-group is-warning">
                        <label>Name</label>
                        <textarea className="form-control" defaultValue="Baba yetu spielgen over 2 lines because this has a very long name"></textarea>
                        <ValidationMessage type="warning" message="Warning message here..." />
                    </div>

                    <div className="form-group is-invalid">
                        <label>Name</label>
                        <textarea className="form-control" defaultValue="Baba yetu spielgen over 2 lines because this has a very long name"></textarea>
                        <ValidationMessage type="invalid" message="Error message here..." />
                    </div>

                    <hr className="mt60" />
                    <h1 className="mb0">Element title form</h1>

                    <div className="line-form-group-v2">
                        <input type="text" className="line-form-v2" placeholder="Add a title" />
                    </div>
                    <div className="line-form-group-v2">
                        <input type="text" className="line-form-v2" />
                        <label>Add a title</label>
                    </div>


                    <hr className="mt60" />
                    <h1>Segmented control</h1>
                    
                    <div className="row">
                        <div className="col-md-12">
                            <div className=" segmented-control-primary">
                                <label>Name</label>
                                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                    <label className="btn btn-secondary">
                                        <input type="radio" name="options" id="option1" autoComplete="off" defaultChecked /> Quick notes
                                    </label>
                                    <label className="btn btn-secondary">
                                        <input type="radio" name="options" id="option2" autoComplete="off" /> Procedures
                                    </label>
                                </div>
                            </div> 
                            <div className=" segmented-control-primary is-invalid">
                                <label>Name</label>
                                <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                    <label className="btn btn-secondary">
                                        <input type="radio" name="options" id="option1" autoComplete="off" defaultChecked /> Quick notes
                                    </label>
                                    <label className="btn btn-secondary">
                                        <input type="radio" name="options" id="option2" autoComplete="off" /> Procedures
                                    </label>
                                </div>
                                <ValidationMessage type="invalid" message="Error message here..." />
                            </div>
                        </div>
                    </div>

                    <hr className="mt60" />
                    <h1>Large buttons with icon</h1>
                    <div className="row ">
                        <div className="col-md-10 mt30">
                            <div className="cta secondary-cta cta-lg active">
                                <img src={img} />
                                Breast
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-10 mt30">
                            <div className="cta cta-secondary-outline cta-lg">
                                <img src={img} className="bg-dark" />
                                Breast
                            </div>
                        </div>
                    </div>

                    <hr className="mt60" />
                    <h1>Call to actions</h1>
                    <div className="row ">
                        <div className="col-md-12 mt30 d-flex">
                            <Button primary>Primary</Button>
                            <Button secondary>Secondary</Button>
                            <Button tertiary>Tertiary</Button>
                        </div>
                        <div className="col-md-12 mt30 d-flex">
                            <Button primary outline>Primary</Button>
                            <Button secondary outline>Secondary</Button>
                            <Button tertiary>Tertiary</Button>
                        </div>
                    </div>

                    <hr className="mt60" />
                    <h1>Dark mode</h1>
                    <div className="custom-control custom-switch">
                        <input type="checkbox" className="custom-control-input" id="customSwitch1" />
                        <label className="custom-control-label" htmlFor="customSwitch1">Toggle this switch element</label>
                    </div>

                </div>
                <div className="col-md-6">
                    <h3>VALIDATION ICONS</h3>
                    <div className="row">
                        <div className="col-md-12 mt24">
                            <ValidationMessage type="success" message="Success message here..." />
                        </div>
                        <div className="col-md-12 mt24">
                            <ValidationMessage type="warning" message="Warning message here..." />
                        </div>
                        <div className="col-md-12 mt24">
                            <ValidationMessage type="invalid" message="Error message here..." />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Forms
