import React, { Component } from 'react'
import axios from 'axios'
import BigPicture from 'react-bigpicture'
import { Link } from 'react-router'
import BigPictureModal from '../modals/BigPictureModal';
import $ from 'jquery'
import { RecentMediaCard } from '../commons/cards';

class Media extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      page: 1,
      loading: false,
      selected_media:[]
    }
  }
  
  componentDidMount() {
    this.makeRemoteRequest()
     
  }

  makeRemoteRequest = () => {
    this.setState({
      loading: true,
    })
    const { page } = this.state

    axios
      .get(`api/my-galleries?page=${page}`)
      .then(res => {
        this.setState({
          data:
            page === 1 ? res.data.data : [...this.state.data, ...res.data.data],
          loading: false,
        })
      })
      .catch(errors => {
        console.log(errors)
        console.log(errors.response)
      })
  }

  loadMoreData() {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => {
        this.makeRemoteRequest()
      }
    )
  }

  previewThumbnail(media){
    if (media) {
      let type = media.type
      let source = media.source

      if (type == 'video') {
        if (media.disk == 'vimeo') {
          type = 'vimeo'
          const video_id = source.split('/').pop()
          source = `https://vimeo.com/${video_id}`
        }
      }

      let thumbnail = media.thumbnail
      if (thumbnail.split('.').pop() == 'mp4') {
        thumbnail = 'http://lorempixel.com/400/200/sports/5'
      }
      return (  
          <div className="ar32" onClick={() => {  
            $("#media_type").val('image').change()
            $("#media_title").val('Most recent media upload').change()
            $("#media_source").val(thumbnail).change()
            $("#fullMediaViewModal").modal('show')
          }}>
            <img src={thumbnail} />
          </div> 
      )
    }
    return null
  }
  render() {
    const { data } = this.state
    return (
      <div className="card shadow-0 border-0 mb24">
        <BigPictureModal idModal="recentMediaPreviewModal" data={this.state.selected_media} />
        <RecentMediaCard viewAllLink={{ pathname: '/my-records', state: { tab: 'galleries' } }}> 
          {data.length > 0 ? this.previewThumbnail(data[0]) : <p>No media uploaded yet.</p>} 
        </RecentMediaCard>
      </div>
    )
  }
}

export default Media
