import React, { Component } from 'react'

export class DataCard extends Component {

  header = () => {
    const { title, className, bodyClass, stickyHeader, headerAction, id, inputHeader, html, headerClass, cardHeaderClass = '', headerIcon, onClick} = this.props
    

    return(
      <div className={`card-header ${stickyHeader ? 'sticky-top':''} ${cardHeaderClass}`} onClick={onClick}>
        {
          inputHeader ? inputHeader() :
            <h3 className={` ${headerAction ? 'd-flex align-items-center' : ''} ${headerClass ? headerClass : ''} w-100`}>
              {title ? title : '-'}
              {headerAction ? headerAction() : null}
              {headerIcon ? headerIcon() : null}
            </h3>
        }
      </div>
    )
  }

  render() {
    const { title, className, bodyClass, stickyHeader, headerAction, id, inputHeader, html, headerClass, cardHeaderClass = '', headerIcon, onClick} = this.props
    return (
      <div className={`data-card ${className}`} id={id}>
        
        {this.header()}
        <div className={`card-body ${bodyClass}`}>
          {
            html ? <div
            dangerouslySetInnerHTML={{
              __html: html
            }}
          />:null
          }
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default DataCard
