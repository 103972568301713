import { CLEAR_QUICKNOTES_DATA, GET_QUICKNOTES_CATEGORIES, GET_QUICKNOTES_ITEM_LIST, GET_SURGEON_QUICKNOTES, SET_QUICKNOTES_ID, UPDATE_QUICKNOTES_LIST } from '../actions/types'

const INITIAL_STATE = {
  categories: [],
  quicknotes_id: null,
  quicknotes_list: [],
  draft_count: 0,
  surgeon_quicknotes_id: null,
  item_list: [],
  loading: false,
  
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case GET_QUICKNOTES_CATEGORIES:
        return {...state, categories: action.categories}
      case GET_SURGEON_QUICKNOTES:
        return {...state, quicknotes_id: action.quicknotes_id, quicknotes_list: action.quicknotes_list, surgeon_quicknotes_id: action.surgeon_quicknotes_id, draft_count: action.draft_count , loading: action.loading}
      case GET_QUICKNOTES_ITEM_LIST:
        return {...state, item_list: action.item_list, loading: action.loading}
      case SET_QUICKNOTES_ID:
        return {...state, quicknotes_id: action.quicknotes_id, item_list: [], quicknotes_list: [], surgeon_quicknotes_id: null}
      case UPDATE_QUICKNOTES_LIST:
        let quicknotes_list = action.quicknotes_list.splice(0)
        return {...state, quicknotes_list: quicknotes_list, draft_count:0}
      case CLEAR_QUICKNOTES_DATA:
        return {
          ...state,
          quicknotes_id: null,
          quicknotes_list: [],
          draft_count: 0,
          surgeon_quicknotes_id: null,
          item_list: [],
          loading: false,
        }
      default:
        return state
   }
}
