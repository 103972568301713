import React, { Component } from 'react'

export default class Logo extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      icon: require(`../../../../src/assets/images/logos/logo-single.png`)
    }
  }

  componentDidMount() {
    this.setState({
      icon: require(`../../../../src/assets/images/logos/${this.props.icon}.png`)
    })
  }
  render() {
    return (<img src={this.state.icon} className={this.props.className} />)
  }
}
