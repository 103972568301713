import axios from 'axios'
import $ from 'jquery'
import _ from 'lodash'
import { Cropper } from 'rc-cropping'
import 'rc-cropping/assets/index.css'
import Dialog from 'rc-dialog'
import 'rc-dialog/assets/index.css'
import React, { Component } from 'react'
import { ErrorText } from '../components/validations/error'
import { getError } from '../utilities/'
import ImagePicker from './commons/elements/inputs/ImagePicker'
import { Button, ButtonGroup, TextInput } from './commons/forms'
import ValidationMessage from './commons/forms/ValidationMessage'
import { Modal } from './commons/modals'
import { Table, TData, THead, TRow } from './commons/table'
import SpecailtySection from './specialty_section'
import ViewItemCard from './commons/cards/ViewItemCard'


class SurgeonForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      file: null,
      croppedFile: null,
      show: false,
      isNext: false,
      gloveData: [],
      page: '',
      filename: '',
      selected: null,
      errors: [],
      keyword: '',
      glove_error: false,
      searchKeyword:'',
      selectedItem: []
    }

    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {   
    const { surgeon} = this.props
    
    console.log(surgeon,'sssss')
    $('#gloves-modal').on('hidden.bs.modal', () => {
      this.setState({
        selected: null,
      })
    })  
    
    if (window.location.pathname.split('/')[1] == 'surgeon-update'){
      this.setState({ 
        errors: [],
      })
    }
  }

  handleClick(e) {
    this.setState({
      show: true,
    })
    this.refs.file.click()
  }
  onSubmit() {
    const { surgeon } = this.props
    
    console.log(surgeon)
    $(".continue-btn").click()
    // this.setState({
    //   isNext: true,
    // })
  }

  onFileChange = () => {
    this.setState({ file: this.refs.file.files[0] })
  }
  croppeFile = fileBlob => {
    if (!fileBlob) {
      this.setState({
        show: false,
        file: null,
        croppedFile: null,
        isNext: false,
        page: '',
      })
      return
    }
    const reader = new FileReader()
    reader.readAsDataURL(fileBlob)
    let filename = this.state.file.name
    reader.onload = () => {
      this.setState({
        show: false,
        file: null,
        croppedFile: null,
        isNext: false,
        page: '',
        filename: filename,
      })
      this.props.surgeonUpdate({
        prop: 'avatar',
        value: reader.result,
      })
    }
  }

  gloveModalOpen(hand) {
    this.setState({
      hand,
      keyword: '',
      glove_error: false
    })
    axios
      .get(`api/gloves?page=${this.state.page}`)
      .then(result => {
        this.setState({
          gloveData: result.data.data,
        })
      })
      .catch(error => console.log(error))
  }

  renderCropper() {
    const { croppedFile, file } = this.state

    if (!file) {
      if (this.state.filename) {
        return (
          <div className="d-inline-flex align-items-center w-100">
            <input
              type="file"
              onChange={this.onFileChange}
              ref="file"
              className="d-none"
            />
            <Button className="cta cta-secondary" onClick={this.handleClick}>
              Change
          </Button>
          </div>
        )
      }

      return (
        <div>
          <input
            type="file"
            onChange={this.onFileChange}
            ref="file"
            className="d-none"
          />
          <Button className="cta cta-secondary" onClick={this.handleClick}>
            Upload
          </Button>
        </div>
      )
    } else {
      if (this.state.show) {
        return (
          <Cropper
            size={[64, 64]}
            file={this.state.file}
            getSpinContent={() => <span>loading...</span>}
            renderModal={() => <Dialog className="modal-dialog-centered" />}
            onChange={this.croppeFile}
            circle
          />
        )
      }
    }
  }
   isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  renderGlove = () => {
    // console.log('suurgeon', this.props.surgeon)

    // console.log('suurgeonglove', this.props.surgeon.glove_inner)
 
    if (!this.isEmpty(this.props.surgeon.inner_glove)) {
      return (
        <div className="d-inline-flex align-items-center">
          <p className="m0">{this.props.surgeon.inner_glove.name}</p>
          <Button
            tertiary
            datatoggle="modal"
            datatarget="#gloves-modal"
            className="w100"
            onClick={this.gloveModalOpen.bind(this, 'inner_glove')}
          >
            Edit
          </Button>
        </div>
      )
    } else {
      return (
        <Button
          secondary
          className="ml0"
          datatoggle="modal"
          datatarget="#gloves-modal"
          className="w100"
          onClick={this.gloveModalOpen.bind(this, 'inner_glove')}
        >
          Add
        </Button>
      )
    }
  }

  renderOuterGlove = () => {
    if (!this.isEmpty(this.props.surgeon.outer_glove)) {
      return (
        <div className="d-inline-flex align-items-center">
          <p className="m0">{this.props.surgeon.outer_glove.name}</p>
          <Button
            tertiary
            datatoggle="modal"
            datatarget="#gloves-modal"
            className="w100"
            onClick={this.gloveModalOpen.bind(this, 'outer_glove')}
          >
            Edit
          </Button>
        </div>
      )
    } else {
      return (
        <Button
          secondary
          className="ml0"
          datatoggle="modal"
          datatarget="#gloves-modal"
          className="w100"
          onClick={this.gloveModalOpen.bind(this, 'outer_glove')}
        >
          Add
        </Button>
      )
    }
  }

  getGloves = searchKeyword => {
    let url = `api/gloves?search=${this.searchKeyword.value}&page=`
    axios
      .get(url)
      .then(res => {
        this.setState({
          gloveData: res.data.data,
        })
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
      })
  }

  renderContent() {
    const { surgeon } = this.props
    if(!surgeon)
    return null
    // console.log(this.state)

    if (this.state.isNext) {
      return <SpecailtySection />
    }
    const { errors} = this.props
    console.log(surgeon,errors,'surgeonsssw')
    return (
      <div>
        <div className="input-holder">
          <label>Upload surgeon photo </label>
          <ErrorText error={getError(errors, 'avatar')} />
          <ImagePicker aspectRatio={3 / 3}
            getImage={(image) => {
              this.props.surgeonUpdate({
                prop: 'avatar',
                value: image
              })
            }}
            height={400}
            width={400}
            previewType="rounded-circle"
          /> 

        </div>
        <div className="input-holder"> 
          <TextInput
            label={'First name '}
            value={surgeon.firstname}
            errorMessage={getError(errors, 'firstname')}
            onValueChange={event => {
              this.props.surgeonUpdate({
                prop: 'firstname',
                value: event.target.value,
              })
            }}
            onKeyPress={event => {
              if (event.key == 'Enter') this.onSubmit()
            }}
          />
        </div>
        <div className="input-holder">
          <TextInput
            label={'Last name'}
            value={surgeon.lastname}
            errorMessage={getError(errors, 'lastname')}
            onValueChange={event => {
              this.props.surgeonUpdate({
                prop: 'lastname',
                value: event.target.value,
              })
            }}
            onKeyPress={event => {
              if (event.key == 'Enter') this.onSubmit()
            }}
            required
          />
        </div>
        <div className="input-holder">
          <TextInput
            label={'Title '}
            value={surgeon.title}
            errorMessage={getError(errors, 'title')}
            onValueChange={event => {
              this.props.surgeonUpdate({
                prop: 'title',
                value: event.target.value,
              })
            }}
            onKeyPress={event => {
              if (event.key == 'Enter') this.onSubmit()
            }}
          />
        </div>
        <div className="input-holder">
          <TextInput
            label={'Mobile number '}
            value={surgeon.contact_number && surgeon.contact_number !='undefined' ? surgeon.contact_number:''}
            errorMessage={getError(errors, 'contact')}
            onValueChange={event => {
              this.props.surgeonUpdate({
                prop: 'contact_number',
                value: event.target.value,
              })
            }}
            onKeyPress={event => {
              if (event.key == 'Enter') this.onSubmit()
            }}
          />
        </div>

        <div className="normal-input-holder">
          <ButtonGroup label="Handedness" required error={getError(errors, 'dominant_hand')}>
            <label className={`btn btn-secondary
              ${
                this.props.surgeon.handedness === 'left' ? 'active' : null
              }`}
              onClick={event => {
                this.props.surgeonUpdate({
                  prop: 'handedness',
                  value: 'left',
                })
              }}>
              <input type="radio" autoComplete="off" /> Left
            </label>
            <label className={`btn btn-secondary
              ${
                this.props.surgeon.handedness === 'right' ? 'active' : null
              }`}
              onClick={event => {
                this.props.surgeonUpdate({
                  prop: 'handedness',
                  value: 'right',
                })
              }}>
              <input type="radio" autoComplete="off" /> Right
            </label>
          </ButtonGroup>
        </div> 

        <div className="normal-input-holder">
          <label className="w-100">Glove <span className="error">*</span> </label>
          { 
            getError(errors, 'inner_gloves') ? 
            <ValidationMessage type="invalid" message={getError(errors, 'inner_gloves')} />:null
          }
          <div>
            {this.renderGlove()}
          </div>
        </div>

        <div className="normal-input-holder">
          <label className="w-100">Outer glove</label> 
          {
            getError(errors, 'outer_gloves') ?
              <ValidationMessage type="invalid" message={getError(errors, 'outer_gloves')} /> : null
          }
          <div>
            {this.renderOuterGlove()}
          </div>
        </div>

        <div className="normal-input-holder">  
          <ButtonGroup label="Music preference" error={getError(errors, 'music')}>
            <label className={`btn btn-secondary
              ${
                this.props.surgeon.music == 1 ? 'active' : null
              }`}
              onClick={() => {
                this.props.surgeonUpdate({
                  prop: 'music',
                  value: 1,
                })
              }}>
              <input type="radio" autoComplete="off" /> On
            </label>
            <label className={`btn btn-secondary 
             ${
                this.props.surgeon.music != 1 ? 'active' : null
              }`}
              onClick={event => {
                this.props.surgeonUpdate({
                  prop: 'music',
                  value: 0,
                })
              }}>
              <input type="radio" autoComplete="off" /> Off
            </label>
          </ButtonGroup> 
        </div>

        <div className="normal-input-holder"> 
          <ButtonGroup label="Room environment">
            <label className={`btn btn-secondary
              ${
                this.props.surgeon.enviroment_on ? 'active' : null
              }`}
              onClick={() => {
                this.props.surgeonUpdate({
                  prop: 'enviroment_on',
                  value: 'normal',
                })
              }}>
              <input type="radio" autoComplete="off" /> Normal
            </label>
            <label className={`btn btn-secondary 
              ${
                !this.props.surgeon.enviroment_on ? 'active' : null
              }`}
              onClick={event => {
                this.props.surgeonUpdate({
                  prop: 'enviroment_on',
                  value: 'silent',
                })
              }}>
              <input type="radio" autoComplete="off" /> Silent
            </label>
          </ButtonGroup> 
        </div>
      </div>
    )
  }

  selectGlove(item) {
    const { hand } = this.state
    this.props.surgeonUpdate({
      prop: hand,
      value: item,
    })
    $('#gloves-modal').modal('hide')
  }

  selectOneClickGlove(item) {
    const { hand } = this.state
    this.props.surgeonUpdate({
      prop: hand,
      value: item,
    })
    this.setState({
      selected: item
    })
  }

  saveOneClickGlove() { 
    const { hand, selected } = this.state
    console.log('selectedselectedselected', selected)
    if(selected){
      this.props.surgeonUpdate({
        prop: hand,
        value: selected,
      })
      this.setState({
        selected: null,
      })
      // alert(1)
      $('#gloves-modal').modal('hide')
    } else {
      // alert(2)
      $('#gloves-modal').modal('hide')
      this.setState({
        glove_error: true
      })
    }
  }

  render() {
    if(!this.props.surgeon)
    return null
    const { gloveData } = this.state

    const search = _.debounce((searchKeyword) => {
      this.getGloves(searchKeyword)
    }, 500)
  

    // const getGloves = _.debounce((term) => {this.getGloves(term)}, 300)

    return (
      <div className="row">
        <div className="col-md-12 d-none d-md-block">
          <h1 className="page-title">
            {this.props.surgeon.id ? 'Edit Surgeon' : 'Add new Surgeon'}
          </h1>
          <h2 className="page-sub-title">Personal Information</h2>
          <p className="page-description">
            Fields required are labelled with <span className="error">*</span>
          </p>
        </div>
        <div className="col-xl-6 col-lg-7 col-md-12">{this.renderContent()}</div>
        <Modal
          idModal="gloves-modal"
          size="modal-md"
          title="Add Glove"
          confirmText="Save glove"
          cancelText="Cancel"
          bodyClass="add-glove-modal-body"
          confirm={this.saveOneClickGlove.bind(this)}
          cancel={(e) => {
            //fn here
          }}
        >

          <div className="row">
            <div className="col-lg-7 col-md-10">
              <div className="line-form-group-v2">
                <input type="text" className="line-form-v2" ref={el => (this.searchKeyword = el)} onChange={search} />
                <label>Search supplies database <i className="mdi mdi-magnify"></i></label>
              </div>
            </div>
          </div>
          <ViewItemCard
            id={`preview-modal-add-glove`}
            onRef={ref => (this.viewItemRef = ref)}
            item={this.state.selectedItem}
          ></ViewItemCard>
          <Table minWidth={550} className="modal-table"
 
            noResults={gloveData.length > 0 ? 0:1}
            renderHeaders={() => {
              return (
                <TRow>
                  <THead className="w40" text="" />
                  <THead className="w150" text="Catalog #" sortable />
                  <THead className="w200" text="Name" sortable />
                  <THead className="w100" text="Size" sortable />
                </TRow>
              )
            }}
            renderRows={() => { 
              let rows = gloveData.map(item => {
                let is_selected = ''
                let checked = ''
                if (this.state.selected) {
                  if (this.state.selected.id == item.id) {
                    is_selected = '_selected'
                    checked = 'checked'
                  }
                } else {
                  if (this.state.hand) {
                    console.log(item.id)
                    if (this.props.surgeon[this.state.hand] != null) {
                      if (this.props.surgeon[this.state.hand].id == item.id) {
                        is_selected = '_selected'
                        checked = 'checked'
                        console.log(is_selected, this.props.surgeon[this.state.hand].id)
                      }
                    }
                  }
                }
                return (
                  <TRow hasChoices key={`glove-row-${item.id}`} onClick={this.selectOneClickGlove.bind(this, item)}
                    onDoubleClick={this.selectGlove.bind(this, item)}>
                    <TData className="w40">
                      <div className="custom-control custom-radio">
                        <input type="radio" className="custom-control-input" name={`glove-radio`} checked={checked}  id={`glove-radio-${item.id}`} onChange={()=>{}} />
                        <label className="custom-control-label" htmlFor={`glove-radio-${item.id}`}></label>
                      </div>
                    </TData>
                    <TData className="w150">{item.catalog_number}</TData>
                    <TData className="w200 has-action" item={item} onClick={()=>{
                        this.setState({
                          selectedItem: item
                        }, ()=>{
                            $("#preview-modal-add-glove").modal({ keyboard: false})
                        })
                    }}>{item.name}</TData>
                    <TData className="w100">{item.size}</TData>
                  </TRow>
                )
              })
              return rows
            }}

          />
        </Modal>
      </div>
    )
  }
}

export default SurgeonForm
