import React, { Component } from 'react'

export default class Notifier extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            status: 'positive', 
            //AVAILABLE OPTIONS positive, error, warning, info 
      }
    }
    

    render() {
        const { status, message, show, cols} = this.props
        if(!show){
            return null;
        }
        if (status == 'positive') {
            return (
                <tr>
                    <td className="td-notifier" colSpan={cols}>
                        <span className="error-state-icon positive"><i className="mdi mdi-check-circle"></i>{ message }</span>
                    </td>
                </tr>
            )
        }
        if (status == 'error') {
            return (
                <tr>
                    <td className="td-notifier" colSpan={cols}>
                        <span className="error-state-icon error"><i className="mdi mdi-information"></i>{message}</span>
                    </td>
                </tr>
            )
        }
        if (status == 'warning') {
            return (
                <tr>
                    <td className="td-notifier" colSpan={cols}>
                        <span className="error-state-icon warning"><i className="mdi mdi-alert"></i>{message}</span>
                    </td>
                </tr>
            )
        }
        if (status == 'info') {
            return (
                <tr>
                    <td className="td-notifier" colSpan={cols}>
                        <span className="error-state-icon info"><i className="mdi mdi-information"></i>{message}</span>
                    </td>
                </tr>
            )
        }
        if (status == 'info-outline') {
            return (
                <tr>
                    <td className="td-notifier" colSpan="2">
                        <span className="error-state-icon info"><i className="mdi mdi-information-outline"></i>{message}</span>
                    </td>
                </tr>
            )
        }
    }
}
