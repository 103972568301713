import React, { Component } from 'react'
import Footer from '../../components/footer'
import '../../assets/scss/App.scss'
import '../../assets/css/app.css'
import TextInput from '../../components/commons/forms/TextInput'
import axios from 'axios'
import { getError } from '../../utilities'
import { Link } from 'react-router'
import { BASE_URL } from '../../config/constant'
import { Button } from '../../components/commons/forms'
import logo from '../../assets/images/Logo-Mono-Reverse.png'
import { Modal } from '../../components/commons/modals'
import $ from 'jquery'
import { connect } from 'react-redux'
import { loginUpdate, loginUser } from '../../actions'


import logo2 from '../../assets/images/logo-gradient.png'


import cellphone from '../../assets/images/welcome/Cellphone.png'
import desktop from '../../assets/images/welcome/Desktop.png'
import tablet from '../../assets/images/welcome/Tablet.png'

import web from '../../assets/images/welcome/web.png'
import apple from '../../assets/images/welcome/apple.png'
import play from '../../assets/images/welcome/play.png'


class Register extends Component {
  constructor(props) {
    super(props)

    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      loading: false,
      errors: [],
      share_id: '',
      share_token: '',
      beta_key: '',
    }
  }
  componentDidMount() {
    // $('#thankYouModal').modal({
    //   backdrop: 'static',
    //   keyboard: false,
    //   show: true,
    // })
    const { email, beta_key } = this.props.location.query
    this.setState({
      email: email ? email : '',
      beta_key: beta_key ? beta_key : '',
    })
    $(document).on('blur', '.line-form-group input', e => {
      if (!$(e.currentTarget).val()) {
        $(e.currentTarget)
          .closest('.line-form-group')
          .removeClass('active')
      } else {
        $(e.currentTarget)
          .closest('.line-form-group')
          .addClass('active')
      }
    })

    $(document).on('focusout', '.line-form-group-v2 input', e => {
      if (!$(e.currentTarget).val()) {
        $(e.currentTarget).removeClass('has-val')
      } else {
        $(e.currentTarget).addClass('has-val')
      }
    })
    $(document).on('keyup', '.line-form-group-v2 input', e => {
      if (!$(e.currentTarget).val()) {
        $(e.currentTarget).removeClass('has-val')
      } else {
        $(e.currentTarget).addClass('has-val')
      }
    })

    $(document).on('focusout', '.line-form-group-v2 select', e => {
      if (!$(e.currentTarget).val()) {
        $(e.currentTarget).removeClass('has-val')
      } else {
        $(e.currentTarget).addClass('has-val')
      }
    })

    $('textarea').autoResize()
    $('textarea').change()
    $('input').keyup()
  }
  componentWillMount() {
    axios.defaults.baseURL = BASE_URL
  }

  onSubmit() {
    this.setState({
      loading: true,
    })
    // $("#thankYouModal").modal({
    //   backdrop: 'static',
    //   keyboard: false,
    //   show: true,
    // })
    const {
      firstname,
      lastname,
      email,
      password,
      share_id,
      share_token,
      beta_key,
    } = this.state

    const data = {
      firstname,
      lastname,
      email,
      password,
      password_confirmation: password,
      beta_key,
    }
    if (share_id && share_token) {
      data.share_id = share_id
      data.share_token = share_token
    } 
    axios
      .post(`api/register`, data)
      .then(result => {
        console.log(result)
        this.setState({
          loading: false,
        })

        // this.props.router.push({
        //   pathname: '/login',
        //   state: { email: email },
        // })
        if(result.status == 200){
          //beta users thank you

          const token = result.data.access_token
          this.props.loginUpdate({
            prop: 'token',
            value: result.data.access_token,
          })
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
          this.props.router.replace('/')
          
        }else{
          //normal user verification
        }

      

        $('#thankYouModal').modal({
          backdrop: 'static',
          keyboard: false,
          show: true,
        })
      })
      .catch(errors => {
        console.log(errors.response)
        this.setState({
          errors: errors.response.data.errors,
          loading: false,
        })
      })
  }

  
  emailLink(email) {
    let spliter = email.split('@')
    if (spliter[1]) return `https://${spliter[1]}`
    return '' 
  }

  render() {
    return (
      <main role="main" className="flex-shrink-0 d-flex light-theme login-page">
      <Modal
        idModal="thankYouModal"
        noCloseBtn
        size="modal-full"
        className="thank-you-modal"
        noFooter
      >
          <section className="welcome-page">
            <img src={logo2} className="logo" />
            <div className="row">
              <div className="col-md-12 text-center">
                <h2 className="title2">Awesome!</h2>
                <p className="subtitle">You have successfully signed up to CORH</p>
                <h1 className="title1">Where to now?</h1>
              </div>
              <div className="col-lg-3 col-md-4 three-cards">
                <div className="card">
                  <div className="card-body">
                    <p>If you want to go ahead and use our desktop version</p>
                    <img src={desktop} className="big-icon" />
                    <a href="#" className="small-icon"><img src={web} /></a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 three-cards d-none">
                <div className="card">
                  <div className="card-body">
                    <p>If you want to go ahead and use our mobile version</p>
                    <img src={tablet} className="big-icon" />
                    <a href="#" className="small-icon"><img src={apple} /></a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 three-cards">
                <div className="card">
                  <div className="card-body">
                    <p>If you want to go ahead and use our mobile version</p>
                    <img src={cellphone} className="big-icon" />
                    <a href="#" className="small-icon"><img src={play} /></a>
                  </div>
                </div>
              </div>
            </div>
          </section>
      </Modal>
        <div className="container align-self-center">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-6 col-md-7 ">
              <div className="card shadow mb30">
                <div className="card-body p30 ">
                  <div className="font-weight-bold d-flex justify-content-center mb30">
                    <img src={logo} className="logo" />
                  </div>

                  <div className="row">
                    <div className="col-md-12 mb20">
                      <p className="welcome sub-title">Welcome</p>
                      <small className="small-label">Please sign up</small>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="input-holder">
                        <TextInput
                          label={'First name'}
                          value={this.state.firstname}
                          errorMessage={getError(this.state.errors, 'firstname')}
                          onValueChange={event => {
                            this.setState({
                              firstname: event.target.value,
                            })
                          }}
                          onKeyPress={event => {
                            if (event.key == 'Enter') this.onSubmit()
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="input-holder">
                        <TextInput
                          label={'Last name'}
                          value={this.state.lastname}
                          errorMessage={getError(this.state.errors, 'lastname')}
                          onValueChange={event => {
                            this.setState({
                              lastname: event.target.value,
                            })
                          }}
                          onKeyPress={event => {
                            if (event.key == 'Enter') this.onSubmit()
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="input-holder">
                        <TextInput
                          label={'Email Address'}
                          value={this.state.email}
                          inputType={'email'}
                          errorMessage={getError(this.state.errors, 'email')}
                          containerClass={`mb30`}
                          onValueChange={event => {
                            this.setState({
                              email: event.target.value,
                            })
                          }}
                          onKeyPress={event => {
                            if (event.key == 'Enter') this.onSubmit()
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="input-holder">
                        <TextInput
                          label={'Beta Key'}
                          value={this.state.beta_key}
                          errorMessage={getError(this.state.errors, 'beta_key')}
                          onValueChange={event => {
                            this.setState({
                              beta_key: event.target.value,
                            })
                          }}
                          onKeyPress={event => {
                            if (event.key == 'Enter') this.onSubmit()
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="input-holder">
                        <TextInput
                          label={'Password'}
                          value={this.state.password}
                          inputType={'password'}
                          errorMessage={getError(this.state.errors, 'password')}
                          containerClass={`mb30`}
                          onValueChange={event => {
                            this.setState({
                              password: event.target.value,
                            })
                          }}
                          onKeyPress={event => {
                            if (event.key == 'Enter') this.onSubmit()
                          }}
                        />
                      </div>
                    </div>
                  </div>


                  <div className="mb30 pb30">
                    <Button className="submit-btn" primary onClick={this.onSubmit.bind(this)}>
                      Sign me up
                    </Button>
                  </div>

                  <div className="mb0 d-flex justify-content-center desc">
                    
                    Already have an account?
                  <Button
                        tertiary
                        className="ml8"
                        link
                        url={{ pathname: '/login' }}
                      >
                        Login
                  </Button>
                  </div>

                </div>
              </div>

              <div className="w-100 mb32 d-flex justify-content-center align-items-center">
                <p className="inline-paragraph text-white text-center"> 
                    By signing up you are accepting our  
                     <a href="https://www.corhsystem.com/terms" target="_blank" className="text-success"> Terms of Service</a> &
                      <a href="https://www.corhsystem.com/privacy-policy" target="_blank" className="text-success"> Privacy Policy</a>. 
                </p>
              </div>

            </div>
          </div>
        </div>
      </main>

       )

    return (
      <div className="no-nav">
        <main role="main" className="flex-shrink-0 d-flex py-0">
          <div className="container-fluid">
            <div className="row row-orig h-100">
              <div className="col-md-6 py-5 bg-secondary">
                <div className="row">
                  <div className="col-md-7 offset-2">
                    <a
                      className="navbar-brand p-0"
                      href="#"
                      style={{ marginRight: '30px' }}
                    >
                      <div className="btn btn-light rounded-0">CORH</div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 py-5 bg-white d-flex">
                <div className="rowrow align-self-center w-100">
                  <div className="col-md-7 offset-2">
                    <h1 className="font-weight-bold text-center mb30">
                      Welcome
                    </h1>
                    <div className="row">
                      <div className="col-md-6">
                        <TextInput
                          label={'First name'}
                          value={this.state.firstname}
                          errorMessage={getError(
                            this.state.errors,
                            'firstname'
                          )}
                          containerClass={`mb30`}
                          onValueChange={event => {
                            this.setState({
                              firstname: event.target.value,
                            })
                          }}
                          onKeyPress={event => {
                            if (event.key == 'Enter') this.onSubmit()
                          }}
                        />
                      </div>
                      <div className="col-md-6">
                        <TextInput
                          label={'Last name'}
                          value={this.state.lastname}
                          errorMessage={getError(this.state.errors, 'lastname')}
                          containerClass={`mb30`}
                          onValueChange={event => {
                            this.setState({
                              lastname: event.target.value,
                            })
                          }}
                          onKeyPress={event => {
                            if (event.key == 'Enter') this.onSubmit()
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <TextInput
                          label={'Email Address'}
                          value={this.state.email}
                          inputType={'email'}
                          errorMessage={getError(this.state.errors, 'email')}
                          containerClass={`mb30`}
                          onValueChange={event => {
                            this.setState({
                              email: event.target.value,
                            })
                          }}
                          onKeyPress={event => {
                            if (event.key == 'Enter') this.onSubmit()
                          }}
                        />
                      </div>
                      <div className="col-md-12">
                        <TextInput
                          label={'Password'}
                          value={this.state.password}
                          inputType={'password'}
                          errorMessage={getError(this.state.errors, 'password')}
                          containerClass={`mb30`}
                          onValueChange={event => {
                            this.setState({
                              password: event.target.value,
                            })
                          }}
                          onKeyPress={event => {
                            if (event.key == 'Enter') this.onSubmit()
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <div
                          className="btn btn-primary mb30"
                          onClick={this.onSubmit.bind(this)}
                        >
                          Sign up
                        </div>
                        <p className="fs14 m-0">
                          By Signing up you acknowledge that you have read and
                          agree to our{' '}
                          <a href="#">
                            <u>Terms of service</u>
                          </a>{' '}
                          and our{' '}
                          <a href="#">
                            <u>Privacy policy</u>
                          </a>
                          .{' '}
                        </p>
                        <hr className="my30" />
                        <p className="fs14 m-0">
                          Already a member?{' '}
                          <Link to="/login">
                            <u>Log in</u>
                          </Link>
                          .{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <Footer />
      </div>
    )
  }
}


const mapStateToProps = ({ auth }) => {
  const { email, password, loading, error } = auth

  return { email, password, loading, error }
}

export default connect(
  mapStateToProps,
  { loginUpdate, loginUser }
)(Register)
