import React, { Component } from 'react'
import Swiper from 'react-id-swiper'
import error_img from '../../../../assets/images/error_img.png'
import Vimeo from '@u-wave/react-vimeo'
import MediaOnly from './MediaOnly';
import WholeBodyloader from '../../../loader/WholeBodyloader';
import BigPictureModal from '../../../modals/BigPictureModal';
import $ from 'jquery';
export default class MediaPreview extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            selected_media: [],
            loading:1,
            galleryRef: null,
            thumbRef: null,
            unique:
                Math.random()
                    .toString(36)
                    .substring(2, 15) +
                Math.random()
                    .toString(36)
                    .substring(2, 15),
        }
        this.setSelectedMedia = this.setSelectedMedia.bind(this)
        
    }

    setSelectedMedia(media) {
        this.setState({
            selected_media: media
        }, () => {
            $("#bigPictureMediaPreview").modal('show')
            setTimeout(() => {
                $('.modal-backdrop').last().addClass('opacity-9 z-index-2k')
            }, 200);
        })

    } 

    componentDidMount() {
        setTimeout(()=>{
            this.setState({
                loading:0
            })
        },700)
    }
 
    
    render() {
        const { idModal, title, data } = this.props
        console.log('dattaaaaa', data)
        return (
            <div>
                <BigPictureModal idModal="bigPictureMediaPreview" data={this.state.selected_media} />
                <WholeBodyloader progress={this.state.loading} />

                <div className="modal fade" id={idModal ? idModal : 'viewMediaModalCenter'} tabIndex={-1} role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
                        <div className="modal-content border-0 bg-white pb0">
                            <div className="modal-header border-0 bg-white">
                                <h5 className="modal-title mb0 h3bold pb0">{title}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body border-0 bg-white rounded pb30">
                                {/*  {data.media.length > 0 ? this.mediapreview(data) : null} */}

                                {data.media.length > 0 ? <MediaOnly data={data.media} styleClass="shadow-none" key={this.state.unique} setSelectedMedia={this.setSelectedMedia} no_bigpiture={true} /> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
