import React, { Component } from 'react'
import SurgeonForm from '../../components/surgeon_form'
import SurgeonPreview from '../../components/surgeon_preview'
import { connect } from 'react-redux'
import Confirmation from '../../components/Confirmation'
import Notification from '../../components/Notification'
import WholeBodyloader from '../../components/loader/WholeBodyloader'
import {
  createSurgeon,
  clearSurgeonData,
  setIsNext,
  surgeonUpdate,
  getSurgeon,
} from '../../actions/SurgeonActions'
import SpecialtySection from '../../components/specialty_section'

import { Button } from '../../components/commons/forms'
import {
  ConfirmationModal,
  NotificatonModal,
} from '../../components/commons/modals'
import $ from 'jquery'
import axios from 'axios'
import { dataURItoBlob } from '../../utilities'

class SurgeonEdit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isNext: false,
      progress: 1,
      page: 1,
      surgeon: null,
      errors: [],
    }
  }

  componentDidMount = () => {
    let url = `/api/client/surgeon/${this.props.params.id}`

    axios
      .get(url)
      .then(result => {
        console.log('surgeonsssd', result.data.data)

        this.setState(
          {
            surgeon: result.data.data,
            progress: 0,
          },
          () => {
            console.log('surgeonsssw', this.state.surgeon)
          }
        )
      })
      .catch(error => {
        console.log('surgeonsss', error)
        console.log('surgeonsss', error.response)
      })

    window.onpopstate = event => {
      event.preventDefault()
      console.log('============================================')
      console.log(event)
      console.log('============================================')
      if (this.state.isNext) {
        this.back()
      } else {
        $('.cancel-button').click()
      }
    }
  }

  /* componentWillReceiveProps = nextProps => {
    if (this.props.surgeon) {
      const {
        avatar,
        contact,
        contact_number,
        enviroment_on,
        firstname,
        handedness,
        id,
        inner_glove,
        lastname,
        music,
        outer_glove,
        title,
      } = nextProps.surgeon

      this.props.surgeonUpdate({
        prop: 'environment',
        value: enviroment_on ? 'normal' : 'silent',
      })

      this.props.surgeonUpdate({
        prop: 'firstname',
        value: firstname,
      })
      this.props.surgeonUpdate({
        prop: 'lastname',
        value: lastname,
      })
      this.props.surgeonUpdate({
        prop: 'avatar',
        value: avatar,
      })
      this.props.surgeonUpdate({
        prop: 'mobile',
        value: contact_number,
      })
      this.props.surgeonUpdate({
        prop: 'glove_inner',
        value: inner_glove,
      })
      this.props.surgeonUpdate({
        prop: 'glove_outer',
        value: outer_glove,
      })
      this.props.surgeonUpdate({
        prop: 'mobile',
        value: contact_number,
      })
      this.props.surgeonUpdate({
        prop: 'title',
        value: title,
      })
      this.props.surgeonUpdate({
        prop: 'is_edit',
        value: true,
      })
      this.props.surgeonUpdate({
        prop: 'handness',
        value: handedness,
      })
      this.props.surgeonUpdate({
        prop: 'music',
        value: music,
      })

      this.setState({
        progress: 0,
      })
    }
  } */
  updateSurgeon = () => {
    const { surgeon } = this.state
    let {
      firstname,
      lastname,
      title,
      music,
      contact_number,
      handedness,
      inner_glove,
      outer_glove,
      environment,
      enviroment_on,
      avatar,
    } = surgeon
    let data = new FormData()
    data.append('firstname', firstname)
    data.append('lastname', lastname)
    if(contact_number)
    data.append('contact', contact_number)
    data.append('dominant_hand', handedness)
    data.append('music', music)
    data.append('title', title)
    data.append('_method', 'PATCH')

    if (avatar) {
      if (avatar.indexOf('base64') >= 0) {
        data.append('avatar', dataURItoBlob(avatar))
      }
    }

    /* console.log(glove_outer.id) */

    if (inner_glove.id != undefined) {
      inner_glove = inner_glove.id
    } else {
      inner_glove = '' //changed from = 0 to = ''
    }
    data.append('inner_gloves', inner_glove)

    if (outer_glove) {
      if (outer_glove.id != null) {
        outer_glove = outer_glove.id
      } else {
        outer_glove = '' //changed from = 0 to = ''
      }
    } else {
      outer_glove = '' //changed from = 0 to = ''
    }
    data.append('outer_gloves', outer_glove)
    if (enviroment_on == 'normal' || enviroment_on == 1) {
      data.append('enviroment_on', 1)
    }else{
      data.append('enviroment_on', 0)
    }

    let url = `/api/client/surgeon/${surgeon.id}`

    let config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }

    axios
      .post(url, data, config)
      .then(result => {
        console.log('result', result)
        this.setState({
          isNext: true,
          errors:[],
          surgeon: result.data.data
        })
      })
      .catch(error => {
        console.log('Error')
        console.log(error)
        console.log(error.response)
        if (error.response && error.response.status == 422)
          this.setState({
            errors: error.response ? error.response.data.errors : [],
          })
      })
  }
  surgeonSwitch(surgeon) {
    this.setState({
      surgeon,
    })
  }
  renderRightSide() {
    const { surgeon, isNext, errors } = this.state
    if (isNext)
      return (
        <SpecialtySection
          surgeon={surgeon}
          surgeonUpdate={surgeon => {
            this.surgeonSwitch(surgeon)
          }}
        />
      )
    return (
      <SurgeonForm
        isEditMode={true}
        surgeon={surgeon}
        errors={errors}
        surgeonUpdate={payload => {
          this.surgeonUpdate(payload)
        }}
      />
    )
  }
  surgeonUpdate(payload) {
    const { surgeon } = this.state
    surgeon[payload.prop] = payload.value
    this.setState({
      surgeon,
    })
  }
  back = () => {
    this.setState({
      isNext: false,
    })
  }

  render() {
    if (!this.state.surgeon) return null
    return (
      <div className="container">
        {/* <WholeBodyloader progress={this.state.progress} /> */}
        <div className="row">
          <div className="col-md-12 d-block d-md-none">
            <h1 className="page-title">Edit Surgeon</h1>
            <h4 className="h2med page-sub-title">Personal Information</h4>
          </div>
          <div className="col-lg-3 col-md-4 mb30">
            <div className="col-md-12 d-none d-md-block">
              <h1 className="page-title">&nbsp;</h1>
            </div>
            <SurgeonPreview surgeon={this.state.surgeon} />
          </div>
          <div className="col">
            {this.renderRightSide()}
            <div className="row mb16">
              <div className="col-xl-6 col-lg-7 col-md-12 d-flex">
                {this.state.isNext ? (
                  <Button
                    className="w270 px8"
                    primary
                    datatoggle="modal"
                    datatarget="#notificationModal"
                  >
                    Complete surgeon profile
                  </Button>
                ) : (
                  <Button
                    className="w300 px8"
                    primary
                    onClick={() => {
                      this.updateSurgeon()
                      window.history.pushState(
                        { page: 1 },
                        '',
                        '#surgeon_specialties'
                      )
                    }}
                  >
                   Save and Continue
                  </Button>
                )}

                {this.state.isNext ? (
                  <Button
                    className="w150"
                    secondary
                    onClick={() => {
                      this.back()
                    }}
                  >
                    Back
                  </Button>
                ) : (
                  <Button
                    className="w150 px8"
                    secondary
                    datatoggle="modal"
                    datatarget="#cancelModal"
                  >
                    Cancel
                  </Button>
                )}
              </div>
            </div>
          </div>

          <ConfirmationModal
            idModal="cancelModal"
            title="Cancel"
            message="Are you sure you want to cancel?"
            confirmText="Yes"
            cancelText="No"
            confirm={() => {
              this.props.router.push('/surgeon')
            }}
            cancel={() => {
              $('confirmModal').modal('hide')
            }}
          />
          <NotificatonModal
            title="Success"
            message="Surgeon successfully save."
            confirmText="Ok"
            confirm={() => { 
              this.props.router.push({
                pathname: `/quicknotes/${this.state.surgeon.id}`,
                state: { surgeon: this.state.surgeon }
              }) 
            }}
          />

          <WholeBodyloader progress={this.state.loading} type="spinner" />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps,
  {}
)(SurgeonEdit)
