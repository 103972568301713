import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'react-router'

class Contribution extends Component {
  constructor(props) {
    super(props)

    this.state = {
      contributions: {
        media: 0,
        items: 0,
        surgeons: 0,
        procedures: 0,
      },
    }
  }

  componentDidMount = () => {
    axios
      .get(`api/my-contributions`)
      .then(result => {
        this.setState({
          contributions: result.data,
        })
      })
      .catch(error => {
        console.log(error)
      })
  }
  render() {
    const { contributions } = this.state
    let title = 'My contributions',
      updates = contributions.media,
      items = contributions.items,
      procedures = contributions.procedures,
      surgeons = contributions.surgeons

    return (
      <div className="card my-records-card">
        <h3 className="bold my-records-title">{title}</h3>
        <div className="row">
          <div className="col-md-6 my-records-tile">
            <h1 className="my-records-value">{updates}</h1>
            <p className="my-records-desc">Updates made</p>
          </div>
          <div className="col-md-6 my-records-tile">
            <Link
              to={{ pathname: '/my-records', state: { tab: 'items' } }}
              style={{ color: 'inherit', textDecoration: 'none' }}
            >
              <h1 className="my-records-value">{items}</h1>
            </Link>
            <p className="my-records-desc">Items registered</p>
          </div>
          <div className="col-md-6 my-records-tile">
            <Link
              to={{ pathname: '/my-records', state: { tab: 'surgeons' } }}
              style={{ color: 'inherit', textDecoration: 'none' }}
            >
              <h1 className="my-records-value">{surgeons}</h1>
            </Link>
            <p className="my-records-desc">Surgeons added</p>
          </div>
          <div className="col-md-6 my-records-tile">
            <Link
              to={{ pathname: '/my-records', state: { tab: 'procedures' } }}
              style={{ color: 'inherit', textDecoration: 'none' }}
            >
              <h1 className="my-records-value">{procedures}</h1>
            </Link>
            <p className="my-records-desc">Procedures added</p>
          </div>
        </div>
      </div>
    )
    return (
      <div className="card shadow-sm border-0 mb20">
        <div className="card-body">
          <h5 className="mb20 font-weight-bold">My contributions</h5>
          <div className="row">
            <div className="col-md-6 text-center mb20">
              <Link
                to={{ pathname: '/my-records', state: { tab: 'surgeons' } }}
              >
                <h1 className="mb20">{contributions.surgeons}</h1>
              </Link>
              <p className="mb20">Surgeons</p>
            </div>
            <div className="col-md-6 text-center mb20">
              <Link
                to={{ pathname: '/my-records', state: { tab: 'galleries' } }}
              >
                <h1 className="mb20">{contributions.media}</h1>
              </Link>
              <p className="mb20">Media</p>
            </div>
            <div className="col-md-6 text-center mb20">
              <Link to={{ pathname: '/my-records', state: { tab: 'items' } }}>
                <h1 className="mb20">{contributions.items}</h1>
              </Link>
              <p className="m-0">Items</p>
            </div>
            <div className="col-md-6 text-center mb20">
              <Link
                to={{ pathname: '/my-records', state: { tab: 'procedures' } }}
              >
                <h1 className="mb20">{contributions.procedures}</h1>
              </Link>
              <p className="m-0">Procedures</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Contribution
