import React, { Component } from 'react';
import $ from 'jquery'
import { Link } from 'react-router'
import ReactDOMServer from 'react-dom/server'
import ViewItemModal from '../../components/modals/ViewItemModal'

class CardSection extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: 0,
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      selectedItem:null
    }
  } 
  componentDidMount() {
    const { data } = this.props 
    // window.initPersistHeader(`#${data.code}-1`)
    $('[data-toggle="popover"]').popover()
    $('.popover').popover('hide')
  }
  componentDidUpdate() { 
    $('[data-toggle="popover"]').popover()
    $('.popover').popover('hide')
  }
    render() {
      const { data } = this.props
      console.log('data', data)
      const item_list = data.tableData.map((i, index) => {
        let item = i.item.item
        console.log(item)
        let tags = item.tags.map((tag) => {
          return ReactDOMServer.renderToStaticMarkup(
            <span class="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">{tag.name}</span>
          )
        })
        
        return(
          <tr>
            <td className="w120">{item.catalog_number}</td>
            <th className="w250 font-weight-bold c-pointer text-dark fs-16"  
              data-container="body" data-trigger="hover" data-toggle="popover" data-placement="right" data-html="true" data-content={`
                              <div class="row">
                              <div class="col-md-4 text-center pr-0">
                              <img src=${item.icon} class="bg-dark w-100">
                              </div>
                              <div class="col">
                              <h6 class="mb15">${item.name}</h6>
                              <p>${item.used_for}</p>
                              </div>
                              <div class="col-md-12 mt15">
                              <p class=" mb10">Tags</p>
                              ${tags}
                              </div>
                              </div>`} onClick={() => {

                this.setState({
                  selectedItem: item,
                  unique:
                    Math.random()
                      .toString(36)
                      .substring(2, 15) +
                    Math.random()
                      .toString(36)
                      .substring(2, 15)
                }, () => {
                    $(`#${this.state.unique}`).modal('show');

                })
              }}> 
                {item.name} 
            </th>
            <td className="w70 text-center"> <p className="m-0 p-0 float-left w-100 fs14">{i.item.qty ? i.item.qty : 1} </p> </td>
            <td className="w250"> <div dangerouslySetInnerHTML={{__html: i.item.notes ? i.item.notes : '-'}}></div></td>
            <td className="w80 text-center">
              {i.item.media.length > 0 ? 
                <a href="javascript:;" onClick={(event) => {
                  console.log('itemsssss', i.item.media.length)
                  this.props.onViewMedia(event, i.item)
                }}>View</a>
                : '-'
                
              }
            </td>

          </tr>
        )
      })
      
      return (
        <div className="card p-0 shadow-sm border-0 mt30" id={data.code}>
          <div
            className="card-header bg-white p15 border-0 persist-area"
            id={`${data.code}-1`}
          >
            <h5 className="mb-10 d-flex align-self-start align-items-center fs20 font-weight-bold persist-header bg-white">
              {data.name}
            </h5>
            <div className="float-left w-100 of-auto">
              <table className="table table-striped min-table-width-775">
                <thead>
                  <tr>
                    <th className="px10 w120">Catalogue #</th>
                    <th className="px10 w250">Name</th>
                    <th className="px10 w70 text-center">Qty</th>
                    <th className="px10 w250">Notes</th>
                    <th className="px10 w80 text-center">Media</th>
                  </tr>
                </thead>
                <tbody>
                  {item_list}
                </tbody>
              </table>
            </div>
          </div>
 
          <ViewItemModal
            idModal={this.state.unique}
            key={this.state.unique}
            item={this.state.selectedItem}
            cancel={() => {
              $('viewItemModal').modal('hide')
            }}
          />               

        </div>
      );
    }
}

export default CardSection;