import React, { Component } from 'react'
import cardView from '../../assets/images/icons/card-view.svg'
import Icon from '../commons/icons/Icon';
import Logo from '../commons/icons/Logo';


export class Icons extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          <h1>Item table</h1>

          <ul className="list-unstyled">
            <li className="py20">
              <Icon icon="add-sizes" />
            </li>
            <li className="py20">
              <Icon icon="arrow" />
            </li>
            <li className="py20">
              <Icon icon="card-view" />
            </li>
            <li className="py20">
              <Icon icon="close-modal" />
            </li>
            <li className="py20">
              <Icon icon="copy" />
            </li>
            <li className="py20">
              <Icon icon="list" />
            </li>
            <li className="py20">
              <Icon icon="media-gallery" />
            </li>
            <li className="py20">
              <Icon icon="medication" />
            </li>
            <li className="py20">
              <Icon icon="move-down" />
            </li>
            <li className="py20">
              <Icon icon="move-up" />
            </li>
            <li className="py20">
              <Icon icon="profile" />
            </li>
            <li className="py20">
              <Icon icon="search" />
            </li>
            <li className="py20">
              <Icon icon="text-and-media" />
            </li>
            <li className="py20">
              <Icon icon="text-only" />
            </li>
            <li className="py20">
              <Icon icon="trash" />
            </li>
            <li className="py20">
              <Icon icon="view" className="w500 h500" />
            </li>
            <li className="py20">
              <Icon icon="view-copy" />
            </li>
            <li className="py20">
              <Logo icon="logo-single" className="p5" />
            </li>
            <li className="py20">
              <Logo icon="logo-single-reverse" className="bg-dark p5" />
            </li>
            <li className="py20">
              <Logo icon="logo-gradient" className="p5" />
            </li>
            <li className="py20">
              <Logo icon="logo-gradient-reverse" className="bg-dark p5" />
            </li>
            <li className="py20">
              <Logo icon="logo-mono" className="p5" />
            </li>
            <li className="py20">
              <Logo icon="logo-mono-reverse" className="bg-dark p5" />
            </li>
            <li className="py20">
              <Logo icon="logo-standard" className="p5" />
            </li>
            <li className="py20">
              <Logo icon="logo-standard-reverse" className="bg-dark p5" />
            </li>
            <li className="py20 d-flex">
              <i className="mdi mdi-information mdi-flip-v mdi-48px error mr30"></i>
              <i className="mdi mdi-information mdi-48px information mr30"></i>
              <i className="mdi mdi-check-circle mdi-48px success mr30"></i>
              <i className="mdi mdi-alert mdi-48px warning mr30"></i>
            </li>
            
          </ul>
          
            
          
        </div>
      </div>
    )
  }
}

export default Icons
