import React, {Component} from 'react';
import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe
} from 'react-stripe-elements'
import axios from 'axios'
import $ from 'jquery'
import Button from './Button';
import { getUser } from '../../../actions';
import { connect } from 'react-redux'


class ChangeCardForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: '',
      duration: '',
      durations: [
        {id: 1, value: 'monthly', name: 'Monthly', amount: '9.99', label: 'mo'},
        {id: 2, value: 'annually', name: 'Annually', amount: '99.99', label: 'yr'},
      ],
      errors: []
    }

    this.submit = this.submit.bind(this);
  }

  async submit(ev) {

    let { token } = await this.props.stripe.createToken({name: this.state.name, })
    
    if(token){
      let url = '/api/client/card-update'

      const data = {
        _method: 'PATCH',
        stripeToken:token.id
      }
      axios.post(url, data).then((res) => {
        this.props.getUser()
        this.props.onSuccess()
        $('#changeCardModal').modal('hide')
      }).catch((err) => {
        console.log('stripe', err)
        console.log('stripe', err.response)
      })
    }else{
      alert('Payment error!')
    }
    
  }

  render() {
    
    return (
      <div className="checkout">
       
        <div className='mb32'> 
          <div className="line-form-group-v2">
            <input type="text" className="line-form-v2" value={this.state.name} onChange={(event) => { this.setState({ name: event.target.value }) }} />
            <label>Name on card</label> 
          </div>
        </div>
        <div className="row">
          <label className="col-md-12 mb32 floaty-label">
            <span>Card number</span>
            <span class="brand"><i class="fa fa-credit-card" id="brand-icon"></i></span>
            <CardNumberElement onChange={(event)=>{
              $('#brand-icon').attr('class','')
              console.log(event.brand)
              if (!event.empty){
                $('#brand-icon').addClass(`fa fa-cc-${event.brand}`)
              } else {
                $('#brand-icon').attr('class', 'fa fa-credit-card')
              }
            }} />
          </label> 
          <label className="col-md-6 mb32 floaty-label">
            <span>Expiry date (MM / YY)</span>
            <CardExpiryElement />
          </label> 
          <label className="col-md-6 mb32 floaty-label">
            <span>CVC / CVV</span>
            <CardCVCElement />
          </label>
        </div>
        <div className="row">
          <div className="col-md-12 text-center"> 
            <Button primary onClick={() => { this.submit() }}>Update Card</Button>
          </div>
        </div>

      </div>
    )
  }
}

const mapStateToProps = state => {

  return {  }
}

export default  connect(mapStateToProps,
  { getUser })(injectStripe(ChangeCardForm))