import axios from 'axios'
import $ from 'jquery'
import { Cropper } from 'rc-cropping'
import Dialog from 'rc-dialog'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import img from '../../assets/images/img.png'
import ImagePicker from '../../components/commons/elements/inputs/ImagePicker'
import Confirmation from '../../components/Confirmation'
import WholeBodyloader from '../../components/loader/WholeBodyloader'
import MediaInput from '../../components/MediaInput'

const dataURItoBlob = dataURI => {
  if (dataURI == '') return ''
  var byteString
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1])
  else byteString = unescape(dataURI.split(',')[1])
  var mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0]
  var ia = new Uint8Array(byteString.length)
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ia], { type: mimeString })
}

class Edit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      categories: [],
      file: null,
      croppedFile: null,
      show: false,
      isNext: false,
      filename: '',
      selected: null,
      image: img,
      name: '',
      catalogue: '',
      size: '',
      company: '',
      category: '',
      description: '',
      used: '',
      tags: [],
      isMedia: false,
      medias: [

      ],
      progress: 0,
      errors: []
    }

    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount() {
    if (this.props.router.location.state) {
      const item  = this.props.router.location.state
      const meds = item.media.map(i => {
        return {
          show: true,
          title: i.gallery.title,
          originSource: i.gallery ? i.gallery.originSource : '',
            originThumbnail: i.gallery ? i.gallery.originThumbnail : '',
          source: i.gallery.source,
          type: i.gallery.type,
          caption: i.gallery.caption,
          id: i.gallery.id,
          thumbnail: i.gallery.thumbnail,
        }
      })
      this.setState({
        id: item.id,
        image: item.icon,
        name: item.name,
        catalogue: item.catalog_number,
        size: item.size,
        company: item.company,
        category: item.category.id,
        description: item.description,
        used: item.used_for,
        tags: item.tags.map(i => {
          return i.name
        }),
        medias: meds,
      })
    }
    axios
      .get(`api/medical-categories/${this.props.router.location.state.category.medical_inventory_id}`)
      .then(result => {
        this.setState({
          categories: result.data.data,
        })
      })
      .catch(errors => {
        console.log(errors)
      })
    $('[data-toggle="popover"]').popover()

    $(document).on('click', '.rc-dialog-close', function () {
      $('.rc-btn-ghost').click()
    })
  }

  onSaveItem() {
    const {
      medias,
      image,
      name,
      catalogue,
      size,
      company,
      category,
      description,
      used,
      tags,
      id
    } = this.state
    const form = new FormData()
    form.append('_method', 'PATCH')
    if (image.indexOf('base64') >= 0) {
      form.append('icon', dataURItoBlob(image))
    }
    form.append('name', name)
    form.append('medical_category_id', category)
    form.append('catalog_number', catalogue)
    form.append('company', company)
    form.append('description', description)
    form.append('used_for', used)
    form.append('tags', tags)
    form.append('size', size)

    console.log(medias)
    medias.map((item, index) => {
      if (item.show) {
        if (item.source != '') {
          let source = item.source ? item.source : ''
          if (source.indexOf('base64') >= 0) {
            form.append(`images[${index}]`, dataURItoBlob(item.source))
            form.append(`thumbnails[${index}]`, dataURItoBlob(item.thumbnail))
            form.append(`updated[${index}]`, 1)
          } else {
            form.append(`images[${index}]`, '')
            form.append(`thumbnails[${index}]`, '')
            form.append(`updated[${index}]`, 0)
          }
          form.append(`originSource[${index}]`, item.originSource ? item.originSource : 0) 
            form.append(`originThumbnail[${index}]`, item.originThumbnail)
            form.append(`disk[${index}]`, item.disk) 
          form.append(`image_id[${index}]`, item.id)
          form.append(`img_name[${index}]`, item.title)
          form.append(`img_caption[${index}]`, item.caption)
          form.append(`type[${index}]`, item.type)
        }
      }
    })
    let config = {
      headers: {
        'content-type': 'multipart/form-data',
      },

      onUploadProgress: (progressEvent) => {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        this.setState({
          progress: percentCompleted >= 95 ? 95 : percentCompleted,
        })
      }
    }
    const uri = `api/items/${id}`
    axios
      .post(uri, form, config)
      .then(result => {
        console.log('tesasdasd',result)
        this.setState({
          progress: 0,
        })
        this.props.router.push({pathname:'/medical-inventory-instruments'})
      })
      .catch(error => {
        console.log('Error')
        console.log(error)
        console.log(error.response)
        this.setState({
          progress: 0,
          errors: error.response.data.errors,
        }, () => {
          let targetOffset = $('.is-invalid').offset().top;
          $('html, body').animate({scrollTop: targetOffset}, 100);
        })  
      })
  }

  addMoreMedia() {
    const { medias } = this.state
    medias.push({
      show: true,
      title: '',
      media: '',
      type: '',
      caption: '',
      id: 0,
      thumbnail: '',
    })
    this.setState({
      medias,
    })
  }

  handleClick(e) {
    this.setState({
      show: true,
    })
    this.refs.file.click()
  }
  onFileChange = () => {
    this.setState({ file: this.refs.file.files[0] })
  }
  croppeFile = fileBlob => {
    if (!fileBlob) {
      this.setState({
        show: false,
        file: null,
        croppedFile: null,
        isNext: false,
        page: 1,
      })
      return
    }
    const reader = new FileReader()
    reader.readAsDataURL(fileBlob)
    let filename = this.state.file.name
    reader.onload = () => {
      this.setState({
        show: false,
        file: null,
        croppedFile: null,
        isNext: false,
        page: 1,
        filename: filename,
        image: reader.result,
      })
    }
  }
  renderCropper() {
    const { file } = this.state

    if (!file) {
      if (this.state.filename) {
        return (
          <div className="d-inline-flex aling-items-center">
            <p className="m0">{this.state.filename}</p>
            <input
              type="file"
              onChange={this.onFileChange}
              ref="file"
              className="d-none"
            />
            <button
              className="btn btn-link ml20 text-dark font-weight-bold py0"
              onClick={this.handleClick}
            >
              Edit
            </button>
          </div>
        )
      }

      return (
        <div>
          <input
            type="file"
            onChange={this.onFileChange}
            ref="file"
            className="d-none"
          />
          <button className="btn btn-dark mw150" onClick={this.handleClick}>
            Upload
          </button>
        </div>
      )
    } else {
      if (this.state.show) {
        return (
          <Cropper
            size={[64, 64]}
            file={this.state.file}
            getSpinContent={() => <span>loading...</span>}
            renderModal={() => <Dialog className="modal-dialog-centered" />}
            onChange={this.croppeFile}
            locale='en-US'
            circle={false}
          />
        )
      }
    }
  }
  handleChange(tags) {
    this.setState({ tags })
  }
  tagHelper() {
    const { name, description } = this.state
    const nameTag = name.split(' ')
    const desc = description.split(' ')
    const tag = [...nameTag, ...desc]
    this.setState({
      tags: tag,
    })
  }
  renderInputTags({ addTag, ...props }) {
    let { onChange, value, ...other } = props
    return (
      <textarea
        className="form-control"
        rows={4}
        onChange={onChange}
        value={value}
        {...other}
      />
    )
  }
  renderForm() {
    const categoryOptions = this.state.categories.map(item => {
      return (
        <option
          key={`category_option_${item.id}`}
          value={item.id}
          selected="false"
        >
          {item.name}
        </option>
      )
    })
    return (
      <div className="col-lg-5 col-md-6">
        <h4 className="mb15">Item information</h4>
        <p className="mb30">Required fields are marked with *</p>
        <div className="form-group mb30">
          <ImagePicker aspectRatio={3 / 2} 
            getImage={(image) => {
              this.setState({
                image: image
              })
            }}
          />
        </div>
        <div className="form-group mb30">
          <label className="mb10">Name *</label>
          <input
            type="text"
            name="image"
            className="form-control"
            onChange={event => {
              this.setState({ name: event.target.value })
            }}
            value={this.state.name}
            onBlur={this.tagHelper.bind(this)}
          />
        </div>
        <div className="row">
          <div className="col-md-8 pr-15">
            <div className="form-group mb30">
              <label className="mb10">Catalogue</label>
              <input
                type="text"
                name="catalogue"
                className="form-control"
                onChange={event => {
                  this.setState({ catalogue: event.target.value })
                }}
                value={this.state.catalogue}
              />
            </div>
          </div>
          <div className="col-md-4 pl-15">
            <div className="form-group mb30">
              <label className="mb10">Size</label>
              <input
                type="text"
                name="Size"
                className="form-control"
                onChange={event => {
                  this.setState({ size: event.target.value })
                }}
                value={this.state.size}
              />
            </div>
          </div>
        </div>
        <div className="form-group mb30">
          <label className="mb10">Company</label>
          <input
            type="text"
            name="company"
            className="form-control"
            onChange={event => {
              this.setState({ company: event.target.value })
            }}
            value={this.state.company}
          />
        </div>
        <div className="form-group mb30">
          <label className="mb10">Assign a category*</label>
          <select
            className="form-control"
            onChange={event => {
              this.setState({ category: event.target.value })
            }}
            value={this.state.category}
          >
            <option value="">Select Category</option>
            {categoryOptions}
          </select>
        </div>
        <div className="form-group mb30">
          <label className="mb10">Add a description</label>
          <textarea
            className="form-control"
            rows={4}
            onChange={event => {
              this.setState({ description: event.target.value })
            }}
            onBlur={this.tagHelper.bind(this)}
            value={this.state.description}
          />
        </div>
        <div className="form-group mb30">
          <label className="mb10">How is it used?</label>
          <textarea
            className="form-control"
            rows={4}
            onChange={event => {
              this.setState({ used: event.target.value })
            }}
            value={this.state.used}
          />
        </div>
        <div className="form-group mb30">
          <label className="mb10 d-flex">
            Tags
            <div
              className="info ml-auto c-pointer"
              data-container="body"
              data-trigger="click"
              data-toggle="popover"
              data-placement="right"
              data-content="By adding tags, you and your team will be able to mention items in the context of an instruction, procedure or phase. Make sure to include any nick-names or slang so that others can find it easy."
              data-title="Adding tags"
            >
              <div className="w25 h25">i</div>
            </div>
          </label>
          <TagsInput
            value={this.state.tags}
            onChange={this.handleChange.bind(this)}
            style={{height:200}}
          />
        </div>
        <div className="form-group mb-0 pb-0">
          <a
            href="#"
            className="btn btn-dark btn-element mr30 c-pointer px30 m-xsx0"
            onClick={(event) => {
              event.preventDefault()
              this.setState({ isMedia: true })
              $('[data-toggle="popover"]').popover('hide')
            }}
          >
            Add media
          </a>
          <div
            className="btn btn-outline-dark btn-element c-pointer mx-0 m-xst30 m-xsx0"
            data-target="#confirmationModal"
            data-toggle="modal"
          >
            Cancel
          </div>
        </div>
      </div>
    )
  }
  titleChange(event, index) {
    const { medias } = this.state

    medias[index].title = event.target.value

    this.setState({
      medias: medias.splice(0),
    })
  }

  titleChange(event, index) {
    const { medias } = this.state

    medias[index].title = event.target.value

    this.setState({
      medias: medias.splice(0),
    })
  }
  captionChange(event, index) {
    const { medias } = this.state

    medias[index].caption = event.editor.getData()

    this.setState({
      medias: medias.splice(0),
    })
  }

  onDelete(event, index) {
    event.preventDefault()
    const { medias } = this.state

    medias[index].show = false

    this.setState({
      medias: medias.splice(0),
    })
  }

  mediaChange(event, index) {
    const { medias } = this.state

    medias[index].source = event.data
    medias[index].type = event.type
    if (event.type == 'image') medias[index].thumbnail = event.data

    this.setState({
      medias: medias.splice(0),
    })
  }

  thumbnailChange(thumbnail, index) {
    const { medias } = this.state
    console.log(thumbnail)
    medias[index].thumbnail = thumbnail
    this.setState({
      medias: medias.splice(0),
    })
  }
  renderMedia() {
    const media_items = this.state.medias.map((media, index) => {
      return media.show ? (
        <MediaInput
          key={`media_key_${index}`}
          media_data={media}
          validation={{
            errors: this.state.errors,
            index: index,
            field: 'img_name'
          }}
          onTitleChange={event => this.titleChange(event, index)}
          onCaptionChange={event => this.captionChange(event, index)}
          onMediaChange={event => this.mediaChange(event, index)}
          onDelete={event => this.onDelete(event, index)}
          thumbnailChange={thumbnail => this.thumbnailChange(thumbnail, index)}
        />
      ) : null
    })
    return (
      <div className="col-md-8">
        <h4 className="mb15">Add media and instructions</h4>
        <div className="row mb0">
          <div className="col-md-9">
            <p className="mb30">
              Here you can add images or videos to provide additional
              information on how your hospital uses the item.
            </p>
          </div>
        </div>
        <div className="row mb-0">
          <div className="col">{media_items}</div>
        </div>
        <div className="row mb-0">
          <div className="col-12">
            <label
              className="w-100 d-flex justify-content-center align-items-center bg-light of-hidden m-0 cursor-pointer h50 py30"
              style={{ border: '2px dashed rgb(132, 134, 136)' }}
              onClick={this.addMoreMedia.bind(this)}
            >
              {/* <img src="img.png" className="h50" id="">  */}
              Add more image or video +
            </label>
          </div>
        </div>
        <div className="form-group pt30 pb-0 mb-0">
          <div
            className="btn btn-dark btn-element mr30 c-pointer px30"
            data-target="#saveModal"
            data-toggle="modal"
          >
            Save item
          </div>
          <div
            className="btn btn-outline-dark btn-element c-pointer px30"
            onClick={() => this.setState({ isMedia: false })}
          >
            Back
          </div>
          <a
            href="#"
            className="btn btn-link text-dark btn-element c-pointer"
            data-target="#skipModal"
            data-toggle="modal"
          >
            <u>Skip step</u>
          </a>
        </div>
      </div>
    )
  }

  renderView() {
    if (this.state.isMedia) return this.renderMedia()
    else return this.renderForm()
  }
  render() {
    console.log('xcxc', this.state)

    return (
      <div className="container">
        <div className="row">
          <div className="col-lg-9 col-md-8 offset-lg-3 offset-md-4">
            <h1 className="h1bold page-title">{`${
              true ? 'Update' : 'Register'
              }  ${this.props.medicalInventoryName == 'Supply' ? 'a' : 'an'} [${
              this.props.medicalInventoryName
                ? this.props.medicalInventoryName
                : 'Item'
              }]`}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-4 mb30">
            <div className="card side-card border-0">
              <div className="card-body border-0 p-0">
                <img src={this.state.image} className="bg-dark w-100" />
              </div>
            </div>
          </div>
          {this.renderView()}
          <Confirmation
            title="Cancel"
            message="Are you sure you want to cancel?"
            confirmText="Yes"
            cancelText="No"
            confirm={() => {
              this.props.router.push({pathname:'/medical-inventory-instruments'})
            }}
            cancel={() => {
              $('confirmModal').modal('hide')
            }}
          />

          <WholeBodyloader message="Saving! please wait..." progress={this.state.progress} type="progress" />

          <Confirmation
            title="Skip and Save"
            message="Are you sure you want to skip this process?"
            confirmText="Yes"
            cancelText="No"
            idModal={'skipModal'}
            confirm={this.onSaveItem.bind(this)}
            cancel={() => {
              $('confirmModal').modal('hide')
            }}
          />

          <Confirmation
            title="Save"
            message="Are you sure you want to save?"
            confirmText="Yes"
            cancelText="No"
            idModal={'saveModal'}
            confirm={this.onSaveItem.bind(this)}
            cancel={() => {
              $('confirmModal').modal('hide')
            }}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    medical_inventory_id: state.item.medical_inventory_id,
  }
}

export default connect(
  mapStateToProps,
  null
)(Edit)
