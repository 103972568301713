import $ from 'jquery';
import React, { Component } from 'react';
import ImageMapper from 'react-image-mapper';
import { connect } from 'react-redux';
import { getBodyAreas } from '../../actions/PostioningActions';
import body_img from '../../assets/images/body-area.png';
import DataCard from '../commons/cards/DataCard';
class PositionAreas extends Component {
    constructor(props) {
      super(props)
    
			this.state = {
				hoveredItem: [],
				hoveredArea: [],
				body_areas: [],
				map: {
					name: "body-map",
					areas: [
					]
				},
				key: this.makeId(15)
      } 
		}
		makeId(length) {
			var result = '';
			var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
			var charactersLength = characters.length;
			for (var i = 0; i < length; i++) {
				result += characters.charAt(Math.floor(Math.random() * charactersLength));
			}
			return result;
		}
		setAreas(nextProps = null) {
			this.setState({
				map: {
					name: "body-map",
					areas: []
				},
			})
			let areas_default = [
				{ name: '1', shape: "poly", coords: [124, 7, 132, 8, 138, 12, 145, 19, 148, 26, 150, 35, 150, 44, 147, 55, 141, 63, 133, 67, 124, 68, 114, 66, 106, 62, 101, 55, 98, 46, 98, 38, 99, 29, 102, 21, 106, 15, 115, 8], fillColor: "#7674d6", strokeColor: "#7674d6", lineWidth: 0 },
				{ name: '2', shape: "poly", coords: [110, 66, 123, 69, 138, 67, 145, 76, 163, 85, 163, 145, 84, 145, 84, 85, 103, 75], fillColor: "#7674d6", strokeColor: "#7674d6", lineWidth: 0 },
				{ name: '3', shape: "poly", coords: [84, 85, 83, 145, 57, 144, 56, 111, 61, 96, 71, 86], fillColor: "#7674d6", strokeColor: "#7674d6", lineWidth: 0 },
				{ name: '4', shape: "poly", coords: [164, 85, 164, 145, 191, 145, 191, 108, 187, 94, 177, 85], fillColor: "#7674d6", strokeColor: "#7674d6", lineWidth: 0 },
				{ name: '5', shape: "poly", coords: [82, 146, 166, 146, 165, 184, 171, 231, 76, 231, 84, 184], fillColor: "#7674d6", strokeColor: "#7674d6", lineWidth: 0 },
				{ name: '6', shape: "poly", coords: [76, 232, 171, 232, 171, 270, 166, 295, 129, 295, 127, 271, 121, 271, 119, 294, 83, 294, 77, 270], fillColor: "#7674d6", strokeColor: "#7674d6", lineWidth: 0 },
				{ name: '7', shape: "poly", coords: [83, 295, 119, 295, 120, 333, 117, 345, 119, 382, 115, 398, 118, 416, 96, 416, 86, 378, 88, 341, 89, 317], fillColor: "#7674d6", strokeColor: "#7674d6", lineWidth: 0 },
				{ name: '8', shape: "poly", coords: [128, 295, 166, 295, 158, 317, 160, 334, 160, 349, 162, 380, 152, 416, 130, 416, 133, 396, 129, 382, 130, 345, 127, 331], fillColor: "#7674d6", strokeColor: "#7674d6", lineWidth: 0 },
				{ name: '9', shape: "poly", coords: [56, 145, 81, 145, 72, 172, 69, 194, 46, 232, 31, 225, 42, 180], fillColor: "#7674d6", strokeColor: "#7674d6", lineWidth: 0 },
				{ name: '10', shape: "poly", coords: [168, 146, 191, 146, 206, 185, 216, 226, 201, 231, 179, 191, 174, 167], fillColor: "#7674d6", strokeColor: "#7674d6", lineWidth: 0 },
				{ name: '11', shape: "poly", coords: [96, 417, 118, 417, 118, 436, 121, 446, 108, 451, 98, 461, 83, 462, 77, 457, 78, 449, 96, 431], fillColor: "#7674d6", strokeColor: "#7674d6", lineWidth: 0 },
				{ name: '12', shape: "poly", coords: [129, 416, 152, 416, 152, 431, 171, 451, 170, 459, 162, 462, 147, 460, 137, 449, 126, 445, 128, 436], fillColor: "#7674d6", strokeColor: "#7674d6", lineWidth: 0 },
				{ name: '13', shape: "poly", coords: [217, 225, 245, 251, 230, 245, 238, 273, 227, 258, 230, 278, 218, 256, 221, 278, 212, 258, 212, 271, 202, 247, 201, 231], fillColor: "#7674d6", strokeColor: "#7674d6", lineWidth: 0 },
				{ name: '14', shape: "poly", coords: [30, 225, 4, 250, 19, 245, 10, 272, 23, 254, 18, 277, 30, 254, 27, 277, 35, 258, 37, 271, 45, 249, 46, 232], fillColor: "#7674d6", strokeColor: "#7674d6", lineWidth: 0 },
			]
			let areas = [];
			if(nextProps){ 
				const d = nextProps.details.map(detail => {
					let parts = [{ name: 1, part: 'head' }, { name: 2, part: 'neck_upper_torso' }, { name: 3, part: 'left_shoulder' }, { name: 4, part: 'right_shoulder' }, { name: 5, part: 'abdomen' }, { name: 6, part: 'hip' }, { name: 7, part: 'left_leg' }, { name: 8, part: 'right_leg' }, { name: 9, part: 'left_arm' }, { name: 10, part: 'right_arm' }, { name: 11, part: 'left_foot' }, { name: 12, part: 'right_foot' }, { name: 13, part: 'right_hand' }, { name: 14, part: 'left_hand' }]
					let part = parts.find((x) => { return x.part == detail[0].position.code });
					let area = areas_default.find((x) => { return x.name == part.name })
					areas.push(area)
				})
			} else {
				const d = this.props.details.map(detail => {
					let parts = [{ name: 1, part: 'head' }, { name: 2, part: 'neck_upper_torso' }, { name: 3, part: 'left_shoulder' }, { name: 4, part: 'right_shoulder' }, { name: 5, part: 'abdomen' }, { name: 6, part: 'hip' }, { name: 7, part: 'left_leg' }, { name: 8, part: 'right_leg' }, { name: 9, part: 'left_arm' }, { name: 10, part: 'right_arm' }, { name: 11, part: 'left_foot' }, { name: 12, part: 'right_foot' }, { name: 13, part: 'right_hand' }, { name: 14, part: 'left_hand' }]
					let part = parts.find((x) => { return x.part == detail[0].position.code });
					let area = areas_default.find((x) => { return x.name == part.name })
					areas.push(area)
				})
			}

			this.setState({
				map: {
					name: "body-map",
					areas: areas
				},
			})
		}
		
		componentWillMount() {
			this.props.getBodyAreas()
			this.setAreas()
		}

    componentDidMount = () => {

			this.props.getBodyAreas()
			// this.state.map.areas.map(area => {
			// 	console.log('this.computeCenter(area)', this.computeCenter(area));
			// })
		}

		enterArea(area) { 
			if (this.state.hoveredArea.indexOf(area) < 0) {
				this.setState({ hoveredArea: [...this.state.hoveredArea, area] });
			}
		}

		scaleCoords(coords) {
			var _props = this.props;
			var imgWidth = _props.imgWidth;
			var width = _props.width;

			// calculate scale based on current 'width' and the original 'imgWidth'
			var scale = width && imgWidth && imgWidth > 0 ? width / imgWidth : 1;
			return coords.map(function (coord) {
				return coord * scale;
			});
		}

		computeCenter(area) {
			if (!area) return [0, 0];

			var scaledCoords = this.scaleCoords(area.coords);

			switch (area.shape) {
				case "circle":
					return [scaledCoords[0], scaledCoords[1]];
				case "poly":
				case "rect":
				default:
					{
						var _ret = (function () {
							// Calculate centroid
							var n = scaledCoords.length / 2;

							var _scaledCoords$reduce = scaledCoords.reduce(function (_ref, val, idx) {
								var y = _ref.y;
								var x = _ref.x;

								return !(idx % 2) ? { y: y, x: x + val / n } : { y: y + val / n, x: x };
							}, { y: 0, x: 0 });

							var y = _scaledCoords$reduce.y;
							var x = _scaledCoords$reduce.x;

							return {
								v: [x, y]
							};
						})();

						if (typeof _ret === 'object') return _ret.v;
					}
			}
		}

		getTipPosition(area) {
			return { top: `${area.center[1]}px`, left: `${area.center[0]}px` };
		}
		
		renderToolTips() {
			const tips = this.state.map.areas.map(area => {
				let top = this.computeCenter(area)[1];
				let left = this.computeCenter(area)[0];
				if (top == 272.4) {
					top = top - 20
				}
				if (Math.ceil(top) == 91) {
					top = top + 20
				}
				if (Math.ceil(top) == 256) {
					top = top - 10;
					left = left + (left == 27 ? 4 : -4);
				}
				let parts = [{name: 1, part: 'head'}, {name:2, part:'neck_upper_torso'}, {name:3, part:'left_shoulder'}, {name:4, part:'right_shoulder'}, {name:5, part:'abdomen'}, {name:6, part:'hip'}, {name:7, part:'left_leg'}, {name:8, part:'right_leg'}, {name:9, part:'left_arm'}, {name:10 ,part:'right_arm'}, {name:11 ,part:'left_foot'}, {name:12 ,part:'right_foot'}, {name:13 ,part:'right_hand'},{name:14 ,part:'left_hand'}]
				let name = '';
				let body_part = ''
				parts.map( part => {
					if(area.name == part.name){
						name = this.getLabel(part.part)
						body_part = part.part
					}
				})
				return (<span className="tooltip"
					style={{ ...{ top: `${top}px`, left: `${left}px` } }}>
					{ name }
				</span>
				)
			})
			return (
				<div>
					{tips}
				</div>
			)
		}
		
		
		
    getLabel = (code) => {
			const { details }  = this.props

			let parts = [{ name: 1, part: 'head' }, { name: 2, part: 'neck_upper_torso' }, { name: 3, part: 'left_shoulder' }, { name: 4, part: 'right_shoulder' }, { name: 5, part: 'abdomen' }, { name: 6, part: 'hip' }, { name: 7, part: 'left_leg' }, { name: 8, part: 'right_leg' }, { name: 9, part: 'left_arm' }, { name: 10, part: 'right_arm' }, { name: 11, part: 'left_foot' }, { name: 12, part: 'right_foot' }, { name: 13, part: 'right_hand' }, { name: 14, part: 'left_hand' }]
      let length = ' '
			const d =  details.map( detail => {
        if(detail[0].position.code == code){
					length = detail.length
					let part = parts.find((x) => { return x.part != code });
					
					// if(part){
					// 	this.setState({
					// 		data: this.state.map.areas.data.map(el => (el.name === part.name ? Object.assign({}, el, { fillColor:'#fff' }) : el))
					// 	})
					// } else {
					// 	this.setState({
					// 		data: this.state.map.areas.data.map(el => (el.name === part.name ? Object.assign({}, el, { fillColor: '#7674d6' }) : el))
					// 	})
						
					// }
        }
       
      })

      return length
    }
		
		componentWillReceiveProps(nextProps) {
			// if (this.props != nextProps) { 
			// 	this.setAreas(nextProps)
			// 	this.setState({
			// 		key: this.makeId(11) 
			// 	})	
			// }
		}

		goToAction(el){

		}
		
    render() {

      const { details }  = this.props
      
        return (
					<>
						<DataCard title=" "  className="mb20"
							key={`card-${this.state.key}`}>
							<div className="centerAlign mb20">
								<div className="body-image">
									<ImageMapper
										key={this.state.key}
										src={body_img}
										map={this.state.map}
										onLoad={(areas) => {
											console.log('areas', areas)
										}}
										onMouseEnter={area => this.enterArea(area)}
										onClick={area => { 
											let parts = [{ name: 1, part: 'head' }, { name: 2, part: 'neck_upper_torso' }, { name: 3, part: 'left_shoulder' }, { name: 4, part: 'right_shoulder' }, { name: 5, part: 'abdomen' }, { name: 6, part: 'hip' }, { name: 7, part: 'left_leg' }, { name: 8, part: 'right_leg' }, { name: 9, part: 'left_arm' }, { name: 10, part: 'right_arm' }, { name: 11, part: 'left_foot' }, { name: 12, part: 'right_foot' }, { name: 13, part: 'right_hand' }, { name: 14, part: 'left_hand' }]
										 
											let body_part = ''
											parts.map(part => {
												if (area.name == part.name) { 
													body_part = part.part
												}
											})

											var jump = body_part;
											var new_position = $(`#${jump}`).offset();
											console.log(new_position, jump)
											$(`#${jump} .collapse`).collapse('show');
											if (new_position) {
												$('html').stop().animate({ scrollTop: new_position.top }, 200);
											} 
											this.enterArea(area)
										}}
									/>
									{this.renderToolTips()}
								</div>
							</div>
						</DataCard>
			<div className="card p-0 shadow-sm border-0 mt20 d-none" id="home">
				<div className="card-header bg-white p15 border-0" id="note-headeing-1">
					<h5 className="mb-0 d-flex align-items-center">
						Items required for positioning
                      </h5>
				</div>
				<div className="card-body pt-0 mt5 px15">

					<div class="row mb10">
						<div class="col-md-5 mx-auto d-flex justify-content-center align-items-baseline p-xsx0">


							<div class="w60 px5 mb5 d-flex justify-content-center flex-column">
								<a class="border-0 px10 h60 d-flex align-items-center justify-content-center mb5">&nbsp;</a>

								<a href="#left_shoulder" class="border border-dark px10 h50 d-flex align-items-center justify-content-center mb5">
									{this.getLabel('left_shoulder')}
								</a>
								<a href="#left_arm" class="border border-dark px10 h100 d-flex align-items-center justify-content-center mb5"> 
									{this.getLabel('left_arm')}
								</a>
								<a href="#left_hand" class="border border-dark px10 h50 d-flex align-items-center justify-content-center mb5">
									{this.getLabel('left_hand')}
								</a>
							</div>

							<div class="w150 mb5 d-flex justify-content-center flex-column align-items-baseline">
								<a href="#head" class="border border-dark w150 h50 d-flex align-items-center justify-content-center mb5">
									{this.getLabel('head')}
								</a>
								<a href="#neck_upper_torso" class="border border-dark w150 h90 d-flex align-items-center justify-content-center mb5">
									{this.getLabel('neck_upper_torso')}
								</a>
								<a href="#abdomen" class="border border-dark w150 h90 d-flex align-items-center justify-content-center mb5">
									{this.getLabel('abdomen')}
								</a>
								<a href="#hip" class="border border-dark w150 h60 d-flex align-items-center justify-content-center mb5">
									{this.getLabel('hip')}
								</a>

								<div class="row h100 d-flex justify-content-between m-0 w-100">
									<div class="col-6 pl-0 pr5">
										<a href="#left_leg" class="border border-dark w-100 h70 d-flex align-items-center justify-content-center mb5">
											{this.getLabel('left_leg')}
										</a>
										<a href="#left_foot" class="border border-dark w-100 h30 d-flex align-items-center justify-content-center mb5">
											{this.getLabel('left_foot')}
										</a>
									</div>
									<div class="col-6 pr-0 pl5">
										<a href="#right_leg" class="border border-dark w-100 h70 d-flex align-items-center justify-content-center mb5">
											{this.getLabel('right_leg')}
										</a>
										<a href="#right_foot" class="border border-dark w-100 h30 d-flex align-items-center justify-content-center mb5">
											{this.getLabel('right_foot')}
										</a>
									</div>

								</div>

							</div>
							<div class="w60 px5 mb5 d-flex justify-content-center flex-column">
								<a class="border-0 px10 h60 d-flex align-items-center justify-content-center mb5">&nbsp;</a>

								<a href="#right_shoulder" class="border border-dark px10 h50 d-flex align-items-center justify-content-center mb5">
									{this.getLabel('right_shoulder')}
								</a>
								<a href="#right_arm" class="border border-dark px10 h100 d-flex align-items-center justify-content-center mb5">
									{this.getLabel('right_arm')}
								</a>
								<a href="#right_hand" class="border border-dark px10 h50 d-flex align-items-center justify-content-center mb5">
									{this.getLabel('right_hand')}
								</a>
							</div>

						</div>
					</div>

				</div>
			</div>
			</>
        );
    }
}

const mapStateToProps = (state) => {
  return({
    body_areas: state.positioning.body_areas
  })
}

  export default connect(mapStateToProps, {getBodyAreas})(PositionAreas);