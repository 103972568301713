import { POSITIONING_UPDATE, GET_POSITIONS, GET_POSITION, GET_BODY_AREAS, GET_POSITION_DETAILS } from '../actions/types'

const INITIAL_STATE = {
  name: null,
  instructions: null,
  position_image: null,
  pressure_map_image: null,
  pressure_maps: [],
  media_title: null,
  media: [],
  positions: [],
  position: {},
  position_details: [],
  position_master: [],
  body_areas: []
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case POSITIONING_UPDATE:
        return { ...state, [action.payload.prop]: action.payload.value }
      case GET_POSITIONS:
        return {...state, positions: action.positions}
      case GET_POSITION:
        return {...state, position: action.position}
      case GET_BODY_AREAS:
        return {...state, body_areas: action.body_areas}
      case GET_POSITION_DETAILS:
        return {...state, position_details: action.position_details, position_master: action.position_master}
      default:
        return state
   }
}
