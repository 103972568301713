import React, { Component } from 'react'

export class Collapsible extends Component {
  constructor(props) {
    super(props)

    this.state = {
      unique: this.makeid(10),
    }

  }
  

  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  
  render() {
    const { className, bodyClass, open, title, stickyHeader,onClick, headerBtn = null, headerTitleClass=''} = this.props
    return (
      <div className={`single-collapse ${className} ${this.state.unique} `}>
        <div className={`header ${open ? '' :'collapsed'} ${stickyHeader ? 'sticky-top':''}`} data-toggle="collapse" href={`#${this.state.unique}`} onClick={onClick}>
          <h3 className={`title ${headerTitleClass}`}>{title ? title:'-'}</h3>
          {headerBtn ? headerBtn():''}
          <a className="toggle-icon">
            <i className="mdi mdi-chevron-up"></i>
          </a>
        </div>
        <div className={`body collapse ${open ? 'show':''}`} id={this.state.unique}>
          <div className={`card card-body ${bodyClass}`}>
            {this.props.children}
          </div>
        </div>
      </div> 
    )
  }
}

export default Collapsible
