import React, { Component } from 'react'

export class FormModal extends Component {
    render() {

        const { title, idModal, confirmText, cancelText, closeModalOnSubmit=true, confirmDisabled=false } = this.props

        return (
            <div className="modal fade modal-xs" id={idModal ? idModal:'formModal'} tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        {
                            title ? 
                            <div className="modal-header text-center">
                                <h3 className="modal-title">{title}</h3>
                            </div>:null
                        }
                        <div className="modal-body form-modal-body">
                            {this.props.children}
                        </div>
                        
                        <div className="modal-footer text-center">
                            <div className={`cta cta-primary ${confirmDisabled ? 'disabled' : ''}`} data-dismiss={closeModalOnSubmit ? 'modal':''} onClick={(event) => { this.props.confirm(event)}} >{confirmText}</div>
                            <div className="cta cta-secondary" data-dismiss="modal" onClick={this.props.cancel}>{cancelText}</div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default FormModal
