import React, { Component } from 'react'
import BigPictureModal from '../../../modals/BigPictureModal';
import $ from 'jquery'

export default class Standard extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      selected_media: [],
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
    }
  }
  
  render() {
    const { data, classStyle } = this.props
    return (
      <div className={`card p-0 shadow-sm border-0 mt0 w-100 float-left ${classStyle}`}>
        <BigPictureModal idModal={`bigPictureModal-standard-${this.state.unique}`} data={this.state.selected_media} />
        <div className="card-body pt-0 px15 pb-0">
          <div className="row">
            <div className="col-lg-6 pt20 order-2 order-lg-1">
              <p className="font-weight-bold mb15">
                {data.title ? data.title : 'No Specified.'}
              </p>
              <div
                className="of-auto h210 pr10"
                dangerouslySetInnerHTML={{
                  __html: data.caption || data.caption != 'null' ? data.caption : 'No specified',
                }}
              />
            </div>
            <div className="col-lg-6 p-0 order-1 order-lg-2">
              <div className="image-div">
                <img src={data.source} className="bg-dark w-100"
                  onClick={() => {
                    this.setState({
                      selected_media: {
                        title: data.title,
                        type: 'image',
                        source: data.source,
                      }
                    }, () => {
                        $(`#bigPictureModal-standard-${this.state.unique}`).modal('show')
                    })
                  }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
