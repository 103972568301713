import React, { Component } from 'react'
import img from '../../../../src/assets/images/img.png'
import { Button } from '../forms';

export class RecentMediaCard extends Component {
    render() {
        const { viewAllLink } = this.props
        return (
            <div className="card recent-media-card">
                <h3 className="bold recent-media-title">Most recent media upload
                    <Button tertiary link className="ml-md-auto" url={viewAllLink}>
                        View all
                    </Button>
                </h3>
                {this.props.children}
            </div>
        )
    }
}

export default RecentMediaCard
