import { 
  SEARCH_ITEM, GET_ITEMS, SET_ITEM_MEDICAL_INVENTORY, GET_MEDICAL_INVENTORIES,GET_QUICKNOTES_ITEM_LIST, GET_INVENTORY_CATEGORIES, GET_ITEMS_CATEGORIES, GET_STANDARD_ITEMS
} from "./types"
import axios from 'axios'
import { showLoader, setLoaderProg, hideLoader } from "../components/loader/loading_screen";
import _ from 'lodash'

export const getItems = (id, page, query) => {
  return(dispatch, getState) => {
    const { items } = getState().item
    dispatch({
      type: GET_ITEMS,
      loading: true,
      items: []
    })

    let url = `api/items?medical_inventory_id=${id}&medical_category_id=all&query=${query}&paginate=15&page=${page}`

    axios.get(url).then((res) => {
      dispatch({
        type: GET_ITEMS,
        loading: false,
        items: res.data.data
        // items: page === 1 ? res.data.data : [...items, ...res.data.data]
      })
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
    })
  }
}


export const itemLoading = () => {
  return dispatch => {
    dispatch({
      type:'ITEM_LOADING'
    })
  }
}
export const setItems = (items) => {
  return(dispatch, getState) => {
    const { more } = getState().item
    dispatch({
      type: GET_ITEMS,
      loading: false,
      items: items,
      more: more
    })
  }
}

export const getItemsPerCategory = (id, selected = [], id2=1, keyword='', page = 'all') => {
  return(dispatch, getState) => {
    let { items } = getState().item

    let url = `api/items?medical_category_id=${id}&medical_inventory_id=${id2}&query=${keyword}&paginate=15&page=${page}`

    let config = {
      headers: {
        'content-type': 'multipart/form-data',
      },

      // onDownloadProgress: progressEvent => {
      //   var percentCompleted = Math.round(
      //     (progressEvent.loaded * 100) / progressEvent.total
      //   )
      //   showLoader(percentCompleted >= 95 ? 95 : percentCompleted);
      //   setLoaderProg(percentCompleted >= 95 ? 95 : percentCompleted)
      // },
    }
    if (page == 1) {
      dispatch({
        type: GET_ITEMS,
        loading: true,
        items: []
      })
    }

    axios.get(url, config).then((res) => {
      let temp = page === 1 ? res.data.data : [...items, ...res.data.data]

      selected.map((selected) => {
        temp.map((item, index) => {
          if(selected.id == item.id){
            temp[index] = selected
          }
        })
      })

      let more = res.data.data.length == 15

      dispatch({
        type: GET_ITEMS,
        loading: false,
        items: _.cloneDeep(temp),
        more: more
      })
      // setLoaderProg(99)
      // setTimeout(() => {
      //   hideLoader()
      // }, 800);
    }).catch((error) => {
      // setLoaderProg(99)
      // setTimeout(() => {
      //   hideLoader()
      // }, 800);
      console.log(error)
      console.log(error.response)
    })

  }
}

export const getItemsPerCategoryStandard = (type, category, procedure, surgeon=1, selected = [], keyword='', page=1) => {
  return(dispatch, getState) => {
    let { items } = getState().item
    if (page == 1) {
      dispatch({
        type: GET_ITEMS_CATEGORIES,
        // loading: true,
        items: [],
        categories: [],
        more: false
      })
    }
    let url = ''
    switch(type){
      case 'consumables':
        category = category ? category : 'all'
        url = `api/client/client-stage-items?surgeon_id=${surgeon}&procedure_id=${procedure}&medical_category=${category}&search=${keyword}&paginate=10&page=${page}`
        break
      case 'instruments':
        category = category ? category : 'all'
        url = `api/client/client-stage-instrument-items?surgeon_id=${surgeon}&procedure_id=${procedure}&medical_category=${category}&search=${keyword}&paginate=10&page=${page}`
        break
      case 'equipments':
        url = `api/client/client-stage-equipment-items?surgeon_id=${surgeon}&procedure_id=${procedure}&medical_category=${category}&search=${keyword}&paginate=10&page=${page}`
        break
      default:
        break
    }

    axios.get(url).then((res) => {
      let temp = page === 1 ? res.data.items : [...items, ...res.data.items]

      selected.map((selected) => {
        temp.map((item, index) => {
          if(selected.id == item.id){
            temp[index] = selected
          }
        })
      })

      let more = res.data.items.length == 10

      res.data.category.unshift({
        id: 'all',
        name: 'All'
      })
      
      dispatch({
        type: GET_ITEMS_CATEGORIES,
        loading: false,
        items: _.cloneDeep(temp),
        categories: _.cloneDeep(res.data.category),
        more: more
      })
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
    })

  }
}

export const getStandardItems = (procedure, surgeon=1) => {
  return(dispatch) => {
    dispatch({
      type: GET_STANDARD_ITEMS,
      standard_items: [],
      loading:true
    })
    let items = []

    // let urls = [
    //   `api/client/client-stage-items?surgeon_id=${surgeon}&procedure_id=${procedure}&medical_category=all&paginate=0`,
    //   `api/client/client-stage-instrument-items?surgeon_id=${surgeon}&procedure_id=${procedure}&medical_category=all&paginate=0`,
    //   `api/client/client-stage-equipment-items?surgeon_id=${surgeon}&procedure_id=${procedure}&medical_category=all&paginate=0`
    // ]
    let url = `api/client/client-stage-items?surgeon_id=${surgeon}&procedure_id=${procedure}&medical_category=all&paginate=0`
    axios.get(url).then((res) => {
      items = items.concat(res.data.items)
      url = `api/client/client-stage-instrument-items?surgeon_id=${surgeon}&procedure_id=${procedure}&medical_category=all&paginate=0`
      axios.get(url).then((res) => {
        items = items.concat(res.data.items)
        url = `api/client/client-stage-equipment-items?surgeon_id=${surgeon}&procedure_id=${procedure}&medical_category=all&paginate=0`
        axios.get(url).then((res) => {
          items = items.concat(res.data.items)
          dispatch({
            type: GET_STANDARD_ITEMS,
            standard_items: items,
            loading:false
          })

        }).catch((err) => {
          console.log(err)
        })
      }).catch((err) => {
        console.log(err)
      })
    }).catch((err) => {
      console.log(err)
    })
  }
}



export const getItemsByCategory = (category_id, inventory_id) => {
  return(dispatch) => {
    dispatch({
      type: GET_ITEMS,
      loading: true,
      items: []
    })

    let url = `api/items?medical_category_id=${category_id}&medical_inventory_id=${inventory_id}`

    axios.get(url).then((res) => {
      dispatch({
        type: GET_ITEMS,
        loading: false,
        items: res.data.data.splice(0)
      })
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
    })

  }
}

export const getMedicalInventories = () => {
  return(dispatch) => {
    let url = 'api/medical-inventories'

    axios.get(url).then((res) => {
      dispatch({
        type: GET_MEDICAL_INVENTORIES,
        medical_inventories: res.data.data
      })
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
    })
  } 
}

export const getMedicalInventoryCategories = (id) => {
  return(dispatch) => {

    let url = `api/medical-categories-by-inventory/${id}`

    axios.get(url).then((res) => {
      res.data.data.unshift({
        id: 'all',
        name: 'All'
      })
      dispatch({
        type: GET_INVENTORY_CATEGORIES,
        inventory_categories: res.data.data
      })
    }).catch((error) => {
      console.log(error)
      console.log(error.response.data.errors)
    })
  }
}

export const setItemMedicalInventory = (id, name) => {
  return(dispatch) => {
    dispatch({
      type: SET_ITEM_MEDICAL_INVENTORY,
      medical_inventory_id: id,
      medical_inventory_name: name 
    })
  }
}

export const assignItems = (name, specialty_id, items) => {
  return(dispatch) => {
    let data = new FormData()
    items.map((item) => {
      data.append('items[]', item.id)
      data.append('qty[]', item.qty)
    })

    let url = '/api/tray'

    axios.post(url, data).then((res) => {

    }).catch((error) => {
      console.log(error)
      console.log(error.response)
    })
 
  }
}

export const getItem = (id) => {
  return(dispatch) => {

  }
}

export const searchItem = (item, keyword) => { 
  return(dispatch) => {
    dispatch({
      type: SEARCH_ITEM
    })
  }  
}
