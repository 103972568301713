const initialState = {
  items: [],
  tray: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_TRAY_ITEMS':
      return { ...state, items:action.items }
    default:
      return state
  }
}
