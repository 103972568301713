import React, { Component } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import error_img from '../../../assets/images/error_img.png'
import Vimeo from '@u-wave/react-vimeo'
import BigPictureModal from '../../modals/BigPictureModal'
import $ from 'jquery'
import axios from 'axios'
function NextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <i className="mdi mdi-chevron-right"></i>
    </div>
  )
}

function PrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <i className="mdi mdi-chevron-left"></i>
    </div>
  )
}

export class MediaElement extends Component {
  constructor(props) {
    super(props)
    this.state = {
      nav1: null,
      nav2: null,
      activeSlide: 0,
      key: this.makeid(10),
      unique: this.makeid(12),
      media_data: null,
    }
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    })
  }

  makeid(length) {
    var result = ''
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  next() {
    this.slider1.slickNext()
    this.slider2.slickNext()
  }
  previous() {
    this.slider1.slickPrev()
    this.slider2.slickPrev()
  }

  isUrl(s) {
    var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
    return regexp.test(s)
  }

  renderVideo(gallery) {
    let vimeoError = false
    if (!this.isUrl(gallery.source)) {
      return (
        <div className="ar32 in-progress">
          <img src={gallery.thumbnail} />
        </div>
      )
    }

    if (gallery.disk == 'local' || !gallery.disk) {
      return (
        <video className="bg-dark w-100 align-items-center h-100" controls>
          <source src={gallery.source} type="video/mp4" />
          <source src={gallery.source} type="video/webM" />
          <source src={gallery.source} type="video/ogg" />
          Your browser does not support the video tag.
        </video>
      )
    }
    const source = gallery.source
    console.log('gallery.sourcegallery.source', source)
    if (vimeoError) {
      return <img class="bg-light w-100 h-100" src={error_img} />
    } else {
      if (gallery.preview) {
        return (
          <div className="ar32 limit-reached">
            <img src={gallery.thumbnail} />
          </div>
        )
      } else {
        if (gallery.status == 'in_progress') {
          return (
            <div className="ar32 in-progress">
              <img src={gallery.thumbnail} />
            </div>
          )
        } else {
          return (
            <div className="ar32 vimeo">
              <Vimeo
                video={source}
                className="w-100 align-items-center "
                onError={() => {
                  vimeoError = false
                  this.setState({
                    errorInVimeo: true,
                    video_loaded: false,
                  })
                }}
                onLoaded={() => {
                  this.setState({
                    video_loaded: false,
                  })
                }}
              />
            </div>
          )
        }
      }
    }
  }

  renderPreviewItems(medias) {
    //let items = [1,2,3,4,5,6];
    return medias.map((media, index) => {
      let data = media.gallery ? media.gallery : media
      return (
        <div className="w-100 preview-slider">
          {' '}
          {data.length}
          <div className="image">
            {data.type == 'video' || data.disk == 'vimeo' ? (
              this.renderVideo(data)
            ) : (
              <div
                className="ar32"
                onClick={() => {
                  $('#media_type')
                    .val('image')
                    .change()
                  $('#media_title')
                    .val(data.title)
                    .change()
                  $('#media_source')
                    .val(data.source)
                    .change()
                  $('#fullMediaViewModal').modal('show')
                }}
              >
                <img src={data.thumbnail} />
              </div>
            )}
          </div>
          <div className="description">
            <h3 className="title">{data.title}</h3>
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html:
                  data.caption == undefined || data.caption == 'undefined'
                    ? ''
                    : data.caption,
              }}
            ></div>
          </div>
        </div>
      )
    })
  }

  renderThumnailPreview(medias) {
    return medias.map((media, index) => {
      let data = media.gallery ? media.gallery : media
      return (
        <div
          className={`thumb-item ${
            this.state.activeSlide == index ? 'active-slide' : ''
          }`}
          onClick={() => {
            this.slider1.slickGoTo(index)
          }}
        >
          <div className="ar32">
            <img src={data.thumbnail} />
          </div>
        </div>
      )
    })
  }

  render() {
    const { className, data, title } = this.props
    if (!data) return null
    const mainsetting = {
      focusOnSelect: true,
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 200,
      arrows: false,
      swipeToSlide: true,
      afterChange: index => {
        this.setState({
          activeSlide: index,
        })
      },
    }
    const thumbsettings = {
      className: 'center',
      infinite: false,
      centerPadding: '60px',
      slidesToShow: 5,
      swipeToSlide: true,
      afterChange: function(index) {
        console.log(
          `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
        )
      },
      rows: 1,
      responsive: [
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1
          }
        }
      ],
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    }
    let card_title = title ? title : data.title ? data.title : ''
    let medias = data.contents ? data.contents : data
    if (medias.length <= 0) return null

    return (
      <div className={`card media-element ${className}`}>
        {this.state.media_data ? (
          <BigPictureModal
            idModal={`media-big-picture-${this.state.unique}`}
            data={this.state.media_data}
          />
        ) : null}
        <div className="card-header">{card_title}</div>
        <div className="card-body">
          <div className="preview">
            <Slider
              {...mainsetting}
              ref={slider => (this.slider1 = slider)}
              asNavFor={this.state.nav2}
            >
              {this.renderPreviewItems(medias)}
            </Slider>
          </div>

          <div className="thumbnails">
            <Slider
              {...thumbsettings}
              key={this.state.key}
              ref={slider => (this.slider2 = slider)}
            >
              {this.renderThumnailPreview(medias)}
            </Slider>
          </div>
        </div>
      </div>
    )
  }
}

export default MediaElement
