import React, { Component } from 'react';
import img from '../../../../src/assets/images/default_profile.jpg';
import { Pills } from '../misc';

export class SurgeonInfoCard extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            surgeon: [],
            specialty: false
        }
    }

    componentWillReceiveProps(nextProps) {
       
    }
    
    render() {
        const { surgeon} = this.props
        const {  specialty=true } = this.state
        let specialty_cards = null;
        if(surgeon){
            if (surgeon.specialtyList){
                specialty_cards = surgeon.specialtyList.map( (data,index) =>{
                    if(data)
                    return <Pills content={data.name} key={`specialty-pill-info-${index}`}/>
                })
            }
        }
        if( !surgeon ){

            return (
                <div className="card surgeon-info-card">
                    <div className="profile">
                        <img src={img} className="icon-circle icon-circle-lg" />
                        <h3 className="surgeon-name bold">Surgeon’s full name</h3>
                        <h3 className="surgeon-job regular">Job title</h3>
                        <h3 className="surgeon-contact regular">-</h3>
                    </div>
                    <div className="detail">
                        <ul className="list-unstyled list">
                            <li className="item">
                                <h4 className="bold">Handedness</h4>
                                <p></p>
                            </li>
                            <li>
                                <h4 className="bold">Gloves</h4>
                                <p>Glove’s full name&nbsp; </p>
                            </li>
                            <li className="item">
                                <h4 className="bold">Size</h4>
                                <p>[size]&nbsp;</p>
                            </li>
                            <li>
                                <h4 className="bold">Outer Gloves</h4>
                                <p>Glove’s full name&nbsp; </p>
                            </li>
                            <li className="item">
                                <h4 className="bold">Size</h4>
                                <p>[size]&nbsp;</p>
                            </li>
                            <li className="item">
                                <h4 className="bold">Prefers</h4>
                                <p>
                                    <span>
                                        Music
                                    </span>
                                    <span className="float-right">
                                     Room environment
                                    </span>
                                </p>
                            </li>

                            {
                                specialty ?
                                    <li>
                                        <h4 className="bold">Specialties</h4>
                                        <span className="specialty-span">Orthopedics</span>
                                        <span className="specialty-span">ENT</span>
                                        <span className="specialty-span">Pedia</span>
                                    </li> : null
                            }

                        </ul>
                    </div>
                </div>
            )
        }else{

            return (
                <div className="card surgeon-info-card">
                    <div className="profile">
                        <img src={surgeon.avatar ? surgeon.avatar : img} className="icon-circle icon-circle-lg" />
                        <h3 className="surgeon-name bold">{surgeon.firstname || surgeon.lastname  ? `${surgeon.firstname} ${surgeon.lastname}` : 'Surgeon’s full name'}</h3>
                        <h3 className="surgeon-job regular">{surgeon.title ? surgeon.title : 'Job title'}</h3>
                        <h3 className="surgeon-contact regular">{surgeon.contact_number && surgeon.contact_number !='undefined' ? surgeon.contact_number:'-'}</h3>
                    </div>
                    <div className="detail">
                        <ul className="list-unstyled list">
                            <li className="item">
                                <h4 className="bold">Handedness</h4>
                                <p>
                                    {
                                        surgeon.handedness ? `${surgeon.handedness.charAt(0).toUpperCase()}${surgeon.handedness.slice(1)} handed` : ''
                                    }
                                </p>
                            </li>
                            {surgeon.inner_glove ?
                            (<>
                            <li>
                                <h4 className="bold">Gloves</h4>
                                <p>{surgeon.inner_glove ? surgeon.inner_glove.name : 'Glove’s full name'}&nbsp; </p>
                            </li>
                            <li className="item">
                                <h4 className="bold">Size</h4>
                                <p>{surgeon.inner_glove ? surgeon.inner_glove.size : '[size]'}&nbsp;</p>
                            </li>
                            </>)
                            : null }

                            {surgeon.outer_glove ?
                                (<>
                                    <li>
                                        <h4 className="bold">Outer Gloves</h4>
                                        <p>{surgeon.outer_glove ? surgeon.outer_glove.name : 'Glove’s full name'}&nbsp;</p>
                                    </li>
                                    <li className="item">
                                        <h4 className="bold">Size</h4>
                                        <p>{surgeon.outer_glove ? surgeon.outer_glove.size : '[size]'}&nbsp;</p>
                                    </li>
                                </>)
                                : null}
                            <li className="item">
                                <h4 className="bold">Prefers</h4>
                                <p>
                                    <span>
                                        Music {surgeon.music == 1 ? 'on' : 'off'}
                                    </span>
                                    <span className="float-right">
                                        {/* Room environment  */}
                                        {surgeon.enviroment_on == "normal" || surgeon.enviroment_on == 1 ? 'Normal' : 'Silent'}
                                    </span>
                                </p>
                            </li>
                                    
                            {
                                specialty_cards ?
                                    <li>
                                        <h4 className="bold">Specialties</h4>
                                        {specialty_cards}
                                    </li> : null
                            }

                        </ul>
                    </div>
                </div>
            )
        }
    }
}

export default SurgeonInfoCard
