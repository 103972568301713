import React, { Component } from 'react'

export class TabHead extends Component {
  render() {
    const { id = 'nav-tab', className='' } = this.props
    return (  this.props.children )
  }
}

export default TabHead
