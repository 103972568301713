import React, { Component } from 'react'
import ReactDOMServer from 'react-dom/server'
import _ from 'lodash'
import $ from 'jquery'
import { Picker } from '../commons/forms'
import axios from 'axios'
import { getArrayError, arrayInsert } from '../../utilities'
import ViewItemModal from '../../components/modals/ViewItemModal'
import { Modal } from '../commons/modals';
import { Table, THead, TData, TRow } from '../commons/table';
import ViewItemCard from '../commons/cards/ViewItemCard';

export class ReviewItemsPositioning extends Component {
  constructor(props) {
    super(props)

    this.state = {
      category: '',
      categories: [],
      inventory: '',
      selected_items: [],
      positions: [],
      qty: [],
      search: '',
      errors: [],
      selectedItem:null,
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15)
    }
  }

  componentWillReceiveProps = nextProps => {

    if (this.props.change != nextProps.change) {
      const qty = []
      let sel_pos = [];
      let sel_qty = [];
      let other_pos = [];
      let other_qty = [];

      nextProps.selected_items.map(item => {
        sel_pos.push(nextProps.selected_body_area)
      })
      nextProps.qty_selected.map(qty => {
        sel_qty.push(qty)
      })
      // this.props.selected_items_filtered.map(item => {
      //   other_pos.push(item.body_area)
      //   other_qty.push(item.qty)
      // })
       
      const positions = nextProps.selected_items.map((data, index) => {

        qty.push(data.qty ? data.qty:1)

        return this.props.selected_body_area
      })
      console.log('componentWillReceiveProps', nextProps.selected_items, nextProps.selected_items_filtered)
      this.setState({
        selected_items: nextProps.selected_items,
        sel_qty,
        sel_pos,
      })
    }
  }
    
  sortBy(key) {
    let arrayCopy = [...this.props.selected_items]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }

    this.props.setSelected(arrayCopy)
    this.setState({ isReverse: !this.state.isReverse })
  }

  validate = (items, position, qty) => {
    
    this.props.assign(items, position, qty)
    $('.modal').modal('hide')
    return
    let data = new FormData()

    items.map((item, index) => {
      data.append('item_ids[]', item.id)
      data.append('areas[]', position[index])
      data.append('qty[]', qty[index])
    })

    let url = '/api/positioning-equipments-validator'
    axios.post(url, data).then((res) => {
      this.props.assign(this.state.selected_items, this.state.positions, this.state.qty)
      this.setState({
        errors: []
      })
      $('.modal').modal('hide')
    }).catch((error) => {
      console.log('a', error)
      console.log(error.response)
      this.setState({
        errors: error.response.data.errors
      })
    })
  }

  compareBy(key) {
    if (key == 'category') {
      return function(a, b) {
        if (a.category.name < b.category.name) return -1
        if (a.category.name > b.category.name) return 1
        return 0
      }
    }
    return function(a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }

  duplicateItem = ( index) => {
    let { selected_items,positions,qty } = this.state
    const new_index = index + 1;
    console.log('indexindex',index,new_index,selected_items)
    const item = _.cloneDeep(selected_items[index])

    const selected_items_clone = _.cloneDeep(selected_items)
    const positions_clone = _.cloneDeep(positions)
    const qty_clone = _.cloneDeep(qty)
    const items = arrayInsert(selected_items_clone,new_index,item)
    const new_postions = arrayInsert(positions_clone,new_index,'')
    const new_qty = arrayInsert(qty_clone,new_index,1)

    this.setState({ selected_items:items.splice(0),positions:new_postions.splice(0),qty:new_qty.splice(0) })
  }

  

  isEqual = (item) => {
    let { search } = this.state

  }

  render() {
    let { selected_items } = this.state

    console.log('state', this.state)

    

    return (
      <>

        <Modal
          idModal="reviewPEItemsModal"
          size="modal-xl"
          title="Assign items"
          confirmText="Assign Items"  
          cancelText="Cancel"
          confirm={(e) => {
            const { sel_qty } = this.state
            let sel_pos = [];
            // let sel_qty = [];
            let other_pos = [];
            let other_qty = [];
            this.state.selected_items.map((item,index) => {
              sel_pos.push(this.props.selected_body_area) 
            })
            this.props.selected_items_filtered.map(item => {
              other_pos.push(item.body_area)
              other_qty.push(item.qty)
            })
            let merge_items = [...this.state.selected_items, ...this.props.selected_items_filtered];
            let merge_pos = [...sel_pos, ...other_pos ];
            let merge_qty = [...sel_qty, ...other_qty];
            // console.log('merge', merge_items);
            merge_items.map((mi, index) =>{
              console.log(`m-${index}`, mi.id, mi.body_area)
            })
            // return;
            this.validate(merge_items, merge_pos, merge_qty)
          }}
          cancel={(e) => {
            //fn here
          }}
          midButtonText="Back"
          midButtonAction={(e) => {
            $('#reviewPEItemsModal').modal('hide')
            $('#assignItemsModalCenter').modal('show')
          }}
          bodyClass="assign-items-body"
        >
          <div className="container">
          <div className="row">
            <div className="col-lg-12">
                <Table 
                  minWidth={890} className="modal-table infi-table"
                key={'review_postion-key-1'}
                renderModal={() => {
                  return (<ViewItemCard id={`aisrp-preview-modal-3121`} onRef={ref => (this.viewItemRef = ref)} item={this.state.selectedItem} ></ViewItemCard>)
                }}

                infiniteScroll
                infiniteHeight={window.innerHeight - 275}
                infiniteDataLength={10} //This is important field to render the next data
                infiniteNext={() => { }}
                infiniteHasMore={false}

                // endOfMessageTitle='Nothing else to see...'
                endOfMessageMessage="Nothing else to see..."
                // endOfMessageLinkMessage="Edit database"

                renderHeaders={() => {
                  return (
                    <TRow>
                      <THead className="w40" text="" /> 
                      <THead className="w150" text="Catalogue #" sortable onClick={() => this.sortSelectedBy('catalog_number')} />
                      <THead className="w200" text="Name" sortable onClick={() => this.sortSelectedBy('name')} />
                      <THead className="w300 d-none" text="Assign body category" sortable onClick={() => this.sortSelectedBy('category')} /> 
                      <THead className="w100" text="Qty" />
                    </TRow>
                  )
                }}
                renderRows={() => {
                  let si = selected_items.filter(item => item.body_area == this.props.selected_body_area)
                  let rows = si.map((item,index) => {
                    

                    return (
                      <TRow hasChoices key={`row-rp-${index}`} autoCheck={false}>
                        <TData className="w40" onClick={event => {

                          this.setState({
                            positions: this.state.positions.filter((d, i) => {
                              return i != index
                            }),
                            selected_items: this.state.selected_items.filter((d, i) => {
                              return i != index
                            }),
                            qty: this.state.qty.filter((d, i) => {
                              return i != index
                            }),
                          })
                        }} >
                          <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id={`checkbox-a1-${item.id}`} checked={true} />
                            <label className="custom-control-label" htmlFor={`checkbox-a1-${item.id}`} onClick={(e) => { e.preventDefault() }}></label>
                          </div>
                        </TData> 
                        <TData className="w150">{item.catalog_number}</TData>
                        <TData className="w200 has-action" item={item} popover onClick={(e) => {
                          e.stopPropagation()
                          this.setState({
                            selectedItem: item,
                            unique:
                              Math.random()
                                .toString(36)
                                .substring(2, 15) +
                              Math.random()
                                .toString(36)
                                .substring(2, 15),
                          })
                          $(`#aisrp-preview-modal-3121`).modal('show')
                        }}>{item.name}</TData>
                        <TData className="w300 d-none" onClick={e=>{e.stopPropagation()}}>
                          <div className="d-flex align-items-center">
                            <Picker
                              containerClass={`m0`}
                              onChange={event => {
                                const { positions } = this.state
                                positions[index] = event.target.value
                                this.setState({
                                  positions: positions.splice(0),
                                })
                              }}
                              value={this.props.selected_body_area}
                              data={this.props.body_areas}
                              errorMessage={
                                getArrayError(this.state.errors, 'areas', index) ? 'This field is required':''
                              }
                            />

                            <span
                              class="action-circle-btn float-left ml5 mt5 c-pointer"
                              onClick={() => {
                                this.duplicateItem(index)
                              }}
                            >
                              <i className="mdi mdi-plus"></i>
                            </span>
                          </div>
                        </TData> 
                        <TData className="w100" onClick={e=>e.stopPropagation()}>
                          <input
                            type="text"
                            name="search"
                            className="form-control text-center"
                            placeholder=""
                            value={this.state.sel_qty[index]}
                            onChange={event => {
                              if (/^[1-9][0-9]*$/.test($(event.target).val()) || !$(event.target).val().length) {     
                                const { sel_qty } = this.state
                                sel_qty[index] = event.target.value 
                                this.setState({ sel_qty: sel_qty.splice(0) })
                              }
                            }}
                            onBlur={(event) => {
                              if (!$(event.target).val().length) {
                                $(event.target).val(1)
                                const { sel_qty } = this.state
                                sel_qty[index] = 1
                                this.setState({ sel_qty: sel_qty.splice(0) })
                              }
                            }}
                          />
                        </TData>
                      </TRow>
                    )
                  })
                  return rows
                }}
              // renderNotification={() => {
              //     return (<TableNotificationCard type="success" message="Success message here..." />)
              // }}

              />

            </div>

          </div>
          </div>
        </Modal>
        
         </>
    )
  }
}

export default ReviewItemsPositioning
