import arrayMove from 'array-move'
import React, { Component } from 'react'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { getElementError } from '../../../utilities'
import { TextInput } from '../../commons/forms'
import Icon from '../icons/Icon'
import AddNewSection from './AddNewSection'
import MediaEditorItem from './MediaEditorItem'

const DragHandle = SortableHandle(() => {
  return (
    <a
      href="#"
      onClick={event => {
        event.preventDefault()
      }}
      className="move-icon"
    >
      <Icon icon="Move" className="negative" height={30} width={30} />
    </a>
  )
})

const SortableItem = SortableElement(
  ({
    item,
    i,
    setNewState,
    validation,
    onDeleteMediaElement,
    onContentTitleChange,
    onContentCaptionChange,
    onMediaChange,
    thumbnailChange,
    mentions,
    progressMedia,
    progressComplete,
  }) => (
    <MediaEditorItem
      mentions={mentions ? mentions : []}
      key={`media_key_${i}`}
      media_data={item.gallery ? item.gallery : item}
      onTitleChange={event => onContentTitleChange(event, i)}
      onCaptionChange={event => onContentCaptionChange(event, i)}
      validation={{ ...validation, field: 'media-title' }}
      index={i}
      onMediaChange={event => onMediaChange(event, i)}
      onDeleteMediaElement={onDeleteMediaElement}
      thumbnailChange={thumbnail => thumbnailChange(thumbnail, i)}
      progressMedia={progressMedia}
      progressComplete={progressComplete}
    />
  )
)

const SortableList = SortableContainer(
  ({
    items,
    setNewState,
    validation,
    onDeleteMediaElement,
    onContentTitleChange,
    onContentCaptionChange,
    onMediaChange,
    thumbnailChange,
    addNewMedia,
    mentions,
    progressMedia,
    progressComplete,
  }) => {
    return (
      <div className="card-body media-body">
        {items.map((item, index) => {
          return (
            <SortableItem
              key={`item-${index}`}
              i={index}
              index={index}
              item={item}
              setNewState={setNewState}
              validation={validation}
              onContentTitleChange={onContentTitleChange}
              onContentCaptionChange={onContentCaptionChange}
              onMediaChange={onMediaChange}
              onDeleteMediaElement={onDeleteMediaElement}
              thumbnailChange={thumbnailChange}
              mentions={mentions}
              progressMedia={progressMedia}
              progressComplete={progressComplete}
            />
          )
        })}

        <div className="row mb20">
          <div className="col-12">
            <AddNewSection
              title="Add another image / video +"
              single
              onClick={addNewMedia}
            />
          </div>
        </div>
      </div>
    )
  }
)

export class MediaEditor extends Component {
  constructor(props) {
    super(props)

    this.state = {
      unique: '1',
    }
  }
  componentDidMount() {
    this.setState({
      unique: this.makeid(5),
    })

    if(this.props.elementRef){
      this.props.elementRef(this.refs.element)
    }
  }

  makeid(length) {
    var result = ''
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }
  onSortEnd = ({ oldIndex, newIndex }) => {
    this.props.onContentSortChange(
      arrayMove(this.props.data.medias, oldIndex, newIndex)
    )
  }

  headerActions = () => {
    const { validation } = this.props

    return (
      <div className="card-header" ref='element'>
        <DragHandle />
        <a href="#" className="copy-icon" onClick={this.props.onDuplicate}>
          <Icon
            icon="Copy-2"
            className="negative"
            height={30}
            width={30}
            key={`copy-icon-${this.state.unique}`}
          />
        </a>
        <a href="#" className="trash-icon" onClick={this.props.onDelete}>
          <Icon
            icon="Delete"
            className="negative"
            height={30}
            width={30}
            key={`trash-icon-${this.state.unique}`}
          />
        </a>
      </div>
    )
  }
  titleBody() {
    const { validation } = this.props
    return (
      <div className="card-body">
        <div className="row pt4">
          <div className="col-lg-5 col-md-6">
            <TextInput
              value={this.props.data.title}
              placeholder={'Title here...'}
              label="Carousel title"
              errorMessage={
                getElementError(
                  validation.errors,
                  validation.prefix,
                  validation.index,
                  validation.field
                )
                  ? 'This field is required!'
                  : ''
              }
              errorClass="mt0"
              onValueChange={event => {
                this.props.onTitleChange(event)
              }}
              label={
                <>
                  Title <span className="text-danger ml4">*</span>
                </>
              }
              inputClass="has-val active show-placeholder"
              labelClass="bolder"
            />
          </div>
        </div>
      </div>
    )
  }

  getContainerElement = () => this.containerEl

  render() {
    const { data, validation } = this.props

    return (
      <div className="card media-editor" ref={el => (this.containerEl = el)}>
        {this.props.onTitleChange ? this.headerActions() : null}
        {this.props.onTitleChange ? this.titleBody() : null}

        <SortableList
          items={data.medias}
          setNewState={state => {
            this.setState(state)
          }}
          validation={this.props.validation}
          onContentTitleChange={this.props.onContentTitleChange}
          onContentCaptionChange={this.props.onContentCaptionChange}
          onMediaChange={this.props.onContentMediaChange}
          onDeleteMediaElement={this.props.onDeleteMediaElement}
          thumbnailChange={this.props.onContentThumbnailChange}
          pressDelay={100}
          onSortEnd={this.onSortEnd}
          useDragHandle
          addNewMedia={this.props.addNewMedia}
          lockToContainerEdges={false}
          disableAutoscroll={false}
          mentions={this.props.mentions ? this.props.mentions : []}
          progressMedia={this.props.progressMedia}
          progressComplete={this.props.progressComplete}
          helperContainer={this.getContainerElement}
        />
      </div>
    )
  }
}

export default MediaEditor
