

const INITIAL_STATE = {
  progress: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "PROGRESS_MEDIA_UPLOAD":
      return { ...state, progress: [...state.progress,1] }
    default:
      return state
  }
}
