import React, { Component } from 'react'
import $ from 'jquery'
export class Tabs extends Component {

  componentDidMount() {
    
    var total=0;

    $('.fadeAwayTabs .nav-item').each(e =>{
      console.log('e', $($(`.fadeAwayTabs .nav-item`)[e]))
      total += $($(`.fadeAwayTabs .nav-item`)[e])[0].scrollWidth;
    }) 
    $('.fadeAwayTabs .nav-tabs').css('width', `${total}px`); 

    $('.prev').addClass('d-none')

    if ($('.fadeAwayTabs').width() >= total){ 
      $('.next').addClass('d-none')
    }

    $('.next').click(e => {
      let w = $('.fadeAwayTabs .c-pos').width(); 
      $('.fadeAwayTabs').animate({ scrollLeft: `+=${w+5}px` }, 150);
      $('.fadeAwayTabs .c-pos').removeClass('c-pos').next().addClass('c-pos') 

    })

    $('.prev').click(e => {
      let w = $('.fadeAwayTabs .c-pos').width();
      $('.fadeAwayTabs').animate({ scrollLeft: `-=${w}px` }, 150);
      $('.fadeAwayTabs .c-pos').removeClass('c-pos').prev().addClass('c-pos') 

      if ($('.fadeAwayTabs .c-pos').is(':first-child')) {
        $('.prev').addClass('d-none')
      } else {
        $('.prev').removeClass('d-none')
      }

      if (!$('.fadeAwayTabs .c-pos').is(':last-child')) {
        $('.next').removeClass('d-none')
      } else {
        $('.next').addClass('d-none')
      }
    })

    $('.fadeAwayTabs').scroll(function () {
      console.log('scrolled', $('.fadeAwayTabs')[0].scrollLeft, $('.corh-nav-tabs')[0].clientWidth, $('.fadeAwayTabs')[0].clientWidth, $('.fadeAwayTabs')[0].scrollLeft >= $('.corh-nav-tabs')[0].clientWidth - $('.fadeAwayTabs')[0].clientWidth)

      // Math.floor($('.corh-nav-tabs')[0].clientWidth - $('.fadeAwayTabs')[0].clientWidth) <= (Math.floor($('.fadeAwayTabs')[0].scrollLeft))
      if ($('.fadeAwayTabs')[0].scrollLeft > 0) {
        $('.prev').removeClass('d-none')
      } else {
        $('.prev').addClass('d-none')
      }
       
      if ($('.fadeAwayTabs')[0].scrollLeft >= $('.corh-nav-tabs')[0].clientWidth - $('.fadeAwayTabs')[0].clientWidth) {
        $('.next').addClass('d-none')
      } else {
        $('.next').removeClass('d-none')
      }

    })
  }



  moveRight(){

  }

  moveLeft(){

  }


  render() {
    let items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 8, 9, 0, 8, 9, 0]
    return (
      <div className="row">
        <div className="col-md-12">
          <h1>TABS</h1>
          <div className="w-100 fadeAway-container">
            <div className="btns prev"><i className="mdi mdi-chevron-left"></i></div>
            <div className="btns next"><i className="mdi mdi-chevron-right"></i></div> 
            <div className="card fadeAwayTabs"> 
              <ul className="nav nav-tabs corh-nav-tabs">
                <li className="nav-item active c-pos">
                  <a className="nav-link" href="#">Active</a>
                </li>
                {
                  items.map((item)=>{
                    return (
                      <li className="nav-item">
                        <a className="nav-link" href="#">
                          <span className="icon"></span>
                          Sample text {item}
                        </a>
                      </li> 
                    )
                  })
                }
              </ul>


            </div> 
          </div>
        </div>
      </div>
    )
  }
}

export default Tabs
