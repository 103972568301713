import axios from 'axios'
import $ from 'jquery'
import React, { Component } from 'react'
import Pagination from 'react-js-pagination'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { getError } from '../utilities'
import { NotificationCard } from './commons/cards'
import ViewItemCard from './commons/cards/ViewItemCard'
import { Button, Picker, TextInput } from './commons/forms'
import Icon from './commons/icons/Icon'
import { ConfirmationModal, Modal } from './commons/modals'
import Notifier from './commons/notifier/Notifier'
import Collapsible from './commons/panel/Collapsible'
import Text from './commons/texts/Text'
import { hideLoader, setLoaderProg, showLoader } from './loader/loading_screen'
import LoadFromModalStandard from './modals/LoadFromModalStandard'
import TrayItemModal from './modals/TrayItemModal'


const SortableItem = SortableElement(({ category, setNewState }) => (
  <li className="draggable-item mb12 procedure-settings">
    <div className="icon-holder">
      <Icon icon="move-icon" height={28} width={15} className="negative" />
    </div>
    <span className="pl10 work-break-all">
      {/* <img
        src={
          category.stage.icon ? category.stage.icon.replace('public', '') : ''
        }
        className=""
        alt="..."
      /> */}
      {category.stage.name}
    </span>
    <div className="action-holder d-flex">
      <Button
        tertiary
        datatoggle="modal"
        className="mr0"
        datatarget="#updateStageModal"
        onClick={e => {
          e.preventDefault()
          setNewState({
            stage_data: category,
            stage_name: category.stage.name,
            stage_type: category.type,
            errors: [],
            icon: {
              icon: category.stage.icon,
            },
          })
        }}
      >
        <i className="mdi mdi-square-edit-outline fs20"></i>
      </Button>
      <Button
        tertiary
        datatoggle="modal"
        className="mr4"
        datatarget="#confirmationModal"
        onClick={e => {
          e.preventDefault()
          setNewState({
            stage_data: category,
          })
        }}
      >
        <i className="mdi mdi-trash-can-outline fs20"></i>
      </Button>
    </div>
  </li>
))

const SortableList = SortableContainer(({ categories, setNewState }) => {
  let lists = categories.map((category, index) => {
    return (
      <SortableItem
        key={`item-${index}`}
        index={index}
        category={category}
        setNewState={setNewState}
      />
    )
  })

  return <ul className="list-unstyled mb-0">{lists}</ul>
})

export class ProcedureSettings extends Component {
  constructor(props) {
    super(props)

    this.state = {
      icons: [],
      icon: {},
      stage_data: {},
      stage_type: 'standard',
      stage_name: '',
      stage_types: [
        // { id: '', name: 'Please Select' },
        { id: 'standard', name: 'Standard' },
      ],
      rearrange: false,
      errors: [],
      showNotification: false,
      notificationMessage: '',
      notifications: [],
      notifications_meta: [],
      page: 1,
      selectedItem: [],
      key: this.makeid(10),
      tray_unique:
                      Math.random()
                        .toString(36)
                        .substring(2, 15) +
                      Math.random()
                        .toString(36)
                        .substring(2, 15),
      tray:{},
      savingStage: false,
    }
    this.toggleShow = this.toggleShow.bind(this)
  }
  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  toggleShow(val, message = '') {
    this.setState({
      showNotification: val,
      notificationMessage: message,
    })
  }

  componentDidMount = () => {
   this.getIcons()
  }

  setNewState = states => {
    this.setState(states)
  }

  getIcons = () => {
    let url = `/api/icon-library`
  //  axios.get(`api/client/client-stage-common-items?surgeon_id=${this.props.surgeon.id}&procedure_id=${this.props.procedure_id}`)
    axios
      .get(url)
      .then(res => {
        this.setState({
          icons: res.data.data,
        })
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
      })
  }

  saveStage = () => {
    this.setState({
      savingStage:true
    })
    const { icon, stage_name, stage_type } = this.state
    const { surgeon, procedure } = this.props
   
    let data = new FormData()
    data.append('procedure_id', procedure.id)
    data.append('surgeon_id', surgeon.id)
    data.append('name', stage_name)
    if(icon && icon.source){
      data.append('icon', icon.source)
    }
    data.append('type', stage_type)

    let url = '/api/client/custom-stage'

    axios
      .post(url, data)
      .then(res => {
        this.props.getCustomStages(procedure.id, 1)
        this.props.changeTabsKey()
        $('#addStageModal').modal('hide')
        this.setState({
          savingStage:false
        })
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
        this.setState({
          errors: error.response.data.errors,
          savingStage:false
        })
      })
  }

  updateStage = () => {
    const { icon, stage_name, stage_type, stage_data } = this.state
    const { procedure } = this.props

    let data = new FormData()
    data.append('procedure_id', procedure.id)
    data.append('name', stage_name)
    if (icon.source) {
      data.append('icon', icon.source)
    }
    data.append('type', stage_type)
    data.append('_method', 'PATCH')

    let url = `/api/client/custom-stage/${stage_data.id}`

    axios
      .post(url, data)
      .then(res => {
        this.props.getCustomStages(procedure.id, 1)
        $('#updateStageModal').modal('hide')
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
        this.setState({
          errors: error.response.data.errors,
        })
      })
  }

  deleteStage = () => {
    const { stage_data } = this.state
    const { procedure } = this.props

    let url = `/api/client/custom-stage/${stage_data.id}`

    axios
      .post(url, { _method: 'DELETE' })
      .then(res => {
        this.props.getCustomStages(procedure.id, 1)
        this.props.changeTabsKey()
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
      })
  }

  newStageModal = () => {
    return (
      <>
        <Modal
          idModal="addStageModal"
          size="modal-sm"
          title="Add new stage"
          confirmText="Add stage"
          confirmDisabled={this.state.stage_type == '' || this.state.savingStage}
          cancelText="Cancel"
          bodyClass="add-glove-modal-body"
          confirm={e => {
            this.saveStage()
          }}
          cancel={e => {
            //fn here
          }}
        >

          <div className="form-group mt40">
            <TextInput
              label={'Name new stage'}
              value={this.state.stage_name}
              placeholder={'Name'}
              errorMessage={getError(this.state.errors, 'name')}
              containerClass={`mb20`}
              onValueChange={event => {
                this.setState({ stage_name: event.target.value })
              }}
            />
          </div>
           
        </Modal>
        <div
          className="modal fade"
          id="addStageModal3"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="addStageModalTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-xs"
            role="document"
          >
            <div className="modal-content bg-light">
              <div className="modal-body pt30 px30 pb0">
                <h3 className="m-0 mx-auto font-weight-bold pb0 fs20 mb0">
                  Add new stage
                </h3>
              </div>
              <div className="modal-body px30 pt-0 pb0">
                <div className="form-group mb20">
                  <label
                    className="w-100 float-left d-flex mb20"
                    data-toggle="collapse"
                    data-target="#collapseInfo"
                  >
                    Assign stage type
                    <i className="mdi mdi-information ml-auto" />
                  </label>
                  <div className="w-100 float-left">
                    <div className="collapse" id="collapseInfo">
                      <h6 className="mb10"> Stage types: </h6>
                      <h6 className="mb10"> Standard </h6>
                      <p className="mb20">
                        {' '}
                        Contains item list and media and text elements used for
                        a majority of standard phase formats{' '}
                      </p>
                      <h6 className="mb10"> Positioning </h6>
                      <p className="mb20">
                        {' '}
                        Additional positioning phase if required by procedure.
                        (An additional Positioning equipment phase is also
                        added){' '}
                      </p>
                      <h6 className="mb10"> Equipment / Room setup</h6>
                      <p className="mb30">
                        {' '}
                        Additional room setup if required by procedure{' '}
                      </p>
                    </div>
                  </div>
                  <Picker
                    onChange={event => {
                      this.setState({ stage_type: event.target.value })
                    }}
                    value={this.state.stage_type}
                    data={this.state.stage_types}
                    errorMessage={getError(this.state.errors, 'type')}
                  />
                </div>
                {this.state.stage_type != '' ? (
                  <div>
                    <div className="row">
                      <div className="col-md-12">
                        <div
                          className="float-left d-flex justify-content-center align-items-center fs18 flex-column text-center mr30 mb20 c-pointer"
                          data-toggle="modal"
                          data-target="#selectStageIconModal"
                          style={{
                            width: '120px',
                            height: '120px',
                            border: '2px dashed #979797',
                          }}
                        >
                          {this.state.icon ? (
                            <img
                              src={this.state.icon.icon}
                              className="w-100 p5"
                            />
                          ) : (
                            'Assign icon'
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb30">
                      <TextInput
                        label={'Name new stage'}
                        value={this.state.stage_name}
                        placeholder={'Name'}
                        errorMessage={getError(this.state.errors, 'name')}
                        containerClass={`mb30`}
                        onValueChange={event => {
                          this.setState({ stage_name: event.target.value })
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  ''
                )}

                <div className="row">
                  <div className="col-md-12 text-center">
                    {this.state.stage_type != '' ? (
                      <div
                        className="btn btn-primary mr20"
                        onClick={() => {
                          this.saveStage()
                        }}
                      >
                        Add stage
                      </div>
                    ) : (
                      ''
                    )}
                    <div
                      className="btn btn-outline-dark m-xst20"
                      data-dismiss="modal"
                    >
                      Cancel
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  updateStageModal = () => {
    return (
      <>
        <Modal
          idModal="updateStageModal"
          size="modal-sm"
          title="Update stage"
          confirmText="Update"
          cancelText="Cancel"
          bodyClass="add-glove-modal-body"
          confirm={e => {
            this.updateStage()
          }}
          cancel={e => {
            //fn here
          }}
        >
          <div className="form-group mt40">
            <TextInput
              label={'Name new stage'}
              value={this.state.stage_name}
              placeholder={'Name'}
              errorMessage={getError(this.state.errors, 'name')}
              containerClass={`mb20`}
              onValueChange={event => {
                this.setState({ stage_name: event.target.value })
              }}
            />
          </div>
        </Modal>
        <div
          className="modal fade"
          id="updateStageModal2"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="updateStageModalTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-xs"
            role="document"
          >
            <div className="modal-content bg-light">
              <div className="modal-body pt30 px30 pb0">
                <h3 className="m-0 mx-auto font-weight-bold pb10 fs20">
                  Update stage
                </h3>
              </div>
              <div className="modal-body px30 pt-0 pb30">
                <div className="form-group mb20">
                  <label className="w-100 float-left">
                    Assign stage type
                    <i className="mdi mdi-information ml-auto" />
                  </label>
                  <Picker
                    onChange={event => {
                      this.setState({ stage_type: event.target.value })
                    }}
                    value={this.state.stage_type}
                    data={this.state.stage_types}
                    errorMessage={getError(this.state.errors, 'type')}
                  />
                </div>
                {this.state.stage_type != '' ? (
                  <div>
                    <div className="row">
                      <div className="col-md-12">
                        <div
                          className="float-left d-flex justify-content-center align-items-center fs18 flex-column text-center mr30 mb20 c-pointer"
                          data-toggle="modal"
                          data-target="#selectStageIconModal"
                          style={{
                            width: '120px',
                            height: '120px',
                            border: '2px dashed #979797',
                          }}
                        >
                          {this.state.icon.icon ? (
                            <img src={this.state.icon.icon} className="w-100" />
                          ) : (
                            'Assign icon'
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb30">
                      <TextInput
                        label={'Name new stage'}
                        value={this.state.stage_name}
                        placeholder={'Name'}
                        errorMessage={getError(this.state.errors, 'name')}
                        containerClass={`mb30`}
                        onValueChange={event => {
                          this.setState({ stage_name: event.target.value })
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  ''
                )}
                <div className="row">
                  <div className="col-md-12 text-center">
                    {this.state.stage_type != '' ? (
                      <div
                        className="btn btn-primary mr20"
                        onClick={() => {
                          this.updateStage()
                        }}
                      >
                        Update stage
                      </div>
                    ) : (
                      ''
                    )}
                    <div
                      className="btn btn-outline-dark m-xst20"
                      data-dismiss="modal"
                    >
                      Cancel
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  iconModal = () => {
    let iconlist = this.state.icons.map(icon => {
      return (
        <img
          src={icon.icon}
          className="bg-dark mb30 mr30 h120 w120 c-pointer"
          data-dismiss="modal"
          onClick={() => {
            this.setState({ icon: icon })
          }}
        />
      )
    })
    return (
      <Modal
        idModal="selectStageIconModal"
        size="modal-lg"
        title="Select Icon"
        cancelText="Cancel" 
      >
        <div
          className="col-md-12 d-flex flex-wrap px0 mb30"
          style={{ height: '370px', overflow: 'auto' }}
        >
          {iconlist}
        </div>
      </Modal>
    )
  }

  loadExistingProcedureContent() {
    return (
      <div>
        <p className="mb15">
          You can save time by loading an existing procedure
        </p>
        <div className="d-flex">
          <Button
            secondary
            onClick={() => {
              this.setState(
                {
                  key_load:
                    Math.random()
                      .toString(36)
                      .substring(2, 15) +
                    Math.random()
                      .toString(36)
                      .substring(2, 15),
                },
                () => {
                  $('#loadFromModalCenter').modal('show')
                }
              )
            }}
          >
            Load procedure from...
          </Button>
        </div>
      </div>
    )
  }
  handlePageChange = pageNumber => {
    this.setState(
      {
        page: pageNumber,
      },
      () => {
        this.searchData()
        // window.scrollTo({
        //   top: 0,
        //   left: 0,
        // })
      }
    )
    console.log(`active page is ${pageNumber}`)
  }
  searchData = () => {
    let pages = ''
    if (this.state.active != 'all') {
      pages = '&paginate=5'
    }
    let url = `/api/client/setting-notification?surgeon_id=${this.props.surgeon.id}&procedure_id=${this.props.procedure_id}&page=${this.state.page}`
    axios.get(`api/client/client-stage-common-item?surgeon_id=${this.props.surgeon.id}&procedure_id=95`).then( result => {
      console.log('resssss',result)
    })

    this.searchNotifData(url)
  }
  searchNotifData(url) {
    let config = {
      onDownloadProgress: progressEvent => {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        showLoader(percentCompleted >= 95 ? 95 : percentCompleted)
        setLoaderProg(percentCompleted >= 95 ? 95 : percentCompleted)
      },
    }
    axios
      .get(url, config)
      .then(res => {
        this.setState({
          notifications: res.data.data,
          notifications_meta: res.data,
        })
        setLoaderProg(99)
        setTimeout(() => {
          hideLoader()
        }, 800)
      })
      .catch(err => {
        console.log('popop', err)
        console.log('popop', err.response)
        setLoaderProg(99)
        setTimeout(() => {
          hideLoader()
        }, 800)
      })
  }
  componentWillReceiveProps(nextProps) {
    if (this.props != nextProps) {
      this.setState({
        notifications_meta: nextProps.notifications_meta,
        notifications: nextProps.notifications,
      })
    }
  }

  editLogsContent = () => {
    const { notifications } = this.state

    let notification_list = notifications.map(notification => {
      const changes = notification.changes.map(change => {
 
        const new_items = change.new_items.map((item,index) => {
          console.log('itemitemitemitemitemitem',item)
          return <li><Text className="cta cta-tertiary wmax-content" item_id={item.id} setSelectedItem={(sel_item)=>{ 
          this.setState({
            selectedItem: sel_item,
            key: this.makeid(10)
          })
        }} onClick={()=>{
       
          if(change.key_new && change.key_new.length > 0){
                  this.setState(
                  {
                    tray: {
                      id: change.key_new[index],
                      name: item,
                    },
                    tray_unique:
                      Math.random()
                        .toString(36)
                        .substring(2, 15) +
                      Math.random()
                        .toString(36)
                        .substring(2, 15),
                  },
                  () => {
                    $('#openTrayDetail').modal('show')
                  }
                )
                }else{
                 $("#preview-modal-notification").modal('show')
                }
        }} >{change.name == 'Tray' ? item:item.name}</Text></li>
        })

        const remove_items = change.remove_items.map((item,index) => {
          return <li><Text className="cta cta-tertiary wmax-content" item_id={item.id} setSelectedItem={(sel_item)=>{
          this.setState({
            selectedItem: sel_item,
            key: this.makeid(10)
          })
        }} onClick={()=>{
          if(change.key_remove && change.key_remove.length > 0){
                  this.setState(
                  {
                    tray: {
                      id: change.key_remove[index],
                      name: item,
                    },
                    tray_unique:
                      Math.random()
                        .toString(36)
                        .substring(2, 15) +
                      Math.random()
                        .toString(36)
                        .substring(2, 15),
                  },
                  () => {
                    $('#openTrayDetail').modal('show')
                  }
                )
                }else{

                  $("#preview-modal-notification").modal('show')
                }
        }} >{change.name == 'Tray' ? item:item.name}</Text></li>
        })
        return (

          <p>
            <b>{change.name}</b><br />

            {
              change.new_items ? change.new_items.length > 0 ?
              <span>
                {change.name == 'Tray' ? (<small>Added</small>):(<small>Added item{change.new_items ? change.new_items.length > 1 ? 's' : '' : ''}</small>) }
                <ul className="list-unstyled" style={{ listStyleType: 'disc', textIndent: '1.3em' }}>
                  {new_items}
                </ul>
                <br />
              </span> : '' : ''
            }
            {change.remove_items ? change.remove_items.length > 0 ?
              <span>
              {change.name == 'Tray' ? (<small>Removed</small>):(<small>Removed item{change.remove_items ? change.remove_items.length > 1 ? 's' : '' : ''}</small>)}
                <ul className="list-unstyled" style={{ listStyleType: 'disc', textIndent: '1.3em' }}>
                  {remove_items}
                </ul>
              </span> : '' : ''
            }
          </p> 
        )
      })
      return (
        <NotificationCard
          image={notification.by_user.avatar}
          title={`${notification.by_user.firstname} ${notification.by_user.lastname}`}
          date={notification.date}
        >
          <p className="notification-desc">
            {notification.action_type} - {notification.data}
          </p>
          {notification.changes && notification.changes.length > 0 ? changes:''}
        </NotificationCard>
      )
    })

    return (
      <div>
        <ul className="list-unstyled">{notification_list}</ul>

        <Pagination
          activePage={this.state.page}
          itemsCountPerPage={
            this.state.notifications_meta
              ? this.state.notifications_meta.meta
                ? this.state.notifications_meta.meta.per_page
                : 0
              : 0
          }
          totalItemsCount={
            this.state.notifications_meta
              ? this.state.notifications_meta.meta
                ? this.state.notifications_meta.meta.total
                : 0
              : 0
          }
          pageRangeDisplayed={
            this.state.notifications_meta
              ? this.state.notifications_meta.meta
                ? this.state.notifications_meta.meta.per_page
                : 0
              : 0
          }
          onChange={this.handlePageChange.bind(this)}
          hideDisabled={true}
          hideFirstLastPages={true}
          linkClassPrev="prev"
          linkClassNext="next"
          prevPageText={<i className="mdi mdi-chevron-left"></i>}
          nextPageText={<i className="mdi mdi-chevron-right"></i>}
        />
      </div>
    )
  }

  deleteProcedure = () => {
    return (
      <div>
        <p className="mb15">
          If for any reason you need to remove this procedure, you can do so
          here.
        </p>

        <div className="d-flex">
          <Button
            tertiary
            onClick={() => {
              $('#deleteProcedureModal').modal('show')
            }}
          >
            I would like to delete this procedure.
          </Button>
        </div>
      </div>
    )
  }

  stagesContent() {
    let firstlist = this.props.surgical_tree_first.map((item, index) => {
      return (
        <li
          className="draggable-item mb12 procedure-settings"
          key={`item-${index}`}
        >
          <div className="icon-holder"></div>
          <span className="pl10 work-break-all">
            {/* <img src={item.icon.replace('public', '')} className="" alt="..." /> */}
            {item.name}
          </span>
        </li>
      )
    })

    let customlist = this.props.custom_stages.map(category => {
      return (
        <li className="draggable-item mb12 procedure-settings">
          <div className="icon-holder"></div>
          <span className="pl10 work-break-all">
            {/* <img
              src={
                category.stage.icon
                  ? category.stage.icon.replace('public', '')
                  : ''
              }
              className=""
              alt="..."
            /> */}
            {category.stage.name}
          </span>
          <div className="action-holder d-flex">
            <Button
              tertiary
              datatoggle="modal"
              className="mr0"
              datatarget="#updateStageModal"
              onClick={e => {
                e.preventDefault()
                this.setState({
                  stage_data: category,
                  stage_name: category.stage.name,
                  stage_type: category.type,
                  errors: [],
                  icon: {
                    icon: category.stage.icon,
                  },
                })
              }}
            >
              <i className="mdi mdi-square-edit-outline fs20"></i>
            </Button>
            <Button
              tertiary
              datatoggle="modal"
              className="mr4"
              datatarget="#confirmationModal"
              onClick={e => {
                e.preventDefault()
                this.setState({
                  stage_data: category,
                })
              }}
            >
              <i className="mdi mdi-trash-can-outline fs20"></i>
            </Button>
          </div>
        </li>
      )
    })

    let secondlist = this.props.surgical_tree_second.map(item => {
      return (
        <li
          className="draggable-item mb12 procedure-settings"
          key={`item-sl-${item.id}`}
        >
          <div className="icon-holder"></div>
          <span className="pl10 work-break-all">
            {/* <img src={item.icon.replace('public', '')} className="" alt="..." /> */}
            {item.name}
          </span>
        </li>
      )
    })

    return (
      <div>
        <p className="my15">
          If the procedure requires, you can add new stages after draping to fit
          your style.
        </p>
        {this.state.showNotification ? (
          <div className="row">
            <div className="col-md-12">
              {/* <Notifier show={true} message={this.state.notificationMessage} type="success" styleClass="mb20" toggleShow={this.toggleShow} /> */}

              <Notifier
                show={true}
                message={this.state.notificationMessage}
                onRef={ref => (this.refNotifier2 = ref)}
                type="success"
                styleClass="mb20"
                hideThisNotif={() => {
                  this.refNotifier2.hideThis()
                }}
              />
            </div>
          </div>
        ) : null}
        <ul className="list-unstyled">
          {firstlist}

          {this.state.rearrange ? (
            <SortableList
              categories={this.props.custom_stages}
              onSortEnd={this.props.onSortEnd}
              pressDelay={50}
              setNewState={this.setNewState}
              lockToContainerEdges={false}
              useWindowAsScrollContainer={true}
            />
          ) : (
            customlist
          )}

          <li className="add-new-section-list">
            <ul className="list-unstyled">
              <li
                className="list-item add-new-secion"
                data-toggle="modal"
                data-target="#addStageModal"
                onClick={() => {
                  this.setState({ icon: '', stage_name: '', stage_type: 'standard' })
                }}
              >
                + Add new stage
              </li>
            </ul>
          </li>
          {secondlist}
          {this.state.rearrange ? (
            <div className="d-flex pt12">
              <Button
                secondary
                onClick={() => {
                  this.props.saveSorting()
                  this.setState({
                    rearrange: false,
                    showNotification: true,
                    notificationMessage: 'Order updated!',
                  })
                }}
              >
                Update arrangement
              </Button>
              {/* <Button
                tertiary
                onClick={() => {
                  this.setState({ rearrange: false })
                }}
              >
                Cancel Arrangement
              </Button> */}
            </div>
          ) : (
            <div className="d-flex pt12">
              <Button
                secondary
                onClick={() => {
                  this.setState({ rearrange: true })
                }}
              >
                Arrange stage
              </Button>
            </div>
          )}
        </ul>
      </div>
    )
  }
  render() {
    return (
      <div className="container px0">

        <ViewItemCard id={`preview-modal-procedure-settings`} onRef={ref => (this.viewItemRef = ref)} item={this.state.selectedItem} key={this.state.key} ></ViewItemCard>
          <TrayItemModal key={this.state.tray_unique} tray={this.state.tray} />

        <div className="row mt32 mb20" id="settings">
          <div className="col-md-12 mx-auto">
            <h2 className="page-sub-title">Settings</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Collapsible
              className="mb20"
              title="Procedure edit logs"
              key={'settings1'}
              open
            >
              {this.editLogsContent()}
            </Collapsible>
          </div>

          <div className="col-md-12">
            <Collapsible
              className="mb20"
              title="Load procedure"
              key={'settings2'}
            >
              {this.loadExistingProcedureContent()}
            </Collapsible>
          </div>

          <div className="col-md-12">
            <Collapsible
              className="mb20"
              title="Add new stages"
              key={'settings3'}
            >
              {this.stagesContent()}
            </Collapsible>
          </div>

          <div className="col-md-12">
            <Collapsible
              className="mb20"
              title="Delete procedure"
              key={'settings4'}
            >
              {this.deleteProcedure()}
            </Collapsible>
          </div>
        </div>
        
        <LoadFromModalStandard
          key={this.props.quicknotes_id}
          surgeon={this.props.surgeon}
          procedure_id={this.props.procedure_id}
          getProcedureDetail={this.props.getProcedureDetail}
          loadSurgeonProcedure={id => {
            this.props.loadSurgeonProcedure(id)
          }}
          loadFrom={() => {
            this.setState({
              loadFrom: true,
            })
          }}
        />

        {this.newStageModal()}
        {this.updateStageModal()}
        {this.iconModal()}

        <ConfirmationModal
          title="Delete"
          message="Are you sure you want to delete?"
          confirmText="Yes"
          cancelText="No"
          confirm={() => {
            this.deleteStage()
          }}
          cancel={() => {
            $('#confirmationModal').modal('hide')
          }}
        />

        <ConfirmationModal
          idModal="deleteProcedureModal"
          title="Delete procedure"
          message="Deleting this procedure will remove if from the database. The procedure and it's phase will then to be added manually."
          confirmText="Yes"
          cancelText="No"
          confirm={() => {
            this.props.deleteProcedure()
          }}
          cancel={() => {
            $('#deleteProcedureModal').modal('hide')
          }}
        />
        <ViewItemCard id={`preview-modal-notification`} onRef={ref => (this.viewItemRef12 = ref)} item={this.state.selectedItem} ></ViewItemCard>
      </div>
    )
  }
}

export default ProcedureSettings
