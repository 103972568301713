import React, { Component } from 'react'

import { connect } from 'react-redux'
import { positioningUpdate } from '../../actions'
import MediaEditor from '../commons/media_elements/MediaEditor'
import _ from 'lodash'

class Medias extends Component {
  titleChange = event => {
    const value = event.target.value
    this.props.positioningUpdate({ prop: 'media_title', value })
  }

  onContentTitleChange = (event, index) => {
    const { media } = this.props

    media[index].title = event.target.value

    const value = _.cloneDeep(media)

    this.props.positioningUpdate({ prop: 'media', value })
  }

  deleteMediaElement = (event, index, subIndex) => {
    event.preventDefault()
    const { media } = this.props

    media.splice(subIndex, 1)

    const value = _.cloneDeep(media)

    this.props.positioningUpdate({ prop: 'media', value })
  }

  onContentCaptionChange = (event, index) => {
    const { media } = this.props

    media[index].caption = event.editor.getData()

    const value = _.cloneDeep(media)

    this.props.positioningUpdate({ prop: 'media', value })
  }

  onContentMediaChange = (event, index) => {
    const { media } = this.props

    media[index].source = event.data
    media[index].type = event.type
    if (event.type == 'image') media[index].thumbnail = event.data
    const value = _.cloneDeep(media)

    this.props.positioningUpdate({ prop: 'media', value })
  }

  onContentThumbnailChange = (event, index) => {
    const { media } = this.props
    media[index].thumbnail = event
    const value = _.cloneDeep(media)

    this.props.positioningUpdate({ prop: 'media', value })
  }

  addNewMedia = event => {
    event.preventDefault()

    const { media } = this.props
    media.push({
      show: true,
      thumbnail: null,
      source: null,
      caption: null,
      title: null,
      id: 0,
      show: true,
    })
    const value = _.cloneDeep(media)

    this.props.positioningUpdate({ prop: 'media', value })
  }

  duplicateElement = event => {
    console.log('test dups')
  }

  deleteElement = (event, index) => {
    event.preventDefault()

    const { media } = this.props
    media[index].show = false
    const value = _.cloneDeep(media)
    this.props.positioningUpdate({ prop: 'media', value })
  }
  render() {
    const { media, media_title } = this.props
    return (
      <div className="row">
        <div className="col-md-12 media-editor mb0">
          <MediaEditor
            className="mb0"
            data={{
              title: media_title,
              medias: media,
            }}
            validation={this.props.validation}
            extracted={true}
            // onTitleChange={event => {
            //   this.titleChange(event)
            // }}
            onContentTitleChange={(event, i) => {
              this.onContentTitleChange(event, i)
            }}
            onContentCaptionChange={(event, i) => {
              this.onContentCaptionChange(event, i)
            }}
            onContentMediaChange={(event, i) => {
              this.onContentMediaChange(event, i)
            }}
            onContentThumbnailChange={(event, i) => {
              this.onContentThumbnailChange(event, i)
            }}
            addNewMedia={event => this.addNewMedia(event)}
            onDuplicate={event => this.duplicateElement(event)}
            onDeleteElement={(event, index) => this.deleteElement(event, index)}
            onDeleteMediaElement={this.deleteMediaElement}
            progressMedia={() => {
              this.props.progressMedia()
            }}
            progressComplete={() => {
              this.props.progressComplete()

            
            }}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ positioning }) => {
  const { media, media_title } = positioning
  return { media, media_title }
}

export default connect(
  mapStateToProps,
  { positioningUpdate }
)(Medias)
