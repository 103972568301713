import React, { Component } from 'react'
import {
	Elements
} from 'react-stripe-elements'
import { Modal } from '../commons/modals';
import ChangeCardForm from '../commons/forms/ChangeCardForm';

export class CardChangeModal extends Component {
	render() {
		return (
			<div>
				<Modal idModal="changeCardModal" size="modal-full" className="upgrade-plan-modal" noFooter >
					<div className="container h-100">
						<div className="row h-100">
								<div className="col-lg-6 col-md-8 offset-lg-6 offset-md-2 payment-form text-center">
								<h1 className="title">Change Card Information!</h1>
								<p className="desc">
									Your now about to change card information
								</p>
								<Elements>
									<ChangeCardForm name={''} onSuccess={() => this.props.updateInfo()} />
								</Elements>
							</div>
						</div>
					</div>
				</Modal>
				 

				<Modal idModal="changeSuccess" size="modal-full" className="thank-you-modal" noFooter >
					<div className="container h-100">
						<div className="row h-100">
							<div className="col-lg-8 text-center thank-you">
								<h1 className="title">Thanks - on behalf of the team at Global OR</h1>
								<p className="desc">
									By subscribing you are helping us develop future tools that will build and support surgical <br />
									OR teams and a new generation of scrubbers. Most of all it will make your work easier!
								</p> 
							</div>
						</div>
					</div>
				</Modal>

			</div>
		)
	}
}

export default CardChangeModal
