import React, { Component } from 'react'

export class ValidationMessage extends Component {
  render() {

    const { customClass, type, message } = this.props;

    let _class = 'mdi mdi-information'
    if (type == 'success' || type == 'valid') {
      _class = 'mdi mdi-check-circle valid'
    } else if (type == 'error' || type == 'invalid') {
      _class = 'mdi mdi-information mdi-rotate-180 invalid'
    } else if (type == 'warning') {
      _class = 'mdi mdi-information warning'
    }
    return (
      <span className={`validation-message ${type}`}>
        <i className={`${_class} ${customClass} ${type} mr8`}></i>
        <span className={` ${type}`}>
          {message}
        </span>
      </span>
    )
  }
}

export default ValidationMessage
