import React, { Component } from 'react'
import Icon from '../icons/Icon';

export class AddNewSection extends Component {
  render() {
    let default_title = 'Add a new section:'

    const { title, single } = this.props

    return (
      <div className={`card add-section ${single ? 'single':''}`} onClick={this.props.onClick}>
        <div className="card-header">{title ? title : default_title}</div>
        {
          single ? null:
            <div className="card-body">
              <ul className="list-unstyled">
                <li>
                  <a href="javascript:;" onClick={this.props.addTextOnly}>
                    <Icon icon="text-only" width={25} height={25} /> Text only
                  </a>
                </li>
                <li>
                  <a href="javascript:;" onClick={this.props.addMediaGallery}>
                    <Icon icon="media-gallery" width={25} height={25} /> Media gallery
                  </a>
                </li>
                <li>
                  <a href="javascript:;" onClick={this.props.addTextAndMedia}>
                    <Icon icon="text-and-media" width={25} height={25} /> Text and media
                  </a>
                </li>
              </ul>
            </div>
        }
      </div>
    )
  }
}

export default AddNewSection
