import React, { Component } from 'react'

import Cards from './Cards';
import Forms from './Forms';
import Data from './Data';
import Modals from './Modals';
import Tabs from './Tabs';
import Icons from './Icons';
import ContentEditor from './ContentEditor';
import SrollableTabs from '../commons/tabs/SrollableTabs';
import interact from 'interactjs'
import $ from 'jquery'

export class Components extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            key: this.makeid(5)
        }
    }
    componentDidMount() {
        var element = document.getElementById('resize-drag')
        var x = 0; var y = 0
        interact(element)
            .draggable({
                modifiers: [
                    interact.modifiers.snap({
                        targets: [
                            interact.createSnapGrid({ x: 15, y: 15 })
                        ],
                        range: Infinity,
                        relativePoints: [{ x: 0, y: 0 }]
                    }), 
                    interact.modifiers.restrictRect({
                        restriction: 'parent'
                    })
                ],
                inertia: true
            })
            .on('dragmove', function (event) {
                x += event.dx
                y += event.dy

                event.target.style.webkitTransform =
                    event.target.style.transform =
                    'translate(' + x + 'px, ' + y + 'px)'
            })
            .resizable({
                // resize from all edges and corners
                edges: { left: false, right: true, bottom: true, top: false },

                modifiers: [
                    // keep the edges inside the parent
                    interact.modifiers.restrictEdges({
                        outer: 'parent',
                        endOnly: true
                    }),

                    //minimum size
                    interact.modifiers.restrictSize({
                        min: { width: 40, height: 40 }
                    })
                ],

                inertia: true
            })
            .on('resizemove', function (event) {
                var target = event.target
                var x = (parseFloat(target.getAttribute('data-x')) || 0)
                var y = (parseFloat(target.getAttribute('data-y')) || 0)

                // update the element's style
                target.style.width = event.rect.width + 'px'
                target.style.height = event.rect.height + 'px'

                // translate when resizing from top or left edges
                x += event.deltaRect.left
                y += event.deltaRect.top

                target.style.webkitTransform = target.style.transform =
                    'translate(' + x + 'px,' + y + 'px)'

                target.setAttribute('data-x', x)
                target.setAttribute('data-y', y)
                //target.textContent = Math.round(event.rect.width) + '\u00D7' + Math.round(event.rect.height)
            })


    }
    makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
    
    render() {
        return (
            <div className="container">

                <div className="row">
                    <div className="col-lg-3">
                        <h3 className="mb20">Components</h3>
                        <div className="nav flex-column nav-pills" id="pills-tab" role="tablist" aria-orientation="vertical">
                            <a className="nav-link active" id="pills-messages-tab" data-toggle="pill" href="#pills-messages" role="tab" aria-controls="pills-messages" aria-selected="false">Data</a>
                            <a className="nav-link" id="pills-cards-tab" data-toggle="pill" href="#pills-cards" role="tab" aria-controls="pills-cards" aria-selected="true">Cards</a>
                            <a className="nav-link" id="pills-forms-tab" data-toggle="pill" href="#pills-forms" role="tab" aria-controls="pills-forms" aria-selected="false">Forms</a>
                            <a className="nav-link" id="pills-settings-tab" data-toggle="pill" href="#pills-settings" role="tab" aria-controls="pills-settings" aria-selected="false">Modals</a>
                            <a className="nav-link" id="pills-tabs-tab" data-toggle="pill" href="#pills-tabs" role="tab" aria-controls="pills-tabs" aria-selected="false"
                            onClick={()=>{
                                this.setState({
                                    key: this.makeid(5)
                                })
                            }}
                            >Tabs</a>
                            <a className="nav-link" id="pills-icons-tab" data-toggle="pill" href="#pills-icons" role="tab" aria-controls="pills-icons" aria-selected="false">Icons</a>
                            <a className="nav-link" id="pills-content-editor-tab" data-toggle="pill" href="#pills-content-editor" role="tab" aria-controls="pills-content-editor" aria-selected="false">Content Editor</a>
                            
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="tab-content" id="pills-tabContent">
                        
                            <div className="tab-pane show active" id="pills-forms" role="tabpanel" aria-labelledby="pills-forms-tab">
                                <div className="row">
                                    <div className="col-md-5">
                                        
                                        <SrollableTabs 
                                            navLinks={()=>{
                                                return (
                                                    <>
                                                        <a class="nav-link active" href="#">Sample text</a>
                                                        <a class="nav-link" href="#">Quick brown</a>
                                                        <a class="nav-link" href="#">Fox jumps</a>
                                                        <a class="nav-link" href="#">Quick brown</a>
                                                        <a class="nav-link" href="#">Fox jumps</a>
                                                        <a class="nav-link" href="#">Quick brown</a>
                                                        <a class="nav-link" href="#">Fox jumps</a>
                                                        <a class="nav-link" href="#">Quick brown</a>
                                                        <a class="nav-link" href="#">Fox jumps</a>
                                                    </>
                                                )
                                            }}
                                        />

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        
                                    </div>
                                    <div className="col-md-8 p-5">
                                        <div className="resize-container horizontal-grid vertical-grid ">
                                            <div className="resize-drag" id="resize-drag" contentEditable onDoubleClick={(e) => {
                                                alert(!$(e.target).attr('contentEditable'))
                                                $(e.target).attr('contentEditable', !$(e.target).attr('contentEditable'))
                                            }}>
                                                Name here
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="tab-pane" id="pills-cards" role="tabpanel" aria-labelledby="pills-cards-tab">
                                <h1 className="mb20">CARDS</h1>
                                <Cards />
                            </div>
                            <div className="tab-pane" id="pills-forms" role="tabpanel" aria-labelledby="pills-forms-tab">
                                <Forms />
                            </div>
                            <div className="tab-pane show active" id="pills-messages" role="tabpanel" aria-labelledby="pills-messages-tab">
                                <Data />
                            </div>
                            <div className="tab-pane" id="pills-settings" role="tabpanel" aria-labelledby="pills-settings-tab">
                                <Modals />
                            </div>
                            <div className="tab-pane" id="pills-tabs" role="tabpanel" aria-labelledby="pills-tabs-tab">
                                <Tabs key={this.state.key} />
                            </div>
                            <div className="tab-pane" id="pills-icons" role="tabpanel" aria-labelledby="pills-icons-tab">
                                <Icons />
                            </div>
                            <div className="tab-pane" id="pills-content-editor" role="tabpanel" aria-labelledby="pills-content-editor-tab">
                                <ContentEditor />
                            </div> */}
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default Components
