import React, { Component } from 'react'

export class SavedDraftCard extends Component {

    renderItem(item) {
        return (
            <li className="item">
                <span>[Phase name], [Procedure name], [Specialty name]</span>
                <small className="saved-drafts-date">[Date format]</small>
            </li>
        )
    }

    render() {
        const { title, items=[1,2,3] } = this.props
        
        const itemList = items.map(item=>{
            return this.renderItem(item)
        })

        return (
            <div className="card saved-drafts-card">
                <h3 className="bold saved-drafts-title">{ title }</h3>
                <ul className="list-unstyled saved-drafts-list">
                    {itemList}
                </ul>
            </div>
        )
        
    }
}

export default SavedDraftCard
