import React, { Component } from 'react'
import axios from 'axios'
import TextInput from '../../components/commons/forms/TextInput'
import { Link } from 'react-router'
import Notification from '../../components/Notification';
import $ from 'jquery'
import { BASE_URL } from '../../config/constant';
import logo from '../../assets/images/logos/logo-mono-reverse.png'
import { Button } from '../../components/commons/forms';
import { ConfirmationModal } from '../../components/commons/modals';

class ResetPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: '',
      email: '',
      loading: false,
    }
  }

  componentWillMount() {
    axios.defaults.baseURL = BASE_URL

  }
  componentDidMount() {

    $('.line-form-group-v2 input').blur((e) => {
      if (!$(e.currentTarget).val()) {
        $(e.currentTarget).removeClass('has-val')
      } else {
        $(e.currentTarget).addClass('has-val')
      }
    })
    $('.line-form-group-v2 input').blur()

  }
  

  onSubmit = () => {
    axios.post(`api/create`, {
        email:this.state.email
    }).then( result => {
        $('#notificationModal').modal('show')
       
    }).catch( error => {
        if(error.response.status == 422){
           this.setState({
               error:error.response.data.errors['email'][0]
           })
        }
        console.log(error.response)
    })
    
  }
  render() {
    return (
      <main role="main" className="light-theme flex-shrink-0 d-flex login-page">
        <div className="container align-self-center">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-6 col-md-7 ">
              <div className="card shadow">
                <div className="card-body p30 text-center">
                  <div className="font-weight-bold d-flex justify-content-center mb30">
                    <img src={logo} className="logo" />
                  </div> 
                  <div className="row">
                    <div className="col-md-10 mx-auto">
                      <p className="mx-auto mb40 bold mt0 sub-title">
                        Welcome To CORH password recovery <br /> please enter your email 
                      </p>
                    </div>
                  </div> 
                 

                  <div className="input-holder">
                    <TextInput
                      icon='mdi mdi-email'
                      label={'Email'}
                      value={this.props.email}
                      errorMessage={this.state.error}
                      containerClass={`mb50 text-left`}
                      onValueChange={event => {
                        this.setState({
                          email: event.target.value
                        })
                      }}
                      onKeyPress={(event) => {
                        if (event.key == 'Enter')
                          this.onSubmit()
                      }}

                    />
                  </div> 

                  <div className="mb30">
                    <Button primary onClick={this.onSubmit.bind(this)} className="submit-btn">
                      Reset Password
                    </Button> 
                  </div>



                  <div className="mb10 d-flex justify-content-center desc flex-wrap">
                    Did you remember your password? <Link to={{ pathname: `/login` }} className="ml6 sign-up-today">
                      Sign in
                    </Link>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
          <ConfirmationModal
            idModal="notificationModal" 
              title="Password Reset"
              message="Check your email for password reset link!!"
              confirmText="OK"
              confirm={ () => {
                this.props.router.push({
                    location: '/login'
                })
              }}
          />
        </div>
      </main>
    )
  }
}



export default ResetPassword
