import React, { Component } from 'react'
import ValidationMessage from './ValidationMessage';

class Picker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
    }
    this.pickerId.bind(this)
  }
  pickerId() {
    return `_${this.state.unique}`
  }

  render() {

    const {
        label,
        value,
        errorMessage = '',
        containerClass = '',
        data=[],
        required,
        search,
        
      } = this.props

     

    const options = data.map((item, index) => {
        return (
            <option
              key={`options-${this.state.unique}-${index}`}
              value={item.id}
              selected="false"
              disabled={item.disabled}
            >
              {item.name}
            </option>
          )
    })
    const red_label = () => {
      return (
        <span className="error">&nbsp;*</span>
      )
    }
    const search_label = () => {
      return (<i className="mdi mdi-magnify"></i>)
    }
    
    return (

      <div className={`line-form-group-v2 ${errorMessage.length > 0 ? 'is-invalid' : ''} ${containerClass}`}>
        <select
          name={`_${this.state.unique}`}
          id={`_${this.state.unique}`}
          className={
            errorMessage.length > 0 ? ' is-invalid' : ''
          }
          onChange={this.props.onChange}
          value={value}>
          {this.props.firstOption ? this.props.firstOption:null}
          {options}
          {this.props.children}
        </select>
        {
          label ?
            <label htmlFor={`_${this.state.unique}`}>{label} {required ? red_label() : null }</label>
            : null
        }
        {errorMessage.length > 0 ? ( 
          <ValidationMessage type="error" message={errorMessage} />
        ) : null}
      </div>
    )
  }
}

export default Picker
