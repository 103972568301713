import {
  FETCH_SPECIALTIES_DATA,
  FETCH_SPECIALTIES_DATA_COMPLETE,
  SET_SELECTED_SPECIALTY,
  GET_SPECIALTY_CATEGORIES
} from './types'

import axios from 'axios'

export const getSpecailtiesData = () => {
  return (dispatch) => {
    dispatch({
      type: FETCH_SPECIALTIES_DATA
    })

    let url = 'api/client/specialty'

    axios.get(url).then((res) => {
      dispatch({
        type: FETCH_SPECIALTIES_DATA_COMPLETE,
        payload: res.data.data
      })
    }).catch((err) => {
      console.log(err)
    })
  }
}

export const setSpecialty = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_SPECIALTY,
      payload: data
    })
  }
}

export const getSpecialtyCategories = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_SPECIALTY_CATEGORIES,
      categories: []
    })

    let url = `/api/client/specialty-categories-by-specialty/${id}`

    axios.get(url).then((res) => {
      dispatch({
        type: GET_SPECIALTY_CATEGORIES,
        categories: res.data.data,
        specialty: id
      })
    }).catch((error) => {
      console.log(error)
      console.log(error.response)
    })

  }
}