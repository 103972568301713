import 'airbnb-js-shims'

import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'

import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import $ from 'jquery'
import './assets/js/autorisize'
import Popper from 'popper.js'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import * as serviceWorker from './serviceWorker'

import { Provider } from 'react-redux'
import { createStore } from 'redux'
import reducers from './reducers'
import RouterLink from './routes'
import { persistStore, persistReducer } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import CKEditor from 'ckeditor4-react'
import { StripeProvider } from 'react-stripe-elements'
import Raven from 'raven-js'
import { sentry_url } from './config/sentry-config'
import { ENVIROMENT } from './config/settings'

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
} else {
  Raven.config(sentry_url).install()
}
CKEditor.editorUrl = '/ckeditor/ckeditor.js' 
console.log('CKEditor.plugins', CKEditor.config)
// CKEditor.plugins.add('ckeditortheme', {
//   init: function (editor) {
//     var pluginDirectory = this.path;
//     editor.addContentsCss(pluginDirectory + './asset/ckeditortheme.css');
//   }
// });
const persistedReducer = persistReducer(ENVIROMENT.persistConfig, reducers)

const store = createStore(
  persistedReducer,
  {},
  ENVIROMENT.devTools
)

const persistor = persistStore(store)

ReactDOM.render(
  <StripeProvider apiKey={ENVIROMENT.stripeKey}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RouterLink />
      </PersistGate>
    </Provider>
  </StripeProvider>,
  document.getElementById('root')
)

if (window.location.pathname == '?secure') {
  console.log('seach')
  persistor.purge()
}
window.persistor = persistor
/* 
ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
              {routes}
        </PersistGate>
    </Provider>, document.getElementById('root')); */

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
