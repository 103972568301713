import React, { Component } from 'react'
import { getMedicalInventories, getItemsPerCategoryStandard, setItems, getItems,itemLoading } from '../../actions/ItemAction'
import { updateQuicknotesItemList } from '../../actions/QuicknotesActions'
import ReactDOMServer from 'react-dom/server'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import _ from 'lodash'
import $ from 'jquery'
import ViewItemModal from './ViewItemModal'
import InfiniteScroll from "react-infinite-scroll-component"
import { Picker, TextInput } from '../commons/forms'
import { Modal } from '../commons/modals';
import { Tabs, TabHead, TabNavigation, TabBody, TabContent } from '../commons/tabs';
import { Pills } from '../commons/misc';
import { Table, TRow, TData, THead } from '../commons/table';
import ViewItemCard from '../commons/cards/ViewItemCard';

export class AssignItemsStandard extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
      selected_items: [],
      selected_items_review: [],
      type: 'consumables',
      inventory: null,
      category: 'all',
      change: false,
      category_consumables: '',
      category_instruments: '',
      counters:{
        supplies: 0,
        instruments: 0,
        equipments: 0,
      },
      prn_items: [],
      or_items: [],
      selectedItem: null,
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      keyword: '',
      page: 1,
      isReverse: false
    }
    this.searchData = _.debounce(this.getItemResults, 600)

  }
  
  componentDidMount = () => {
    this.props.setItems([])
    this.props.getItemsPerCategoryStandard(this.state.type, this.state.category, this.props.stage_master.procedure_id, this.props.stage_master.surgeon_id, this.state.selected_items, this.state.keyword, this.state.page)

    $("#assignItemsModalCenter").on('show.bs.modal', ()=>{
      setTimeout(() => {
        this.setState({ 
          unique:
            Math.random()
              .toString(36)
              .substring(2, 15) +
            Math.random()
              .toString(36)
              .substring(2, 15),
        })
      }, 500);
    });
  } 
  
  componentWillReceiveProps = (nextProps) => { 
    if(this.props.change != nextProps.change){
      this.setSelected(nextProps)
      this.setState({
        inventory: 1,
        page: 1,
        keyword: '',
        category: 'all',  
      }, () => {
        this.props.getItemsPerCategoryStandard(this.state.type, this.state.category, this.props.stage_master.procedure_id, this.props.stage_master.surgeon_id, this.state.selected_items, this.state.keyword, this.state.page)
      })

      $('#supplies-tab').tab('show')
    }

    if(nextProps.item_list != this.props.item_list){
      this.setState({
        item_list: nextProps.item_list
      }, () => { this.setSelected(nextProps) })
    }

    if(this.props.items != nextProps.items){
      this.setState({
        items: nextProps.items
      })
    }

    if(this.props.stage_master != nextProps.stage_master){
      this.props.getItemsPerCategoryStandard(this.state.type, this.state.category, this.props.stage_master.procedure_id, this.props.stage_master.surgeon_id, this.state.selected_items, this.state.keyword, this.state.page)
    }

  }

  componentDidUpdate() {
    $('[data-toggle="popover"]').popover()
    $('.popover').popover('hide')
  }

  getMoreItems = () => {
    const { page } = this.state

    this.setState({
      page: page + 1
    }, () => { this.props.getItemsPerCategoryStandard(this.state.type, this.state.category, this.props.stage_master.procedure_id, this.props.stage_master.surgeon_id, this.state.selected_items, this.state.keyword, this.state.page) })
  }

  sortBy(key) {
    let arrayCopy = [...this.props.items]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }

    this.props.setItems(arrayCopy)

    this.setState({ isReverse: !this.state.isReverse })
  }

  sortSelectedBy(key) {
    let arrayCopy = [...this.state.selected_items_review]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }

    this.setState({ isReverse: !this.state.isReverse, selected_items_review: arrayCopy })
  }

  compareBy(key) {
    if (key == 'category') {
      return function (a, b) {
        if (a.category.name < b.category.name) return -1
        if (a.category.name > b.category.name) return 1
        return 0
      }
    }
    return function (a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }

  setSelected = (props) => {
    let { item_list, selected_category } = props

    let temp = []
    item_list.map((list, index) => {
      list.details.map((item) => {
        item.prn = null
        if(index == 0){
          item.prn = list.id
        }
        item.qty = item.qty
        temp.push(item)
      })
    })

    let a = 0, b = 0, c = 0
    let prn_items = []
    let or_items = []
    temp.map((data) => { 
      if (data.prn) {
        prn_items.push(data)
      } else {
        or_items.push(data)
      }
    })
    console.log('propspropspropsprops',props.selected_category)
    if(props.selected_category == 'PRN'){
      prn_items.map(d => { 
        if (d.category.medical_inventory_id == 1)
          a++;
        if (d.category.medical_inventory_id == 2)
          b++;
        
        if (d.category.medical_inventory_id == 3)
          c++;
      })
    }else{
      or_items.map(d => { 
        if (d.category.medical_inventory_id == 1)
          a++;
        if (d.category.medical_inventory_id == 2)
          b++;
        
        if (d.category.medical_inventory_id == 3)
          c++;
      })
    } 
    // console.log('226', temp.splice(0))
    
    this.setState({
      selected_items: selected_category == 'PRN' ? prn_items:or_items,
      prn_items: prn_items,
      or_items: or_items,
      counters: {
        supplies: a,
        instruments: b,
        equipments: c,
      }
    }, () => {console.log('22f', this.state)})
  }

  checkItem = (id) => {
    let { selected_items } = this.state
    const { items, selected_category } = this.props
    

    let item = items.find((e) => e.id === id)
    if(item){
      if(!item.qty){
        item.qty = 1
      }
    }else{
      item = selected_items.find((e) => e.id === id)
    }
    
    if(selected_items.find((e) => e.id === id)){
      console.log('sel', selected_items, selected_items.filter(x => x.id != id))
      let sel_ = selected_items.filter(x => x.id != id)
      this.setState({
        selected_items: sel_.splice(0 ),
        counters: {
          supplies: this.state.counters.supplies + (item.category.medical_inventory_id == 1 ? -1:0),
          instruments: this.state.counters.instruments + (item.category.medical_inventory_id == 2 ? -1:0),
          equipments: this.state.counters.equipments + (item.category.medical_inventory_id == 3 ? -1:0),
        },
      })
    }else{
      selected_items.unshift(item)
      this.setState({
        selected_items: selected_items,
        counters: {
          supplies: this.state.counters.supplies + (item.category.medical_inventory_id == 1 ? 1 : 0),
          instruments: this.state.counters.instruments + (item.category.medical_inventory_id == 2 ? 1 : 0),
          equipments: this.state.counters.equipments + (item.category.medical_inventory_id == 3 ? 1 : 0),
        },
      })
      if (selected_category == 'PRN') {
        this.setPRN(id)
      }
    }
  }

  checkItemExistence = (id) => {
    let { selected_items, prn_items, or_items } = this.state

    let exist = false
    let itemss = this.props.selected_category == 'PRN' ? prn_items:or_items
    if (selected_items.filter((e) => e.id === id).length > 0){
      exist = true
    }else{
      exist = false
    }

    return exist
  }

  isChecked = (id) => {
    let { selected_items } = this.state
    const { items } = this.props

    let item_local = selected_items.find((e) => e.id === id)
    let item_store = items.find((e) => e.id === id)

    let checked = false
    if(item_local){
      if(item_local.prn){
        checked = true
      }
    }else{
      if(item_store){
        if(item_store.prn){
          checked = true
        }
      }
    }

    return checked
  }

  setPRN = (id) => { 
    let { selected_items, item_list } = this.state
    let { items } = this.props
    console.log('objectsetprn', item_list)
    let prn_id = item_list[0].id

    let item_local = selected_items.find( x => x.id === id)

    selected_items.map((selected) => {
      items.map((item, index) => {
        if(selected.id == item.id){
          items[index] = selected
        }
      })
    })

    let item_store = items.find((e) => e.id === id)

    if(item_store){
      if(item_store.prn){ 
        item_store.prn = null
      }else{ 
        item_store.prn = prn_id
      }
       
      items[items.findIndex((e) => e.id === id)] = item_store

      selected_items[selected_items.findIndex((e) => e.id === id)] = item_store
      this.setState({
        selected_items: selected_items
      })

    }else{
      if(item_local.prn){ 
        item_local.prn = null
      }else{ 
        item_local.prn = prn_id
      }

      selected_items[selected_items.findIndex((e) => e.id === id)] = item_local
      this.setState({
        selected_items: selected_items
      })
    }

    this.setState({
      change: true
    })

    this.props.setItems(items)
  }

  filterSelected = (event) => {
    const { selected_items } = this.state

    if(event.target.value == ''){
      this.setState({
        selected_items_review: selected_items,
        inventory_filter: event.target.value
      })
    }else{
      let query = event.target.value  
      let temp = []

      let items = selected_items
      
      items.map((item) => {
        if(item.name.toLowerCase().includes(query.toLowerCase()) || item.catalog_number.toLowerCase().includes(query.toLowerCase()) || item.company.toLowerCase().includes(query.toLowerCase())){
          temp.push(item)
        }
      })

      console.log('sdsd', temp)

      this.setState({
        selected_items_review: temp,
        inventory_filter: event.target.value
      })
    }
  }

  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  renderTags(data) {
    return ReactDOMServer.renderToStaticMarkup(
      data.map(data => {
        return (
          <span
            key={`tag_${data.id}`}
            className="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10"
          >
            {data.name}
          </span>
        )
      })
    )
  }

  getItemResults = () => {
    this.props.getItemsPerCategoryStandard(this.state.type, this.state.category, this.props.stage_master.procedure_id, this.props.stage_master.surgeon_id, this.state.selected_items, this.state.keyword, this.state.page)
  }
  searchItems = ({ target: { value } }) => {
    this.props.itemLoading()
    this.setState({ keyword: value, page: 1 }, () => {
      this.searchData()
    })
  }

  checkIfInCategory(selected_category, item){

    let { selected_items } = this.state
    const { items } = this.props

    let item_local = selected_items.find((e) => e.id === item.id)
    let item_store = items.find((e) => e.id === item.id)
    
    let checked = false
    if (selected_category == 'PRN'){

      if (item_local) {
        if (item_local.prn) {
          checked = true
        }
      } else {
        if (item_store) {
          if (item_store.prn) {
            checked = true
          }
        }
      }
    }

    return checked
  }

  render() {
    const { items } = this.props
    const { counters, selected_items_review } = this.state

 
    return (
      <div>

        <ViewItemCard id={`ais-preview-modal`} item={this.state.selectedItem} className="zindex-5k" ></ViewItemCard>
        <Modal
          idModal="assignItemsModalCenter"
          size="modal-xl"
          title="Assign items"
          confirmText="Review items"
          cancelText="Cancel"
          confirm={(e) => {
            this.setState({ selected_items_review: this.state.selected_items, inventory: 'all' })
            $("#assignItemsModalCenter").modal('hide')
            $("#reviewItemsModalCenter").modal('show')
          }}
          cancel={(e) => {
            //fn here
          }}
          footerClass="container"
          className="preview-item-modal"
          bodyClass="assign-items-body"
        >

          <div className="container">
            <Tabs
              onRef={ref => (this.tabsRef = ref)}
              className=""
              key={`tabs-${this.state.unique}`}
              navs={() => {
                return (
                  <TabHead className="col-lg-12 px0">
                    <TabNavigation target="consumables" active={true} onClick={(event) => {
                      this.setState({ type: 'consumables', page: 1 }, () => {
                        this.props.getItemsPerCategoryStandard(this.state.type, 'all', this.props.stage_master.procedure_id, this.props.stage_master.surgeon_id, this.state.selected_items)
                      })
                    }}> 
                      <span className="d-flex">
                        Consumables 
                        {/* <Pills className="mt0 mr0 ml8 sm specialty-span">{counters.supplies ? counters.supplies:'0'}</Pills> */}
                      </span>
                    </TabNavigation>
                    <TabNavigation target="instruments" onClick={(event) => {
                      this.setState({ type: 'instruments', page: 1 }, () => {
                        this.props.getItemsPerCategoryStandard(this.state.type, 'all', this.props.stage_master.procedure_id, this.props.stage_master.surgeon_id, this.state.selected_items)
                      })
                    }}> 
                      <span className="d-flex"> 
                        Instruments
                        {/* <Pills className="mt0 mr0 ml8 sm specialty-span">{counters.instruments ? counters.instruments:'0'}</Pills> */}
                      </span>
                    </TabNavigation>
                    <TabNavigation target="equipments" onClick={(event) => {
                      this.setState({ type: 'equipments', page: 1 }, () => {
                        this.props.getItemsPerCategoryStandard(this.state.type, 'all', this.props.stage_master.procedure_id, this.props.stage_master.surgeon_id, this.state.selected_items)
                      })
                    }}>
                      <span className="d-flex">Equipment
                        {/* <Pills className="mt0 ml8 sm specialty-span">{counters.equipments ? counters.equipments:'0'}</Pills> */}
                      </span>
                    </TabNavigation>
                  </TabHead>
                )
              }}
              contents={() => {
                return (
                  <TabBody className="col-md-12 px0 pt16">
                    <TabContent idTab="consumables" active={true}>
                      <div className="row">
                        <div className="col-lg-5">
                          <TextInput
                            label={'Search item database'}
                            placeholder={'Start typing name, catalogue #…'}
                            className="active"
                            onChange={this.searchItems.bind(this)}
                            value={this.state.keyword}
                            search
                          />
                        </div>
                        <div className="col-lg-5">
                          <Picker
                            onChange={event => {
                              this.setState({ category: event.target.value, keyword: '', page: 1 }, () => {
                                this.props.getItemsPerCategoryStandard(this.state.type, this.state.category, this.props.stage_master.procedure_id, this.props.stage_master.surgeon_id, this.state.selected_items, this.state.keyword, this.state.page)
                              })
                            }}
                            label={`Filter by category`}
                            value={this.state.category}
                            data={this.props.inventory_categories}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12">
                          <Table minWidth={690}
                            className="modal-table infi-table" 

                            renderModal={() => {
                              return null
                            }}

                            infiniteScroll
                            infiniteHeight={307}
                            infiniteDataLength={this.props.items.length} //This is important field to render the next data
                            infiniteNext={() => { this.getMoreItems() }}
                            infiniteHasMore={this.props.more}
                            loading={this.props.loading}

                            endOfMessageMessage='Nothing else to see...'
                            

                            renderHeaders={() => {
                              return (
                                <TRow>
                                  <THead className="w40" text="" />
                                  {/* <THead className="w50" text="PRN" /> */}
                                  <THead className="w150" text="Catalogue #" sortable onClick={() => this.sortBy('catalog_number')} />
                                  <THead className="w250" text="Name" sortable onClick={() => this.sortBy('name')} />
                                  <THead className="w80" text="Size" sortable onClick={() => this.sortBy('size')} />
                                  <THead className="w110" text="Company" sortable onClick={() => this.sortBy('company')} />
                                  {/* <THead className="w110" text="Category" sortable onClick={() => this.sortBy('category')} /> */}
                                </TRow>
                              )
                            }}
                            renderRows={() => { 
                              let rows = this.props.items.map(item => {
                                return (
                                  <TRow hasChoices key={this.makeid(Math.ceil(Math.random() * 10))}  autoCheck={false}>
                                    <TData className="w40" onClick={() => {
                                      this.checkItem(item.id);
                                      //this.tabsRef.reInit(); 
                                    }}>
                                      <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id={`checkbox-a1-${item.id}`} checked={this.checkItemExistence(item.id)} />
                                        <label className="custom-control-label" htmlFor={`checkbox-a1-${item.id}`} onClick={(e) => { e.preventDefault() }}></label>
                                      </div>
                                    </TData>
                                    {/* <TData className="w50" onClick={(e)=>{
                                        e.preventDefault()
                                        e.stopPropagation()
                                        this.setPRN(item.id)
                                      }}>
                                      <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id={`checkbox-ais1-${item.id}`} checked={this.isChecked(item.id)} />
                                        <label className="custom-control-label" htmlFor={`checkbox-ais1-${item.id}`} onClick={(e) => { e.preventDefault() }}></label>
                                      </div>
                                    </TData> */}
                                    <TData className="w150">{item.catalog_number}</TData>
                                    <TData className="w250 has-action" item={item} popover onClick={(e) => {
                                      e.stopPropagation()
                                      this.setState({
                                        selectedItem: item, 
                                      })
                                      $(`#ais-preview-modal`).modal('show')
                                    }}>{item.name}</TData>
                                    <TData className="w80">{item.size}</TData>
                                    <TData className="w110">{item.company}</TData>
                                    {/* <TData className="w110">{item.category ? item.category.name : ''}</TData> */}
                                  </TRow>
                                )
                              })
                              return rows
                            }}
                          // renderNotification={() => {
                          //     return (<TableNotificationCard type="success" message="Success message here..." />)
                          // }}

                          />

                        </div>

                      </div>
                    </TabContent>
                    <TabContent idTab="instruments" active={false}>
                      <div className="row">
                        <div className="col-lg-5">
                          <TextInput
                            label={'Search item database'}
                            placeholder={'Start typing name, catalogue #…'}
                            className="active"
                            onChange={this.searchItems.bind(this)}
                            value={this.state.keyword}
                            search
                          />
                        </div>
                        <div className="col-lg-5">
                          <Picker
                            onChange={event => {
                              this.setState({ category: event.target.value, keyword: '', page: 1 }, () => {
                                this.props.getItemsPerCategoryStandard(this.state.type, this.state.category, this.props.stage_master.procedure_id, this.props.stage_master.surgeon_id, this.state.selected_items, this.state.keyword, this.state.page)
                              })
                            }}
                            label={`Filter by category`}
                            value={this.state.category}
                            data={this.props.inventory_categories}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12">
                          <Table minWidth={690}
                            className="modal-table infi-table" 

                            renderModal={() => {
                              return null
                            }}

                            infiniteScroll
                            infiniteHeight={307}
                            infiniteDataLength={this.props.items.length} //This is important field to render the next data
                            infiniteNext={() => { this.getMoreItems() }}
                            infiniteHasMore={this.props.more}
                            loading={this.props.loading}
 
                            endOfMessageMessage="Nothing else to see..."

                            renderHeaders={() => {
                              return (
                                <TRow>
                                  {<THead className="w40" text="" />}
                                  {/* <THead className="w50" text="PRN" /> */}
                                  <THead className="w150" text="Catalogue #" sortable onClick={() => this.sortBy('catalog_number')} />
                                  <THead className="w250" text="Name" sortable onClick={() => this.sortBy('name')} />
                                  <THead className="w80" text="Size" sortable onClick={() => this.sortBy('size')} />
                                  <THead className="w110" text="Company" sortable onClick={() => this.sortBy('company')} />
                                  {/* <THead className="w110" text="Category" sortable onClick={() => this.sortBy('category')} /> */}
                                </TRow>
                              )
                            }}
                            renderRows={() => {
                              let rows = this.props.items.map(item => {
                                return (
                                  <TRow hasChoices key={`row-aitt2-${item.id}`}  autoCheck={false}>
                                    <TData className="w40" onClick={() => {
                                      this.checkItem(item.id);
                                      //this.tabsRef.reInit();
                                    }}>
                                      <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id={`checkbox-a2-${item.id}`} checked={this.checkItemExistence(item.id)} />
                                        <label className="custom-control-label" htmlFor={`checkbox-a2-${item.id}`} onClick={(e) => { e.preventDefault() }}></label>
                                      </div>
                                    </TData>
                                    {/* <TData className="w50" onClick={(e) => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                      this.setPRN(item.id)
                                    }}>
                                      <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id={`checkbox-ais2-${item.id}`} checked={this.isChecked(item.id)} />
                                        <label className="custom-control-label" htmlFor={`checkbox-ais2-${item.id}`} onClick={(e) => { e.preventDefault() }}></label>
                                      </div>
                                    </TData> */}
                                    <TData className="w150">{item.catalog_number}</TData>
                                    <TData className="w250 has-action" item={item} popover onClick={(e) => {
                                      e.stopPropagation()
                                      this.setState({
                                        selectedItem: item,
                                      })
                                      $(`#ais-preview-modal`).modal('show')
                                    }}>{item.name}</TData>
                                    <TData className="w80">{item.size}</TData>
                                    <TData className="w110">{item.company}</TData>
                                    {/* <TData className="w110">{item.category ? item.category.name : ''}</TData> */}
                                  </TRow>
                                )
                              })
                              return rows
                            }}
                          // renderNotification={() => {
                          //     return (<TableNotificationCard type="success" message="Success message here..." />)
                          // }}

                          />

                        </div>

                      </div>
                    </TabContent>
                    <TabContent idTab="equipments" active={false}>
                      <div className="row">
                        <div className="col-lg-5">
                          <TextInput
                            label={'Search item database'}
                            placeholder={'Start typing name, catalogue #…'}
                            className="active"
                            onChange={this.searchItems.bind(this)}
                            value={this.state.keyword}
                            search
                          />
                        </div>
                        <div className="col-lg-5">
                          <Picker
                            onChange={event => {
                              this.setState({ category: event.target.value, keyword: '', page: 1 }, () => {
                                this.props.getItemsPerCategoryStandard(this.state.type, this.state.category, this.props.stage_master.procedure_id, this.props.stage_master.surgeon_id, this.state.selected_items, this.state.keyword, this.state.page)
                              })
                            }}
                            label={`Filter by category`}
                            value={this.state.category}
                            data={this.props.inventory_categories}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12">
                          <Table minWidth={690}
                            className="modal-table infi-table" 

                            renderModal={() => {
                              return null
                            }}

                            infiniteScroll
                            infiniteHeight={307}
                            infiniteDataLength={this.props.items.length} //This is important field to render the next data
                            infiniteNext={() => { this.getMoreItems() }}
                            infiniteHasMore={this.props.more}
                            loading={this.props.loading}

                            endOfMessageMessage='Nothing else to see...' 

                            renderHeaders={() => {
                              return (
                                <TRow>
                                  <THead className="w40" text="" />
                                  {/* <THead className="w50" text="PRN" /> */}
                                  <THead className="w150" text="Catalogue #" sortable onClick={() => this.sortBy('catalog_number')} />
                                  <THead className="w250" text="Name" sortable onClick={() => this.sortBy('name')} />
                                  <THead className="w80" text="Size" sortable onClick={() => this.sortBy('size')} />
                                  <THead className="w110" text="Company" sortable onClick={() => this.sortBy('company')} />
                                  {/* <THead className="w110" text="Category" sortable onClick={() => this.sortBy('category')} /> */}
                                </TRow>
                              )
                            }}
                            renderRows={() => {
                              let rows = this.props.items.map(item => {
                                return (
                                  <TRow hasChoices key={`row-aitt3-${item.id}`}  autoCheck={false}>
                                    <TData className="w40" onClick={() => {
                                      this.checkItem(item.id);
                                      //this.tabsRef.reInit();
                                    }}>
                                      <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id={`checkbox-a33-${item.id}`} checked={this.checkItemExistence(item.id)} />
                                        <label className="custom-control-label" htmlFor={`checkbox-a3-${item.id}`} onClick={(e) => { e.preventDefault() }}></label>
                                      </div>
                                    </TData>
                                    {/* <TData className="w50" onClick={(e) => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                      this.setPRN(item.id)
                                    }}>
                                      <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id={`checkbox-ais3-${item.id}`} checked={this.isChecked(item.id)} />
                                        <label className="custom-control-label" htmlFor={`checkbox-ais3-${item.id}`} onClick={(e) => { e.preventDefault() }}></label>
                                      </div>
                                    </TData> */}
                                    <TData className="w150">{item.catalog_number}</TData>
                                    <TData className="w250 has-action" item={item} popover onClick={(e) => {
                                      e.stopPropagation()
                                      this.setState({
                                        selectedItem: item,
                                      })
                                      $(`#ais-preview-modal`).modal('show')
                                    }}>{item.name}</TData>
                                    <TData className="w80">{item.size}</TData>
                                    <TData className="w110">{item.company}</TData>
                                    {/* <TData className="w110">{item.category ? item.category.name : ''}</TData> */}
                                  </TRow>
                                )
                              })
                              return rows
                            }}
                          // renderNotification={() => {
                          //     return (<TableNotificationCard type="success" message="Success message here..." />)
                          // }}

                          />

                        </div>

                      </div>
                    </TabContent> 
                  </TabBody>
                )
              }}
            />
             
             
          </div>
        </Modal>
    
        <Modal
          idModal="reviewItemsModalCenter"
          size="modal-xl"
          title="Review items"
          confirmText="Confirm assigned items"
          cancelText="Cancel"
          confirm={(e) => {
            let mergeItems = []
            if (this.props.selected_category == 'PRN') {
              mergeItems = [...this.state.selected_items, ...this.state.or_items];
            }
            if (this.props.selected_category != 'PRN') {
              mergeItems = [...this.state.selected_items, ...this.state.prn_items];
            }
            this.props.setSelected(mergeItems)
            $('#reviewItemsModalCenter').modal('hide')
          }}
          cancel={(e) => {
            $('.modal').modal('hide')
          }}
          footerClass="container"
          className="preview-item-modal"
          bodyClass="assign-items-body"
          midButtonText="Back"
          midButtonAction={(e)=>{
            $('#reviewItemsModalCenter').modal('hide')
            $('#assignItemsModalCenter').modal('show')
          }}
        >
          <div className="container">
            <div className="row"> 
              <div className="col-md-12">
                <p className="mb20">Please review and confirm assigned items. <br />Items will be separated by PRN and General OR sections.</p>
              </div>
              <div className="col-lg-5">
                <TextInput
                  label={'Search item database'}
                  placeholder={'Start typing name, catalogue #…'}
                  className="active"
                  onChange={(event) => { this.filterSelected(event) }}
                  search
                />
              </div>
              <div className="col-lg-5">
                <Picker
                  onChange={event => {
                    this.setState({ category: event.target.value, keyword: '', page: 1 }, () => {
                      this.props.getItemsPerCategoryStandard(this.state.type, this.state.category, this.props.stage_master.procedure_id, this.props.stage_master.surgeon_id, this.state.selected_items, this.state.keyword, this.state.page)
                    })
                  }}
                  label={`Filter by category`}
                  value={this.state.category}
                  data={this.props.inventory_categories}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <Table minWidth={690}
                  className="modal-table infi-table" 

                  renderModal={() => {
                    return (<ViewItemCard id={`ais-preview-modal-asd123`} onRef={ref => (this.viewItemRef = ref)} item={this.state.selectedItem} ></ViewItemCard>)
                  }}

                  infiniteScroll
                  infiniteHeight={307}
                  infiniteDataLength={10} //This is important field to render the next data
                  infiniteNext={() => { }}
                  infiniteHasMore={false}

                  endOfMessageMessage="Nothing else to see..."

                  renderHeaders={() => {
                    return (
                      <TRow>
                        <THead className="w40" text="" />
                        <THead className="w50 d-none" text="PRN" />
                        <THead className="w150" text="Catalogue #" sortable onClick={() => this.sortSelectedBy('catalog_number')} />
                        <THead className="w250" text="Name" sortable onClick={() => this.sortSelectedBy('name')} />
                        <THead className="w150" text="Category" sortable onClick={() => this.sortSelectedBy('category')} />
                        <THead className="w80" text="Size" sortable onClick={() => this.sortSelectedBy('size')} />
                        <THead className="w80" text="Qty" />
                      </TRow>
                    )
                  }}
                  renderRows={() => {
                    let rows = selected_items_review.map(item => {
                      return (
                        <TRow hasChoices key={`row-ri-${item.id}`} autoCheck={false}>
                          <TData className="w40" onClick={() => {
                            this.checkItem(item.id);
                          }} >
                            <div className="custom-control custom-checkbox">
                              <input type="checkbox" className="custom-control-input" id={`checkbox-a1-${item.id}`} checked={this.checkItemExistence(item.id)} />
                              <label className="custom-control-label" htmlFor={`checkbox-a1-${item.id}`} onClick={(e) => { e.preventDefault() }}></label>
                            </div>
                          </TData>
                          <TData className="w50 d-none" onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            this.setPRN(item.id)
                          }}>
                            <div className="custom-control custom-checkbox">
                              <input type="checkbox" className="custom-control-input" id={`checkbox-ais1-${item.id}`} checked={this.isChecked(item.id)} />
                              <label className="custom-control-label" htmlFor={`checkbox-ais1-${item.id}`} onClick={(e) => { e.preventDefault() }}></label>
                            </div>
                          </TData>
                          <TData className="w150">{item.catalog_number}</TData>
                          <TData className="w250 has-action" item={item} popover onClick={(e) => {
                            e.stopPropagation()
                            this.setState({
                              selectedItem: item,
                              unique:
                                Math.random()
                                  .toString(36)
                                  .substring(2, 15) +
                                Math.random()
                                  .toString(36)
                                  .substring(2, 15),
                            })
                            $(`#ais-preview-modal-asd123`).modal('show')
                          }}>{item.name}</TData>
                          <TData className="w150">{item.category ? item.category.name : ''}</TData>
                          <TData className="w80">{item.size}</TData>
                          <TData className="w80" onClick={e=>{
                            e.stopPropagation();
                          }}><input type="number" name className="form-control text-center p-0  bg-white"
                            value={item.qty ? item.qty : ''}
                            onChange={(event) => {
                              if ($(event.target).val() <= 0) {
                                $(event.target).val(1)
                              }
                              let { selected_items } = this.state

                              let index = _.findIndex(selected_items, (e) => { return e.id == item.id })

                              if (index > -1) {
                                selected_items[index].qty = event.target.value
                              }

                              this.setState({
                                selected_items: selected_items
                              })

                              item.qty = event.target.value
                              items[index] = item
                              this.props.setItems(items)
                            }}
                            onKeyUp={(event) => {
                              if ($(event.target).val() <= 0) {
                                $(event.target).val(1)
                                let { selected_items } = this.state

                                let index = _.findIndex(selected_items, (e) => { return e.id == item.id })

                                if (index > -1) {
                                  selected_items[index].qty = 1
                                }

                                this.setState({
                                  selected_items: selected_items
                                })

                                item.qty = 1
                                items[index] = item
                                this.props.setItems(items)
                              }
                            }}
                          /></TData>
                        </TRow>
                      )
                    })
                    return rows
                  }}
                // renderNotification={() => {
                //     return (<TableNotificationCard type="success" message="Success message here..." />)
                // }}

                />

              </div>

            </div>
          </div>
        </Modal>

      
        <ViewItemModal
          key={this.state.unique}
          item={this.state.selectedItem}
          cancel={() => {
            $('viewItemModal').modal('hide')
          }}
        />    
      </div>
    )
  }
}



const mapStateToProps = (state) => {
  console.log("mapStateToProps", state.item.loading)
  return({
    medical_inventories: state.item.medical_inventories,
    inventory_categories: state.item.inventory_categories,
    items: state.item.items,
    more: state.item.more,
    loading:state.item.loading
  })
}

export default connect(mapStateToProps, {getMedicalInventories, getItemsPerCategoryStandard, setItems, updateQuicknotesItemList, getItems,itemLoading})(AssignItemsStandard)
