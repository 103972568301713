import {
    SET_SELECTED_SPECIALTY,
    FETCH_SPECIALTIES_DATA,
    FETCH_SPECIALTIES_DATA_COMPLETE,
    GET_SPECIALTY_CATEGORIES
  } from '../actions/types';
  
  const INITIAL_STATE = {
    items:[],
    loading: false,
    selectedSpecailty: {},
    categories: [],
    specialty: null
  };
  
  export default (state = INITIAL_STATE, action) => {
  
      switch (action.type) {
      case FETCH_SPECIALTIES_DATA:
        return { ...state,  loading: true};
      case FETCH_SPECIALTIES_DATA_COMPLETE:
        return { ...state,  loading: false, items: action.payload};
      case SET_SELECTED_SPECIALTY:
        return { ...state, selectedSpecailty: action.payload};
      case GET_SPECIALTY_CATEGORIES:
        return {...state, categories: action.categories, specialty: action.specialty}
      default:
        return state;
     }
  };
  