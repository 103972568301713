import React, { Component } from 'react'
import Item from '../../containers/items/Item'
import Inbodyloader from '../loader/Inbodyloader'
import BigPictureModal from './BigPictureModal';
import $ from 'jquery'
export class ViewItemModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isMounted: false, 
            selected_media: {}
        }
        this.setSelectedMedia = this.setSelectedMedia.bind(this)
        
    }
    componentDidMount(){
        setTimeout(() => { 
            this.setState({
                isMounted: true
            })
        }, 1000);
    }
    setSelectedMedia(media){
        this.setState({
            selected_media: media
        }, ()=>{
            $("#bigPictureModal").modal('show')
            setTimeout(() => {
            $('.modal-backdrop').last().addClass('opacity-9 z-index-2k') 
            }, 200);
        })
        
    }
    renderItem(item) {
        if(this.state.isMounted)
            return  (<div className="modal-body h-100 of-auto">
                {item ? <Item item={item}  setSelectedMedia={this.setSelectedMedia} /> : null} 
            </div>
            )
        return <Inbodyloader progress={5} />
            
    }
    render() {
        const { idModal, item } = this.props 
        
        return ( 
            <div>
                <BigPictureModal idModal="bigPictureModal" data={this.state.selected_media} />
                <div className="modal fade p-0 px-0 fullScreenModal" id={idModal ? idModal : 'viewItemModal'} tabIndex={-1} role="dialog" aria-labelledby="viewItemTitle" aria-hidden="true">
                    <div className="modal-dialog rounded-0 px-0 mb-0" role="document" style={{ maxWidth: '100%', background: 'var(--level6)' }}>
                        <div className="modal-content h-100 rounded-0" style={{ background: 'var(--level6)' }}>
                            {this.renderItem(item)}
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default ViewItemModal
