import React, { Component } from 'react'
import { Pills } from '../misc';

export class TagsCard extends Component {

  renderPills(tag){
    return (
      <Pills className="tags-pill">{tag.name}</Pills>
    )
  }

  render() {
    const { item } = this.props 
    const pills = item.tags ? item.tags.map(tag => { 
      if (tag.name.length > 0)
        return this.renderPills(tag)
      return null 
    }) : null
      
    return (
      <div className="card tags-card">
        <h3>Tags</h3>
        <div className="tags-holder">
          {pills}
        </div>
      </div>
    )
  }
}

export default TagsCard
