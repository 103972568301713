import React, { Component } from 'react'
import ReactDOMServer from 'react-dom/server'

export class TextElement extends Component {
  render() {
    const { data, className, procedure} = this.props
    let title = data.title;
    let content = data.contents ?  procedure ? data.contents:data.contents.caption : '';
    let items = null; 
    console.log('dataaaa', data,content)
    if (data.contents && !content && data.contents.length > -1 ){ 
      items = data.contents.map((content) => {
        return (
          <div className="card-body"
            dangerouslySetInnerHTML={{
              __html: content.content ? content.content:""
            }}
          />
        )
      }) 
    }

    return (
      <div className={`card text-preview ${className}`}>
        {
          title ? <div className="card-header text-capitalize ">{title}</div>:''
        }
        { content ? 
            <div className="card-body" dangerouslySetInnerHTML={{
            __html: content.content ? content.content : ""
            }}></div> : items
        } 
      </div>
    )
  }
}

export default TextElement
