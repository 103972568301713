import React, { Component } from 'react'
import axios from 'axios'
import InfiniteScroll from 'react-infinite-scroll-component'
import BigPicture from 'react-bigpicture'
import BigPictureModal from '../modals/BigPictureModal';
import _ from 'lodash'
import $ from 'jquery'
import img from '../../../src/assets/images/img.png'
import MediaCard from '../commons/cards/MediaCard';
import { TextInput } from '../commons/forms';

class Media extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      page: 1,
      loading: false,
      selected_item: [],
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      search: ''
    }
  }


  componentDidMount() {
    /* setInterval(() => {
      var w_ = $(".records-media .image-div").width() / 3 * 2;
      $(".records-media .image-div").height(w_);
    }, 500);  */
    this.makeRemoteRequest() 
  }

  makeRemoteRequest = () => {
    this.setState({
      loading: true,
    })
    const { page } = this.state

    axios
      .get(`api/my-galleries?page=${page}`)
      .then(res => {
        this.setState({
          data:
            page === 1 ? res.data.data : [...this.state.data, ...res.data.data],
          loading: false,
        })
      })
      .catch(errors => {
        console.log(errors)
        console.log(errors.response)
      })
  }

  loadMoreData() {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => {
        this.makeRemoteRequest()
      }
    )
  }
  

  render() {
    const search = _.debounce(keyword => {
      this.setState({
        search: $("#search_media_uploads").val(),
      })
    }, 500)

    const cards = this.state.data.map((media, index) => {
      console.log('mediamedia', media)
      let type = media.type
      let source = media.source

      if (type == 'video') {
        if (media.disk == 'vimeo') {
          type = 'vimeo'
          const video_id = source.split('/').pop()
          //source = `https://vimeo.com/${video_id}`
        }
      }

      let thumbnail = media.thumbnail
      if (thumbnail.split('.').pop() == 'mp4') {
        thumbnail = 'http://lorempixel.com/400/200/sports/5'
      }
      return (
        <div
          className="col-lg-3 col-md-4 col-sm-6 mb30 p-xsx0 c-pointer "
          type={type}
          caption={media.title}
          src={source}
          onClick={() => {
            this.setState({
              selected_item: {
                title: media.title,
                type: type,
                source: source
              }
            }, () => { 
                $("#media_type").val(type).change()
                $("#media_title").val(media.title).change()
                $("#media_source").val(source).change()
                $("#fullMediaViewModal").modal('show')  
            })
          }}
        >
          <MediaCard  media={media} />
        </div>
      )
    })
    return ( 
      <div className=" h451">
        <InfiniteScroll
          className=""
          dataLength={this.state.data.length}
          next={this.loadMoreData.bind(this)}
          hasMore={true}
          scrollThreshold={0.5}
          initialScrollY={1}
          scrollableTarget="nav-medias"
        >
          <div className="w-100 d-flex flex-wrap">{cards}</div>
        </InfiniteScroll> 
        </div>
    )
  }
}

export default Media
