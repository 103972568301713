import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getSpecialtyCategories } from '../actions/SpecialtyActions'
import { createSurgeonSpecialty } from '../actions/SurgeonActions'
import SpecailtyCard from './specailty_card'
import { Confirmation } from './Confirmation'
import $ from 'jquery'
import { Link } from 'react-router'
import axios from 'axios'
import { Button, TextInput } from './commons/forms'
import {
  TData,
  Table,
  THead,
  TRow,
  TableNotificationCard,
} from './commons/table'
import { Modal, FormModal } from './commons/modals'
import { Pills } from './commons/misc'
import { getArrayError } from '../utilities'



class SpecialtySection extends Component {
  constructor(props) {
    super(props)

    this.state = {
      specialty: null,
      procedures: [],
      category: null,
      selected_category: null,
      selected_categories: [],
      selected_procedures: [],
      current_categories: [],
      current_procedures: [],
      newprocedure_error: [],
      hasProcedures: true,
    }
  }

  componentDidMount = () => {
 /*    const { selected_categories, selected_procedures } = this.state
    if (this.props.surgeon.specialties) {
      this.props.surgeon.specialties.map(specialty => {
        if (!specialty) return
        specialty.categories.map(category => {
          selected_categories.push(category.category.id)
          let temp = []
          category.surgeonProcedures.map(procedure => {
            if (procedure.procedure) temp.push(procedure.procedure.id)
          })
          selected_procedures.push(temp)
        })
      })
    } */

    console.log('state,state', this.state)
  }

  procedures = id => {
    let { selected_categories, selected_procedures } = this.state
    let { specialty } = this.props
    this.setState(
      {
        category: id,
        procedure: [],
        hasProcedures: true,
      },
      () => {
        this.categoryTableNotif.hideThis()
      }
    )
    this.props.categories.map(category => {
      if (category.id == id) {
        if (!selected_categories.includes(id)) {
          selected_categories.push(id)
          selected_procedures.push([])
        }
        this.setState({
          procedures: category.procedures,
          selected_categories,
        })
      }
    })
  }

  selectedProcedures = id => {
    let {
      selected_procedures,
      selected_categories,
      category,
      current_categories,
      current_procedures,
    } = this.state

    let index = selected_categories.indexOf(category)
    if (selected_procedures[index] && index != -1) {
      if (selected_procedures[index].includes(id)) {
        selected_procedures[index] = selected_procedures[index].filter(
          e => e !== id
        )
      } else {
        selected_procedures[index].push(id)
      }
    }

    console.log(selected_procedures)
    this.setState({
      selected_procedures,
    })
  }

  check = id => {
    let { selected_procedures, selected_categories, category } = this.state
    return selected_procedures.flat().includes(id)
    let index = selected_categories.indexOf(category)
    if (selected_procedures[index] && index != -1) {
      if (selected_procedures[index].includes(id)) {
        return true
      } else {
        return false
      }
    }
  }

  save = () => {
    console.log(
      'this.state.selected_procedures',
      this.state.selected_procedures
    )
    if (this.state.selected_procedures.length == 0) {
      this.categoryTableNotif.showThis()
    } else {
     
      const { specialty,all_categories} = this.props
      let temp_categories = []
      let temp_procedures = []
      let categories = this.state.selected_categories
      let procedures = this.state.selected_procedures
      this.state.selected_categories.map((category, index) => {
        all_categories.map(c => {
          if (category == c.id) {
            temp_categories.push(category)
            temp_procedures.push(procedures[index])
          }
        })
      })

      categories = temp_categories
      procedures = temp_procedures

      console.log('test')
      console.log(categories)
      console.log(procedures)

      let data2 = new FormData()
    

      let url = `/api/client/surgeon-save-category/${this.props.surgeon.id}/${specialty}`

      categories.map(category => {
        let index = categories.indexOf(category)
        if (procedures[index].length > 0) {
          data2.append('category[]', category)

          procedures[index].map(procedure => {
            data2.append(`procedures_${category}[]`, procedure)
          })
        }
      })

      axios
        .post(url, data2)
        .then(res => {
          console.log('res.data.surgeon', res.data.surgeon)
          this.props.surgeonUpdate(res.data.surgeon)
          
        })
        .catch(error => {
          console.log(error)
          console.log(error.response)
        })
      $('#selectProceduresModal').modal('hide')
    }
  }

  checkActive = id => {
    let status = false
    this.props.surgeon.specialties.map(specialty => {
      if (!specialty.specialty) return
      if (specialty.specialty.id == id) {
        status = true
      }
    })
    return status
  }

  getCategories = data => {

    let procedures = []
    let categories_arr = []
    const specialities = this.props.surgeon.specialtyList
        if(specialities){
          let categories = specialities.flatMap( x => x.categories)
          if(categories){
           procedures = categories.map( cat => {
                  categories_arr.push(cat.id)
                  return cat.procedures.map( y => y.id)
            })
            console.log('proceduresprocedures',procedures)
          }
        }
    this.setState({
      procedures: [],
      category: null,
      selected_procedures:procedures,
      selected_categories:categories_arr
    }, () => {
      $('#selectProceduresModal').modal('show')
    })
    this.props.getSpecialtyCategories(data.id)
   
  }

  saveprocedure = () => {
    const { surgeon } = this.props
    this.setState({
      newprocedure_error: [],
    })
    let data = new FormData()
    data.append('category_id', this.state.category)
    data.append('name[]', this.state.newprocedure)
    data.append('surgeon_id', surgeon.id)
    let url = 'api/specialty-procedure'

    let index = this.state.selected_categories.indexOf(this.state.category)
    let { selected_procedures, procedures } = this.state

    axios
      .post(url, data)
      .then(res => {
        console.log(res)
        selected_procedures[index].push(res.data[0].id)
        procedures.push(res.data[0])
        this.setState({
          selected_procedures,
          procedures,
          newprocedure: '',
          newprocedure_error: [],
        })
        this.procedureTableNotify.showThis()
        //$('#procedure-modal').modal('hide')
        $('#procedureModal').modal('hide')
        $('#selectProceduresModal').modal('show')
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
        this.setState({
          newprocedure_error:error.response.data.errors
        })
      })
  }

  render() {
    const cards = this.props.items.map(data => {
      return (
        <Button
          secondary
          large
          className="ml0 mb28 mr28 col-lg-3 col-md-4"
          key={`specialty-btn-${data.id}`}
          active={this.checkActive(data.id)}
          onClick={() => {
            this.setState(
              {
                hasProcedures: false,
              },
              () => {
                this.categoryTableNotif.hideThis()
              }
            )
            this.getCategories(data)
          }}
        >
          <img src={data.icon} className="specialty-btn-img" />
          {data.name}
        </Button>
      )
      {
        /* <SpecailtyCard
          key={data.id}
          icon={data.icon}
          name={data.name}
          active={this.checkActive(data.id)}
          getSpecialtyCategories={() => {
            this.getCategories(data)
          }}
        /> */
      }
    })

    const categories = this.props.categories.map(category => {
      const selected = this.state.selected_categories.findIndex(
        el => el === category.id
      )
      console.log(selected)
      let count = 0
      if (selected >= 0) {
        if (this.state.selected_procedures[selected].length != undefined)
          count = this.state.selected_procedures[selected].length
      }
      console.log('catss',this.state.selected_categories,this.state.selected_procedures,selected,count)
      return (
        <tr
          key={category.id}
          onClick={() => {
            this.procedures(category.id)
            $(`#${category.id}`)
              .closest('table')
              .find('tr')
              .removeClass('_active_fixed')
            $(`#${category.id}`)
              .closest('tr')
              .addClass('_active_fixed')
            $(`#${category.id}`)
              .closest('tr')
              .find('label')
              .click()
          }}
        >
          <td className="text-center mw50 pr-0">
            <div
              className="custom-control custom-radio custom-control-inline c-pointer"
              style={{ marginRight: 0, paddingLeft: 25 }}
            >
              <input
                type="radio"
                type="radio"
                name="radiobtn"
                id={category.id}
                onChange={() => {
                  this.procedures(category.id)
                  $(`#${category.id}`)
                    .closest('table')
                    .find('tr')
                    .removeClass('_active_fixed')
                  $(`#${category.id}`)
                    .closest('tr')
                    .addClass('_active_fixed')
                }}
                className="custom-control-input  c-pointer"
              />
              <label
                className="custom-control-label  c-pointer"
                htmlFor={category.id}
              >
                &nbsp;
              </label>
            </div>
          </td>
          <td
            className="text-left"
            htmlFor={category.id}
            style={{ width: 'calc(100% - 50px)' }}
          >
            <label
              className="c-pointer font-weight-normal"
              htmlFor={category.id}
            >
              {category.name}
            </label>
            {count > 0 ? (
              <span className="badge badge-pill badge-secondary float-right px15 py5">
                {count}
              </span>
            ) : (
              ''
            )}
          </td>
        </tr>
      )
    })

    const procedures = this.state.procedures.map(procedure => {
      return (
        <tr
          class={this.check(procedure.id) ? '_active_fixed' : ''}
          onClick={() => {
            this.selectedProcedures(procedure.id)
            $(`#procedure-${procedure.id}`)
              .closest('tr')
              .find('label')
              .click()
          }}
        >
          <td className="text-center mw50 max-w50 pr-0">
            <div
              className="custom-control custom-checkbox"
              style={{ marginRight: 0, paddingLeft: 25 }}
            >
              <input
                type="checkbox"
                className="custom-control-input c-pointer"
                id={`procedure-${procedure.id}`}
                onChange={() => {
                  this.selectedProcedures(procedure.id)
                }}
                checked={this.check(procedure.id)}
              />{' '}
              <label
                className="custom-control-label c-pointer"
                htmlFor={`procedure-${procedure.id}`}
              >
                &nbsp;
              </label>
            </div>
          </td>
          <td style={{ width: 'calc(100% - 50px)' }}>
            <label
              className="c-pointer font-weight-normal"
              htmlFor={`procedure-${procedure.id}`}
            >
              {procedure.name}
            </label>
          </td>
        </tr>
      )
    })

    return (
      <div className="row">
        <div className="col-md-12">
          <h1 className="page-title">
            {this.props.isEditMode ? 'Edit Surgeon' : 'Add new Surgeon'}
          </h1>
          <h2 className="page-sub-title">Add specialty and procedures</h2>
          <p className="page-description">
            Once you select a specialty, you’ll then add relevant procedures
          </p>
        </div>

        <div className="col-md-12 d-flex flex-wrap justify-content-center justify-content-md-start">
          {cards}
        </div>

        <Modal
          idModal="selectProceduresModal"
          size="modal-lg"
          title="Select Procedures"
          confirmText="Save procedures"
          cancelText="Cancel"
          confirm={e => {
            this.save()
          }}
          cancel={e => {
            //fn here
          }}
          bodyClass="select-procedures-body"
        >
          <p>You can add multiple categories and procedures below</p>

          <div className="row">
            <div className="col-lg-6 pr10">
              <Table
                minWidth={370}
                className="modal-table has-placeholder"
                renderHeaders={() => {
                  return (
                    <TRow>
                      <THead className="w40  d-none" text="" />
                      <THead className="w270" text="Category" />
                      <THead className="w50" text="" />
                    </TRow>
                  )
                }}
                renderRows={() => {
                  // const found = this.props.categories.some(r => {
                  //   console.log('r.id', r, this.state.selected_categories)
                  //   this.state.selected_categories.includes(r.id)
                  // })

                  //   if(!found){
                  //     console.log('this.categoryTableNotif', this.categoryTableNotif ? this.categoryTableNotif.showThis():null)
                  //   }

                  let rows = this.props.categories.map(category => {
                    const selected = this.state.selected_categories.findIndex(
                      el => el === category.id
                    )
                    let count = 0
                    if (selected >= 0) {
                      if (
                        this.state.selected_procedures[selected].length !=
                        undefined
                      )
                        count = this.state.selected_procedures[selected].length
                    }
                    if (count > 0 && !this.state.hasProcedures) {
                      this.setState(
                        {
                          hasProcedures: true,
                        },
                        () => {
                          this.categoryTableNotif.hideThis()
                        }
                      )
                    }
                    return (
                      <TRow
                        hasChoices
                        key={`category-row-${category.id}`}
                        onClick={() => {
                          this.setState({
                            selected_category: category,
                          })
                          this.procedures(category.id)
                        }}
                      >
                        <TData className="w40 d-none">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id={`category-chck-${category.id}`}
                              checked={count > 0}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={`category-chck-${category.id}`}
                            ></label>
                          </div>
                        </TData>
                        <TData className="w270">{category.name}</TData>
                        <TData className="w50">
                          {count > 0 ? <Pills content={count} /> : null}
                        </TData>
                      </TRow>
                    )
                  })
                  return rows
                }}
                renderNotification={() => {
                  return (
                    <TableNotificationCard
                      type="error"
                      message="No procedure selected"
                      onRef={ref => (this.categoryTableNotif = ref)}
                    />
                  )
                }}
              />
            </div>

            <div className="col-lg-6 pl10">
              <Table
                minWidth={370}
                className="modal-table has-placeholder"
                dataText={`${
                  this.state.procedures.length
                    ? ''
                    : 'Select a surgical area to view a list of procedures'
                }`}
                renderHeaders={() => {
                  return (
                    <TRow>
                      <THead className="w40" text="" />
                      <THead className="w320" text="Procedure" />
                    </TRow>
                  )
                }}
                renderRows={() => {
                  let rows = this.state.procedures.map(procedure => {
                    return (
                      <TRow
                        hasChoices
                        key={`procedure-row-${procedure.id}`}
                        hightlight
                      >
                        <TData className="w40">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id={`procedure-chck-${procedure.id}`}
                              onChange={() => {
                                this.selectedProcedures(procedure.id)
                              }}
                              checked={this.check(procedure.id)}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={`procedure-chck-${procedure.id}`}
                              onClick={e => {
                                e.stopPropagation()
                              }}
                            ></label>
                          </div>
                        </TData>
                        <TData className="w320">{procedure.name}</TData>
                      </TRow>
                    )
                  })
                  return rows
                }}
                renderLastRow={() => {
                  if (this.state.category) {
                    return (
                      <tr className="add-data-row">
                        <td
                          colSpan="2"
                          onClick={() => {
                            this.setState({
                              newprocedure: '',
                              newprocedure_error: '',
                            })
                            $('#selectProceduresModal').modal('hide')
                            $('#procedureModal').modal('show')
                          }}
                        >
                          Add new procedure +
                        </td>
                      </tr>
                    )
                  }
                  return null
                }}
                renderNotification={() => {
                  return (
                    <TableNotificationCard
                      type="success"
                      message="New procedure added!"
                      onRef={ref => (this.procedureTableNotify = ref)}
                    />
                  )
                }}
              />
            </div>
          </div>
        </Modal>

        {/*  Add new stage == modal-xs*/}
        <FormModal
          idModal="procedureModal"
          title="Adding new procedure"
          confirmText="Save procedure"
          cancelText="Close"
          closeModalOnSubmit={false}
          confirm={e => {
            this.saveprocedure()
          }}
          cancel={e => {
            $('#procedureModal').modal('hide')
            $('#selectProceduresModal').modal('show')
          }}
        >
          <div className="row">
            <div className="col-12">
              You're adding a new procedure under{' '}
              <b>
                {this.state.selected_category
                  ? this.state.selected_category.name
                  : null}
              </b>
            </div>
            <div className="col-12">
              <TextInput
                label={'Procedure name'}
                value={this.state.newprocedure}
                errorMessage={getArrayError(this.state.newprocedure_error,'name',0)}
                onValueChange={event => {
                  this.setState({ newprocedure: event.target.value })
                }}
                onKeyPress={event => {
                  if (event.key == 'Enter') this.onSubmit()
                }}
              />
            </div>
            <div className="col-12">
              You’ll be able to edit and customize this procedure under the surgeons procedure cards.
            </div>
          </div>
        </FormModal>

        <Confirmation
          title="Conf"
          subTitle=""
          confirmText=""
          cancelText=""
          confirm="4\4"
          cancel=""
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    categories: state.specialty.categories,
    items: state.specialty.items,
    specialty: state.specialty.specialty,
    all_categories: state.specialty.categories
  }
}

export default connect(
  mapStateToProps,
  { getSpecialtyCategories, createSurgeonSpecialty }
)(SpecialtySection)
