import React, { Component } from 'react'
import ReactDOMServer from 'react-dom/server'
import $ from 'jquery'
import { Link } from 'react-router'
import ViewItemModal from './ViewItemModal';
import ViewItemCard from '../commons/cards/ViewItemCard';
import { Table, TRow, THead, TData } from '../commons/table';
import { Modal } from '../commons/modals';
import { Picker } from '../commons/forms';
import _ from 'lodash'

export class ReviewItemsModal extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      selectedItem: []
    }
  }

  componentWillReceiveProps(nextProps) {
    if(this.props != nextProps){
      this.setState({
        selected_items: nextProps.selected_items
      })
    }
  }
  

  sortBy(key) {
    let arrayCopy = [...this.props.selected_items]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }

    // this.props.setItems(arrayCopy)
    this.props.setNewState({
      selected_items: _.cloneDeep(arrayCopy)
    })
    this.setState({ isReverse: !this.state.isReverse })
  }

  compareBy(key) {
    if (key == 'category') {
      return function (a, b) {
        if (a.category.name < b.category.name) return -1
        if (a.category.name > b.category.name) return 1
        return 0
      }
    }
    return function (a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }


  render() {
    const { selected_items } = this.props

    return (
      <Modal
        idModal="reviewPEItemsModal"
        size="modal-xl"
        title="Review Items"
        confirmText="Assign Items"
        cancelText="Cancel"
        confirm={(e) => {
          this.props.setNewState({ selected_items: this.state.selected_items }) 
          $("#reviewPEItemsModal").modal('hide')
        }}
        cancel={(e) => {
          //fn here
        }}
        midButtonText="Back"
        midButtonAction={(e) => {
          $('#reviewPEItemsModal').modal('hide')
          $('#assignItemsDefault').modal('show')
        }}
        bodyClass="assign-items-body"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Table
                minWidth={890} className="modal-table infi-table"
                key={'review_postion-key-1'}
                renderModal={() => {
                  return (<ViewItemCard id={`aisrp-preview-modal-4213`} onRef={ref => (this.viewItemRef = ref)} item={this.state.selectedItem} ></ViewItemCard>)
                }}


                infiniteScroll infiniteHeight={307}
                infiniteDataLength={10} //This is important field to render the next data
                infiniteNext={() => { }}
                infiniteHasMore={false}

                // endOfMessageTitle='Nothing else to see...'
                endOfMessageMessage="Nothing else to see..."
                // endOfMessageLinkMessage="Edit database"

                renderHeaders={() => {
                  return (
                    <TRow>
                      <THead className="w40" text="" />
                      <THead className="w150" text="Catalogue #" sortable onClick={() => this.sortBy('catalog_number')} />
                      <THead className="w200" text="Name" sortable onClick={() => this.sortBy('name')} />
                      <THead className="w100" text="Category" sortable onClick={() => this.sortBy('category')} />
                      <THead className="w100" text="Qty" />
                    </TRow>
                  )
                }}
                renderRows={() => {
                  let si = selected_items.filter(item => item.body_area == this.props.selected_body_area)
                  let rows = selected_items.map((item, index) => {


                    return (
                      <TRow hasChoices key={`row-rp-${index}`} autoCheck={false}>
                        <TData className="w40" onClick={() => { this.props.checkItem(item.id) }}>
                          <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id={`checkbox-a1-${item.id}`} checked={true} />
                            <label className="custom-control-label" htmlFor={`checkbox-a1-${item.id}`} onClick={(e) => { e.preventDefault() }}></label>
                          </div>
                        </TData>
                        <TData className="w150">{item.catalog_number}</TData>
                        <TData className="w200 has-action" item={item} popover onClick={(e) => {
                          e.stopPropagation()
                          this.setState({
                            selectedItem: item,
                            unique:
                              Math.random()
                                .toString(36)
                                .substring(2, 15) +
                              Math.random()
                                .toString(36)
                                .substring(2, 15),
                          })
                          $(`#aisrp-preview-modal-4213`).modal('show')
                        }}>{item.name}</TData>
                        <TData className="w100">{item.category ? item.category.name:''}</TData>
                        <TData className="w100" onClick={(e) => { e.stopPropagation() }}>
                          <div className="w-100 d-flex align-items-center justify-content-center">
                            <input type="text" name className="form-control text-center bg-white" pattern="^[0-9]*$" value={item.qty != null ? item.qty : 1}
                              onClick={(e) => { e.stopPropagation() }}
                              onChange={(event) => {
                                // console.log('$(event.target).val().length', $(event.target).val().length)
                                if (/^[1-9][0-9]*$/.test($(event.target).val()) || !$(event.target).val().length) {

                                  let { selected_items } = this.state
                                  let index = _.findIndex(selected_items, (e) => { return e.id == item.id })
                                  if (index > -1) {
                                    selected_items[index].qty = event.target.value
                                  }

                                  this.props.setNewState  ({
                                    selected_items: selected_items
                                  })

 
                                }
                              }}
                              onBlur={(event) => {
                                if (!$(event.target).val().length) {
                                  let { selected_items } = this.state
                                  let index = _.findIndex(selected_items, (e) => { return e.id == item.id })
                                  if (index > -1) {
                                    selected_items[index].qty = 1
                                  }

                                  this.props.setNewState({
                                    selected_items: selected_items
                                  }) 

                                }
                              }}
                            />
                          </div>
                        </TData>
                      </TRow>
                    )
                  })
                  return rows
                }}
              // renderNotification={() => {
              //     return (<TableNotificationCard type="success" message="Success message here..." />)
              // }}

              />

            </div>

          </div>
        </div>
      </Modal> 

    )
  }
}

export default ReviewItemsModal
