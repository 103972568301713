import React, { Component } from 'react'

export class ConfirmationModal extends Component {
    render() {
        const { title, idModal, message, confirmText, cancelText } = this.props
        
        return (
            <div className="modal fade modal-xs" id={idModal ? idModal : 'confirmationModal'} tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                    
                        {
                            title ? 
                            <div className="modal-header text-center">
                                <h3 className="modal-title">{title}</h3>
                            </div>:null
                        }
                         
                        <div className="modal-body text-center" dangerouslySetInnerHTML={{ __html: message}}>
                        </div>
                        <div className="modal-footer text-center">
                            <div className="cta cta-primary" data-dismiss="modal" onClick={(event) => { this.props.confirm(event) }} >{confirmText}</div>
                            {
                                this.props.cancel ? 
                                <div className="cta cta-secondary" data-dismiss="modal" onClick={this.props.cancel}>{cancelText}</div>
                                :null
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ConfirmationModal
