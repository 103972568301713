import React, { Component } from 'react'
import $ from 'jquery'
export class SrollableTabs extends Component {

	constructor(props) {
		super(props)
	
		this.state = {
			id: this.makeid(10),
			showNext: false,
			showPrev: false,
			navLinksOffsets: [],
			nextScrollWidth: 0,
			prevScrollWidth: 0,
			onLast: false,
			goBack: false
		}

	}

	makeid(length) {
		var result = '';
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}
	
	componentDidMount() { 
		const { id } = this.state 
		//console.log("$(`#${id} .scrollable-tabs`)", $(`#${id} .scrollable-tabs`))
		let width = $(`#${id} .scrollable-tabs`)[0].clientWidth
		let scrollWidth = $(`#${id} .scrollable-tabs`)[0].scrollWidth

		if(scrollWidth > width){
			this.setState({
				showNext: true,
				showPrev: false,
			})
		}

		let links = []
		$(`#${id} .nav-link`).each( index =>{
			// console.log('link', $($(`#${id} .nav-link`)[index])[0].offsetLeft )
			links.push({ 'index': index, 'offsetLeft': $($(`#${id} .nav-link`)[index])[0].offsetLeft, 'element': $($(`#${id} .nav-link`)[index]), 'offsetWidth': $($(`#${id} .nav-link`)[index])[0].offsetWidth })
		})

		var selected = links.find(x => (x.offsetLeft + x.offsetWidth) >= 0) 

		this.setState({
			navLinksOffsets: links,
			nextScrollWidth: selected.offsetWidth
		})

		$(`#${id} .scrollable-tabs`).on('scroll', (e) => {
			var prev_selected = links.find(x => (x.offsetLeft + x.offsetWidth) >= Math.floor(e.target.scrollLeft))
			var selected = links.find(x => (x.offsetLeft + x.offsetWidth) >= Math.floor(e.target.scrollLeft)+5)
			this.setState({ 
				showPrev: Math.floor(e.target.scrollLeft) > 0,
				nextScrollWidth: selected.offsetWidth,
				prevScrollWidth: prev_selected.offsetWidth,
				onLast: Math.floor(e.target.scrollLeft) == scrollWidth - width
			})
			//console.log('eeeeeeeeeeee', Math.floor(e.target.scrollLeft), width, scrollWidth - width, selected)
		})
		$(`#${id} .nav-link`).on('click', (e)=>{
			e.preventDefault()
			// console.log('e', e.target.offsetLeft)
			$(`#${id} .scrollable-tabs`).animate({scrollLeft: `${e.target.offsetLeft-55}px` }, 150)
		})
	}
	
	goPrev() {
		const { id } = this.state
		const { prevScrollWidth } = this.state
		$(`#${id} .scrollable-tabs`).animate({ scrollLeft: `-=${prevScrollWidth}px` }, 150)
	}
	
	goNext(){
		const { id } = this.state
		const { nextScrollWidth, onLast } = this.state
		// console.log('next', onLast) 
		if (onLast){
			$(`#${id} .scrollable-tabs`).animate({ scrollLeft: `0px` }, 150)
			this.setState({ 
				onLast: false
			})
		}else{ 
			$(`#${id} .scrollable-tabs`).animate({ scrollLeft: `+=${nextScrollWidth}px` }, 150)
		}
	}


	render() {
		const { navLinks, contents } = this.props
		return (
			<div className="scrollable-tab-parent" id={this.state.id}>

				<div className={`prev ${this.state.showPrev ? '':'d-none'}`} onClick={()=>{
					this.goPrev()
				}}>
					<i className="fa fa-chevron-left"></i>
				</div> 
				<div className={`next ${this.state.showNext ? '':'d-none'}`} onClick={()=>{
					this.goNext()
				}}>
					<i className="fa fa-chevron-right"></i>
				</div>

				<div className="scrollable-tabs">
					<nav class="nav">
						{navLinks ? navLinks():null}
					</nav>
				</div>

				{contents ? contents() : null}
			</div>
		)
	}
}

export default SrollableTabs
