import arrayMove from 'array-move'
import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { progressVideo } from '../actions/VideoAction'
import AddNewSection from './commons/media_elements/AddNewSection'
import MediaEditor from './commons/media_elements/MediaEditor'
import MediaElement from './commons/media_elements/MediaElement'
import StandardEditor from './commons/media_elements/StandardEditor'
import StandardElement from './commons/media_elements/StandardElement'
import TextEditor from './commons/media_elements/TextEditor'
import TextElement from './commons/media_elements/TextElement'
 
export class StandardElementor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      standardData: [],
      editMode: true,
      preview: false,
      progressData:[]

    }

    this.elementsRef = []

    const SortableItem = SortableElement(({ media, index2 }) => {
      const index = index2
      switch (media.type) {
        case 'text':
          return (
            <li>
              <TextEditor
                elementRef={(e) => { this.elementsRef[index2] = e }}
                data={{
                  title: media.title,
                  content: media.contents.caption,
                }}
                validation={{
                  errors: this.props.errors,
                  prefix: 'elements',
                  index: index,
                  field: 'title'
                }}
                mentions={this.props.selected_items ? this.props.selected_items : []}
                onTitleChange={event => {
                  this.titleChange(event, index)
                }}
                textContentChange={event => {
                  this.textContentChange(event, index)
                }}
                onDelete={event => this.deleteElement(event, index)}
                onDuplicate={event => this.duplicateElement(event, index)}
                
              />
            </li>
          )
          break
        case 'media':
          return (
            <li>
              <MediaEditor
                elementRef={(e) => { this.elementsRef[index2] = e }}
                data={{
                  title: media.title,
                  medias: media.contents,
                }}
                validation={{
                  errors: this.props.errors,
                  prefix: 'elements',
                  index: index,
                  field: 'title'
                }}
                onTitleChange={event => {
                  this.titleChange(event, index)
                }}
                onContentTitleChange={(event, i) => {
                  this.onContentTitleChange(event, i, index)
                }}
                onContentCaptionChange={(event, i) => {
                  this.onContentCaptionChange(event, i, index)
                }}
                onContentMediaChange={(event, i) => {
                  this.onContentMediaChange(event, i, index)
                }}
                onContentThumbnailChange={(event, i) => {
                  this.onContentThumbnailChange(event, i, index)
                }}
                onContentSortChange={(contents, i) => {this.onContentSortChange(contents, index)}}
                addNewMedia={event => this.addNewMedia(event, index)}
                onDuplicate={event => this.duplicateElement(event, index)}
                onDelete={event => this.deleteElement(event, index)}
                onDeleteMediaElement={this.deleteMediaElement}
                progressMedia={() => {
                  console.log('progressMediaprogressMedia')
                  this.setState({
                    progressData: [...this.state.progressData, true],
                  })
                  if(this.props.progressList){
                    this.props.progressList()
                  }
                }}
                progressComplete={() => {
                  this.setState({
                    progressData: [...this.state.progressData, true],
                  })
                  if(this.props.progressList){
                    this.props.progressList()
                  }

              }}

              />
            </li>
          )
          break
        case 'standard':
          return (
            <li>
              <StandardEditor
                elementRef={(e) => { this.elementsRef[index2] = e }}
                data={{
                  title: media.title,
                  text: media.contents ? media.contents.caption:'',
                  media: {
                    thumbnail: media.contents ? media.contents.gallery ? media.contents.gallery.thumbnail : '':'',
                    source: media.contents ? media.contents.gallery ? media.contents.gallery.source : '':'',
                  },
                }}
                validation={{
                  errors: this.props.errors,
                  prefix: 'elements',
                  index: index,
                  field: 'title'
                }}
                onTitleChange={event => {
                  this.titleChange(event, index)
                }}
                onStandardTextChange={event => {
                  this.standardTextChange(event, index)
                }}
                thumbnailChange={thumbnail => {
                  this.standardThumbnailChange(thumbnail, index)
                }}
                onMediaChange={event => {
                  this.mediaStandardChange(event, index)
                }}
                onDelete={event => this.deleteElement(event, index)}
                onDuplicate={event => this.duplicateElement(event, index)}
                progressMedia={() => {
                  console.log('progressMediaprogressMedia')
                  this.setState({
                    progressData: [...this.state.progressData, true],
                  })
                }}
                progressComplete={() => {
                  this.setState({
                    progressData: [...this.state.progressData, true],
                  })
              }}
              />
            </li>
          )
          break
        default:
          break
      }
    })

    this.SortableList = SortableContainer(({ items }) => {
      return (
        <ul className="list-unstyled">
          {items.map((value, index) => (
            <SortableItem
              key={`item-${index}`}
              index={index}
              index2={index}
              media={value}
            />
          ))}
        </ul>
      )
    })
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.props.setNewState(({ standardData }) => ({
      errors: [],
      standardData: arrayMove(standardData, oldIndex, newIndex),
    }))
  }

  onContentSortChange = (contents, index) => {
    const { standardData } = this.props
    standardData[index].contents = contents

    this.props.setNewState({
      errors: [],
      standardData: standardData.splice(0),
    })
  }

  newSectionMenu = () => {
    return (
      <div className="row mt30">
        <div className="col-12">
          <AddNewSection 
            addTextOnly={() => {
              this.newTextSection()
            }}
            addMediaGallery={() => {
              this.newMediaSection()
            }}
            addTextAndMedia={() => {
              this.newStandardSection()
            }} 
          /> 
        </div>
      </div>
    )
  }

  newTextSection = () => {
    const { standardData } = this.props
    let textsection = {
      title: null,
      type: 'text',
      contents: {
        caption: '',
      },
    }
    standardData.push(textsection)
    this.props.setNewState({
      standardData: standardData,
    })
  }

  deleteElement = (event, index) => {
    const { standardData } = this.props
    event.preventDefault()
    standardData.splice(index, 1)
    this.props.setNewState({
      errors: [],
      standardData: standardData,
    })
  }

  duplicateElement = (event, index) => {
    let { standardData } = this.props
    event.preventDefault()
    standardData.push(_.cloneDeep(standardData[index]))
    this.props.setNewState({
      standardData: standardData,
    }, () => {
      this.elementsRef[standardData.length - 1].scrollIntoView({behavior: 'smooth'});
    })
  }

  deleteMediaElement = (event, index, subIndex) => {
    event.preventDefault()
    const { standardData } = this.props

    standardData[index].contents.splice(subIndex, 1)

    this.props.setNewState({
      errors: [],
      standardData: standardData.splice(0),
    })
  }

  newMediaSection = () => {
    const { standardData } = this.props
    let mediasection = {
      title: null,
      type: 'media',
      contents: [
        {
          title: null,
          caption: null,
          gallery: {},
        },
      ],
    }
    standardData.push(mediasection)
    this.props.setNewState({
      standardData: standardData,
    })
  }

  newStandardSection = () => {
    const { standardData } = this.props
    let standardsection = {
      title: null,
      type: 'standard',
      contents: {
        caption: '',
        gallery: {
          source: null,
          thumbnail: null,
        },
      },
    }

    standardData.push(standardsection)
    this.props.setNewState({
      standardData: standardData,
    })
  }

  titleChange = (event, index) => {
    const { standardData } = this.props
    standardData[index].title = event.target.value

    this.props.setNewState({
      standardData: standardData,
    })
  }

  textContentChange = (event, index) => {
    const { standardData } = this.props
    standardData[index].contents.caption = event.editor.getData()

    this.props.setNewState({
      standardData: standardData,
    })
  }

  standardTextChange = (event, index) => {
    const { standardData } = this.props
    standardData[index].contents.caption = event.editor.getData()

    this.props.setNewState({
      standardData: standardData,
    })
  }

  mediaStandardChange = (event, index) => {
    const { standardData } = this.props
    standardData[index].contents.gallery.source = event.data
    standardData[index].contents.gallery.type = event.type
    if (event.type == 'image')
      standardData[index].contents.gallery.thumbnail = event.data

    this.props.setNewState({
      standardData: standardData,
    })
  }

  standardThumbnailChange = (thumbnail, index) => {
    const { standardData } = this.props
    standardData[index].contents.gallery.thumbnail = thumbnail
    this.props.setNewState({
      standardData: standardData,
    })
  }

  addNewMedia = (event, index) => {
    event.preventDefault()
    const { standardData } = this.props
    standardData[index].contents.push({
      gallery: {
        show: true,
        thumbnail: null,
        source: null,
        caption: null,
        title: null,
        id: 0,
      },
    })
    
    this.props.setNewState({
      standardData: standardData,
    })
  }

  onContentTitleChange = (event, item_index, index) => {
    const { standardData } = this.props
    standardData[index].contents[item_index].gallery.title = event.target.value

    this.props.setNewState({
      standardData: standardData,
    })
  }

  onContentCaptionChange = (event, item_index, index) => {
    const { standardData } = this.props
    standardData[index].contents[
      item_index
    ].gallery.caption = event.editor.getData()
    
    this.props.setNewState({
      standardData: standardData,
    })
  }

  onContentMediaChange = (event, item_index, index) => {
    const { standardData } = this.props
    standardData[index].contents[item_index].gallery.source = event.data
    standardData[index].contents[item_index].gallery.type = event.type
    if (event.type == 'image')
      standardData[index].contents[item_index].gallery.thumbnail = event.data
    
      this.props.setNewState({
      standardData: standardData,
    })
  }

  onContentThumbnailChange = (event, item_index, index) => {
    const { standardData } = this.props
    standardData[index].contents[item_index].gallery.thumbnail = event

    this.props.setNewState({
      standardData: standardData,
    })
  }

  renderEditMode = () => {
    const { preview } = this.state
    const { standardData } = this.props

    if(preview){
      return (
        this.renderView()
      )
    }else{
      return (
        <div className="row">
          <div className="col-md-12">
            <div className=" " id="itemListAccordion">
              {
                <this.SortableList
                  items={standardData}
                  onSortEnd={this.onSortEnd}
                  distance={1}
                  useDragHandle
                  useWindowAsScrollContainer={true}
                />
              }
              {this.newSectionMenu()}
            </div>
          </div>
        </div>
      )
    }

    
  }

  renderView = () => {
    const { standardData } = this.props

    const viewData = standardData.map(item => {
      switch (item.type) {
        case 'text':
          return (
            <TextElement
              className="mb20"
              title={item.title}
              data={{
                title: item.title,
                contents: {
                  content: item.contents.caption,
                }
              }}
              procedure={true}
            />
          )
        case 'media':
          return( 

            <MediaElement data={item.contents} title={item.title} className="mb20" /> 
          )
        case 'standard':
          let media = item.contents
          return(
            <StandardElement className="mb20" data={{ title: item.title, contents: media }} />
          )
        default:
          return
      }
    })

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="" id="itemListAccordion">
            {viewData}
          </div>
        </div>
      </div>
    )
  }

  render() {
    console.log('xxx', this.props)

    return (
      <div className="elementor mx-auto">
        {this.props.edit ? this.renderEditMode() : this.renderView()}
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
  }
}

export default connect(mapStateToProps,{
  progressVideo
})(StandardElementor)
