import React, { Component } from 'react';
import img from '../assets/images/img.png'
import default_profile from '../assets/images/default_profile.jpg'
import { connect } from 'react-redux'
import { SurgeonInfoCard } from './commons/cards';

class SurgeonPreview extends Component {

	renderEnviroment() {
		const { surgeon } = this.props
		// console.log(surgeon.environment)
		if (surgeon.environment.length > 0) {
			if (surgeon.environment == 'silent')
				return <b>Silent OR</b>
			return <p>{/* <b>Normal</b> OR noise */}</p>
		}

		return ''
	}
	render() {
		const { surgeon } = this.props
		console.log('surgoen in Preview',surgeon)
		return (
			<SurgeonInfoCard surgeon={surgeon} />
		);
	}
}

const mapStateToProps = (state) => {
	return ({
		surgeon: state.surgeon,
		isNext: state.surgeon.isNext,
	})
}

export default SurgeonPreview;



{/* <div className="card side-card border-0">
    				  	<div className="card-body border-0">
    				  		<div className="row">
							  
    				  			<div className="col-md-12 text-center">
    				  				<img src={surgeon.avatar ? surgeon.avatar : default_profile} className="bg-dark rounded-circle h100 w100 mb10"/>	
    				  			</div>
    				  			<div className="col-md-12 text-center">
    				  				<h5 className="m-0">{fullname.trim(' ') ? fullname : 'Full name'}</h5>
										<p className="fw-100 m-0">{surgeon.title ? surgeon.title : 'No title'}</p>
    				  				<p>{surgeon.mobile ? surgeon.mobile : 'No mobile'}</p>
    				  			</div>
    				  			<div className="col-md-12">
							<h6 className="mb-3 font-weight-bold">{surgeon.handness ? `${surgeon.handness.charAt(0).toUpperCase()}${surgeon.handness.slice(1)} handed` : 'Handedness'}</h6>
    				  			</div>
    				  			<div className="col-md-12">
                                    <h6 className="mb5 font-weight-bold">Gloves</h6>
                                    <p className="mb20">{surgeon.glove_inner ? surgeon.glove_inner.name : 'No gloves'}</p>
    				  			</div>
    				  			<div className="col-md-12">
                                    <h6 className="mb5 font-weight-bold">Outer Gloves</h6>
                                    <p className="mb20">{surgeon.glove_outer ? surgeon.glove_outer.name : 'No gloves'}</p>
    				  			</div>
    				  			<div className="col-md-12">
                                    <h6 className="mb5 font-weight-bold">Prefers</h6>
                                    <p className="mb5">Music <b>{surgeon.music ? 'ON' : 'OFF'}</b></p>
                                    <p className="m-0"> {this.renderEnviroment()}</p> 
    				  			</div>
    				  		</div>
    				  	</div>
					</div> */}