import $ from 'jquery'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import tippy from 'tippy.js'
import emma from '../../assets/images/default_profile.jpg'
import procedure_img from '../../assets/images/Procedure_300x.png'
import surgeon_img from '../../assets/images/Surgeon_300x.png'
import ViewItemCard from '../../components/commons/cards/ViewItemCard'
import { Button } from '../../components/commons/forms'
import Text from '../../components/commons/texts/Text'



export class Sidebar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      active: 'procedures',
      location: '',
      selectedItem: null,
      avatar: emma,
    }
  }

  componentDidMount() {
    $(window).scroll(e => {
      let navigation_height = $('.navigation').height()
      //console.log('e.currentTarget.pageYOffset', 60 - e.currentTarget.pageYOffset, $('nav.navigation'))
      let top = navigation_height
      // if( window.screen.width <= 768){
      //   top = navigation_height
      // }

      if ($('.sidebar')[0]) {
        if (e.currentTarget.pageYOffset <= top) {
          $('.sidebar').css({ top: top - e.currentTarget.pageYOffset })
        } else {
          $('.sidebar').css({ top: 0 })
        }
        // if (e.currentTarget.pageYOffset > 0) {
        //   if ($('.sidebar')[0].offsetTop == 60){
        //     //$('.sidebar').animate({ top: 0 }, 100)
        //   }
        //   if (60 - e.currentTarget.pageYOffset <= 60 && 60 - e.currentTarget.pageYOffset > 0) {
        //     $('.sidebar').animate({ top: 60 - e.currentTarget.pageYOffset }, 0)
        //   }else{

        //   }
        // } else {
        //   if ($('.sidebar')[0].offsetTop == 0)
        //     $('.sidebar').animate({ top: 60 }, 0)
        // }
      }
    })
    $('main')
      .removeClass('has-sidebar')
      .addClass('has-sidebar')
    $('main').addClass('sidebar-open')
    $('body').addClass('sidebar-open')
    this.setState({
      location: window.location.pathname.split('/')[1],
    })
    tippy('#user-side', {
      content: 'Surgeon',
      placement: 'right',
      onShow(instance) {
        $(instance.popper).addClass('dark-tippy')
      },
    })
    tippy('#proc-side', {
      content: 'Procedures',
      placement: 'right',
      onShow(instance) {
        $(instance.popper).addClass('dark-tippy')
      },
    })
  }

  setActive(panel) {
    this.setState({
      active: panel,
    })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.surgeon !== nextProps.surgeon) {
      this.setState({
        avatar: nextProps.surgeon.avatar ? nextProps.surgeon.avatar : emma,
      })
    }
  }

  checkActive(panel) {
    return this.state.active == panel ? 'active' : ''
  }

  surgeonPanel() {
    const { surgeon } = this.props
    if (!surgeon) return null
    return (
      <div className="surgeon-profile-display">
        <div className="profile-img">
          <img src={this.state.avatar} />
          <Button link url={`/surgeon-update/${surgeon.id}`} className="m-0">Edit</Button>
        </div>
        <h3 className="name">{`${surgeon.firstname} ${surgeon.lastname}`}</h3>
        <h3 className="text-center regular job">
          {surgeon.title ? surgeon.title : '-'}
        </h3>
        <h3 className="text-center regular number">
          {surgeon.contact_number ? surgeon.contact_number : '-'}
        </h3>

        <div className="row">
          {this.state.location != 'quicknotes' ? (
            <div className="col-md-12 mb8 text-center">
              <Link
                className='cta cta-tertiary'
                to={{ pathname: `/` }}
                onClick={e => {
                  this.setState({
                    settings: true,
                    stage: {},
                    stage_details: [],
                  })
                }}
                key="12345"
                className="justify-content-center align-items-center"
              >
                <i className="fa fa-eye mr4"></i>
                View quicknotes
              </Link>
            </div>
          ) : (
              ''
            )}
          <div className="col-md-12">
            <ul className="list-unstyled surgeon-details with-btm-border">
              <li className="item skip">
                <b>{surgeon.handedness == 'left' ? 'Left ' : 'Right '}</b>
                handed
              </li>
              <li className="item">
                <h4>Gloves </h4>
                {surgeon.inner_glove ? (
                  <Text
                    className="cta cta-tertiary"
                    item={surgeon.inner_glove}
                    popover
                    key="inner-glovess"
                    setSelectedItem={sel_item => {
                      this.setState({
                        selectedItem: sel_item,
                      })
                    }}
                    onClick={() => {
                      $('#sidepane-viewitem').modal('show')
                    }}
                  >
                    {' '}
                    {surgeon.inner_glove.name}{' '}
                  </Text>
                ) : (
                    ' '
                  )}
              </li>
              <li className="item skip">
                <b>Size </b>
                {surgeon.inner_glove ? surgeon.inner_glove.size : ' '}
              </li>
              {surgeon.outer_glove ? (
                <li className="item">
                  <h4>Outer Gloves </h4>
                  {surgeon.outer_glove ? (
                    <Text
                      className="cta cta-tertiary"
                      item={surgeon.outer_glove}
                      popover
                      key="outer-glovess"
                      setSelectedItem={sel_item => {
                        this.setState({
                          selectedItem: sel_item,
                        })
                      }}
                      onClick={() => {
                        $('#sidepane-viewitem').modal('show')
                      }}
                    >
                      {' '}
                      {surgeon.outer_glove.name}{' '}
                    </Text>
                  ) : (
                      ' '
                    )}
                </li>
              ) : (
                  ' '
                )}
              {surgeon.outer_glove ? (
                <li className="item mb16">
                  <b>Size </b>
                  {surgeon.outer_glove ? surgeon.outer_glove.size : ' '}
                </li>
              ) : (
                  ' '
                )}
            </ul>
            <ul className="list-unstyled surgeon-details with-btm-border">
              <li className="item">
                <b>OR Environment</b>
              </li>
              <li className="item">
                <span className="item-detail">
                  Music <b>{surgeon.music ? 'ON' : 'OFF'}</b>
                </span>
                <span className="item-detail">
                  <b>{surgeon.enviroment_on ? 'Normal' : 'Silent'}</b>{' '}
                  environment
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }

  proceduresPanel() {
    const { surgeon } = this.props
    if (!surgeon) {
      return null
    }
    if (!surgeon.specialtyList) {
      return null
    }
    const specialties = surgeon.specialtyList.map((specialty) => {
      // console.log('specialty', specialty)
      const procedures = specialty.categories.map((category) => {
        // console.log('categories', category)
        return (
          <>
            <h4
              className="procedure-title collapsed font-weight-bold"
              id={`proc-title-header-${specialty.id}-${category.id}`}
              data-toggle="collapse"
              data-target={`#category-collapse-${specialty.id}-${category.id}`}
              aria-expanded="false"
              aria-controls={`category-collapse-${specialty.id}-${category.id}`}
              onClick={() => {
                if (
                  $(
                    `#proc-title-header-${specialty.id}-${category.id}`
                  ).hasClass('collapsed')
                ) {
                  $(`#proc-title-header-${specialty.id}-${category.id}`)
                    .find('.arrow-indicator')
                    .toggleClass('mdi-chevron-down mdi-chevron-up')
                } else {
                  $(`#proc-title-header-${specialty.id}-${category.id}`)
                    .find('.arrow-indicator')
                    .toggleClass('mdi-chevron-up mdi-chevron-down')
                }
              }}
            >
              {category.name}{' '}
              <i className="mdi mdi-chevron-down arrow-indicator fs20 position-relative" style={{ top: 5 }}></i>
            </h4>
            <div
              class="collapse show"
              id={`category-collapse-${specialty.id}-${category.id}`}
            >
              <div class="card card-body procedure-list">
                <ul className="list-unstyled">
                  {category.procedures.map(procedure => {
                    return (
                      <li>
                        <Link
                          to={{
                            pathname: `/procedure`,
                            search: `?procedure_id=${procedure.id}`,
                          }}
                          className="font-weight-normal"
                          onClick={() => {
                            this.props.setProcedure(procedure)
                          }}
                        >
                          {procedure.name}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </>
        )
      })
      return (
        <div className="specialty-group">
          <h3 className="title">
            <span className="image">
              <img src={specialty.icon} className="w-100" />
            </span>
            {specialty.name}
          </h3>

          {procedures}
        </div>
      )
    })
    return <div className="surgeon-procedure-display">{specialties}</div>
  }

  render() {
    return (
      <>
        <div className="sidebar">
          <div
            className="side-toggler"
            onClick={() => {
              $('.sidebar').hasClass('collapsed')
                ? $('.sidebar').removeClass('collapsed')
                : $('.sidebar').addClass('collapsed')
              $('main')
                .toggleClass('sidebar-open')
                .trigger('classChange')

              $('body').toggleClass('sidebar-open')
            }}
          >
            <i className="mdi mdi-chevron-right"></i>
          </div>
          <div className="side">
            <ul className="side-list">
              <li
                className={`${this.checkActive('procedures')}`}
                id="proc-side"
              >
                <a
                  href="javascript:;"
                  onClick={() => {
                    this.setActive('procedures')
                    if ($('.sidebar').hasClass('collapsed')) {
                      $('.sidebar').toggleClass('collapsed')
                      $('main')
                        .toggleClass('sidebar-open')
                        .trigger('classChange')
                    }
                  }}
                >
                  <img src={procedure_img} className="procedure_img" />
                </a>
              </li>
              <li className={`${this.checkActive('surgeon')}`} id="user-side">
                <a
                  href="javascript:;"
                  onClick={() => {
                    this.setActive('surgeon')
                    if ($('.sidebar').hasClass('collapsed')) {
                      $('.sidebar').toggleClass('collapsed')
                      $('main')
                        .toggleClass('sidebar-open')
                        .trigger('classChange')
                    }
                  }}
                >
                  <img src={surgeon_img} className="surgeon_img" />
                </a>
              </li>
            </ul>
          </div>
          <div className="body">
            {this.state.active == 'surgeon' ? this.surgeonPanel() : null}
            {this.state.active == 'procedures' ? this.proceduresPanel() : null}
          </div>
        </div>

        <ViewItemCard
          id={`sidepane-viewitem`}
          onRef={ref => (this.viewItemRef3 = ref)}
          item={this.state.selectedItem}
        ></ViewItemCard>
      </>
    )
  }
}

export default Sidebar
