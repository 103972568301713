import React, { Component } from 'react'
import ReactSVG from 'react-svg'

export default class Icon extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fill: "#3451A8",
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      icon: ''
    }
  }
  componentDidMount() {
    this.setState({
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      icon: require(`../../../../src/assets/images/icons/${this.props.icon}.svg`)
    })
  }
  mouseEnter() {
    this.setState({
      fill: "#5C5AC8"
    })
  }
  mouseLeave() {
    this.setState({
      fill: "#3451A8"
    })
  } 
  render() {
    const { width, height, className } = this.props
    
    return (
      <ReactSVG 
        src={this.state.icon} 
        className={`icon ${className}`} 
        beforeInjection={svg => { 
          svg.setAttribute('style', `width: ${width}px;height: ${height}px`) 
        }} 
        key={this.state.unique} 
        onClick={this.props.onClick}
        evalScripts="always"
        fallback={() => <span>Error!</span>}
        loading={() => 
          <div className="spinner-grow text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div> }
        renumerateIRIElements={false}  />  
    )
  }
}
