import React, { Component } from "react"

class SpecailtyCard extends Component {
	constructor(props){
		super(props)

		this.state = {
			
		}
	}

	active = () => {
		return(
			<div className="specialty-card selected mb30 mx15 m-xxsx15 m-xsx5  m-smx5 m-smx10">
        <div className="card " data-toggle="modal" data-target="#specialty-modal" onClick={this.props.getSpecialtyCategories}>
          <div className="card-body text-center">
            <img src={this.props.icon.replace('public/', '')} className="" />
						<h6 className="mt15 card-subtitle">{this.props.name}</h6>

          </div>
				</div>
				</div>
		)
	}

	inactive = () => {
		return(
			<div className="specialty-card mb30 mx15 m-xxsx15 m-xsx5  m-smx5 m-smx10">

        <div className="card" data-toggle="modal" data-target="#specialty-modal" onClick={this.props.getSpecialtyCategories}>
          <div className="card-body text-center">
            <img src={this.props.icon.replace('public/', '')} className="" />
            <h6 className="mt15 card-subtitle">{this.props.name}</h6>
          </div>
				</div>
			</div>
		)
	}

  render() {
    return (
			<div>
				{ this.props.active ? this.active() : this.inactive() }
			</div>
    )
  }
}

export default SpecailtyCard
