import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { getSurgeon } from '../actions/SurgeonActions'
import { setQuicknotesId } from '../actions/QuicknotesActions'
import { clearSurgeonData } from '../actions/SurgeonActions'
import img from '../assets/images/default_profile.jpg'
import { ConfirmationModal } from './commons/modals'
import $ from 'jquery'
import axios from 'axios'
class SurgeonCard extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  setquicknotes = surgeonData => {
    this.props.getSurgeon(surgeonData.id)
    this.props.clearSurgeonData()
  }

  componentDidMount = () => {
    this.props.setQuicknotesId(9)
  }

  toggleDropDown = event => {
    event.stopPropagation()

    console.log('handleChildClick')
  }

  deleteSurgeon = () => {
    const { surgeonData } = this.props

    const data = {
      _method: 'DELETE',
    }
    axios.post(`api/client/surgeon/${surgeonData.id}`, data).then(result => {
      console.log(result)
    })
  }

  deleteSurgeonModal = event => {
    event.stopPropagation()
    event.preventDefault()
    const { surgeonData } = this.props

    this.props.setDeleteSurgeon(surgeonData.id)
  }

  shareSurgeon = event => {
    event.stopPropagation()
    event.preventDefault()
    const { surgeonData } = this.props
    this.props.setShareSurgeon(surgeonData.id)
  }

  render() {
    const { surgeonData } = this.props
    const specialities = surgeonData.specialties.map(data => {
      console.log('d', surgeonData.specialties)
      if (data.specialty) {
        return (
          <span
            className="specialty-span"
            style={{ background: `${data.specialty.color}` }}
          >
            {data.specialty ? data.specialty.name : null}
          </span>
        )
      }
    })
    /* 

				<Link to={{ pathname: `/quicknotes/${surgeonData.id}` }} onClick={() => { this.setquicknotes(surgeonData) }} className="no-underline">
				</Link>*/
    const { className, cardClass, cardID } = this.props
    return (
      <>
        <div
          className={`col-lg-4 col-md-6 ${className}`}
          onClick={surgeonData => {
            this.props.onClick(surgeonData)
          }}
        >
          <div className={`card surgeon-card ${cardClass}`} id={cardID}>
            <div class="d-flex justify-content-between">
              <div className="card-body surgeon-card-body">
                <img
                  src={`${surgeonData.avatar ? surgeonData.avatar : img}`}
                  className="surgeon-image icon-circle icon-circle-lg"
                />

                <div className="">
                  <h3 className="bold surgeon-name">
                    {`${surgeonData.firstname} ${surgeonData.lastname}`}
                  </h3>
                  {surgeonData.title ? (
                    <span className="surgeon-title">{surgeonData.title}</span>
                  ) : null}
                </div>
              </div>
              <div class="btn-group dropright dropright-surgeon ">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={this.toggleDropDown.bind(this)}
                  style={{ borderRadius: 0, background: 'none !important' }}
                >
                  <i
                    className="mdi mdi-dots-vertical "
                    style={{ fontSize: 25 }}
                  ></i>
                </button>
                <div class="dropdown-menu">
                  <Link
                    to={{ pathname: `/surgeon-update/${surgeonData.id}` }}
                    className="dropdown-item"
                    onClick={this.toggleDropDown.bind(this)}
                  >
                    Edit
                  </Link>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={this.shareSurgeon.bind(this)}
                  >
                    Share
                  </a>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={this.deleteSurgeonModal.bind(this)}
                  >
                    Delete
                  </a>
                </div>
              </div>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <ul className="list-unstyled surgeon-details with-btm-border">
                    <li className="item">
                      <b>Gloves </b>
                      {`${
                        surgeonData.inner_glove == undefined
                          ? ''
                          : surgeonData.inner_glove.name
                      }`}
                    </li>
                    <li className="item skip">
                      <b>Size </b>
                      {`${
                        surgeonData.inner_glove == undefined
                          ? ''
                          : surgeonData.inner_glove.size
                      }`}
                    </li>
                    <li className="item">
                      <b>Outer Gloves </b>
                      {`${
                        surgeonData.outer_glove == undefined
                          ? ''
                          : surgeonData.outer_glove.name
                      }`}
                    </li>
                    <li className="item">
                      <b>Size </b>
                      {`${
                        surgeonData.outer_glove == undefined
                          ? ''
                          : surgeonData.outer_glove.size
                      }`}
                    </li>
                  </ul>
                  <ul className="list-unstyled surgeon-details with-btm-border">
                    <li className="item">
                      <b>Prefers</b>
                    </li>
                    <li className="item">
                      <span className="item-detail">{`Music ${
                        surgeonData.music ? 'on' : 'off'
                      }`}</span>
                      <span className="item-detail">
                        {surgeonData.enviroment_on ? '' : 'Silent OR'}
                      </span>
                    </li>
                  </ul>
                  <ul className="list-unstyled surgeon-details">
                    <li className="item mb0">
                      <b>Specialties</b>
                    </li>
                    <li className="item mb0">
                      {/* <span className="specialty-span">Orthopedics</span>
									<span className="specialty-span">Pediatrics</span>
									<span className="specialty-span">ENT</span>
									<span className="specialty-span">Thyroid</span> */}
                      {specialities}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default connect(
  null,
  { getSurgeon, setQuicknotesId, clearSurgeonData }
)(SurgeonCard)
