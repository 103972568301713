import React, { Component } from 'react'
import img from '../../../../src/assets/images/540x360.png'

export class ItemPopover extends Component {
 

  render() {
    let imgContent = '', usedForContent = '', tagsContent = '';
    if (true) {
      imgContent = `<div class="row item-img">
                            <div class="col-md-12">
                                <div class="ar32">
                                <img src="${img}" class="bg-dark" />
                                </div>
                            </div>
                        </div>`;
    }
    if (true) {
      usedForContent = `<div class="row">
                                <div class="col-12">
                                    <h3 class="title">Used for</h3>
                                    <div class="description">
                                    <p>
                                        Show description of use here. Description not necessessary as we want to impart pure use and context of the item instead.
                                    </p>
                                    <p>
                                        Similar to media element, if this text goes over a set limit, make the content box scrollable
                                    </p>
                                    </div>
                                </div>
                                </div>`;
    }
    if (true) {
      tagsContent = `<div class="row">
                                <div class="col-12"> 
                                    <span class="tags-pill">Orthopedics</span>
                                    <span class="tags-pill">ENT</span>
                                    <span class="tags-pill">Sample</span>
                                </div>
                            </div>`;
    }
    return `<div class="popover-item-card"> 
                    ${imgContent}
                    ${usedForContent}
                    ${tagsContent}
                </div>`;
  }
}

export default ItemPopover
