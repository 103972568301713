import React, { Component } from 'react'
import { Link } from 'react-router'
import $ from 'jquery'

export class privacy extends Component {

  
  goTo(id){
    let offset = $(`#${id}`).offset();

    
    $('html').animate({
      scrollTop: $(`#${id}`)[0].offsetTop,
    }, 400)
    console.log('$(`#${id}`)[0].offsetTop', $(`#${id}`)[0].offsetTop)
    
  } 


  render() {
    return (
      <div className="container privacy">
        <div className="row">
          <div className="col-md-8 mx-auto my32"><h1 className="title bold">Privacy Policy</h1></div>
          <div className="col-md-8 mx-auto">
            <p className="justify mb8">Thank you for choosing to be part of our community at Global OR (“<b>Company</b>”, “<b>we</b>”, “<b>us</b>”, or “<b>our</b>”). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us at globalor@email.com.</p>
            <p className="justify mb8">When you visit our and use our services, you trust us with your personal information. We take your privacy very seriously. In this privacy policy, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy policy that you do not agree with, please discontinue use of our and our services.</p>
            <p className="justify mb8">This privacy policy applies to all information collected through our and/or any related services, sales, marketing or events (we refer to them collectively in this privacy policy as the "<b>Services</b>").</p>
          </div>
          
          <div className="col-md-8 mx-auto">
            <h2 className="bold mt16 mb16">TABLE OF CONTENTS</h2>
            <ol type="1">
              <li className="mb12"><a href="javascript:;" onClick={()=>{ this.goTo('section-1') }}>What information do we collect?</a></li>
              <li className="mb12"><a href="javascript:;" onClick={()=>{ this.goTo('section-2') }}>Will your information be shared with anyone?</a></li>
              <li className="mb12"><a href="javascript:;" onClick={()=>{ this.goTo('section-3') }}>Do we use cookies and other tracking technologies?</a></li>
              <li className="mb12"><a href="javascript:;" onClick={()=>{ this.goTo('section-4') }}>Is your information transferred internationally?</a></li>
              <li className="mb12"><a href="javascript:;" onClick={()=>{ this.goTo('section-5') }}>How long do we keep your information?</a></li>
              <li className="mb12"><a href="javascript:;" onClick={()=>{ this.goTo('section-6') }}>Do we collect information from minors?</a></li>
              <li className="mb12"><a href="javascript:;" onClick={()=>{ this.goTo('section-7') }}>What are your privacy rights?</a></li>
              <li className="mb12"><a href="javascript:;" onClick={()=>{ this.goTo('section-8') }}>Controls for do-not-track features</a></li>
              <li className="mb12"><a href="javascript:;" onClick={()=>{ this.goTo('section-9') }}>Do california residents have specific privacy rights?</a></li>
              <li className="mb12"><a href="javascript:;" onClick={()=>{ this.goTo('section-10') }}>Do we make updates to this policy?</a></li>
              <li className="mb12"><a href="javascript:;" onClick={()=>{ this.goTo('section-11') }}>How can you contact us about this policy?</a></li>
            </ol>
          </div>

          
          <div className="col-md-8 mx-auto" id="section-1">
            <h2 className="bold mb20 mt30">1. What information do we collect?</h2>
            <p className="mb8 bold">Personal information you disclose to us</p>
            <p className="mb8 justify"><i><b>In Short</b>:  We collect personal information that you provide to us such as name, address, contact information, passwords and security data, and payment information.</i></p>
            <p className="mb8 justify">We collect personal information that you voluntarily provide to us when registering at the expressing an interest in obtaining information about us or our products and services, when contacting us.</p>
            <p className="mb8">Information we are securely storing which included:</p>
            <ol type="a" className="mb10"> 
              <li>Name</li>
              <li>Surname</li>
              <li>Email Address</li>
              <li>Job Title</li>
              <li>Specialties in the Operating room they are working in</li>
              <li>Credit Card Details</li>
            </ol>
            <p className="mb8 justify">The personal information that we collect depends on the context of your interactions with us, the choices you make and the products and features you use. The personal information we collect can include the following: </p>
            <p className="mb8 justify"><b>Credentials</b>. We collect passwords, password hints, and similar security information used for authentication and account access.</p>
            <p className="mb18 justify">All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.</p>


            <h3 className="mb8 blue"><i>Information automatically collected</i></h3>
            <p className="justify mb8 blue"><i>In Short:   Some information — such as IP address and/or browser and device characteristics — is collected automatically when you visit our website or app.</i></p>
            <p className="mb8 justify blue">We automatically collect certain information when you visit, use or navigate the website or the app . This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our and other technical information. This information is primarily needed to maintain the security and operation of our , and for our internal analytics and reporting purposes.</p>
            <p className="mb18 justify blue">Like many businesses, we also collect information through cookies and similar technologies.</p>

            
            <h3 className="mb8">Monitoring & Recording of data</h3>
            <p className="mb8">We will monitor and record your data automatically when you will use our website and or app:</p>
            <ul className="mb18">
              <li>Browser information</li>
              <li>Operating System information </li>
              <li>Mobile device Information</li>
              <li>IP address</li>
              <li>Internet Service Provider</li>
              <li>Content viewed</li>
              <li>Geographic location</li>
              <li>Connection speed</li>
              <li>Time of visit</li>
              <li>Activity during website visit</li>
              <li>Registration time</li>
            </ul>

            <h3 className="mb8">How we will use your personal data? </h3>
            <p className="mb8 bold">We will:</p>
            <ul className="mb18">
              <li>Provide Global OR products and services</li>
              <li>Fulfill user requests</li>
              <li>Improve services and product</li>
              <li>Operate our business</li>
              <li>Find out how users are engaging with CORH</li>
            </ul>
          </div>


            
          <div className="col-md-8 mx-auto" id="section-2">
            <h2 className="bold mb20 mt30 ">2. Will your information be shared with anyone?</h2>
            <p className="mb8 justify"><i><b>In Short</b>:  we will not sell or use their emails and data to 3rd party and strictly used for Global OR but we may process or share data based on the following legal basis:</i></p>
            <ol className="mb8 justify" type="1">   
              <li><b>Consent</b>: We may process your data if you have given us specific consent to use your personal information in a specific purpose.</li>
              <li>Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate medical  interests.</li>
              <li><b>Legal Obligations</b>: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</li>
              <li><b>Vital Interests</b>: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</li>
            </ol>
            <p className="mb8 justify">More specifically, we may need to process your data or share your personal information in the following situations:</p>
            <ul className="mb8 justify">
              <li><b>Business Transfers</b>. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
            </ul>
          </div>


          
          <div className="col-md-8 mx-auto" id="section-3">
            <h2 className="bold mb20 mt30">3. Do we use cookies?</h2>
            <p className="mb8 justify"><i><b>In Short</b>:  We may use cookies to collect and store your information.</i></p>
            <p className="mb8 justify">We may use cookies to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Policy.</p>
          </div>

          
          
          <div className="col-md-8 mx-auto" id="section-4">
            <h2 className="bold mb20 mt30">4. Is your information transferred internationally?</h2>
            <p className="mb8 justify"><i><b>In Short</b>:  We may transfer, store, and process your information in countries other than your own.</i></p>
            <p className="mb8 justify">Our servers are located in <b className="blue italic">please mention the location of your server here</b> If you are accessing our website or app from outside, please be aware that your information may be transferred to, stored, and processed by us in our facilities in and other countries.</p>
            <p className="mb8 justify">If you are a resident in the European Economic Area, then these countries may not have data protection or other laws as comprehensive as those in your country. We will however take all necessary measures to protect your personal information in accordance with this privacy policy and applicable law according to art 50 from the GDPR. </p>
          </div>
          
          
          <div className="col-md-8 mx-auto" id="section-5">
            <h2 className="bold mb20 mt30">5. How long do we keep your information?</h2>
            <p className="mb8 italic justify"><b>In Short</b>:  We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by law.</p>
            <p className="mb8 justify">We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this policy will require us keeping your personal information for longer than <b className="blue">days</b>.</p>
          </div>
          
          
          <div className="col-md-8 mx-auto" id="section-6">
            <h2 className="bold mb20 mt30">6. Do we collect information from minors?</h2>
            <p className="mb8 italic justify"><b>In Short</b>:  We do not knowingly collect data from or market to children under 18 years of age.</p>
            <p className="mb8 justify">We do not knowingly solicit data from or market to children under 18 years of age. By using the , you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the . If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we have collected from children under age 18, please contact us at globalor@email.com.</p>
          </div> 

          
          
          <div className="col-md-8 mx-auto" id="section-7">
            <h2 className="bold mb20 mt30">7. What are your privacy rights?</h2>
            <p className="mb8 italic justify"><b>In Short</b>:  You may review, change, or terminate your account at any time.</p>
            <p className="mb18 justify">If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.</p>
            <h4 className="mb8">Account Information</h4>
            <p className="mb8 justify">If you would at any time like to review or change the information in your account or terminate your account, you can:</p>
            <p className="mb8 justify">Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.</p>
          </div> 
          
          
          <div className="col-md-8 mx-auto" id="section-8">
            <h2 className="bold mb20 mt30 blue">8. Controls for do-not-track features</h2>
            <p className="mb8 blue justify italic">Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy policy.</p>
          </div> 
          

          <div className="col-md-8 mx-auto" id="section-9">
            <h2 className="bold mb20 mt30 blue">9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
            <p className="mb8 blue justify italic"><b>In Short</b>:  Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</p>

            <p className="mb8 justify">California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</p>
            <p className="mb16 justify">If you are under 18 years of age, reside in California, and have a registered account with the , you have the right to request removal of unwanted data that you publicly post on the . To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the , but please be aware that the data may not be completely or comprehensively removed from our systems.</p>
            <p className="mb8 justify">Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy policy.</p>
          </div>


          <div className="col-md-8 mx-auto" id="section-10">
            <h2 className="bold mb20 mt30">10. Do we make updates to this policy?</h2>
            <p className="mb8 justify italic"><b>In Short</b>:  Yes, we will update this policy as necessary to stay compliant with relevant laws.</p>
            <p className="mb8 justify">We may update this privacy policy from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.</p>
          </div> 
          
          
          <div className="col-md-8 mx-auto" id="section-11">
            <h2 className="bold mb20 mt30">11. How can you contact us about this policy?</h2>
            <p className="mb8 justify">If you have questions or comments about this policy, you may email us at <a href="#">globalor@email.com</a> or by post to 83 Faunce Crescent, Wyalong, Australia.</p>
            <p className="mb8 justify">We may update this privacy policy from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.</p>
          </div> 

          
          

        </div>
      </div>
    )
  }
}

export default privacy
