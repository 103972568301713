import React, { Component } from 'react'
import { connect } from 'react-redux' 
import { getSurgeonQuicknotes } from '../actions/QuicknotesActions'
import { Tabs, TabHead, TabNavigation, TabBody, TabContent } from './commons/tabs';
import $ from 'jquery'
import {showLoader} from './loader/loading_screen';
import { ConfirmationModal } from './commons/modals';


export class QuicknotesCategories extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      loaded: false,
      key: this.makeKey(),
      active: 1
    }
  }

  makeKey(){
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }

  componentDidMount() { 
    $('main').on('classChange', () => { 
      this.setState({
        key: this.makeKey()
      })
    }); 
   
    this.setState({
      key: this.makeKey()
    })
  }

  componentWillReceiveProps(nextProps) {
    if(this.props != nextProps){
      this.setState({
        key: this.makeKey(),
        active: nextProps.quicknotes_id
      })
    }
  }

  render() { 
    const { categories, quicknotes_id, edit,preview } = this.props
    
    return (
      <div className="col-md-12"> 

        <ConfirmationModal
          idModal="unlockModal"
          title="Action not possible!"
          message="You need to close edit mode first."
          confirmText="Okay"
          confirm={() => {
            $('#unlockModal').modal('hide')
          }}
        />
        <Tabs
          // key={this.state.key}
          className="px0"
          onRef={ref => (this.tabsRef = ref)}
          navs={() => {
            return (
              <TabHead className="col-md-12 px0">
                {
                  categories.map( category => {
                    return (
                      <TabNavigation active={category.id == this.state.active}
                        onClick={() => {  
                          if (!(edit || preview)){
                            this.props.getSurgeonQuicknotes(category.id) 
                            this.props.closeNotif()
                          }else{
                            $("#unlockModal").modal('show')
                          }
                        }}
                        disabled={category.id != quicknotes_id && (edit || preview)}
                      >{category.name}</TabNavigation>
                    )
                  })
                }
              </TabHead>
            )
          }}
          contents={() => {
            return ''
          }}
        /> 
        
        
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return({
    categories: state.quicknotes.categories,
    quicknotes_id: state.quicknotes.quicknotes_id
  })
} 

export default connect(mapStateToProps, {getSurgeonQuicknotes})(QuicknotesCategories)
