import React, { Component } from 'react';
import Equipments from '../../assets/images/medical_inventories/Equipments.svg';
import Instruments from '../../assets/images/medical_inventories/Instruments.svg';
import Supplies from '../../assets/images/medical_inventories/Supplies.svg';
import MedicalInventoryCard from '../../components/commons/cards/MedicalInventoryCard';


class MedicalInventories extends Component {
     
    render() { 
        return (
            <div className="container animated fadeIn middle-content"> 
                <div className="">
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <h1 class="font-weight-bold h1bold page-title mb24">Medical Inventory</h1>
                            <h3 class="b2med page-sub-title mb24">Our global database for your knowledge</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 mb20">
                            <MedicalInventoryCard
                                title="Supplies"
                                description="CORH global database for surgical supplies and consumables."
                                url="/medical-inventory/supplies"
                                img={`https://api.corhsystem.com/images/item-1.png`}
                            />
                        </div>
                        <div className="col-md-4 mb20">
                            <MedicalInventoryCard
                                title="Instruments"
                                description="CORH global database for instruments. Create your own instrument trays here."
                                url="/medical-inventory-instruments"
                                img={`https://api.corhsystem.com/images/item-2.png`}
                            />
                        </div>
                        <div className="col-md-4 mb20">
                            <MedicalInventoryCard
                                title="Equipment"
                                description="CORH global database for positioning and surgical equipment."
                                url="/medical-inventory/equipments"
                                img={`https://api.corhsystem.com/images/item-3.png`}

                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default MedicalInventories;
