import React, { Component } from 'react'

export class NotificatonModal extends Component {
  render() {
    const { title, idModal, message, confirmText, cancelText } = this.props

    return (
      <div className="modal fade modal-xs" id={idModal ? idModal : 'notificationModal'} tabIndex={-1} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">

            {
              title ?
                <div className="modal-header text-center">
                  <h3 className="modal-title">{title}</h3>
                </div> : null
            }

            <div className="modal-body text-center w-100">
              {message}
            </div>
            <div className="modal-footer text-center">
              {/* <div className="cta cta-primary" data-dismiss="modal" onClick={(event) => { this.props.confirm(event) }} >{confirmText}</div> */}
              <div className="cta cta-secondary" data-dismiss="modal" onClick={(event) => { this.props.confirm(event)}}>{confirmText}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default NotificatonModal
