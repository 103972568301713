import React, { Component } from 'react'
import '../../assets/scss/App.scss'
import '../../assets/css/app.css'
import axios from 'axios'
import { connect } from 'react-redux'
import { BASE_URL } from '../../config/constant'

class Share extends Component {
  constructor(props) {
    super(props)

    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      loading: false,
      errors: [],
      name: '',
      loadingClass: 'verifying share',
    }
  }

  componentWillMount() {
    axios.defaults.baseURL = BASE_URL
    console.log('propsss', this.props)
  }

  componentDidMount() {
    this.acceptShare({
      id: this.props.params.id,
      token: this.props.location.query.token,
    })
  }
  acceptShare(data) {
    console.log(data)
    axios
      .post(`api/client/shares/${data.id}`, {
        _method: 'PATCH',
        token: data.token,
      })
      .then(res => {
        this.setState(
          {
            loadingClass: '',
          },
          () => {
            this.props.router.push({
              pathname: '/surgeon',
            })
          }
        )
      })
      .catch(error => {
        console.log(error.response)
      })
  }
  render() {
    return (
      <div className="share-page">
        <div class="spinner-grow" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <h2 className="mt18 mb0">Sharing account. Please wait...</h2>
      </div>
      // <div className="no-nav verifying share">
        
      // </div>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  const { email, password, loading, error } = auth

  return { email, password, loading, error }
}

export default connect(mapStateToProps, {})(Share)
