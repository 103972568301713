import LoadingManager from "./LoadingManager"; 

import Loading, {
  showLoader,
  hideLoader,
  setLoaderProg
} from "./Loading";

export {
  LoadingManager,
  showLoader,
  hideLoader,
  setLoaderProg
};

export default Loading;
