import React, { Component } from 'react'
import $ from 'jquery'
export class TRow extends Component {
 

  render() {
    const { autoCheck = true, hasChoices } = this.props 
    return (
      <tr className={`${this.props.hasChoices ? 'c-pointer':''}`} onClick={(e)=>{  
        if (this.props.onClick) {
          this.props.onClick()
        } 
        if (hasChoices && autoCheck){ 
          if($(e.target).is('label')){
            $(e.target).closest('td').click();
          } else {
            if ($(e.currentTarget).find('input').is(':checkbox')) {
              if (this.props.hightlight) { 
                $(e.currentTarget).addClass('active').find('label').click()
                if (!$(e.currentTarget).find('input').is(':checked')) {
                  $(e.currentTarget).removeClass('active')
                }
              }else{ 
                $(e.currentTarget).closest('table').find('tr').removeClass('active');
                $(e.currentTarget).addClass('active').find('label').click()
              }
            } else {
              $(e.currentTarget).closest('table').find('tr').removeClass('active');
              $(e.currentTarget).addClass('active').find('label').click() 
            } 
          }
        }
      }} onDoubleClick={this.props.onDoubleClick}>
        {this.props.children}
      </tr>
    )
  }

}

export default TRow
