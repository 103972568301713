import React, { Component } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import _ from 'lodash'
import $ from 'jquery'


export class NewConsumableSkeleton extends Component {

  constructor(props) {
    super(props)

    this.state = {
      counter: _.range(_.random(2, 5)),
      color: '#d2d2d2',
      highlightColor: '#dedede'
    }
  }
  componentWillMount() {
    if ($('body').hasClass('dark-theme')) {
      this.setState({
        color: '#1d212e',
        highlightColor: '#151821'
      })
    }
  }

  titleElement = () => {
    return (
      <Skeleton width={200} height={30} />
    )
  }

  textElement = () => {
    return (
      <div className={'card text-preview w-100 mb20 skeletoncard'}>
        <div className="card-header text-capitalize pb5 ">
          <Skeleton width={200} height={30} />
        </div>
      </div>
    )
  }

  render() {
    return (
      <SkeletonTheme color={this.state.color} highlightColor={this.state.highlightColor}>
        <div className="row mb16">
          <div className="col-md-12 d-flex justify-content-between align-items-center">
            <h2 className="page-sub-title">Consumables</h2> 
          </div>
        </div> 
        <div className="row">
          {
            this.state.counter.map(element => {
              return (
                <div className={`col-md-12 ${element == _.last(this.state.counter) ? 'mb25' : ''}`}>
                  {this.textElement()}
                </div>
              )
            })
          } 
        </div>
      </SkeletonTheme> 
    )
  }
}

export default NewConsumableSkeleton
