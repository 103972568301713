import Raven from 'raven-js';

const sentry_key = `0375d033fd18428c98771b266c7bc85b`
const sentry_app = `1498986`
export const sentry_url = `https://${sentry_key}@sentry.io/${sentry_app}`

export const logException = (ex, context) => {
    Raven.captureException(ex, {
        extra: context
    })

    window && window.console && console.error && console.error(ex)
}