import React, { Component } from 'react'
import { connect } from 'react-redux'
import UserProfileCard from '../commons/cards/UserProfileCard';
import { withRouter } from 'react-router'
import user_img from '../../assets/images/user.png'
class Greeting extends Component {


  componentDidMount = () => {
    console.log('greeting',this.props.user)
  }
  render() {

    const { user } = this.props

    console.log('user.avatar',user.avatar == null || user.avatar == '')
    return ( 
      <div className="card shadow-0 border-0 mb24">
       <UserProfileCard user={user} img={user.avatar == null || user.avatar == '' ? user_img:user.avatar}/>
      </div>
    )
  }
}


const mapStateToProps = ({ auth }) => {
  const {user } = auth

  return {user }
}

export default connect(
  mapStateToProps,
  { }
)(Greeting)