import $ from 'jquery'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { positioningUpdate } from '../../actions'
import { getBodyAreas, getPositioningDetailsProcedure, getPositions, setPositioningEquipmentDetails } from '../../actions/PostioningActions'
import { MediaPreview } from '../../components/commons/elements/display'
import { PositionAreas } from '../../components/positioning_equipments'
import ViewItemCard from '../commons/cards/ViewItemCard'
import { Button } from '../commons/forms'
import MediaElement from '../commons/media_elements/MediaElement'
import { Modal } from '../commons/modals'
import Notifier from '../commons/notifier/Notifier'
import Collapsible from '../commons/panel/Collapsible'
import { Table, TData, THead, TRow } from '../commons/table'
import NotesSkeleton from '../skeletons/NotesSkeleton'
import PositioningEquipmentSkeleton from '../skeletons/PositioningEquipmentSkeleton'
import PositioningEquipmentEditLayout from './PositioningEquipmentEditLayout'




class PositioningEquipmentLayout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      position: '',
      selected_item: null,
      edit: false,
      set: true,
      body_areas: [],
      showNotif: false,
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      key:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      mediasSelected: [],
      selectedItem: []
    }
  }

  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  componentDidMount() {
    this.getPositioning()

    this.props.getBodyAreas()
    $('.scroll-to-top').fadeOut(0)
    $('td').click(function() {
      var prop = $(this)
        .find('input')
        .prop('checked')
      $(this)
        .find('input')
        .prop('checked', prop == true ? false : true)
    })

    $(window).scroll(function() {
      if ($(this).scrollTop() > 350) {
        $('.scroll-to-top').fadeIn()
      } else {
        $('.scroll-to-top').fadeOut()
      }
    })
    //Click event to scroll to top
    $(document).on('click', '.scroll-to-top', function () {
      $('html, body').animate({ scrollTop: $('#home').offset().top - 100 }, 200)
      return false
    })

    if (this.props.enableDraft) {
      this.setState({
        edit: true,
        draft_success: false
      })
      this.props.getProcedureDetail(1)

    }

    this.props.setNewState({
      settings: false
    })
  }

  componentWillReceiveProps = (nextProps) => {
    if(this.props.stage_master != nextProps.stage_master){
      if(!nextProps.stage_master.draft){
        this.setState({
          edit: false
        })
      }
    }
    if(!this.state.body_areas.length){
      let b_areas = nextProps.body_areas.filter((area, index)=>{
        return area.id != ''
      })
      this.setState({
        body_areas: b_areas
      })
    }
  }

  setNewState = (state) => {
    this.setState(state)
  }

  positioningContent = () => {
    const { stage_details} = this.props
    const { position, body_areas } = this.state
    const arrayDetails = Object.values(stage_details.items).splice(0) 
    if (stage_details && stage_details.details) {
 
      let list =  body_areas.map( area => {
        var detail = arrayDetails.filter(item => {
          return item.body_area == area.id
        })
        let modal_ID = this.makeid(11);
        if (!detail.length){
          return null
        }
        return ( 
          <div className="w-100 mb20" id={area.code}>
            <Collapsible title={area.name} stickyHeader> 
              <Table minWidth={650}
                noResults={detail.length > 0 ? 0 : 1}

                renderModal={() => {
                  return (<ViewItemCard id={`${modal_ID}`} onRef={ref => (this.viewItemRef = ref)} item={this.state.selectedItem} ></ViewItemCard>)
                }}
                cols={5}
                renderHeaders={() => {
                  return (
                    <TRow>
                      <THead className="w150" text="Catalogue #" />
                      <THead className="w280" text="Name" />
                      <THead className="w70" text="Qty" />
                      <THead className="w250" text="Notes" />
                      <THead className="w80" text="Media" />
                    </TRow>
                  )
                }}
                renderRows={() => {
                  let rows = detail.map((item, index) => { 
                    return (<TRow>
                      <TData className="w150">{item.catalog_number}</TData>
                      <TData className="w280 has-action" item={item} popover onClick={() => {
                        this.setState({ selectedItem: item })
                        $(`#${modal_ID}`).modal('show')
                      }}>{item.name}</TData>
                      <TData className="w70">{item.qty ?item.qty : 1}</TData>
                      <TData className="w250"><div dangerouslySetInnerHTML={{ __html: stage_details.details[area.code][index] ? stage_details.details[area.code][index].item.notes ? stage_details.details[area.code][index].item.notes : '-':'-' }}></div></TData>
                      <TData className="w80">{stage_details.details[area.code][index] ? stage_details.details[area.code][index].item.media.length > 0 ?
                        <Button tertiary onClick={(event) => {
                          // console.log('itemsssss', stage_details.details[area.code][index].item.media)
                          this.setState({
                            key:
                              Math.random()
                                .toString(36)
                                .substring(2, 15) +
                              Math.random()
                                .toString(36)
                                .substring(2, 15),
                            mediasSelected: stage_details.details[area.code][index].item.media
                          })
                          $('#viewMediaModal').modal('show')
                        }}>View</Button>
                        : '-':'-'
                      }</TData>
                    </TRow>)
                  })
                  return rows
                }}
                renderLastRow={() => {
                  if (detail.length) { return null }
                  return (
                    <tr>
                      <td className="w-100 text-center" colSpan={4}>
                        No item(s) added. Add new items via <a href="javascript:;"
                          onClick={e => {
                            e.preventDefault()
                            this.setState({
                              edit: true,
                              draft_success: false
                            })
                            this.props.getProcedureDetail(1)
                          }}>Edit notes</a>.
                      </td>
                    </tr>
                  )
                }}
              />
            </Collapsible>
          </div>
        )
      })


      // let area_list = arrayDetails.map(detail => {  
      //   let modal_ID = this.makeid(11);
      //   return (
          
      //     <div className="w-100 mb20" id={detail[0].position.code}>  
      //       <Collapsible title={detail[0].position.name} stickyHeader>
              
      //       </Collapsible>
      //     </div>
      //   )
      // })
      return (
        <div className="row">
          <div className="col-md-12 profile-card pt-0">
            <PositionAreas details={Object.values(this.props.stage_details.details).splice(0)} />
            { list }
            {/* {area_list} */}
          </div>
        </div>
      )
    }
    return null
  }

  viewPositionMedia = () => {
    const { selected_item } = this.state
    if (selected_item){
      return (
        <MediaPreview
          idModal='viewMedia'

          key={this.state.unique}
          title={`Viewing the media of [${selected_item.item.name}]`}
          data={selected_item}
        />
      )
    }else{
      return null;
    }
  }
  getPositioning = () => {
    const {stage_details} = this.props
    if(stage_details && stage_details.position){
      Object.keys(stage_details.position).forEach(e => {
        this.props.positioningUpdate({
          prop: e,
          value: stage_details.position[e],
        })
      })
    }
   
  }

  view = () => {
    const { positions } = this.props

    return (
      <div className="row">
        {this.viewPositionMedia()}
        <div className="col-md-12">
          <div className="scroll-to-top c-pointer">
            <i className="fa fa-chevron-up fs20" />
          </div>
          <div className="row">
            <div className="col-md-12">
              {/* <Preview widthStyle="col-md-12" /> */}
            </div>
          </div>
          {this.positioningContent()}
        </div> 
      </div>
    )
  }
  
  showSuccess(){ 
    setTimeout(() => {
      this.setState({
        showNotif: true
      })
    }, 1000);
  }
  loadingPage = () => {
    return ( <div className="w-100">
    <div className="row mt32">
      <div className="col-md-12 mx-auto d-flex justify-content-between align-items-center page-description">
        <h2 className="bold">
        Position {this.props.stage_details.position.name}
        </h2>
      </div>
    </div>
    <div className="row">
        <div className="col-md-12 mx-auto">
          <PositioningEquipmentSkeleton />
        </div>
      </div>
    </div>)
  }
  edit = () => {
    return(
      this.props.loading ? 
      this.loadingPage()
    :
      <PositioningEquipmentEditLayout 
        propsNewState={this.props.setNewState}
        setNewState={this.setNewState} 
        position={this.props.stage_details.position} 
        position_id={this.props.stage_details.master.id}  
        getProcedureDetail={this.props.getProcedureDetail}
        stage_details={this.props.stage_details}
        stage_master={this.props.stage_master}
        showSuccess={this.showSuccess.bind(this)}
        key={'post-edit-123'}
        /> 
    )
  }

  mediaPreviewModal(){
    return ( 
      <Modal
        idModal="viewMediaModal"
        size="modal-xl"
        title="Media" 
        noFooter
        bodyClass="assign-items-body"
      >
        <div className="row">
          <div className="col-lg-12"
            key={this.state.key}>
            {
              this.state.mediasSelected ? this.state.mediasSelected.length ?
                <MediaElement data={this.state.mediasSelected}></MediaElement>
                : null : null
            }
          </div>
        </div>
      </Modal>
    )
  }

  render() {
    return (
      <div className="w-100 float-left">
        {!this.state.edit ?
          <div className={`row mt32 ${this.props.loading ? 'd-none':''}`}>
            <div className="col-md-12 mx-auto d-flex justify-content-between align-items-center page-description">
              <h2 className="mb-2 mb-sm-0 bold">
                Position {this.props.stage_details.position.name}
              </h2>
              <Button tertiary className="ml-0 mr-2 mr-sm-0" onClick={(e) => {
                e.preventDefault()
        //        this.props.getPositioningDetailsProcedure(this.props.stage_details.master.id, 1)
                this.props.getProcedureDetail(1)
                this.setState({
                  edit: true
                }) 
                this.props.setNewState({
                  loading: true,
                  procedure_stage_edit: true
                })
              }} >
              Edit {this.props.title}
              </Button>
            </div>
          </div>:null
        }
        {!this.state.edit ?
        <div className={`row ${this.props.loading ? 'd-none':''}`}>
          <div className="col-md-12 mt0">
            <Notifier show={this.state.showNotif} message='Position successfully updated!' onRef={ref => (this.refNotifier = ref)} type="success" styleClass="mb20 mt0" hideThisNotif={() => { this.refNotifier.hideThis(); this.setState({
              showNotif: false
            }) }} />
          </div>
        </div>
        :null }
        { this.props.loading ? this.loadingPage() : this.state.edit ? this.edit() : this.view()}
      
        {this.mediaPreviewModal()}
      </div>
    )
  }


}

const mapStateToProps = state => {
  return {
    positions: state.positioning.positions,
    position_details: state.positioning.position_details,
    position_master: state.positioning.position_master,
    body_areas: state.positioning.body_areas,
    video_progress: state.video.progress
  }
}

export default connect(
  mapStateToProps,
  { getPositions, getPositioningDetailsProcedure, getBodyAreas, setPositioningEquipmentDetails,positioningUpdate }
)(PositioningEquipmentLayout)
