import React, { Component } from 'react'
import $ from 'jquery'


export class Tabs extends Component {

  constructor(props) {
    super(props)

    this.state = {
      id: this.makeid(10),
      showNext: false,
      showPrev: false,
      navLinksOffsets: [],
      nextScrollWidth: 0,
      prevScrollWidth: 0,
      onLast: false,
      goBack: false,
      supported: true,
    }

  }
  

  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  getWidth(){
    const { id } = this.state
    var totalWidth = 0;
    if (window.navigator.userAgent.indexOf('Edge') >= 0) {
      $(`#${id} .nav-link`).each(index => {
        console.log('totalWidth', totalWidth, $($(`#${id} .nav-link`)[index])[0].clientWidth)
        totalWidth += $($(`#${id} .nav-link`)[index])[0].clientWidth + 2;
      })
      $(`#${id} .nav`).width(totalWidth);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { id } = this.state
    if (this.props != nextProps) {
      let width = $(`#${id} .scrollable-tabs`)[0].clientWidth
      let scrollWidth = $(`#${id} .scrollable-tabs`)[0].scrollWidth

      if (scrollWidth > width) {
        this.setState({
          showNext: true,
          showPrev: $(`#${id} .scrollable-tabs`)[0].scrollLeft > 0,
        })
      }
      let links = []
      $(`#${id} .nav-link`).each(index => {

        links.push({ 'index': index, 'offsetLeft': $($(`#${id} .nav-link`)[index])[0].offsetLeft, 'element': $($(`#${id} .nav-link`)[index]), 'offsetWidth': $($(`#${id} .nav-link`)[index])[0].offsetWidth })
      })

      var selected = links.find(x => (x.offsetLeft + x.offsetWidth) >= 0)

      this.setState({
        navLinksOffsets: links,
        nextScrollWidth: selected ? selected.offsetWidth : 0
      })

        setTimeout(() => {
          this.getWidth()
        }, 1500); 
    }
  }

  componentWillMount() {
  }

  goToScroll(element){
     let e = $(`#${this.state.id} .active`)
     if(e[0]){
      let offsetLeft = e[0].offsetLeft 
      $(`#${this.state.id} .scrollable-tabs`).animate({ scrollLeft: `${offsetLeft - 55}px` }, 150)
     }
  }

  getNextScrollWidth(){
    return this.state.nextScrollWidth;
  }
  
  componentDidMount() { 
    if (this.props.onRef) {
      this.props.onRef(this)
    } 

    const { id } = this.state
    var width = $(`#${id} .scrollable-tabs`)[0].clientWidth
    var scrollWidth = $(`#${id} .scrollable-tabs`)[0].scrollWidth

    if (scrollWidth > width) {
      this.setState({
        showNext: true,
        showPrev: false,
      })
    }

    let links = []
    $(`#${id} .nav-link`).each(index => {
      links.push({ 'index': index, 'offsetLeft': $($(`#${id} .nav-link`)[index])[0].offsetLeft, 'element': $($(`#${id} .nav-link`)[index]), 'offsetWidth': $($(`#${id} .nav-link`)[index])[0].offsetWidth })
    })

    if (window.navigator.userAgent.indexOf('Edge') >= 0) {
      setTimeout(() => {
        this.getWidth()
      }, 1500);
    }

    this.setState({
      navLinksOffsets: links, 
    })

    $(`#${id} .scrollable-tabs`).on('scroll', (e) => {

      var width = $(`#${id} .scrollable-tabs`)[0].clientWidth
      var scrollWidth = $(`#${id} .scrollable-tabs`)[0].scrollWidth

      var prev_selected = this.state.navLinksOffsets.find(x => (x.offsetLeft + x.offsetWidth) >= Math.floor(e.target.scrollLeft))

      var selected = this.state.navLinksOffsets.find(x => (x.offsetLeft + x.offsetWidth) >= Math.floor(e.target.scrollLeft) + 5)

      this.setState({
        showPrev: Math.floor(e.target.scrollLeft) > 0,
        nextScrollWidth: selected ? selected.offsetWidth:0,
        prevScrollWidth: prev_selected ? prev_selected.offsetWidth:0,
        onLast: ((scrollWidth - width) - Math.floor(e.target.scrollLeft)) <= 10
      })
    })
    $(document).on(`#${id} .nav-link`, 'click', (e) => {
      e.preventDefault() 
      console.log('e.target.offsetLeft',e.target.offsetLeft)
      $(`#${id} .scrollable-tabs`).animate({ scrollLeft: `${e.target.offsetLeft - 55}px` }, 150)
    })
  }

  goPrev() {
    const { id } = this.state
    const { prevScrollWidth } = this.state
    $(`#${id} .scrollable-tabs`).animate({ scrollLeft: `-=${prevScrollWidth}px` }, 150)
  }

  goNext() {
    const { id } = this.state
    const { nextScrollWidth, onLast } = this.state

    if (onLast) {
      $(`#${id} .scrollable-tabs`).animate({ scrollLeft: `0px` }, 150)
      this.setState({ onLast: false })
    } else {
      $(`#${id} .scrollable-tabs`).animate({ scrollLeft: `+=${nextScrollWidth}px` }, 150)
    }
  }

  
  render() { 
    const { navs, contents, className = '', navLinks } = this.props
    return (
      <div className={`scrollable-tab-parent ${className}`} id={this.state.id}>

        <div className={`prev ${this.state.showPrev ? '' : 'd-none'}`} onClick={() => { this.goPrev()}}>
          <i className="fa fa-chevron-left"></i>
        </div>
        <div className={`next ${this.state.showNext ? '' : 'd-none'}`} onClick={() => { this.goNext()}}>
          <i className="fa fa-chevron-right"></i>
        </div>

        <div className="scrollable-tabs">
          <nav class="nav">
            {navLinks ? navLinks() : null}
            {navs ? navs() : null} 
          </nav>
        </div>

        {contents ? contents() : null}
      </div>
    )
  }
}

export default Tabs
