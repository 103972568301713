import React, { Component } from 'react'
import { Link } from 'react-router'
class Button extends Component {
  render() {
    const { outline, primary, secondary, tertiary, large, 
            active, link, url, className, datatarget, datatoggle,
            onClick = null, datadismiss='', id, expand,
            disabled
          } = this.props 
    
    let size = large ? 'cta-lg':'';
    let type = primary ? 'primary' : secondary ? 'secondary' : tertiary ? 'tertiary':'';
    let classBuilder = `cta cta-${type}${outline ? '-outline' : ''} ${size} ${active ? 'active' : ''} ${expand ? 'expand':''}`
    
    if (link) {
      if (datadismiss != '') {
      return (
        <Link to={url} {...this.props} className={`${classBuilder} ${className}`} id={id} data-toggle={datatoggle} data-target={datatarget} onClick={onClick} data-dismiss={datadismiss}>
          {this.props.children}
        </Link>
      )
      } else {
        return (
          <Link to={url} {...this.props} className={`${classBuilder} ${className}`} id={id} data-toggle={datatoggle} data-target={datatarget} onClick={onClick}>
            {this.props.children}
          </Link>
        )
      }
    }
    if (datadismiss != '') {
      return ( 
          <div {...this.props} className={`${classBuilder} ${className} ${disabled ? 'disabled' : ''}`} id={id} data-toggle={datatoggle} data-target={datatarget} onClick={onClick} data-dismiss={datadismiss}>
            {this.props.children}
          </div> 
      )
    }else{
      return ( 
        <div {...this.props} className={`${classBuilder} ${className} ${disabled ? 'disabled' : ''}`} id={id} data-toggle={datatoggle} data-target={datatarget} onClick={onClick}>
          {this.props.children}
        </div> 
      )
    }
  }
}

export default Button
