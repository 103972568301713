import {
  PAYMENT_UPDATE,
  FETCH_PAYMENT_DETAIL,
  HAS_OLD_SUBSCRIPTION,
  NO_SUBSCRIPTION
} from '../actions/types'

const INITIAL_STATE = {
  loading: false,
  billingData: null,
  hasOldSubscription: false,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PAYMENT_DETAIL:
      return { ...state, ...INITIAL_STATE, loading: true }
    case NO_SUBSCRIPTION:
        return { ...state, ...INITIAL_STATE }
    case PAYMENT_UPDATE:
      return {
        ...state,
        ...INITIAL_STATE,
        billingData: action.details,
        loading: false,
      }
    case HAS_OLD_SUBSCRIPTION:
      return {
        ...state,
        ...INITIAL_STATE,
        hasOldSubscription: true,
        loading: false,
      }
    default:
      return state
  }
}
