import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getSurgeons } from '../../actions/SurgeonActions'
import { loadSurgeonQuicknotes } from '../../actions/QuicknotesActions'
import _ from 'lodash'
import $ from 'jquery'
import { Modal } from '../commons/modals'
import { Table, TRow, TData, THead } from '../commons/table'
import { Link } from 'react-router'
export class LoadFromModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected: 0,
      page: 1,
      searchKeyword: '',
    }

    
    this.searchData = _.debounce(this.getItemResults, 600)
  }

  componentDidMount = () => {
    this.props.getSurgeons('all', this.state.page)
  }

  getItemResults = () => {
    this.props.getSurgeons('all', this.state.page, this.state.searchKeyword)
  }

  searchItem({ target: { value } }) {
    this.setState(
      {
        searchKeyword: value,
        page: 1,
        loading: true,
      },
      () => {
        this.searchData()
      }
    )
  }

  searchSurgeon = () => {
    const { page, searchKeyword } = this.state
    this.setState(
      {
        page: 1,
        searchKeyword: this.searchKeyword.value,
      },
      () => {
        this.props.getSurgeons('all', this.state.page, this.state.searchKeyword)
      }
    )
  }

  render() {
    const debounce = _.debounce(keyword => {
      this.searchSurgeon()
    }, 500)
    const { surgeons, except } = this.props

    return (
      <>
        <Modal
          idModal="loadFromModalCenter"
          size="modal-md"
          title="Copy surgeon quicknotes"
          confirmText="Copy"
          cancelText="Cancel"
          bodyClass="select-procedures-body"
          confirm={e => {
            this.props.loadSurgeonQuicknotes(this.state.selected)
            this.props.loadFrom()
            $('#loadFromModalCenter').modal('hide')
          }}
          cancel={e => {
            //fn here
          }}
        >
          <div className="row">
            <div className="col-lg-7 col-md-10 mt8 mb16">
              <div className="line-form-group-v2">
                <input
                  type="text"
                  className="line-form-v2"
                  onChange={this.searchItem.bind(this)}
                />
                <label>
                  Searchs <i className="mdi mdi-magnify"></i>
                </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Table
                minWidth={540}
                className="modal-table"
                renderHeaders={() => {
                  return (
                    <TRow>
                      <THead className="w40" text="" />
                      <THead className="w300" text="Surgeon" />
                    </TRow>
                  )
                }}
                renderRows={() => {
                  let rows = surgeons.map(data => {
                    let surgeon = data
                    if (except != surgeon.id)
                      return (
                        <TRow hasChoices key={`row-lfm-${surgeon.id}`}>
                          <TData
                            className="w40"
                            onClick={() => {
                              this.setState({
                                selected: surgeon.id,
                              })
                            }}
                          >
                            <div className="custom-control custom-radio custom-control-inline">
                              <input
                                type="radio"
                                id={`radio-lfm-${data.id}-${surgeon.id}`}
                                name={`radio-lfm`}
                                className="custom-control-input"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`radio-lfm-${data.id}-${surgeon.id}`}
                              ></label>
                            </div>
                          </TData>
                          <TData className="w300">{`${surgeon.firstname} ${surgeon.lastname}`}</TData>
                        </TRow>
                      )
                  })
                  return rows
                }}
                // renderNotification={() => {
                //     return (<TableNotificationCard type="success" message="Success message here..." />)
                // }}
              />
            </div>
          </div>
        </Modal>
        <div
          className="modal fade"
          id="loadFromModalCenter222"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="loadFromModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body pt30 px30 pb0">
                <h5 className="m-0 mx-auto font-weight-bold pb20">
                  Copy surgeon quicknotes
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </h5>
                <p className="m-0">
                  Select from the list of surgeons to copy their{' '}
                  <b>[procedures/phase]</b>
                </p>
              </div>
              <div className="modal-body px30 pt-0">
                <div className="row">
                  <div className="col-md-5">
                    <div className="form-group mb15">
                      <label className=" mb10">Search</label>
                      <input
                        type="text"
                        name="test"
                        className="form-control"
                        placeholder="Start typing surgeon name"
                        ref={el => (this.searchKeyword = el)}
                        onChange={event => {
                          debounce(event.target.value)
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="w-100 of-auto">
                      <table className="table table-striped table-fixed border table-hover-bold min-table-width-610">
                        <thead className="bg-dark text-white">
                          <tr>
                            <th className="w80" />
                            <th className="font-weight-bold mw300">
                              &nbsp;Surgeon
                            </th>
                            <th className="font-weight-bold mw220">
                              View [procedure/phase]
                            </th>
                          </tr>
                        </thead>
                        <tbody
                          style={{
                            float: 'left',
                            width: '100%',
                            overflow: 'auto',
                            maxHeight: '244px',
                          }}
                        >
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-body mb-0 px30 pb0 pt-0 border-0 justify-content-start">
                <div
                  className="btn btn-dark mr30 mt-0 px30 mb-0 float-left"
                  data-dismiss="modal"
                  onClick={() => {
                    this.props.loadSurgeonQuicknotes(this.state.selected)
                    this.props.loadFrom()
                  }}
                >
                  Add
                </div>
                <div
                  className="btn btn-light border-dark px30 mt-0 mb-0 float-left m-xst20"
                  data-dismiss="modal"
                >
                  Cancel
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    surgeons: state.surgeon.surgeons,
  }
}

export default connect(
  mapStateToProps,
  { getSurgeons, loadSurgeonQuicknotes }
)(LoadFromModal)
