import React, { Component } from 'react'

export class Inbodyloader extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        const { progress, style, type } = this.props
        if (progress > 0){

            if(type == 'table'){
                return (
                    <tr className="inbody-loader-tr h-100" style={style}>
                        <td className="text-center w-100 h-100 pt30">
                            <div className="w-100 text-center mb20 d-flex flex-column font-weight-bold">
                                Loading! Please wait...
                            </div>
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                        </td>
                    </tr>
                )
            }else{
                return (
                    <div className="inbody-loader text-center" style={style}>
                        <div className="w-100 text-center mb20 d-flex flex-column font-weight-bold">
                            Loading! Please wait...
                        </div>
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                )
            }
        }else{
            return null;
        }

    }
}

export default Inbodyloader
