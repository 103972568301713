import React, { Component } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import _ from 'lodash'
import $ from 'jquery'

export class InstrumentsSkeleton extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      counter: _.range(_.random(1, 3)),
      color: '#d2d2d2',
      highlightColor: '#dedede'
    }
  }
  componentWillMount() {
    if ($('body').hasClass('dark-theme')) {
      this.setState({
        color: '#1d212e',
        highlightColor: '#151821'
      })
    }
  }
  
  titleElement = () => {
    return (
      <Skeleton width={'30%'} height={30} />
    )
  }

  textElement = () => {
    return (
    <div className={'card text-preview w-100 mb20 skeletoncard'}>
        <div className="card-header text-capitalize pb5 ">
          <Skeleton width={'50%'} height={30} />
        </div>
      </div>
    )
  }

  render() {
    return (
      <SkeletonTheme color={this.state.color} highlightColor={this.state.highlightColor}> 
        <div className="row">
          {
            this.state.counter.map(element => {
              return ( 
                <div className={`col-md-12 ${element == _.last(this.state.counter) ? 'mb25':''}`}> 
                    {this.textElement()}
                </div> 
              )
            })
          } 
          <div className="col-md-12 mb25"> 
              {this.titleElement()}
          </div> 
          <div className="col-md-12"> 
              {this.textElement()}
          </div> 
        </div>
        </SkeletonTheme> 
    )
  }

}

export default InstrumentsSkeleton
