import React, { Component } from 'react'

import TheLounge from '../../assets/images/faq/the-lounge.png'
import TheLoungePage from '../../assets/images/faq/the-lounge-page.png'
import MyRecords from '../../assets/images/faq/my-records.png'
import MyRecordsSurgeons from '../../assets/images/faq/my-records-surgeon.png'
import MyRecordsItems from '../../assets/images/faq/my-records-items.png'
import MyRecordsProcedures from '../../assets/images/faq/my-records-procedures.png'
import M27 from '../../assets/images/faq/MedicalInventory/m27.png' 


import $ from 'jquery'
export class manual extends Component {
  
  imgSrc(src){
    return require(`../../assets/images/faq/${src}`)
  }

  goTo(id){
    let offset = $(`#${id}`).offset();

    var findParentOffsetArray = ['procedure-stages-logs',
      'procedure-stages-load',
      'procedure-stages-add',
      'procedure-stages-edit',
      'procedure-stages-rearrange',
      'procedure-stages-delete']

    if(id=='the-lounge'){
      offset=0
    }
    if (findParentOffsetArray.indexOf(id) >= 0){
      let offsetParent = $(`#${id}`).parent().parent().parent()[0].offsetTop
      $('#faq-content').animate({
        scrollTop: $(`#${id}`)[0].offsetTop + offsetParent,
      }, 400)
    }else{
      $('#faq-content').animate({
        scrollTop: $(`#${id}`)[0].offsetTop,
      }, 400)
    }
    console.log('$(`#${id}`)[0].offsetTop', $(`#${id}`)[0].offsetTop)
    
  } 

  render() {
    
    

    return (
      <div className="container">
        <div className="row accordion h-100" id="accordion-parent">

          <div className="col-lg-3 col-md-4">
            <ul className="list-unstyled left-faq mb-0">
              <li className="pb-1"><h2 className="page-title"><i className="mdi mdi-frequently-asked-questions"></i> User Manual</h2></li>
              <li className="pb-1">
                <a className="bold" data-toggle="collapse" data-target="#lounge-menu" aria-expanded="true" aria-controls="lounge-menu" onClick={() => { this.goTo('the-lounge') }}>
                  The lounge
                </a>
                <div id="lounge-menu" class="collapse" aria-labelledby="headingOne" data-parent="#accordion-parent">
                  <div class="card-body py-0"> 
                    <ul className="list-unstyled">
                      <li className="py-1" onClick={() => { this.goTo('the-lounge-page') }}><a className="">The lounge page</a></li>
                    </ul>
                  </div>
                </div>
              </li>

              <li className="pb-1">
                <a className="bold" data-toggle="collapse" data-target="#myrecords-menu" aria-expanded="true" aria-controls="myrecords-menu" onClick={() => { this.goTo('my-records') }} >
                  My records
                </a>
                <div id="myrecords-menu" class="collapse" aria-labelledby="headingOne" data-parent="#accordion-parent">
                  <div class="card-body py-0">
                    <ul className="list-unstyled">
                      <li className="py-1"><a className=""  onClick={() => { this.goTo('my-records-surgeons') }} >Surgeons</a></li>
                      <li className="py-1"><a className="" onClick={() => { this.goTo('my-records-items') }} >Items</a></li>
                      <li className="py-1"><a className="" onClick={() => { this.goTo('my-records-procedures') }} >Procedures</a></li>
                      <li className="py-1"><a className="" onClick={() => { this.goTo('my-records-media') }} >My media uploads</a></li>

                    </ul>
                  </div>
                </div>
              </li>

              <li className="pb-1">
                <a className="bold" data-toggle="collapse" data-target="#accountsettings-menu" aria-expanded="true" aria-controls="accountsettings-menu" onClick={() => { this.goTo('account-settings') }}>
                  Account settings
                </a>
                <div id="accountsettings-menu" class="collapse" aria-labelledby="headingOne" data-parent="#accordion-parent">
                  <div class="card-body py-0">
                    <ul className="list-unstyled">
                      <li className="py-1"><a className="" onClick={() => { this.goTo('account-settings-2') }}>Account settings</a></li>
                      <li className="py-1"><a className="" onClick={() => { this.goTo('my-profile') }}>My profile</a></li>
                      <li className="py-1"><a className="" onClick={() => { this.goTo('billing-details') }}>Billing details</a></li>
                    </ul>
                  </div>
                </div>
              </li>
 
              <li className="pb-1">
                <a className="bold" onClick={() => { this.goTo('dark-mode') }}>
                  Dark mode
                </a> 
              </li>

              <li className="pb-1">
                <a className="bold" data-toggle="collapse" data-target="#surgeonprofile-menu" aria-expanded="true" aria-controls="surgeonprofile-menu" onClick={() => { this.goTo('surgeon-profile') }}>
                  Surgeon Profile
                </a>
                <div id="surgeonprofile-menu" class="collapse" aria-labelledby="headingOne" data-parent="#accordion-parent">
                  <div class="card-body py-0">
                    <ul className="list-unstyled">
                      <li className="py-1"><a className="" onClick={() => { this.goTo('surgeon-new') }}>Creating new surgeon profile</a></li>
                      <li className="py-1"><a className="" onClick={() => { this.goTo('surgeon-select') }}>Selecting Surgeon specialties and procedures</a></li>
                      <li className="py-1"><a className="" onClick={() => { this.goTo('surgeon-edit') }}>Editing surgeon profile</a></li>
                      <li className="py-1"><a className="" onClick={() => { this.goTo('surgeon-create-procedure') }}>Creating Procedures/Adding Procedures</a></li>
                      
                    </ul>
                  </div>
                </div>
              </li>

              <li className="pb-1">
                <a className="bold" data-toggle="collapse" data-target="#surgeonquicknotes-menu" aria-expanded="true" aria-controls="surgeonquicknotes-menu" onClick={() => { this.goTo('surgeon-quicknotes') }}>
                  Surgeon Quicknotes
                </a>
                <div id="surgeonquicknotes-menu" class="collapse" aria-labelledby="headingOne" data-parent="#accordion-parent">
                  <div class="card-body py-0">
                    <ul className="list-unstyled">
                      <li className="py-1"><a className="" onClick={() => { this.goTo('surgeon-nav') }}>Navigating in Surgeon Quicknotes</a></li>
                    </ul>
                  </div>
                </div>
              </li>

              <li className="pb-1">
                <a className="bold" data-toggle="collapse" data-target="#standardpages-menu" aria-expanded="true" aria-controls="standardpages-menu" onClick={() => { this.goTo('standard-pages') }}>
                  Standard Pages
                </a>
                <div id="standardpages-menu" class="collapse" aria-labelledby="headingOne" data-parent="#accordion-parent">
                  <div class="card-body py-0">
                    <ul className="list-unstyled">
                      <li className="py-1"><a className="" onClick={() => { this.goTo('standard-edit') }}>Editing standard pages</a></li>
                      <li className="py-1"><a className="" onClick={() => { this.goTo('standard-add') }}>Adding items to standard pages</a></li>
                      <li className="py-1"><a className="" onClick={() => { this.goTo('standard-assign') }}>Assign medical items to table</a></li>
                    </ul>
                  </div>
                </div>
              </li>

              <li className="pb-1">
                <a className="bold" data-toggle="collapse" data-target="#standardelements-menu" aria-expanded="true" aria-controls="standardelements-menu" onClick={() => { this.goTo('element-standard') }}>
                  Standard Elements
                </a>
                <div id="standardelements-menu" class="collapse" aria-labelledby="headingOne" data-parent="#accordion-parent">
                  <div class="card-body py-0">
                    <ul className="list-unstyled">
                      <li className="py-1"><a className="" onClick={() => { this.goTo('element-standard-edit') }}>Adding/Editing Text element</a></li>
                      <li className="py-1"><a className="" onClick={() => { this.goTo('element-standard-add') }}>Creating/Editing Media Gallery element</a></li>
                      <li className="py-1"><a className="" onClick={() => { this.goTo('element-standard-create') }}>Creating/Editing Text & Media element</a></li>
                    </ul>
                  </div>
                </div>
              </li>

              <li className="pb-1">
                <a className="bold" data-toggle="collapse" data-target="#procedurestages-menu" aria-expanded="true" aria-controls="procedurestages-menu" onClick={() => { this.goTo('procedure-stages') }}>
                 Procedure stages
                </a>
                <div id="procedurestages-menu" class="collapse" aria-labelledby="headingOne" data-parent="#accordion-parent">
                  <div class="card-body py-0">
                    <ul className="list-unstyled">
                      <li className="py-1"><a className="" onClick={() => { this.goTo('procedure-stages-nav') }}>Navigating through procedure stages</a></li>
                      <li className="py-1"><a className="" onClick={() => { this.goTo('procedure-stages-settings') }}>Procedure settings</a>
                        
                      </li>   
                      <ul className="list-unstyled">
                          <li className="py-1 ml-2 pl-2"> <a onClick={() => { this.goTo('procedure-stages-logs') }}><i className="mdi mdi-keyboard-return mdi-flip-h fs12"></i> Procedure logs </a></li>
                          <li className="py-1 ml-2 pl-2"> <a onClick={() => { this.goTo('procedure-stages-load') }}><i className="mdi mdi-keyboard-return mdi-flip-h fs12"></i> Load Procedure </a></li>
                          <li className="py-1 ml-2 pl-2"> <a onClick={() => { this.goTo('procedure-stages-add') }}><i className="mdi mdi-keyboard-return mdi-flip-h fs12"></i> Add new stages </a></li>
                          <li className="py-1 ml-2 pl-2"> <a onClick={() => { this.goTo('procedure-stages-edit') }}> <i className="mdi mdi-keyboard-return mdi-flip-h fs12"></i> Editing /Deleting stages </a> </li>
                          <li className="py-1 ml-2 pl-2"> <a onClick={() => { this.goTo('procedure-stages-rearrange') }}> <i className="mdi mdi-keyboard-return mdi-flip-h fs12"></i> Rearranging stages </a> </li>
                          <li className="py-1 ml-2 pl-2"> <a onClick={() => { this.goTo('procedure-stages-delete') }}> <i className="mdi mdi-keyboard-return mdi-flip-h fs12"></i> Delete Procedure </a> </li>
                        </ul>

                    </ul>
                  </div>
                </div>
              </li>

              <li className="pb-1">
                <a className="bold" data-toggle="collapse" data-target="#consumables-menu" aria-expanded="true" aria-controls="consumables-menu" onClick={() => { this.goTo('consumables') }}>
                  Consumables
                </a>
                <div id="consumables-menu" class="collapse" aria-labelledby="headingOne" data-parent="#accordion-parent">
                  <div class="card-body py-0">
                    <ul className="list-unstyled">
                      <li className="py-1"><a className="" onClick={() => { this.goTo('consumables-edit') }}>Editing consumable list </a></li>
                      <li className="py-1"><a className="" onClick={() => { this.goTo('consumables-assign') }}>Assigning/Removing items in category lists </a></li>
                    </ul>
                  </div>
                </div>
              </li>

              <li className="pb-1">
                <a className="bold" data-toggle="collapse" data-target="#instruments-menu" aria-expanded="true" aria-controls="instruments-menu" onClick={() => { this.goTo('instruments') }}>
                  Instruments
                </a>
                <div id="instruments-menu" class="collapse" aria-labelledby="headingOne" data-parent="#accordion-parent">
                  <div class="card-body py-0">
                    <ul className="list-unstyled">
                      <li className="py-1"><a className="" onClick={() => { this.goTo('instruments-edit') }}>Editing instrument list </a></li>
                      <li className="py-1"><a className="" onClick={() => { this.goTo('instruments-assign') }}>Assigning/removing instruments into category lists</a></li>
                      <li className="py-1"><a className="" onClick={() => { this.goTo('instruments-assign-trays') }}>Assigning/removing instrument trays</a></li>
                    </ul>
                  </div>
                </div>
              </li>

              <li className="pb-1">
                <a className="bold" data-toggle="collapse" data-target="#positioning-menu" aria-expanded="true" aria-controls="positioning-menu" onClick={() => { this.goTo('positioning') }}>
                  Positioning
                </a>
                <div id="positioning-menu" class="collapse" aria-labelledby="headingOne" data-parent="#accordion-parent">
                  <div class="card-body py-0">
                    <ul className="list-unstyled">
                      <li className="py-1"><a className="" onClick={() => { this.goTo('positioning-injury') }}>Positioning injury prevention information</a></li>
                      <li className="py-1"><a className="" onClick={() => { this.goTo('positioning-select') }}>Selecting positioning</a></li>
                      <li className="py-1"><a className="" onClick={() => { this.goTo('positioning-create') }}>Creating notes/editing notes</a></li>
                    </ul>
                  </div>
                </div>
              </li>
              
              <li className="pb-1">
                <a className="bold" data-toggle="collapse" data-target="#positioningequipment-menu" aria-expanded="true" aria-controls="positioningequipment-menu" onClick={() => { this.goTo('positioning-equipment') }}>
                  Positioning Equipment
                </a>
                <div id="positioningequipment-menu" class="collapse" aria-labelledby="headingOne" data-parent="#accordion-parent">
                  <div class="card-body py-0">
                    <ul className="list-unstyled">
                      <li className="py-1"><a className="" onClick={() => { this.goTo('positioning-equipment-body') }}>Body area navigation</a></li>
                      <li className="py-1"><a className="" onClick={() => { this.goTo('positioning-equipment-assign') }}>Assigning and editing positioning equipment list</a></li>
                    </ul>
                  </div>
                </div>
              </li>

              <li className="pb-1">
                <a className="bold" data-toggle="collapse" data-target="#roomsetup-menu" aria-expanded="true" aria-controls="roomsetup-menu" onClick={() => { this.goTo('room-setup') }}>
                  Room Setup & Equipment
                </a>
                <div id="roomsetup-menu" class="collapse" aria-labelledby="headingOne" data-parent="#accordion-parent">
                  <div class="card-body py-0">
                    <ul className="list-unstyled">
                      <li className="py-1"><a className="" onClick={() => { this.goTo('room-setup-add') }}>Adding room setup image</a></li>
                      <li className="py-1"><a className="" onClick={() => { this.goTo('room-setup-assign') }}>Assigning room equipment</a></li>
                      <li className="py-1"><a className="" onClick={() => { this.goTo('room-setup-notes') }}>Creating notes/Editing notes</a></li>
                    </ul>
                  </div>
                </div>
              </li>

              <li className="pb-1">
                <a className="bold" data-toggle="collapse" data-target="#medicalinventory-menu" aria-expanded="true" aria-controls="medicalinventory-menu" onClick={()=>{ this.goTo('medical-inventory') }}>
                  Medical Inventory
                </a>
                <div id="medicalinventory-menu" class="collapse" aria-labelledby="headingOne" data-parent="#accordion-parent">
                  <div class="card-body py-0">
                    <ul className="list-unstyled"> 
                      <li className="py-1"><a className="" onClick={()=>{ this.goTo('medical-inventory-supplies') }}>Supplies/Instruments & Equipment</a></li>
                      <li className="py-1"><a className="" onClick={()=>{ this.goTo('medical-inventory-register') }}>Registering a new item</a></li>
                      <li className="py-1"><a className="" onClick={()=>{ this.goTo('medical-inventory-edit') }}>Editing items</a></li>
                      <li className="py-1"><a className="" onClick={()=>{ this.goTo('medical-inventory-add-size') }}>Adding sizes</a></li>
                      <li className="py-1"><a className="" onClick={()=>{ this.goTo('medical-inventory-create-set') }}>Creating/Editing your own instrument sets</a></li>
                      <li className="py-1"><a className="" onClick={()=>{ this.goTo('medical-inventory-create-instrument') }}>Creating a new instrument</a></li>
                      <li className="py-1"><a className="" onClick={()=>{ this.goTo('medical-inventory-edit-tray') }}>Editing existing instrument trays</a></li>

                    </ul>
                  </div>
                </div>
              </li>

            </ul>
          </div>
          <div className="col-lg-8 col-md-8 right-faq" id="faq-content">

            <div className="row" id="the-lounge">
              <div className="col-md-12">
                <h1 className="bold mb14">The Lounge</h1>
                <p className="mb8">The lounge is your dashboard that allows you to view all your activity logs as well as other statistical information. </p>
                <p className="mb8">To access the lounge click on the your profile on the top right and click on profile</p>
                <img src={TheLounge} className="w-100 p-5" />
              </div>  
            </div>

            <hr />

            <div className="row" id="the-lounge-page">
              <div className="col-md-12">
                <h2 className="bold mb14">The Lounge Page</h2>
                <img src={TheLoungePage} className="w-100 p-5" />
                <ol type="A" className="bold">
                  <li className="mb16 bold">
                    <h4 className="mb10 bold">User Profile</h4>
                    <p className="regular">Shows your profile and your specialties. Click to link to profile settings.</p>
                  </li>
                  <li className="mb16 bold">
                    <h4 className="mb10 bold">Recent Media upload</h4>
                    <p className="regular">Displays your latest media you have uploaded. Click on “<b>View all</b>” to view all the media you have uploaded.</p>
                  </li>
                  <li className="mb16 bold">
                    <h4 className="mb10 bold">My contributions</h4>
                    <p className="regular">Counts all your activities you have made.</p>
                  </li>
                  <li className="mb16 bold">
                    <h4 className="mb10 bold">Quicknotes Drafts</h4>
                    <p className="regular">Any drafts you have saved to draft in Surgeon Quicknotes is loaded here by clicking on the link. Clicking on the link will load the quicknote page you clicked on save to draft.</p>
                  </li>
                  <li className="mb16 bold">
                    <h4 className="mb10 bold">Procedure Drafts</h4>
                    <p className="regular">Any drafts you have saved to draft in the procedure stages is loaded here by clicking on the link. Clicking on the link will load the procedure page you clicked on save to draft.</p>
                  </li>
                  <li className="mb16 bold">
                    <h4 className="mb10 bold">Activity logs</h4>
                    <p className="regular">Records all your activity with CORH surgeon preferences. Keep track of items you have removed or added to surgeon procedure cards especially in consumables and instruments. Click on the link to view items or load surgeon’s procedure cards.</p>
                  </li>
                </ol>
              </div>
            </div>

            <hr />

            <div className="row" id="my-records">
              <div className="col-md-12">
                <h1 className="bold mb14">My Records</h1>
                <p className="mb8">My records allow you view all items, surgeon, procedures and media upload you have created in your profile.</p>
                <p className="mb8">To access the My records, click on your profile on the top right and click on “<b>My profile</b>”</p>
                <img src={MyRecords} className="w-100 p-5" />
              </div>
            </div>


            <div className="row" id="my-records-surgeons">
              <div className="col-md-10 mx-auto">
                <h3 className="bold mb14">Surgeons</h3>
                <img src={MyRecordsSurgeons} className="w-100 p-5" />
                <p className="mb8">Delete or Edit surgeons profile </p>
                <hr />
              </div>
            </div>

            <div className="row" id="my-records-items">
              <div className="col-md-10 mx-auto">
                <h3 className="bold mb14">Items</h3>
                <img src={MyRecordsItems} className="w-100 p-5" />
                <p className="mb8">Delete, edit and or add more sizes for items you have created </p>
                <hr />
              </div>
            </div>

            <div className="row" id="my-records-procedures">
              <div className="col-md-10 mx-auto">
                <h3 className="bold mb14">Procedures</h3>
                <img src={MyRecordsProcedures} className="w-100 p-5" />
                <p className="mb8">Delete and or edit name of procedure you have created</p>
                <hr />
              </div>
            </div>

            <div className="row" id="my-records-media">
              <div className="col-md-10 mx-auto">
                <h3 className="bold mb14">My media uploads</h3>
                <p className="mb8">View all the media you have uploaded</p>
                <hr />
              </div>
            </div>

            <hr />

            <div className="row" id="account-settings">
              <div className="col-md-12">
                <h1 className="bold mb14">Account Settings</h1>   
                <p className="mb8">Access your account setting, edit your profile, change password, emails and view your billing details. To access your account settings, click on your profile on the top right and click on <b>“Account settings”</b></p>
                <img src={M27} className="w-100 p-5" />
              </div>
            </div>

            <hr />

            <div className="row" id="account-settings-2">
              <div className="col-md-12">
                <h3 className="bold mb14">Account Settings</h3>
                <p className="mb8">Navigation tabs will direct you to options in Account settings </p>
                <img src={this.imgSrc('MedicalInventory/m28.png')} className="w-100 p-5" />
              </div>
            </div>

            <hr />

            <div className="row" id="my-profile">
              <div className="col-md-12">
                <h3 className="bold mb14">My Profile</h3>
                <img src={this.imgSrc('MedicalInventory/m29.png')} className="w-100 p-5" />
                <p className="mb8">Edit Name, upload profile photo and job title. Select specialties in which you specialize in</p>
              </div>
            </div>

            <hr />

            <div className="row" id="my-account-settings">
              <div className="col-md-12">
                <h3 className="bold mb14">My account settings </h3>
                <img src={this.imgSrc('MedicalInventory/m30.png')} className="w-100 p-5" />
                <p className="mb8">Change account e-mail address and change password.</p>
              </div>
            </div>

            <hr />

            <div className="row" id="billing-details">
              <div className="col-md-12">
                <h3 className="bold mb14">Billing Details</h3>
                <img src={this.imgSrc('MedicalInventory/m30.png')} className="w-100 p-5" />
                <p className="mb8">Review your billing details. Here you are able to change credit card details. View when your next bill will be. If you are not a premium member you can also upgrade to premium in this page.</p>
              </div>
            </div>

            <hr />

            <div className="row" id="dark-mode">
              <div className="col-md-12">
                <h1 className="bold mb14">Dark Mode</h1>
                <p className="mb8">For premium users you are able to switch to dark mode. </p>
                <p className="mb8">To switch to dark mode, click on your profile on the top right and click on <b>“Dark mode”</b></p>
                <p className="mb8">Click to toggle on or off dark mode</p>
                <img src={this.imgSrc('MedicalInventory/m31.png')} className="w-100 p-5" />
                <p className="mb8">Dark mode is toggled</p>
                <img src={this.imgSrc('MedicalInventory/m32.png')} className="w-100 p-5" />
              </div>
            </div>
            <hr />

            <div className="row mb24" id="surgeon-profile">
              <div className="col-md-12">
                <h1 className="bold mb14">Surgeon Profile</h1>
                <p className="mb8">Access surgeon profiles to view their quicknotes and choose procedure to view the procedure card.</p>
              </div>
            </div>

            <div className="row mb22" id="surgeon-new">
              <div className="col-md-12">
                <h3 className="bold mb14"><i>Creating a new Surgeon Profile</i></h3>
                <p className="mb8">In the “Surgeon List” Page</p>
                <ol tpye="1">
                  <li>Creating a new Surgeon Profile</li>
                  <img src={this.imgSrc('CreatingNewsurgeonProfile/CSP1.png')} className="w-100 p-5" />
                  <li>Fill in Surgeon details </li>
                  <p className="pl-4"><b>Note</b>: Surgeon Last name, Handedness and glove a required to be inputted before proceeding</p>
                  <img src={this.imgSrc('CreatingNewsurgeonProfile/CSP2.png')} className="w-100 p-5" />
                  <li>Selecting gloves </li>
                  <ol className="pl-4" type="a">
                    <li>Click on <img src={this.imgSrc('CreatingNewsurgeonProfile/CSP3.png')} className="w100 p-1" /></li>
                    <li>Search for desired glove. </li>
                    <ol type="i">
                      <li><b>Note</b>: If desired glove is not present select any glove as temporary glove for now. Create your own glove item in medical inventory and then edit surgeon profile and select your newly added glove</li>
                    </ol>
                  </ol>
                  <li>Click “Continue”</li>
                </ol>
              </div>
            </div>

            <div className="row" id="surgeon-select">
              <div className="col-md-12">
                <h3 className="bold mb14"><i>Selecting Surgeon Specialties and Procedures</i></h3>
                <p className="mb8">In the “Surgeon List” Page</p>
                <ol tpye="1">
                  <li>Select desired Specialty</li>
                  <img src={this.imgSrc('CreatingNewsurgeonProfile/CSP4.png')} className="w-100 p-5" />
                  <li>Once specialty is chosen select the specific surgical area (category)</li>
                  <img src={this.imgSrc('CreatingNewsurgeonProfile/CSP5.png')} className="w-100 p-5" />
                  <li>Select the desired procedures under that surgical area</li>
                  <img src={this.imgSrc('CreatingNewsurgeonProfile/CSP6.png')} className="w-100 p-5" />
                  <ol className="pl-4 mb12" type="a"> 
                    <li>Select all desired procedures for the surgeon</li>
                    <img src={this.imgSrc('CreatingNewsurgeonProfile/CSP7.png')} className="w-100 p-5" />
                  </ol>
                  <li>Click “Save procedures”</li>
                  <img src={this.imgSrc('CreatingNewsurgeonProfile/CSP6.png')} className="w-100 p-5" />
                  <ol className="pl-4 mb12" type="a">
                    <li>If your surgeon performs surgery in other specialties you are able to select multiple specialties in the “Add specialty and Procedures” page</li>
                  </ol>
                  <li>Once completed selecting specialties and procedures for surgeon click “Complete Surgeon Profile”</li>
                </ol>
              </div>
            </div>


            <div className="row" id="surgeon-edit">
              <div className="col-md-12">
                <h3 className="bold mb14"><i>Editing surgeon Profile</i></h3>
                <p className="mb8">In the “Surgeon List” Page</p>
                <ol tpye="1">
                  <li>Select desired surgeon.</li>
                  <li>On the left side panel select surgeon profile icon then click on surgeons avatar profile</li>
                  <div className="row">
                    <div className="col-md-8">
                      <img src={this.imgSrc('CreatingNewsurgeonProfile/CSP8.png')} className="w-100 p-1" />
                    </div>
                    <div className="col-md-4">
                      <img src={this.imgSrc('CreatingNewsurgeonProfile/CSP9.png')} className="w-100 p-1" />
                    </div>
                  </div>
                </ol>
              </div>
            </div>

            <div className="row" id="surgeon-create-procedure">
              <div className="col-md-12">
                <h3 className="bold mb14"><i>Creating Procedures/Adding Procedures</i></h3>
                <p className="mb8">To create brand new procedures or add new procedures go to “Edit surgeon Page”</p>
                <p className="mb8">In the “Surgeon List” Page</p>
                <ol tpye="1">
                  <li>Select desired surgeon.</li>
                  <li>On the left side panel select surgeon profile icon then click on surgeons avatar profile</li>
                  <div className="row">
                    <div className="col-md-8">
                      <img src={this.imgSrc('CreatingNewsurgeonProfile/CSP8.png')} className="w-100 p-1" />
                    </div>
                    <div className="col-md-4">
                      <img src={this.imgSrc('CreatingNewsurgeonProfile/CSP9.png')} className="w-100 p-1" />
                    </div>
                  </div>
                  <li>Click on continue at the bottom of the page</li>
                  <li>In the “Specialty and Procedure page” select desired Specialty and then surgical area (Category) then the desired procedure</li>

                  <div className="row">
                    <div className="col-md-10 offset-1"> 
                      <b><i>Creating a new procedure</i></b>
                      <p className="mb8">From</p>
                      <img src={this.imgSrc('CreatingNewsurgeonProfile/CSP7.png')} className="w-100 p-5" />
                      
                      <p className="mb8">Click on “Add new procedure+”</p>
                      <img src={this.imgSrc('CreatingNewsurgeonProfile/CSP10.png')} className="w-100 p-5" />
                  
                      <p className="mb8">Input the name of new procedure</p>
                      <img src={this.imgSrc('CreatingNewsurgeonProfile/CSP11.png')} className="w-100 p-5" />

                      <p className="mb8">Click “Save Procedure”</p>
                      <p className="mb8">To update click on “Complete Surgeon Profile”</p>

                    </div> 
                  </div>
                </ol>
              </div>
            </div>

            <hr />
            <div className="row" id="surgeon-quicknotes">
              <div className="col-md-12">
                <h2 className="bold mb14">Surgeon Quicknotes</h2>
                <p className="mb8">Surgeon quicknotes are used as quick references for your surgeon in common items they use and quirks they do for every procedure. </p>
                <p className="mb8">Sections of surgeon Quicknotes are</p>
                <ul>
                  <li>Medication & Irrigation</li>
                  <li>Clip & Prep</li>
                  <li>Closure</li>
                  <li>Equipment setting start</li>
                  <li>Surgeon notes</li> 
                </ul>
                <p className="mb8">You are able to add notes and media as well as adding items needed for each section of the quicknotes. Quicknotes are all <a href='#'>standard pages</a> in CORH. </p>
                <p className="mb8">Items added here will automatically appear in the consumables phase of the procedure card.</p>
              </div>
            </div>

            <hr />
            <div className="row" id="surgeon-nav">
              <div className="col-md-12">
                <h3 className="bold mb14"><i>Navigating in Surgeon Quicknotes</i></h3>
                <img src={this.imgSrc('Quicknotes/QN1.png')} className="w-100 p-5" />

                <ol type="A">
                  <li>The tab navigation bar located under the title allows you navigate to different sections.</li>
                  <li>Clicking on the <b>“Show item list”</b> Bar will reveal the items needed for this particular section.</li>
                </ol>
                
              </div>
            </div>

            <hr />

            <div className="row" id="standard-pages">
              <div className="col-md-12">
                <h2 className="bold mb14">Standard Pages</h2>
                <p className="mb8">Standard Pages are pages in which you are able to create or edit your own notes. Add items such as consumables and instruments to be used for that particular phase of the procedure. Other features include the ability to create your own notes with the following functionality.</p>
                <img src={this.imgSrc('Quicknotes/QN8.png')} className="w-100 p-5" />
                <ol type="A" className="pl-4">
                  <li>
                    <b>Show Item List</b> <br />
                    <p className="mb8 pl-4">Click to show item list that will be used for this phase of the procedure</p>
                  </li>
                  <li>
                    <b>Text & Media</b> <br />
                    <p className="mb8 pl-4">Upload an image or a video (Video upload only available for Premium accounts) and write accompanying notes.</p>
                  </li>
                  <li>
                    <b>Text & Media</b> <br />
                    <p className="mb8 pl-4">Upload an image or a video (Video upload only available for Premium accounts) and write accompanying notes.</p>
                  </li>
                  <li>
                    <b>Text</b> <br />
                    <p className="mb8 pl-4">Write notes with our text editor.</p>
                  </li>
                  <li>
                    <b>Media Gallery</b> <br />
                    <p className="mb8 pl-4">Create a media gallery in which you are able to upload images and video (Video upload only available for Premium accounts). Write notes for each media.</p>
                  </li>

                </ol>

              </div>
            </div>

            <hr />
            <div className="row" id="standard-edit">
              <div className="col-md-12">
                <h3 className="bold mb14">Editing standard pages</h3>
                <p className="mb8">You are able to edit or create notes in standard pages as well as assign items relevant to this particular phase of the surgery.</p>
                <p className="mb8">Our standard pages are found in</p>
                <img src={this.imgSrc('Quicknotes/QN8.png')} className="w-100 p-5" />
                <ul className="pl-5">
                  <li>All surgeon quicknotes</li>
                  <li>In procedure stages</li>
                  <ul type="circle" className="pl-5 mb-3">
                    <li> Clip & Prep</li>
                    <li> Draping</li>
                    <li> Closure</li>
                    <li> Drains & Dressings</li>
                    <li> Post op instructions</li>
                    <li> Newly created procedure stages by users</li>

                  </ul>
                </ul>
                <p className="mb8">To start editing click on “Edit” button. In this case we are editing Closure phase in Surgeon Quicknotes</p>
                <p className="mb8">Click <b>edit</b></p>
                <img src={this.imgSrc('Quicknotes/QN2.png')} className="w-100 p-5" />
                <p className="mb8">You will now be in edit mode</p>
                
                <ol type="A" className="pl-5">


                  <li>
                    <b>Update</b> <br />
                    <p className="mb8 pl-4">Click to save and update any changes made on notes.</p>
                  </li>

                  <li>
                    <b>Save Draft</b> <br />
                    <p className="mb8 pl-4">Save your notes to draft and come back another time to finish it off. You are able to load previous saved drafts in the lounge.</p>
                    <p className="mb8 pl-4"><img src={this.imgSrc('Quicknotes/QN10.png')} className="w-100 p-5" /></p>
                  </li>
                  <li>
                    <b>Copy From</b> <br />
                    <p className="mb8 pl-4">Allows you to copy exactly copy the same notes from another surgeon for this particular phase. This is to give the option to not having to start from scratch if both surgeons are similar in their practice and what they use.</p>
                  </li>
                  <li>
                    <b>Preview</b> <br />
                    <p className="mb8 pl-4">Preview allows you to view what the final output of you page will look like before updating.</p>
                  </li>
                  <li>
                    <b>Back</b> <br />
                    <p className="mb8 pl-4">Cancel Edit mode</p>
                  </li>
                  <li>
                    <b>Show item list</b> <br />
                    <p className="mb8 pl-4">Click to open item list of items to be used for this phase in the surgeons procedure</p>
                  </li>
                  <li>
                    <b>Text only element</b> <br />
                    <p className="mb8 pl-4">Click to add a new section to the standard page for text only function</p>
                  </li>
                  <li>
                    <b>Text only element</b> <br />
                    <p className="mb8 pl-4">Click to add a new section to the standard page for text only function</p>
                  </li>
                  <li>
                    <b>Media Gallery element</b> <br />
                    <p className="mb8 pl-4">Click to add a new section to standard page for media gallery. Upload multiple media and set title and write notes for each media uploaded.</p>
                  </li>
                  <li>
                    <b>Text & Media element</b> <br />
                    <p className="mb8 pl-4">Click to add new section to standard page for text and media. Upload a media and write notes and heading for it.</p>
                  </li> 
                    

                </ol>
              </div>
            </div>

            <hr />

            <div className="row" id="standard-add">
              <div className="col-md-12">
                <h3 className="bold mb14">Adding items to standard pages</h3>
                <p className="mb8">
                  You are able to add the appropriate items to be used by the surgeon for that particular phase of the procedure. In this case we are adding items into Surgeons Quick notes <b>“Clip & Prep” </b>
                </p>
                <p className="mb8 pl-4"><b>Assign items table </b></p>
                <img src={this.imgSrc('Quicknotes/QN11.png')} className="w-100 p-5" />
                <ol type="A">
                  <li> <b>Item navigation</b> <br />
                    You are able to assign items from all medical inventory categories, Consumables, Instruments, Equipment. Click to navigate between each category.  </li>

                  <li> <b>Search data base</b> <br />
                    Search for particular items </li>

                  <li> <b>Filter by category</b> <br />
                    View items by its categories by filtering desired categories </li>

                  <li> <b>Select Item</b> <br />
                    Click to select desired item to be added </li>

                  <li> <b>View Product Description </b> <br />
                    Click to see full detailed information of item </li>

                  <li> <b>Review Items</b> <br />
                    Click to review items </li>

                </ol> 

              </div>
            </div>

            <hr />

            <div className="row" id="standard-assign">
              <div className="col-md-12">
                <h3 className="bold mb14">Assign items to table</h3>

                <ol type="1">
                  <li> Assign items to table</li>
                  <img src={this.imgSrc('Quicknotes/QN2.png')} className="w-100 p-5" />
                  <li>Click on Show item </li>
                  <img src={this.imgSrc('Quicknotes/QN3.png')} className="w-100 p-5" />
                  <li>Click on “Assign item” to assign items to your desired category</li>
                  <img src={this.imgSrc('Quicknotes/QN4.png')} className="w-100 p-5" />
                  <li> Search desired items to be selected in either consumables, instruments and equipment </li>
                  <li> Click review items </li>
                  <li> Review items you have chosen, deselect items that you do not desire. </li>
                  <li> Enter quantity needed for each item </li>
                  <li> Click “confirm assigned items” </li>
                  <li> Click “Update” to update and save standard page </li>
                </ol>

              </div>
            </div>

            <hr />

            <div className="row" id="element-standard">
              <div className="col-md-12">
                <h2 className="bold mb14">Standard Elements</h2>
                <p className="mb8">There are three standard elements in which you can choose from in order to create your own notes for your procedure cards. Elements show when you click on “Edit” and go into edit mode</p>
                <img src={this.imgSrc('Quicknotes/QN7.png')} className="w-100 p-5" />

                <p className="mb8"> Standard page when in edit mode </p>

                <p className="mb8"> New element section  </p>

                <p className="mb8"> When a new element section is created a box for that section will be created </p>
                <img src={this.imgSrc('Quicknotes/qn12.png')} className="w-100 p-5" />


                <ol type="A"> 
                   <li> <b>  Element rearrangement  </b> <br />
                    Click, hold and drag element up and down standard page to rearrange the order of the element  </li> 
                   <li> <b>  Copy element </b> <br />
                    Creates another copy of element </li> 
                   <li> <b>  Delete element </b> <br />
                    Deletes element </li> 
                </ol>

              </div>
            </div>

            <hr />

            <div className="row" id="element-standard-edit">
              <div className="col-md-12">
                <h3 className="bold mb14"><i>Adding/Editing Text element</i></h3> 

                <ol type="1">
                  <li>Click on “edit”</li>
                  <li>Click on element “text only”</li>
                  <li>A new text element is created  </li>
                  <li>Input desired text.  </li>
                  <li><b>Note</b>: Title input is required to be able to save and update standard page </li>
                  <li>Click on “Update” to update and save changes on standard pages. </li> 
                </ol>

              </div>
            </div>

            <hr />

            <div className="row" id="element-standard-add">
              <div className="col-md-12">
                <h3 className="bold mb14"><i>Creating/Editing Media Gallery element</i></h3>

                <ol type="1"> 
 
                <li> Click on “edit”</li>
                
                <li> Click on element “Text & Media Element”</li>
                
                <li> A new “Text & Media Element </li>
                
                <li> Add media either image or video</li>
                
                <li> Add notes through text input</li>
                
                <li> Input title of element <b>Note</b>: Must input title of text and media element in order to be able to update and save changes.</li>
                  <p className="mb8">Allows you to customize your procedure card by adding new stages of the procedure. If your surgeon has an extra step create a new stage here for you to be able to start taking notes.</p>
                  <p className="mb8"><b>Note</b>: There are 10 standardized stages in which you are not able to edit, delete or rearrange. The only stages you are able to do so are stages you create yourself.</p>
                  <img src={this.imgSrc('ProcedureSettings/ps5.png')} className="w-100 p-5" />
                
                </ol>

              </div>
            </div>

            <hr />

            <div className="row" id="element-standard-create">
              <div className="col-md-12">
                <h3 className="bold mb14"><i>Creating/Editing Text & Media Element</i></h3>

                <ol type="1">
                  <li>Click on “edit”</li>
                  <li>Click on element “Media Gallery</li>
                  <img src={this.imgSrc('Quicknotes/qn13.png')} className="w-100 p-5" />
                  <li>A new media gallery element is created</li>
                  <img src={this.imgSrc('Quicknotes/QN14.png')} className="w-100 p-5" />

                  <ol type="A" className="pl-4">
                    <li> <b> Insert Title </b> <br />
                      Input title Note: Must input title of media in order to be able to update and save changes
                    </li>
                    <li> <b> Delete Media </b> <br />
                      Delete Media
                    </li>
                    <li> <b> Add image or video+ </b> <br />
                      Click to add more either video or images to the gallery
                    </li>
                    <li> <b> Input notes </b> <br />
                      Input text notes here concerning about the attached image
                    </li>
                    <li> <b> Add another media </b> <br />
                      Click to add another media to the gallery
                    </li>
                  </ol>
                  <li>Click on “update” to save and update changes.</li>
                </ol>

              </div>
            </div>

            <hr />

            <div className="row" id="procedure-stages">
              <div className="col-md-12">
                <h2 className="bold mb14">Procedure Stages </h2>
                <p className="mb8">Procedural stages is the phases of the procedure. Following from consumables right to post op care notes, you will be able to create notes for every stage of the procedure. Follow a surgeons procedures from start to finish.</p>
              </div>
            </div>

            <hr />

            <div className="row" id="procedure-stages-nav">
              <div className="col-md-12">
                <h3 className="bold mb14"> <i>Navigating through Procedure stages</i> </h3>
                <img src={this.imgSrc('Procedure/P1.png')} className="w-100 p-5" />
                <p className="mb8">The navigation tabs allow for you to navigate to specific phases or stages of the procedure to view previous notes and or media you have saved. </p>
                <table className="w-100 ">
                  <body>
                    <tr>
                      <th className="p8">Note:</th>
                      <td className="p8">When first accessing a procedure card the other stages are greyed out and cannot be updated.  This is to ensure that each stage is filled out and completed before moving on to the next stage.</td>
                    </tr>
                    <tr>
                      <th className="p8">Tip:</th>
                      <td className="p8">If you are in a rush you can enable the next stage by clicking on “edit” then clicking on the “Update button”.</td>
                    </tr>
                  </body>
                </table>
              </div>
            </div>

            <hr />

            <div className="row" id="procedure-stages-settings">
              <div className="col-md-12">
                <h3 className="bold mb14"> <i>Procedure Settings</i> </h3>
                <p className="mb8">By clicking on the “cog” icon you are able to view the procedure settings.</p>
                <img src={this.imgSrc('Quicknotes/qn15.png')} className="w-100 p-5" />
                <p className="mb8"><b><i>Procedure Settings</i></b></p>
                <img src={this.imgSrc('ProcedureSettings/ps2.png')} className="w-100 p-5" />
                
                <ol type="1" className="pl-5">
                  <li id="procedure-stages-logs">
                    <b>Procedure Log</b> <br />
                    <p className="mb8">
                      In procedure settings you can view Procedure logs which will give track the history of all the changes and updates you have performed for this procedure.
                      <img src={this.imgSrc('ProcedureSettings/ps1.png')} className="w-100 p-5" />
                    </p>
                  </li>
                  <li id="procedure-stages-load">
                    <b>Load Procedure</b> <br />
                    <p className="mb8">
                      Load Procedure allows you to directly copy another surgeons procedure card of the same procedure.
                    </p>
                    <img src={this.imgSrc('ProcedureSettings/ps3.png')} className="w-100 p-5" />
                    <p className="mb8">
                      Clicking on “Load Procedure from” shows the list of other surgeons who have the same procedure. Select surgeon on whom you would want to copy the procedure from or view the procedure card
                    </p>
                    <img src={this.imgSrc('ProcedureSettings/ps4.png')} className="w-75 p-5" />
                  </li>
                  <li id="procedure-stages-add">
                    <b>Add new stages</b> <br />
                    <p className="mb8">
                      You are able to create new stages for the procedure. Does a surgeon perform an extra phase? Create one here.
                      <img src={this.imgSrc('ProcedureSettings/ps5.png')} className="w-100 p-5" />
                    </p>
                    <p className="mb8">Click on <b>“+ Add new stage”</b></p>
                    <p className="mb8">Assign type as “standard”</p>
                    <img src={this.imgSrc('ProcedureSettings/ps6.png')} className="w-100 p-5" />
                    <p className="mb8">Assign Icon</p>
                    <p className="mb8">Enter name of new stage</p>
                    <img src={this.imgSrc('ProcedureSettings/ps7.png')} className="w-100 p-5" />
                    <p className="mb8">Click on <b>“Add stage</b></p>
                  </li>
                  <li id="procedure-stages-edit">
                    <b>Editing/Deleting stages</b> <br />
                    <p className="mb8">
                      Apart from the standard pages you are able to edit and delete stages <br />
                      Edit stage
                      <img src={this.imgSrc('ProcedureSettings/ps9.png')} className="w-100 p-5" />
                    </p>
                    <p className="mb8">Delete stage </p>
                    <img src={this.imgSrc('ProcedureSettings/ps10.png')} className="w-100 p-5" /> 
                  </li>
                  <li id="procedure-stages-rearrange">
                    <b>Rearranging stages</b> <br />
                    <p className="mb8">
                      Click on <b> “Arrange stage”</b>
                    </p>
                      <img src={this.imgSrc('ProcedureSettings/ps8.png')} className="w-100 p-5" />
                    <p className="mb8">Click, hold and drag on ellipses to rearrange stages</p>
                    <img src={this.imgSrc('ProcedureSettings/ps11.png')} className="w-100 p-5" />
                    <p className="mb8">Click on <b>“Update arrangement”</b> to save arrangements</p>
                    <img src={this.imgSrc('ProcedureSettings/ps12.png')} className="w-100 p-5" />
                  </li>
                  <li id="procedure-stages-delete">
                    <b>Delete Procedure</b> <br />
                    <img src={this.imgSrc('ProcedureSettings/ps13.png')} className="w-100 p-5" /> 
                  </li>
                </ol>  

              </div>
            </div>

            <hr />

            

            <div className="row" id="consumables">
              <div className="col-md-12">
                <h2 className="bold mb14"> Consumables </h2>
                <p className="mb8">Allows you to view, assign new and edit consumables list for the procedure.</p>
                <img src={this.imgSrc('Procedure/P2.png')} className="w-100 p-5" />
                <ol type="A">
                  <li><b>Edit</b> <br/>
                    <p className="mb8">Click to edit consumable list</p>
                  </li>
                  <li><b>Category list</b> <br/>
                    <p className="mb8">Shows item details under its category (for this instance PRN)</p>
                  </li>
                  <li><b>Category bar</b> <br/>
                    <p className="mb8">Click to unhide category list</p>
                  </li>
                </ol>  
              </div>
            </div>

            <hr />

            <div className="row" id="consumables-edit">
              <div className="col-md-12">
                <h3 className="bold mb14"> <i>Editing Consumable lists</i> </h3>
                <p className="mb8">Click on edit to enter edit mode.</p>
                <img src={this.imgSrc('Procedure/P3.png')} className="w-100 p-5" />
                <ol type="A">
                  <li><b>Update</b> <br/>
                    <p className="mb8">Update and save changes to consumable list</p>
                  </li>

                  <li><b>Save Draft</b> <br/>
                    <p className="mb8">Save consumable list as draft. You can come back to your previous work through “lounge” page</p>
                  </li>

                  <li><b>Cancel</b> <br/>
                    <p className="mb8">Cancel all changes and go back to view mode</p>
                  </li>

                  <li><b>Standard Categories</b> <br/>
                    <p className="mb8">These are the standard categories in which CORH offers. You cannot delete or edit our standard categories</p>
                  </li>

                  <li><b>Add new category</b> <br/>
                    <p className="mb8">Allows you to create your own categories</p>
                  </li>
                
                </ol>  
              </div>
            </div>

            <hr />

            <div className="row" id="consumables-assign">
              <div className="col-md-12">
                <h3 className="bold mb14"> <i>Assigning/removing items into category lists</i> </h3>
                <p className="mb8">To add (assign) items to category list </p>
                <ol type="1">
                  <li>Click on <b>“edit”</b>.
                  </li>

                  <li>Click on desired category to add item by clicking on the category bar.
                  </li>
                  <img src={this.imgSrc('Procedure/p4.png')} className="w-100 p-5" />
                  
                  <li>To remove items from category list, click on <b>“Remove”</b>.
                  </li>
                  <img src={this.imgSrc('Procedure/p5.png')} className="w-100 p-5" />
                  
                  <li>To add item to category list, click on <b>“assign Items”</b>.
                  </li>
                  <img src={this.imgSrc('Procedure/p6.png')} className="w-100 p-5" />
                  
                  <li>Search, select and set quantity needed of items. Click on tick box to select items.
                  </li>
                  <img src={this.imgSrc('Procedure/p7.png')} className="w-100 p-5" />
                </ol>  
                  <ol type="A" className="ml-5 pl-5">
                    <li><b>Search Bar</b>
                        <br/>
                        <p className="mb8">Search for the item.</p>
                    </li>

                    <li><b>Filter by category</b>
                        <br/>
                        <p className="mb8">Select to view items by its category.</p>
                    </li>

                    <li><b>Select checkbox</b>
                        <br/>
                        <p className="mb8">Click on the checkbox to select the item.</p>
                    </li>

                    <li><b>Quantity</b>
                        <br/>
                        <p className="mb8">
                            Enter the quantity needed for the item.</p>
                    </li>

                    <li><b>Continue</b>
                        <br/>
                        <p className="mb8">
                            Click to continue to review items.</p>
                    </li>
                  </ol>
              </div>
            </div>

            <hr />
            
            <div className="row" id="instruments">
              <div className="col-md-12">
                <h2 className="bold mb14"> Instruments </h2>
                <p className="mb8">Allows you to view, assign new and edit instruments list for the procedure. Flow is similar to consumables.</p>
                <img src={this.imgSrc('Procedure/p9.png')} className="w-100 p-5" />
                <ol type="A">
                  <li><b>Edit</b> <br/>
                    <p className="mb8">Click to edit instrument list</p>
                  </li>
                  <li><b>Category list</b> <br/>
                    <p className="mb8">Shows item details under its category (for this instance PRN)</p>
                  </li>
                  <li><b>Category bar</b> <br/>
                    <p className="mb8">Click to unhide category list</p>
                  </li>
                  <li><b>Instrument Trays</b> <br/>
                    <p className="mb8">List of instrument trays to be used for the procedure. Click on the <b>“eye”</b> icon to view all the instruments inside the tray.</p>
                  </li>
                </ol>  
              </div>
            </div>

            <hr />
            
            <div className="row" id="instruments-edit">
              <div className="col-md-12">
                <h3 className="bold mb14"> <i>Instruments</i> </h3>
                <p className="mb8">Click on edit to enter edit mode.</p>
                <img src={this.imgSrc('Procedure/p10.png')} className="w-100 p-5" />
                <ol type="A">
                  <li><b>Update</b>
                      <br/>
                      <p className="mb8">
                          Update and save changes to consumable list
                      </p>
                  </li>

                  <li><b>Save Draft</b>
                      <br/>
                      <p className="mb8">
                          Save instruments list as draft. You can come back to your previous work through <b>“lounge”</b> page
                      </p>
                  </li>

                  <li><b>Cancel</b>
                      <br/>
                      <p className="mb8">
                          Cancel all changes and go back to view mode
                      </p>
                  </li>

                  <li><b>Standard Categories</b>
                      <br/>
                      <p className="mb8">
                          These are the standard categories in which CORH offers. You cannot delete or edit our standard categories
                      </p>
                  </li>

                  <li><b>Add new category</b>
                      <br/>
                      <p className="mb8">
                          Allows you to create your own categories
                      </p>
                  </li>

                  <li><b>Category List</b>
                      <br/>
                      <p className="mb8">
                          Click to expand category lists which allow you to preview, assign new items and remove items from the category
                      </p>
                  </li>

                  <li><b>Trays List</b>
                      <br/>
                      <p className="mb8">
                          Click to expand to view all trays needed for this procedure. Click on <b>“assign tray”</b> to assign trays to be used for the procedure
                      </p>
                  </li>
                
                </ol>  
              </div>
            </div>

            <hr />
            
            <div className="row" id="instruments-assign">
              <div className="col-md-12">
                <h3 className="bold mb14"> <i>Assigning/removing instruments into category lists</i> </h3>
                <p className="mb8">To add (assign) items to category list </p>
                
                <ol type="1">
                  <li>Click on <b>“edit”</b>.</li>
                  <li>Click on desired category to add item by clicking on the category bar.</li>
                  <img src={this.imgSrc('Procedure/p4.png')} className="w-100 p-5" />
                  <li>To remove items from category list, click on <b>“Remove”</b>.</li>
                  <img src={this.imgSrc('Procedure/p5.png')} className="w-100 p-5" />
                  <li>To add item to category list, click on <b>“assign Items”</b>.</li>
                  <img src={this.imgSrc('Procedure/p6.png')} className="w-100 p-5" />
                  <li>Search, select and set quantity needed of items. Click on tick box to select items.</li>
                  <img src={this.imgSrc('Procedure/p7.png')} className="w-100 p-5" />
                </ol>  
                  <ol type="A" className="pl-5 ml-5">
                    <li><b>Search Bar</b> <br/>
                    <p className="mb8">	Search for the item. </p> </li>

                    <li><b>Filter by category</b> <br/>
                    <p className="mb8">	Select to view items by its category. </p> </li>

                    <li><b>Select checkbox</b> <br/>
                    <p className="mb8">	Click on the checkbox to select the item. </p> </li>

                    <li><b>Quantity</b> <br/>
                    <p className="mb8">	Enter the quantity needed for the item. </p> </li>

                    <li><b>Continue</b> <br/>
                    <p className="mb8">	Click to continue to review items. </p> </li> 

                  </ol>

                    <p className="mb8">After selecting desired items click on continue to review your selection. </p>

                    <p className="mb8">Review your selection. You are able to readjust quantity of the item and or deselect the item by clicking on the check box.</p>
                    <img src={this.imgSrc('Procedure/p8.png')} className="w-100 p-5" />

                    <p className="mb8">Click on the “assign items” do save the items into the category list. </p>

                    <p className="mb8">Click on “update” to save and update changes </p>
                
              </div>
            </div>

            <hr />
            
            <div className="row" id="instruments-assign-trays">
              <div className="col-md-12">
                <h3 className="bold mb14"> <i>Assigning/removing instrument trays</i> </h3>
                
                <ol type="1">
                  <li>Click on <b>“edit”</b>.</li>
                  <li>Click on the <b>"Trays"</b> bar.</li>
                  <img src={this.imgSrc('Procedure/p11.png')} className="w-100 p-5" />
                  <li>Click on <b>“assign tray”</b> and the assign tray menu will pop up <br/>
                    <b>Note:</b> Trays that will appear here will be instrument trays you have created yourself in our         instrument tray creator under <a href="#">Medical Inventory>Instruments>Instrument Tray</a>
                    <img src={this.imgSrc('Procedure/P12.png')} className="w-100 p-5" />
                    <ol type="A" className="pl-5 ml-5">
                      <li> <b>Search </b> <br />
                        <p className="mb8">Search for the instrument tray </p>
                      </li>
                      <li> <b>View Assigned Trays </b> <br />
                        <p className="mb8">Click to view instruments trays you have assigned to for this procedure </p>
                      </li>
                      <li> <b>Select Tray </b> <br />
                        <p className="mb8">Click on the check box to select the instrument tray(s) to be used for this procedure </p>
                      </li>
                      <li> <b>Preview instruments  </b> <br />
                        <p className="mb8">When a tray is selected the instruments in that tray is displayed here </p>
                      </li> 
                    </ol>
                  </li>
                    
                  <li>Click on desired tray then click on the “<b>assign tray</b>” button</li>
                  <li>Click on <b>“update”</b> to save and update changes.</li>
                </ol>   
                
              </div>
            </div>

            <hr />
            
            <div className="row" id="positioning">
              <div className="col-md-12">
                <h2 className="bold mb14">Positioning </h2>
                <p className="mb8">The positioning page allows you to set which position the procedure requires the patient to be positioned in. The positioning page also allows you the use of standard elements, Text, Text & Media and Media gallery to be used to create notes. (Anchor link to standard Elements) </p>                
              </div>
            </div>

            <hr />
            
            <div className="row" id="positioning-injury">
              <div className="col-md-12">
                <h3 className="bold mb14"><i>Positioning Injury prevention information</i></h3>
                <p className="mb8">Clicking on “View injury prevention information” allows you to view information of the basic prevention measures to keep in mind for the particular positioning chosen. </p>         
                <img src={this.imgSrc('Procedure/p15.png')} className="w-100 p-5" /> 
              </div>
            </div>

            <hr />
            
            <div className="row" id="positioning-select">
              <div className="col-md-12">
                <h3 className="bold mb14"><i>Positioning Injury prevention information</i></h3> 
                <ol type="1">
                  <li>Click on “<b>edit</b>” to enter edit mode</li>
                  <li>Select position required for the procedure <br />
                    <p className="mb8"><b>Note</b>: Warning! Any information saved in Positioning equipment stage will be overridden and changed when positioning will be changed.  </p>
                    <img src={this.imgSrc('Procedure/p13.png')} className="w-100 p-5" /> 
                  </li>
                  <li>A final warning will pop up to remind you of the changes it will do to positioning equipment.</li>
                    <img src={this.imgSrc('Procedure/p14.png')} className="w-100 p-5" /> 
                  <li>Click on “<b>yes</b>” if you want to change position</li>
                  <li>Click “<b>Update</b>” to save and update changes</li>
                </ol>
              </div>
            </div>

            <hr />
            
            <div className="row" id="positioning-create">
              <div className="col-md-12">
                <h3 className="bold mb14"><i>Creating notes/editing notes</i></h3>
                <p className="mb8">You are able to create your own notes by using our standard elements</p>         
                <a href="#">Click here to view how to use our standard element </a>
              </div>
            </div>

            <hr className="mb-5" />
            
            <div className="row" id="positioning-equipment">
              <div className="col-md-12">
                <h2 className="bold mb14">Positioning Equipment </h2>
                <p className="mb8">The positioning Equipment page allows you to assign, edit and remove items that will be used to aid in positioning the patient. Here you are able to attach notes and media to each equipment to record any special instructions on how to apply the item during positioning of the patient.  </p>  
              </div>
            </div>

            <hr />
            
            <div className="row" id="positioning-equipment-body">
              <div className="col-md-12">
                <h3 className="bold mb14"><i>Body area navigation </i></h3>
                <img src={this.imgSrc('Procedure/p16.png')} className="w-100 p-5" /> 
                <p className="mb8">The body area navigation mannequin allows you to quickly scroll to the area you have clicked. In this case if you click on the head the page will scroll down to the head category and open the list of items needed for the head during positioning. The number noted on each area indicates the number of equipment needed for that body section. </p>  
              </div>
            </div>

            <hr />
            
            <div className="row" id="positioning-equipment-assign">
              <div className="col-md-12">
                <h3 className="bold mb14"><i>Assigning and editing positioning equipment list </i></h3> 
                <ol type="1">
                  <li>Click on edit mode</li>
                  <li>Click on the body area section in which the item will be used</li>
                  <img src={this.imgSrc('Procedure/p17.png')} className="w-100 p-5" />
                  <li>Click on <b>“assign item”</b> <br />
                    <p className="mb8">A pop up window will open </p>
                    <img src={this.imgSrc('Procedure/P18.png')} className="w-100 p-5" />
                    <ol className="ml-5">
                      <li><b>Item navigation</b><br />
                        <p className="mb8">Tab bar navigations allows you to search for items from consumables, instruments and equipment</p>
                      </li>

                      <li><b>Search</b><br />
                        <p className="mb8">Search for your item</p>
                      </li>

                      <li><b>Filter by category</b><br />
                        <p className="mb8">Select to view items by its category</p>
                      </li>

                      <li><b>Select Item</b><br />
                        <p className="mb8">Click on checkbox to select desired item to be used for positioning</p>
                      </li>

                    </ol>
                  </li>
                  <li>After selecting items needed click on <b>“review item”</b></li>
                  <li>Review your selection. You are able to readjust quantity of the item and or deselect the item by clicking on the check box.</li>
                  <img src={this.imgSrc('Procedure/p8.png')} className="w-100 p-5" />
                  <li>Click on the “<b>assign items</b>” to save the items into the body area section.</li>
                  <li>Click on “<b>update</b>” to save and update changes</li>
                </ol> 
              </div>
            </div>

            <hr className="mb-5" />

            <div className="row" id="room-setup">
              <div className="col-md-12">
                <h2 className="bold mb14">Room Setup & Equipment</h2>
                <p className="mb8">Room setup page allows you to take an image of the setup of the room to keep as a reference as to how a certain room is setup for a particular surgeon’s procedure. You can also assign equipment needed for the procedure as well as take notes using our standard elements.</p>
                <img src={this.imgSrc('Procedure/p19.png')} className="w-100 p-5" />
              </div>
            </div>

            <hr />

            <div className="row" id="room-setup-add">
              <div className="col-md-12">
                <h3 className="bold mb14"><i>Adding room setup image</i></h3>
                <ol type="1">
                  <li>Adding room setup image</li>
                  <li>Input room setup floor plan title</li>
                  <img src={this.imgSrc('Procedure/p20.png')} className="w-100 p-5" />
                  <li>Click on “<b>Upload a floor plan image</b>”</li>
                  <li>Choose image for the floor plan</li>
                  <li>Click “<b>update</b>” to save and update changes</li>

                </ol>
              </div>
            </div>

            <hr />

            <div className="row" id="room-setup-assign">
              <div className="col-md-12">
                <h3 className="bold mb14"><i>Assigning Room Equipment</i></h3>
                <ol type="1">
                  <li>Click on “<b>edit room setup & equipment</b>”</li>
                  <li>Click on “<b>Assign items</b>”</li>
                  <img src={this.imgSrc('Procedure/p21.png')} className="w-100 p-5" />
                  <li>Search desired equipment</li>
                  <li>Enter quantity needed for each item</li>
                  <li>Click “confirm assigned items”</li>
                  <li>Click “Update” to save and update changes</li>
                </ol>
              </div>
            </div>

            <hr />

            <div className="row" id="room-setup-notes">
              <div className="col-md-12">
                <h3 className="bold mb14"><i>Creating notes/Editing notes</i></h3> 
                <p className="mb8">You are able to create your own notes by using our standard elements.</p>
                <p className="mb8"><a href="#">Click here to view how to use our standard element </a></p>
                <img src={this.imgSrc('Procedure/p22.png')} className="w-100 p-5" />
              </div>
            </div>

            <hr className="mb-5" />

            <div className="row" id="medical-inventory">
              <div className="col-md-12">
                <h2 className="bold mb14">Medical Inventory</h2>
                <p className="mb8">CORH medical inventory show cases an ever-growing database of items such as consumables, instruments and equipment typically used in the OR. Some items will contain instructional media and a brief description of what that item will be typically used.</p>
                <p className="mb8">You can access medical inventory click on “<b>Medical Inventory</b>”</p>
                <img src={this.imgSrc('MedicalInventory/mi1.png')} className="w-100 p-5" />
                <p className="mb8">Choose as to which database you would like to view</p>
                <img src={this.imgSrc('MedicalInventory/mi2.png')} className="w-100 p-5" />
                <p className="mb8">Choose as to which database you would like to view</p>
                <ul> 
                  <li>Supplies (OR consumables including implants)</li>
                  <li>Instruments</li>
                  <li>Equipment </li> 
                </ul>
              </div>
            </div>

            <hr />

            <div className="row" id="medical-inventory-supplies">
              <div className="col-md-12">
                <h3 className="bold mb14"><i>Supplies, Instruments & Equipment</i></h3>
                <img src={this.imgSrc('MedicalInventory/m3.png')} className="w-100 p-5" /> 
                <ol type="A">
                  <li> <b>Search Bar </b> <br />
                    <p className="mb8">Search for items </p>
                  </li>

                  <li> <b>Filter by </b> <br />
                    <p className="mb8">Filter items by its categories </p>
                  </li>

                  <li> <b>Edit Database </b> <br />
                    <p className="mb8">Click to edit existing items and or register a new item for the database</p>
                    <p className="mb8"><b>Note</b>: Any items edited from CORH library and new items registered will be saved under your account and for your account only. </p>
                  </li>


                  <li> <b>Show in Card form </b> <br />
                    <p className="mb8">Displays items into card form</p>
                    <img src={this.imgSrc('MedicalInventory/m4.png')} className="w-100 p-5" />
                  </li>  

                    <li> <b>Item details view </b> <br />
                      <p className="mb8">Click on individual item to view detailed information on it. View, if available, educational media resources.</p>
                    <img src={this.imgSrc('MedicalInventory/m5.png')} className="w-100 p-5" />
                    </li> 

                </ol>
              </div>
            </div>

            <hr />

            <div className="row" id="medical-inventory-register">
              <div className="col-md-12">
                <h3 className="bold mb14"><i>Registering a new Item</i></h3>
                <ol type="1">
                  <li>Click on “<b>Edit database</b>”</li>
                  <li>Click on “<b>Register new item</b>”</li>
                  <li>Input all relevant data in the register a supply page</li>
                  <img src={this.imgSrc('MedicalInventory/M6.png')} className="w-100 p-5" />
                  <ol type="A" className="pl-5 ml-5">
                    <li> <b>Item profile image preview </b> <br />
                      <p className="mb8">Displays item profile image </p>
                    </li>
                    <li> <b>Upload Photo </b> <br />
                      <p className="mb8">Click to upload image of item</p> 
                    </li>
                    <li> <b>Details input </b> <br />
                      <p className="mb8">Input details of item </p> 
                      <p className="mb8"><b>Note:</b> You will need to input Name and assign a category item to proceed</p>
                    </li>
                    <li> <b>Tags </b> <br />
                      <p className="mb8">Add tags to make the item uniquely your own. Add your own nicknames for that instrument and search items by nicknames.</p>
                    </li>
                    <li> <b>Add Media </b> <br />
                      <p className="mb8">Add any media you would like to attach to the item</p> 
                    </li> 
                  </ol>
                  <li>After inputting all information click on “Add media”</li>
                  <li>If you do not wish to add any media click on “Skip step” to save item and skip adding media.</li>
                    <img src={this.imgSrc('MedicalInventory/m8.png')} className="w-100 p-5" />
                  <li>Click on “Add more image/video+”</li>
                    <img src={this.imgSrc('MedicalInventory/m7.png')} className="w-100 p-5" />
                  <li>Add image by clicking on “Add image or video+” </li> 
                    <img src={this.imgSrc('MedicalInventory/m9.png')} className="w-100 p-5" />
                  <li>Add title of the image Note: Title must be added to be able to save item into the database.</li>
                  <li>Add any description of the media if you want</li>
                    <img src={this.imgSrc('MedicalInventory/m10.png')} className="w-100 p-5" />
                  <li>If you want to add more media click on “Add more image/video +” </li>
                    <img src={this.imgSrc('MedicalInventory/m11.png')} className="w-100 p-5" />
                  <li>Click on “Save item” to save item</li>
                </ol>
              </div>
            </div>
            
            <hr />

            <div className="row" id="medical-inventory-edit">
              <div className="col-md-12">
                <h3 className="bold mb14"><i>Editing Items</i></h3>
                <p className="mb8">To edit items</p>
                <ol type="A">
                  <li>Search for the item you want to edit </li>
                  <li>Click on “Edit database” </li>
                  <li>Click on “Edit content” </li>
                  <img src={this.imgSrc('MedicalInventory/m12.png')} className="w-100 p-5" />

                </ol>
              </div>
            </div>

            <hr />

            <div className="row" id="medical-inventory-add-size">
              <div className="col-md-12">
                <h3 className="bold mb14"><i>Adding sizes</i></h3>
                <p className="mb8">Adding sizes allow you to create multiple duplicates of an item that may have other sizes and with other codes. This is so you won’t need to create multiples of the same item with different sizes.</p>
                <p className="mb8">To add more </p>
                <ol type="1">
                  <li>Search for item to add more sizes</li>
                  
                  <li>Click on “Edit database”</li>
                  
                  <li>Click on “Add sizes”</li>
                  
                  <img src={this.imgSrc('MedicalInventory/m13.png')} className="w-100 p-5" />
                  <li>An input box will appear</li>
                  <img src={this.imgSrc('MedicalInventory/m14.png')} className="w-100 p-5" />
                  <ol className="pl-5 ml-5" type="A">
                    <li> <b>Original Item information </b><br />
                      <p className="mb8">Display information of the original item to be added more size</p>
                    </li>
                    <li> <b>Catalogue </b><br />
                      <p className="mb8">Input catalogue number. You are able to proceed without inputting catalogue number</p>
                    </li>
                    <li> <b>Size </b><br />
                      <p className="mb8">Input size </p>
                    </li>
                    <li> <b>Add another size+ </b><br />
                      <p className="mb8">Click to add another duplicate of the item</p>
                    </li> 
                  </ol>
                  <li>Click on “Add” button to create the newly made multiples of the item</li>
                  <li>Click on “Update” to update the item database</li>
                </ol>
              </div>
            </div>

            <hr />

            <div className="row" id="medical-inventory-create-set">
              <div className="col-md-12">
                <h3 className="bold mb14"><i>Creating/Editing your own instrument sets</i></h3>
                <p className="mb8">You are able to create your own instrument sets. These sets will appear when you choose instruments to be used in our procedure stage <b>Instrument</b>.</p>
                <p className="mb8">Click on “<b>Medical inventory</b>” then click on Instruments “<b>View database</b>” </p>
                <p className="mb8">On the top bar click on “<b>instrument trays</b>” </p>
                <img src={this.imgSrc('MedicalInventory/m15.png')} className="w-100 p-5" />
                <p className="mb8">Instrument tray page will be displayed</p>
                <img src={this.imgSrc('MedicalInventory/m16.png')} className="w-100 p-5" />
                <ol type="A">
                  <li> <b>Search </b> <br />
                    <p className="mb8">Search for your instrument tray</p>
                  </li>
                  <li> <b>Filter view</b> <br />
                    <p className="mb8">Filter instrument trays by specialty</p>
                  </li>
                  <li> <b>Register new Tray</b> <br />
                    <p className="mb8">Click to create a new tray</p>
                  </li>
                  <li> <b>Instrument Preview</b> <br />
                    <p className="mb8">Click on the instrument tray and the preview of its contents will appear on the box on the right</p>
                  </li>
                </ol>
              </div>
            </div>

            <hr />

            <div className="row" id="medical-inventory-create-instrument">
              <div className="col-md-12">
                <h3 className="bold mb14"><i>Creating a new instrument</i></h3> 
                <ol type="1">
                  <li>Click on “medical inventory” then click on “Instrument tray”</li>
                  <li>Click on “Register new tray”</li>
                  <li>You will be taken to the Register a new Tray page</li>
                  <img src={this.imgSrc('MedicalInventory/m17.png')} className="w-100 p-5" />
                  <ol className="pl-5 ml-5">
                    <li> <b>Enter Tray name </b> <br />
                      <p className="mb8">Enter the name of the newly created instrument tray </p>
                      <p className="mb8 bold">Note: Name needed to save instrument tray </p>
                    </li>
                    <li> <b>Assign Specialty </b> <br />
                      <p className="mb8">Assign the newly created instrument tray to a specialty it will be used </p>
                      <p className="mb8 bold">Note: Assigning tray to a specialty is required to save instrument tray </p>
                    </li>
                    <li> <b>Assign item  </b> <br />
                      <p className="mb8">Click to assign items to instrument tray </p>
                    </li>
                    <li> <b>Remove </b> <br />
                      <p className="mb8">Remove instrument from instrument tray </p>
                    </li>
                  </ol>
                  <li>Enter name of tray.  <b>Note: Name needed to save instrument tray</b></li>
                  <li>Assign Instrument tray to a specialty. <b>Note: Assigning tray to a specialty is required to save instrument tray</b></li>
                  <li>Assign tray </li>
                  <li>Search for desired instrument</li>
                  <li>Click on check box to select instrument to be added to tray</li>
                  <li>Set Quantity of the instrument </li>
                  <li>Click on “Continue”</li>
                  <li>Review instruments added</li>
                  <li>Click on “Update”</li>
                </ol>
              </div>
            </div>

            <hr />

            <div className="row" id="medical-inventory-edit-tray">
              <div className="col-md-12">
                <h3 className="bold mb14"><i>Editing existing instrument trays</i></h3>
                <p className="mb8">You are able to create your own instrument sets. These sets will appear when you choose instruments to be used in our procedure stage <b>Instrument</b>.</p>
                <p className="mb8">Click on “<b>Medical inventory</b>” then click on Instruments “<b>View database</b>” </p>
                <p className="mb8">On the top bar click on “<b>instrument trays</b>” </p>
                <img src={this.imgSrc('MedicalInventory/m15.png')} className="w-100 p-5" />
                <p className="mb8">Instrument tray page will be displayed</p>
                <img src={this.imgSrc('MedicalInventory/m16.png')} className="w-100 p-5" />
                <ol type="A">
                  <li> <b>Search </b> <br />
                    <p className="mb8">Search for your instrument tray</p>
                  </li>
                  <li> <b>Filter view</b> <br />
                    <p className="mb8">Filter instrument trays by specialty</p>
                  </li>
                  <li> <b>Register new Tray</b> <br />
                    <p className="mb8">Click to create a new tray</p>
                  </li>
                  <li> <b>Instrument Preview</b> <br />
                    <p className="mb8">Click on the instrument tray and the preview of its contents will appear on the box on the right</p>
                  </li>
                </ol>
              </div>
            </div>

            <hr />

            <div className="row" id="medical-inventory-edit-tray">
              <div className="col-md-12">
                <h3 className="bold mb14"><i>Editing existing instrument trays</i></h3>
                  
                <ol type="1">
                  <li>Click and select instrument tray to be edited</li>
                  <img src={this.imgSrc('MedicalInventory/m18.png')} className="w-100 p-5" />
                  <li>Click on “Edit database”</li>
                  
                  <img src={this.imgSrc('MedicalInventory/m19.png')} className="w-100 p-5" />
                  <li>Click on “Edit” to edit tray or click on “Remove” to delete instrument tray</li>
                  
                  <img src={this.imgSrc('MedicalInventory/m20.png')} className="w-100 p-5" />
                  <li>Clicking edit will take you to Edit tray page</li>
                  <li>Click on “<b>Assign items</b>”</li>
                  <li>Search for desired instrument</li>
                  <li>Click on check box to select instrument to be added to tray. </li>
                  <li>Set quantity of the instrument </li>
                  <li>Click on “<b>Continue</b>”</li>
                  <li>Review instruments added</li>
                  <li>Click on “<b>Update</b>”</li>

                </ol>
              </div>
            </div>

            <hr />

          </div>

        </div>
      </div>
    )
  }
}

export default manual
