import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getItemsPerCategory } from '../../actions/ItemAction'
import {
  savePositioningEquipment,
  getPositioningDetailsProcedure,
} from '../../actions/PostioningActions'
import ReactDOMServer from 'react-dom/server'
import { Medias } from '../../components/positioning'
import AssignItemsPositioning from '../../components/modals/AssignItemsPositioning'
import { dataURItoBlob, isBase64 } from '../../utilities'
import { getPosition } from '../../actions/PostioningActions'
import { positioningUpdate } from '../../actions'
import $ from 'jquery'
import {
  CardSection,
  PositionAreas,
} from '../../components/positioning_equipments'
import axios from 'axios'

import ViewItemModal from '../../components/modals/ViewItemModal'
import InlineNotification from '../commons/notifier/InlineNotification'
import EditMenu from '../commons/misc/EditMenu'
import { Button } from '../commons/forms'
import DataCard from '../commons/cards/DataCard'
import { Table, TRow, THead, TData } from '../commons/table'
import ViewItemCard from '../commons/cards/ViewItemCard'
import { Modal } from '../commons/modals'
import { showLoader, setLoaderProg, hideLoader } from '../loader/loading_screen'
import Notifier from '../commons/notifier/Notifier'
import { procedureAction } from '../../utilities/notifier'
import { TextEditor } from '../commons/media_elements/TextEditor'
import Collapsible from '../commons/panel/Collapsible'
import _ from 'lodash'
import { Event } from '../../utilities/analytics'
export class PositioningEquipmentEditLayout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected_items: [],
      selected_items_filtered: [],
      change: false,
      qty: [],
      position: [],
      positions: [],
      errors: [],
      errorMedia: [],
      medias: [],
      preview: false,
      fresh: true,
      item: {},
      isReverse: false,
      selected_index: '',
      index: null,
      selectedItem: null,
      draft_success: false,
      editor_key: this.makeid(10),
      progressData: [],
      selected_body_area: null,
      selected_pos: [],
    }
  }

  makeid(length) {
    var result = ''
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps != this.props) {
      let pos = []
      let qty = []
      let medias = []
      nextProps.stage_details.items.map(item => {
        pos.push(item.body_area)
        qty.push(item.qty)
        medias.push({ data: item.position_media ? item.position_media : [] })
      })
      if (nextProps.stage_details.items != this.props.stage_details.items) {
        this.setState({
          selected_items: nextProps.stage_details.items,
          positions: pos,
          qty: qty,
          medias,
          fresh: false,
        })
      }
    }
  }

  mediaElements = index => {
    const { medias } = this.state
    const { media } = this.props
    media.length = 0

    if (medias[index].data.length) {
      medias[index].data.map(data => {
        media.push(data)
      })
    } else {
      media.push({
        show: true,
        thumbnail: null,
        source: null,
        caption: null,
        title: null,
        id: 0,
      })
    }
    const value = _.cloneDeep(media)

    this.props.positioningUpdate({ prop: 'media', value })
    const val = ''
    this.props.positioningUpdate({ prop: 'media_title', val })

    this.setState(
      {
        selected_index: index,
      },
      () => {
        $('#addMediaModal').modal('show')
      }
    )
  }

  componentDidMount() {
    this.setState(
      {
        selected_items: this.props.stage_details.items,
      },
      () => {
        showLoader()
        setTimeout(() => {
          hideLoader()
        }, 4000)
      }
    )

    let pos = []
    let qty = []
    let medias = []
    this.props.stage_details.items.map(item => {
      pos.push(item.body_area)
      qty.push(item.qty)
      medias.push({ data: item.position_media ? item.position_media : [] })
    })

    this.setState({
      selected_items: this.props.stage_details.items,
      positions: pos,
      qty: qty,
      medias,
      fresh: false,
    })
  }

  getItems2 = nextProps => {
    let pos = []
    let qty = []
    let medias = []
    nextProps.position_master.map(item => {
      pos.push(item.body_area)
      qty.push(item.qty)
      medias.push({ data: item.position_media ? item.position_media : [] })
    })
    this.setState({
      positions: pos,
      qty: qty,
      medias,
      fresh: false,
    })
  }

  getItems = () => {
    let pos = []
    let qty = []
    let medias = []
    this.props.position_master.map(item => {
      pos.push(item.body_area)
      qty.push(item.qty)
      medias.push({ data: item.position_media ? item.position_media : [] })
    })
    this.setState({
      selected_items: this.props.position_master,
      positions: pos,
      qty: qty,
      medias,
      fresh: false,
    })
  }

  validateMedia = () => {
    let { medias, selected_index } = this.state
    const { media } = this.props

    console.log(media)
    let config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    const data = new FormData()
    media.map((content, i) => {
      data.append(
        `elements[0][media-text][]`,
        content.caption ? content.caption : ''
      )
      data.append(
        `elements[0][media-title][]`,
        content.title ? content.title : ''
      )
      data.append(`elements[0][media-type][]`, content.type)
      data.append(`elements[0][media-id][]`, 0)
      data.append(`elements[0][media-updated][]`, 1)
      data.append(
        `elements[0][media-value][${i}]`,
        content.source ? content.source : ''
      )
      data.append(
        `elements[0][media-original][${i}]`,
        content.originSource ? content.originSource : ''
      )
    })
    axios
      .post(`api/client/validate-media`, data, config)
      .then(result => {
        medias[selected_index].data = media
        console.log('medias[selected_index].data-2', this.state.medias)
        this.setState(
          {
            medias: _.cloneDeep(medias),
          },
          () => {
            console.log('medias[selected_index].data-3', this.state.medias)
            $('#addMediaModal').modal('hide')
          }
        )
        const value = _.cloneDeep(media)

        // media.length = 0
        this.props.positioningUpdate({ prop: 'media', value })
      })
      .catch(errors => {
        this.setState(
          {
            progress: 0,
            errorMedia: errors.response.data.errors,
          },
          () => {
            let targetOffset = $('.is-invalid').offset()
              ? $('.is-invalid').offset().top - 20
              : null
            if (targetOffset) {
              $('html, body').animate({ scrollTop: targetOffset }, 100)
            }
          }
        )
      })
  }

  savePositioningEquipment = (
    id,
    items,
    position,
    qty,
    draft,
    redirect = true
  ) => {
    if (draft == 0) {
      Event('Procedure', 'Update', 'Positoning Equipments')
    }
    const { medias } = this.state
    console.log('medias[', medias)
    let data = new FormData()
    data.append('positioning', id)
    data.append('draft', draft)
    items.map((item, index) => {
      data.append(`item_ids[${index}]`, item.id)
      data.append(`areas[${index}]`, position[index])
      data.append(`qty[${index}]`, qty[index])
      data.append(
        `notes[${index}]`,
        item.position_notes ? item.position_notes : ''
      )
      medias[index].data.map((media, i) => {
        if (media.show) {
          data.append(`media_id[${index}][${i}]`, media.id)
          data.append(
            `medias[${index}][${i}]`,
            media.source ? media.source : ''
          )
          data.append(`origin_source[${index}][${i}]`, media.originSource)
          data.append(`updated[${index}][${i}]`, 'no')
          data.append(`caption[${index}][${i}]`, media.caption)
          data.append(`title[${index}][${i}]`, media.title)
          data.append(`type[${index}][${i}]`, media.type)
        }
      })
    })

    let config = {
      headers: {
        'content-type': 'multipart/form-data',
      },

      onUploadProgress: progressEvent => {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        showLoader(percentCompleted >= 95 ? 95 : percentCompleted)
        setLoaderProg(percentCompleted >= 95 ? 95 : percentCompleted)
      },
    }
    let url = '/api/client/stage-positioning-equipments'

    axios
      .post(url, data, config)
      .then(res => {
        if (redirect) {
          this.props.setNewState({
            edit: false,
            draft_success: false,
          })
          this.props.getProcedureDetail(0)
        } else {
          this.props.getProcedureDetail(1, 'main', false, true)
          this.setState({
            draft_success: true,
          })
          this.refNotifier2.showThis()
        }
        setLoaderProg(99)
        setTimeout(() => {
          hideLoader()
        }, 800)

        // this.props.getPositioningDetailsProcedure(this.props.position_id, 1)
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
        // this.setState({
        //   errors: Object.values(error.response.data.errors),
        // })
        setLoaderProg(99)
        setTimeout(() => {
          hideLoader()
        }, 800)
      })
  }

  setItems = (items, positions, qty) => {
    console.log('SET ITEMS', items)
    const medias = []
    // items.map((q, index) => {
    //   items[index].qty = q.qty
    //   items[index].body_area = q.body_area
    //   items[index].position_media = items[index].position_media ? items[index].position_media.splice(0) : []
    //   medias.push({ data: items[index].position_media ? items[index].position_media.splice(0) :[]})
    // })
    let pos = []
    items.map((item, index) => {
      console.log('item', item.body_area, item.id)
      pos.push(item.body_area)
      item.position_media = item.position_media
        ? item.position_media.splice(0)
        : []
      medias.push({
        data: item.position_media ? item.position_media.splice(0) : [],
      })
      item.qty = qty[index]
    })
    this.setState(
      {
        selected_items: _.cloneDeep(items),
        positions: pos,
        qty: qty,
        fresh: false,
        medias,
      },
      () => {
        // this.savePositioningEquipment(this.props.position_id, this.state.selected_items, this.state.positions, this.state.qty, 1, false)
        // this.props.getPositioningDeta_idils(this.props.position_id, 1)
      }
    )
  }

  sortBy(key) {
    let arrayCopy = [...this.state.selected_items]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }
    this.setState({
      selected_items: arrayCopy,
      isReverse: !this.state.isReverse,
    })
  }

  compareBy(key) {
    if (key == 'category') {
      return function(a, b) {
        if (a.category.name < b.category.name) return -1
        if (a.category.name > b.category.name) return 1
        return 0
      }
    }
    return function(a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }

  titleChange = event => {
    let item = this.state.item

    item.position_notes_title = event.target.value

    this.setState({
      item: item,
    })
  }

  textContentChange = (event, index) => {
    let item = this.state.item

    item.position_notes = event.editor.getData()

    this.setState({
      item: item,
    })
  }

  saveMedia = () => {
    let { medias, selected_index } = this.state
    const { media } = this.props

    this.validateMedia()
    return
    console.log('medias[selected_index].data-1', media)
    medias[selected_index].data = media
    console.log('medias[selected_index].data-2', this.state.medias)
    this.setState(
      {
        medias: _.cloneDeep(medias),
      },
      () => {
        console.log('medias[selected_index].data-3', this.state.medias)
        $('#addMediaModal').modal('hide')
      }
    )
    const value = _.cloneDeep(media)

    // media.length = 0
    this.props.positioningUpdate({ prop: 'media', value })
  }

  saveNotes = index => {
    let { item, selected_items } = this.state

    selected_items[index] = item

    this.setState({
      selected_items: selected_items,
      item: {},
    })
  }

  addNotesModal = () => {
    return (
      <Modal
        idModal="addNotesModal"
        size="modal-lg"
        title={this.state.item.position_notes ? 'Update Notes' : 'Add Notes'}
        confirmText="Save"
        cancelText="Cancel"
        confirm={e => {
          $('#addNotesModal').modal('hide')
          this.saveNotes()
        }}
        cancel={e => {}}
        bodyClass="assign-items-body"
      >
        <div className="row">
          <div className="col-md-12">
            <TextEditor
              limitChar={300}
              notitle={true}
              item_list={[]}
              data={{
                title: '',
                content: this.state.item ? this.state.item.position_notes : '',
              }}
              validation={{
                errors: this.state.errorMedia,
                prefix: 'elements',
                index: 0,
                field: 'title',
              }}
              textContentChange={event => {
                this.textContentChange(event, this.state.index)
              }}
            />
          </div>
        </div>
      </Modal>
    )
  }

  addMediaModal = () => {
    const progressList = $('.pie-wrapper').length
    return (
      <Modal
        idModal="addMediaModal"
        size="modal-lg"
        title="Add Media"
        confirmText="Save"
        cancelText="Cancel"
        confirmDisabled={progressList}
        confirm={e => {
       //   $('#addMediaModal').modal('hide')
          this.saveMedia()
        }}
        cancel={e => {}}
        bodyClass="select-procedures-body"
      >
        <div className="row">
          <div className="col-md-12">
            <Medias
              validation={{
                errors: this.state.errorMedia,
                prefix: 'elements',
                index: 0,
                field: 'title',
              }}
              progressMedia={() => {
                console.log('progressMediaprogressMedia')
                this.setState({
                  progressData: [...this.state.progressData, true],
                })
              }}
              progressComplete={() => {
                this.setState({
                  progressData: [...this.state.progressData, true],
                })
              }}
            />
          </div>
        </div>
      </Modal>
    )
  }

  preview = () => {
    this.setState({ preview: true })
    this.savePositioningEquipment(
      this.props.position_id,
      this.state.selected_items,
      this.state.positions,
      this.state.qty,
      1,
      false
    )
    // this.refNotifier3.showThis()
    this.props.getPositioningDetailsProcedure(this.props.position_id, 1)
  }

  makeid(length) {
    var result = ''
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  positioningContent = () => {
    // window.initScrollToTop()
    const { stage_details, body_areas } = this.props

    console.log('stage_detailsss', stage_details)
    const arrayDetails = Object.values(stage_details.items).splice(0)
    let area_list = body_areas.map(area => {
      var detail = arrayDetails.filter(item => {
        return item.body_area == area.id
      })
      console.log('areaaa', area, detail)
      let modal_ID = this.makeid(11)
      if (!detail.length) {
        return null
      }
      return (
        <div className="w-100 mb20" id={area.code}>
          <Collapsible title={area.name} stickyHeader>
            <Table
              minWidth={650}
              noResults={detail.length > 0 ? 0 : 1}
              renderModal={() => {
                return (
                  <ViewItemCard
                    id={`${modal_ID}`}
                    onRef={ref => (this.viewItemRef = ref)}
                    item={this.state.selectedItem}
                  ></ViewItemCard>
                )
              }}
              cols={5}
              renderHeaders={() => {
                return (
                  <TRow>
                    <THead className="w150" text="Catalogue #" />
                    <THead className="w280" text="Name" />
                    <THead className="w70" text="Qty" />
                    <THead className="w250" text="Notes" />
                    <THead className="w80" text="Media" />
                  </TRow>
                )
              }}
              renderRows={() => {
                let rows = detail.map((item, index) => {
                  return (
                    <TRow>
                      <TData className="w150">{item.catalog_number}</TData>
                      <TData
                        className="w280 has-action"
                        item={item}
                        popover
                        onClick={() => {
                          this.setState({ selectedItem: item })
                          $(`#${modal_ID}`).modal('show')
                        }}
                      >
                        {item.name}
                      </TData>
                      <TData className="w70">{item.qty ? item.qty : 1}</TData>
                      <TData className="w250">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: stage_details.details[area.code][index]
                              ? stage_details.details[area.code][index].item
                                  .notes
                                ? stage_details.details[area.code][index].item
                                    .notes
                                : '-'
                              : '-',
                          }}
                        ></div>
                      </TData>
                      <TData className="w80">
                        {stage_details.details[area.code][index] ? (
                          stage_details.details[area.code][index].item.media
                            .length > 0 ? (
                            <Button
                              tertiary
                              onClick={event => {
                                // console.log('itemsssss', stage_details.details[area.code][index].item.media)
                                this.setState({
                                  key:
                                    Math.random()
                                      .toString(36)
                                      .substring(2, 15) +
                                    Math.random()
                                      .toString(36)
                                      .substring(2, 15),
                                  mediasSelected:
                                    stage_details.details[area.code][index].item
                                      .media,
                                })
                                $('#viewMediaModal').modal('show')
                              }}
                            >
                              View
                            </Button>
                          ) : (
                            '-'
                          )
                        ) : (
                          '-'
                        )}
                      </TData>
                    </TRow>
                  )
                })
                return rows
              }}
              renderLastRow={() => {
                if (detail.length) {
                  return null
                }
                return (
                  <tr>
                    <td className="w-100 text-center" colSpan={4}>
                      No item(s) added. Add new items via{' '}
                      <a
                        href="javascript:;"
                        onClick={e => {
                          e.preventDefault()
                          this.setState({
                            edit: true,
                            draft_success: false,
                          })
                          this.props.getProcedureDetail(1)
                        }}
                      >
                        Edit notes
                      </a>
                      .
                    </td>
                  </tr>
                )
              }}
            />
          </Collapsible>
        </div>
      )
    })

    // console.table('arrbody_areasbody_areasbody_areasay', arrayDetails)
    let list = this.props.body_areas.map((detail, index) => {
      console.log('detaildetail', detail)
      if (index == 0) return ''
      let modal_ID = this.makeid(11)
      return (
        <div className="w-100 mb20" key={index}>
          <DataCard title={detail.name} stickyHeader id={detail.code}>
            <Table
              minWidth={650}
              noResults={0}
              renderModal={() => {
                return (
                  <ViewItemCard
                    id={`${modal_ID}`}
                    onRef={ref => (this.viewItemRef = ref)}
                    item={this.state.selectedItem}
                  ></ViewItemCard>
                )
              }}
              cols={5}
              renderHeaders={() => {
                return (
                  <TRow>
                    <THead className="w150" text="Catalogue #" />
                    <THead className="w280" text="Name" />
                    <THead className="w70" text="Qty" />
                    <THead className="w250" text="Notes" />
                    <THead className="w80" text="Media" />
                  </TRow>
                )
              }}
              renderRows={() => {
                return null
              }}
              renderLastRow={() => {
                return null
                if (detail.length) {
                  return null
                }
                return (
                  <tr>
                    <td className="w-100 text-center" colSpan={4}>
                      No item(s) added. Add new items via{' '}
                      <a
                        href="javascript:;"
                        onClick={e => {
                          e.preventDefault()
                          this.setState({
                            edit: true,
                            draft_success: false,
                          })
                          this.props.getProcedureDetail(1)
                        }}
                      >
                        Edit notes
                      </a>
                      .
                    </td>
                  </tr>
                )
              }}
            />
          </DataCard>
        </div>
      )
    })
    return (
      <div className="container">
        {this.state.draft_success && !this.state.editor ? (
          <div className="row mt32">
            <div className="col-md-12 mx-auto d-flex justify-content-between align-items-center page-description">
              <h2 className=" bold">Positioning Equipments page</h2>
            </div>
          </div>
        ) : null}
        <div className="row">
          <div className="col-md-12">
            <Notifier
              show={true}
              onRef={ref => (this.refNotifier3 = ref)}
              type="warning"
              styleClass="mb30 mt0"
              hideThisNotif={() => {
                this.refNotifier3.hideThis()
                this.setState({ preview: false, editor: false })
              }}
            >
              <div>
                <b>Preview mode</b> - If you like what you see, you can{' '}
                <u
                  className="font-weight-bold c-pointer mx5"
                  onClick={() => {
                    this.savePositioningEquipment(
                      this.props.position_id,
                      this.state.selected_items,
                      this.state.positions,
                      this.state.qty,
                      0
                    )
                    this.props.propsNewState({
                      procedure_stage_edit: false,
                    })
                  }}
                >
                  Update position equipments
                </u>{' '}
                or make edits by{' '}
                <u
                  className="font-weight-bold c-pointer mx5"
                  onClick={() => {
                    this.setState({
                      preview: false,
                    })
                  }}
                >
                  Closing preview
                </u>
              </div>
            </Notifier>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="scroll-to-top c-pointer">
              <i className="fa fa-chevron-up fs20" />
            </div>
            <div className="row">
              <div className="col-md-12 profile-card pt-0">
                <div className="accordion" id="itemListAccordion">
                  <PositionAreas
                    details={Object.values(
                      this.props.stage_details.details
                    ).splice(0)}
                  />
                  {area_list}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { selected_items } = this.state

    const { stage_details, body_areas } = this.props

    if (this.state.fresh) this.getItems()

    let item_list = selected_items

    if (this.state.preview) {
      return <div className="w-100">{this.positioningContent()}</div>
    }

    return (
      <div className="w-100">
        <div className="row mt32">
          <div className="col-md-12 mx-auto d-flex justify-content-between align-items-center page-description">
            <h2 className="bold">
              Editing - Position [{this.props.stage_details.position.name}]
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mx-auto">
            <div className="row">
              <div className="col-md-12">
                <EditMenu>
                  <li>
                    <Button
                      primary
                      onClick={() => {
                        this.props.showSuccess()
                        const { stage_master } = this.props
                        procedureAction(stage_master.id, 0)
                        this.savePositioningEquipment(
                          this.props.position_id,
                          this.state.selected_items,
                          this.state.positions,
                          this.state.qty,
                          0
                        )

                        this.props.propsNewState({
                          procedure_stage_edit: false,
                        })
                      }}
                    >
                      Update
                    </Button>
                  </li>
                  <li>
                    <Button
                      tertiary
                      onClick={() => {
                        const { stage_master } = this.props
                        procedureAction(stage_master.id, 1)
                        this.savePositioningEquipment(
                          this.props.position_id,
                          this.state.selected_items,
                          this.state.positions,
                          this.state.qty,
                          1,
                          false
                        )
                      }}
                    >
                      Save Draft
                    </Button>
                  </li>
                  <li>
                    <Button
                      tertiary
                      onClick={() => {
                        this.preview()
                        this.setState({ preview: true })
                        this.setState({
                          draft_success: false,
                        })
                      }}
                    >
                      Preview
                    </Button>
                  </li>
                  <li className="has-line">
                    <Button
                      tertiary
                      onClick={e => {
                        e.preventDefault()
                        this.props.getProcedureDetail(0)
                        this.props.setNewState({
                          edit: false,
                          draft_success: false,
                        })
                        this.props.propsNewState({
                          procedure_stage_edit: false,
                        })
                      }}
                    >
                      Cancel
                    </Button>
                  </li>
                </EditMenu>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <Notifier
                  message="Draft has been saved!"
                  onRef={ref => (this.refNotifier2 = ref)}
                  type="success"
                  styleClass="mb20 mt10"
                  hideThisNotif={() => {
                    this.refNotifier2.hideThis()
                  }}
                />
              </div>
            </div>

            {/* {this.state.draft_success ? <InlineNotification message={'Saved to drafts!'} /> : null} */}
            <div className="row">
              <div className="col-md-12">
                {stage_details
                  ? //Object.values(stage_details.details).splice(0).map((detail, index) => {
                    body_areas.map((detail, index) => {
                      if (index == 0) {
                        return null
                      }
                      return (
                        <div className="w-100 mb20">
                          <Collapsible
                            title={detail.name}
                            stickyHeader
                            id={detail.code}
                          >
                            <div className="d-flex ml-auto pb20">
                              <Button
                                secondary
                                onClick={() => {
                                  let filtered = item_list.filter(item => {
                                    return item.body_area == detail.id
                                  })
                                  this.setState(
                                    {
                                      change: !this.state.change,
                                      selected_body_area: detail.id,
                                      selected_items_filtered: item_list.filter(
                                        item => {
                                          return item.body_area == detail.id
                                        }
                                      ),
                                      selected_items_un_filtered: item_list.filter(
                                        item => {
                                          return item.body_area != detail.id
                                        }
                                      ),
                                      qty: filtered.map(item => {
                                        return item.qty
                                      }),
                                    },
                                    () => {
                                      this.props.getItemsPerCategory(
                                        'all',
                                        this.state.selected_items,
                                        1
                                      )
                                      console.log(
                                        'assignitems',
                                        this.state.selected_items,
                                        this.state.selected_items_filtered
                                      )
                                      $('#assignItemsModalCenter').modal('show')
                                      $('#assignItemsModalCenter').on(
                                        'shown.bs.modal',
                                        function() {
                                          document
                                            .querySelectorAll('.tippy-popper')
                                            .forEach(popper => {
                                              popper._tippy.hide()
                                            })
                                        }
                                      )
                                    }
                                  )
                                }}
                              >
                                Assign items
                              </Button>
                            </div>
                            <Table
                              minWidth={650}
                              noResults={item_list.length > 0 ? 0 : 1}
                              renderModal={() => {
                                return (
                                  <ViewItemCard
                                    id={`viewitemcard-${detail.id}-${index}`}
                                    onRef={ref => (this.viewItemRef = ref)}
                                    item={this.state.selectedItem}
                                  ></ViewItemCard>
                                )
                              }}
                              cols={5}
                              renderHeaders={() => {
                                return (
                                  <TRow>
                                    <THead
                                      className="w130"
                                      text="Catalogue #"
                                    />
                                    <THead className="w200" text="Name" />
                                    <THead className="w70" text="Qty" />
                                    <THead className="w70" text="Notes" />
                                    <THead className="w70" text="Media" />
                                    <THead className="w80" text="Edits" />
                                  </TRow>
                                )
                              }}
                              renderRows={() => {
                                //   let item_list2 = item_list.filter(item => {

                                //   return item.body_area == detail.id
                                // })
                                let rows = item_list.map((item, index) => {
                                  if (item.body_area != detail.id) {
                                    return null
                                  }
                                  return (
                                    <TRow>
                                      <TData className="w130">
                                        {item.catalog_number}
                                      </TData>
                                      <TData
                                        className="w200 has-action"
                                        item={item}
                                        popover
                                        onClick={() => {
                                          this.setState({ selectedItem: item })
                                          $(
                                            `#peel-modal-${this.state.unique}`
                                          ).modal('show')
                                        }}
                                      >
                                        {item.name}
                                      </TData>
                                      <TData className="w70">
                                        {this.state.qty[index]}
                                      </TData>
                                      <TData className="w70">
                                        <Button
                                          tertiary
                                          onClick={() => {
                                            $('#addNotesModal').modal('show')
                                            this.setState({
                                              item: item,
                                              index: index,
                                              editor_key: this.makeid(10),
                                            })
                                            setTimeout(() => {
                                              this.setState({
                                                editor_key: this.makeid(10),
                                              })
                                            }, 500)
                                          }}
                                        >
                                          {item.position_notes ? 'Edit' : 'Add'}
                                        </Button>
                                      </TData>
                                      <TData className="w70">
                                        <Button
                                          tertiary
                                          onClick={event => {
                                            event.preventDefault()
                                            this.mediaElements(index)
                                          }}
                                        >
                                          {this.state.medias[index].data.length
                                            ? 'Edit'
                                            : 'Add'}
                                        </Button>
                                      </TData>
                                      <TData className="w80">
                                        <Button
                                          tertiary
                                          onClick={event => {
                                            event.preventDefault()
                                            this.setState({
                                              positions: this.state.positions.filter(
                                                (d, i) => {
                                                  return i != index
                                                }
                                              ),
                                              selected_items: this.state.selected_items.filter(
                                                (d, i) => {
                                                  return i != index
                                                }
                                              ),
                                              qty: this.state.qty.filter(
                                                (d, i) => {
                                                  return i != index
                                                }
                                              ),
                                              medias: this.state.medias.filter(
                                                (d, i) => {
                                                  return i != index
                                                }
                                              ),
                                            })
                                          }}
                                        >
                                          Remove
                                        </Button>
                                      </TData>
                                    </TRow>
                                  )
                                })
                                return rows
                              }}
                              renderLastRow={() => {
                                let itms = item_list.filter(item => {
                                  return item.body_area == detail.id
                                })
                                if (itms.length) {
                                  return null
                                }
                                return (
                                  <tr>
                                    <td
                                      className="w-100 text-center"
                                      colSpan={6}
                                    >
                                      No item(s) added. Add new items via{' '}
                                      <a
                                        href="javascript:;"
                                        onClick={() => {
                                          let filtered = item_list.filter(
                                            item => {
                                              return item.body_area == detail.id
                                            }
                                          )
                                          this.setState(
                                            {
                                              change: !this.state.change,
                                              selected_body_area: detail.id,
                                              selected_items_filtered: item_list.filter(
                                                item => {
                                                  return (
                                                    item.body_area == detail.id
                                                  )
                                                }
                                              ),
                                              selected_items_un_filtered: item_list.filter(
                                                item => {
                                                  return (
                                                    item.body_area != detail.id
                                                  )
                                                }
                                              ),
                                              qty: filtered.map(item => {
                                                return item.qty
                                              }),
                                            },
                                            () => {
                                              this.props.getItemsPerCategory(
                                                'all',
                                                this.state.selected_items,
                                                1
                                              )
                                              console.log(
                                                'assignitems',
                                                this.state.selected_items,
                                                this.state
                                                  .selected_items_filtered
                                              )
                                              $(
                                                '#assignItemsModalCenter'
                                              ).modal('show')
                                              $('#assignItemsModalCenter').on(
                                                'shown.bs.modal',
                                                function() {
                                                  document
                                                    .querySelectorAll(
                                                      '.tippy-popper'
                                                    )
                                                    .forEach(popper => {
                                                      popper._tippy.hide()
                                                    })
                                                }
                                              )
                                            }
                                          )
                                        }}
                                      >
                                        Assign items
                                      </a>
                                      .
                                    </td>
                                  </tr>
                                )
                              }}
                            />
                          </Collapsible>
                        </div>
                      )
                    })
                  : null}
              </div>
            </div>
            <div className="row d-none">
              <div className="col-md-12">
                <DataCard
                  title="Item list"
                  stickyHeader
                  headerAction={() => {
                    return (
                      <div className="d-flex ml-auto">
                        <Button
                          secondary
                          onClick={() => {
                            this.setState(
                              {
                                change: !this.state.change,
                              },
                              () => {
                                this.props.getItemsPerCategory(
                                  'all',
                                  this.state.selected_items,
                                  1
                                )
                                $('#assignItemsModalCenter').modal('show')
                                $('#assignItemsModalCenter').on(
                                  'shown.bs.modal',
                                  function() {
                                    document
                                      .querySelectorAll('.tippy-popper')
                                      .forEach(popper => {
                                        popper._tippy.hide()
                                      })
                                  }
                                )
                              }
                            )
                          }}
                        >
                          Assign items2
                        </Button>
                      </div>
                    )
                  }}
                >
                  {this.state.errors.map(error => {
                    return <p className="text-danger">{error}</p>
                  })}

                  <Table
                    minWidth={755}
                    noResults={item_list.length > 0 ? 0 : 1}
                    loading={item_list.length > 0 ? 0 : 1}
                    cols={7}
                    renderModal={() => {
                      return (
                        <ViewItemCard
                          id={`peel-modal-${this.state.unique}`}
                          onRef={ref => (this.viewItemRef = ref)}
                          item={this.state.selectedItem}
                        ></ViewItemCard>
                      )
                    }}
                    renderHeaders={() => {
                      return (
                        <TRow>
                          <THead
                            className="w130"
                            text="Catalogue #"
                            onClick={() => {
                              this.sortBy('catalog_number')
                            }}
                            sortable
                          />
                          <THead
                            className="w200"
                            text="Name"
                            onClick={() => {
                              this.sortBy('name')
                            }}
                            sortable
                          />
                          <THead
                            className="w150"
                            text="Body Category"
                            onClick={() => {
                              this.sortBy('body_area')
                            }}
                            sortable
                          />
                          <THead
                            className="w70"
                            text="Qty"
                            onClick={() => {
                              this.sortBy('qty')
                            }}
                            sortable
                          />
                          <THead className="w70" text="Notes" />
                          <THead className="w70" text="Media" />
                          <THead className="w80" text="Edits" />
                        </TRow>
                      )
                    }}
                    renderRows={() => {
                      let rows = item_list.map((item, index) => {
                        let body_area = _.find(this.props.body_areas, e => {
                          return e.id == item.body_area
                        })

                        return (
                          <TRow>
                            <TData className="w130">
                              {item.catalog_number}
                            </TData>
                            <TData
                              className="w200 has-action"
                              item={item}
                              popover
                              onClick={() => {
                                this.setState({ selectedItem: item })
                                $(`#peel-modal-${this.state.unique}`).modal(
                                  'show'
                                )
                              }}
                            >
                              {item.name}
                            </TData>
                            <TData className="w150">
                              {' '}
                              {body_area ? body_area.name : ''}{' '}
                            </TData>
                            <TData className="w70">{item.qty}</TData>
                            <TData className="w70">
                              <Button
                                tertiary
                                onClick={() => {
                                  $('#addNotesModal').modal('show')
                                  this.setState({
                                    item: item,
                                    index: index,
                                    editor_key: this.makeid(10),
                                  })
                                  setTimeout(() => {
                                    this.setState({
                                      editor_key: this.makeid(10),
                                    })
                                  }, 500)
                                }}
                              >
                                {item.position_notes ? 'Edit' : 'Add'}
                              </Button>
                            </TData>
                            <TData className="w70">
                              {/* <Button tertiary onClick={event => {
                                event.preventDefault()
                                this.mediaElements(index)
                              }}>{this.state.medias[index].data.length ? 'Edit' : 'Add'}</Button>  */}
                            </TData>
                            <TData className="w80">
                              <Button
                                tertiary
                                onClick={event => {
                                  event.preventDefault()
                                  this.setState({
                                    positions: this.state.positions.filter(
                                      (d, i) => {
                                        return i != index
                                      }
                                    ),
                                    selected_items: this.state.selected_items.filter(
                                      (d, i) => {
                                        return i != index
                                      }
                                    ),
                                    qty: this.state.qty.filter((d, i) => {
                                      return i != index
                                    }),
                                  })
                                }}
                              >
                                Remove
                              </Button>
                            </TData>
                          </TRow>
                        )
                      })
                      return rows
                    }}
                  />
                </DataCard>
              </div>
            </div>
          </div>
        </div>
        {this.addNotesModal()}
        {this.addMediaModal()}
        <AssignItemsPositioning
          assign={this.setItems}
          stage_master={this.props.stage_master}
          selected_items={this.state.selected_items}
          selected_items_filtered={this.state.selected_items_filtered}
          selected_items_un_filtered={this.state.selected_items_un_filtered}
          selected_qty={this.state.qty}
          getItemsPerCategory={this.props.getItemsPerCategory}
          selected_body_area={this.state.selected_body_area}
          change={this.state.change}
        />

        <ViewItemModal
          idModal={this.state.unique}
          key={this.state.unique}
          item={this.state.selectedItem}
          cancel={() => {
            $('viewItemModal').modal('hide')
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    // position: state.positioning.position,
    position_master: state.positioning.position_master,
    position_details: state.positioning.position_details,
    media: state.positioning.media,
    body_areas: state.positioning.body_areas,
  }
}

export default connect(mapStateToProps, {
  getItemsPerCategory,
  getPosition,
  savePositioningEquipment,
  getPositioningDetailsProcedure,
  positioningUpdate,
})(PositioningEquipmentEditLayout)
