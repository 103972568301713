import React, { Component } from 'react'
import axios from 'axios'
import _ from 'lodash'
import $ from 'jquery'
import { TextInput, Button } from '../commons/forms'
import {
  Table,
  THead,
  TRow,
  TData,
  TableNotificationCard,
} from '../commons/table'
import { Modal } from '../commons/modals'
import ViewItemCard from '../commons/cards/ViewItemCard'
export class AssignTraysProcedureInstrument extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected_trays: [],
      trays: [],
      keyword: '',
      assignOnly: false,
      selectedTray: null,
      isReverse: false,
      loading: false,
    }
  }

  sortByTray(key) {
    let arrayCopy = [...this.state.trays]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareByTray(key))
    } else {
      arrayCopy.sort(this.compareByTray(key)).reverse()
    }

    this.setState({ trays: arrayCopy, isReverse: !this.state.isReverse })
  }

  compareByTray(key) {
    if (key == 'specialty') {
      return function(a, b) {
        if (a.specialty.name < b.specialty.name) return -1
        if (a.specialty.name > b.specialty.name) return 1
        return 0
      }
    }
    return function(a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }

  componentDidMount = () => {
    this.getTrays()
  }

  componentWillReceiveProps = nextProps => {
    this.setState({
      selected_trays: nextProps.selected_trays,
    })
    if (nextProps.change != this.props.change) {
      this.getTrays()
    }
  }

  checkTray = id => {
    let { selected_trays, trays } = this.state
    let tray = trays.find(e => e.id === id)

    if (this.checkTrayExistence(id)) {
      if (selected_trays.filter(e => e.tray_id === id).length > 0) {
        this.setState({
          selected_trays: selected_trays.filter(x => x.tray_id !== id),
          selectedTray: null,
        })
      } else if (selected_trays.filter(e => e.id === id).length > 0) {
        this.setState({
          selected_trays: selected_trays.filter(x => x.id !== id),
          selectedTray: null,
        })
      }
    } else {
      this.setState({
        selected_trays: [...selected_trays, tray],
      })
    }
  }

  checkTrayExistence = id => {
    let { selected_trays } = this.state

    let exist = false
    if (selected_trays.filter(e => e.tray_id === id).length > 0) {
      exist = true
    } else if (selected_trays.filter(e => e.id === id).length > 0) {
      exist = true
    } else {
      exist = false
    }

    return exist
  }

  getTrays() {
    let url = `api/tray?keyword=${this.state.keyword}`
    axios
      .get(url)
      .then(res => {
        this.setState({
          trays: res.data.data,
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  allocateItem = () => {
    const { stage_master } = this.props
    const { selected_trays } = this.state
    const data = new FormData()
    data.append(`_method`, 'PATCH')
    selected_trays.map(tray => {
      data.append(`tray_ids[]`, tray.tray_id ? tray.tray_id : tray.id)
    })
    axios
      .post(`api/client/stage-instrument-assign-tray/${stage_master.id}`, data)
      .then(result => {
        this.props.getProcedureDetail(1)
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
      })
    this.props.assignNewState({ selected_trays: selected_trays })
    $('#assignTraysModal').modal('hide')
  }

  getItems = id => {
    this.setState({
      loading: true,
    })
    axios
      .get(`api/tray/${id}`)
      .then(result => {
        console.log(result)
        this.setState({
          selectedTray: result.data.data,
          loading: false,
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  render() {
    let traylist = this.state.trays.map(tray => {
      if (this.state.assignOnly) {
        if (this.checkTrayExistence(tray.id)) {
          return (
            <tr>
              <td className="px10 w200">{tray.specialty.name}</td>
              <td className="px10 w250">{tray.name}</td>
              <th className="px10 text-center w100">
                <a href="#" className="text-dark">
                  {this.checkTrayExistence(tray.id) ? 'Remove' : 'Assign'}
                </a>
              </th>
            </tr>
          )
        }
      } else {
        return (
          <tr>
            <td className="px10 w200">{tray.specialty.name}</td>
            <td className="px10 w250">{tray.name}</td>
            <th className="px10 text-center w100">
              <a
                href="#"
                className="text-dark"
                onClick={e => {
                  e.preventDefault()
                  this.checkTray(tray.id)
                }}
              >
                {this.checkTrayExistence(tray.id) ? 'Remove' : 'Assign'}
              </a>
            </th>
          </tr>
        )
      }
    })

    const search = _.debounce(() => {
      this.getTrays()
    }, 500)

    return (
      <>
        <Modal
          idModal="assignTraysModal"
          size="modal-xl"
          title="Assign trays"
          confirmText="Assign Tray"
          cancelText="Cancel"
          confirm={e => {
            this.allocateItem()
          }}
          cancel={e => {
            //fn here
          }}
          bodyClass="assign-items-body"
        >
          <div className="container">
            <div className="row pb10">
              <div className="col-lg-5">
                <TextInput
                  label={'Search item database'}
                  placeholder={'Start typing name'}
                  className="active"
                  onChange={event => {
                    this.setState({ keyword: event.target.value }, () => {
                      search()
                    })
                  }}
                  search
                />
              </div>
              <div className="col-md-7 d-flex align-items-start">
                <Button
                  secondary
                  onClick={() => {
                    this.setState({
                      assignOnly: !this.state.assignOnly,
                    })
                  }}
                >
                  {' '}
                  {this.state.assignOnly
                    ? 'View all'
                    : 'View assigned trays only'}
                </Button>
                <Button
                  secondary
                  onClick={() => {
                    $('#assignTraysModal').modal('hide')
                    $('#registerNewTrayModal').modal('show')
                  }}
                >
                  {' '}
                  Register new tray
                </Button>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <Table
                  minWidth={460}
                  className="modal-table infi-table"
                  infiniteScroll infiniteHeight={307}
                  infiniteDataLength={10} //This is important field to render the next data
                  infiniteNext={() => {}}
                  infiniteHasMore={false}
                  endOfMessageTitle="Nothing else to see..."
                  endOfMessageMessage="Missing something? Add new trays via"
                  endOfMessageLinkMessage="Instruments page"
                  endOfMessageLink={{
                    pathname: '/medical-inventory-instruments',
                  }}
                  endOfMessageOnClick={() => {
                    $('#assignTraysModal').modal('hide')
                  }}
                  renderHeaders={() => {
                    return (
                      <TRow>
                        <THead className="w40" text="" />
                        <THead className="w150" text="Specialty" />
                        <THead
                          className="w200"
                          text="Name"
                          sortable
                          onClick={() => {
                            this.sortByTray('name')
                          }}
                        />
                        <THead className="w90 d-none" text="View" />
                      </TRow>
                    )
                  }}
                  renderRows={() => {
                    let rows = this.state.trays.map(tray => {
                      if (this.state.assignOnly) {
                        if (this.checkTrayExistence(tray.id)) {
                          return (
                            <TRow
                              key={`row-tray-p-${tray.id}`}
                              autoCheck={false}
                              hasChoices={false}
                            >
                              <TData
                                hasChoices
                                className="w40"
                                onClick={() => {
                                  this.checkTray(tray.id)
                                }}
                              >
                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={`checkbox-trayp-${tray.id}`}
                                    checked={this.checkTrayExistence(tray.id)}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={`checkbox-trayp-${tray.id}`}
                                    onClick={e => {
                                      e.stopPropagation()
                                    }}
                                  ></label>
                                </div>
                              </TData>
                              <TData className="w150">
                                {tray.specialty.name}
                              </TData>
                              <TData className="w200">
                                <a
                                  href="#"
                                  onClick={e => {
                                    e.preventDefault()
                                    this.getItems(tray.id)
                                  }}
                                >
                                  {tray.name}{' '}
                                </a>
                              </TData>
                              <TData className="w90 d-none">
                                <div className="d-flex">
                                  <Button
                                    tertiary
                                    onClick={e => {
                                      e.stopPropagation()
                                      this.setState({
                                        selectedTray: tray,
                                      })
                                    }}
                                  >
                                    View
                                  </Button>
                                </div>
                              </TData>
                            </TRow>
                          )
                        }
                      } else {
                        return (
                          <TRow key={`row-tray-p-${tray.id}`} autoCheck={false}>
                            <TData
                              className="w40"
                              onClick={e => {
                                this.checkTray(tray.id)
                              }}
                            >
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id={`checkbox-trayp-${tray.id}`}
                                  checked={this.checkTrayExistence(tray.id)}
                                  autoCheck={false}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={`checkbox-trayp-${tray.id}`}
                                  onClick={e => {
                                    e.stopPropagation()
                                  }}
                                ></label>
                              </div>
                            </TData>
                            <TData className="w150">
                              {tray.specialty.name}
                            </TData>
                            <TData className="w200">
                              <a
                                href="#"
                                onClick={e => {
                                  e.preventDefault()
                                  this.getItems(tray.id)
                                }}
                              >
                                {tray.name} ({tray.total_items})
                              </a>
                            </TData>
                            <TData className="w90 d-none">
                              <div className="d-flex">
                                <Button tertiary>View</Button>
                              </div>
                            </TData>
                          </TRow>
                        )
                      }
                    })
                    return rows
                  }}
                  // renderNotification={() => {
                  //     return (<TableNotificationCard type="success" message="Success message here..." />)
                  // }}
                />
              </div>

              <div className="col">
                <Table
                  minWidth={400}
                  className="modal-table infi-table "
                  infiniteScroll infiniteHeight={307}
                  infiniteDataLength={10} //This is important field to render the next data
                  infiniteNext={() => {}}
                  infiniteHasMore={false}
                  loading={this.state.loading}
                  endOfMessageTitle={''} //this.state.selectedTray ? this.state.selectedTray.items.length ? 'Nothing else to see...' : 'No items in tray...' :'No tray selected'
                  endOfMessageMessage={
                    this.state.selectedTray
                      ? ''
                      : "To select a tray, click 'View' on tray data."
                  }
                  renderHeaders={() => {
                    return (
                      <TRow>
                        <THead className="w200" text="Name" />
                        <THead className="w150" text="Category" />
                        <THead className="w90" text="Qty" />
                      </TRow>
                    )
                  }}
                  renderRows={() => {
                    if (this.state.selectedTray) {
                      let rows = this.state.selectedTray.items.map(item => {
                        return (
                          <TRow>
                            <TData className="w200">{item.name}</TData>
                            <TData className="w150">
                              {item.category ? item.category.name : ''}
                            </TData>
                            <TData className="w90">{item.qty}</TData>
                          </TRow>
                        )
                      })
                      return rows
                    }
                    return []
                  }}
                  // renderNotification={() => {
                  //     return (<TableNotificationCard type="success" message="Success message here..." />)
                  // }}
                />
              </div>
            </div>
          </div>
        </Modal>
        <div
          className="modal fade"
          id="assignTraysModal2"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="assignTraysModalTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-xl px30" role="document">
            <div className="modal-content">
              <div className="modal-body pt30 px30 pb0">
                <h5 className="m-0 pb0 h3bold">Assign trays</h5>
              </div>
              <div className="modal-body px30 pt-0 pb0">
                <div className="row pb0">
                  <div className="col-lg-4 form-group mb30">
                    <label className="mb10">Search</label>
                    <input
                      type="text"
                      name="search"
                      className="form-control"
                      placeholder="Start typing name, catalogue #..."
                      onChange={event => {
                        this.setState({ keyword: event.target.value }, () => {
                          search()
                        })
                      }}
                    />
                  </div>
                  <div className="col-lg-4 mb30 form-group m-0 d-flex align-items-end">
                    <div
                      className="btn  btn-outline-dark"
                      onClick={() => {
                        this.setState({
                          assignOnly: !this.state.assignOnly,
                        })
                      }}
                    >
                      {this.state.assignOnly
                        ? 'View all'
                        : 'View assigned trays only'}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="w-100 of-auto">
                      <table className="table table-striped table-fixed border min-table-width-560">
                        <thead className="bg-dark text-white text-left">
                          <tr>
                            <th className="px10 w200">Specialty</th>
                            <th className="px10 w250">Name</th>
                            <th className="px10 w100" />
                          </tr>
                        </thead>
                        <tbody
                          style={{
                            float: 'left',
                            width: '100%',
                            overflow: 'auto',
                            maxHeight: '358px',
                            height: '358px',
                          }}
                        >
                          {traylist}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-body mb-0 px30 pb0 pt-0 border-0 justify-content-start">
                <div
                  className="btn btn-dark mr30 mt-0 px30 mb-0 float-left"
                  onClick={() => this.allocateItem()}
                >
                  Allocate items
                </div>
                <div
                  className="btn btn-light border-dark px30 mt-0 mb-0 float-left m-xst30"
                  data-dismiss="modal"
                >
                  Cancel
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default AssignTraysProcedureInstrument
