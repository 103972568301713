import arrayMove from 'array-move'
import axios from 'axios'
import $ from 'jquery'
import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { positioningUpdate } from '../../actions'
import MediaEditor from '../../components/commons/media_elements/MediaEditor'
import StandardEditor from '../../components/commons/media_elements/StandardEditor'
import TextEditor from '../../components/commons/media_elements/TextEditor'
import { getError } from '../../utilities'
import { procedureAction } from '../../utilities/notifier'
import DataCard from '../commons/cards/DataCard'
import { Button, Picker } from '../commons/forms'
import AddNewSection from '../commons/media_elements/AddNewSection'
import MediaElement from '../commons/media_elements/MediaElement'
import StandardElement from '../commons/media_elements/StandardElement'
import TextElement from '../commons/media_elements/TextElement'
import EditMenu from '../commons/misc/EditMenu'
import { ConfirmationModal, Modal } from '../commons/modals'
import Notifier from '../commons/notifier/Notifier'
import { hideLoader, setLoaderProg, showLoader } from '../loader/loading_screen'
import PositioningSkeleton from '../skeletons/PositioningSkeleton'

export class PositioningLayout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      positioning_list: [],
      positioning: { id: '' },
      positioning_content: [],
      editor: false,
      selected_temp: '',
      selected: {
        id: '',
      },
      draft_success: false,
      repopulate: true,
      preview: false,
      errors: [],
      progress: 0,
      common_items: [],
      progressData: [],
    }

    this.elementsRef = []

    const SortableItem = SortableElement(({ media, index2 }) => {
      const index = index2
      switch (media.type) {
        case 'text':
          return (
            <li>
              <TextEditor
                elementRef={e => {
                  this.elementsRef[index2] = e
                }}
                data={{
                  title: media.title,
                  content: media.contents.caption,
                }}
                mentions={this.state.common_items}
                validation={{
                  errors: this.state.errors,
                  prefix: 'elements',
                  index: index,
                  field: 'title',
                }}
                onTitleChange={event => {
                  this.titleChange(event, index)
                }}
                textContentChange={event => {
                  this.textContentChange(event, index)
                }}
                onDelete={event => this.deleteElement(event, index)}
                onDuplicate={event => this.duplicateElement(event, index)}
              />
            </li>
          )
          break
        case 'media':
          return (
            <li>
              <MediaEditor
                elementRef={e => {
                  this.elementsRef[index2] = e
                }}
                data={{
                  title: media.title,
                  medias: media.contents,
                }}
                mentions={this.state.common_items}
                validation={{
                  errors: this.state.errors,
                  prefix: 'elements',
                  index: index,
                  field: 'title',
                }}
                onTitleChange={event => {
                  this.titleChange(event, index)
                }}
                onContentTitleChange={(event, i) => {
                  this.onContentTitleChange(event, i, index)
                }}
                onContentCaptionChange={(event, i) => {
                  this.onContentCaptionChange(event, i, index)
                }}
                onContentMediaChange={(event, i) => {
                  this.onContentMediaChange(event, i, index)
                }}
                onContentThumbnailChange={(event, i) => {
                  this.onContentThumbnailChange(event, i, index)
                }}
                onContentSortChange={(contents, i) => {
                  this.onContentSortChange(contents, index)
                }}
                addNewMedia={event => this.addNewMedia(event, index)}
                onDuplicate={event => this.duplicateElement(event, index)}
                onDelete={event => this.deleteElement(event, index)}
                onDeleteMediaElement={this.deleteMediaElement}
                progressMedia={() => {
                  console.log('progressMediaprogressMedia')
                  this.setState({
                    progressData: [...this.state.progressData, true],
                  })
                }}
                progressComplete={() => {
                  this.setState({
                    progressData: [...this.state.progressData, true],
                  })
                }}
              />
            </li>
          )
          break
        case 'standard':
          const contentMedia = media.contents
          return (
            <li>
              <StandardEditor
                elementRef={e => {
                  this.elementsRef[index2] = e
                }}
                progressMedia={() => {
                  console.log('progressMediaprogressMedia')
                  this.setState({
                    progressData: [...this.state.progressData, true],
                  })
                }}
                progressComplete={() => {
                  setTimeout(() => {
                    this.setState({
                      progressData: [...this.state.progressData, true],
                    })
                  }, 500)
                }}
                mentions={this.state.common_items}
                data={{
                  title: media.title,
                  text: contentMedia ? contentMedia.caption : '',
                  media: {
                    thumbnail: contentMedia
                      ? contentMedia.gallery
                        ? contentMedia.gallery.thumbnail
                        : ''
                      : '',
                    source: contentMedia
                      ? contentMedia.gallery
                        ? contentMedia.gallery.source
                        : ''
                      : '',
                  },
                }}
                validation={{
                  errors: this.state.errors,
                  prefix: 'elements',
                  index: index,
                  field: 'title',
                }}
                onTitleChange={event => {
                  this.titleChange(event, index)
                }}
                onStandardTextChange={event => {
                  this.standardTextChange(event, index)
                }}
                thumbnailChange={thumbnail => {
                  this.standardThumbnailChange(thumbnail, index)
                }}
                onMediaChange={event => {
                  this.mediaStandardChange(event, index)
                }}
                onDelete={event => this.deleteElement(event, index)}
                onDuplicate={event => this.duplicateElement(event, index)}
              />
            </li>
          )
          break
        default:
          break
      }
    })

    this.SortableList = SortableContainer(({ items }) => {
      return (
        <ul className="list-unstyled">
          {items.map((value, index) => (
            <SortableItem
              key={`item-${index}`}
              index={index}
              index2={index}
              media={value}
            />
          ))}
        </ul>
      )
    })
  }

  componentDidMount = () => {
    console.log('this.props.stage_details', this.props.stage_details)
    this.getAllPoisitioning()
    this.setState({
      repopulate: false,
      positioning: this.props.stage_details.position,
      positioning_content: this.props.stage_details.elements,
      selected: {
        id: this.props.stage_details.position_id,
      },
    })
    if (this.props.stage_details.position) {
      Object.keys(this.props.stage_details.position).forEach(e => {
        this.props.positioningUpdate({
          prop: e,
          value: this.props.stage_details.position[e],
        })
      })

      this.setState({
        positioning: this.props.stage_details.position,
        positioning_content: this.props.stage_details.elements,
      })
    }
    console.log('enableDraft', this.props.enableDraft)

    if (this.props.enableDraft) {
      this.setState(
        {
          editor: true,
          repopulate: true,
        },
        () => {
          this.props.getProcedureDetail(1)
        }
      )
    }

    this.props.setNewState({
      settings: false,
    })
  }

  onEditMode = () => {
    this.setState({
      repopulate: false,
      positioning: this.props.stage_details.position,
      positioning_content: this.props.stage_details.elements,
      selected: {
        id: this.props.stage_details.position_id,
      },
    })
    if (this.props.stage_details.position) {
      Object.keys(this.props.stage_details.position).forEach(e => {
        this.props.positioningUpdate({
          prop: e,
          value: this.props.stage_details.position[e],
        })
      })

      this.setState({
        positioning: this.props.stage_details.position,
        positioning_content: this.props.stage_details.elements,
      })
    }
    console.log('enableDraft', this.props.enableDraft)

    if (this.props.enableDraft) {
      this.setState(
        {
          editor: true,
          repopulate: true,
        },
        () => {
          this.props.getProcedureDetail(1)
        }
      )
    }

    this.props.setNewState({
      settings: false,
    })
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.stage_master != nextProps.stage_master) {
      if (!nextProps.stage_master.draft) {
        this.setState({
          editor: false,
          item_list: nextProps.item_list,
        })
        if (
          nextProps.stage_details.elements != this.props.stage_details.elements
        ) {
          this.setState({
            positioning_content: nextProps.stage_details.elements,
          })
        }
      }
    }
    /* 
    if (nextProps.stage_details.position && this.state.repopulate) {
      Object.keys(nextProps.stage_details.position).forEach(e => {
        this.props.positioningUpdate({
          prop: e,
          value: nextProps.stage_details.position[e],
        })
      })

      this.setState({
        repopulate: false,
        positioning: nextProps.stage_details.position,
        positioning_content: nextProps.stage_details.elements,
        selected: {
          id: nextProps.stage_details.position_id,
        },
      })
    } */
  }

  componentDidUpdate(prevProps, prevState) {
    $('[data-toggle="popover"]').popover()
    $('.popover').popover('hide')
  }

  savePositioning = (draft = 0) => {
    const { positioning_content } = this.state
    const { stage_master } = this.props
    procedureAction(stage_master.id, draft)
    const data = new FormData()
    data.append('_method', 'PATCH')
    data.append('draft', draft)
    data.append('surgeon_id', this.props.stage_master.surgeon_id)
    if (this.state.selected.id && this.state.selected.id > 0) {
      data.append('position', this.state.selected.id)
    }

    positioning_content.map((position, index) => {
      data.append(
        `elements[${index}][title]`,
        position.title ? position.title : ''
      )
      data.append(`elements[${index}][type]`, position.type)

      switch (position.type) {
        case 'text':
          data.append(
            `elements[${index}][values]`,
            position.contents.caption ? position.contents.caption : ''
          )
          break
        case 'standard':
          data.append(
            `elements[${index}][media-text]`,
            position.contents.caption ? position.contents.caption : ''
          )
          data.append(
            `elements[${index}][media-title]`,
            position.contents.title
          )
          data.append(`elements[${index}][media-id]`, 0)
          data.append(`elements[${index}][media-updated]`, 1)
          data.append(
            `elements[${index}][media-value]`,
            position.contents.gallery.source
              ? position.contents.gallery.source
              : ''
          )
          data.append(
            `elements[${index}][media-original]`,
            position.contents.gallery.originSource
              ? position.contents.gallery.originSource
              : ''
          )
          data.append(
            `elements[${index}][media-type]`,
            position.contents.gallery.type
          )

          break
        case 'media':
          position.contents.map((content, i) => {
            data.append(
              `elements[${index}][media-text][]`,
              content.gallery.caption ? content.gallery.caption : ''
            )
            data.append(
              `elements[${index}][media-title][]`,
              content.gallery.title ? content.gallery.title : ''
            )
            data.append(
              `elements[${index}][media-type][]`,
              content.gallery.type
            )
            data.append(`elements[${index}][media-id][]`, 0)
            data.append(`elements[${index}][media-updated][]`, 1)
            data.append(
              `elements[${index}][media-value][${i}]`,
              content.gallery.source ? content.gallery.source : ''
            )
            data.append(
              `elements[${index}][media-original][${i}]`,
              content.gallery.originSource ? content.gallery.originSource : ''
            )
          })
          break
        default:
          break
      }
    })

    let url = `api/client/stage-positioning/${this.props.stage_master.id}`
    let config = {
      headers: {
        'content-type': 'multipart/form-data',
      },

      onUploadProgress: progressEvent => {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        showLoader(percentCompleted >= 95 ? 95 : percentCompleted)
        setLoaderProg(percentCompleted >= 95 ? 95 : percentCompleted)
      },
    }

    axios
      .post(url, data, config)
      .then(result => {
        console.log(result)
        if (!draft) {
          this.setState({
            preview: false,
            editor: false,
            repopulate: true,
            draft_success: false,
            showNotif1: true,
            progress: 0,
            errors: [],
          })
          this.props.getProcedureDetail(0)
          setLoaderProg(99)
          hideLoader()
        } else {
          this.setState({
            draft_success: true,
            progress: 0,
            errors: [],
          })
          this.refNotifier2.showThis()
          setLoaderProg(99)
          hideLoader()
        }
      })
      .catch(errors => {
        setLoaderProg(99)
        setTimeout(() => {
          hideLoader()
        }, 800)
        console.log(errors.response)
        if (errors.response.status != 422) {
          this.setState({
            progress: 0,
          })
          return
        }
        this.setState(
          {
            progress: 0,
            errors: errors.response.data.errors,
          },
          () => {
            let targetOffset = $('.is-invalid').offset()
              ? $('.is-invalid').offset().top
              : ''
            $('html, body').animate({ scrollTop: targetOffset }, 100)
          }
        )
      })
  }

  getAllPoisitioning = () => {
    let url = `/api/client/stage-positioning-list`

    axios
      .get(url)
      .then(res => {
        console.log(res)

        const list = res.data.map(i => {
          return {
            id: i.id,
            name: i.name,
          }
        })
        list.unshift({
          id: '',
          name: 'Select positioning',
        })
        this.setState({
          positioning_list: list,
        })

        axios
          .get(
            `/api/client/client-stage-common-items?surgeon_id=${this.props.stage_master.surgeon_id}&procedure_id=${this.props.stage_master.procedure_id}`
          )
          .then(res => {
            this.setState({
              common_items: res.data.data,
            })
          })
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
      })
  }

  getPositioning = (id, draft = 0) => {
    if (id != '') {
      let url = `/api/edit/${id}/positioning`

      axios
        .get(url)
        .then(res => {
          console.log('tes', res)
          this.setState({
            positioning: res.data.data,
            selected: {
              id: id,
            },
          })

          Object.keys(res.data.data).forEach(e => {
            this.props.positioningUpdate({
              prop: e,
              value: res.data.data[e],
            })
          })
        })
        .catch(error => {
          console.log(error)
          console.log(error.response)
        })
    } else {
      this.setState({
        positioning: { id: '' },
        selected: {
          id: 0,
        },
        positioning_content: [],
      })
    }
  }

  getEditPositioning = (draft = 0) => {
    const { positioning } = this.state
    let url = `api/edit/${positioning.id}/positioning`

    axios
      .get(url)
      .then(res => {
        Object.keys(res.data.data).forEach(e => {
          this.props.positioningUpdate({
            prop: e,
            value: res.data.data[e],
          })
        })
      })
      .catch(err => {
        console.log(err)
        console.log(err.response)
      })
  }

  getLayout = () => {
    const { editor, positioning, preview } = this.state
    if (preview) {
      return this.previewContent()
    }

    if (editor) {
      return this.editor()
    } else {
      return this.view()
    }
  }

  duplicateElement = (event, index) => {
    event.preventDefault()
    const { positioning_content } = this.state
    positioning_content.push(_.cloneDeep(positioning_content[index]))
    console.log(
      'cccc',
      positioning_content,
      positioning_content[index],
      _.cloneDeep(positioning_content[index])
    )

    this.setState(
      {
        positioning_content: positioning_content,
      },
      () => {
        this.elementsRef[
          this.state.positioning_content.length - 1
        ].scrollIntoView({ behavior: 'smooth' })
      }
    )
  }

  deleteElement = (event, index) => {
    const { positioning_content } = this.state
    event.preventDefault()
    positioning_content.splice(index, 1)
    this.setState({
      errors: [],
      positioning_content: _.cloneDeep(positioning_content),
    })
  }

  deleteMediaElement = (event, index, subIndex) => {
    event.preventDefault()
    const { positioning_content } = this.state

    positioning_content[index].contents.splice(subIndex, 1)

    this.setState({
      errors: [],
      positioning_content: _.cloneDeep(positioning_content),
    })
  }

  newTextSection = () => {
    const { positioning_content } = this.state
    let textsection = {
      title: null,
      type: 'text',
      contents: {
        caption: '',
        content: '',
      },
    }
    positioning_content.push(textsection)
    this.setState({
      positioning_content,
    })
  }

  newMediaSection = () => {
    const { positioning_content } = this.state
    let mediasection = {
      title: null,
      type: 'media',
      contents: [
        {
          title: null,
          caption: null,
          gallery: {},
        },
      ],
    }
    positioning_content.push(mediasection)
    this.setState({
      positioning_content,
    })
  }

  newStandardSection = () => {
    const { positioning_content } = this.state
    let standardsection = {
      title: null,
      type: 'standard',
      contents: {
        gallery: {
          source: null,
          thumbnail: null,
          type: null,
        },
        caption: '',
      },
    }

    positioning_content.push(standardsection)
    this.setState({
      positioning_content,
    })
  }

  newSectionMenu = () => {
    return (
      <div className="row mt30">
        <div className="col-12">
          <AddNewSection
            addTextOnly={() => {
              this.newTextSection()
            }}
            addMediaGallery={() => {
              this.newMediaSection()
            }}
            addTextAndMedia={() => {
              this.newStandardSection()
            }}
          />
        </div>
      </div>
    )
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ positioning_content }) => ({
      errors: [],
      positioning_content: arrayMove(positioning_content, oldIndex, newIndex),
    }))
  }

  onContentSortChange = (contents, index) => {
    const { positioning_content } = this.state
    positioning_content[index].contents = contents

    this.setState({
      errors: [],
      positioning_content: _.cloneDeep(positioning_content),
    })
  }
  //helper functions
  titleChange = (event, index) => {
    console.log('title changing')
    const { positioning_content } = this.state
    positioning_content[index].title = event.target.value

    this.setState({
      positioning_content: _.cloneDeep(positioning_content),
    })
  }

  textContentChange = (event, index) => {
    const { positioning_content } = this.state
    positioning_content[index].contents.caption = event.editor.getData()

    this.setState({
      positioning_content: _.cloneDeep(positioning_content),
    })
  }

  standardTextChange = (event, index) => {
    const { positioning_content } = this.state
    positioning_content[index].contents.caption = event.editor.getData()

    this.setState({
      positioning_content: _.cloneDeep(positioning_content),
    })
  }

  mediaStandardChange = (event, index) => {
    const { positioning_content } = this.state
    positioning_content[index].contents.gallery.source = event.data
    positioning_content[index].contents.gallery.type = event.type
    if (event.type == 'image')
      positioning_content[index].contents.gallery.thumbnail = event.data

    this.setState({
      positioning_content: _.cloneDeep(positioning_content),
    })
  }

  standardThumbnailChange = (thumbnail, index) => {
    const { positioning_content } = this.state
    positioning_content[index].contents.gallery.thumbnail = thumbnail
    this.setState({
      positioning_content: _.cloneDeep(positioning_content),
    })
  }

  onContentTitleChange = (event, item_index, index) => {
    const { positioning_content } = this.state
    positioning_content[index].contents[item_index].gallery.title =
      event.target.value
    this.setState({
      positioning_content: _.cloneDeep(positioning_content),
    })
  }

  onContentCaptionChange = (event, item_index, index) => {
    const { positioning_content } = this.state
    if (positioning_content[index]) {
      positioning_content[index].contents[
        item_index
      ].gallery.caption = event.editor.getData()
      this.setState({
        positioning_content: _.cloneDeep(positioning_content),
      })
    }
  }

  onContentMediaChange = (event, item_index, index) => {
    const { positioning_content } = this.state
    if (positioning_content[index]) {
      positioning_content[index].contents[item_index].gallery.source =
        event.data
      positioning_content[index].contents[item_index].gallery.type = event.type
      if (event.type == 'image')
        positioning_content[index].contents[item_index].gallery.thumbnail =
          event.data
      this.setState({
        positioning_content: _.cloneDeep(positioning_content),
      })
    }
  }

  onContentThumbnailChange = (event, item_index, index) => {
    const { positioning_content } = this.state
    if (
      positioning_content[index] &&
      positioning_content[index].contents[item_index] &&
      positioning_content[index].contents[item_index].gallery
    ) {
      positioning_content[index].contents[item_index].gallery.thumbnail = event
      this.setState({
        positioning_content: _.cloneDeep(positioning_content),
      })
    }
  }

  addNewMedia = (event, index) => {
    event.preventDefault()
    const { positioning_content } = this.state
    positioning_content[index].contents.push({
      gallery: {
        show: true,
        thumbnail: null,
        source: null,
        caption: null,
        title: null,
        id: 0,
      },
    })
    this.setState(
      {
        positioning_content: _.cloneDeep(positioning_content),
      },
      () => {}
    )
  }

  editor = () => {
    const { positioning, positioning_content } = this.state

    const progressList = $('.pie-wrapper').length

    return (
      <div className="w-100 float-left mt0">
        <ConfirmationModal
          title="Save to draft"
          message="Do you want to save draft?"
          confirmText="Yes"
          cancelText="Cancel"
          confirm={() => {
            this.setState({
              repopulate: true,
            })
            this.savePositioning(1)
          }}
          idModal="save-to-draftmodal"
          cancel={() => {
            $('.modal').modal('hide')
          }}
        />
        <div className="row">
          <div className="col-md-12">
            <EditMenu>
              <li>
                <Button
                  primary
                  onClick={() => {
                    this.savePositioning()

                    this.props.setNewState({
                      procedure_stage_edit: false,
                    })
                  }}
                  disabled={progressList}
                >
                  Update
                </Button>
              </li>
              <li>
                <Button
                  tertiary
                  onClick={e => {
                    e.preventDefault()
                    $('#save-to-draftmodal').modal('show')
                  }}
                  disabled={progressList}
                >
                  Save Draft
                </Button>
              </li>
              <li>
                <Button
                  tertiary
                  onClick={e => {
                    e.preventDefault()
                    this.refNotifier3.showThis()
                    this.setState({
                      preview: true,
                      draft_success: false,
                    })
                  }}
                  disabled={progressList}
                >
                  Preview
                </Button>
              </li>
              <li className="has-line">
                <Button
                  tertiary
                  onClick={e => {
                    e.preventDefault()
                    this.setState({
                      editor: false,
                      repopulate: true,
                      draft_success: false,
                    })
                    this.props.getProcedureDetail()

                    this.props.setNewState({
                      procedure_stage_edit: false,
                    })
                  }}
                >
                  Cancel
                </Button>
              </li>
            </EditMenu>
          </div>
          <div className="col-md-12 mt10">
            <Notifier
              message="Draft has been saved!"
              onRef={ref => (this.refNotifier2 = ref)}
              type="success"
              styleClass="mb30 mt0"
              hideThisNotif={() => {
                this.refNotifier2.hideThis()
              }}
            />
          </div>
          <div className="col-md-12 mx-auto">
            <div className="row">
              {/* {this.state.draft_success ? <InlineNotification message={'Draft has been saveds!'} /> : null} */}
              <div className="col-md-5">
                <div className="form-group mb24">
                  <Picker
                    label="Select Position  "
                    onChange={event => {
                      $('#confirmModal').modal('show')
                      this.setState({
                        selected_temp: event.target.value,
                      })
                    }}
                    value={this.state.selected.id}
                    data={this.state.positioning_list}
                    errorMessage={getError(this.state.errors, 'position')}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 profile-card pt-0">
                {positioning &&
                positioning.id? (
                  <DataCard
                    title="Posititioning references"
                    className="mb20"
                    headerClass="bold"
                  >
                    <div className="row">
                      <div className="col-lg-6">
                        <h4 className="mb8 text-capitalize">
                          {positioning.name}
                        </h4>
                        <div
                          className="ar32 border"
                          onClick={() => {
                            $('#media_type')
                              .val('image')
                              .change()
                            $('#media_title')
                              .val(positioning.name)
                              .change()
                            $('#media_source')
                              .val(positioning.position_image)
                              .change()
                            $('#fullMediaViewModal').modal('show')
                          }}
                        >
                          <img src={positioning.position_image} />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <h4 className="mb8">Pressure map</h4>
                        <div
                          className="ar32 border"
                          onClick={() => {
                            $('#media_type')
                              .val('image')
                              .change()
                            $('#media_title')
                              .val('Pressure map')
                              .change()
                            $('#media_source')
                              .val(positioning.pressure_map_image)
                              .change()
                            $('#fullMediaViewModal').modal('show')
                          }}
                        >
                          <img src={positioning.pressure_map_image} />
                        </div>
                        <div className="mt8 d-flex justify-content-center">
                          {positioning.pressure_maps ? (
                            positioning.pressure_maps.length ? (
                              <Button
                                tertiary
                                datatoggle="modal"
                                datatarget="#viewInjuryPreventionModal"
                              >
                                View injury prevention information
                              </Button>
                            ) : (
                              ''
                            )
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </DataCard>
                ) : (
                  ''
                )}
                {/* {positioning.id ? <Preview widthStyle="col-md-12 pt0" /> : ''} */}
              </div>
            </div>
            {positioning_content.length > 0 ? (
              <ul className="list-unstyled">
                {
                  <this.SortableList
                    items={positioning_content}
                    onSortEnd={this.onSortEnd}
                    distance={1}
                    useDragHandle
                    useWindowAsScrollContainer={true}
                  />
                }
              </ul>
            ) : (
              ''
            )}

            {positioning.id
              ? this.newSectionMenu()
              : ''}
          </div>
        </div>
      </div>
    )
  }

  previewContent = () => {
    return (
      <div>
        {this.state.positioning && this.state.selected_temp != '' ? (
          <DataCard
            title="Posititioning references"
            className="mb20"
            headerClass="bold"
          >
            <div className="row">
              <div className="col-lg-6">
                <h4 className="mb8 text-capitalize">
                  {this.state.positioning.name}
                </h4>
                <div
                  className="ar32 border"
                  onClick={() => {
                    $('#media_type')
                      .val('image')
                      .change()
                    $('#media_title')
                      .val(this.state.positioning.name)
                      .change()
                    $('#media_source')
                      .val(this.state.positioning.position_image)
                      .change()
                    $('#fullMediaViewModal').modal('show')
                  }}
                >
                  <img src={this.state.positioning.position_image} />
                </div>
              </div>
              <div className="col-lg-6">
                <h4 className="mb8">Pressure map</h4>
                <div
                  className="ar32 border"
                  onClick={() => {
                    $('#media_type')
                      .val('image')
                      .change()
                    $('#media_title')
                      .val('Pressure map')
                      .change()
                    $('#media_source')
                      .val(this.state.positioning.pressure_map_image)
                      .change()
                    $('#fullMediaViewModal').modal('show')
                  }}
                >
                  <img src={this.state.positioning.pressure_map_image} />
                </div>
                <div className="mt8 d-flex justify-content-center">
                  {this.state.positioning.pressure_maps ? (
                    this.state.positioning.pressure_maps.length ? (
                      <Button
                        tertiary
                        datatoggle="modal"
                        datatarget="#viewInjuryPreventionModal"
                      >
                        View injury prevention information
                      </Button>
                    ) : (
                      ''
                    )
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </DataCard>
        ) : (
          ''
        )}

        {this.elements()}
      </div>
    )
  }

  positioningContent = () => {
    return (
      <div className="row">
        <div className="col-md-12">
          <h4 className="mb-0 d-flex align-items-end fs20 d-none"></h4>
        </div>
        <div className="col-md-12 profile-card pt-0">
          {this.props.stage_details.position ? (
            <DataCard
              title="Posititioning references"
              className="mb20"
              headerClass="bold"
            >
              <div className="row">
                <div className="col-lg-6">
                  <h4 className="mb8 text-capitalize">
                    {this.props.stage_details.position.name}
                  </h4>
                  <div
                    className="ar32 border"
                    onClick={() => {
                      $('#media_type')
                        .val('image')
                        .change()
                      $('#media_title')
                        .val(this.props.stage_details.position.name)
                        .change()
                      $('#media_source')
                        .val(this.props.stage_details.position.position_image)
                        .change()
                      $('#fullMediaViewModal').modal('show')
                    }}
                  >
                    <img
                      src={this.props.stage_details.position.position_image}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <h4 className="mb8">Pressure map</h4>
                  <div
                    className="ar32 border"
                    onClick={() => {
                      $('#media_type')
                        .val('image')
                        .change()
                      $('#media_title')
                        .val('Pressure map')
                        .change()
                      $('#media_source')
                        .val(
                          this.props.stage_details.position.pressure_map_image
                        )
                        .change()
                      $('#fullMediaViewModal').modal('show')
                    }}
                  >
                    <img
                      src={this.props.stage_details.position.pressure_map_image}
                    />
                  </div>
                  <div className="mt8 d-flex justify-content-center">
                    {this.props.stage_details.position.pressure_maps ? (
                      this.props.stage_details.position.pressure_maps.length ? (
                        <Button
                          tertiary
                          datatoggle="modal"
                          datatarget="#viewInjuryPreventionModal"
                        >
                          View injury prevention information
                        </Button>
                      ) : null
                    ) : null}
                  </div>
                </div>
              </div>
            </DataCard>
          ) : (
            ''
          )}

          {/* {this.props.stage_details.position ? <Preview widthStyle="col-md-12" /> : ''}  */}

          {this.elements()}
        </div>
      </div>
    )
  }

  elements = () => {
    const { positioning_content } = this.state

    if (positioning_content) {
    }
    let element = positioning_content.map(content => {
      if (content.type == 'text') {
        return <TextElement data={content} className="mb20" procedure={true} />
      } else if (content.type == 'media') {
        return <MediaElement data={content} className="mb20" />
      } else if (content.type == 'standard') {
        return <StandardElement data={content} className="mb20" />
      }
    })

    return element
  }

  view = () => {
    console.log(
      'this.props.stage_details',
      this.props.stage_details.position_id
    )
    const { positioning } = this.state
    return (
      <div className="w-100 float-left">
        <div className="row">
          <div className="col-md-12 mx-auto">
            <div className="row">
              <div className="col-md-5">
                {this.props.stage_details ? (
                  ''
                ) : (
                  <p
                    onClick={() => {
                      this.setState({
                        editor: true,
                        errors: [],
                      })
                    }}
                  >
                    Edit mode
                  </p>
                )}
              </div>
            </div>
            {this.props.stage_details &&
            this.props.stage_details.position_id != 0
              ? this.positioningContent()
              : ''}
          </div>
        </div>
      </div>
    )
  }

  injuryPreventionModal() {
    return (
      <Modal
        idModal="viewInjuryPreventionModal"
        size="modal-xl"
        title="Injury prevention"
        confirmText="Continue"
        cancelText="Cancel"
        noFooter
        bodyClass="assign-items-body"
      >
        <div className="row">
          <div className="col-lg-12">
            {this.props.positioning ? (
              this.props.positioning.pressure_maps ? (
                <MediaElement
                  data={this.props.positioning.pressure_maps}
                ></MediaElement>
              ) : null
            ) : null}
          </div>
        </div>
      </Modal>
    )
  }

  render() {
    return (
      <div className="w-100 float-left">
        {this.props.stage_details ? this.injuryPreventionModal() : null}
        <div className="row">
          <div className="col-md-12 mx-auto">
            {this.state.editor ? (
              <div className="row mt32">
                <div className="col-md-12 mx-auto d-flex justify-content-between align-items-center page-description">
                  <h2 className=" bold">Edit - Positioning page</h2>
                </div>
              </div>
            ) : !this.state.draft_success ? (
              <div className="row mt32">
                <div className="col-md-12 mx-auto d-flex justify-content-between align-items-center page-description">
                  <h2 className=" bold">Positioning</h2>
                  <Button
                    tertiary
                    onClick={e => {
                      e.preventDefault()
                      this.props.getProcedureDetail(
                        1,
                        'main',
                        false,
                        false,
                        () => {
                          this.setState(
                            {
                              editor: true,
                              repopulate: true,
                            },
                            () => {
                              this.onEditMode()
                            }
                          )
                        }
                      )
                      this.props.setNewState({
                        procedure_stage_edit: true,
                      })
                    }}
                  >
                    Edit {this.props.title}
                  </Button>
                </div>
                <div className="col-md-12 mt10">
                  <Notifier
                    show={this.state.showNotif1 && !this.state.draft_success}
                    message="Positioning successfully updated!"
                    onRef={ref => (this.refNotifier1 = ref)}
                    type="success"
                    styleClass="mb30 mt0"
                    hideThisNotif={() => {
                      this.refNotifier1.hideThis()
                      this.setState({
                        showNotif1: false,
                      })
                    }}
                  />
                </div>
              </div>
            ) : null}

            {this.state.draft_success && !this.state.editor ? (
              <div className="row mt32">
                <div className="col-md-12 mx-auto d-flex justify-content-between align-items-center page-description">
                  <h2 className=" bold">Positioning page 2</h2>
                </div>
              </div>
            ) : null}
            <div className="row">
              <div className="col-md-12">
                <Notifier
                  onRef={ref => (this.refNotifier3 = ref)}
                  type="warning"
                  styleClass="mb30 mt0"
                  hideThisNotif={() => {
                    this.setState({ preview: false, editor: true }, () => {
                      this.refNotifier3.hideThis()
                    })
                  }}
                >
                  <div>
                    <b>Preview mode</b> - If you like what you see, you can
                    <u
                      className="font-weight-bold c-pointer mx4"
                      onClick={() => {
                        this.savePositioning()
                        this.refNotifier3.hideThis()
                        this.props.setNewState({
                          procedure_stage_edit: false,
                        })
                      }}
                    >
                      Update positioning
                    </u>
                    or make edits by
                    <u
                      className="font-weight-bold c-pointer mx4"
                      onClick={() => {
                        this.setState({ preview: false, editor: true }, () => {
                          this.refNotifier3.hideThis()
                        })
                      }}
                    >
                      Closing preview
                    </u>
                  </div>
                </Notifier>
              </div>
            </div>
            {this.props.loading ? <PositioningSkeleton /> : this.getLayout()}

            <ConfirmationModal
              title="Change"
              message="Are you sure you want to change, the changes in positioning equipment will be overridden?"
              confirmText="Yes"
              cancelText="No"
              idModal={'confirmModal'}
              confirm={() => {
                this.getPositioning(this.state.selected_temp)
              }}
              cancel={() => {
                $('confirmModal').modal('hide')
              }}
            />
          </div>
        </div>
        {/* <WholeBodyloader
          message="Saving! please wait..."
          progress={this.state.progress}
          type="spinner"
        /> */}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    positioning: state.positioning,
    video_progress: state.video.progress,
  }
}

export default connect(mapStateToProps, { positioningUpdate })(
  PositioningLayout
)
