import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getSurgeons } from '../../actions/SurgeonActions'
import { loadSurgeonQuicknotes } from '../../actions/QuicknotesActions'
import _ from 'lodash'
import $ from 'jquery'
import { Modal } from '../commons/modals'
import { Table, TRow, TData, THead } from '../commons/table'
import { Link } from 'react-router'
import { Button, TextInput } from '../commons/forms'
import { getError } from '../../utilities'
import Axios from 'axios'

export class ShareSurgeon extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      errors: [],
    }
  }

  componentDidMount = () => {}
  componentWillReceiveProps = nextPros => {
    if (this.props.surgeonId != nextPros.surgeonId) {
      this.setState({
        email: '',
        errors: [],
      })
    }
  }

  shareToEmail = () => {
    const { email } = this.state
    const { surgeonId } = this.props
    Axios.post(`api/client/shares`, {
      email,
      surgeon_id: surgeonId,
    })
      .then(result => {
        console.log(result)

        this.setState(
          {
            email: '',
            errors: [],
          },
          () => {
            $(`#surgeon-modal-share`).modal('hide')
          }
        )
      })
      .catch(error => {
        this.setState({
          errors: error.response.data.errors,
        })
      })
  }

  render() {
    console.log('thi share', this.props)
    return (
      <>
        <Modal
          idModal="surgeon-modal-share"
          size="modal-md"
          title="Share Surgeon"
          confirmText="Ok"
          cancelText="Cancel"
          bodyClass="select-procedures-body"
          confirm={e => {
            $('#surgeon-modal-share').modal('hide')
          }}
          cancel={e => {}}
        >
          <div className="row">
            <div className="col-lg-8 col-md-10 mt8 mb16">
              <TextInput
                label={'Email Address *'}
                value={this.state.email}
                placeholder={'Enter email address'}
                errorMessage={getError(this.state.errors, 'email')}
                containerClass={`mb30`}
                onValueChange={event => {
                  this.setState({ email: event.target.value })
                }}
              />
            </div>
            <div className="col-lg-4 col-md-2 mt8 mb16">
              <Button
                primary
                onClick={() => {
                  this.shareToEmail()
                }}
              >{`Add`}</Button>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Table
                minWidth={540}
                className="modal-table"
                renderHeaders={() => {
                  return (
                    <TRow>
                      <THead className="w40" text="" />
                      <THead className="w300" text="Email" />
                      <THead className="w200" text="Status" />
                    </TRow>
                  )
                }}
                renderRows={() => {
                  let rows = this.props.shares.map((data, index) => {
                    return (
                      <TRow hasChoices key={`row-lfm-${index}`}>
                        <TData className="w300">{`${data.to_email}`}</TData>
                        <TData className="w200">
                          {data.accepted ? 'Accepted' : 'Pending'}
                        </TData>
                      </TRow>
                    )
                  })
                  return rows
                }}
                // renderNotification={() => {
                //     return (<TableNotificationCard type="success" message="Success message here..." />)
                // }}
              />
            </div>
          </div>
        </Modal>
        <div
          className="modal fade"
          id="loadFromModalCenter222"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="loadFromModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body pt30 px30 pb0">
                <h5 className="m-0 mx-auto font-weight-bold pb20">
                    Copy surgeons  procedure or surgical stage
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </h5>
                <p className="m-0">
                  Select from the list of surgeons to copy their{' '}
                  <b>[procedures/phase]</b>
                </p>
              </div>
              <div className="modal-body px30 pt-0">
                {/*  <div className="row">
                  <div className="col-md-5">
                    <div className="form-group mb15">
                      <label className=" mb10">Search</label>
                      <input
                        type="text"
                        name="test"
                        className="form-control"
                        placeholder="Start typing surgeon name"
                        ref={el => (this.searchKeyword = el)}
                        onChange={event => {
                          debounce(event.target.value)
                        }}
                      />
                    </div>
                  </div>
                </div> */}

                <div className="row">
                  <div className="col-md-12">
                    <div className="w-100 of-auto">
                      <table className="table table-striped table-fixed border table-hover-bold min-table-width-610">
                        <thead className="bg-dark text-white">
                          <tr>
                            <th className="w80" />
                            <th className="font-weight-bold mw300">
                              &nbsp;Surgeon
                            </th>
                            <th className="font-weight-bold mw220">
                              View [procedure/phase]
                            </th>
                          </tr>
                        </thead>
                        <tbody
                          style={{
                            float: 'left',
                            width: '100%',
                            overflow: 'auto',
                            maxHeight: '244px',
                          }}
                        ></tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-body mb-0 px30 pb0 pt-0 border-0 justify-content-start">
                <div
                  className="btn btn-dark mr30 mt-0 px30 mb-0 float-left"
                  data-dismiss="modal"
                  onClick={() => {
                    this.props.loadSurgeonQuicknotes(this.state.selected)
                    this.props.loadFrom()
                  }}
                >
                  Add
                </div>
                <div
                  className="btn btn-light border-dark px30 mt-0 mb-0 float-left m-xst20"
                  data-dismiss="modal"
                >
                  Cancel
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    surgeons: state.surgeon.surgeons,
  }
}

export default connect(
  mapStateToProps,
  { getSurgeons, loadSurgeonQuicknotes }
)(ShareSurgeon)
