import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router'
import $ from 'jquery'
export default WrappedComponent => {
  class Wrapper extends Component {
    state = { isLoading: true }

    componentDidMount = () => {
      
      this.setTimer()
      this.props.router.setRouteLeaveHook(
        this.props.route,
        this.routerWillLeave
      )

    }

    componentDidUpdate = prevProps => {
      if (this.props.location !== prevProps.location) {
        this.clearTimer()
        this.setState({ isLoading: true }, () => this.setTimer())
      }
    }

    clearTimer = () => clearTimeout(this.timeout)

    timer = () => this.setState({ isLoading: false }, () => this.clearTimer())

    setTimer = () => (this.timeout = setTimeout(this.timer, 1000))
    loadingScreen = () => {
      $('body').addClass('on')
    }
    routerWillLeave(nextState) {
      

      if (nextState.action === 'POP') {
      if ($('.modal').hasClass('show')) {
          $('.modal').modal('hide')
          console.log('modal here')
          return false
        }
        return true
      }
    }
    render = () => (
      <Fragment>
        {this.state.isLoading ? (
          this.loadingScreen()
        ) : (
          <WrappedComponent {...this.props} />
        )}
      </Fragment>
    )
  }
  return withRouter(Wrapper)
}
