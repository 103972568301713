import React, { Component } from 'react'
import { Link } from 'react-router'
import { Button } from '../forms'
import $ from 'jquery'
export class MedicalInventoryCard extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            unique: this.makeid(10)
        }
    }
    makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
 
    render() {
        const { title, description, url ,img} = this.props
        return (
            <Link className="card medical-inventory-card" to={url}>  
                <div className="ar32 item-img">
                    <img src={img} />
                </div>
                <div className="item-body">
                    <h3 className="bold item-name">{title}</h3>
                    <p className="item-description">{description}</p>
                    <div>
                        <Button tertiary link id={this.state.unique} url={url}>View database</Button>
                    </div>
                </div>
            </Link>
        )
    }
}

export default MedicalInventoryCard
