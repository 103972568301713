import React, { Component } from 'react'
import $ from 'jquery'
import { Link } from 'react-router-dom'

export class TabNavLink extends Component {
  render() {
    const { target, active = false, disabled = false, id, to} = this.props 
    return (
      <Link className={`link nav-item nav-link ${active ? 'active' : ''} ${disabled ? 'disabled':''}`}
        to={to}
       id={id ? id:`nav-${target}-tab`}  
       role="tab"   
       onClick={this.props.onClick}>

        {this.props.children}
      </Link>
    )
  }
}

export default TabNavLink
