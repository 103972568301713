import React, { Component } from 'react'
import axios from 'axios'
import $ from 'jquery'
import { Link } from 'react-router'
import ReactDOMServer from 'react-dom/server'
import Confirmation from '../Confirmation'
import ViewItemModal from '../modals/ViewItemModal'
import { Table, TRow, THead, TData, TableNotificationCard } from '../commons/table';
import ViewItemCard from '../commons/cards/ViewItemCard';
import { Button, TextInput, Picker } from '../commons/forms';
import _ from 'lodash'
import { Modal, ConfirmationModal } from '../commons/modals';

class Items extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      loading: false,
      page: 1,
      loading: 1,
      dataLength: 1,
      isEditMode: true,
      medical_categories: [],
      medical_inventory_name: '',
      selectedCategory: 'all',
      searchKeyword: '',
      inventory: '',
      cardView: false,
      selectedItem: null,
      sizes: [{ catalogue: '', size: '', show: true }],
      successAppear: false,
      isCreate: false,
      successMessage: '',
      isReverse: false,
      errors: [],
    }

    this.searchData = _.debounce(this.makeRemoteRequest, 600);
  }

  componentDidMount() {
    console.log('itemssssss', this.props)
    if (this.props.item) {
      const { item } = this.props
      this.setState(
        {
          data: this.state.data.filter(i => i.id != item.id),
        },
        () => {
          const { data } = this.state

          data.unshift(item)
          this.setState({
            data: data.splice(0),
            isCreate: false,
            successAppear: true,
            successMessage: 'Items have been successfully updated',
          })
          setTimeout(() => {
            this.setState(
              {
                successAppear: false,
              },
              () => {
                this.props.removeState()
              }
            )
          }, 5000)
        }
      )
    }
    this.makeRemoteRequest()
  }


  loadMoreData() {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => {
        this.makeRemoteRequest()
      }
    )
  }

  componentDidUpdate() {
    $('[data-toggle="popover"]').popover()
    $('[data-toggle="popover"]').popover('hide')
  }

  editItemSuccess(item) {}

  makeRemoteRequest = () => {
    this.setState({ loading: true })
    const { page, selectedCategory, searchKeyword, inventory } = this.state

    let url = `api/my-items?page=${page}&medical_inventory_id=${inventory}&search=${searchKeyword}`
    axios
      .get(url)
      .then(res => {
        this.setState({
          data:
            page === 1 ? res.data.data : [...this.state.data, ...res.data.data],
          loading: false,
          hasMore: res.data.data.length > 0,
          dataLength: res.data.data.length,
        })
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
        this.setState({ loading: false, hasMore: false })
      })
  }

  renderTable() {
    const rows = this.state.data.map(data => {
      return (
        <tr key={data.id}>
          <td>{data.catalog_number}</td>
          <td
            className="text-dark pover  text-left"
            data-trigger="hover"
            data-toggle="popover"
            data-placement="right"
            data-html="true"
            data-content={`
                <div class="row">
                    <div class="col-md-4 text-center pr-0">
                      <img src="${data.icon}" class="bg-dark w-100 " />
                    </div>
                    <div class="col">
                      <h6 class="mb15">${data.name}</h6>
                      <p>${data.used_for}</p>
                    </div>
                    <div class="col-md-12 mt15">
                      <p class="font-weight-bold mb10">Tags</p>
                      ${this.renderTags(data.tags)}
                    </div>
                  </div>

                `}
          >
            <div
              class="btn btn-link text-left"
              data-toggle="modal"
              data-target="#viewItemModal"
              
              onClick={() => {
                this.setState({
                  selectedItem: data,
                })
                $('.pover').popover('hide')
              }}
            >
              {data.name}
            </div>
          </td>
          <td>{data.company}</td>
          <td>{data.category.name}</td>
          <td>{data.size}</td>
          {this.state.isEditMode ? (
            <td className="w115 px-0 text-center">
              {data.manageable ? (
                <Link
                  to={{ pathname: '/update-item', state: data }}
                  className="text-dark font-weight-bold"
                >
                  <u>Edit content</u>
                </Link>
              ) : (
                '-'
              )}
            </td>
          ) : (
            ''
          )}

          {this.state.isEditMode ? (
            <td className="w95 px-0 text-center">
              <a
                href="#"
                class="text-dark"
                
                data-toggle="modal"
                data-target="#sizesModalCenter"
                onClick={() =>
                  this.setState({
                    selectedItem: data,
                    sizes: [
                      {
                        catalogue: '',
                        size: '',
                        show: true,
                      },
                    ],
                  })
                }
              >
                <u>Add sizes</u>
              </a>
            </td>
          ) : (
            ''
          )}

          {this.state.isEditMode ? (
            <td className="w70 px-0 text-center">
              {data.manageable ? (
                <a
                  href="#"
                  class="text-dark"
                  data-target="#confirmationModal"
                  data-toggle="modal"
                  onClick={() => this.setState({ selectedItem: data })}
                  
                >
                  <u>Delete</u>
                </a>
              ) : (
                '-'
              )}
            </td>
          ) : (
            ''
          )}
        </tr>
      )
    })
    return (

      <Table minWidth={`${this.state.isEditMode ? 1160 : 860}`}

        renderModal={() => {
          return (<ViewItemCard id={`preview-modal-${this.state.unique}`} onRef={ref => (this.viewItemRef = ref)} item={this.state.selectedItem} ></ViewItemCard>)
        }}

        className="infi-table h480"
        infiniteScroll infiniteHeight={410}
        infiniteDataLength={this.state.data.length} //This is important field to render the next data
        infiniteNext={this.loadMoreData.bind(this)}
        infiniteHasMore={this.state.hasMore}

        endOfMessageTitle='Nothing else to see...'
        endOfMessageMessage="Missing something? Add new items via"
        endOfMessageLinkMessage="Medical Inventory"
        endOfMessageLink='/medical-inventory'

        renderHeaders={() => {
          return (
            <TRow>
              <THead className="w150" text="Catalogue #" sortable onClick={() => this.sortBy('catalog_number')} />
              <THead className="w340" text="Name" sortable onClick={() => this.sortBy('name')} />
              <THead className="w150" text="Company" sortable onClick={() => this.sortBy('company')} />
              <THead className="w150" text="Category" sortable onClick={() => this.sortBy('category')} />
              <THead className="w100 text-center" text="Size" sortable onClick={() => this.sortBy('size')} />

              {this.state.isEditMode ? <THead className="w270" text="Edits" /> : ''}
            </TRow>
          )
        }}
        renderRows={() => {
          let rows = this.state.data.map(item => {
            return (
              <TRow>
                <TData className="w150">{item.catalog_number}</TData>
                <TData className="w340 has-action" item={item} popover onClick={() => {
                  this.setState({ selectedItem: item })
                  $(`#preview-modal-${this.state.unique}`).modal('show')
                }}>{item.name}</TData>
                <TData className="w150">{item.company ? item.company : ''}</TData>
                <TData className="w150">{item.category ? item.category.name : ''}</TData>
                <TData className="w100 text-center">{item.size}</TData>

                {this.state.isEditMode ? <TData className="w270 text-center actions">

                  <div className="d-flex justify-content-start">
                    <Button tertiary onClick={(event) => {
                      event.preventDefault()
                      this.setState({
                        selectedItem: item,
                        isCreate: true
                      }, () =>{
                        this.props.setIsCreate(this.state.selectedItem, true)
                      })
                      
                    }}>Edit content</Button>
                    <Button tertiary
                      datatarget="#sizesModalCenter"
                      datatoggle="modal"
                      onClick={() =>
                        this.setState({
                          selectedItem: item,
                          sizes: [
                            {
                              catalogue: '',
                              size: '',
                              show: true,
                            },
                          ],
                          errors: []
                        })
                      }>Add sizes</Button>
                    {item.manageable ? (
                      <Button tertiary
                        datatarget="#confirmationModal"
                        datatoggle="modal"
                        onClick={() => this.setState({ selectedItem: item })}>Delete</Button>
                    ) : null}
                  </div>


                </TData> : ''}
              </TRow>
            )
          })
          return rows
        }}
      // renderNotification={() => {
      //   return (<TableNotificationCard type={this.state.notificationType} message={this.state.notificationMessage} onRef={ref => (this.itemTableNotif = ref)} />)
      // }}

      />
    )
  }

  renderTags(data) {
    return ReactDOMServer.renderToStaticMarkup(
      data.map(data => {
        return (
          <span
            key={`tag_${data.id}`}
            className="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10"
          >
            {data.name}
          </span>
        )
      })
    )
  }

  onInventoryChange = event => {
    this.setState(
      {
        inventory: event.target.value,
        page: 1,
        data: [],
      },
      () => {
        this.makeRemoteRequest()
      }
    )
  }

  getArrayError(field, index) {
    const { errors } = this.state

    if (errors[`${field}.${index}`]) {
      return errors[`${field}.${index}`][0]
    }
    return ''
  }

  addMoreSizes() {
    const { sizes } = this.state
    sizes.push({ catalogue: '', size: '', show: true })
    this.setState({
      sizes: sizes.splice(0),
    })
  }

  addSizesSubmit() {
    const { selectedItem, sizes } = this.state

    const form = new FormData()
    sizes.map(data => {
      if (data.show) {
        form.append('catalog_number[]', data.catalogue)
        form.append('size[]', data.size)
      }
    })
    axios
      .post(`api/save-duplicate-supply/${selectedItem.id}`, form)
      .then(result => {
        if (result.data.success) {
          $('.modal').modal('hide')
          this.setState({
            successAppear: true,
            successMessage: 'Items have been successfully duplicated!',
            show: true,
            data: [...result.data.data, ...this.state.data],
            sizes: [
              {
                catalogue: '',
                size: '',
                show: true,
              },
            ],
          })

          setTimeout(() => {
            this.setState({
              successAppear: false,
            })
          }, 5000)
        } else {
          console.log(result.data.errors)
          this.setState(
            {
              errors: result.data.errors,
            },
            () => {
              console.log('errors', this.state.errors)
            }
          )
        }
      })
      .catch(error => {})
  }

  sizeRemove(index) {
    const { sizes } = this.state
    //sizes[index].show = false
    sizes.splice(index, 1)
    this.setState({
      sizes: sizes.splice(0),
    })
  }

  deleteItem() {
    const { selectedItem } = this.state
    axios
      .post(`api/items/${selectedItem.id}`, {
        _method: 'DELETE',
      })
      .then(() => {
        this.setState({
          data: this.state.data.filter(i => i.id != selectedItem.id),
          isCreate: false,
          successAppear: true,
          successMessage: 'Items have been successfully deleted!',
        })
        setTimeout(() => {
          this.setState({
            successAppear: false,
          })
        }, 5000)
      })
      .catch(error => {
        console.log(error.response)
      })
  }
 

  searchItem({ target: { value } }) {
    this.setState(
      {
        searchKeyword: value,
        page: 1,
        loading: true,
        data: [],
      },
      () => {
        this.searchData()
      }
    )
  }
  render() {
    const filter = this.state.sizes.filter(data => {
      return data.show
    })

    // const search = _.debounce(keyword => {
    //   console.log('keyword', keyword)
    //   this.searchItem()
    // }, 500)

    const sizes = filter.map((data, index) => {
      return (
        <div className="row " key={`size_item_${index}`}>
          <div className="col-xl-10 col-lg-9 col-md-8 card-body">
            <div className="row animated fadeIn">
              <div className="col-lg-8 col-y-7">
                <TextInput
                  errorMessage={this.getArrayError('catalog_number', index)}
                  label="Catalog"
                  value={data.catalogue}
                  onChange={event => {
                    const { sizes } = this.state
                    sizes[index].catalogue = event.target.value
                    this.setState({
                      sizes: sizes.splice(0),
                    })
                  }}
                />
              </div>
              <div className="col-lg-4 col-y-5">
                <TextInput
                  errorMessage={this.getArrayError('size', index) ? 'Required' : ''}
                  label="Size"
                  value={data.size}
                  onChange={event => {
                    const { sizes } = this.state
                    sizes[index].size = event.target.value
                    this.setState({
                      sizes: sizes.splice(0),
                    })
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-4 d-flex align-items-center">
            {index != 0 ? (
              <a href="#" className="cta cta-tertiary" onClick={this.sizeRemove.bind(this, index)}><i className="mdi mdi-trash-can"></i></a>
            ) : (
                ''
              )}

          </div>
        </div>
      )
    })

    return (
      <div >
        <ViewItemModal
          item={this.state.selectedItem}
          cancel={() => {
            $('viewItemModal').modal('hide')
          }}
        />

        <ConfirmationModal
          idModal="confirmationModal"
          title="Delete Item"
          message="Deleting item will remove it from the database and will have to be added back manually."
          confirmText="Delete item"
          cancelText="Cancel"
          confirm={this.deleteItem.bind(this)}
          cancel={() => {
            $('#confirmationModal').modal('hide')
          }}
        /> 
        {this.state.selectedItem ? (

          <Modal
            idModal="sizesModalCenter"
            size="modal-sm"
            title="Add sizes"
            confirmText="Add"
            cancelText="Cancel"
            confirm={(e) => {
              this.addSizesSubmit()
            }}
            cancel={(e) => {
              //fn here
            }}
          >
            <p className="modal-sub-title">Adding additional sizes for <b>{this.state.selectedItem ? this.state.selectedItem.name : ''}</b></p>
            <div className="add-size-card">
              {sizes}
              <div className="row">
                <div className="col-xl-10 col-lg-9 col-md-8 add-card" onClick={this.addMoreSizes.bind(this)}>
                  Add another size +
               </div>
              </div>

            </div>
          </Modal>
           
        ) : (
          ''
        )}  


        <div className="row page-toolbar pt16">
          <div className="col-lg-3 col-md-6 col-lg-3 col-md-6 d-flex align-items-end sub-div">
            <TextInput
              id="search_items"
              className="form-control active"
              placeholder="Start typing name, catalogue #…"
              ref={el => (this.searchKeyword = el)}
              onChange={this.searchItem.bind(this)}
              label="Search"
            />
          </div>
          <div className="col-lg-4 col-md-6 col-lg-3 col-md-6 d-flex align-items-end sub-div">
            <Picker
              onChange={this.onInventoryChange.bind(this)}
              label="Filter supplies by" >
              <option value="">All</option>
              <option value={1}>Supply</option>
              <option value={2}>Instruments</option>
              <option value={3}>Equipment</option>
            </Picker>
          </div>

        </div>

        <div className="row mb30">
          <div className="col-md-12">
            <div className="of-auto">
              {this.renderTable()}
            </div>
          </div>
        </div> 

        
      </div>
    )
  }
}

export default Items
