import React, { Component } from 'react'
import { Image } from '../commons/elements/inputs'
import { connect } from 'react-redux'
import { positioningUpdate } from '../../actions'

class Images extends Component {
  
  onMediaChange = (event, prop) => {
  
    const value = event.data

    this.props.positioningUpdate({ prop, value })
  }
  
  render() {
    const { position_image,
      pressure_map_image} = this.props
    return (
      <div className="row mb30">
        <Image 
            label={`Positioning image`}
            media_data={ {
                source:position_image
            }}
            onMediaChange={ (event) => {
              this.onMediaChange(event,'position_image')
            }}
        />
        <Image 
            label={`Pressure map`}
            media_data={ {
                source:pressure_map_image
            }}
            onMediaChange={ (event) => {
              this.onMediaChange(event,'pressure_map_image')
            }}

        />
      </div>
    )
  }
}


const mapStateToProps = ({ positioning }) => {
  const {  position_image,pressure_map_image } = positioning
  return { position_image,pressure_map_image }
}

export default connect(
  mapStateToProps,
  { positioningUpdate }
)(Images)
