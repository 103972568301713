import { SURGEON_DECREMENT, SURGEON_INCREMENT } from './types'

export const decrementSurgeon = () => {
  return dispatch =>
  dispatch({
    type: SURGEON_DECREMENT,
  })
}

export const incrementSurgeon = () => {
  return dispatch =>
  dispatch({
    type: SURGEON_INCREMENT,
  })
}
