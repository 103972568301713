import React, { Component } from 'react'

import Vimeo from '@u-wave/react-vimeo'
import error_img from '../../assets/images/error_img.png'
import $ from 'jquery'
import Modal from '../commons/modals/Modal';

export class BigPictureModal extends Component {
	/*  PARAMETERS 
		idModal
		title,
		type,
		source,
	*/

	constructor(props) {
		super(props)

		this.state = {

		}
	}

	componentDidMount() {
		const { idModal } = this.props
		$(`#${idModal ? idModal : 'bigPictureModal'}`).on('shown.bs.modal', function (e) {
			$(".modal-backdrop").last().addClass('opacity-9 z-index-2k');
		})
		$(`#${idModal ? idModal : 'bigPictureModal'}`).on('hide.bs.modal', function (e) {
			$(".modal-backdrop").last().removeClass('opacity-9 z-index-2k');
		})
	}


	renderVideo(gallery, widthOfModal) {
		let vimeoError = false;

		if (gallery.disk == 'local') {
			return (
				<video className="bg-dark w-100 align-items-center h-100" controls>
					<source src={gallery.source} type="video/mp4" />
					<source src={gallery.source} type="video/webM" />
					<source src={gallery.source} type="video/ogg" />
					Your browser does not support the video tag.
				</video>
			)
		}

		const source = gallery.source.split('/').pop()
		if (vimeoError) {
			return <img className="bg-light w-100 h-100" src={error_img} />
		} else {
			return <Vimeo video={source} height={568} className="bg-primaryblack w-100 align-items-center h-100 d-flex justify-content-center" onError={() => {
				vimeoError = false;
				this.setState({
					errorInVimeo: true
				})
			}} />
		}
	}

	renderImage(data) {
		return (
			<div>
				<p>{data.caption}</p>
				<img src={data.source} className="w-100 image-ni mh50 bg-dark" />
			</div>
		)
	}

	renderMedia(data) { 
 		if (data.type == 'image') {
			return (
				<img src={data.source} className='w-100 image-ni mh50 bg-dark' />
			)
		} else {
			let widthOfModal = ($(`#${this.props.idModal ? this.props.idModal : 'bigPictureModal'}`).clientWidth)-50
			if(data.source){
				return this.renderVideo(data, widthOfModal)
			}
		}
	}

	render() {
		const { idModal, data} = this.props
		return (
			<Modal
				className='media-item-view-modal'
				idModal={`${idModal ? idModal : 'bigPictureModal'}`}
				size="modal-lg"
				title={data.title ? data.title : '-'} 
				cancel={(e) => {
					$(`${idModal ? idModal : 'bigPictureModal'}`).modal('hide'); 
				}} 
				noFooter
			>
				{this.renderMedia(data)}
			</Modal> 

		)
	}
}

export default BigPictureModal
