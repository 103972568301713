import React, { Component } from 'react';
import LoadingManager from './LoadingManager';


export class Loading extends Component {
  constructor(props) {
    super(props)
    if (!this._id) this._id = this.makeid(15); 
  
    this.state = {
      show: false,
      progress: 0,
      type: 'default',
      id: this._id
    } 
  }
  
  componentDidMount() { 
    LoadingManager.register(this); 
  }
  componentWillUnmount() {
    LoadingManager.unregister(this);

  }

  //display temp
  showLoader(progress = 0, display) {
    if(display){
      this.setState({ 
        show: true,
        progress: progress, 
      })
    }
  }

  hideLoader() {
    setTimeout(() => {
      this.setState({
        show: false,
        progress: 0,
      })
    }, 800);
  }
   
  setLoaderProg(progress = 0) {
    this.setState({ 
      show: progress > 0,
      progress: progress,
    })
  }

  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  render() {
    const {
      show,
      progress, 
    } = this.state
    return (
      <div className={`loader-page loading ${show ? 'on':''} ${progress ? 'has-progress':''}`}>
        {
          this.state.progress > 0 &&
          <div className="progress">
            <div className="progress-bar" role="progressbar" style={{
              width: `${progress}%`}} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        }
        
      </div>
    )
  }
  _id;
}
export default Loading

export function showLoader(...args) {
  const ref = LoadingManager.getDefault();
  if (!!ref) {
    ref.showLoader(...args);
  }
}

export function hideLoader(...args) {
  const ref = LoadingManager.getDefault();
  if (!!ref) {
    ref.hideLoader(...args);
  }
}
export function setLoaderProg(...args) {
  const ref = LoadingManager.getDefault();
  if (!!ref) {
    ref.setLoaderProg(...args);
  }
}



