import React, { Component } from 'react'
import tippy from 'tippy.js'
import { Pills } from '../misc';
import ReactDOMServer from 'react-dom/server'
import { Button } from '../forms';
import axios from 'axios'
import $ from 'jquery'

export class Text extends Component {
  constructor(props) {
    super(props)

    this.state = {
      tippyInstance: [],
      unique: this.makeid(10),
      item_id: 0,
      item: false,
      ready: false,
    }

  }


  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  componentDidMount() {
    
    
    if(this.props.item){
      this.setState({
        item: this.props.item
      })
    }
      
  }

  popOverItem(item) {
    // if (!this.props.item){
    //   const { item } = this.props
    // }else{
    //   const { item } = this.state
    // }
    
    let imgContent = '', usedForContent = '', tagsContent = '';
  
    if (item.icon) {
      imgContent = `<div class="row item-img ">
                            <div class="col-md-12">
                                <div class="ar32">
                                <img src="${item.icon}" class="bg-white" />
                                </div>
                            </div>
                        </div>`;
    }
    if (item.used_for) {
      usedForContent = `<div class="row">
                                <div class="col-12">
                                    <h3 class="title">Used for</h3>
                                    <div class="description">
                                    <p>
                                        ${item.used_for}
                                    </p> 
                                    </div>
                                </div>
                                </div>`;
    }
    if (item.tags) {
      /* let tags = item.tags.map(tag => {
        return (<Pills className="tags-pill">{tag.name}</Pills>)
      }) */

      let tags = ReactDOMServer.renderToStaticMarkup(
        item.tags.map(tag => {
          if (tag.name.length) {
            return (<Pills className="tags-pill">{tag.name}</Pills>)
          }
          return null;
        })
      )
      tagsContent = `<div class="row"> 
                                <div class="col-12"> 
                                  <h4 class="sub-title">Tags</h4>
                                </div>
                                <div class="col-12 tags"> 
                                    ${tags}
                                </div>
                            </div>`;
    }

    return `<div class="popover-item-card" style="width: 300px">
              ${imgContent}
              ${usedForContent}
              ${tagsContent}
            </div>`;
  }
  getItem(){ 
    if (this.props.item_id) {
      if (!this.state.item) {
        axios.get(`api/items/${this.props.item_id}`).then((e) => {
          console.log('e.response', e.data.data)
          this.setState({
            item: e.data.data
          })
        })
      }
    }
  }
  createTippy() {
    if (this.props.item_id) {
      if (!this.state.item) {
        axios.get(`api/items/${this.props.item_id}`).then((e) => {
          console.log('e.response', e.data.data)
          this.setState({
            item: e.data.data
          })
          setTimeout(() => {
            this.setState({
              tippyInstance: tippy(`#${this.state.unique}`, {
                trigger: 'mouseenter focus',
                distance: 20,
                placement: 'right',
                arrow: true,
                boundary: "window",
                duration: [100, 100],
                theme: '',
                content: this.popOverItem(this.props.item ? this.props.item : this.state.item),
              })
            })
            $(`#${this.state.unique}`).focus()
          }, 200);
        })
      }
    }
    if (this.props.item || this.state.item) {

      if (this.props.setSelectedItem){
        this.props.setSelectedItem(this.state.item)
      }

      if (this.state.tippyInstance.length == 0) {
        this.setState({
          tippyInstance: tippy(`#${this.state.unique}`, {
            trigger: 'mouseenter focus',
            distance: 20,
            placement: 'right',
            arrow: true,
            boundary: "window",
            duration: [100, 100],
            theme: '',
            content: this.popOverItem(this.props.item ? this.props.item : this.state.item),
          })
        })
      }
    }
  }  
  render() { 
    return (<> 
      {
        this.props.link ?
          <Button className={this.props.className ? this.props.className : ''} tertiary link url={this.props.url} onClick={this.props.onClick}>{this.props.children}</Button> :

          <span id={this.state.unique} className={this.props.className ? this.props.className : ''} onMouseEnter={()=>{
            this.getItem()
          }} onMouseOver={() => {
            this.createTippy()
          }}  onClick={this.props.onClick}>
           
            {this.props.children}
          </span>
      }
    </>
    )
  }
}

export default Text
