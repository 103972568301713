import React, { Component } from 'react'
import { withRouter } from 'react-router'
import SurgeonForm from '../../components/surgeon_form'
import SurgeonPreview from '../../components/surgeon_preview'
import { connect } from 'react-redux'
import Confirmation from '../../components/Confirmation'
import Notification from '../../components/Notification'
import {
  createSurgeon,
  clearSurgeonData,
  setIsNext,
  surgeonUpdate,
} from '../../actions/SurgeonActions'
import SpecialtySection from '../../components/specialty_section'
import WholeBodyloader from '../../components/loader/WholeBodyloader'
import $ from 'jquery'
import { Button } from '../../components/commons/forms'
import {
  ConfirmationModal,
  NotificatonModal,
} from '../../components/commons/modals'
import {
  showLoader,
  setLoaderProg,
} from '../../components/loader/loading_screen'

// showLoader(percentCompleted >= 95 ? 95 : percentCompleted);
// setLoaderProg(percentCompleted >= 95 ? 95 : percentCompleted)
import axios from 'axios'
import { dataURItoBlob } from '../../utilities'
class SurgeonCreate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isNext: false,
      page: 1,
      loading: false,
      key:
        Math.random(10)
          .toString(26)
          .substring(2, 7) +
        Math.random(10)
          .toString(26)
          .substring(2, 7),
      surgeon: {
        id: '',
        firstname: '',
        lastname: '',
        specialtyList: [],
        specialties: [],
        inner_glove: {},
        outer_glove: {},
        music: 0,
        handedness: '',
        enviroment_on: '',
        title: '',
      },
      errors: [],
    }
  }
  componentWillReceiveProps(nextProps) {
    console.log('RECIEVED PROPS', nextProps)
  }
  routerWillLeave(nextLocation) {
    /*  if(nextLocation.action == "POP"){
        
      } */
  }

  componentWillMount() {
    window.scrollTo({
      top: 0,
      left: 0,
    })
  }

  componentDidMount = () => {
    /*  this.props.clearSurgeonData()
    this.props.surgeonUpdate({
      prop: 'surgeon_id',
      value: null,
    })

    this.props.surgeonUpdate({
      prop: 'is_edit',
      value: false,
    })
    //    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave);
 */
    window.scrollTo({
      top: 0,
      left: 0,
    })
    window.history.pushState({ page: 1 }, '', '#surgeon_info')

    window.onpopstate = event => {
      event.preventDefault()

      if (this.state.isNext) {
        window.history.pushState({ page: 1 }, '', '#surgeon_info')
        this.back()
      } else {
        if (this.props.router.location.pathname == '/surgeon-create') {
          this.props.router.push({
            pathname: 'surgeon',
          })
        } else {
          $('.cancel-button').click()
        }
      }
    }
  }

  saveSurgeon = () => {
    this.setState({
      loading:true,
    })
    const { surgeon } = this.state
    let {
      firstname,
      lastname,
      title,
      music,
      contact_number,
      handedness,
      inner_glove,
      outer_glove,
      environment,
      enviroment_on,
      avatar,
      id,
    } = surgeon
    let data = new FormData()
    data.append('firstname', firstname)
    data.append('lastname', lastname)
    if(contact_number)
    data.append('contact', contact_number)
    data.append('dominant_hand', handedness)
    data.append('music', music)
    data.append('title', title)
    
    if (avatar) {
      if (avatar.indexOf('base64') >= 0) {
        data.append('avatar', dataURItoBlob(avatar))
      }
    }

    /* console.log(glove_outer.id) */

    if (inner_glove.id != undefined) {
      inner_glove = inner_glove.id
    } else {
      inner_glove = '' //changed from = 0 to = ''
    }
    data.append('inner_gloves', inner_glove)

    if (outer_glove) {
      if (outer_glove.id != null) {
        outer_glove = outer_glove.id
      } else {
        outer_glove = '' //changed from = 0 to = ''
      }
    } else {
      outer_glove = '' //changed from = 0 to = ''
    }
    data.append('outer_gloves', outer_glove)
    if (enviroment_on == 'normal' || enviroment_on == 1) {
      data.append('enviroment_on', 1)
    }else{
      data.append('enviroment_on', 0)
    }

    let url = ''
    if (id && id != '') {
      url = `/api/client/surgeon/${id}`
      data.append('_method', 'PATCH')
    } else {
      url = '/api/client/surgeon'
    }

    let config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }

    axios
      .post(url, data, config)
      .then(result => {
        console.log('result', result)
        this.setState({
          isNext: true,
          surgeon: result.data.data,
          errors:[],
          loading:false,
        })
      })
      .catch(error => {
        console.log('Error', error.response.data.errors)
        console.log(error)
        console.log(error.response, 'ress')
        if (error.response && error.response.status == 422)
          this.setState({
            errors: error.response ? error.response.data.errors : [],
            loading: false,
          })
      })
  }
  surgeonSwitch(surgeon) {
    this.setState({
      surgeon,
    })
  }
  renderRightSide() {
    const { surgeon, isNext, errors } = this.state
    if (isNext)
      return (
        <SpecialtySection
          surgeon={surgeon}
          surgeonUpdate={surgeon => {
            this.surgeonSwitch(surgeon)
          }}
        />
      )
    return (
      <SurgeonForm
        isEditMode={true}
        surgeon={surgeon}
        surgeonUpdate={payload => {
          this.surgeonUpdate(payload)
        }}
        errors={errors}
      />
    )
  }
  surgeonUpdate(payload) {
    const { surgeon } = this.state
    surgeon[payload.prop] = payload.value
    this.setState({
      surgeon,
    })
  }
  back = () => {
    this.setState({
      isNext: false,
    })
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 d-block d-md-none">
            <h1 className="page-title">
              {this.state.surgeon.id && this.state.surgeon.id != ''
                ? 'Edit Surgeon'
                : 'Add new Surgeon'}
            </h1>
            <h4 className="page-sub-title">Personal Information</h4>
          </div>

          <div className="col-xl-3 col-lg-4 col-md-5 mb30">
            <div className="col-md-12 d-none d-md-block">
              <h1 className="page-title">&nbsp;</h1>
            </div>
            <SurgeonPreview surgeon={this.state.surgeon} />
          </div>
          <div className="col">
            {this.renderRightSide()}
            <div className="row mb16">
              <div className="col-xl-6 col-lg-7 col-md-12 d-flex">
                {this.state.isNext ? (
                  <Button
                    className="w270 px8"
                    primary
                    datatoggle="modal"
                    datatarget="#notificationModal"
                  >
                    Complete surgeon profile
                  </Button>
                ) : (
                  <Button
                    className={`w270 px8 ${this.state.loading ? 'disabled':''}`}
                    primary
                    onClick={() => {
                      this.saveSurgeon()
                    }}
                  >
                    {
                        this.state.loading ? <> <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          <span class="sr-only">Loading...</span>&nbsp;Loading...</> : 'Save and continue'
                    }
                    
                  </Button>
                )}

                {this.state.isNext ? (
                  <Button
                    className="w250 px8"
                    secondary
                    onClick={() => {
                      this.back()
                    }}
                  >
                    Back
                  </Button>
                ) : (
                  <Button
                    className="w150 px8  "
                    secondary
                    onClick={() => {
                      $('#cancelModal').modal('show')
                    }}
                  >
                    Cancel
                  </Button>
                )}
              </div>
            </div>
          </div>
          <ConfirmationModal
            idModal="cancelModal"
            title="Cancel"
            message="Are you sure you want to cancel?"
            confirmText="Yes"
            cancelText="No"
            confirm={() => {
              this.props.router.push('/surgeon')
              
            }}
            cancel={() => {
              $('#confirmModal').modal('hide')
            }}
          />
          <NotificatonModal
            title="Success"
            message="Surgeon successfully save."
            confirmText="Ok"
            confirm={() => {

              this.props.router.push({
                pathname: `/quicknotes/${this.state.surgeon.id}`,
                state: { surgeon: this.state.surgeon }
              }) 
            }}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {}
}

export default connect(
  mapStateToProps,
  { createSurgeon, clearSurgeonData, setIsNext, surgeonUpdate }
)(SurgeonCreate)
