import React, { Component } from 'react'
import Footer from '../../components/footer'
import '../../assets/scss/App.scss'
import '../../assets/css/app.css'
import TextInput from '../../components/commons/forms/TextInput'
import axios from 'axios'
import { getError } from '../../utilities'
import { loginUpdate, loginUser, setUser } from '../../actions'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { BASE_URL } from '../../config/constant';
import Loading, { showLoader, setLoaderProg, hideLoader } from '../../components/loader/loading_screen'
import $ from 'jquery'
class VerifyAccount extends Component {
  constructor(props) {
    super(props)

    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      loading: false,
      errors: [],
      name:''
    }
  }

  componentWillMount() {
 
    axios.defaults.baseURL = BASE_URL
  }

    componentDidMount() { 
      this.verify(this.props.params.id) 
    } 
    verify(id) {
        const data = [];
 
       
        axios
            .post(`api/verify-globaluser/${id}`, data )
            .then(result => {
                console.log(result)
                this.setState({
                    loading: false,
                })
                const token = result.data.access_token
                this.props.loginUpdate({
                    prop: 'token',
                    value: result.data.access_token,
                }) 
                this.props.loginUpdate({
                    prop: 'user',
                    value: result.data.user,
                })
                this.props.loginUpdate({
                    prop: 'password',
                    value: '',
                })

                this.setState(
                    {
                        loading: false,
                    },
                  () => {
                    setTimeout(() => {
                      this.props.router.push({
                        pathname: '/account-settings',
                      })
                    }, 4000);

                  }
              )
            })
            .catch(errors => { 
                this.setState({
                    errors: errors.response.data.errors,
                    loading: false,
                }) 
            })
    }
  render() {
    return (
      <div className="no-nav verifying">  
{/*       
        <main role="main" className="flex-shrink-0 d-flex py-0">
          <div className="container-fluid d-none">
            <div className="row row-orig h-100">
              <div className="col-md-6 py-5 bg-secondary">
                <div className="row">
                  <div className="col-md-7 offset-2">
                    <a
                      className="navbar-brand p-0"
                      href="#"
                      style={{ marginRight: '30px' }}
                    >
                      <div className="btn btn-light rounded-0">CORH</div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 py-5 bg-white d-flex animated fadeIn">
                <div className="rowrow align-self-center w-100">
                  <div className="col-md-7 offset-2">
                    <h1 className="font-weight-bold text-center mb20">
                      Welcome {this.props.params.name}!
                    </h1>
                       
                    <div className="row">
                    <div className="col-md-12 text-center">
                        <p>
                            <i className="fa fa-spinner fa-spin"></i>
                        </p>
                        <p className="fs14 m-0">
                            Please wait while your account is being verified...
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main> */}

        {/* <Footer /> */}
      </div>
    )
  }
}
 

const mapStateToProps = ({ auth }) => {
    const { email, password, loading, error } = auth

    return { email, password, loading, error }
}

export default connect(
    mapStateToProps,
    { loginUpdate, loginUser }
)(VerifyAccount)