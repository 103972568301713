export const getArrayError = (errors, field, index) => {
  if (errors[`${field}.${index}`]) {
    return errors[`${field}.${index}`][0]
  }
  return ''
}
  
export const getError = (errors, field) => { 
  if (errors[`${field}`]) {
    return errors[`${field}`][0]
  } 
  return ''
}

export const getElementError = (errors, prefix, index, field) => {
  //console.log('errrrr',errors,prefix,index,field)

  if(!prefix){
    return getArrayError(errors,field,index)
  }
  if(errors[`${prefix}.${index}.${field}`]){
    return errors[`${prefix}.${index}.${field}`][0]
  }

  return ''
}

export const getSubElementError = (errors, prefix, index, field, subIndex ) => {
 
  if(errors[`${prefix}.${index}.${field}.${subIndex}`]){
    console.log('val', errors[`${prefix}.${index}.${field}.${subIndex}`][0])
    return errors[`${prefix}.${index}.${field}.${subIndex}`][0]
  }

  return ''
}