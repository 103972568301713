import React, { Component } from 'react'
import { connect } from 'react-redux'
import { loginUpdate, loginUser, setUser } from '../../actions'
import axios from 'axios'
import TextInput from '../../components/commons/forms/TextInput'
import { Link } from 'react-router'
import $ from 'jquery'
import { BASE_URL } from '../../config/constant';
import logo from '../../assets/images/logos/logo-mono-reverse.png'
import Button from '../../components/commons/forms/Button';

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: '',
      email: '',
      loading: false,
    }
  }

  componentWillMount() {
    
    axios.defaults.baseURL = BASE_URL
  }

  componentDidUpdate = () => {
   
  }

  componentDidMount() {
    const { location } = this.props

    if (location) {
      if (location.state) {
        if (location.state.email) {
          this.props.loginUpdate({
            prop: 'email',
            value: location.state.email,
          })
        }
      }
    }
    $('.line-form-group-v2 input').blur((e)=>{
      if (!$(e.currentTarget).val()) {
          $(e.currentTarget).removeClass('has-val')
      } else {
          $(e.currentTarget).addClass('has-val')
      }
  })
  $('.line-form-group-v2 input').blur()
  }

  onSubmit = () => {
    this.setState({
      loading: true,
      error: '',
    })
    const { email, password } = this.props
    const data = {
      login: email,
      password,
  
    }
    axios
      .post('api/client/login', data)
      .then(result => {
        console.log(result)

        const token = result.data.access_token
        this.props.loginUpdate({
          prop: 'token',
          value: result.data.access_token,
        })
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

        axios
          .get('api/client/me')
          .then(result => {
            console.log('result.data.data', result.data.data)
            if (!result.data.data.premium){
              window.localStorage.removeItem('darkmode')
            }
            this.props.loginUpdate({
              prop: 'user',
              value: result.data.data,
            })
            this.props.loginUpdate({
              prop: 'password',
              value: '',
            })

            this.setState(
              {
                loading: false,
              },
              () => {
                this.props.router.push({
                  pathname: '/lounge',
                })
              }
            )
          })
          .catch(errors => {
            console.log(errors)
            this.setState({
              error: 'Something went wrong',
              loading: false,
            })
          })
      })
      .catch(errors => {
        this.setState({
          error: 'These credentials do not match our records.',
          loading: false,
        })
      })
  }
  
  render() {
    return (
      <main role="main" className="flex-shrink-0 d-flex light-theme login-page">
        <div className="container align-self-center">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-6 col-md-7 ">
              <div className="card shadow mb24">
                <div className="card-body p30 ">
                  <div className="font-weight-bold d-flex justify-content-center mb30">
                    <img src={logo} className="logo" />
                  </div>

                  <div className="row">
                    <div className="col-md-12 mb40">
                      <p className="welcome sub-title">Welcome</p>
                      <small className="small-label">Please sign in</small>
                    </div>
                  </div>

                  <div className="row mb20">
                    <div className="col-md-12">
                      <div className="input-holder">
                        <TextInput
                          autoComplete="off"
                          icon='mdi mdi-email'
                          label={'Email'}
                          // value={this.props.email}
                          errorMessage={this.state.error}
                          containerClass={`mb40 text-left`}
                          onValueChange={event => {
                            this.props.loginUpdate({
                              prop: 'email',
                              value: event.target.value,
                            })
                          }}
                          onKeyPress={event => {
                            if (event.key == 'Enter') this.onSubmit()
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mb40">
                    <div className="col-md-12"> 
                      <div className="input-holder mb10">
                        <TextInput
                          icon='mdi mdi-lock'
                          label={'Password'}
                          // value={this.props.username}
                          errorMessage={this.props.errors}
                          containerClass={`mb0 text-left`}
                          inputType={'password'}
                          onValueChange={event => {
                            this.props.loginUpdate({
                              prop: 'password',
                              value: event.target.value,
                            })
                          }}
                          onKeyPress={event => {
                            if (event.key == 'Enter') this.onSubmit()
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 d-flex justify-content-end">
                      <Button tertiary link url={{
                        pathname: `/reset-password`,
                      }} className="link"> Forgotten password?
                        </Button>
                    </div>
                  </div>


                  <div className="mb30">
                    <Button primary onClick={this.onSubmit.bind(this)} disabled={this.state.loading} className="submit-btn">{this.state.loading ? 'Logging you in':'Login'}</Button>
                  </div>

                  <div className="mb10 d-flex justify-content-center desc">
                    Not a member yet? <Link to={{ pathname: `/register` }} className="ml6 sign-up-today">
                      Sign up today
                    </Link>
                  </div>

                </div>
              </div>
           
                      
                
              <div className="w-100 mb32 d-flex justify-content-center align-items-center">
                <p className="inline-paragraph text-white text-center"> 
                    By logging in you are accepting our  
                      <a href="https://www.corhsystem.com/terms" target="_blank" className="text-success"> Terms of Service</a> & 
                      <a href="https://www.corhsystem.com/privacy-policy" target="_blank" className="text-success"> Privacy Policy</a>. 
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  const { email, password, loading, error } = auth

  return { email, password, loading, error }
}

export default connect(
  mapStateToProps,
  { loginUpdate, loginUser }
)(Login)
