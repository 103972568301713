import arrayMove from 'array-move'
import axios from 'axios'
import $ from 'jquery'
import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import {
  getQuicknotesCategories,
  getSurgeonQuicknotes,
  saveQuicknotesItemList,
  updateQuicknotesList,
} from '../../actions/QuicknotesActions'
import { getSurgeon } from '../../actions/SurgeonActions'
import { Button } from '../../components/commons/forms'
import AddNewSection from '../../components/commons/media_elements/AddNewSection'
import MediaEditor from '../../components/commons/media_elements/MediaEditor'
import MediaElement from '../../components/commons/media_elements/MediaElement'
import StandardEditor from '../../components/commons/media_elements/StandardEditor'
import StandardElement from '../../components/commons/media_elements/StandardElement'
import TextEditor from '../../components/commons/media_elements/TextEditor'
import TextElement from '../../components/commons/media_elements/TextElement'
import EditMenu from '../../components/commons/misc/EditMenu'
import { ConfirmationModal } from '../../components/commons/modals'
import Notifier from '../../components/commons/notifier/Notifier'
import SidePanel from '../../components/commons/panel/SidePanel'
import LoadFromModal from '../../components/modals/LoadFromModal'
import QuicknotesCategories from '../../components/QuicknotesCategories'
import QuicknotesItemList from '../../components/QuicknotesItemList'
import NotesSkeleton from '../../components/skeletons/NotesSkeleton'
import { Event } from '../../utilities/analytics'

export class SurgeonQuicknotes extends Component {
  constructor(props) {
    super(props)

    this.state = {
      quicknotes: '',
      edit: false,
      qnlist: [],
      change: false,
      preview: false,
      loadFrom: false,
      loading: 0,
      progressLoading: 0,
      progress_loading: 0,
      errors: [],
      showNotification: false,
      notificationMessage: '',
      key: this.makeid(10),
      progressMedia: 0,
      progressData: [],
    }

    this.elementsRef = []

    const SortableItem = SortableElement(({ quicknotes, index2, key }) => {
      console.log('index2,key', index2, key)
      const index = index2
      switch (quicknotes.type) {
        case 'text':
          return (
            <li>
              <TextEditor
                elementRef={e => {
                  this.elementsRef[index2] = e
                }}
                data={{
                  title: quicknotes.title ? quicknotes.title : '',
                  content: quicknotes.contents[0].content,
                }}
                validation={{
                  errors: this.state.errors,
                  prefix: 'elements',
                  index: index,
                  field: 'title',
                }}
                key={`text-editor-sq-${index2}`}
                mentions={this.getMentions()}
                onTitleChange={event => {
                  this.titleChange(event, index)
                }}
                textContentChange={event => {
                  this.textContentChange(event, index)
                }}
                onDelete={event => this.deleteElement(event, index)}
                onDuplicate={event => this.duplicateElement(event, index)}
              />
            </li>
          )
          break
        case 'media':
          return (
            <li>
              <MediaEditor
                elementRef={e => {
                  this.elementsRef[index2] = e
                }}
                data={{
                  title: quicknotes.title ? quicknotes.title : '',
                  medias: quicknotes.contents,
                }}
                mentions={this.getMentions()}
                validation={{
                  errors: this.state.errors,
                  prefix: 'elements',
                  index: index,
                  field: 'title',
                }}
                key={index2}
                onTitleChange={event => {
                  this.titleChange(event, index)
                }}
                onContentTitleChange={(event, i) => {
                  this.onContentTitleChange(event, i, index)
                }}
                onContentCaptionChange={(event, i) => {
                  this.onContentCaptionChange(event, i, index)
                }}
                onContentMediaChange={(event, i) => {
                  this.onContentMediaChange(event, i, index)
                }}
                onContentThumbnailChange={(event, i) => {
                  this.onContentThumbnailChange(event, i, index)
                }}
                onContentSortChange={(contents, i) => {
                  this.onContentSortChange(contents, index)
                }}
                addNewMedia={event => this.addNewMedia(event, index)}
                onDuplicate={event => this.duplicateElement(event, index)}
                onDelete={event => this.deleteElement(event, index)}
                onDeleteMediaElement={this.deleteMediaElement}
                progressMedia={() => {
                  console.log('progressMediaprogressMedia')
                  this.setState({
                    progressData: [...this.state.progressData, true],
                  })
                }}
                progressComplete={() => {
                  this.setState({
                    progressData: [...this.state.progressData, true],
                  })
                }}
              />
            </li>
          )
          break
        case 'standard':
          return (
            <li>
              <StandardEditor
                elementRef={e => {
                  this.elementsRef[index2] = e
                }}
                progressMedia={() => {
                  this.setState({
                    progressData: [...this.state.progressData, true],
                  })
                }}
                progressComplete={() => {
                  this.setState({
                    progressData: [...this.state.progressData, true],
                  })
                }}
                mentions={this.getMentions()}
                data={{
                  title: quicknotes.title ? quicknotes.title : '',
                  text: quicknotes.contents[0].elements[0].content,
                  media: {
                    thumbnail: quicknotes.contents[1].elements[0]
                      ? quicknotes.contents[1].elements[0].gallery.thumbnail
                      : '',
                    source:
                      quicknotes.contents[1].elements[0] &&
                      quicknotes.contents[1].elements[0].gallery.source
                        ? quicknotes.contents[1].elements[0].gallery.source
                        : '',
                  },
                }}
                validation={{
                  errors: this.state.errors,
                  prefix: 'elements',
                  index: index,
                  field: 'title',
                }}
                onTitleChange={event => {
                  this.titleChange(event, index)
                }}
                onStandardTextChange={event => {
                  this.standardTextChange(event, index)
                }}
                thumbnailChange={thumbnail => {
                  this.standardThumbnailChange(thumbnail, index)
                }}
                onMediaChange={event => {
                  this.mediaStandardChange(event, index)
                }}
                onDelete={event => this.deleteElement(event, index)}
                onDuplicate={event => this.duplicateElement(event, index)}
              />
            </li>
          )
          break
        default:
          break
      }
    })

    this.SortableList = SortableContainer(({ items }) => {
      return (
        <ul className="list-unstyled">
          {items.map((value, index) => (
            <SortableItem
              key={`item-${index}`}
              index={index}
              index2={index}
              quicknotes={value}
            />
          ))}
        </ul>
      )
    })

    this.toggleShow = this.toggleShow.bind(this)
  }

  getMentions = () => {
    let items = []
    this.props.item_list.map(list => {
      list.details.map(item => {
        items.push(item.item)
      })
    })

    items = items.splice(0)
    return items
  }

  makeid(length) {
    var result = ''
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  toggleShow(val, message = '') {
    this.setState({
      showNotification: val,
      notificationMessage: message,
    })
  }

  componentDidMount = () => {
    this.props.getQuicknotesCategories()
    this.props.router.setRouteLeaveHook(
      this.props.route,
      this.routerWillLeave.bind(this)
    )

    let id = 9
    let draft = 0
    if (
      this.props.location &&
      this.props.location.query &&
      this.props.location.query.quicknotes_data_id
    ) {
      id = this.props.location.query.quicknotes_data_id
      draft = 1
      this.setState({
        edit: true,
      })
    }
    this.props.getSurgeonQuicknotes(id, draft, () => {
      this.setState({
        qnlist: this.props.quicknotes_list,
      })
    })
    window.onbeforeunload = this.handleOnBeforeUnload

    this.setState({
      progress_loading: 1,
    })

    if (this.props.location.state) {
      if (this.props.location.state.edit) {
        /*  this.setState({
          edit: true,
        }) */
      }
    } else {
      this.props.getQuicknotesCategories(() => {
        if (this.props.categories) {
          if (this.props.categories.length) {
            if (!this.props.location.state) this.props.getSurgeonQuicknotes(9)
          }
        }
      })
    }

    this.props.getSurgeon(this.props.params.id)
  }

  routerWillLeave(nextLocation) {
    if (this.state.edit)
      return 'Your work is not saved! Are you sure you want to leave?'
  }
  handleOnBeforeUnload = e => {
    if (this.state.edit) {
      const message = 'Your work is not saved! Are you sure you want to leave?'
      e.returnValue = message
      return message
    }
  }

  componentDidUpdate = () => {
    this.props.categories.map(category => {
      if (category.id == this.props.quicknotes_id) {
        if (this.state.quicknotes != category.name) {
          this.setState({
            quicknotes: category.name,
          })
        }
      }
    })
  }

  componentWillReceiveProps = nextProps => {
    // if (
    //   _.difference(nextProps.quicknotes_list, this.props.quicknotes_list)
    //     .length > 0
    // ) {
    //   this.setState({
    //     qnlist: nextProps.quicknotes_list,
    //     key: this.makeid(10),
    //   })
    // }
    // if (nextProps.surgeon.id != this.props.surgeon.id) {
    //   this.props.getSurgeonQuicknotes(this.props.quicknotes_id)
    //   this.props.getQuicknotesCategories()
    //   this.setState({
    //     qnlist: nextProps.quicknotes_list,
    //   })
    // }
  }

  setNewState = (states) =>{
    this.setState(states)
  }

  newSectionMenu = () => {
    return (
      <div
        className="row"
        ref={e => {
          this.newsection = e
        }}
      >
        <div className="col-12">
          <AddNewSection
            addTextOnly={() => {
              this.newTextSection()
            }}
            addMediaGallery={() => {
              this.newMediaSection()
            }}
            addTextAndMedia={() => {
              this.newStandardSection()
            }}
          />
        </div>
      </div>
    )
  }

  newTextSection = () => {
    const { qnlist } = this.state
    let textsection = {
      title: null,
      type: 'text',
      contents: [
        {
          content: null,
        },
      ],
    }
    qnlist.push(textsection)
    this.setState({
      qnlist,
    })
  }

  deleteElement = (event, index) => {
    const { qnlist } = this.state
    event.preventDefault()
    qnlist.splice(index, 1)
    this.setState({
      errors: [],
      qnlist: qnlist.splice(0),
    })
  }

  deleteMediaElement = (event, index, subIndex) => {
    event.preventDefault()
    const { qnlist } = this.state

    qnlist[index].contents.splice(subIndex, 1)

    this.setState({
      errors: [],
      qnlist: qnlist.splice(0),
    })
  }

  duplicateElement = (event, index) => {
    const { qnlist } = this.state
    event.preventDefault()
    qnlist.push(_.cloneDeep(qnlist[index]))
    this.setState({ qnlist: qnlist }, () => {
      this.elementsRef[this.state.qnlist.length - 1].scrollIntoView({
        behavior: 'smooth',
      })
    })
  }

  newMediaSection = () => {
    const { qnlist } = this.state
    let mediasection = {
      title: '',
      type: 'media',
      contents: [
        {
          title: '',
          caption: '',
          gallery: {},
        },
      ],
    }
    qnlist.push(mediasection)
    this.setState({
      qnlist,
    })
  }

  newStandardSection = () => {
    const { qnlist } = this.state
    let standardsection = {
      title: '',
      type: 'standard',
      contents: [
        {
          type: 'text',
          elements: [
            {
              content: '',
              standard: 1,
            },
          ],
        },
        {
          type: 'media',
          elements: [
            {
              title: null,
              caption: null,
              gallery: {},
            },
          ],
        },
      ],
    }

    qnlist.push(standardsection)
    this.setState({
      qnlist,
    })
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ qnlist }) => ({
      errors: [],
      qnlist: arrayMove(qnlist, oldIndex, newIndex),
    }))
  }

  onContentSortChange = (contents, index) => {
    const { qnlist } = this.state
    qnlist[index].contents = contents

    this.setState({
      errors: [],
      qnlist: qnlist.splice(0),
    })
  }

  titleChange = (event, index) => {
    const { qnlist } = this.state
    qnlist[index] = { ...qnlist[index], title: event.target.value }
    this.setState({
      qnlist: qnlist,
    })
  }

  textContentChange = (event, index) => {
    const { qnlist } = this.state
    qnlist[index].contents[0].content = event.editor.getData()
    const copy = _.cloneDeep(qnlist.splice(0))
    this.setState({
      qnlist: copy,
    })
  }

  standardTextChange = (event, index) => {
    const { qnlist } = this.state
    qnlist[index].contents[0].elements[0].content = event.editor.getData()

    this.setState({
      qnlist: qnlist.splice(0),
    })
  }

  onUpdateQuicknotes = draft => {
    // this.setState({ loading: 1 })
    if (draft == 0) {
      Event('Quicknotes', 'Update', this.state.quicknotes)
    }
    const { qnlist } = this.state
    const { surgeon_quicknotes_id, item_list } = this.props

    this.props.saveQuicknotesItemList(item_list, draft)

    const data = new FormData()
    data.append('surgeon_quick_note_id', surgeon_quicknotes_id)
    data.append('draft', draft)
    qnlist.map((quicknotes, index) => {
      data.append(
        `elements[${index}][title]`,
        quicknotes.title ? quicknotes.title : ''
      )
      data.append(`elements[${index}][type]`, quicknotes.type)
      data.append('draft', draft)
      switch (quicknotes.type) {
        case 'text':
          data.append(
            `elements[${index}][values]`,
            quicknotes.contents[0].content ? quicknotes.contents[0].content : ''
          )
          break
        case 'standard':
          data.append(`elements[${index}][standard-type][]`, 'text')
          data.append(
            `elements[${index}][text-value]`,
            quicknotes.contents[0].elements[0].content
              ? quicknotes.contents[0].elements[0].content
              : ''
          )
          data.append(`elements[${index}][standard-type][]`, 'media')

          data.append(`elements[${index}][media-id]`, 0)
          data.append(`elements[${index}][media-updated]`, 1)

          data.append(
            `elements[${index}][media-value]`,
            quicknotes.contents[1].elements[0].gallery.source
              ? quicknotes.contents[1].elements[0].gallery.source
              : ''
          )
          data.append(
            `elements[${index}][media-original]`,
            quicknotes.contents[1].elements[0].gallery.originSource
              ? quicknotes.contents[1].elements[0].gallery.originSource
              : ''
          )
          data.append(
            `elements[${index}][media-type]`,
            quicknotes.contents[1].elements[0].gallery.type
          )

          break
        case 'media':
          quicknotes.contents.map((content, indx) => {
            data.append(
              `elements[${index}][media-text][${indx}]`,
              content.gallery.caption ? content.gallery.caption : ''
            )
            data.append(
              `elements[${index}][media-title][${indx}]`,
              content.gallery.title ? content.gallery.title : ''
            )
            data.append(
              `elements[${index}][media-type][${indx}]`,
              content.gallery.type ? content.gallery.type : ''
            )

            data.append(`elements[${index}][media-id][${indx}]`, 0)
            data.append(`elements[${index}][media-updated][${indx}]`, 0)
            data.append(
              `elements[${index}][media-value][${indx}]`,
              content.gallery.source ? content.gallery.source : ''
            )
            data.append(
              `elements[${index}][media-original][${indx}]`,
              content.gallery.originSource ? content.gallery.originSource : ''
            )
          })

          break
        default:
          break
      }
    })
    let config = {
      headers: {
        'content-type': 'multipart/form-data',
      },

      onUploadProgress: progressEvent => {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        // showLoader(percentCompleted >= 95 ? 95 : percentCompleted)
        // setLoaderProg(percentCompleted >= 95 ? 95 : percentCompleted)
      },
    }

    axios
      .post(`api/quicknotes-element`, data, config)
      .then(result => {
        if (!draft) {
          this.setState(
            {
              edit: false,
              preview: false,
              loadFrom: false,
              progressLoading: 0,
              showNotification: true,
              loading: 0,
              notificationMessage: 'Quicknotes updated!',
            },
            () => {
              this.props.updateQuicknotesList(result.data)
              this.refNotifier.showThis()
              // hideLoader()
            }
          )
        } else {
          this.setState({
            preview: false,
            loadFrom: false,
            progressLoading: 0,
            loading: 0,
            showNotification: true,
            notificationMessage: 'Quicknotes draft has been saved!',
          })
          // hideLoader()
          this.refNotifier.showThis()
        }
      })
      .catch(errors => {
        if (errors.response.status != 422) {
          // hideLoader()
          this.setState({
            loading: 0,
          })
          return
        }

        this.setState(
          {
            errors: errors.response.data.errors
              ? errors.response.data.errors
              : [],
            loading: 0,
          },
          () => {
            // hideLoader()
            if ($('.is-invalid').offset()) {
              let targetOffset = $('.is-invalid').offset().top
              $('html, body').animate({ scrollTop: targetOffset }, 100)
            }
          }
        )
      })
  }

  mediaStandardChange = (event, index) => {
    const { qnlist } = this.state
    qnlist[index].contents[1].elements[0].gallery.source = event.data
    qnlist[index].contents[1].elements[0].gallery.type = event.type
    this.setState({
      qnlist: qnlist.splice(0),
    })
  }

  standardThumbnailChange = (thumbnail, index) => {
    console.log(thumbnail)
    const { qnlist } = this.state
    qnlist[index].contents[1].elements[0].gallery.thumbnail = thumbnail
    this.setState({
      qnlist: qnlist.splice(0),
    })
  }

  addNewMedia = (event, index) => {
    event.preventDefault()
    const { qnlist } = this.state
    qnlist[index].contents.push({
      gallery: {
        show: true,
        thumbnail: '',
        source: '',
        caption: '',
        title: '',
        id: 0,
      },
    })
    this.setState({
      qnlist: qnlist.splice(0),
    })
  }

  onContentTitleChange = (event, item_index, index) => {
    const { qnlist } = this.state
    qnlist[index].contents[item_index].gallery.title = event.target.value
    this.setState({
      qnlist: qnlist.splice(0),
    })
  }

  onContentCaptionChange = (event, item_index, index) => {
    const { qnlist } = this.state
    qnlist[index].contents[item_index].gallery.caption = event.editor.getData()
    this.setState({
      qnlist: qnlist.splice(0),
    })
  }

  onContentMediaChange = (event, item_index, index) => {
    const { qnlist } = this.state
    qnlist[index].contents[item_index].gallery.source = event.data
    qnlist[index].contents[item_index].gallery.type = event.type
    if (event.type == 'image')
      qnlist[index].contents[item_index].gallery.thumbnail = event.data
    this.setState({
      qnlist: qnlist.splice(0),
    })
  }

  onContentThumbnailChange = (event, item_index, index) => {
    const { qnlist } = this.state
    qnlist[index].contents[item_index].gallery.thumbnail = event
    this.setState({
      qnlist: qnlist.splice(0),
    })
  }

  render() {
    const { surgeon, quicknotes_list, video_progress } = this.props

    const progressList = $('.pie-wrapper').length
    console.log('progressList', progressList, video_progress, surgeon)

    let quicknotesitems = quicknotes_list.map((quicknotes, index) => {
      if (quicknotes.type == 'text') {
        return (
          <TextElement
            data={quicknotes}
            className="mb20"
            key={`surgeon_quick_text_$`}
          />
        ) //<QuicknotesText quicknotes={quicknotes} />
      } else if (quicknotes.type == 'media') {
        return (
          <MediaElement
            data={quicknotes}
            className="mb20"
            key={`surgeon_quick_media_$`}
          />
        )
      } else if (quicknotes.type == 'standard') {
        return (
          <StandardElement
            quicknotes={quicknotes}
            className="mb20"
            key={`surgeon_quick_standard_$`}
          />
        )
      }
    })
    const { qnlist } = this.state
    if (quicknotes_list.length > 0 && qnlist.length == 0) {
      this.setState({
        qnlist: quicknotes_list,
      })
    }

    let quicknotesedit = (
      <this.SortableList
        items={qnlist}
        onSortEnd={this.onSortEnd}
        distance={1}
        useDragHandle
        useWindowAsScrollContainer={true}
      />
    )

    return (
      <div className="container pt-0 mb30 pb0">
        <SidePanel surgeon={surgeon}></SidePanel>
        <ConfirmationModal
          title="Update Elements"
          message="This updates the elements."
          confirmText="Yes"
          cancelText="Cancel"
          confirm={this.onUpdateQuicknotes.bind(this, 0)}
          idModal="update-confirmation"
          cancel={() => {
            $('.modal').modal('hide')
          }}
        />

        <ConfirmationModal
          title="Save to draft"
          message="Do you want to save draft?"
          confirmText="Yes"
          cancelText="Cancel"
          confirm={this.onUpdateQuicknotes.bind(this, 1)}
          idModal="save-to-draft"
          cancel={() => {
            $('.modal').modal('hide')
          }}
        />
        <div className="row">
          {/* <div className="col-lg-3 col-md-4">
            {surgeon.id ? <SurgeonInfo /> : ''}

            {surgeon.id ? <SurgeonSpecialties /> : ''}
          </div> */}
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <h1 className="page-title">
                  {surgeon.firstname
                    ? `${surgeon.firstname} ${surgeon.lastname}`
                    : 'Surgeon'}{' '}
                  quicknotes
                </h1>
              </div>

              <QuicknotesCategories
                edit={this.state.edit}
                preview={this.state.preview}
                closeNotif={() => {
                  this.refNotifier.hideThis()
                }}
              />

              <div
                className={`col-md-12 mt32 mx-auto d-flex justify-content-between align-items-center page-description`}
              >
                <h2 className={`bold `}>{this.state.quicknotes} </h2>
                {this.state.edit || this.state.preview ? null : (
                  <Button
                    tertiary
                    className={` `}
                    onClick={() => {
                      if (!this.state.preview) {
                        this.props.getSurgeonQuicknotes(
                          this.props.quicknotes_id,
                          1
                        )
                      }

                      this.setState({
                        edit: true,
                        loadFrom: false,
                        qnlist: [],
                        errors: [],
                      })
                    }}
                  >
                    Edit {this.state.quicknotes}
                  </Button>
                )}
              </div>

              <div className={`col-md-12 mt0 `}>
                <Notifier
                  message={this.state.notificationMessage}
                  onRef={ref => (this.refNotifier = ref)}
                  type="success"
                  styleClass="mb30 mt0"
                  hideThisNotif={() => {
                    this.refNotifier.hideThis()
                  }}
                />

                {this.state.preview ? (
                  <Notifier
                    show={this.state.preview}
                    onRef={ref => (this.refNotifier5 = ref)}
                    type="success"
                    styleClass="mb30 mt0"
                    hideThisNotif={() => {
                      this.refNotifier5.hideThis()
                      this.setState({
                        edit: true,
                        preview: false,
                      })
                    }}
                  >
                    <p className="m-0">
                      <b>Preview mode</b> - If you like what you see, you can{' '}
                      <u
                        className="font-weight-bold c-pointer"
                        data-target="#update-confirmation"
                        data-toggle="modal"
                      >
                        Update Notes
                      </u>{' '}
                      or make edits by{' '}
                      <u
                        className="font-weight-bold c-pointer"
                        onClick={() => {
                          this.setState({
                            edit: true,
                            preview: false,
                          })
                        }}
                      >
                        Closing preview
                      </u>
                    </p>
                  </Notifier>
                ) : null}

                {this.state.edit ? (
                  <div className={`row `}>
                    <div className="col-md-12 text-right animated fadeIn">
                      <EditMenu>
                        <li>
                          <Button
                            primary
                            onClick={e => {
                              e.preventDefault()
                              this.onUpdateQuicknotes(0)
                            }}
                            disabled={progressList}
                          >
                            Update
                          </Button>
                        </li>
                        <li>
                          <Button
                            tertiary
                            onClick={e => {
                              e.preventDefault()
                              $('#save-to-draft').modal('show')
                            }}
                            disabled={progressList}
                          >
                            Save Draft
                          </Button>
                        </li>
                        <li>
                          <Button
                            tertiary
                            onClick={e => {
                              $('#loadFromModalCenter').modal('show')
                            }}
                            disabled={progressList}
                          >
                            Copy from...
                          </Button>
                        </li>
                        <li>
                          <Button
                            tertiary
                            onClick={() => {
                              this.setState(
                                {
                                  preview: true,
                                  edit: false,
                                },
                                () => {
                                  this.props.updateQuicknotesList(
                                    this.state.qnlist
                                  )
                                }
                              )
                            }}
                            disabled={progressList}
                          >
                            Preview
                          </Button>
                        </li>
                        <li className="has-line">
                          <Button
                            tertiary
                            onClick={() => {
                              this.setState(
                                {
                                  edit: false,
                                  qnlist: [],
                                  preview: false,
                                },
                                () =>
                                  this.props.getSurgeonQuicknotes(
                                    this.props.quicknotes_id
                                  )
                              )
                            }}
                            disabled={progressList}
                          >
                            Cancel
                          </Button>
                        </li>
                      </EditMenu>

                      {this.state.loadFrom ? (
                        <Notifier
                          show={this.state.loadFrom}
                          message="Copy from success"
                          onRef={ref => (this.refNotifier4 = ref)}
                          type="success"
                          styleClass="mb30 mt0"
                          hideThisNotif={() => {
                            this.refNotifier4.hideThis()
                            this.setState({
                              edit: true,
                              loadFrom: false,
                            })
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                ) : null}
                <div className={``} id="itemListAccordion">
                  {this.props.loading ? (
                    ''
                  ) : (
                    <QuicknotesItemList
                      edit={this.state.edit}
                      key={this.state.key}
                      setNewState={this.setNewState}
                    />
                  )}
                  {this.state.edit ? (
                    quicknotesedit
                  ) : this.props.loading ? (
                    <NotesSkeleton />
                  ) : (
                    quicknotesitems
                  )}
                  {this.state.edit ? this.newSectionMenu() : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <LoadFromModal
          except={surgeon.id}
          loadFrom={() => {
            this.setState({
              loadFrom: true,
            })
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    surgeon: state.surgeon.surgeon,
    quicknotes_id: state.quicknotes.quicknotes_id,
    surgeon_quicknotes_id: state.quicknotes.surgeon_quicknotes_id,
    quicknotes_list: state.quicknotes.quicknotes_list,
    item_list: state.quicknotes.item_list,
    categories: state.quicknotes.categories,
    draft_count: state.quicknotes.draft_count,
    loading: state.quicknotes.loading,
    video_progress: state.video.progress,
  }
}

export default connect(mapStateToProps, {
  getSurgeon,
  getQuicknotesCategories,
  getSurgeonQuicknotes,
  updateQuicknotesList,
  saveQuicknotesItemList,
})(SurgeonQuicknotes)
