import React, { Component } from 'react'
import img from '../../assets/images/540x360.png'
import emma from '../../assets/images/emma.png'
import body_img from '../../assets/images/body-area.png'
import { Pills, ItemPopover } from '../commons/misc'
import { Button } from '../commons/forms'
import tippy from 'tippy.js'
import $ from 'jquery'
import Collapsible from '../commons/panel/Collapsible';
import DataCard from '../commons/cards/DataCard';
import { Table, THead, TData, TRow } from '../commons/table';
import ImageMapper from 'react-image-mapper';
import Text from '../commons/texts/Text';

export class Data extends Component {

    constructor(props) {
        super(props)
        
        this.state = {
            hoveredItem: [],
            map: {
                name: "body-map",
                areas: [
                    { name: '1', shape: "poly", coords: [124, 7, 132, 8, 138, 12, 145, 19, 148, 26, 150, 35, 150, 44, 147, 55, 141, 63, 133, 67, 124, 68, 114, 66, 106, 62, 101, 55, 98, 46, 98, 38, 99, 29, 102, 21, 106, 15, 115, 8], fillColor: "#7674d6", strokeColor: "rgb(255, 0, 0)", lineWidth: 0},
                    { name: '2', shape: "poly", coords: [110, 66, 123, 69, 138, 67, 145, 76, 163, 85, 163, 145, 84, 145, 84, 85, 103, 75],  fillColor: "#7674d6", strokeColor: "rgb(255, 0, 0)",  lineWidth: 0 },
                    { name: '3', shape: "poly", coords: [84, 85, 83, 145, 57, 144, 56, 111, 61, 96, 71, 86],  fillColor: "#7674d6", strokeColor: "rgb(255, 0, 0)",  lineWidth: 0 },
                    { name: '4', shape: "poly", coords: [164, 85, 164, 145, 191, 145, 191, 108, 187, 94, 177, 85],  fillColor: "#7674d6", strokeColor: "rgb(255, 0, 0)",  lineWidth: 0 },
                    { name: '5', shape: "poly", coords: [82, 146, 166, 146, 165, 184, 171, 231, 76, 231, 84, 184],  fillColor: "#7674d6", strokeColor: "rgb(255, 0, 0)",  lineWidth: 0 },
                    { name: '6', shape: "poly", coords: [76, 232, 171, 232, 171, 270, 166, 295, 129, 295, 127, 271, 121, 271, 119, 294, 83, 294, 77, 270],  fillColor: "#7674d6", strokeColor: "rgb(255, 0, 0)",  lineWidth: 0 },
                    { name: '7', shape: "poly", coords: [83, 295, 119, 295, 120, 333, 117, 345, 119, 382, 115, 398, 118, 416, 96, 416, 86, 378, 88, 341, 89, 317],  fillColor: "#7674d6", strokeColor: "rgb(255, 0, 0)",  lineWidth: 0 },
                    { name: '8', shape: "poly", coords: [128, 295, 166, 295, 158, 317, 160, 334, 160, 349, 162, 380, 152, 416, 130, 416, 133, 396, 129, 382, 130, 345, 127, 331],  fillColor: "#7674d6", strokeColor: "rgb(255, 0, 0)",  lineWidth: 0 },
                    { name: '9', shape: "poly", coords: [56, 145, 81, 145, 72, 172, 69, 194, 46, 232, 31, 225, 42, 180],  fillColor: "#7674d6", strokeColor: "rgb(255, 0, 0)",  lineWidth: 0 },
                    { name: '10', shape: "poly", coords: [168, 146, 191, 146, 206, 185, 216, 226, 201, 231, 179, 191, 174, 167],  fillColor: "#7674d6", strokeColor: "rgb(255, 0, 0)",  lineWidth: 0 },
                    { name: '11', shape: "poly", coords: [96, 417, 118, 417, 118, 436, 121, 446, 108, 451, 98, 461, 83, 462, 77, 457, 78, 449, 96, 431],  fillColor: "#7674d6", strokeColor: "rgb(255, 0, 0)",  lineWidth: 0 },
                    { name: '12', shape: "poly", coords: [129, 416, 152, 416, 152, 431, 171, 451, 170, 459, 162, 462, 147, 460, 137, 449, 126, 445, 128, 436],  fillColor: "#7674d6", strokeColor: "rgb(255, 0, 0)",  lineWidth: 0 },
                    { name: '13', shape: "poly", coords: [217, 225, 245, 251, 230, 245, 238, 273, 227, 258, 230, 278, 218, 256, 221, 278, 212, 258, 212, 271, 202, 247, 201, 231],  fillColor: "#7674d6", strokeColor: "rgb(255, 0, 0)",  lineWidth: 0 },
                    { name: '14', shape: "poly", coords: [30, 225, 4, 250, 19, 245, 10, 272, 23, 254, 18, 277, 30, 254, 27, 277, 35, 258, 37, 271, 45, 249, 46, 232],  fillColor: "#7674d6", strokeColor: "rgb(255, 0, 0)",  lineWidth: 0 },
                ]
            },
            hoveredArea: []
        }
    } 
    
    loadedArea(){
        
    }

    enterArea(area) {  
        if (this.state.hoveredArea.indexOf(area) < 0){
            this.setState({ hoveredArea: [...this.state.hoveredArea, area] });
        }
    }

    scaleCoords(coords) {
        var _props = this.props;
        var imgWidth = _props.imgWidth;
        var width = _props.width;

        // calculate scale based on current 'width' and the original 'imgWidth'
        var scale = width && imgWidth && imgWidth > 0 ? width / imgWidth : 1;
        return coords.map(function (coord) {
            return coord * scale;
        });
    }

    computeCenter(area) {
        if (!area) return [0, 0];

        var scaledCoords = this.scaleCoords(area.coords);

        switch (area.shape) {
            case "circle":
                return [scaledCoords[0], scaledCoords[1]];
            case "poly":
            case "rect":
            default:
                {
                    var _ret = (function () {
                        // Calculate centroid
                        var n = scaledCoords.length / 2;

                        var _scaledCoords$reduce = scaledCoords.reduce(function (_ref, val, idx) {
                            var y = _ref.y;
                            var x = _ref.x;

                            return !(idx % 2) ? { y: y, x: x + val / n } : { y: y + val / n, x: x };
                        }, { y: 0, x: 0 });

                        var y = _scaledCoords$reduce.y;
                        var x = _scaledCoords$reduce.x;

                        return {
                            v: [x, y]
                        };
                    })();

                    if (typeof _ret === 'object') return _ret.v;
                }
        }
    }
     
    getTipPosition(area) {
        return { top: `${area.center[1]}px`, left: `${area.center[0]}px` };
    }

    renderToolTips(){
        const tips = this.state.map.areas.map(area => {
            let top = this.computeCenter(area)[1];
            let left = this.computeCenter(area)[0];
            if (top == 272.4){
                top = top - 20
            }
            if (Math.ceil(top) == 91) {
                top = top + 20
            }
            if (Math.ceil(top) == 256){
                top = top - 10;
                left = left + (left == 27 ? 4:-4);
            }
            return (<span className="tooltip"
                style={{ ...{ top: `${top}px`, left: `${left}px` } }}>
                {area.name}
            </span>
            )
        })
        return (
            <div>
                {tips}
            </div>
        )
    }

    componentDidMount() {

        this.state.map.areas.map(area => {
            console.log('this.computeCenter(area)', this.computeCenter(area));
        })

    }
    render() {
        return (
            <div className="row"> 
                <div className="col-md-12">
                    <h1 className="mt30">Data playback</h1>
                </div>
                <div className="col-12">
                    <h2 className="mt30 mb20">Popover</h2> 
                </div>

                <div className="col-md-5">
                    <div className="popover-item-card">
                        <div className="row item-img">
                            <div className="col-md-12">
                                <div className="ar32">
                                    <img src={img} className="bg-dark" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h3 className="title">Used for</h3>
                                <div className="description">
                                    <p>
                                        Show description of use here. Description not necessessary as we want to impart pure use and context of the item instead.
                                    </p>
                                    <p>
                                        Similar to media element, if this text goes over a set limit, make the content box scrollable
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h4 className="sub-title">Tags</h4> 
                                <span className="tags-pill">Orthopedics</span>
                                <span className="tags-pill">ENT</span>
                                <span className="tags-pill">Sample</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-5">
                    <div className="popover-item-card">
                        <div className="row">
                            <div className="col-12">
                                <h3 className="title">Used for</h3>
                                <div className="description">
                                    <p>
                                        Show description of use here. Description not necessessary as we want to impart pure use and context of the item instead.
                                    </p>
                                    <p>
                                        Similar to media element, if this text goes over a set limit, make the content box scrollable
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h4 className="sub-title">Tags</h4>
                                <span className="tags-pill">Orthopedics</span>
                                <span className="tags-pill">ENT</span>
                                <span className="tags-pill">Sample</span>
                            </div>
                        </div>
                    </div>
                </div> 

                <div className="col-md-5 mt20">
                    <div className="popover-item-card">
                        <div className="row item-img">
                            <div className="col-md-12">
                                <div className="ar32">
                                    <img src={img} className="bg-dark" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h4 className="sub-title">Tags</h4>
                                <span className="tags-pill">Orthopedics</span>
                                <span className="tags-pill">ENT</span>
                                <span className="tags-pill">Sample</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 mt20">
                    <div className="popover-item-card"> 
                        <div className="row">
                            <div className="col-12">
                                <h3 className="title">Adding tags</h3>
                                <div className="description">
                                    <p>By adding tags, you and your team will be able to mention items in the context of an instruction, procedure or phase. Make sure to include any nick-names or slang so that others can find it easy.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-12">
                    <h2 className="mt30 mb20">Table</h2>
                    <Table minWidth={750}
                        renderHeaders={() => {
                            return (
                                <TRow>
                                    <THead className="w150" text="Catalogue #" sortable />
                                    <THead className="w250" text="Name" sortable />
                                    <THead className="w150" text="Specialty" sortable />
                                    <THead className="w100 text-center" text="Size" sortable />
                                    <THead className="w90 text-center" text="Qty" sortable />
                                </TRow>
                            )
                        }}
                        renderRows={() => { 
                            let items = [
                                {
                                    catalog_number: "AVS 233 -01",
                                    name: 'Bear Hugger VX',
                                    category: {name:'Category'},
                                    company: 'Johnson & Johnson',
                                    qty: 13,
                                    icon: "http://68.183.187.221/storage/gallery/items/VW2tCGVTmpFruGv8LbXYwOuacouId8LWJF4Npxu7.jpeg",
                                    size: '10mm',
                                    tags: [
                                        { name: 'bear' },
                                        { name: 'Hugger' },
                                        { name: 'VX' },
                                        { name: 'AVS' },
                                        { name: '233' }
                                    ],
                                    used_for: "Show description of use here. Description not necessessary as we want to impart pure use and context of the item instead."
                                },
                                {
                                    catalog_number: "AVS 233 -01",
                                    name: 'Bear Hugger VX',
                                    category: { name: 'Category' },
                                    company: 'Johnson & Johnson',
                                    qty: 13,
                                    icon: "http://68.183.187.221/storage/gallery/items/VW2tCGVTmpFruGv8LbXYwOuacouId8LWJF4Npxu7.jpeg",
                                    size: '10mm',
                                    tags: [
                                        { name: 'bear' },
                                        { name: 'Hugger' },
                                        { name: 'VX' },
                                        { name: 'AVS' },
                                        { name: '233' }
                                    ],
                                    used_for: "Show description of use here. Description not necessessary as we want to impart pure use and context of the item instead."
                                },
                                {
                                    catalog_number: "AVS 233 -01",
                                    name: 'Bear Hugger VX',
                                    category: { name: 'Category' },
                                    company: 'Johnson & Johnson',
                                    qty: 13,
                                    icon: "http://68.183.187.221/storage/gallery/items/VW2tCGVTmpFruGv8LbXYwOuacouId8LWJF4Npxu7.jpeg",
                                    size: '10mm',
                                    tags: [
                                        { name: 'bear' },
                                        { name: 'Hugger' },
                                        { name: 'VX' },
                                        { name: 'AVS' },
                                        { name: '233' }
                                    ],
                                    used_for: "Show description of use here. Description not necessessary as we want to impart pure use and context of the item instead."
                                },
                                {
                                    catalog_number: "AVS 233 -01",
                                    name: 'Bear Hugger VX',
                                    category: { name: 'Category' },
                                    company: 'Johnson & Johnson',
                                    qty: 13,
                                    icon: "http://68.183.187.221/storage/gallery/items/VW2tCGVTmpFruGv8LbXYwOuacouId8LWJF4Npxu7.jpeg",
                                    size: '10mm',
                                    tags: [
                                        { name: 'bear' },
                                        { name: 'Hugger' },
                                        { name: 'VX' },
                                        { name: 'AVS' },
                                        { name: '233' }
                                    ],
                                    used_for: "Show description of use here. Description not necessessary as we want to impart pure use and context of the item instead."
                                },
                            ];
                            let rows = items.map(item => {
                                return ( 
                                    <TRow>
                                        <TData className="w150">{item.catalog_number}</TData>
                                        <TData className="w250 has-action" item={item} popover>{item.name}</TData>
                                        <TData className="w150">{item.category.name}</TData>
                                        <TData className="w100 text-center">{item.size}</TData>
                                        <TData className="w100 text-center">{item.qty}</TData>
                                    </TRow>
                                )
                            })
                            return rows
                        }}

                    />
                </div>

                <div className="col-md-7">
                    <h2 className="mt30 mb20">Table with radio</h2>
                    <Table minWidth={450}
                        renderHeaders={() => {
                            return (
                                <TRow>
                                    <THead className="w40" text="" />
                                    <THead className="w250" text="Name" sortable />
                                    <THead className="w150" text="Specialty" sortable />
                                </TRow>
                            )
                        }}
                        renderRows={() => {
                            let items = [1,2,3,4];
                            let rows = items.map(item=>{
                                return (
                                    <TRow hasChoices>
                                        <TData className="w40">
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id={`radio-${item}`} name={`radio-gaga`} className="custom-control-input" />
                                                <label className="custom-control-label" htmlFor={`radio-${item}`}></label>
                                            </div>
                                        </TData>
                                        <TData className="w250 has-action">Baba yetu spielgen</TData>
                                        <TData className="w150">Cardiac</TData>
                                    </TRow>
                                )
                            })
                            return rows
                        }}

                    /> 
                </div>

                <div className="col-md-7">
                    <h2 className="mt30 mb20">Table with checkbox</h2>
                    <Table minWidth={450}
                        renderHeaders={() => {
                            return (
                                <TRow>
                                    <THead className="w40" text="" />
                                    <THead className="w250" text="Name" sortable />
                                    <THead className="w150" text="Specialty" sortable />
                                </TRow>
                            )
                        }}
                        renderRows={() => {
                            let items = [1, 2, 3, 4];
                            let rows = items.map(item => {
                                return (
                                    <TRow hasChoices key={`row-${item}`}>
                                        <TData className="w40">
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id={`checkbox-${item}`} />
                                                <label className="custom-control-label" htmlFor={`checkbox-${item}`}></label>
                                            </div> 
                                        </TData>
                                        <TData className="w250 has-action">Baba yetu spielgen</TData>
                                        <TData className="w150">Cardiac</TData>
                                    </TRow>
                                )
                            })
                            return rows
                        }}

                    />  
                </div>


                <div className="col-md-12">
                    <h2 className="mt30 mb20">Show hide</h2>
                    <Collapsible className="mb20" open title="Item list">
                        <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.</p>
                    </Collapsible>
                    <Collapsible className="mb20" title="Item list">
                        <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.</p>
                    </Collapsible>
                    <Collapsible className="mb20" title="Item list">
                        <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.</p>
                    </Collapsible>
                </div>
                <div className="col-md-12">
                    <h2 className="mt30 mb20">Data Card</h2>
                    <DataCard title="Title of the card here...">
                        <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.</p>
                        <div className="corh-table">
                            <table className="min-table-width-750">
                                <thead>
                                    <tr>
                                        <th className="w150">Catalogue #</th>
                                        <th className="w250">Name</th>
                                        <th className="w150">Company</th>
                                        <th className="w100 text-center">Size</th>
                                        <th className="w100 text-center">Qty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>12345678901234</td>
                                        <td className="has-action">Baba yetu spielgen</td>
                                        <td>Johnson & Johnson</td>
                                        <td className="text-center">xxx</td>
                                        <td className="text-center">11</td>
                                    </tr>
                                    <tr>
                                        <td>12345678901234</td>
                                        <td className="has-action">Baba yetu spielgen over 2 lines because its long</td>
                                        <td>Johnson & Johnson</td>
                                        <td className="text-center">xxx</td>
                                        <td className="text-center">11</td>
                                    </tr>
                                    <tr>
                                        <td>12345678901234</td>
                                        <td className="has-action">Baba yetu spielgen</td>
                                        <td>Johnson & Johnson</td>
                                        <td className="text-center">xxx</td>
                                        <td className="text-center">11</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </DataCard>
                </div>


                <div className="col-md-12">
                    <h2 className="mt30 mb20">Items required by body part</h2>
                    <DataCard title="Title of the card here...">
                        <div className="centerAlign">
                            <div className="body-image">
                                <ImageMapper
                                    src={body_img}
                                    map={this.state.map}
                                    onLoad={(areas) => {
                                        console.log('areas', areas)
                                    }}
                                    onMouseEnter={area => this.enterArea(area)}
                                    onClick={area => this.enterArea(area)}
                                />
                                {this.renderToolTips()}
                            </div>
                        </div>
                    </DataCard>
                </div>

                <div className="sidebar collapsed">
                    <div className="side-toggler">
                        <i className="mdi mdi-chevron-right"></i>
                    </div>
                    <div className="side">
                        <ul className="side-list">
                            <li className="active">
                                <a href="#">
                                    <i className="fa fa-user-circle-o"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i className="mdi mdi-format-list-checkbox"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="body">
                        <div className="surgeon-profile-display">
                            <div className="profile-img">
                                <img src={emma} />
                                <a href="#">Edit</a>
                            </div>
                            <h3 className="name">Emma Wattz</h3>
                            <h3 className="text-center regular job">Witch Doctor</h3>
                            <h3 className="text-center regular number">09 777 777 777</h3>
                            <div className="row">
                                <div className="col-md-12">
                                    <ul className="list-unstyled surgeon-details with-btm-border">
                                        <li className="item skip">
                                            <b>Left </b>
                                            handed
                                        </li>
                                        <li className="item">
                                            <h4>Gloves </h4>
                                            <Text item={
                                                {
                                                    catalog_number: "AVS 233 -01",
                                                    name: 'Bear Hugger VX',
                                                    category: { name: 'Category' },
                                                    company: 'Johnson & Johnson',
                                                    qty: 13,
                                                    icon: "http://68.183.187.221/storage/gallery/items/VW2tCGVTmpFruGv8LbXYwOuacouId8LWJF4Npxu7.jpeg",
                                                    size: '10mm',
                                                    tags: [
                                                        { name: 'bear' },
                                                        { name: 'Hugger' },
                                                        { name: 'VX' },
                                                        { name: 'AVS' },
                                                        { name: '233' }
                                                    ],
                                                    used_for: "Show description of use here. Description not necessessary as we want to impart pure use and context of the item instead."
                                                }
                                            } popover>
                                                CURAD Stretch Vinyl Exam Gloves (Vinyl Exam Gloves)
                                            </Text>
                                        </li>
                                        <li className="item skip">
                                            <b>Size </b>
                                            L
                                        </li>
                                        <li className="item">
                                            <h4>Outer Gloves </h4>
                                            <Text item={
                                                {
                                                    catalog_number: "AVS 233 -0111",
                                                    name: 'Bear Hugger VXXX',
                                                    category: { name: 'Category' },
                                                    company: 'Johnson & Johnson',
                                                    qty: 13,
                                                    icon: "http://68.183.187.221/storage/gallery/items/VW2tCGVTmpFruGv8LbXYwOuacouId8LWJF4Npxu7.jpeg",
                                                    size: '10mm',
                                                    tags: [
                                                        { name: 'bear' },
                                                        { name: 'Hugger' },
                                                        { name: 'VX' },
                                                        { name: 'AVS' },
                                                        { name: '233' }
                                                    ],
                                                    used_for: "Show description of use here. Description not necessessary as we want to impart pure use and context of the item instead."
                                                }
                                            } popover>
                                                CURAD Stretch Vinyl Exam Gloves (Vinyl Exam Gloves)2
                                            </Text>
                                        </li>
                                        <li className="item mb16">
                                            <b>Size </b>
                                            XL
                                        </li>
                                    </ul>
                                    <ul className="list-unstyled surgeon-details with-btm-border">
                                        <li className="item">
                                            <b>Prefers</b>
                                        </li>
                                        <li className="item">
                                            <span className="item-detail">Music <b>ON</b></span>
                                            <span className="item-detail"><b>Normal</b> environment</span>
                                        </li>
                                    </ul> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Data
