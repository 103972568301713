import React, { Component } from 'react'

export class CardIcon extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      icon: require(`../../../src/assets/images/cc/cc.png`)
    }
  }
  componentDidMount = () => {
    this.setState({
      icon: require(`../../../src/assets/images/cc/${this.props.icon}.png`)
    })
  }
  componentWillUpdate(nextProps, nextState) {
    if(this.props != nextProps){
      this.setState({
        icon: require(`../../../src/assets/images/cc/${nextProps.icon}.png`)
      })
    }
  }
  
  render() {
    return <img src={this.state.icon} />
  }
}

export default CardIcon
