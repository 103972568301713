import axios from 'axios'
import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { Modal } from '../commons/modals'
import { Table, TData, THead, TRow } from '../commons/table'

export class LoadFromModalStandard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      page: 1,
      selected: 0,
      keyword: '',
      surgeons: [],
      selected_surgeon_id: '',
      loading: false,
    }
    this.searchData = _.debounce(this.getItemResults, 600)
  }

  componentDidMount = () => {
    this.getSurgeons(this.props)
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.procedure != nextProps.procedure) {
      this.getSurgeons(nextProps)
    }
  }

  getSurgeons = props => {
    const { procedure_id, surgeon } = props
    const { keyword } = this.state
    let url = `/api/client/surgeon-procedure-load?surgeon_id=${surgeon.id}&procedure_id=${procedure_id}&search=${keyword}`
    this.setState(
      {
        loading: true,
      },
      () => {
        axios
          .get(url)
          .then(res => {
            console.log('popo', res)

            this.setState({
              surgeons: res.data,
              loading:false
            })
          })
          .catch(err => {
            this.setState({
              loading:false
            })
            console.log('popop', err)
            console.log('popop', err.response)
          })
      }
    )
  }
  submitSurgeon = () => {
    const { selected_surgeon_id } = this.state
    if (selected_surgeon_id) {
      this.props.loadSurgeonProcedure(selected_surgeon_id)
    }
  }

  searchSurgeon({ target: { value } }) {
    this.setState(
      {
        keyword: value,
        loading: true,
        surgeons: [],
      },
      () => {
        this.searchData()
      }
    )
  }
  getItemResults = () => {
    this.getSurgeons(this.props)
  }

  render() {
    const { surgeons } = this.state

    return (
      <Modal
        idModal="loadFromModalCenter"
        size="modal-md"
        title="Copy surgeons  procedure or surgical stage"
        confirmText="Copy Surgeon"
        cancelText="Cancel"
        bodyClass="select-procedures-body"
        confirm={e => {
          this.submitSurgeon()
        }}
        cancel={e => {
          //fn here
        }}
      >
        <p className="modal-sub-title">
          Select from the list of surgeons to copy their{' '}
          <b>[procedures/phase]</b>
        </p>
        <div className="row">
          <div className="col-lg-7 col-md-10 mt8 mb16">
            <div className="line-form-group-v2">
              <input
                type="text"
                className="line-form-v2"
                ref={el => (this.keyword = el)}
                onChange={this.searchSurgeon.bind(this)}
              />
              <label>
                Search <i className="mdi mdi-magnify"></i>
              </label>
            </div>
          </div>
        </div>
        <Table
          loading={this.state.loading}
          minWidth={540}
          className="modal-table"
          renderHeaders={() => {
            return (
              <TRow>
                <THead className="w40" text="" />
                <THead className="w300" text="Surgeon" />
                <THead className="w200 d-none" text="View [procedure/phase]" />
              </TRow>
            )
          }}
          renderRows={() => {
            let rows = surgeons.map(data => {
              let surgeon = data.surgeon
              return (
                <TRow
                  hasChoices
                  key={`row-lfm-${surgeon.id}`}
                  onClick={() => {
                    this.setState({
                      selected_surgeon_id: surgeon.id,
                    })
                  }}
                >
                  <TData className="w40">
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        id={`radio-lfm-${data.id}-${surgeon.id}`}
                        name={`radio-lfm`}
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={`radio-lfm-${data.id}-${surgeon.id}`}
                      ></label>
                    </div>
                  </TData>
                  <TData className="w300">{`${surgeon.firstname} ${surgeon.lastname}`}</TData>
                  <TData className="w200 d-none">
                    <Link
                      to={{
                        pathname: `/procedure/${data.procedure_id}`,
                        search: `?surgeon_id=${surgeon.id}`,
                      }}
                      target="_blank"
                      className="p-0"
                    >
                      View
                    </Link>
                  </TData>
                </TRow>
              )
            })
            return rows
          }}
          // renderNotification={() => {
          //     return (<TableNotificationCard type="success" message="Success message here..." />)
          // }}
        />
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    // surgeons: state.surgeon.surgeons,
  }
}

export default connect(mapStateToProps)(LoadFromModalStandard)
