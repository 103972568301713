import React, { Component } from 'react'
import $ from 'jquery'


export default class CollapsePanel extends Component {
    constructor(props) {
        super(props)

        this.state = {
            unique:
                Math.random()
                    .toString(36)
                    .substring(2, 15) +
                Math.random()
                    .toString(36)
                    .substring(2, 15),
            show: false, 
        }
    }

    componentDidMount() {
        const { idPanel, title, content } = this.props
        $(`#${idPanel}-heading`).click(()=>{
            $(`#${idPanel}-heading`).find('i').toggleClass('fa-chevron-down fa-chevron-up');
        })
    }
     

    render() {
        const { idPanel, title, content, idHeader} = this.props 
        return (
            <div className={`card w-100 rounded-0 bg-white ${this.props.className}`}>
                <div className="card-header w-100 c-pointer p-0 border-0 bg-white  sticky-top" id={`${idPanel}-heading`} data-toggle="collapse" data-target={`#${idPanel}-id`} aria-expanded="true" aria-controls={`${idPanel}-id`}>
                    <b className="w-100 px15 py10 float-left d-flex align-items-center h4med" id={idHeader}>
                        {title}
                        <i className="fa fa-chevron-down ml-auto"></i>
                    </b>

                </div>
                <div id={`${idPanel}-id`} className="collapse border-0" aria-labelledby={`${idPanel}-heading`}>
                    <div className="card-body border-0 pt-0 px15 pb15">
                        {content}
                    </div>
                </div>
            </div>

        )
    }
}
