import React, { Component } from 'react'
import default_img from '../../../../src/assets/images/img.png'
import { Pills } from '../misc'
import { Link } from 'react-router'

export default class UserProfileCard extends Component {
  render() {
    const { user, img, centerProfile } = this.props
    let image = img ? img : default_img

    let specs = user.specialties.map(data => {
      return <Pills background={data.color}>{data.name}</Pills>
    })
    console.log('userusedr', user.job_title == 'null')
    return (
      <Link
        to={{ pathname: '/account-settings'}}
        style={{ color: 'inherit', textDecoration: 'none' }}
      >
        <div className="card surgeon-card">
          <div
            className={`card-body surgeon-card-body ${
              centerProfile ? 'center-profile' : ''
            }`}
          >
            <img
              src={image}
              className="surgeon-image icon-circle icon-circle-lg"
            />
            <div className="name-holder">
              <h3 className="bold surgeon-name text-center w-100">
                {user.name ? (user.name == 'null' ? '-' : user.name) : '-'}
              </h3>
              <span className="surgeon-title text-center w-100 mx-auto float-left">
                {user.job_title
                  ? user.job_title == 'null'
                    ? '-'
                    : user.job_title
                  : '-'}
              </span>
            </div>
          </div>
          {specs.length ? (
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <ul className="list-unstyled surgeon-details">
                    <li className="item mb0">
                      <b>Specialties</b>
                    </li>
                    <li className="item mb0">{specs}</li>
                  </ul>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </Link>
    )
  }
}
