import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createBrowserHistory } from "history";

import { getSurgeon } from '../../actions/SurgeonActions'
import SidePanel from '../../components/commons/panel/SidePanel'
import {
	BrowserRouter as Router,
	Switch,
	Route,
  Link, 
  Redirect,  
  } from "react-router-dom";
import NewProcedures from './NewProcedures';
import NewQuicknotes from './NewQuicknotes';
import Sidebar from './Sidebar';


function getParams(param) {
	const searchParams = new URLSearchParams(window.location.search);
	return searchParams.get(param) || '';
}

const history = createBrowserHistory();

export class Core extends Component {

	constructor(props) {
		super(props)
	
		this.state = {
			procedure: {
        id: 0
      },
		}
  }
  
  componentDidMount() {  
  }
  
   
	setProcedure = (procedure) => {
		this.setState({
			procedure: procedure
		})
	}

  render() {
    const { surgeon } = this.props
    const { procedure } = this.state 
    return ( 
      <Router history={history} basename={`/core/${surgeon.id}`}>
      <div className="container pt-0 mb30 pb30">
          
          <Sidebar surgeon={surgeon} setProcedure={this.setProcedure}></Sidebar>

          
        <div className="row">
          
          <div className="col-md-12 order-1 order-md-2" id="home"> 
            <Switch>
              
              <Route path={`/quicknotes`} exact={true} children={<NewQuicknotes surgeon={surgeon}/>} />
              <Route path={`/procedures`} children={<NewProcedures surgeon={surgeon} procedure={procedure}/>} />    
              
              <Redirect from='/procedure' 
              to={{
                pathname: "/procedures",
                search: '?procedure_id='+procedure.id 
              }} />          
              <Redirect from='*' to={`/quicknotes`} />          
            </Switch>
          </div>


        </div>

      </div> 
        </Router>
    )
  }
}


const mapStateToProps = state => {
	return {
	  surgeon: state.surgeon.surgeon,
	  loading: state.loading,
	}
}
  
export default connect(
	mapStateToProps, { getSurgeon }
)(Core)
  