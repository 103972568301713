import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  getMedicalInventories,
  getMedicalInventoryCategories,
  setItems,
  getItemsPerCategory,
  itemLoading
} from '../../actions/ItemAction'
import ReactDOMServer from 'react-dom/server'
import { Link } from 'react-router'
import { Picker, TextInput } from '../../components/commons/forms'
import $ from 'jquery'
import _ from 'lodash'
import AllocateItemsProcedure from './AllocateItemsProcedure'
import ViewItemModal from './ViewItemModal'
import InfiniteScroll from 'react-infinite-scroll-component'
import {
  Table,
  THead,
  TRow,
  TData,
} from '../commons/table'
import { Modal } from '../commons/modals'
import ViewItemCard from '../commons/cards/ViewItemCard'
import axios from 'axios'

export class AssignItemsProcedure extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected_items: [],
      category: 'all',
      inventory: 1,
      isReverse: false,
      keyword: '',
      selectedItem: null,
      loading:false,
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      page: 1,
      key:
        Math.random()
        .toString(36)
        .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      page: 1,
    }
    this.searchData = _.debounce(this.getItemResults, 600)
  }

  componentDidMount = () => {
    this.props.getMedicalInventories()
    this.props.getMedicalInventoryCategories(this.state.inventory)
    this.props.getItemsPerCategory(
      'all',
      this.props.selected_items,
      this.state.inventory,
      '',
      this.state.page
    )

    setTimeout(() => {
      this.setState({

        key:
          Math.random()
            .toString(36)
            .substring(2, 15) +
          Math.random()
            .toString(36)
            .substring(2, 15),
      })
    }, 500)
  }

  componentDidUpdate(prevProps, prevState) {
    
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.change != nextProps.change) {
      var selected_ = nextProps.selected_items.filter(item => {
        return item.item_category == nextProps.selected_category
      })

      var unselected_ = nextProps.selected_items.filter(item => {
        return item.item_category != nextProps.selected_category
      })
      this.setState(
        {
          inventory: 1,
          page: 1,
          keyword: '',
          category: 'all',
          key:
            Math.random()
              .toString(36)
              .substring(2, 15) +
            Math.random()
              .toString(36)
              .substring(2, 15),
          selected_items: _.cloneDeep(selected_), 
          un_selected_items: _.cloneDeep(unselected_),
        },
        () => {
          console.log('componentWillReceiveProps', _.cloneDeep(selected_), _.cloneDeep(unselected_))
          this.props.getMedicalInventoryCategories(this.state.inventory)
        
          this.props.getItemsPerCategory(
            'all',
            _.cloneDeep(selected_),
            this.state.inventory,
            '',
            this.state.page
          )
        }
      )
    }
  }

  getMoreItems = () => {
    const { page } = this.state

    this.setState(
      {
        page: page + 1,
      },
      () => {
        this.props.getItemsPerCategory(
          this.state.category,
          this.state.selected_items,
          this.state.inventory,
          '',
          this.state.page
        )
      }
    )
  }

  checkItem = id => {
    let { selected_items } = this.state
    const { items, selected_category } = this.props

    let item = items.find(e => e.id === id)
    if (item) {
      if (!item.qty) {
        item.qty = 1
      }
    } else {
      item = selected_items.find(e => e.id === id)
    }

    if (selected_items.find(e => e.id === id)) {
      this.setState({
        selected_items: selected_items.filter(x => x.id !== id),
      })
    } else {
      item['item_category'] = selected_category
      selected_items.unshift(item)
      this.setState({
        selected_items: selected_items,
      })
    }
  }

  checkItemExistence = id => {
    let { selected_items } = this.state
    let { selected_category } = this.props

    let exist = false
    if (selected_items.filter(e => e.id === id && e.item_category == selected_category).length > 0) {
      exist = true
    } else {
      exist = false
    }

    return exist
  }

  sortBy(key) {
    let arrayCopy = [...this.props.items]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }

    this.props.setItems(arrayCopy)

    this.setState({ isReverse: !this.state.isReverse })
  }

  setSelected = selected_items => {
    this.setState({
      selected_items: selected_items,
    })
  }

  compareBy(key) {
    if (key == 'category') {
      return function(a, b) {
        if (a.category.name < b.category.name) return -1
        if (a.category.name > b.category.name) return 1
        return 0
      }
    }
    return function(a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }

  getCategories = () => {
    let i = this.props.item_categories
    let x = _.find(i, e => {
      return e.id == ''
    })
    if (!x) {
      i.unshift({ id: '', name: 'Select category' })
    }

    return i
  }
  getItemResults = () => {
    this.props.getItemsPerCategory(
      this.state.category,
      this.state.selected_items,
      this.state.inventory.id,
      this.state.keyword,
      this.state.page
    )
  }
  searchItems = ({ target: { value } }) => {
    this.props.itemLoading()
    this.setState({ keyword: value, page: 1 }, () => {
      this.searchData()
    })
  }

  validate = () => {

    this.assignItem();
    return;
    const { selected_items } = this.props
    this.setState({
      errors: []
    })

    let data = new FormData()
    data.append('_method', 'PATCH')
    selected_items.map((item) => {
      data.append('consumables[]', item.item_category ? item.item_category : '')
    })

    let url = '/api/client/stage-consumable-validate'

    axios.post(url, data).then((res) => {
      console.log('popo5', res)
      this.assignItem()

    }).catch((err) => {
      console.log(err)
      console.log(err.response)
      this.setState({
        errors: err.response.data.errors
      })
    })
  }
  subtractQty = (item, index) => {
    let { selected_items } = this.state

    if (item.qty > 1) {
      item.qty -= 1
    }

    selected_items[index] = item
    this.setSelected(selected_items)
  }

  changeQty = (qty, item, index) => {
    
    let { selected_items } = this.state

    item.qty = qty

    let sel_index = selected_items.findIndex((d) => {
      return d.id == item.id
    })

    if (selected_items[sel_index]){ 
      selected_items[sel_index] = item
    }else{
      selected_items.push(item)
    }
    this.setSelected(selected_items)
  }

  addQty = (item, index) => {
    let { selected_items } = this.state

    item.qty += 1
    selected_items[index] = item
    this.setSelected(selected_items)
  }


  assignItem = () => {

    $('#assignItemsModalCenter').modal('hide')
    $('#allocateItemsModalCenter').modal('show') 
  }

  render() {
    return (
      <div>
        <Modal
          idModal="assignItemsModalCenter"
          size="modal-xl"
          title="Step 1 - Assign items"
          confirmText="Continue"
          cancelText="Cancel"
          confirm={e => {
            this.validate()
            // $('#assignItemsModalCenter').modal('hide')
            // $('#allocateItemsModalCenter').modal('show')
          }}
          cancel={e => {
            //fn here
          }} 
          bodyClass="assign-items-body"
        >
          <div className="container">
            <div className="row py15">
              <div className="col-lg-5">
                <TextInput
                  label={'Search supply database'}
                  placeholder={'Start typing name, catalogue #…'}
                  className="active"
                  onChange={this.searchItems.bind(this)}
                  value={this.state.keyword}
                  search
                />
              </div>
              <div className="col-lg-5">
                <Picker
                  onChange={event => {
                    this.setState(
                      { category: event.target.value, keyword: '', page: 1 },
                      () => {
                        this.props.getItemsPerCategory(
                          this.state.category,
                          this.state.selected_items,
                          this.state.inventory,
                          '',
                          this.state.page
                        )
                      }
                    )
                  }}
                  label={`Filter by category`}
                  value={this.state.category}
                  data={this.props.inventory_categories}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <Table
                  key={this.state.key}
                   minWidth={890} className="modal-table infi-table"
                  renderModal={() => {
                    return (
                      <ViewItemCard
                        id={`aip-c-preview-modal-${this.state.unique}`}
                        onRef={ref => (this.viewItemRef = ref)}
                        item={this.state.selectedItem}
                      ></ViewItemCard>
                    )
                  }}
                  loading={this.props.loading}
                  infiniteScroll infiniteHeight={307}
                  infiniteDataLength={this.props.items.length} //This is important field to render the next data
                  infiniteNext={() => {
                    this.getMoreItems()
                  }}
                  infiniteHasMore={this.props.more}
                  // endOfMessageTitle="Nothing else to see..."
                   endOfMessageMessage="Nothing else to see..."
                  // endOfMessageLinkMessage="Edit database"
                  renderHeaders={() => {
                    return (
                      <TRow>
                        <THead className="w40" text="" />
                        <THead
                          className="w150"
                          text="Catalogue #"
                          sortable
                          onClick={() => this.sortBy('catalog_number')}
                        />
                        <THead
                          className="w290"
                          text="Name"
                          sortable
                          onClick={() => this.sortBy('name')}
                        />
                        <THead
                          className="w100"
                          text="Size"
                          sortable
                          onClick={() => this.sortBy('size')}
                        />
                        <THead
                          className="w150"
                          text="Company"
                          sortable
                          onClick={() => this.sortBy('company')}
                        />
                        <THead
                          className="w150"
                          text="Category"
                          sortable
                          onClick={() => this.sortBy('category')}
                        />
                        <THead className="w90" text="Qty" />
                      </TRow>
                    )
                  }}
                  renderRows={() => {
                    let rows = this.props.items.map((item, index) => {
                      console.log('aip-item', item.qty)
                      return (
                        <TRow
                          hasChoices={true}
                          key={`row-aitp-${item.id}-${index}`}
                          
                          autoCheck={false}
                        >
                          <TData className="w40" onClick={() => {
                            this.checkItem(item.id)
                          }}>
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={`checkbox-aitt-${item.id}`}
                                checked={this.checkItemExistence(item.id)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`checkbox-aitt-${item.id}`}
                                onClick={e => {
                                  e.preventDefault()
                                }}
                              ></label>
                            </div>
                          </TData>
                          <TData className="w150">{item.catalog_number}</TData>
                          <TData
                            className="w290 has-action"
                            item={item}
                            popover
                            onClick={e => {
                              e.stopPropagation()
                              this.setState({
                                selectedItem: item,
                                unique:
                                  Math.random()
                                    .toString(36)
                                    .substring(2, 15) +
                                  Math.random()
                                    .toString(36)
                                    .substring(2, 15),
                              })
                              $(
                                `#aip-c-preview-modal-${this.state.unique}`
                              ).modal('show')
                            }}
                          >
                            {item.name}
                          </TData>
                          <TData className="w100">{item.size}</TData>
                          <TData className="w150">{item.company}</TData>
                          <TData className="w150">
                            {item.category ? item.category.name : ''}
                          </TData>
                          <TData className="w90" onClick={(e) => {
                            e.stopPropagation()
                          }}> 
                            <input
                              type="number"
                              defaultValue={1}
                              className="form-control text-center form-control-sm px-0"
                              value={item.qty} 
                              onChange={(event) => {
                                let string = `${event.target.value}` 
                                console.log('/^[1-9][0-9]*$/.test(string)', /^[1-9][0-9]*$/.test(string))
                                if (/^[1-9][0-9]*$/.test(string) || !event.target.value.length) {
                                  this.changeQty(event.target.value, item, index)
                                } else {
                                  this.changeQty(1, item, index)
                                }
                              }}
                              onKeyUp={(event) => {
                                let string = `${event.target.value}`
                                if (/^[1-9][0-9]*$/.test(string) || !event.target.value.length) {
                                  this.changeQty(event.target.value, item, index)
                                }
                              }}
                              onBlur={(event)=>{ 
                                if (!$(event.target).val().length) {
                                   if ($(event.target).val() <= 0) {
                                    $(event.target).val(1)
                                    this.changeQty(1, item, index)
                                  }
                                }
                              }} 
                            />
                          </TData>
                        </TRow>
                      )
                    })
                    return rows
                  }}
                // renderNotification={() => {
                //     return (<TableNotificationCard type="success" message="Success message here..." />)
                // }}
                />
              </div>
            </div>

          </div>
        
        </Modal>

        <ViewItemModal
          idModal="viewItemConsumableLayoutModal"
          key={this.state.unique}
          item={this.state.selectedItem}
          cancel={() => {
            $('#viewItemConsumableLayoutModal').modal('hide')
          }}
        />

        <AllocateItemsProcedure
          checkItem={this.checkItem}
          checkItemExistence={this.checkItemExistence}
          setSelected={this.setSelected}
          setNewState={this.props.setNewState}
          selected_items={this.state.selected_items}
          stage_master={this.props.stage_master}
          item_categories={this.getCategories()}
          selected_category={this.props.selected_category}
          un_selected_items={this.state.un_selected_items}
        />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    medical_inventories: state.item.medical_inventories,
    inventory_categories: state.item.inventory_categories,
    items: state.item.items,
    loading: state.item.loading,
    more: state.item.more,
  }
}

export default connect(
  mapStateToProps,
  {
    setItems,
    getMedicalInventories,
    getMedicalInventoryCategories,
    getItemsPerCategory,
    itemLoading
  }
)(AssignItemsProcedure)
