import React, { Component } from 'react'
import AssignItemsProcedureInstrument from '../modals/AssignItemsProcedureInstrument'
import AssignTraysProcedureInstrument from '../modals/AssignTraysProcedureInstrument'
import ReactDOMServer from 'react-dom/server'
import { Link } from 'react-router'
import $ from 'jquery'
import axios from 'axios'
import { TextInput, Button, Picker } from '../commons/forms'
import { getError } from '../../utilities'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import arrayMove from 'array-move'
import ViewItemModal from '../modals/ViewItemModal'
import Confirmation from '../Confirmation'
import InlineNotification from '../commons/notifier/InlineNotification'
import CollapsePanel from '../commons/panel/CollapsePanel'
import Notifier from '../commons/notifier/Notifier'
import DataCard from '../commons/cards/DataCard'
import { Table, TRow, THead, TData } from '../commons/table'
import Collapsible from '../commons/panel/Collapsible'
import ViewItemCard from '../commons/cards/ViewItemCard'
import EditMenu from '../commons/misc/EditMenu'
import Icon from '../commons/icons/Icon'
import { ConfirmationModal, FormModal, Modal } from '../commons/modals'
import { showLoader, setLoaderProg, hideLoader } from '../loader/loading_screen'
import { procedureAction } from '../../utilities/notifier'
import _ from 'lodash'
import TrayItemModal from '../modals/TrayItemModal'
import NotesSkeleton from '../skeletons/NotesSkeleton'
import InstrumentsSkeleton from '../skeletons/InstrumentsSkeleton'
import { Event } from '../../utilities/analytics'

const SortableItem = SortableElement(
  ({ category, assignNewState, actions }) => (
    <li className="draggable-item">
      <div className="icon-holder">
        <Icon icon="move-icon" height={28} width={15} className="negative" />
      </div>
      <span className="pl10 work-break-all">{category.name}</span>
      {actions ? (
        <div className="action-holder">
          <Button
            tertiary
            datatoggle="modal"
            datatarget="#confirmationDeleteSectionModal"
            onClick={e => {
              e.preventDefault()
              assignNewState({
                section: category,
              })
            }}
          >
            <i className="mdi mdi-trash-can-outline fs20"></i>
          </Button>
        </div>
      ) : (
        ''
      )}
    </li>
  )
)

const SortableList = SortableContainer(
  ({ categories, assignNewState, actions = 1 }) => {
    return (
      <ul className="list-unstyled mb0 d-flex" style={{ flexWrap: 'wrap' }}>
        {categories.map((category, index) => {
          return (
            <SortableItem
              key={`item-${index}`}
              index={index}
              category={category}
              assignNewState={assignNewState}
              actions={actions}
              axis='xy'
            />
          )
        })}
      </ul>
    )
  }
)

export class InstrumentsLayout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected_items: [],
      selected_trays: [],
      tray: {},
      section: {},
      section_name: '',
      errors: [],
      addSection: false,
      detail_stage: [],
      custom_sections: [],
      custom_trays: [],
      edit: false,
      change: false,
      selectedItem: null,
      draft_success: false,
      showNotification: false,
      notificationMessage: '',
      selectedItem: [],
      specialties:[],
      activeIndex: null,
      deleteCategoryId: null,
      deleteItemId: null,
      selectedCategory: null,
      specialty: '',
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      tray_data: null,
      tray_unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      loading: false,
      adding_category:false,
      adding_tray: false,
    }
    this.toggleShow = this.toggleShow.bind(this)
  }

  toggleShow(val, message = '') {
    this.setState({
      showNotification: val,
      notificationMessage: message,
    })
  }

  componentDidMount = () => {
    const { trays } = this.props
    if (trays) {
      this.setState({
        selected_trays: trays,
      })
    }

    if (this.props.enableDraft) {
      this.setState({
        edit: true,
        draft_success: false,
      })
      this.props.getProcedureDetail(1)
    }
    this.scrollToTop()

    setTimeout(() => {
      $('[data-toggle="popover"]').popover()
      $('.popover').popover('hide')
    }, 200)
    this.props.setNewState({
      settings: false,
    })
 
    axios.get('api/client/specialty').then((res) => { 
      let res_ar = res.data.data; 

      res_ar.unshift({ id: '', name: 'Select Specialty' }) 
      this.props.setNewState({
        specialties: res_ar,
      }) 
      this.setState({
        specialties: res_ar
      })
    }).catch((err) => {
      console.log(err)
    })
  }

  componentDidUpdate() {
    $('[data-toggle="popover"]').popover()
    $('.popover').popover('hide')
  }

  assignNewState = state => {
    this.setState(state)
  }

  onSortEndTray = ({ oldIndex, newIndex }) => {
    this.setState(
      ({ selected_trays }) => ({
        selected_trays: arrayMove(selected_trays, oldIndex, newIndex),
      }),
      () => {
        // this.saveSorting()
      }
    )
  }

  onSortEndSection = ({ oldIndex, newIndex }) => {
    this.setState(
      ({ custom_sections }) => ({
        custom_sections: arrayMove(custom_sections, oldIndex, newIndex),
      }),
      () => {
       this.saveSorting()
      }
    )
    
  }

  saveSorting(){
    const combind = [...this.state.detail_stage, ...this.state.custom_sections]
    const data = new FormData
    console.log('combind', combind)
    combind.map((item, index) => {
      if (item.items)
        data.append(`item_ids[]`, item.id)
    })
   axios.post('api/client/stage-instrument-sort', data).then(result => {
      console.log(result)
      this.props.getProcedureDetail(1)
    }).catch(error => {
      console.log(error)
      console.log(error.response)
    })
 
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.stage_master != nextProps.stage_master) {
      if (!nextProps.stage_master.draft) {
        this.setState({
          edit: false,
          item_list: nextProps.item_list,
        })
      }
    }

    if (!this.state.selected_flag) {
      this.generateSelected(nextProps.stage_details)
    }

    if (nextProps.stage_details != this.props.stage_details) {
      this.setState({
        detail_stage: nextProps.stage_details.filter(item => {
          return item.default_value == 1
        }),
        custom_sections: nextProps.stage_details.filter(item => {
          return item.default_value == 0 && item.tray_id == 0
        }),
        selected_trays: nextProps.trays ? nextProps.trays : [],
      })
    }
  }

  addSection = () => {
    const { stage_master } = this.props
    this.setState({
      adding_category: true
    })
    const data = {
      name: this.state.section_name,
      stage_id: stage_master.id,
    }
    axios
      .post(`api/client/stage-instrument`, data)
      .then(result => {
        this.setState({
          addSection: false,
          section_name: '',
          adding_category: false
        })
        this.props.getProcedureDetail(1)
        $("#addNewCategory").modal('hide');
      })
      .catch(error => {
        console.log(error)
        console.log(error.response, error.response.data)
        this.setState({
          errors: error.response.data.errors,
          adding_category: false
        })
      })
  }

  editSection = () => {
    const { stage_master } = this.props

    const data = {
      name: this.state.section_name,
    }

    let url = `api/client/stage-instrument/${this.state.section.id}`
    axios
      .post(url, data)
      .then(result => {
        this.setState({
          create_category: false,
        })
        this.props.getProcedureDetail(1)
        $("#addNewCategory").modal('hide');
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
        this.setState({
          errors: error.reponse.data.errors,
        })
      })
  }

  deleteSection = () => {
    const { section } = this.state

    const data = {
      _method: 'DELETE',
    }

    let url = `api/client/stage-instrument/${this.state.section.id}`
    axios
      .post(url, data)
      .then(res => {
        this.props.getProcedureDetail(1)
      })
      .catch(err => {
        console.log(err)
        console.log(err.reponse)
      })
  }

  generateSelected = stage_details => {
    let temp = []
    stage_details.map(detail => {
      if (detail.tray_id == 0) {
        if (detail.items) {
          detail.items.map(item => {
            item.item_category = detail.id
            temp.push(item)
          })
        }
      }
    })

    this.setState({
      selected_items: temp,
    })
  }

  setNewstate = state => {
    this.setState(state)
  }

  subtractQty = (item, index) => {
    let { selected_items } = this.state

    if (item.qty > 1) {
      item.qty -= 1
    }

    selected_items[index] = item
    this.setState({
      selected_items: selected_items,
    })
  }

  sortBy(key) {
    let arrayCopy = [...this.state.selected_items]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }

    this.setState({
      selected_items: arrayCopy,
      isReverse: !this.state.isReverse,
    })
  }

  compareBy(key) {
    if (key == 'category') {
      return function(a, b) {
        if (a.category.name < b.category.name) return -1
        if (a.category.name > b.category.name) return 1
        return 0
      }
    }
    return function(a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }

  sortByTray(key) {
    let arrayCopy = [...this.state.selected_trays]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareByTray(key))
    } else {
      arrayCopy.sort(this.compareByTray(key)).reverse()
    }

    this.setState({
      selected_trays: arrayCopy,
      isReverse: !this.state.isReverse,
    })
  }

  compareByTray(key) {
    if (key == 'specialty') {
      return function(a, b) {
        if (a.specialty.name < b.specialty.name) return -1
        if (a.specialty.name > b.specialty.name) return 1
        return 0
      }
    }
    return function(a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }

  addQty = (item, index) => {
    let { selected_items } = this.state

    item.qty += 1

    selected_items[index] = item
    this.setState({
      selected_items: selected_items,
    })
  }

  updateInstrument = () => {
    const { stage_master } = this.props
    if(stage_master.draft == 0){
      Event('Procedure', 'Update', 'Instruments')
    }
    procedureAction(stage_master.id, 0)
    let data = new FormData()
    data.append('_method', 'PATCH')

    let url = `/api/client/stage-instrument-update/${stage_master.id}`

    let config = {
      headers: {
        'content-type': 'multipart/form-data',
      },

      onUploadProgress: progressEvent => {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        showLoader(percentCompleted >= 95 ? 95 : percentCompleted)
        setLoaderProg(percentCompleted >= 95 ? 95 : percentCompleted)
      },
    }

    axios
      .post(url, data, config)
      .then(res => {
        this.props.getProcedureDetail(0)
        this.setState({
          edit: false,
          draft_success: false,
          showNotification: true,
          notificationMessage: 'Instruments saved!',
        })
        this.refNotifier.showThis()
        setLoaderProg(99)
        setTimeout(() => {
          hideLoader()
        }, 800)
      })
      .catch(error => {
        console.log(error)
        console.log(error.reponse)
        setLoaderProg(99)
        setTimeout(() => {
          hideLoader()
        }, 800)
      })
  }

  saveTray = () => {

    const { tray_name, specialty} = this.state
    let edit = false
    
    this.setState({
      adding_tray: true
    })
 
    const form = new FormData
    form.append('name', tray_name)
    form.append('specialty_id', specialty)

    axios.post(`api/tray`, form).then(result => {
      console.log('api-tray', result) 
      
      $("#registerNewTrayModal").modal('hide')
      $("#successNewTray").modal('show')
      
      this.setState({
        adding_tray: false
      })
    }).catch(error => {
      console.log(error)
      console.log(error.response)
      this.setState({
        errors: error.response.data.errors,
        adding_tray: false
      })
    })
  }

  editContent = () => {

    const { stage_details } = this.props
    let detaillist = stage_details.map((detail, index) => {
      if (true) {
        let itemlist = this.state.selected_items.filter(item => item.item_category == detail.id)//detail.items
        return (
          <div className="w-100 float-left">
            <Modal
              idModal="registerNewTrayModal"
              size="modal-sm"
              title="Add new tray" 
              confirmText="Add tray"
              confirmDisabled={this.state.stage_type == '' || this.state.adding_tray}
              cancelText="Cancel"
              bodyClass="add-glove-modal-body"
              confirm={e => {
                this.saveTray()
              }}
              cancel={e => {
                //fn here
              }}
            >
                

              <div className="form-group mb20 mt40">
                <TextInput
                  label={'Tray name'}
                  value={this.state.tray_name}
                  placeholder={'Name'}
                  errorMessage={getError(this.state.errors, 'name')}
                  containerClass={`mb10`}
                  onValueChange={event => {
                    this.setState({ tray_name: event.target.value })
                  }}
                />
              </div>
              <div className="form-group mb20 mt40">
                <Picker
                
                  errorMessage={getError(this.state.errors, 'specialty_id')}
                  onChange={event => {
                    this.setState({ specialty: event.target.value })
                  }}
                  label={`Assign specialty`}
                  value={this.state.specialty}
                  data={this.state.specialties}
                  required
                />  
              </div>

            </Modal>
            {detail.tray_id ? '' : detail.name == 'Select category' ? '' : (
              <div
                className="col-md-12 mx-auto mb20"
                id={`edit-detail-instruments-${detail.id}-${index}`}
              >
                <Collapsible title={detail.name} stickyHeader key={`edit-detail-supplies-${detail.id}-${index}`} headerClass={` ${this.state.activeIndex == index ? 'font-weight-bold text-underline' : ''}`}>

                  <div className="w-100 d-flex justify-content-end pb16">
                    <Button secondary link
                      className="mr-0"
                      onClick={(event) => {
                        event.stopPropagation();
                        this.setState({
                          change: !this.state.change,
                          selectedCategory: detail.id
                        }, () => {
                          $('#assignItemsModalCenter').modal({
                            show: true,
                            keyboard: false
                          })
                          $('#assignItemsModalCenter').on('shown.bs.modal', function () {
                            document.querySelectorAll('.tippy-popper').forEach(popper => {
                              popper._tippy.hide()
                            })
                          })
                        })
                      }}
                    >
                      Assign items
                </Button>
                </div>
                  <Table
                    minWidth={650}
                    noResults={0}
                    renderModal={() => {
                      return (
                        <ViewItemCard
                          id={`edit-preview-mainContent-modal-${this.state.unique}`}
                          onRef={ref => (this.viewItemRef = ref)}
                          item={this.state.selectedItem}
                        ></ViewItemCard>
                      )
                    }}
                    renderHeaders={() => {
                      return (
                        <TRow>
                          <THead className="w150" text="Catalogue #" />
                          <THead className="w280" text="Name" />
                          <THead className="w100" text="Size" />
                          <THead className="w100" text="Qty" />
                          <THead className="w80" text="" />
                        </TRow>
                      )
                    }}
                    renderRows={() => {
                      let rows = itemlist.map(item => {
                        return (
                          <TRow>
                            <TData className="w150">
                              {item.catalog_number}
                            </TData>
                            <TData
                              className="w280 has-action"
                              item={item}
                              popover
                              onClick={() => {
                                this.setState({ selectedItem: item })
                                $(
                                  `#edit-preview-mainContent-modal-${this.state.unique}`
                                ).modal('show')
                              }}
                            >
                              {item.name}
                            </TData>
                            <TData className="w100">{item.size}</TData>
                            <TData className="w100">{item.qty}</TData>
                            <TData className="w80"><Button tertiary
                              onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                this.setState({
                                  deleteCategoryId: detail.id,
                                  deleteItemId: item.id
                                })
                                $('#confirmRemoveItem').modal('show')
                              }}>Remove</Button></TData>
                          </TRow>
                        )
                      })
                      return rows
                    }}
                    renderLastRow={() => {
                      if (itemlist.length) {
                        return null
                      }
                      return (
                        <tr>
                          <td className="w-100 text-center" colSpan={5}>
                            No item(s) added. Add new items via{' '}
                            <a
                              href="javascript:;"
                              
                              onClick={(event) => {
                                event.stopPropagation();
                                this.setState({
                                  change: !this.state.change,
                                  selectedCategory: detail.id
                                }, () => {
                                  $('#assignItemsModalCenter').modal({
                                    show: true,
                                    keyboard: false
                                  })
                                  $('#assignItemsModalCenter').on('shown.bs.modal', function () {
                                    document.querySelectorAll('.tippy-popper').forEach(popper => {
                                      popper._tippy.hide()
                                    })
                                  })
                                })
                              }}
                            >
                              Assign items
                            </a>
                            .
                          </td>
                        </tr>
                      )
                    }}
                  />
                </Collapsible>
              </div>
            )}
          </div>

        )
      }
    })


    return (
      <div className="row mt32">
        <ConfirmationModal
          title="Save to draft"
          message="Do you want to save draft?"
          confirmText="Yes"
          cancelText="Cancel"
          confirm={()=>{
            this.refNotifier2.showThis()
            const { stage_master } = this.props
            procedureAction(stage_master.id, 1)
            this.setState({
              draft_success: true,
              showNotification: true,
              notificationMessage: 'Draft has been saved!',
            })
          }}
          idModal="save-to-draftmodal"
          cancel={() => {
            $('.modal').modal('hide')
          }}
        />
        <div className="col-md-12">
          <h2 className="page-description bold">Editing - Instruments</h2>
        </div>
        <div className="col-md-12 mx-auto">
          <EditMenu>
            <li>
              <Button
                primary
                onClick={e => {
                  e.preventDefault()
                  this.updateInstrument()
                  this.props.setNewState({
                    procedure_stage_edit: false
                  })
                }}
              >
                Update
              </Button>
            </li>
            <li>
              <Button
                tertiary
                onClick={e => {
                  e.preventDefault()
                  $('#save-to-draftmodal').modal('show')
                }}
              >
                Save Draft
              </Button>
            </li>
            <li className="has-line">
              <Button
                tertiary
                onClick={() => {
                  this.setState({ edit: false, draft_success: false }, () => {
                    this.props.getProcedureDetail()
                  })
                  this.props.setNewState({
                    procedure_stage_edit: false
                  })
                }}
              >
                Cancel
              </Button>
            </li>
          </EditMenu>
        </div>

        {/* {this.state.draft_success ? <InlineNotification message={'Draft has been saveds!'} /> : null} */}

        <div className="col-md-12 mx-auto mt10">
          <Notifier
            message={this.state.notificationMessage}
            onRef={ref => (this.refNotifier2 = ref)}
            type="success"
            styleClass="mb30 mt0"
            hideThisNotif={() => {
              this.refNotifier2.hideThis()
            }}
          />
          
          {this.props.loading ? 
              <InstrumentsSkeleton /> : 
              <DataCard
            title="Edit - Instruments Categories"
            className="jump-to-section-card edit"
          >
            <ul className="list">
              {this.state.detail_stage.map(category => {
                if (category.items && category.default_value == 1) {
                  return <li className="list-item">{category.name}</li>
                }
              })}
              {/* <SortableList
                items={custom_data}
                pressDelay={150}
                onSortEnd={this.onSortEnd}
                setNewState={this.setNewState}
              /> */}

              <SortableList
                categories={this.state.custom_sections}
                onSortEnd={this.onSortEndSection}
                pressDelay={50}
                assignNewState={this.assignNewState}
                lockToContainerEdges={false}
                disableAutoscroll={true}
                axis='xy'
              />

              {/* <SortableList
                categories={[
                  ...this.state.custom_trays,
                  ...this.state.selected_trays,
                ]}
                actions={0}
                onSortEnd={this.onSortEndTray}
                pressDelay={150}
                assignNewState={this.assignNewState}
              /> */}
              {/* {this.state.addSection ? (
                <li className="add-new-section-form">
                  <TextInput
                    label={'New section name'}
                    value={this.state.section_name}
                    placeholder={'Name'}
                    errorMessage={getError(this.state.errors, 'name')}
                    containerClass={` `}
                    onValueChange={event => {
                      this.setState({ section_name: event.target.value })
                    }}
                  />
                  <div className="action-holder">
                    {this.state.section.id ? (
                      <Button
                        primary
                        onClick={() => {
                          this.editSection()
                        }}
                      >
                        Save
                      </Button>
                    ) : (
                      <Button
                        primary
                        onClick={() => {
                          this.addSection()
                        }}
                      >
                        Save
                      </Button>
                    )}
                    <Button
                      secondary
                      onClick={() => {
                        this.setState({ create_category: false, errors: [] })
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </li>
              ) : (
                <li
                  className="list-item add-new-secion"
                  onClick={() => {
                    this.setState({
                      addSection: !this.state.addSection,
                      section: {},
                      section_name: '',
                    })
                  }}
                >
                  + Add new category
                </li>
              )} */}

              <li
                className="list-item add-new-secion"
                onClick={() => {
                  this.setState({
                    addSection: !this.state.addSection,
                    section: {},
                    section_name: '',
                  }, ()=>{
                      $("#addNewCategory").modal('show')
                  })
                }}
              >
                + Add new category
                </li>
            </ul>
          </DataCard>
          }
       </div>

        {this.props.loading ? '':detaillist}
        
        {/* <div className="col-md-12 mx-auto mb20">
                <DataCard
                  title="Item list"
                  stickyHeader
                  headerAction={() => {
                    return (
                      <div className="d-flex ml-auto">
                        <Button
                          secondary
                          onClick={() => {
                            this.setState(
                              {
                                change: !this.state.change,
                              },
                              () => {
                                $('#assignItemsModalCenter').modal({
                                  show: true,
                                  keyboard: false,
                                })
                                $('#assignItemsModalCenter').on(
                                  'shown.bs.modal',
                                  function() {
                                    document
                                      .querySelectorAll('.tippy-popper')
                                      .forEach(popper => {
                                        popper._tippy.hide()
                                      })
                                  }
                                )
                              }
                            )
                          }}
                        >
                          Assign items
                        </Button>
                      </div>
                    )
                  }}
                >
                  <Table
                    minWidth={755}
                    noResults={this.state.selected_items.length > 0 ? 0 : 1}
                    renderModal={() => {
                      return (
                        <ViewItemCard
                          id={`preview-cons-modal-2-${this.state.unique}`}
                          onRef={ref => (this.viewItemRef = ref)}
                          item={this.state.selectedItem}
                        ></ViewItemCard>
                      )
                    }}
                    renderHeaders={() => {
                      return (
                        <TRow>
                          <THead
                            className="w150"
                            text="Catalogue #"
                            onClick={() => {
                              this.sortBy('catalog_number')
                            }}
                            sortable
                          />
                          <THead
                            className="w250"
                            text="Name"
                            onClick={() => {
                              this.sortBy('name')
                            }}
                            sortable
                          />
                          <THead
                            className="w150"
                            text="Section"
                            onClick={() => {
                              this.sortBy('item_category')
                            }}
                            sortable
                          />
                          <THead
                            className="w100"
                            text="Size"
                            onClick={() => {
                              this.sortBy('size')
                            }}
                            sortable
                          />
                          <THead
                            className="w100"
                            text="Qty"
                            onClick={() => {
                              this.sortBy('qty')
                            }}
                            sortable
                          />
                        </TRow>
                      )
                    }}
                    renderRows={() => {
                      let rows = this.state.selected_items.map(item => {
                        const combind = [
                          ...this.state.detail_stage,
                          ...this.state.custom_sections,
                        ]
                        let section = combind.find(e => {
                          return e.id == item.item_category
                        })

                        return (
                          <TRow>
                            <TData className="w150">{item.catalog_number}</TData>
                            <TData
                              className="w250 has-action"
                              item={item}
                              popover
                              onClick={() => {
                                this.setState({ selectedItem: item })
                                $(`#preview-cons-modal-2-${this.state.unique}`).modal(
                                  'show'
                                )
                              }}
                            >
                              {item.name}
                            </TData>
                            <TData className="w150">
                              {' '}
                              {section ? section.name : '-'}{' '}
                            </TData>
                            <TData className="w100">{item.size}</TData>
                            <TData className="w100">{item.qty}</TData>
                          </TRow>
                        )
                      })
                      return rows
                    }}
                  />
                </DataCard>
              </div>
         */}
        <div className="col-md-12 mx-auto mb20">

          <Collapsible title="Trays" stickyHeader key={`edit-trays`} headerClass={`font-weight-bold`}>

            <div className="w-100 d-flex justify-content-end pb16">
              <Button
                secondary
                onClick={() => {
                  this.setState(
                    {
                      change: !this.state.change,
                    },
                    () => {
                      $('#assignTraysModal').modal({
                        show: true,
                        keyboard: false,
                      })
                    }
                  )
                }}
              >
                Assign trays
                  </Button>
            </div>

            <Table
              minWidth={755}
              renderHeaders={() => {
                return (
                  <TRow>
                    <THead
                      className="w250"
                      text="Tray name"
                      onClick={() => {
                        this.sortByTray('name')
                      }}
                      sortable
                    />
                    <THead className="w150" text="Specialty" />
                  </TRow>
                )
              }}
              renderRows={() => {
                let rows = this.state.selected_trays.map(item => {
                  if(item.tray)
                  return (
                    <TRow>
                      <TData className="w250">{item.name}</TData>
                      <TData className="w150">{item.specialty ? item.specialty.name:''}</TData>
                    </TRow>
                  )
                })
                return rows
              }}
            /> 
          
          </Collapsible> 
        </div>
      </div>
    )
  }

  sectionContent = itemlist => {
    return (
      <div className="w-100 of-auto">
        <Table
          minWidth={650}
          noResults={itemlist.length > 0 ? 0 : 1}
          loading={this.state.loading}
          renderModal={() => {
            return (
              <ViewItemCard
                id={`preview-sectionContent-modal-${this.state.unique}`}
                onRef={ref => (this.viewItemRef = ref)}
                item={this.state.selectedItem}
              ></ViewItemCard>
            )
          }}
          renderHeaders={() => {
            return (
              <TRow>
                <THead className="w150" text="Catalogue #" />
                <THead className="w280" text="Name" />
                <THead className="w100" text="Size" />
                <THead className="w100" text="Qty" />
              </TRow>
            )
          }}
          renderRows={() => {
            let rows = itemlist.map(item => {
              return (
                <TRow>
                  <TData className="w150">{item.catalog_number}</TData>
                  <TData
                    className="w280 has-action"
                    item={item}
                    popover
                    onClick={() => {
                      this.setState({ selectedItem: item })
                      $(
                        `#preview-sectionContent-modal-${this.state.unique}`
                      ).modal('show')
                    }}
                  >
                    {item.name}
                  </TData>
                  <TData className="w100">{item.size}</TData>
                  <TData className="w100">{item.qty}</TData>
                </TRow>
              )
            })
            return rows
          }}
          renderLastRow={() => {
            if (itemlist.length) {
              return null
            }
            return (
              <tr>
                <td className="w-100 text-center" colSpan={4}>
                  No item(s) added. Add new items via{' '}
                  <a
                    href="javascript:;"
                    onClick={e => {
                      e.preventDefault()
                      this.setState({
                        edit: true,
                        draft_success: false,
                      })
                      this.props.getProcedureDetail(1)
                    }}
                  >
                    Edit notes
                  </a>
                  .
                </td>
              </tr>
            )
          }}
        />
      </div>
    )
  }

  getItems = id => {
    this.setState({
      loading: true,
    })
    axios
      .get(`api/tray/${id}`)
      .then(result => {
        this.setState({
          loading: true,
          tray_data: result.data.data,
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  mainContent = () => {
    const { stage_details } = this.props
    console.log('p1p1p1', stage_details)
    let headerlist = stage_details.map(detail => {
      if (detail.items ? detail.items.length : false) {
        return (
          <li className="list-inline-item pr20 fs16 mb15 mx-0">
            <a href={`#${detail.name}`}>{detail.name}</a>
          </li>
        )
      }
    })
    let trayIndex = 0;

    let item_count = 0;
    let item_count2 = 0;
    stage_details.map((detail) => {
      if ((detail.items && detail.items.length > 0) || detail.tray_id > 0){
        item_count += 1;
      }
      if (detail.tray_id && detail.tray) {
        item_count2 += 1;
      }
    })
    console.log('item_count', item_count)

    let detaillist = stage_details.map((detail, index) => {
      if ((detail.items && detail.items.length > 0) || detail.tray_id > 0) {
        let itemlist = detail.items
        return (
          <div className="w-100 float-left">
            {detail.tray_id ? '' : (
              <div
                className="col-md-12 mx-auto mb20"
                id={`detail-instruments-${detail.id}-${index}`}
              >
               <Collapsible title={detail.name} stickyHeader key={`detail-supplies-${detail.id}-${index}`} headerClass={` ${this.state.activeIndex == index ? 'font-weight-bold text-underline' : ''}`}>
                
                  <Table
                    minWidth={650}
                    noResults={itemlist.length > 0 ? 0 : 1}
                    renderModal={() => {
                      return (
                        <ViewItemCard
                          id={`preview-mainContent-modal-${this.state.unique}`}
                          onRef={ref => (this.viewItemRef = ref)}
                          item={this.state.selectedItem}
                        ></ViewItemCard>
                      )
                    }}
                    renderHeaders={() => {
                      return (
                        <TRow>
                          <THead className="w150" text="Catalogue #" />
                          <THead className="w280" text="Name" />
                          <THead className="w100" text="Size" />
                          <THead className="w100" text="Qty" />
                        </TRow>
                      )
                    }}
                    renderRows={() => {
                      let rows = itemlist.map(item => {
                        return (
                          <TRow>
                            <TData className="w150">
                              {item.catalog_number}
                            </TData>
                            <TData
                              className="w280 has-action"
                              item={item}
                              popover
                              onClick={() => {
                                this.setState({ selectedItem: item })
                                $(
                                  `#preview-mainContent-modal-${this.state.unique}`
                                ).modal('show')
                              }}
                            >
                              {item.name}
                            </TData>
                            <TData className="w100">{item.size}</TData>
                            <TData className="w100">{item.qty}</TData>
                          </TRow>
                        )
                      })
                      return rows
                    }}
                    renderLastRow={() => {
                      if (itemlist.length) {
                        return null
                      }
                      return (
                        <tr>
                          <td className="w-100 text-center" colSpan={4}>
                            No item(s) added. Add new items via{' '}
                            <a
                              href="javascript:;"
                              onClick={e => {
                                e.preventDefault()
                                this.setState({
                                  edit: true,
                                  draft_success: false,
                                })
                                this.props.getProcedureDetail(1)
                              }}
                            >
                              Edit notes
                            </a>
                            .
                          </td>
                        </tr>
                      )
                    }}
                  />
                  </Collapsible> 
              </div>
            )}
          </div>
 
        )
      }
    })

    let detailList2 = stage_details.map((detail, index) => {

      if (detail.tray_id && detail.tray){
       return (<div
         className="col-md-12 mx-auto"
         id={`detail-instruments-${detail.id}-${index}`}
       >
         <DataCard
           cardHeaderClass="p15"
           headerClass="font-weight-bold d-flex justify-content-between align-items-center"
           bodyClass="p0"
           className="mb20"
           headerIcon={() => {
             return (
               <a href="javascript:;" className="fs20 ml-auto mr10" onClick={() => {
                 this.getItems(detail.tray.id)
                 this.setState({
                   tray_data: detail.tray,
                   tray_unique:
                     Math.random()
                       .toString(36)
                       .substring(2, 15) +
                     Math.random()
                       .toString(36)
                       .substring(2, 15),

                 }, () => {
                   $('#openTrayDetail').modal('show')
                 })

               }}><i className="fa fa-eye"></i></a>
             )
           }}
           title={`${detail.tray.name} (${detail.tray.total_items})`}
           stickyHeader
           key={`tray-panel-${detail.id}`}

         ></DataCard>
       </div>)
     }
    });

    return (
      <div className="row mt32">
        <div className="col-md-12 mx-auto d-flex justify-content-between align-items-center page-description">
          <h2 className="mb-2 mb-sm-0 bold">Instruments</h2>
          <Button  className="ml-0 mr-2 mr-sm-0"
            tertiary
            onClick={e => {
              e.preventDefault()
              this.setState({
                edit: true
              })
              this.props.setNewState({
                procedure_stage_edit: true
              })
              this.props.getProcedureDetail(1)
            }}
          >
            Edit {this.props.title}
          </Button>
        </div>
        <div className="col-md-12 mt10">
          <Notifier
            message={this.state.notificationMessage}
            onRef={ref => (this.refNotifier = ref)}
            type="success"
            styleClass="mb30 mt0"
            hideThisNotif={() => {
              this.refNotifier.hideThis()
            }}
          />
          {this.props.loading ? 
              <InstrumentsSkeleton /> : 
              <DataCard title="Instruments Categories" className="jump-to-section-card d-none">
                <ul className="list">
                  {stage_details.map((detail, index) => {
                    if (
                      (detail.items && detail.items.length > 0) ||
                      detail.tray_id > 0
                    ) {
                      if (detail.tray_id > 0) {return null}
                      return (
                        <li
                          className={`list-item ${
                            this.state.activeIndex == index
                              ? 'font-weight-bold text-underline'
                              : ''
                          }`}
                        >
                          {/* <a target={`#${detail.name}`}>{detail.name}</a> */}
                          <Button
                            className={`${
                              this.state.activeIndex == index
                                ? 'font-weight-bold text-underline'
                                : ''
                            }`}
                            tertiary
                            onClick={() => {
                              this.setState({
                                activeIndex: index,
                              })
                              $(`#detail-instruments-${detail.id}-${index} .collapse`).collapse('show');
                              $('html, body').animate(
                                {
                                  scrollTop: $(
                                    `#detail-instruments-${detail.id}-${index}`
                                  ).offset().top,
                                },
                                400
                              )
                            }}
                          >
                            {detail.name}
                          </Button>
                        </li>
                      )
                    }
                  })}
                </ul>
              </DataCard>
            }
        </div>

        {this.props.loading ? '' : item_count > 0 ? detaillist:(
          <div className=" col-md-12 mb20">
            <div className="data-card">
              <div className="card-body pt20 ">
                <p className="m0">
                  No item(s) added. Add new items via <a href="javascript:;"
                    onClick={e => {
                      e.preventDefault()
                      this.setState({
                        edit: true
                      })
                      this.props.setNewState({
                        procedure_stage_edit: true
                      })
                      this.props.getProcedureDetail(1)
                    }} >
                    Edit {this.props.title}</a>.
                </p>
              </div>
            </div>
          </div>
        )}
        {this.props.loading ? '' :
          <div className="col-md-12 mt8 mb28 ">
            <h3 className=" bold">Instrument trays</h3>
          </div>
        }
        {this.props.loading ? '' : item_count2 > 0 ? detailList2:(
          <div className=" col-md-12">
            <div className="data-card">
              <div className="card-body pt20 ">
                <p className="m0">
                  No item(s) added. Add tray items via <a href="javascript:;"
                    onClick={e => {
                      e.preventDefault()
                      this.setState({
                        edit: true
                      })
                      this.props.setNewState({
                        procedure_stage_edit: true
                      })
                      this.props.getProcedureDetail(1)
                    }} >
                    Edit {this.props.title}</a>.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }

  scrollToTop = () => {
    $('.scroll-to-top').fadeOut(0)
    $(window).scroll(function() {
      if ($(this).scrollTop() > 350) {
        $('.scroll-to-top').fadeIn()
      } else {
        $('.scroll-to-top').fadeOut()
      }
    })

    $(document).on('click', '.scroll-to-top', function() {
      $('html, body').animate({ scrollTop: $('#home').offset().top - 100 }, 400)
      return false
    })
    setTimeout(() => {
      $('[data-toggle="popover"]').popover()
      $('.popover').popover('hide')
    }, 200)
  }
  


  removeItem = () => {

    const { deleteCategoryId, deleteItemId, detail_stage } = this.state
    const { stage_master } = this.props
    const rem_index = this.state.selected_items.findIndex(item => {
      return item.id == deleteItemId && item.item_category == deleteCategoryId;
    })
    let sel_items1 = this.state.selected_items;
    sel_items1.splice(rem_index, 1);
    console.log('rem_index', this.state.selected_items, deleteItemId, deleteCategoryId)
    this.setState({
      selected_items: sel_items1
    })
    const stage_details_changed = [];

    const formData = new FormData()
    formData.append(`_method`, 'PATCH')
    formData.append('draft', 1)
    var sel_items = [];
    detail_stage.map((category, index) => {
      var cat = category;
      formData.append(`instruments[${index}]`, category.id)
      var items = [];
      this.state.selected_items.map(item => {
        if (item.item_category == category.id) {
          items.push(item);
          formData.append(`item_ids[${index}][]`, item.id)
          formData.append(`qty[${index}][]`, item.qty)
          sel_items.push(item);
        }
      })
      cat['items'] = items;
      sel_items.push(items);
      stage_details_changed.push(cat);
    })
    console.log('stage_details_changed', stage_details_changed)
    axios
      .post(`api/client/stage-instrument-add-items/${stage_master.id}`, formData).then((res) => {
      this.setState({
        detail_stage: stage_details_changed,
        selected_items: sel_items
      })
      $('.modal').modal('hide')
    }).catch((err) => {
      console.log(err)
      console.log(err.response)
    })
  } 

  render() {
    return (
      <div className="w-100 float-left ">
        <div className="scroll-to-top c-pointer">
          <i className="fa fa-chevron-up fs20" />
        </div>
        {this.state.edit ? this.editContent() : this.mainContent()}
        <AssignItemsProcedureInstrument
          assignNewState={this.assignNewState}
          selected_items={this.state.selected_items}
          change={this.state.change}
          item_categories={this.props.stage_details}
          stage_master={this.props.stage_master}
          selected_category={this.state.selectedCategory}
        />
        <AssignTraysProcedureInstrument
          assignNewState={this.assignNewState}
          selected_trays={this.state.selected_trays}
          change={this.state.change}
          item_categories={this.props.stage_details}
          stage_master={this.props.stage_master}
          getProcedureDetail={this.props.getProcedureDetail}
        />

        <FormModal
          idModal="addNewCategory"
          closeModalOnSubmit={false}
          confirmDisabled={this.state.adding_category}
          title="Add new category"
          confirmText="Save"
          cancelText="Close"
          confirm={(e) => {
            this.addSection()
          }}
          cancel={(e) => {
            //fn here
          }}
        >
          <div className="row">
            <div className="col-12">

              <TextInput
                label={'Category name'}
                value={this.state.section_name}
                placeholder={'Type name here...'}
                errorMessage={getError(this.state.errors, 'name')}
                containerClass={''}
                onValueChange={event => {
                  this.setState({ section_name: event.target.value })
                }}
              />
            </div>
          </div>
        </FormModal>

        <ConfirmationModal
          idModal="confirmationDeleteSectionModal"
          title="Delete"
          message="Are you sure you want to delete?"
          confirmText="Yes"
          cancelText="No"
          confirm={() => {
            this.deleteSection()
          }}
          cancel={() => {
            $('#confirmationModal').modal('hide')
          }}
        />


        <ConfirmationModal
          idModal="successNewTray"
          title="Success"
          message="New tray registered."
          confirmText="Okay" 
          confirm={() => {
            $('#successNewTray').modal('hide')
            this.setState(
              {
                change: !this.state.change,
              },
              () => {
                $('#assignTraysModal').modal({
                  show: true,
                  keyboard: false,
                })
              }
            )
          }} 
        />

        <ConfirmationModal
          idModal="confirmRemoveItem"
          title="Remove item?"
          message="Are you sure you want to remove item?"
          confirmText="Yes"
          cancelText="No"
          confirm={() => {
            this.removeItem()
          }}
          cancel={() => {
            $('#confirmRemoveItem').modal('hide')
          }}
        />
        
        <ViewItemModal
          idModal="viewItemInstrumentsLayout"
          key={this.state.unique}
          item={this.state.selectedItem}
          cancel={() => {
            $('#viewItemInstrumentsLayout').modal('hide')
          }}
        />
        <TrayItemModal key={this.state.tray_unique} tray={this.state.tray_data}/>
      </div>
    )
  }
}

export default InstrumentsLayout
