import React, { Component } from 'react'

import ReactDOMServer from 'react-dom/server'
import { TextInput } from '../commons/forms'
import $ from 'jquery'
import _ from 'lodash'
import {
  Table,
  THead,
  TRow,
  TData,
} from '../commons/table'
import { Modal } from '../commons/modals'
import ViewItemCard from '../commons/cards/ViewItemCard'
import axios from 'axios'

export class TrayItemModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      items: [],
      keyword: '',
      page: 1,
      loading: false,
      hasMore: false,
    }

    this.searchData = _.debounce(this.getItems, 600);

  }

  componentDidMount = () => {
    const { tray } = this.props
    if(tray && !tray.id){
      this.getItems()
    }
  }
  getItems = () => {
    const { tray } = this.props
    const { keyword,page } = this.state
    if(page <= 1){
      this.setState({
        loading: true
      })
    }
   
    if (!tray.id) return null

    const url = `api/tray-items/${tray.id}?page=${page}&keyword=${keyword}`
    axios
      .get(url)
      .then(res => {
        this.setState({
          items:
            page === 1 ? res.data.data : [...this.state.items, ...res.data.data],
          loading: false,
          hasMore: res.data.links.next != null,
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  getMoreItems = () => {

    this.setState(
      {
        page: this.state.page + 1,
      },
      () => {
        this.getItems()
        console.log('pagepagepage',this.state.page)
      }
    )
  }

  searchItems({ target: { value } }) {
    this.setState(
      {
        keyword: value,
        page: 1,
        loading: true,
        items: [],
      },
      () => {
        this.searchData()
      }
    )
  }

  render() {
    let itemlist = this.state.items.map((item, index) => {
      let tags = item.tags.map(tag => {
        return ReactDOMServer.renderToStaticMarkup(
          <span className="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">
            {tag.name}
          </span>
        )
      })
      return (
        <tr key={`item-${index}`}>
          <td className="w150 px10">{item.catalog_number}</td>
          <td
            className="w250 font-weight-bold c-pointer text-dark fs-16 px10"
            data-container="body"
            data-trigger="hover"
            data-toggle="popover"
            data-placement="right"
            data-html="true"
            data-content={`
                              <div class="row">
                              <div class="col-md-4 text-center pr-0">
                              <img src=${item.icon} class="bg-dark w-100">
                              </div>
                              <div class="col">
                              <h6 class="mb15">${item.name}</h6>
                              <p>${item.used_for}</p>
                              </div>
                              <div class="col-md-12 mt15">
                              <p class=" mb10">Tags</p>
                              ${tags}
                              </div>
                              </div>`}
            onClick={() => {
              this.setState(
                {
                  selectedItem: item,
                  unique:
                    Math.random()
                      .toString(36)
                      .substring(2, 15) +
                    Math.random()
                      .toString(36)
                      .substring(2, 15),
                },
                () => {
                  $(`#${this.state.unique}`).modal('show')
                }
              )
            }}
          >
            {item.name}
          </td>
          <td className=" w150 px10">
            {' '}
            <p className="m-0 p-0 float-left w-100 fs14">
              {item.company}{' '}
            </p>{' '}
          </td>
          <td className=" w150 px10">
            {' '}
            <p className="m-0 p-0 float-left w-100 fs14">
              {item.category ? item.category.name : ''}
            </p>{' '}
          </td>
          <td className=" text-center w80">{item.size}</td>
         
        </tr>
      )
    })

    const { tray } = this.props
    if (!tray) return null

    return (
      <div>
        <Modal
          idModal="openTrayDetail"
          size="modal-full"
          title={`${tray.name} ${tray.total_items ? `(${tray.total_items})`:''}`}
          confirmText="Ok"
          cancelText="Close"
          confirm={e => {
            $('#openTrayDetail').modal('hide')
          }}
          cancel={e => {
            //fn here
          }}
          footerClass="container"
          className="preview-item-modal"
          bodyClass="assign-items-body"
        >
        <div className="container">

            <div className="row">
              <div className="col-lg-5">
                <TextInput
                  label={'Search item database'}
                  placeholder={'Start typing name, catalogue #…'}
                  className="active"
                  onChange={this.searchItems.bind(this)}
                  value={this.state.keyword}
                  search
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <Table
                  minWidth={990}
                  className="modal-table infi-table fullmodal-table" 
                  renderModal={() => {
                    return (
                      <ViewItemCard
                        id={`tray-view-preview-modal-${this.state.unique}`}
                        onRef={ref => (this.viewItemRef = ref)}
                        item={this.state.selectedItem}
                      ></ViewItemCard>
                    )
                  }}
                  infiniteScroll
                  infiniteHeight={window.innerHeight - 275}
                  infiniteDataLength={this.state.items.length} //This is important field to render the next data
                  infiniteNext={() => {
                    this.getMoreItems()
                  }}

                  loading={this.state.loading}
                  infiniteHasMore={this.state.hasMore}
                  endOfMessageTitle="Nothing else to see..."
                  // endOfMessageMessage="Missing something? Add new items via"
                  // endOfMessageLinkMessage="Edit database"
                  renderHeaders={() => {
                    return (
                      <TRow>
                        <THead className="w40" text="" />
                        <THead className="w150" text="Catalogue #" sortable />
                        <THead className="w290" text="Name" sortable />
                        <THead className="w100" text="Size" sortable />
                        <THead className="w150" text="Company" sortable />
                        <THead className="w150" text="Category" sortable />
                      </TRow>
                    )
                  }}
                  renderRows={() => {
                    let rows = this.state.items.map(item => {
                      return (
                        <TRow
                          hasChoices
                          key={`row-aitt-${item.id}`}

                        >
                          <TData className="w150">{item.catalog_number}</TData>
                          <TData
                            className="w290 has-action"
                            item={item}
                            popover
                            onClick={e => {
                              e.stopPropagation()
                              this.setState({
                                selectedItem: item,
                                unique:
                                  Math.random()
                                    .toString(36)
                                    .substring(2, 15) +
                                  Math.random()
                                    .toString(36)
                                    .substring(2, 15),
                              })
                              $(`#tray-view-preview-modal-${this.state.unique}`).modal('show')
                            }}
                          >
                            {item.name}
                          </TData>
                          <TData className="w100">{item.size}</TData>
                          <TData className="w150">{item.company}</TData>
                          <TData className="w150">
                            {item.category ? item.category.name : ''}
                          </TData>
                        </TRow>
                      )
                    })
                    return rows
                  }}
                // renderNotification={() => {
                //     return (<TableNotificationCard type="success" message="Success message here..." />)
                // }}
                />
              </div>
            </div>
        </div>
        </Modal>
      </div>
    )
  }
}

export default TrayItemModal
