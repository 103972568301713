import React, { Component } from 'react'
import img from '../../../src/assets/images/img.png'
import { SurgeonCard, MediaCard, RecentMediaCard, SavedDraftCard, MyRecordsCard, ItemCard, MedicalInventoryCard, NotificationCard, SurgeonInfoCard } from '../commons/cards'
import UserProfileCard from '../commons/cards/UserProfileCard';

export class Cards extends Component {
    render() {
        return (

            <div className="row">
                <div className="col-md-12 ">

                    <div className="row mb30">
                        <div className="col-12">
                            <h3 className="h3bold mb16">Surgeon Cards</h3>
                            <div className="row">
                                <div className="col-lg-6">
                                    <SurgeonCard surgeon={false} /> 
                                </div>

                                <div className="col-lg-6">
                                    <SurgeonCard surgeon={false} /> 
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row mb30">
                        <div className="col-12">
                            <h3 className="h3bold mb16">Media Cards</h3>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-md-4">
                                    <MediaCard media={false} />
                                </div> 
                                <div className="col-md-4">
                                    <MediaCard media={false} />
                                </div> 
                                <div className="col-md-4">
                                    <MediaCard media={false} />
                                </div> 
                            </div>
                        </div>
                    </div>

                    <div className="row mb30">
                        <div className="col-12">
                            <h3 className="mb24">User profile Card</h3>
                        </div>
                        <div className="col-lg-6">
                            <i>Go to lounge for sample</i>
                            {/* <UserProfileCard  /> */} 

                        </div>
                    </div>

                    <div className="row mb30">
                        <div className="col-12">
                            <h3 className="mb24">Recent Media Card</h3>
                        </div>
                        <div className="col-lg-6">
                            <RecentMediaCard />
                        </div>
                    </div>

                    <div className="row mb30">
                        <div className="col-12">
                            <h3 className="mb24">My records Card</h3>
                        </div>
                        <div className="col-lg-6">
                            <MyRecordsCard />
                        </div>
                    </div>



                    <div className="row mb24">
                        <div className="col-12">
                            <h3 className="mb24">Saved drafts Card</h3>
                        </div>
                        <div className="col-lg-6">
                            <SavedDraftCard title="Saved drafts" />
                        </div>
                    </div>

                    <div className="row mb24">
                        <div className="col-12">
                            <h3 className="mb24">Item Card</h3>
                        </div>
                        <div className="col-lg-4">
                            <ItemCard item={null} />
                        </div>
                        <div className="col-lg-4">
                            <ItemCard item={null} />
                        </div>
                    </div>

                    <div className="row mb24">
                        <div className="col-12">
                            <h3 className="mb24">Medical Inventory Card</h3>
                        </div>
                        <div className="col-lg-5">
                            <MedicalInventoryCard title="Supplies"
                                description="Our list of supplies that you find for XXXXXXX how is supplies different from others."
                                url="/supplies"/>
                        </div> 
                    </div>


                    <div className="row mb24">
                        <div className="col-lg-12">
                            <h3 className="mb24">Surgeon preview Card</h3>
                        </div>
                        <div className="col-lg-5">
                            <SurgeonInfoCard surgeon={[]} specialties={true} />
                        </div>
                    </div>

                    <div className="row mb24">
                        <div className="col-12">
                            <h3 className="mb24">Notification Card</h3>
                        </div>
                        <div className="col-lg-12 mb24">
                            <NotificationCard />
                        </div>
                        <div className="col-lg-12">
                            <NotificationCard />
                        </div>
                    </div>

                </div>

            </div>
        )
    }
}

export default Cards
