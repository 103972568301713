import React, { Component } from 'react'
import { 
  getItems, 
  getMedicalInventoryCategories,
  getItemsPerCategory,
  setItems
} from '../../actions/ItemAction'
import { Link } from 'react-router'
import ReactDOMServer from 'react-dom/server'
import Inbodyloader from '../loader/Inbodyloader'
import { Picker, TextInput } from '../../components/commons/forms'
import { connect } from 'react-redux'
import InfiniteScroll from "react-infinite-scroll-component"
import $ from 'jquery'
import _ from 'lodash' 
import { Table, THead, TRow, TData, TableNotificationCard } from '../commons/table';
import { Modal } from '../commons/modals';
import ViewItemCard from '../commons/cards/ViewItemCard';


export class AssignItemsModalInstruments extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      category: 'all',
      selected_items: [],
      keyword: '',
      inventory: 2,
      page: 1, 
      selectedItem: [],
      unique:
        Math.random().toString(36)
          .substring(2, 15) +
        Math.random().toString(36)
          .substring(2, 15),
    }
  }

  componentDidMount = () => {
    this.props.getMedicalInventoryCategories(2)
    
    this.props.getItemsPerCategory('all', this.props.selected_items, this.state.inventory, '', this.state.page)
  }

  componentWillReceiveProps = (nextProps) => {
    if(this.props.change != nextProps.change){
      this.setState({
        paage: 1,
        keyword: '',
        category: 'all',
        selected_items: nextProps.selected_items
      }, () => {
        this.props.getItemsPerCategory('all', this.state.selected_items, this.state.inventory, '',this.state.page)
      })
    }
  }

  componentDidUpdate() {
    $('[data-toggle="popover"]').popover()
    $('[data-toggle="popover"]').popover('hide')
    if(_.difference(this.props.selected_items, this.state.selected_items).length > 0){
      this.setState({
        selected_items: this.props.selected_items,
        category: 'all',
        keyword: '',
        page: 1
      })
    }
  }

  getMoreItems = () => {
    const { page } = this.state

    this.setState({
      page: page + 1
    }, () => { this.props.getItemsPerCategory(this.state.category, this.state.selected_items, this.state.inventory, '', this.state.page) })
  }


  checkItem = (id) => {
    let { items } = this.props
    let { selected_items } = this.state

    let index = _.findIndex(selected_items, (e) => { return e.id == id })
    if(index >= 0){
      _.remove(selected_items, (e) => { return e.id == id})
    }else{
      index = _.findIndex(items, (e) => { return e.id == id})
      if(!items[index].qty){
        items[index].qty = 1
      }
      selected_items.unshift(items[index])
    }

    this.setState({
      selected_items: selected_items
    })
  }

  checkItemExistence = (id) => {
    let { items } = this.props
    let { selected_items } = this.state

    let index = _.findIndex(selected_items, (e) => { return e.id == id })

    if(index >= 0){
      return true
    }

    return false
  }

  sortBy(key) {
    let arrayCopy = [...this.props.items]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }
    this.props.setItems(arrayCopy)
    this.setState({ isReverse: !this.state.isReverse })
  }

  compareBy(key) {
    if (key == 'category') {
      return function (a, b) {
        if (a.category.name < b.category.name) return -1
        if (a.category.name > b.category.name) return 1
        return 0
      }
    }
    return function (a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }

  render() {
    const { category, keyword } = this.state
    const { inventory_categories, items } = this.props 
    const search = _.debounce(() => {
      this.props.getItemsPerCategory(this.state.category, this.state.selected_items, this.state.inventory, this.state.keyword, this.state.page)
    }, 500)

    const itemlist = items.map((item, index) => {
      let tags = item.tags.map((tag) => {
        return ReactDOMServer.renderToStaticMarkup(
          <span class="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">{tag.name}</span>
        )
      })
      return(
        <tr>
          <td className="w140 px5" >{item.catalog_number}</td>
          <td  className="w230 px5">
            <Link href='#'  className="text-dark fs-16" data-container="body" data-trigger="hover" data-toggle="popover" data-placement="right" data-html="true" data-content={`
                              <div class="row">
                              <div class="col-md-4 text-center pr-0">
                              <img src=${item.icon} class="bg-dark w-100">
                              </div>
                              <div class="col">
                              <h6 class="mb15">${item.name}</h6>
                              <p>${item.used_for}</p>
                              </div>
                              <div class="col-md-12 mt15">
                              <p class=" mb10">Tags</p>
                              ${tags}
                              </div>
                              </div>`}>
              {item.name}
            </Link>
          </td>
          <td className="w100 px5"> <p className="m-0 p-0 float-left w-100 fs14">{item.company} </p> </td>
          <td className="w100 px5"> <p className="m-0 p-0 float-left w-100 fs14">{item.category ? item.category.name : ''}</p> </td>
          <td className="px10 text-left  w80">{item.size ? item.size : '-'}</td> 
          <td className="w150 px5" >
            <div className="w130 d-flex align-items-center justify-content-center">
              <input type="text" name className="form-control text-center p-0 mx10 bg-white" value={item.qty ? item.qty : 1}
                onChange={(event) => {
                  let { selected_items } = this.state
                
                  let index = _.findIndex(selected_items, (e) => { return e.id == item.id})
                  
                  if(index > -1){
                    selected_items[index].qty = event.target.value
                  }
  
                  this.setState({
                    selected_items: selected_items
                  })
                  
                  item.qty = event.target.value
                  items[index] = item
                  this.props.setItems(items)
                }}
              />
            </div>
          </td>
         
          <td className="w70 text-center pt15 px5">
            <a href="#" className="text-dark mt15" onClick={(event) =>  {event.preventDefault(); this.checkItem(item.id)}}>{this.checkItemExistence(item.id) ? 'Remove' : 'Add'}</a>
          </td>
        </tr>
      )
    })
    return (
      <>
        <Modal
          idModal="assignItemsModalCenter"
          size="modal-xl"
          title="Assign items to tray"
          confirmText="Continue"
          cancelText="Cancel"
          confirm={(e) => {
            this.props.assign(this.state.selected_items)
            $("#assignItemsModalCenter").modal('hide');
          }}
          cancel={(e) => {
            //fn here
          }}
          bodyClass="assign-items-body"
        >
          <div className="row">
            <div className="col-lg-5">
              <TextInput
                label={'Search item database'} 
                placeholder={'Start typing name, catalogue #…'} 
                className="active"
                onValueChange={event => {
                  this.setState({ keyword: event.target.value, page: 1 }, () => { search() })
                }} 
                search
              /> 
            </div>
            <div className="col-lg-5">
              <Picker 
                onChange={event => {
                  this.setState({ category: event.target.value, keyword: '', page: 1 }, () => {
                    this.props.getItemsPerCategory(this.state.category, this.state.selected_items, this.state.inventory, '', this.state.page)
                  })
                }}
                label={`Filter by category`}
                value={this.state.category}
                data={this.props.inventory_categories}
              /> 
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <Table minWidth={990} className="modal-table infi-table"

                 renderModal={() => {
                   return (<ViewItemCard id={`reg-tray-preview-modal-${this.state.unique}`} onRef={ref => (this.viewItemRef = ref)} item={this.state.selectedItem} ></ViewItemCard>)
                 }}
 
                infiniteScroll infiniteHeight={311}
                infiniteDataLength={this.props.items.length} //This is important field to render the next data
                infiniteNext={() => { this.getMoreItems()}}
                infiniteHasMore={this.props.more} 

                endOfMessageTitle='Nothing else to see...'
                // endOfMessageMessage="Missing something? Add new items via"
                // endOfMessageLinkMessage="Edit database" 

                renderHeaders={() => {
                  return (
                    <TRow>
                      <THead className="w40" text="" />
                      <THead className="w150" text="Catalogue #" sortable onClick={() => this.sortBy('catalog_number')} />
                      <THead className="w290" text="Name" sortable onClick={() => this.sortBy('name')} />
                      <THead className="w200" text="Company" sortable onClick={() => this.sortBy('company')} />
                      <THead className="w200" text="Category" sortable onClick={() => this.sortBy('category')} />
                      <THead className="w90" text="Size" sortable />
                      <THead className="w90" text="Qty" sortable />
                    </TRow>
                  )
                }}
                renderRows={() => { 
                  let rows = items.map((item,ind) => {
                    const sel_item = this.state.selected_items.find(x =>{ return x.id === item.id })
                    const qty_ = item.qty ? item.qty : sel_item ? sel_item.qty:1
                    console.log('item.qty', qty_)
                    return (
                      <TRow hasChoices key={`row-aitt-${item.id}`} autoCheck={false}>
                        <TData className="w40" onClick={() => { this.checkItem(item.id) }}>
                          <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id={`checkbox-aitt-${item.id}`} checked={this.checkItemExistence(item.id)} />
                            <label className="custom-control-label" htmlFor={`checkbox-aitt-${item.id}`} onClick={(e) => { e.preventDefault() }}></label>
                          </div>
                        </TData>
                        <TData className="w150" onClick={() => { this.checkItem(item.id) }}>{item.catalog_number}</TData>
                        <TData className="w290 has-action" item={item} popover onClick={() => {
                          this.setState({ selectedItem: item })
                          $(`#reg-tray-preview-modal-${this.state.unique}`).modal('show')
                        }}>{item.name}</TData>
                        <TData className="w200">{item.company}</TData>
                        <TData className="w200">{item.category ? item.category.name : ''}</TData>
                        <TData className="w90">{item.size}</TData>
                        <TData className="w90" onClick={(e)=>{e.stopPropagation()}}>
                          <div className="w-100 d-flex align-items-center justify-content-center"> 
                            <input type="text"
                              defaultValue={qty_} className="form-control text-center bg-white" 
                                
                              onChange={(event) => { 
                                let { selected_items } = this.state
                                const itemIndex = ind

                                const index = _.findIndex(selected_items, (e) => { return e.id === item.id })
                                console.log('itemIndex', itemIndex)
                                console.log('index', index)
                                
                                if (index > -1) {
                                  selected_items[index].qty = event.target.value
                                }

                                this.setState({
                                  selected_items: selected_items.splice(0)
                                })

                                item.qty = event.target.value
                                items[itemIndex] = item
                                this.props.setItems(items.splice(0))
                              }}

                              onKeyUp={(event)=>{

                                if ($(event.target).val() <= 0) {
                                  $(event.target).val(1)
                                  let { selected_items } = this.state
                                  const itemIndex = ind

                                  const index = _.findIndex(selected_items, (e) => { return e.id === item.id })
                                  console.log('itemIndex', itemIndex)
                                  console.log('index', index)

                                  if (index > -1) {
                                    selected_items[index].qty = 1
                                  }

                                  this.setState({
                                    selected_items: selected_items.splice(0)
                                  })

                                  item.qty = 1
                                  items[itemIndex] = item
                                  this.props.setItems(items.splice(0))
                                }
                              }}
                            />
                          </div>
                        </TData>
                      </TRow>
                    )
                  })
                  return rows
                }}
              // renderNotification={() => {
              //     return (<TableNotificationCard type="success" message="Success message here..." />)
              // }}

              />

            </div>

          </div>
        </Modal>

        
      </>


    )
  }
}

const mapStateToProps = (state) => {
  return({
    items: state.item.items,
    inventory_categories: state.item.inventory_categories,
    loading: state.item.loading,
    more: state.item.more
  })
}

  export default connect(mapStateToProps, { getItems, getMedicalInventoryCategories, getItemsPerCategory, setItems })(AssignItemsModalInstruments)
