import React, { Component } from 'react'
import axios from 'axios'
import $ from 'jquery'
import { Link } from 'react-router'
import ReactDOMServer from 'react-dom/server'
import ViewItemModal from '../modals/ViewItemModal'
import Confirmation from '../../components/Confirmation'
import Notifier from '../../components/commons/table/Notifier'
import { TextInput, Picker, Button } from '../commons/forms'
import {
  Table,
  THead,
  TRow,
  TData,
  TableNotificationCard,
} from '../commons/table'
import { ConfirmationModal } from '../commons/modals'
import ViewItemCard from '../commons/cards/ViewItemCard'
class Trays extends Component {
  constructor(props) {
    super(props)
    this.state = {
      trays: [],
      selectedTray: null,
      editMode: false,
      tray_specialties: [],
      item_categories: [],
      search: '',
      selectedItemCategory: 'all',
      selectedSpecialty: null,
      selectedItem: null,
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      notify_status: '',
      message: '',
      show: false,
      isReserse: false,
      isReverseItems: false,
      loading: false,
    }
  }

  componentDidMount() {
    this.getTrays()
    this.getTraySpecialties()
  }
  componentDidUpdate() {
    $('[data-toggle="popover"]').popover()
    $('[data-toggle="popover"]').popover('hide')
  }

  renderLoading() {
    if (this.state.loading)
      return (
        <div class="row">
          <div class="col-md-12 text-center pb30">
            <div className="d-flex justify-content-center mb30">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      )

    return (
      <div class="row">
        <div class="col-md-12 text-center mt30">
          <h3 class="h2med mb-15">Nothing else to see...</h3>
          <p class="bodyregular mb0">
            Missing something? Add new items via{' '}
            <a
              href="javascript:;"
              onClick={() => {
                this.setState({
                  isEditMode: true,
                })
              }}
            >
              Edit database
            </a>
          </p>
        </div>
      </div>
    )
  }

  getTrays() {
    const { search, selectedSpecialty } = this.state

    axios
      .get(
        `api/tray?specialty=${
          selectedSpecialty == null ? '' : selectedSpecialty
        }&query=${search}`
      )
      .then(result => {
        console.log(result)
        this.setState({
          trays: result.data.data,
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  getTraySpecialties() {
    axios
      .get(`api/tray-specialties`)
      .then(result => {
        console.log(result)
        this.setState({
          tray_specialties: result.data.data,
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  getItemCategories(id) {
    axios
      .get(`api/tray-item-category/${id}`)
      .then(result => {
        console.log(result)
        this.setState({
          item_categories: result.data.data,
          selectedItemCategory: 'all',
        })
      })
      .catch(error => {
        console.log(error)
      })
  }
  getItems = id => {
    this.setState(
      {
        loading: true,
        
      },
      () => {
        axios
          .get(`api/tray/${id}`)
          .then(result => {
            console.log(result)
            this.setState({
              selectedTray: result.data.data,
              loading: false,
            })
          })
          .catch(error => {
            console.log(error)
          })
      }
    )
  }

  componentDidUpdate() {}

  specialtyChange(event) {
    event.preventDefault()
    this.setState(
      {
        selectedSpecialty: event.target.value,
      },
      () => {
        this.getTrays()
      }
    )
  }

  itemCategoryChange(event) {
    this.setState({
      selectedItemCategory: event.target.value,
    })
  }
  searchTray(event) {
    event.preventDefault()
    this.setState(
      {
        search: event.target.value,
      },
      () => {
        this.getTrays()
      }
    )
  }

  compareBy(key) {
    if (key == 'specialty') {
      return function(a, b) {
        if (a.specialty.name < b.specialty.name) return -1
        if (a.specialty.name > b.specialty.name) return 1
        return 0
      }
    }

    if (key == 'category') {
      return function(a, b) {
        if (a.category.name < b.category.name) return -1
        if (a.category.name > b.category.name) return 1
        return 0
      }
    }

    return function(a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }

  sortBy(key) {
    let arrayCopy = [...this.state.trays]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }
    this.setState({ trays: arrayCopy, isReverse: !this.state.isReverse })
  }
  sortByItems(key) {
    let origArray = this.state.selectedTray
    let arrayCopy = [...this.state.selectedTray.items]
    if (this.state.isReverseItems) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }
    origArray.items = arrayCopy
    this.setState({
      selectedTray: this.state.selectedTray,
      isReverseItems: !this.state.isReverseItems,
    })
  }

  deleteItem(event) {
    event.preventDefault()
    const { selectedTray } = this.state
    axios
      .post(`api/tray/${selectedTray.id}`, {
        _method: 'DELETE',
      })
      .then(() => {
        this.setState({
          trays: this.state.trays.filter(i => i.id != selectedTray.id),
          isCreate: false,
          notify_status: 'positive',
          message: 'Tray success fully deleted!',
          show: true,
          selectedTray: null,
        })
        setTimeout(() => {
          this.setState({
            show: false,
          })
          if ($('input:checked')) {
            $('input:checked').checked = false
          }
        }, 5000)
      })
      .catch(error => {
        console.log(error.response)
      })
  }
  renderTrayTable() {
    return (
      <Table
        minWidth={this.state.editMode ? 600 : 370}
        className="modal-table has-placeholder max-h400"
        renderHeaders={() => {
          return (
            <TRow>
              <THead className="w40" text=" " />
              <THead
                className={this.state.editMode ? 'w250' : 'w200'}
                text="Category"
                sortable
                onClick={() => {
                  this.sortBy('name')
                }}
              />
              <THead className="w120" text="Specialty" />
              {this.state.editMode ? (
                <THead className="w200" text="Edits" />
              ) : null}
            </TRow>
          )
        }}
        renderRows={() => {
          let rows = this.state.trays.map(tray => {
            return (
              <TRow
                hasChoices
                key={`tray-row-${tray.id}`}
                onClick={() => {
                  this.getItems(tray.id)
                  this.getItemCategories(tray.id)
                }}
              >
                <TData className="w40">
                  <div className="custom-control custom-radio custom-control-inline">
                    <input
                      type="radio"
                      id={`tray-${tray.id}`}
                      name={`tray-id`}
                      className="custom-control-input"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`tray-${tray.id}`}
                    ></label>
                  </div>
                </TData>
                <TData className={this.state.editMode ? 'w250' : 'w200'}>
                  {tray.name} ({tray.total_items})
                </TData>
                <TData className="w120">{tray.specialty.name}</TData>
                {this.state.editMode ? (
                  <TData className="w200 actions">
                    <div className="d-flex justify-content-start">
                      <Button
                        tertiary
                        link
                        url={{
                          pathname: `/medical-inventory-instruments/edit-tray/${tray.id}`,
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        tertiary
                        datatarget="#deleteTrayModal"
                        datatoggle="modal"
                        onClick={e => {
                          e.stopPropagation()
                          this.getItems(tray.id)
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  </TData>
                ) : null}
              </TRow>
            )
          })
          return rows
        }}
        // renderNotification={() => {
        //   return (<TableNotificationCard type="error" message="No procedure selected" onRef={ref => (this.categoryTableNotif = ref)} />)
        // }}
      />
    )
  }

  renderItemsTable() {
    console.log('this.state.loading', this.state.loading)
    return (
      <Table
        minWidth={this.state.editMode ? 450 : 720}
        className="modal-table has-placeholder"
        key="renderItemsTable"
        loading={this.state.loading}
        renderModal={() => {
          return (
            <ViewItemCard
              id={`ins-tray-preview-modal-${this.state.unique}`}
              onRef={ref => (this.viewItemRef = ref)}
              item={this.state.selectedItem}
            ></ViewItemCard>
          )
        }}
        renderHeaders={() => {
          return (
            <TRow>
              {this.state.editMode ? (
                <>
                  <THead
                    className="w250"
                    text="Item Name"
                    sortable
                    onClick={() => {
                      this.sortByItems('name')
                    }}
                  />
                  <THead
                    className="w85 text-center"
                    text="Qty"
                    sortable
                    onClick={() => {
                      this.sortByItems('qty')
                    }}
                  />
                  <THead
                    className="w85"
                    text="Size"
                    sortable
                    onClick={() => {
                      this.sortByItems('size')
                    }}
                  />
                </>
              ) : (
                <>
                  <THead
                    className="w150"
                    text="Catalogue #"
                    sortable
                    onClick={() => {
                      this.sortByItems('catalog_number')
                    }}
                  />
                  <THead
                    className="w250"
                    text="Item Name"
                    sortable
                    onClick={() => {
                      this.sortByItems('name')
                    }}
                  />
                  <THead
                    className="w150"
                    text="Category"
                    sortable
                    onClick={() => {
                      this.sortByItems('category')
                    }}
                  />
                  <THead className="w80 text-center" text="Qty" />
                  <THead className="w80" text="Size" />
                </>
              )}
            </TRow>
          )
        }}
        renderRows={() => {
          let rows = []
          if (this.state.selectedTray != null) {
            rows = this.state.selectedTray.items.map(item => {
              if (
                item.medical_category_id == this.state.selectedItemCategory ||
                this.state.selectedItemCategory == 'all'
              ) {
                return (
                  <TRow key={`item-row-tray-${item.id}`}>
                    {this.state.editMode ? (
                      <>
                        <TData
                          className="w250 has-action"
                          item={item}
                          popover
                          onClick={() => {
                            this.setState({ selectedItem: item })
                            $(
                              `#ins-tray-preview-modal-${this.state.unique}`
                            ).modal('show')
                          }}
                        >
                          {item.name}
                        </TData>
                        <TData className="w85 text-center">{item.qty}</TData>
                        <TData className="w85 ">{item.size}</TData>
                      </>
                    ) : (
                      <>
                        <TData className="w150">{item.catalog_number}</TData>
                        <TData
                          className="w250 has-action"
                          item={item}
                          popover
                          onClick={() => {
                            this.setState({ selectedItem: item })
                            $(
                              `#ins-tray-preview-modal-${this.state.unique}`
                            ).modal('show')
                          }}
                        >
                          {item.name}
                        </TData>
                        <TData className="w150">
                          {item.category ? item.category.name : null}
                        </TData>
                        <TData className="w80 text-center">{item.qty}</TData>
                        <TData className="w80">{item.size}</TData>
                      </>
                    )}
                  </TRow>
                )
              }
            })
          }
          return rows
        }}
        // renderNotification={() => {
        //   return (<TableNotificationCard type="error" message="No procedure selected" onRef={ref => (this.categoryTableNotif = ref)} />)
        // }}
      />
    )
  }
  render() {
    const { trays, selectedTray } = this.state
    let trayItemList = null
    if (selectedTray) {
      trayItemList = selectedTray.items.map((item, index) => {
        if (this.state.selectedItemCategory) {
          if (this.state.selectedItemCategory != item.medical_category_id) {
            return null
          }
        }
        let tags = item.tags.map(tag => {
          return ReactDOMServer.renderToStaticMarkup(
            <span className="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10">
              {tag.name}
            </span>
          )
        })
        if (this.state.editMode) {
          return (
            <tr>
              <td
                className="w200 px10"
                data-container="body"
                data-trigger="hover"
                data-toggle="popover"
                data-placement="right"
                data-html="true"
                data-content={`
                              <div class="row">
                              <div class="col-md-4 text-center pr-0">
                              <img src=${item.icon} class="bg-dark w-100">
                              </div>
                              <div class="col">
                              <h6 class="mb15">${item.name}</h6>
                              <p>${item.used_for}</p>
                              </div>
                              <div class="col-md-12 mt15">
                              <p class=" mb10">Tags</p>
                              ${tags}
                              </div>
                              </div>`}
              >
                <div
                  class="btn btn-link text-left py-0 fs14"
                  onClick={() => {
                    this.setState(
                      {
                        selectedItem: item,
                        unique:
                          Math.random()
                            .toString(36)
                            .substring(2, 15) +
                          Math.random()
                            .toString(36)
                            .substring(2, 15),
                      },
                      () => {
                        $('#viewItemModal1').modal('show')
                        $('.pover').popover('hide')
                      }
                    )
                  }}
                >
                  {item.name}
                </div>
              </td>
              <td>{item.qty}</td>
              <td className="text-center">{item.size ? item.size : '-'}</td>
            </tr>
          )
        } else {
          return (
            <tr>
              <td>{item.catalog_number}</td>
              <td
                className="w200 px10"
                data-container="body"
                data-trigger="hover"
                data-toggle="popover"
                data-placement="right"
                data-html="true"
                data-content={`
                              <div class="row">
                              <div class="col-md-4 text-center pr-0">
                              <img src=${item.icon} class="bg-dark w-100">
                              </div>
                              <div class="col">
                              <h6 class="mb15">${item.name}</h6>
                              <p>${item.used_for}</p>
                              </div>
                              <div class="col-md-12 mt15">
                              <p class=" mb10">Tags</p>
                              ${tags}
                              </div>
                              </div>`}
              >
                <div
                  class="btn btn-link text-left py-0 fs14"
                  onClick={() => {
                    this.setState(
                      {
                        selectedItem: item,
                        unique:
                          Math.random()
                            .toString(36)
                            .substring(2, 15) +
                          Math.random()
                            .toString(36)
                            .substring(2, 15),
                      },
                      () => {
                        $('#viewItemModal1').modal('show')
                        $('.pover').popover('hide')
                      }
                    )
                  }}
                >
                  {item.name}
                </div>
              </td>
              <td>{item.qty}</td>
              <td>{item.category.name}</td>
              <td className="text-center">{item.size ? item.size : '-'}</td>
            </tr>
          )
        }
      })

      if (selectedTray.items.length == 0) {
        if (this.state.editMode) {
          trayItemList = (
            <tr>
              <td colSpan={3}>
                <b>No items found.</b>
              </td>
            </tr>
          )
        } else {
          trayItemList = (
            <tr>
              <td colSpan={5}>
                <b>No items found.</b>
              </td>
            </tr>
          )
        }
      }
    }

    const filterSpecialty = this.state.tray_specialties.map((data, index) => {
      return (
        <option value={data.id} key={index}>
          {data.name}
        </option>
      )
    })

    const filterCategories = this.state.item_categories.map((data, index) => {
      return (
        <option value={data.id} key={index}>
          {data.name}
        </option>
      )
    })

    const trayList = this.state.trays.map((tray, index) => {
      return (
        <tr
          onClick={() => {
            this.setState({
              selectedTray: tray,
            })
            this.getItemCategories(tray.id)
            $(`#tray_list_row_${tray.id}`)
              .find('label')
              .click()
            setTimeout(() => {
              $('[data-toggle="popover"]').popover()
              $('[data-toggle="popover"]').popover('hide')
            }, 1000)
            $('html, body').animate(
              { scrollTop: $('#trays').offset().top - 20 },
              0
            )
          }}
          className={
            this.state.selectedTray == tray
              ? 'active-row c-pointer'
              : ' c-pointer'
          }
          id={`tray_list_row_${tray.id}`}
        >
          <td className="text-left w40 pr-0" key={`tray-key-${index}`}>
            <div
              className="custom-control custom-radio custom-control-inline c-pointer"
              style={{ marginRight: '-35px', paddingLeft: '25px' }}
            >
              <input
                type="radio"
                name="radiobtn"
                id={tray.id}
                className="custom-control-input c-pointer"
              />
              <label
                className="custom-control-label  c-pointer"
                htmlFor={tray.id}
              >
                &nbsp;
              </label>
            </div>
          </td>
          <td className="font-weight-bold">{tray.name}</td>
          <td>{tray.specialty.name}</td>
          {this.state.editMode ? (
            <td>
              <Link
                to={{
                  pathname: `/medical-inventory-instruments/edit-tray/${tray.id}`,
                }}
                className="mr10"
              >
                Edit
              </Link>
              <a
                href="javascript:;"
                data-target="#deleteTrayModal"
                data-toggle="modal"
                onClick={e => {
                  e.stopPropagation()
                  this.setState({ selectedTray: tray })
                }}
              >
                Delete
              </a>
            </td>
          ) : null}
        </tr>
      )
    })
    return (
      <>
        <ConfirmationModal
          title="Delete Tray"
          message="Deleting tray will remove it from the database and will have to be added back manually."
          confirmText="Delete Tray"
          cancelText="Cancel"
          idModal="deleteTrayModal"
          confirm={this.deleteItem.bind(this)}
          cancel={() => {
            $('deleteTrayModal').modal('hide')
          }}
        />

        <div className="col-md-12 px0">
          <div className="row">
            <div
              className={`${
                this.state.editMode
                  ? 'col-xl-6 col-lg-6'
                  : 'col-xl-4 col-lg-5 col-md-6'
              }`}
            >
              {this.state.editMode ? (
                <div className="row page-toolbar pt24">
                  <div className="col-lg-6 d-flex align-items-end sub-div h44">
                    <TextInput
                      id="search_tray"
                      inputClass="active"
                      placeholder="Start typing name, catalogue #…"
                      onChange={this.searchTray.bind(this)}
                      label="Search for a tray"
                    />
                  </div>
                  <div className="col-lg-6 d-flex align-items-end sub-div h44">
                    <Picker
                      onChange={this.specialtyChange.bind(this)}
                      label="Filter trays by specialty"
                    >
                      <option value="">All</option>
                      {filterSpecialty}
                    </Picker>
                  </div>
                </div>
              ) : (
                <div className="row page-toolbar pt24">
                  <div className="col-lg-8 col-md-10 d-flex align-items-end sub-div h44">
                    <Picker
                      onChange={this.specialtyChange.bind(this)}
                      label="Filter trays by specialty"
                    >
                      <option value="">All</option>
                      {filterSpecialty}
                    </Picker>
                  </div>
                </div>
              )}

              <div className="card float-left w-100 mh350">
                <div className="card-body p-0">{this.renderTrayTable()}</div>
              </div>
            </div>

            <div
              className={`${
                this.state.editMode
                  ? 'col-xl-6 col-lg-6'
                  : 'col-xl-8 col-lg-7 col-md-6'
              }`}
            >
              <div className="row page-toolbar pt24 w-100">
                {this.state.editMode ? (
                  <div className="col-md-12 d-flex">
                    <Button
                      secondary
                      link
                      url="/medical-inventory-instruments/new-tray"
                    >
                      Register new tray
                    </Button>
                    <Button
                     className="ml-auto"
                      primary
                      onClick={() => {
                        this.setState({ editMode: false })
                      }}
                    >
                      Update
                    </Button>
                  </div>
                ) : (
                  <>
                    <div className="col-lg-5 col-md-12 d-flex align-items-end sub-div h44">
                      <Picker
                        onChange={this.itemCategoryChange.bind(this)}
                        label="Filter items by"
                        value={this.state.selectedItemCategory}
                      >
                        <option value="all">All</option>
                        {filterCategories}
                      </Picker>
                    </div>
                    <div className="col">
                    <Button
                      secondary
                      onClick={() => {
                        this.setState(
                          {
                            editMode: true,
                          },
                          () => {
                            $('[data-toggle="popover"]').popover()
                            $('[data-toggle="popover"]').popover('hide')
                          }
                        )
                      }}
                    >
                      Edit database
                    </Button>
                    </div>
                  </>
                )}
              </div>

              <div className="row w-100">
                <div className="col-md-12">
                  <div className="card mh350">
                    <div className="card-body p-0 of-auto">
                      {this.renderItemsTable()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Trays
