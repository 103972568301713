import React, { Component } from 'react'
import { getMedicalInventories,getMedicalInventoryCategories, getItemsPerCategory, setItems, getItems,itemLoading } from '../../actions/ItemAction'
import { updateQuicknotesItemList } from '../../actions/QuicknotesActions'
import ReactDOMServer from 'react-dom/server'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import _ from 'lodash'
import $ from 'jquery'
import ViewItemModal from '../modals/ViewItemModal'
import InfiniteScroll from "react-infinite-scroll-component"
import { Picker, TextInput } from '../commons/forms'
import { Modal } from '../commons/modals';
import { Tabs, TabHead, TabNavigation, TabBody, TabContent } from '../commons/tabs';
import { Table, TRow, THead, TData } from '../commons/table';
import ViewItemCard from '../commons/cards/ViewItemCard';
import { Pills } from '../commons/misc';
import { Event } from '../../utilities/analytics'

export class AssignItemsModal extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      categories: [],
      selected_items: [],
      selected_items_review: [],
      category_filter: '',
      inventory_filter: '',
      inventory: 1,
      category: 'all',
      change: false,
      counters:{
        supplies: 0,
        instruments: 0,
        equipments: 0,
      },
      selectedItem: null,
      prn_items: [],
      or_items: [],
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      page: 1,
    }
    this.searchData = _.debounce(this.getItemResults, 600)
  }
  
  componentDidMount = () => {
    this.props.getMedicalInventories() 

    this.props.getItemsPerCategory('all', this.state.selected_items, this.state.inventory, '', this.state.page)
    
    $("#assignItemsModalCenter").on('shown.bs.modal', () => { 
      //this.tabsRef.reInit()
    })
  } 

  getMoreItems = () => {
    const { page } = this.state

    this.setState({
      page: page + 1
    }, () => { this.props.getItemsPerCategory(this.state.category, this.state.selected_items, this.state.inventory, '', this.state.page) })
  }

  componentWillReceiveProps = (nextProps) => { 
    if(this.props.change != nextProps.change){
      this.setSelected(nextProps)

      let selected_category_props = nextProps.item_list.find(x=> x.category_name == nextProps.selected_category)
      let supplies_counter = 0;
      let instruments_counter = 0;
      let equipments_counter = 0;
      if (selected_category_props){
        selected_category_props.details.map(detail =>{ 
          console.log('detail', detail)
            if (detail.item.category.medical_inventory_id == 1) {
              supplies_counter++;
            }
            if (detail.item.category.medical_inventory_id == 2) {
              instruments_counter++;
            }
            if (detail.item.category.medical_inventory_id == 3) {
              equipments_counter++;
            } 
        })
      }
      this.setState({
        inventory: 1,
        page: 1,
        keyword: '',
        category: 'all', 
        counters: {
          supplies:supplies_counter,
          instruments:instruments_counter,
          equipments:equipments_counter,
        } 
      }, () => {
        this.props.getMedicalInventoryCategories(this.state.inventory)
        this.props.getItemsPerCategory('all', this.state.selected_items, this.state.inventory, '',this.state.page)

        
      })

      $('#supplies-tab').tab('show')
    }
  }

  componentDidUpdate() {
    $('[data-toggle="popover"]').popover()
    $('.popover').popover('hide')
  }

  setSelected = (props) => {
    let { item_list, selected_category } = props

    let temp = []
    item_list.map((list, index) => {
      list.details.map((i) => {
        let item = i.item
        item.prn = null
        if(index == 0){
          item.prn = list.id
        }
        item.qty = i.qty
        temp.push(item)
      })
    })
    let a = 0, b = 0, c = 0
    let prn_items = []
    let or_items = []
    temp.map((data) => {
      if (data.prn) {
        prn_items.push(data)
      } else {
        or_items.push(data)
      }
      if (data.category.medical_inventory_id == 1)
        a++;
      if (data.category.medical_inventory_id == 2)
        b++;
      if (data.category.medical_inventory_id == 3)
        c++;
    })

    this.setState({
      selected_items: selected_category == 'PRN' ? prn_items : or_items,
      prn_items: prn_items,
      or_items: or_items,
      counters: {
        supplies: a,
        instruments: b,
        equipments: c,
      }
    })
  }

  checkItem = (id) => {
    let { selected_items } = this.state
    const { items, selected_category } = this.props

    let item = items.find((e) => e.id === id)
    if(item){
      if(!item.qty){
        item.qty = 1
      }
    }else{
      item = selected_items.find((e) => e.id === id)
    }
    
    if(selected_items.find((e) => e.id === id)){
      this.setState({
        selected_items: selected_items.filter(x => x.id !== id),
        counters: {
          supplies: this.state.counters.supplies + (item.category.medical_inventory_id == 1 ? -1:0),
          instruments: this.state.counters.instruments + (item.category.medical_inventory_id == 2 ? -1:0),
          equipments: this.state.counters.equipments + (item.category.medical_inventory_id == 3 ? -1:0),
        },
      })
    }else{
      selected_items.push(item)
      
      this.setState({
        selected_items: selected_items,
        counters: {
          supplies: this.state.counters.supplies + (item.category.medical_inventory_id == 1 ? 1 : 0),
          instruments: this.state.counters.instruments + (item.category.medical_inventory_id == 2 ? 1 : 0),
          equipments: this.state.counters.equipments + (item.category.medical_inventory_id == 3 ? 1 : 0),
        },
      })
      if (selected_category == 'PRN') {
        this.setPRN(id)
      }
    }
  }

  checkItemExistence = (id) => {
    let { selected_items } = this.state
    
    let exist = false
    if(selected_items.filter((e) => e.id === id).length > 0){
      exist = true
    }else{
      exist = false
    }

    return exist
  }

  isChecked = (id) => {
    let { selected_items } = this.state
    const { items } = this.props

    let item_local = selected_items.find((e) => e.id === id)
    let item_store = items.find((e) => e.id === id)

    let checked = false
    if(item_local){
      if(item_local.prn){
        checked = true
      }
    }else{
      if(item_store){
        if(item_store.prn){
          checked = true
        }
      }
      
    }

    return checked
  }

  setPRN = (id) => { 
    let { selected_items } = this.state
    let { item_list, items } = this.props
    // // console.log(this.props.item_list)
    let prn_id = item_list[0].id

    let item_local = selected_items.find( x => x.id === id)

    selected_items.map((selected) => {
      items.map((item, index) => {
        if(selected.id == item.id){
          items[index] = selected
        }
      })
    })

    let item_store = items.find((e) => e.id === id)

    if(item_store){
      if(item_store.prn){ 
        item_store.prn = null
      }else{ 
        item_store.prn = prn_id
      }
       
      items[items.findIndex((e) => e.id === id)] = item_store

      selected_items[selected_items.findIndex((e) => e.id === id)] = item_store
      this.setState({
        selected_items: selected_items
      })

    }else{
      if(item_local.prn){ 
        item_local.prn = null
      }else{ 
        item_local.prn = prn_id
      }

      selected_items[selected_items.findIndex((e) => e.id === id)] = item_local
      this.setState({
        selected_items: selected_items
      })
    }
      

    
    this.setState({
      change: true
    })

    this.props.setItems(items)
  }

  sortBy(key) {
    let arrayCopy = [...this.props.items]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }

    this.props.setItems(arrayCopy)

    this.setState({ isReverse: !this.state.isReverse })
  }

  sortSelectedBy(key) {
    let arrayCopy = [...this.state.selected_items_review]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }

    this.setState({ isReverse: !this.state.isReverse, selected_items_review: arrayCopy })
  }

  compareBy(key) {
    if (key == 'category') {
      return function (a, b) {
        if (a.category.name < b.category.name) return -1
        if (a.category.name > b.category.name) return 1
        return 0
      }
    }
    return function (a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }

  changeQty = (id, type) => {
    let { selected_items } = this.state
    let { items } = this.props

    let item_local = selected_items.find((e) => e.id === id)

    selected_items.map((selected) => {
      items.map((item, index) => {
        if(selected.id == item.id){
          items[index] = selected
        }
      })
    })

    let item_store = items.find((e) => e.id === id)

    if(item_store){
      if(type == 'increment'){
        if(!item_store.qty){
          item_store.qty = 1
        }
        item_store.qty = item_store.qty + 1
      }else if(type == 'decrement'){
        if(!item_store.qty){
          item_store.qty = 1
        }
        if(item_store.qty > 1){
          item_store.qty = item_store.qty - 1
        }
      }
      items[items.findIndex((e) => e.id === id)] = item_store

      selected_items[selected_items.findIndex((e) => e.id === id)] = item_store

    }else{
      if(type == 'increment'){
        if(!item_local.qty){
          item_local.qty = 1
        }
        item_local.qty = item_local.qty + 1
      }else if(type == 'decrement'){
        if(!item_local.qty){
          item_local.qty = 1
        }
        if(item_local.qty > 1){
          item_local.qty = item_local.qty - 1
        }
      }

      selected_items[selected_items.findIndex((e) => e.id === id)] = item_local
      
    }

    this.setState({
      selected_items: selected_items,
      change: true

    })


    this.props.setItems(items)
  }

  getItemsPerCategory = (event) => {
    const { selected_items } = this.state
    this.props.getItemsPerCategory(event.target.value, selected_items)
  }

  filterByInventory = (event) => {
    let { selected_items } = this.state
    let { medical_inventories } = this.props
    this.setState({
      categories: [],
      category_filter: '',
      inventory_filter: event.target.value,
      category: event.target.value
    })
    
    if(event.target.value == "all"){
      this.setState({
        selected_items_review: selected_items,
        categories: []
      })
    }else{
      selected_items = _.filter(selected_items, (item) => {
        return item.category.medical_inventory_id == event.target.value
      })


      this.setState({
        selected_items_review: selected_items,
        categories: medical_inventories[event.target.value - 1].categories
      })
    }
  } 

  filterByCategory = (event) => {
    let { selected_items_review, selected_items, inventory_filter } = this.state
    let { medical_inventories } = this.props

    if(event.target.value == "all"){
      if(inventory_filter != ''){
        selected_items = _.filter(selected_items, (item) => {
          return item.category.medical_inventory_id == inventory_filter
        })
      }

      this.setState({
        selected_items_review: selected_items,
        category_filter: event.target.value
      })

    }else{
      selected_items_review = _.filter(selected_items, (item) => {
        return item.medical_category_id == event.target.value
      })

      this.setState({
        selected_items_review: selected_items_review,
        category_filter: event.target.value
      })
    }
  }

  checkQty = (selected_items, item) => {
    let i = _.find(selected_items, (e) => {
      return e.id === item.id
    })
    if(i){
      return i.qty
    }else{
      if(item.qty)
        return item.qty
      return 1
    }
  }

  filterSelected = (event) => {
    const { selected_items } = this.state

    if(event.target.value == ''){
      this.setState({
        selected_items_review: selected_items,
        inventory_filter: event.target.value
      })
    }else{
      let query = event.target.value  
      let temp = []

      let items = selected_items
      
      items.map((item) => {
        if(item.name.toLowerCase().includes(query.toLowerCase()) || item.catalog_number.toLowerCase().includes(query.toLowerCase()) || item.company.toLowerCase().includes(query.toLowerCase())){
          temp.push(item)
        }
      })

      console.log('sdsd', temp)

      this.setState({
        selected_items_review: temp,
        inventory_filter: event.target.value
      })
    }
  }

  renderTags(data) {
    return ReactDOMServer.renderToStaticMarkup(
      data.map(data => {
        return (
          <span
            key={`tag_${data.id}`}
            className="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10"
          >
            {data.name}
          </span>
        )
      })
    )
  }
  
  searchItems = ({ target: { value } }) => {
    this.props.itemLoading()
    this.setState({ keyword: value, page: 1 }, () => {
      this.searchData()
    })
  }

  getItemResults = () => {
    console.log('this.state.inventory',this.state.inventory)
    this.props.getItemsPerCategory(this.state.category, this.state.selected_items, this.state.inventory, this.state.keyword, this.state.page)
  }
  render() {
    const { medical_inventories, items } = this.props
    const { categories, counters, selected_items_review } = this.state

    let inventories = medical_inventories.map((inventory, index) => {
      return(
        <option value={inventory.id}>{inventory.name}</option>
      )
    })

    let categorylist = categories.map((category) => {
      return(
        <option value={category.id}>{category.name}</option>
      )
    })

     
 

    return (
      <>

        <Modal
          idModal="assignItemsModalCenter"
          size="modal-xl"
          title="Assign items"
          confirmText="Review items"
          cancelText="Cancel"
          confirm={(e) => {
            this.setState({ selected_items_review: this.state.selected_items, inventory: 'all' })
            $("#assignItemsModalCenter").modal('hide')
            $("#reviewItemsModalCenter").modal('show')
          }}
          cancel={(e) => {
            //fn here
          }} 
          bodyClass="assign-items-body"
        >

          <div className="container">
            <Tabs
              onRef={ref => (this.tabsRef = ref)}
              className="px0"
              key={`tabs-tabs`}
              navs={() => {
                return (
                  <TabHead className="col-lg-12">
                    <TabNavigation 
                    key={`consumable-nav`}
                    target="consumables" active={true} onClick={(event) => {
                      this.setState({ inventory: medical_inventories[0].id, category: 'all', keyword: '', page: 1 }, () => {
                        this.props.itemLoading()
                        this.props.getMedicalInventoryCategories(this.state.inventory)
                        this.props.getItemsPerCategory(this.state.category, this.state.selected_items, this.state.inventory, '', this.state.page)
                      })
                    }}>
                      <span className="d-flex">
                        Supplies
                        {/* <Pills className="mt0 mr0 ml8 sm specialty-span">{counters.supplies ? counters.supplies : '0'}</Pills> */}
                      </span>
                    </TabNavigation>
                    <TabNavigation 
                    key={`instrument-nav`}
                    target="consumables" onClick={(event) => {
                      this.setState({ inventory: medical_inventories[1].id, category: 'all', keyword: '', page: 1 }, () => {
                        this.props.itemLoading()
                        this.props.getMedicalInventoryCategories(this.state.inventory)
                        this.props.getItemsPerCategory(this.state.category, this.state.selected_items, this.state.inventory, '', this.state.page)
                      })
                    }}>
                      <span className="d-flex">
                        Instruments
                        {/* <Pills className="mt0 mr0 ml8 sm specialty-span">{counters.instruments ? counters.instruments : '0'}</Pills> */}
                      </span>
                    </TabNavigation>
                    <TabNavigation 
                    target="consumables" 
                    key={`equipments-nav`}
                    onClick={(event) => {
                      this.setState({ inventory: medical_inventories[2].id, category: 'all', keyword: '', page: 1 }, () => {
                        this.props.itemLoading()
                        this.props.getMedicalInventoryCategories(this.state.inventory)
                        this.props.getItemsPerCategory(this.state.category, this.state.selected_items, this.state.inventory, '', this.state.page)
                      })
                    }}>
                      <span className="d-flex">Equipment
                        {/* <Pills className="mt0 ml8 sm specialty-span">{counters.equipments ? counters.equipments : '0'}</Pills> */}
                      </span>
                    </TabNavigation>
                  </TabHead>
                )
              }}
              contents={() => {
                return (
                  <TabBody className="col-md-12 px0 pt16" key="tab-body-key-1">
                    <TabContent idTab="consumables" active={true} key="tab-content-key-1">
                      <div className="row">
                        <div className="col-lg-5">
                          <TextInput
                            label={'Search item database'}
                            placeholder={'Start typing name, catalogue #…'}
                            className="active"
                            onChange={this.searchItems.bind(this)}
                            value={this.state.keyword}
                            search
                          />
                        </div>
                        <div className="col-lg-5">
                          <Picker
                            onChange={event => {
                              this.setState({ category: event.target.value, keyword: '', page: 1 }, () => {
                                this.props.getItemsPerCategory(this.state.category, this.state.selected_items, this.state.inventory, '', this.state.page)
                              })
                            }}
                            label={`Filter by category`}
                            value={this.state.category}
                            data={this.props.inventory_categories}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-12">
                          <Table minWidth={790}
                            className="modal-table infi-table" 

                            renderModal={() => {
                              return (<ViewItemCard id={`aim-sq1-modal-${this.state.unique}`} onRef={ref => (this.viewItemRef = ref)} item={this.state.selectedItem} ></ViewItemCard>)
                            }}
                            
                            loading={this.props.loading}
                            infiniteScroll infiniteHeight={307}
                            infiniteDataLength={this.props.items.length} //This is important field to render the next data
                            infiniteNext={() => { this.getMoreItems() }}
                            infiniteHasMore={this.props.more}

                            // endOfMessageTitle='Nothing else to see...'
                            endOfMessageMessage="Nothing else to see..."
                            // endOfMessageLinkMessage="Edit database"

                            renderHeaders={() => {
                              return (
                                <TRow>
                                  <THead className="w40" text="" />
                                  <THead className="w50 d-none" text="PRN" />
                                  <THead className="w150" text="Catalogue #" sortable onClick={() => this.sortBy('catalog_number')} />
                                  <THead className="w250" text="Name" sortable onClick={() => this.sortBy('name')} />
                                  <THead className="w100" text="Size" sortable onClick={() => this.sortBy('size')} />
                                  <THead className="w100" text="Company" sortable onClick={() => this.sortBy('company')} />
                                  <THead className="w100" text="Category" sortable onClick={() => this.sortBy('category')} />
                                  <THead className="w80" text="Qty" />
                                </TRow>
                              )
                            }}
                            renderRows={() => {
                              let rows = this.props.items.map((item, item_index) => {
                                return (
                                  <TRow hasChoices key={`row-aitt-${item.id}`} autoCheck={false}>
                                    <TData className="w40" onClick={() => {
                                      this.checkItem(item.id);
                                      //this.tabsRef.reInit();
                                    }} >
                                      <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id={`checkbox-a1-${item.id}`} checked={this.checkItemExistence(item.id)} />
                                        <label className="custom-control-label" htmlFor={`checkbox-a1-${item.id}`} onClick={(e) => { e.preventDefault() }}></label>
                                      </div>
                                    </TData>
                                    {/* <TData className="w40" onClick={(e) => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                      this.setPRN(item.id)
                                    }}>
                                      <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id={`checkbox-ais1-${item.id}`} checked={this.isChecked(item.id)} />
                                        <label className="custom-control-label" htmlFor={`checkbox-ais1-${item.id}`} onClick={(e) => { e.preventDefault() }}></label>
                                      </div>
                                    </TData> */}
                                    <TData className="w150">{item.catalog_number}</TData>
                                    <TData className="w250 has-action" item={item} popover onClick={(e) => {
                                      e.stopPropagation()
                                      this.setState({
                                        selectedItem: item,
                                        unique:
                                          Math.random()
                                            .toString(36)
                                            .substring(2, 15) +
                                          Math.random()
                                            .toString(36)
                                            .substring(2, 15),
                                      })
                                      // alert(3)
                                      $(`#aim-sq1-modal-${this.state.unique}`).modal('show')
                                    }}>{item.name}</TData>
                                    <TData className="w100">{item.size}</TData>
                                    <TData className="w100">{item.company}</TData>
                                    <TData className="w100">{item.category ? item.category.name : ''}</TData>

                                    <TData className="w80" onClick={(e) => { e.stopPropagation() }}>
                                    <input type="number" min="1" className="form-control text-center p-0  bg-white"
                                      defaultValue={1}
                                      value={item.qty}
                                      onChange={(event) => {
                                        if (/^[1-9][0-9]*$/.test($(event.target).val()) || !$(event.target).val().length) {
                                        console.log(event.target.value)
                                          let { selected_items } = this.state

                                          let index = _.findIndex(selected_items, (e) => { return e.id == item.id })

                                          if (index > -1) {
                                            selected_items[index].qty = event.target.value
                                          }
                                            this.setState({
                                              selected_items: selected_items
                                            })
                                          
                                          item.qty = event.target.value
                                          items[item_index] = item
                                          this.props.setItems(items)
                                        }
                                      }}
                                      onBlur={(event) => {  
                                        if (/\D+/g.test(event.target.value) || !$(event.target).val().length){
                                          let { selected_items } = this.state

                                          let index = _.findIndex(selected_items, (e) => { return e.id == item.id })

                                          if (index > -1) {
                                            selected_items[index].qty = 1
                                          }
                                          this.setState({
                                            selected_items: selected_items
                                          })
                                          item.qty = 1
                                          items[item_index] = item
                                          this.props.setItems(items)
                                        }
                                          
                                      }}
                                      />
                                      </TData>
                                  </TRow>
                                )
                              })
                              return rows
                            }}
                          // renderNotification={() => {
                          //     return (<TableNotificationCard type="success" message="Success message here..." />)
                          // }}

                          />

                        </div>

                      </div>
                    </TabContent>
                  </TabBody>
                )
              }}
            />


          </div>
        </Modal>
 

      {/* Review Items modal */}
        <Modal
          idModal="reviewItemsModalCenter"
          size="modal-xl"
          title="Review items"
          confirmText="Confirm assigned items"
          cancelText="Cancel"
          confirm={(e) => {
            let mergeItems = []
            if (this.props.selected_category == 'PRN') {
              mergeItems = [...this.state.selected_items, ...this.state.or_items];
            }
            if (this.props.selected_category != 'PRN') {
              mergeItems = [...this.state.selected_items, ...this.state.prn_items];
            }
            console.log('this.props.quicknotes',this.props.quicknotes)
            const qn = this.props.quicknotes.find(x => x.id ===  this.props.quicknotes_id)
            Event('Quicknotes', 'Assign Item',qn.name )
            
            this.props.updateQuicknotesItemList(mergeItems)
            $('#reviewItemsModalCenter').modal('hide')
          }}
          cancel={(e) => {
            $('.modal').modal('hide')
          }}
          bodyClass="assign-items-body"
          midButtonText="Back"
          midButtonAction={(e) => {
            $('#reviewItemsModalCenter').modal('hide')
            $('#assignItemsModalCenter').modal('show')
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <p className="mb20">Please review and confirm assigned items. <br />Items will be separated by PRN and General OR sections.</p>
              </div>
              <div className="col-lg-4">
                <TextInput
                  label={'Search item database'}
                  placeholder={'Start typing name, catalogue #…'}
                  className="active"
                  onChange={(event) => { this.filterSelected(event) }}
                  search
                />
              </div>
              <div className="col-lg-4">
                <Picker
                  onChange={(event) => { this.filterByInventory(event) }}
                  label={`Filter by inventory`}
                  value={this.state.category} 
                >
                <option value="all">All</option>
                  {inventories}
                </Picker>
              </div>
              <div className="col-lg-4">
                <Picker
                  onChange={(event) => { this.filterByCategory(event) }}
                  label={`Filter by category`}
                  value={this.state.category_filter} 
                >
                  <option value="all">All</option>

                  {categorylist}
                </Picker>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <Table minWidth={790} className="modal-table infi-table"
 
                  renderModal={() => {
                    return (
                      <ViewItemCard
                        id={`sq-preview-modal11-${this.state.unique}`}
                        onRef={ref => (this.viewItemRef = ref)}
                        item={this.state.selectedItem}
                      ></ViewItemCard>
                    )
                  }}

                  infiniteScroll infiniteHeight={307}
                  infiniteDataLength={10} //This is important field to render the next data
                  infiniteNext={() => { }}
                  infiniteHasMore={false}

                  // endOfMessageTitle='Nothing else to see...'
                  endOfMessageMessage="Nothing else to see..."
                  // endOfMessageLinkMessage="Edit database"

                  renderHeaders={() => {
                    return (
                      <TRow>
                        <THead className="w40" text="" />
                        <THead className="w50 d-none" text="PRN" />
                        <THead className="w150" text="Catalogue #" sortable onClick={() => this.sortSelectedBy('catalog_number')} />
                        <THead className="w250" text="Name" sortable onClick={() => this.sortSelectedBy('name')} />
                        <THead className="w150" text="Category" sortable onClick={() => this.sortSelectedBy('category')} />
                        <THead className="w80" text="Size" sortable onClick={() => this.sortSelectedBy('size')} />
                        <THead className="w80" text="Qty" />
                      </TRow>
                    )
                  }}
                  renderRows={() => {
                    let rows = selected_items_review.map(item => {
                      return (
                        <TRow hasChoices key={`row-ri-${item.id}`} autoCheck={false}>
                          <TData className="w40" onClick={() => {
                            this.checkItem(item.id)
                          }}>
                            <div className="custom-control custom-checkbox">
                              <input type="checkbox" className="custom-control-input" id={`checkbox-a1-${item.id}`} checked={this.checkItemExistence(item.id)} />
                              <label className="custom-control-label" htmlFor={`checkbox-a1-${item.id}`} onClick={(e) => { e.preventDefault() }}></label>
                            </div>
                          </TData>
                          {/* <TData className="w40" onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            this.setPRN(item.id)
                          }}>
                            <div className="custom-control custom-checkbox">
                              <input type="checkbox" className="custom-control-input" id={`checkbox-ais1-${item.id}`} checked={this.isChecked(item.id)} />
                              <label className="custom-control-label" htmlFor={`checkbox-ais1-${item.id}`} onClick={(e) => { e.preventDefault() }}></label>
                            </div>
                          </TData> */}
                          <TData className="w150">{item.catalog_number}</TData>
                          <TData className="w250 has-action" item={item} popover onClick={(e) => {
                            e.stopPropagation()
                            this.setState({
                              selectedItem: item,
                              unique:
                                Math.random()
                                  .toString(36)
                                  .substring(2, 15) +
                                Math.random()
                                  .toString(36)
                                  .substring(2, 15),
                            })
                            $(`#sq11-preview-modal-${this.state.unique}`).modal('show')
                          }}>{item.name}</TData>
                          <TData className="w150">{item.category ? item.category.name : ''}</TData>
                          <TData className="w80">{item.size}</TData>
                          <TData className="w80" onClick={(e)=>{e.stopPropagation()}}><input type="number" min="1" className="form-control text-center p-0  bg-white"
                            value={item.qty ? item.qty : ''}
                            onChange={(event) => {
                              if (/^[1-9][0-9]*$/.test($(event.target).val()) || !$(event.target).val().length) {
                                let { selected_items } = this.state

                                let index = _.findIndex(selected_items, (e) => { return e.id == item.id })

                                if (index > -1) {
                                  selected_items[index].qty = event.target.value
                                }

                                this.setState({
                                  selected_items: selected_items
                                })

                                item.qty = event.target.value
                                items[index] = item
                                this.props.setItems(items)
                              }
                            }}
                            onBlur={(event) => {
                              if (!$(event.target).val().length) {
                                let { selected_items } = this.state

                                let index = _.findIndex(selected_items, (e) => { return e.id == item.id })

                                if (index > -1) {
                                  selected_items[index].qty = 1
                                }

                                this.setState({
                                  selected_items: selected_items
                                })

                                item.qty = 1
                                items[index] = item
                                this.props.setItems(items)
                              }
                            }}
                         

                          /></TData>
                        </TRow>
                      )
                    })
                    return rows
                  }}
                // renderNotification={() => {
                //     return (<TableNotificationCard type="success" message="Success message here..." />)
                // }}

                />

              </div>

            </div>
          </div>
        </Modal> 

        <ViewItemModal
          key={this.state.unique}
          item={this.state.selectedItem}
          cancel={() => {
            $('viewItemModal').modal('hide')
          }}
        />    
      
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return({
    medical_inventories: state.item.medical_inventories,
    inventory_categories: state.item.inventory_categories,
    items: state.item.items,
    item_list: state.quicknotes.item_list,
    quicknotes_id: state.quicknotes.quicknotes_id,
    more: state.item.more,
    loading: state.item.loading,
    quicknotes: state.quicknotes.categories
  })
}

export default connect(mapStateToProps, {getMedicalInventories, getItemsPerCategory,getMedicalInventoryCategories, setItems, updateQuicknotesItemList, getItems,itemLoading})(AssignItemsModal)
