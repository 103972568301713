import React, { Component } from 'react'

export class PromoBanner extends Component {
    render() { 
        return (
            <div className={`upgrade-bar ${this.props.error ? 'error':''}`}>
                {this.props.children}
            </div>
        )
    }
}

export default PromoBanner
