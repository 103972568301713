import React, { Component } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import _ from 'lodash'
import $ from 'jquery'

export class AccountBillingSkeleton extends Component {
  constructor(props) {
    super(props)

    this.state = {
      counter: _.range(_.random(1, 3)),
      color: '#d2d2d2',
      highlightColor: '#dedede'
    }
  }
  componentWillMount() {
    if ($('body').hasClass('dark-theme')) {
      this.setState({
        color: '#1d212e',
        highlightColor: '#151821'
      })
    }
  }

  
  render() {
    return (
      <SkeletonTheme color={this.state.color} highlightColor={this.state.highlightColor}> 

        <div className="col-md-12 mb12">
          <Skeleton height={40} />
        </div>
        <div className="col-md-12 mb12">
          <Skeleton height={350} />
        </div>
      </SkeletonTheme>
    )
  }
}

export default AccountBillingSkeleton
