import React, { Component } from 'react'

export class InlineNotification extends Component {
  render() {
    return (
      <div className="bd-callout bd-callout-warning sticky-top w-100">
        <p className="m-0">
          {this.props.message}
        </p>
      </div>
    )
  }
}

export default InlineNotification
