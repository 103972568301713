import React, { Component } from 'react'
import axios from 'axios'
import $ from 'jquery'
import _ from 'lodash'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link } from 'react-router'
import ReactDOMServer from 'react-dom/server'
import Confirmation from '../Confirmation'
import ItemCard from '../../components/commons/cards/ItemCard'
import ViewItemModal from '../modals/ViewItemModal'
import { Modal, ConfirmationModal } from '../commons/modals'
import { TextInput, Button, Picker } from '../commons/forms'
import {
  Table,
  TRow,
  THead,
  TData,
  TableNotificationCard,
} from '../../components/commons/table'
import Icon from '../commons/icons/Icon'
import ViewItemCard from '../../components/commons/cards/ViewItemCard'
class Items extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      loading: false,
      page: 1,
      loading: 1,
      dataLength: 1,
      isEditMode: false,
      medical_categories: [],
      medical_inventory_name: '',
      selectedCategory: 'all',
      searchKeyword: '',
      inventory: 1,
      cardView: false,
      selectedItem: false,
      sizes: [{ catalogue: '', size: '', show: true }],
      successAppear: false,
      isCreate: false,
      successMessage: '',
      isReverse: false,
      errors: [],
      unique: this.makeid(10),
      selected_category_id: null,
      notificationType: '', //success, warning, error
      notificationMessage: '',
    }
  }

  makeid(length) {
    var result = ''
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }
  componentDidMount() {
    console.log('itemssssss', this.props)
    if (this.props.item) {
      const { item } = this.props
      this.setState(
        {
          data: this.state.data.filter(i => i.id != item.id),
        },
        () => {
          const { data } = this.state

          data.unshift(item)
          this.setState({
            data: data.splice(0),
            isCreate: false,
            successAppear: true,
            successMessage: 'Items have been successfully updated',
            notificationType: 'success',
            notificationMessage: 'Items have been successfully updated',
          })
          setTimeout(() => {
            this.itemTableNotif.showThis()
          }, 50)
        }
      )
    }
    this.makeRemoteRequest()
  }

  loadMoreData() {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => {
        this.makeRemoteRequest()
      }
    )
  }

  sortBy(key) {
    let arrayCopy = [...this.state.data]
    if (this.state.isReverse) {
      arrayCopy.sort(this.compareBy(key))
    } else {
      arrayCopy.sort(this.compareBy(key)).reverse()
    }
    this.setState({ data: arrayCopy, isReverse: !this.state.isReverse })
  }

  compareBy(key) {
    if (key == 'category') {
      return function(a, b) {
        if (a.category.name < b.category.name) return -1
        if (a.category.name > b.category.name) return 1
        return 0
      }
    }
    return function(a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }

  renderView() {
    console.log('-======================TABLE RENDER====')
    if (this.state.cardView) {
      return (
        <InfiniteScroll
          className="row"
          dataLength={this.state.data.length} //This is important field to render the next data
          next={this.loadMoreData.bind(this)}
          hasMore={true}
        >
          {this.renderCard()}
        </InfiniteScroll>
      )
    }
    return this.renderTable()
  }

  renderCard() {
    const cards = this.state.data.map(item => {
      return (
        <div className="col-lg-3 col-md-4 col-sm-6">
          <ItemCard
            key={`item_card_${item.id}`}
            item={item}
            onClick={() => {
              $('#viewItemModal').modal('show')
              this.setState({
                selectedItem: item,
              })
            }}
            style={{ height: 'calc(100% - 20px)' }}
          />
        </div>
      )
    })
    return <div className="d-flex flex-wrap w-100">{cards}</div>
  }
  addItemSucces() {
    this.setState({
      isCreate: false,
      successAppear: true,
      notificationType: 'success',
      notificationMessage: 'Item have been registered successfully',
    })
    setTimeout(() => {
      this.itemTableNotif.showThis()
    }, 50)
  }
  editItemSuccess() {
    this.setState({
      isCreate: false,
      successAppear: true,
      notificationType: 'success',
      notificationMessage: 'Item have been updated successfully',
    })
    setTimeout(() => {
      this.itemTableNotif.showThis()
    }, 50)
  }

  makeRemoteRequest = () => {
    const { page, selectedCategory, searchKeyword, inventory } = this.state
    if (page == 1) this.setState({ loading: true })
    let url = `api/items?medical_inventory_id=2&medical_category_id=${selectedCategory}&query=${searchKeyword}&paginate=15&page=${page}`
    axios
      .get(url)
      .then(res => {
        this.setState({
          data:
            page === 1 ? res.data.data : [...this.state.data, ...res.data.data],
          loading: false,
          hasMore: res.data.data.length > 0,
          dataLength: res.data.data.length,
        })
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
        this.setState({ loading: false, hasMore: false })
      })

    axios
      .get(`api/medical-categories-by-inventory/2`)
      .then(result => {
        console.log(result)
        this.setState({
          medical_categories: result.data.data,
        })
      })
      .catch(error => {
        console.log(error.response)
      })
  }

  categoryChange(event) {
    console.log()
    event.preventDefault()
    this.setState(
      {
        page: 1,
        loading: true,
        data: [],
        selectedCategory: event.target.value,
      },
      () => {
        this.makeRemoteRequest()
      }
    )
  }

  renderLoading() {
    if (this.state.loading)
      return (
        <div className="row">
          <div className="col-md-12 text-center pt30">
            <div className="d-flex justify-content-center m0">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      )

    return (
      <div className="row">
        <div className="col-md-12 text-center mt30">
          <h3 className="h2med mb-15">Nothing else to see...</h3>
          <p className="bodyregular mb0">
            Missing something? Add new items via{' '}
            <a
              href="#"
              onClick={() => {
                this.setState({
                  isEditMode: true,
                })
              }}
            >
              Edit database
            </a>
          </p>
        </div>
      </div>
    )
  }

  changeView() {
    this.setState({
      cardView: !this.state.cardView,
    })
  }

  renderTable() {
    return (
      <div className="w-100">
        <Table
          minWidth={`${this.state.isEditMode ? 1160 : 860}`}
          renderModal={() => {
            return (
              <ViewItemCard
                id={`preview-modal-${this.state.unique}`}
                onRef={ref => (this.viewItemRef = ref)}
                item={this.state.selectedItem}
              ></ViewItemCard>
            )
          }}
          className="infi-table"
          infiniteScroll
          infiniteHeight={600}
          infiniteDataLength={this.state.data.length} //This is important field to render the next data
          infiniteNext={this.loadMoreData.bind(this)}
          infiniteHasMore={this.state.hasMore}
          endOfMessageTitle="Nothing else to see..."
          endOfMessageMessage="Missing something? Add new items via"
          endOfMessageLinkMessage="Edit database"
          loading={this.state.loading}
          endOfMessageOnClick={() => {
            this.setState({
              isEditMode: true,
              cardView: false,
            })
            //  this.props.setIsCreate(true)
          }}
          renderHeaders={() => {
            return (
              <TRow>
                <THead
                  className="w150"
                  text="Catalogue #"
                  sortable
                  onClick={() => this.sortBy('catalog_number')}
                />
                <THead
                  className="w250"
                  text="Name"
                  sortable
                  onClick={() => this.sortBy('name')}
                />
                <THead
                  className="w150"
                  text="Company"
                  sortable
                  onClick={() => this.sortBy('company')}
                />
                <THead
                  className="w150"
                  text="Category"
                  sortable
                  onClick={() => this.sortBy('category')}
                />
                <THead
                  className="w100 text-center"
                  text="Size"
                  sortable
                  onClick={() => this.sortBy('size')}
                />

                {this.state.isEditMode ? (
                  <THead className="w270" text="Edits" />
                ) : (
                  ''
                )}
              </TRow>
            )
          }}
          renderRows={() => {
            let rows = this.state.data.map(item => {
              return (
                <TRow>
                  <TData className="w150">{item.catalog_number}</TData>
                  <TData
                    className="w250 has-action"
                    item={item}
                    popover
                    onClick={() => {
                      this.setState({ selectedItem: item })
                      $(`#preview-modal-${this.state.unique}`).modal('show')
                    }}
                  >
                    {item.name}
                  </TData>
                  <TData className="w150">
                    {item.company ? item.company : ''}
                  </TData>
                  <TData className="w150">
                    {item.category ? item.category.name : ''}
                  </TData>
                  <TData className="w100 text-center">{item.size}</TData>

                  {this.state.isEditMode ? (
                    <TData className="w270 text-center actions">
                      <div className="d-flex justify-content-start">
                        <Button
                          tertiary
                          onClick={event => {
                            event.preventDefault()
                            this.setState({
                              selectedItem: item,
                              isCreate: true,
                            })
                            this.props.setIsCreate(true)
                            this.props.setActiveItem(item)
                          }}
                        >
                          Edit content
                        </Button>
                        <Button
                          tertiary
                          datatarget="#sizesModalCenter"
                          datatoggle="modal"
                          onClick={() =>
                            this.setState({
                              selectedItem: item,
                              sizes: [
                                {
                                  catalogue: '',
                                  size: '',
                                  show: true,
                                },
                              ],
                              errors: [],
                            })
                          }
                        >
                          Add sizes
                        </Button>
                        {item.manageable ? (
                          <Button
                            tertiary
                            datatarget="#confirmdeleteMOdal"
                            datatoggle="modal"
                            onClick={() =>
                              this.setState({ selectedItem: item })
                            }
                          >
                            Delete
                          </Button>
                        ) : null}
                      </div>
                    </TData>
                  ) : (
                    ''
                  )}
                </TRow>
              )
            })
            return rows
          }}
          renderNotification={() => {
            return (
              <TableNotificationCard
                type={this.state.notificationType}
                message={this.state.notificationMessage}
                onRef={ref => (this.itemTableNotif = ref)}
              />
            )
          }}
        />
      </div>
    )
  }

  renderTags(data) {
    return ReactDOMServer.renderToStaticMarkup(
      data.map(data => {
        return (
          <span
            key={`tag_${data.id}`}
            className="badge badge-secondary specialty-pill rounded-pill mx5 mb10 py10 px10"
          >
            {data.name}
          </span>
        )
      })
    )
  }

  onInventoryChange = event => {
    this.setState(
      {
        inventory: event.target.value,
        page: 1,
        data: [],
      },
      () => {
        this.makeRemoteRequest()
      }
    )
  }

  getArrayError(field, index) {
    const { errors } = this.state

    if (errors[`${field}.${index}`]) {
      return errors[`${field}.${index}`][0]
    }
    return ''
  }

  addMoreSizes() {
    const { sizes } = this.state
    sizes.push({ catalogue: '', size: '', show: true })
    this.setState({
      sizes: sizes.splice(0),
    })
  }

  addSizesSubmit() {
    const { selectedItem, sizes } = this.state

    const form = new FormData()
    sizes.map(data => {
      if (data.show) {
        form.append('catalog_number[]', data.catalogue)
        form.append('size[]', data.size)
      }
    })
    axios
      .post(`api/save-duplicate-supply/${selectedItem.id}`, form)
      .then(result => {
        if (result.data.success) {
          $('.modal').modal('hide')
          this.setState({
            successAppear: true,
            notificationType: 'success',
            notificationMessage: 'Items have been successfully duplicated!',
            show: true,
            data: [...result.data.data, ...this.state.data],
            sizes: [
              {
                catalogue: '',
                size: '',
                show: true,
              },
            ],
          })
          setTimeout(() => {
            this.itemTableNotif.showThis()
          }, 50)
        } else {
          console.log(result.data.errors)
          this.setState(
            {
              errors: result.data.errors,
            },
            () => {
              console.log('errors', this.state.errors)
            }
          )
        }
      })
      .catch(error => {})
  }

  sizeRemove(index) {
    const { sizes } = this.state
    //sizes[index].show = false
    sizes.splice(index, 1)
    this.setState({
      sizes: sizes.splice(0),
    })
  }

  /*  deleteItem() {
    const { selectedItem } = this.state
    axios
      .post(`api/items/${selectedItem.id}`, {
        _method: 'DELETE',
      })
      .then(() => {
        this.setState({
          data: this.state.data.filter(i => i.id != selectedItem.id),
          isCreate: false,
          successAppear: true,
          successMessage: 'Items have been successfully deleted!',
        }) 
        alert(1)
      })
      .catch(error => {
        console.log(error.response)
      })
  } */

  onChangeCategory(event) {
    this.setState({
      selected_category_id: event.target.value,
    })
  }

  deleteItem() {
    const { selectedItem } = this.state
    axios
      .post(`api/items/${selectedItem.id}`, {
        _method: 'DELETE',
      })
      .then(() => {
        this.setState({
          data: this.state.data.filter(i => i.id != selectedItem.id),
          isCreate: false,
          successAppear: true,
          successMessage: 'Items have been successfully deleted!',
          notificationType: 'success',
          notificationMessage: 'Items have been successfully deleted!',
        })

        setTimeout(() => {
          this.itemTableNotif.showThis()
        }, 50)
      })
      .catch(error => {
        console.log(error.response)
      })
  }

  searchItem() {
    this.setState(
      {
        searchKeyword: $('#search_items').val(),
        page: 1,
        loading: true,
        data: [],
      },
      () => {
        this.makeRemoteRequest()
      }
    )
  }
  render() {
    console.log(this.state)
    const search = _.debounce(keyword => {
      this.searchItem()
    }, 500)
    const categories = this.state.medical_categories.map(item => {
      return (
        <option value={item.id} key={`item_${item.id}`}>
          {item.name}
        </option>
      )
    })
    const filter = this.state.sizes.filter(data => {
      return data.show
    })

    const sizes = filter.map((data, index) => {
      return (
        <div className="row" key={`size_item_${index}`}>
          <div className="col-xl-10 col-lg-9 col-md-8 card-body">
            <div className="row">
              <div className="col-lg-8 col-y-7">
                <TextInput
                  errorMessage={this.getArrayError('catalog_number', index)}
                  label="Catalog"
                  value={data.catalogue}
                  onChange={event => {
                    const { sizes } = this.state
                    sizes[index].catalogue = event.target.value
                    this.setState({
                      sizes: sizes.splice(0),
                    })
                  }}
                />
              </div>
              <div className="col-lg-4 col-y-5">
                <TextInput
                  errorMessage={
                    this.getArrayError('size', index) ? 'Required' : ''
                  }
                  label="Size"
                  value={data.size}
                  onChange={event => {
                    const { sizes } = this.state
                    sizes[index].size = event.target.value
                    this.setState({
                      sizes: sizes.splice(0),
                    })
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-3 col-md-4 d-flex align-items-center">
            {index != 0 ? (
              <a
                href="#"
                className="cta cta-tertiary"
                onClick={event => {
                  event.preventDefault()
                  this.sizeRemove(index)
                }}
              >
                <i className="mdi mdi-trash-can"></i>
              </a>
            ) : (
              ''
            )}
          </div>
        </div>
      )
    })
    return (
      <>
        <Modal
          idModal="sizesModalCenter"
          size="modal-sm"
          title="Add sizes"
          confirmText="Add"
          cancelText="Cancel"
          confirm={e => {
            this.addSizesSubmit()
          }}
          cancel={e => {
            //fn here
          }}
        >
          <p className="modal-sub-title">
            Adding additional sizes for{' '}
            <b>{this.state.selectedItem ? this.state.selectedItem.name : ''}</b>
          </p>
          <div className="add-size-card">
            {sizes}
            <div className="row">
              <div
                className="col-xl-10 col-lg-9 col-md-8 add-card"
                onClick={this.addMoreSizes.bind(this)}
              >
                Add another size +
              </div>
            </div>
          </div>
        </Modal>

        <ConfirmationModal
          idModal="confirmdeleteMOdal"
          title="Delete Item"
          message="Deleting item will remove it from the database and will have to be added back manually."
          confirmText="Delete item"
          cancelText="Cancel"
          confirm={this.deleteItem.bind(this)}
          cancel={() => {
            $('#confirmdeleteMOdal').modal('hide')
          }}
        />

        <div className="col-md-12 px0">
          <div className="row page-toolbar pt24">
            <div className="col-lg-3 col-md-6 col-lg-3 col-md-6 d-flex align-items-end sub-div">
              <TextInput
                id="search_items"
                inputClass="active"
                placeholder="Start typing name, catalogue #…"
                ref={el => (this.searchKeyword = el)}
                onChange={search}
                label="Search"
              />
            </div>

            <div className="col-lg-4 col-md-6 col-lg-3 col-md-6 d-flex align-items-end sub-div">
              <Picker
                onChange={this.categoryChange.bind(this)}
                label="Filter supplies by"
              >
                <option value="all">All</option>
                {categories}
              </Picker>
            </div>
            {this.state.isEditMode ? (
              <div className="col ml-auto text-right d-flex justify-content-end justify-content-lg-end align-items-start has-expand">
                <Button
                  secondary
                  expand
                  onClick={event => {
                    event.preventDefault()
                    this.props.setIsCreate(true)
                  }}
                >
                  Register new item
                </Button>

                <Button
                  primary
                  expand
                  onClick={() => {
                    this.setState({
                      isEditMode: false,
                    })
                    this.props.setIsCreate(false)
                  }}
                >
                  Update
                </Button>
              </div>
            ) : (
              <div className="col ml-auto text-right d-flex justify-content-end justify-content-lg-end align-items-start">
                <Button
                  secondary
                  onClick={() => {
                    this.setState({ isEditMode: true, cardView: false })
                  }}
                >
                  Edit database
                </Button>

                <div className="p-0 ml-3 ml-lg-0">
                  {this.state.cardView ? (
                    <Icon
                      icon="list"
                      onClick={this.changeView.bind(this)}
                      key="list-icon-items"
                    />
                  ) : (
                    <Icon
                      icon="card-view"
                      onClick={this.changeView.bind(this)}
                      key="card-icon-items"
                    />
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="row">
            <div className="col-md-12">{this.renderView()}</div>
            {/* <div className="col-md-12">
              {this.renderLoading()}
            </div> */}
          </div>
        </div>
      </>
    )
  }
}

export default Items
