import React, { Component } from 'react'

export class InsideLoader extends Component {
	render() {
		const { message = 'Loading! Please wait...', customStyle } = this.props
		return (
			<div className="inside-loader" style={customStyle}>
				<div class="spinner-grow text-light h100 w100" role="status">
					<span class="sr-only">Loading...</span>
				</div>
				<div className="w-100 text-center my20 d-flex flex-column text-white font-weight-bold">
					{message}
				</div>
			</div>
		)
	}
}

export default InsideLoader
