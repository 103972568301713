import React, { Component } from 'react'
import logo from '../assets/images/logo-gradient.png'
import mobile_logo from '../assets/images/logos/corh-logo-single.png'
import dark_logo from '../assets/images/Logo-Gradient-Reverse.png'
import dark_mobile_logo from '../assets/images/logos/logo-single-reverse.png'

import img from '../assets/images/img.png'
import user from '../assets/images/user.png'
import { getSpecailtiesData, setSpecialty, logoutUser } from '../actions'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router'
import UpgradeModal from './modals/UpgradeModal'
import { Button } from '../components/commons/forms'
import Raven from 'raven-js'
import $ from 'jquery'
import Switch from 'react-switch'

import failedtoload from '../assets/images/failedtoload.png'
import Axios from 'axios'

class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      active: '',
      search_value: '',
      darkmode: false,
      key: this.makeid(10),
      hubs: [],
      hub_name: '',
    }
  }

  makeid(length) {
    var result = ''
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }
  isActive(param) {
    if (this.props.router.location.pathname == param) return 'active'
    return ''
  }

  componentDidMount() {
    console.log('thiss.props')
    Axios.get('v1/hub').then(result => {
      this.setState({
        hubs: result.data,
      })
    })
    if (!this.props.specialty.items.length) {
      this.props.getSpecailtiesData()
    }

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      // dev code
    } else {
      const { user } = this.props.auth
      if (user) {
        Raven.setUserContext({
          email: user.email,
          id: user.id,
          username: user.username,
        })
      }
    }
    $('.dropdown-menu.keep-open').on('click', function(e) {
      console.log('e.targete.target', e.target)
      if (
        $(e.target).hasClass('react-switch-bg') ||
        $(e.target).hasClass('react-switch-handle') ||
        $(e.target).hasClass('always-open')
      ) {
        e.stopPropagation()
      }
    })
    console.log(
      "window.localStorage.getItem('darkmode')",
      window.localStorage.getItem('darkmode') ? 't' : 'f'
    )
    this.setState({
      darkmode: window.localStorage.getItem('darkmode') == 'true',
    })
    const active_theme = this.state.darkmode ? 'dark-theme' : 'light-theme'

    $('body').remove('light-theme')
    $('body').remove('dark-theme')

    $('body').addClass(`${active_theme}`)

    if (!this.props.auth.user.premium) {
      window.localStorage.removeItem('darkmode')
    }
  }

  logout = () => {
    this.props.logoutUser()
  }

  renderList() {
    const { items, loading } = this.props.specialty
    if (!loading)
      return items.map(data => {
        return (
          <Link
            key={data.id}
            className="dropdown-item"
            to={{
              pathname: `/surgeon/${data.id}`,
              state: {
                specialty: data,
              },
            }}
          >
            {`${data.name}`}
          </Link>
        )
      })

    return <span className="dropdown-item text-dark">Loading...</span>
  }

  keyDown = event => {
    if (event.keyCode === 13) {
      // $("#search-submit-btn").trigger('click')
      $('#search-form').submit()
    }
  }

  render() {
    const { limitreach } = this.props

    return (
      <nav className="navbar navbar-expand-lg navbar-light navigation">
        <UpgradeModal />
        <div className="container">
          <Link className="navbar-brand nav-logo" to="/">
            {!this.state.darkmode ? (
              <>
                <img src={logo} className="nav-logo-img d-none d-lg-block" />
                <img
                  src={mobile_logo}
                  className="nav-logo-img d-block d-lg-none py8"
                />
              </>
            ) : (
              <>
                <img
                  src={dark_logo}
                  className="nav-logo-img d-none d-lg-block"
                />
                <img
                  src={dark_mobile_logo}
                  className="nav-logo-img d-block d-lg-none py8"
                />
              </>
            )}
          </Link>

          <button
            className="navbar-toggler mobile-nav"
            type="button"
            data-toggle="collapse"
            data-target="#corh-header2"
            aria-controls="corh-header2"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => {
              setTimeout(() => {
                window.scrollTo(0, 2)
                window.scrollTo(0, 0)
              }, 400)
            }}
          >
            Menu
          </button>

          {/* DESKTOP NAVIGATION */}
          <div
            className="collapse navbar-collapse d-none d-lg-block desktop-nav"
            id="corh-header"
          >
            <ul className="navbar-nav ml-auto">
              <li className={`nav-item dropdown`}>
                <a
                  className="nav-link dropdown-toggle no-caret"
                  href="/hub"
                  id="medical-inventory-dropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {`${
                    Axios.defaults.headers.common['hub_name']
                      ? Axios.defaults.headers.common['hub_name']
                      : 'Personal'
                  } Hub`}
                </a>
                <div
                  className="dropdown-menu w200"
                
                  aria-labelledby="medical-inventory-dropdown"
                >
                  <Link to={`/create-hub`} className="dropdown-item">
                    Create
                  </Link>
                  

                  {this.state.hubs.map(hub => {
                    return (
                      <div className="dropdown-item">
                        <a
                          href="#"
                          onClick={event => {
                            event.preventDefault()
                            this.setState({
                              hub_name: hub.name,
                            })
                            Axios.defaults.headers.common['Hub-Id'] = hub.id
                            Axios.defaults.headers.common['hub_name'] = hub.name
                            this.props.router.replace(
                              this.props.location.pathname +
                                this.props.location.search
                            )
                          }}
                        >
                          {hub.name}
                        </a>
                        |
                        <Link to={`/hub/${hub.id}`}>Manage</Link>
                      </div>
                    )
                  })}
                </div>
              </li>
              <li className={`nav-item`}>
                <Link className="nav-link" to="/surgeon">
                  Surgeon preferences <span className="sr-only">(current)</span>
                </Link>
              </li>
              <li className={`nav-item`}>
                <Link className="nav-link" to="/medical-inventory">
                  Medical Inventory
                </Link>
              </li>

              {/* <li className="nav-item">
                <a className={`nav-link`} href="#">
                  Education (Coming soon)
                </a>
              </li> */}
            </ul>

            <ul className="navbar-nav with-separator">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle no-caret search-item"
                  href="#"
                  id="search-dropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="mdi mdi-magnify mr8 ml0"></i> Search
                </a>
                <div
                  className="dropdown-menu search-dropdown"
                  aria-labelledby="search-dropdown"
                >
                  <div className="section animated">
                    <div className="container">
                      <div className="row w-100">
                        <div className="col-md-8 justify-content-center d-flex mx-auto">
                          <input
                            type="text"
                            name="search"
                            className="form-control mx-3"
                            placeholder="Start search here…"
                            value={this.state.search_value}
                            onChange={e => {
                              this.setState({ search_value: e.target.value })
                            }}
                            onKeyDown={this.keyDown}
                          />

                          <Button
                            id="search-submit-btn2"
                            primary
                            link
                            url={`/search?q=${this.state.search_value}`}
                          >
                            Go
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="nav-item dropdown notif-dropdown d-none">
                <a
                  className="nav-link dropdown-toggle no-caret"
                  href="#"
                  id="notification-dropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="bg-dark icon-sm"></div>
                </a>
                <div
                  className="dropdown-menu"
                  aria-labelledby="notification-dropdown"
                >
                  <span className="dropdown-item-text">Notification</span>

                  <a className="dropdown-item notif-item" href="#">
                    <div className="notif-image">
                      <img
                        src={img}
                        className="align-self-start icon-circle icon-md bg-dark "
                        alt="..."
                      />
                    </div>
                    <div className="notif-body">
                      <p>
                        [User name] Registered a new procedure [Procedure name]
                        for [Speciality name] specialty
                      </p>
                      <span>1h</span>
                    </div>
                  </a>

                  <a className="dropdown-item notif-item" href="#">
                    <div className="notif-image">
                      <img
                        src={img}
                        className="align-self-start icon-circle icon-md bg-dark "
                        alt="..."
                      />
                    </div>
                    <div className="notif-body">
                      <p>
                        [User name] Registered a new procedure [Procedure name]
                        for [Speciality name] specialty
                      </p>
                      <span>1h</span>
                    </div>
                  </a>

                  <a className="dropdown-item notif-item" href="#">
                    <div className="notif-image">
                      <img
                        src={img}
                        className="align-self-start icon-circle icon-md bg-dark "
                        alt="..."
                      />
                    </div>
                    <div className="notif-body">
                      <p>
                        [User name] Registered a new procedure [Procedure name]
                        for [Speciality name] specialty
                      </p>
                      <span>1h</span>
                    </div>
                  </a>

                  <a className="dropdown-item notif-item" href="#">
                    <div className="notif-image">
                      <img
                        src={img}
                        className="align-self-start icon-circle icon-md bg-dark "
                        alt="..."
                      />
                    </div>
                    <div className="notif-body">
                      <p>
                        [User name] Registered a new procedure [Procedure name]
                        for [Speciality name] specialty
                      </p>
                      <span>1h</span>
                    </div>
                  </a>

                  <a className="dropdown-item notif-item" href="#">
                    <div className="notif-image">
                      <img
                        src={img}
                        className="align-self-start icon-circle icon-md bg-dark "
                        alt="..."
                      />
                    </div>
                    <div className="notif-body">
                      <p>
                        [User name] Registered a new procedure [Procedure name]
                        for [Speciality name] specialty
                      </p>
                      <span>1h</span>
                    </div>
                  </a>
                </div>
              </li>
              <li className="nav-item dropdown">
                <div
                  className="nav-link dropdown-toggle d-flex user-nav"
                  id="user-profile-desktop-dropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {this.props.auth.user.avatar ? (
                    <img
                      className="bg-dark icon-circle icon-sm user-icon"
                      src={this.props.auth.user.avatar}
                      onError={event => {
                        $(event.target).prop('src', failedtoload)
                      }}
                    />
                  ) : (
                    <img
                      className="bg-dark icon-circle icon-sm user-icon"
                      src={user}
                      onError={event => {
                        $(event.target).prop('src', failedtoload)
                      }}
                    />
                  )}
                  <span className="text-decoration-none">
                    {this.props.auth.user.name}
                  </span>
                </div>
                <div
                  className="dropdown-menu  keep-open"
                  aria-labelledby="user-profile-desktop-dropdown"
                >
                  <Link className="dropdown-item" to="/lounge">
                    The lounge
                  </Link>
                  <Link className="dropdown-item" to="/my-records">
                    My records
                  </Link>
                  <Link className="dropdown-item" to="/account-settings">
                    Account settings
                  </Link>
                  {this.props.auth.user.premium ? null : (
                    <a
                      className="dropdown-item upgrade-item"
                      href="javascript:;"
                      data-toggle="modal"
                      data-target="#upgradePlanModal"
                    >
                      Upgrade
                    </a>
                  )}
                  {this.props.auth.user.premium ? (
                    <span className="dropdown-item keep-open always-open py8">
                      <label className="d-flex align-items-center justify-content-between always-open m0">
                        Dark mode
                        <Switch
                          onChange={e => {
                            this.setState(
                              {
                                darkmode: !this.state.darkmode,
                                key: this.makeid(10),
                              },
                              () => {
                                window.localStorage.removeItem('darkmode')
                                window.localStorage.setItem(
                                  'darkmode',
                                  this.state.darkmode
                                )
                                const active_theme = this.state.darkmode
                                  ? 'dark-theme'
                                  : 'light-theme'
                                $('body')
                                  .removeClass('dark-theme light-theme')
                                  .addClass(`${active_theme}`)
                              }
                            )
                          }}
                          onColor="#3451A9"
                          checked={this.state.darkmode}
                          checkedIcon={
                            <i className="night-icons mdi mdi-weather-night"></i>
                          }
                          uncheckedIcon={
                            <i className="night-icons mdi mdi-weather-sunny"></i>
                          }
                        />
                      </label>
                    </span>
                  ) : null}
                  <Link
                    className="dropdown-item"
                    to="/login"
                    onClick={this.logout.bind(this)}
                  >
                    Logout
                  </Link>
                </div>
              </li>
            </ul>
          </div>

          {/* MOBILE NAVIGATION */}
          <div
            className="collapse navbar-collapse mobile-nav-container"
            id="corh-header2"
          >
            <ul className="navbar-nav mx-auto mobile-nav">
              <li className="nav-item dropdown user-item">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="user-profile-dropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {this.props.auth.user.avatar ? (
                    <img
                      className="bg-dark icon-circle icon-sm user-icon"
                      src={this.props.auth.user.avatar}
                    />
                  ) : (
                    <img
                      className="bg-dark icon-circle icon-sm user-icon"
                      src={user}
                    />
                  )}
                  <span>
                    Welcome &nbsp;<b>{this.props.auth.user.name}</b>
                  </span>
                </a>
                <div
                  className="dropdown-menu"
                  aria-labelledby="user-profile-dropdown"
                >
                  <Link className="dropdown-item" to="/lounge">
                    The lounge
                  </Link>
                  <Link className="dropdown-item" to="/my-records">
                    My records
                  </Link>
                  <Link className="dropdown-item" to="/account-settings">
                    Account settings
                  </Link>
                  <Link
                    className="dropdown-item"
                    to="/login"
                    onClick={this.logout.bind(this)}
                  >
                    Logout
                  </Link>
                </div>
              </li>

              <li className="nav-item dropdown search-dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="search-dropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span>Search</span>
                </a>
                <div
                  className="dropdown-menu"
                  aria-labelledby="search-dropdown"
                >
                  <div className="form-group">
                    <input
                      type="search"
                      autoComplete="off"
                      className="form-control"
                      placeholder="Start search here..."
                      value={this.state.search_value}
                      onChange={e => {
                        this.setState({ search_value: e.target.value })
                      }}
                      onKeyDown={this.keyDown}
                    />
                    <Button
                      id="search-submit-btn"
                      secondary
                      url={`/search?q=${this.state.search_value}`}
                      link
                    >
                      Search
                    </Button>
                  </div>
                </div>
              </li>

              <li className="nav-item">
                <Link className="nav-link" to="/surgeon">
                  Surgeon preferences
                </Link>
              </li>

              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="/medical-inventory"
                  id="search-dropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span>Medical inventory</span>
                </a>
                <div
                  className="dropdown-menu"
                  aria-labelledby="search-dropdown"
                >
                  <Link
                    className="dropdown-item"
                    to="/medical-inventory/supplies"
                  >
                    Supplies
                  </Link>
                  <Link
                    className="dropdown-item"
                    to="/medical-inventory-instruments"
                  >
                    Instruments
                  </Link>
                  <Link
                    className="dropdown-item"
                    to="/medical-inventory/equipments"
                  >
                    Equipments
                  </Link>
                </div>
              </li>

              {/* <li className="nav-item">
                <Link className="nav-link" to="/education">Education</Link>
              </li>  */}

              {/* <li className="nav-item">
                <Link className="nav-link" to="/quality">
                  Quality
                </Link>
              </li> */}

              <li className="nav-item upgrade-item">
                {this.props.auth.user.premium ? null : (
                  <a
                    className="nav-link text-center"
                    href="#"
                    data-toggle="modal"
                    data-target="#upgradePlanModal"
                  >
                    Upgrade
                  </a>
                )}
              </li>
              <li className="nav-item">
                {this.props.auth.user.premium ? (
                  <label className="d-flex align-items-center justify-content-between always-open m0">
                    Dark mode
                    <Switch
                      onChange={e => {
                        this.setState(
                          {
                            darkmode: !this.state.darkmode,
                            key: this.makeid(10),
                          },
                          () => {
                            window.localStorage.removeItem('darkmode')
                            window.localStorage.setItem(
                              'darkmode',
                              this.state.darkmode
                            )
                            const active_theme = this.state.darkmode
                              ? 'dark-theme'
                              : 'light-theme'
                            $('body')
                              .removeClass('dark-theme light-theme')
                              .addClass(`${active_theme}`)
                          }
                        )
                      }}
                      onColor="#3451A9"
                      checked={this.state.darkmode}
                      checkedIcon={
                        <i className="night-icons mdi mdi-weather-night"></i>
                      }
                      uncheckedIcon={
                        <i className="night-icons mdi mdi-weather-sunny"></i>
                      }
                    />
                  </label>
                ) : null}
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/login"
                  onClick={this.logout.bind(this)}
                >
                  Logout
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <form
          method="GET"
          action={`/search?q=${this.state.search_value}`}
          id="search-form"
        >
          <input type="hidden" name="q" value={`${this.state.search_value}`} />
        </form>
      </nav>
    )
  }
}

const mapStateToProps = ({ specialty, auth }) => {
  return { specialty, auth }
}

export default withRouter(
  connect(mapStateToProps, {
    getSpecailtiesData,
    setSpecialty,
    logoutUser,
  })(Header)
)

/* 


<div>
        <nav className="navbar navbar-expand-lg bg-header py-0">
          <div className="container">
            <Link className="navbar-brand" to="/lounge">
              <img src={logo} className="header-logo" />
            </Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#masterhead" aria-controls="masterhead" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>


            <div className="collapse navbar-collapse" id="masterhead">
              <ul className="navbar-nav dropdown mr-auto w-100">
                <li className="nav-item text-center h-100 bg-navheader hover-dropdown">
                  <Link
                    className="nav-link dropdown-toggle h-100"
                    to="/surgeon"
                  >
                    Surgeon Preferences
                </Link>
                  <div
                    className="dropdown-menu mega-menu bg-mega-menu animated fadeIn faster"
                    aria-labelledby="dropdown05"
                  >
                    <ul className="list-inline">{this.renderList()}</ul>
                  </div>
                </li>
                <li className="nav-item dropdown-toggle dropdown no-caret h-100 bg-navheader hover-dropdown">
                  <Link
                    className="nav-link h-100"
                    to="/medical-inventory"
                    id="user-dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Medical Inventory
                </Link>
                  <div
                    className="dropdown-menu fit-dropdown bg-navdropdown animated fadeIn pb-0"
                    aria-labelledby="user-dropdown"
                  >
                    <ul className="list-group bg-transparent py5">
                      <li className="list-group-item bg-transparent py15 border-0">
                        <Link to={`/medical-inventory/supplies`}>Supplies</Link>
                      </li>
                      <li className="list-group-item bg-transparent py15 border-0">
                        <Link to={`/medical-inventory-instruments`}>Instruments</Link>
                      </li>
                      <li className="list-group-item bg-transparent py15 border-0">
                        <Link to={`/medical-inventory/equipments`}>
                          Equipments
                      </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item bg-navheader">
                  <Link className="nav-link h-100" to="/medical-inventory">
                    Education
                </Link>
                </li>
                <li className="nav-item bg-navheader">
                  <a className="nav-link h-100" href="#">
                    Quality
                </a>
                </li>
              </ul>

              <ul className="navbar-nav no-caret click-dropdown has-before-border">
                <li
                  className="nav-item dropdown-toggle h-100 bg-navheader"
                  id="search_dropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <a className="nav-link h-100" href="#">
                    Search
                  <i className="fa fa-search ml5" />
                  </a>
                </li>
                <div
                  className="dropdown-menu search-dropdown animated fadeIn faster bg-navdropdown"
                  aria-labelledby="search_dropdown"
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-md-8 justify-content-center d-flex mx-auto">
                        <input
                          type="text"
                          name="search"
                          className="form-control mx-3 border-0"
                          placeholder="Type Example..."
                        />
                        <Link className="btn btn-outline-dark align-self-center px30" to={`/search?q=${this.state.search_value}`}>
                          {' '}
                          Go{' '}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>
              <ul className="navbar-nav has-before-border">
                <li className="nav-item bg-navheader px5 click-dropdown">
                  <a className="nav-link h-100" href="#">
                    <i className="fa fa-globe" />
                  </a>
                </li>
                <li className="nav-item bg-navheader px5 click-dropdown">
                  <a className="nav-link h-100" href="#">
                    <i className="fa fa-bell" />
                  </a>
                </li>
              </ul>
              <ul className="navbar-nav dropdown no-caret has-before-border">
                <li className="nav-item dropdown-toggle no-caret bg-navheader px15 click-dropdown">
                  <a
                    className="nav-link p-0 h-100 bg-transparent"
                    href="#"
                    id="user-dropdown"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {
                      this.props.auth.user.avatar ?
                        <img
                          src={this.props.auth.user.avatar}
                          className="bg-dark rounded-circle mr-2 nurse-icon"
                        />
                        :
                        <img
                          src={img}
                          className="bg-dark rounded-circle mr-2 nurse-icon"
                        />
                    }
                    {this.props.auth.user.name}
                    <i className="fa fa-chevron-down ml5" />
                  </a>
                  <div
                    className="dropdown-menu fit-dropdown bg-navdropdown animated fadeIn faster px20"
                    aria-labelledby="user-dropdown"
                  >
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item bg-transparent"><a href="javascript:;" onClick={() => {
                        $("#upgradePlanModal").modal('show')
                      }}>
                          Upgrade account
                      </a>
                      </li>
                      <li className="list-group-item bg-transparent">
                        <Link to="/lounge">
                          The lounge{' '}
                        </Link>
                      </li>
                      <li
                        className={`list-group-item bg-transparent ${this.isActive(
                          '/my-records'
                        )}`}
                      >
                        <Link to={`/my-records`}>My records</Link>
                      </li>
                      <li className="list-group-item bg-transparent">
                        <Link to={`/account-settings`}>Account settings</Link>
                      </li>
                      <li className="list-group-item bg-transparent">
                        <a href="#">Switch profile</a>
                      </li>
                      <li className="list-group-item bg-transparent pb5">
                        <Link to="/login" onClick={this.logout.bind(this)}>
                          Logout
                      </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>

      {
        limitreach ?

            <div className="w-100 bg-dark text-white">
              <div className="container">
                <div className="row">
                  <div className="col-12 text-center py15">
                    <p className="bodylight m0">
                      Looks like you've reached your free limit of Surgeons. Enjoy unlimited surgeons profiles by <a href="javascript:;" onClick={() => {
                        $("#upgradePlanModal").modal('show')
                      }} className="text-white bodybold"><u>Upgrading</u></a> today!
                  </p>
                  </div>
                </div>
              </div>
            </div>
            :null
      }

        <UpgradeModal />

      </div>
      
      */
