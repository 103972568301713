import $ from 'jquery'


export const makeid = (length = 5) => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const scrollToTop = () => {
  $('.scroll-to-top').fadeOut(0)
  $(window).scroll(function () {
    if ($(this).scrollTop() > 350) {
      $('.scroll-to-top').fadeIn()
    } else {
      $('.scroll-to-top').fadeOut()
    }
  })

  $(document).on('click', '.scroll-to-top', function () {
    $('html, body').animate({ scrollTop: $('#home').offset().top - 100 }, 400)
    return false
  })

  setTimeout(() => {
    $('[data-toggle="popover"]').popover()
    $('.popover').popover('hide')
  }, 200);
}

export const  getParams = (param) => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(param) || '';
}