import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import InfiniteScroll from 'react-infinite-scroll-component'
import $ from 'jquery'
import Confirmation from '../Confirmation'
import {
  Table,
  THead,
  TData,
  TRow,
  TableNotificationCard,
} from '../../components/commons/table'
import { ConfirmationModal, FormModal } from '../commons/modals'
import { Button } from '../commons/forms'
import { getSpecailtiesData } from '../../actions'
class Procedures extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      page: 1,
      loading: false,
      newprocedure: '',
      selected_procedure: null,
      successAppear: false,
      successMessage: '',
      specialties: [],
    }
  }

  componentDidMount() {
    this.makeRemoteRequest()
    this.props.getSpecailtiesData()
  }

  componentWillReceiveProps(nextProps) {
    if (this.props != nextProps) {
      this.setState({
        specialties: nextProps.specialty.items,
      })
    }
  }
  findSpecialty(category) {
    if (category)
      return this.state.specialties.find(x => x.id === category.specialty_id)
    return null
  }
  makeRemoteRequest = () => {
    const { page } = this.state
    axios
      .get(`api/my-procedures?page=${page}`)
      .then(res => {
        this.setState({
          data:
            page === 1 ? res.data.data : [...this.state.data, ...res.data.data],
          loading: false,
        })
      })
      .catch(errors => {
        console.log(errors)
        console.log(errors.response)
      })
  }

  loadMoreData() {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => {
        this.makeRemoteRequest()
      }
    )
  }

  saveprocedure = () => {
    const { selected_procedure, newprocedure } = this.state

    const data = {
      name: newprocedure,
      _method: 'PATCH',
    }
    axios
      .post(`api/specialty-procedure/${selected_procedure.id}`, data)
      .then(result => {
        $('#procedure-modal').modal('hide')

        this.setState(
          {
            data: this.state.data.filter(i => i.id != selected_procedure.id),
          },
          () => {
            const { data } = this.state

            data.unshift(result.data.data)
            this.setState({
              data: data.splice(0),
              successAppear: true,
              successMessage: 'Procedure have been successfully updated',
            })
            setTimeout(() => {
              this.setState({
                successAppear: false,
                successMessage: '',
                selected_procedure: null,
              })
            }, 5000)
          }
        )
      })
  }

  deleteProcedure = () => {
    const { selected_procedure } = this.state
    const data = {
      _method: 'DELETE',
    }
    axios
      .post(`api/specialty-procedure/${selected_procedure.id}`, data)
      .then(result => {
        const res = this.state.data.filter(i => i.id != selected_procedure.id)
        this.setState(
          {
            data: res.splice(0),
            successAppear: true,
            successMessage: 'Procedure have been successfully deleted',
          },
          () => {
            this.procedureTableRef.showThis()
          }
        )
      })
  }
  render() {
    const rows = this.state.data.map((item, index) => {
      return (
        <tr key={`procedure-index-${index}`}>
          <td>
            <a href="#" className="btn btn-link">
              {item.name}
            </a>
          </td>
          <td>{item.specialty ? item.specialty.name : null}</td>
          <td>{item.category ? item.category.name : null}</td>
          <td className="pl10">{item.created_at}</td>
          <td className="pl10">
            <a
              href="#"
              data-toggle="modal"
              data-target="#procedure-modal"
              onClick={() => {
                this.setState({
                  selected_procedure: item,
                  newprocedure: item.name,
                })
              }}
            >
              Edit
            </a>
            <a
              href="#"
              className="ml10"
              onClick={() => {
                $('#deleteProcedureModal').modal('show')
                this.setState({ selected_procedure: item })
              }}
            >
              Delete
            </a>
          </td>
        </tr>
      )
    })
    return (
      <div
        className={`tab-pane  ${this.props.activeTab ? 'active' : ''}`}
        id="procedures"
        role="tabpanel"
        aria-labelledby="procedures-tab"
      >
        <ConfirmationModal
          title="Delete Procedure"
          message="Deleting procedure will remove it from the database and will have to be added back manually."
          confirmText="Delete"
          cancelText="Cancel"
          idModal={`deleteProcedureModal`}
          confirm={this.deleteProcedure.bind(this)}
          cancel={() => {
            $('#deleteProcedureModal').modal('hide')
          }}
        />
        <div>
          <FormModal
            idModal="procedure-modal"
            title="Editing procedure"
            confirmText="Save"
            cancelText="Cancel"
            confirm={() => {
              this.saveprocedure()
            }}
          >
            <div class="row">
              <div class="col-md-12">
                <label className="mb10">Name procedure</label>
                <input
                  type="text"
                  value={this.state.newprocedure}
                  onChange={event => {
                    this.setState({ newprocedure: event.target.value })
                  }}
                  class="form-control"
                />
              </div>
            </div>
          </FormModal>
          <div
            class="modal fade"
            id="procedure-modal2"
            tabindex="-1"
            role="dialog"
            aria-labelledby="specialty-modalLabel"
            aria-hidden="true"
          >
            <div
              class="modal-dialog modal-dialog-centered modal-xs"
              role="document"
            >
              <div class="modal-content pt0">
                <div class="modal-header border-0 pt30 px30 pb0">
                  <h4 className="h2bold mb0" id="specialty-modalLabel">
                    Editing procedure
                  </h4>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body pt0 px30">
                  <p class="mb20" />
                  <div class="row">
                    <div class="col-md-12">
                      <label className="mb10">Name procedure</label>
                      <input
                        type="text"
                        value={this.state.newprocedure}
                        onChange={event => {
                          this.setState({ newprocedure: event.target.value })
                        }}
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="form-group mt30 mb0">
                    <div
                      class="btn btn-dark px30 mr30"
                      onClick={() => {
                        this.saveprocedure()
                      }}
                    >
                      Save
                    </div>
                    <button
                      class="btn btn-outline-dark px30 m-xst20"
                      onClick={() => {
                        $('#procedure-modal').modal('hide')
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <InfiniteScroll
              className=""
              dataLength={this.state.data.length}
              next={this.loadMoreData.bind(this)}
              hasMore={true}
            >
              <Table
                minWidth={`${this.state.isEditMode ? 860 : 860}`}
                renderHeaders={() => {
                  return (
                    <TRow>
                      <THead className="w250" text="Procedure name" />
                      <THead className="w250" text="Specialty" />
                      <THead className="w250" text="Category" />
                      <THead className="w100 text-center" text="Edits" />
                    </TRow>
                  )
                }}
                renderRows={() => {
                  let rows = this.state.data.map(item => {
                    let spec = this.findSpecialty(item.category)
                    // console.log('itemitemitemitemitemitem', spec)
                    return (
                      <TRow>
                        <TData className="w250">{item.name}</TData>
                        <TData className="w300">
                          {spec ? spec.name : null}
                        </TData>
                        <TData className="w300">
                          {item.category ? item.category.name : null}
                        </TData>
                        <TData className="w150 actions text-center">
                          <div className="d-flex w-100 h-100 justify-content-center">
                            <Button
                              tertiary
                              datatoggle="modal"
                              datatarget="#procedure-modal"
                              onClick={() => {
                                this.setState({
                                  selected_procedure: item,
                                  newprocedure: item.name,
                                })
                              }}
                            >
                              Edit
                            </Button>

                            <Button
                              tertiary
                              onClick={() => {
                                $('#deleteProcedureModal').modal('show')
                                this.setState({ selected_procedure: item })
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        </TData>
                      </TRow>
                    )
                  })
                  return rows
                }}
                renderNotification={() => {
                  return (
                    <TableNotificationCard
                      type="success"
                      cols={5}
                      message={this.state.successMessage}
                      onRef={ref => (this.procedureTableRef = ref)}
                    />
                  )
                }}
              />
            </InfiniteScroll>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ specialty }) => {
  return { specialty }
}

export default connect(
  mapStateToProps,
  { getSpecailtiesData }
)(Procedures)
