import React from 'react'

const CircleLoading = ({ progress = 0 }) => (
  <div className={`pie-wrapper progress-${progress} style-2`}>
    <span className="label">
      {progress}
      <span className="smaller">%</span>
    </span>
    <div className="pie">
      <div className="left-side half-circle"></div>
      <div className="right-side half-circle"></div>
    </div>
    <div className="shadow"></div>
  </div>
)

export default CircleLoading
