import React, { Component } from 'react'
import Vimeo from '@u-wave/react-vimeo'
import error_img from '../assets/images/vid-on-process.png'
import $ from 'jquery'

export class VimeoPlayer extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
       height: 0,
       width: 0,
      errorInVimeo: false,
      video_loaded: false
    }
  }
  componentWillMount() {
    this.setState({
      height: $(window).innerHeight(),
      width: $(window).innerWidth(),
    })
    // alert(.height()) git
  }
  
  componentDidMount() {
  }


  renderVim() {
    var vimeoError = false;
    if (vimeoError) {
      return (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          <img class="bg-light h-100 mx-auto" src={error_img} />
        </div>
      )
    } else {
      return (
        <Vimeo
          video={this.props.location.query.src} height={this.state.height} width={this.state.width} className="w-100 bg-black align-items-center h-100"
          onError={() => {
            vimeoError = false;
            this.setState({
              errorInVimeo: true,
              video_loaded: false
            })
          }}

          onLoaded={() => {
            this.setState({
              video_loaded: false
            })
          }}
        />
      )


    }
  }

  render() { 
    console.log(this.props)
    return (
      <div className="position-absolute h-100 w-100 main">
        {this.renderVim()}
        {/* <Vimeo
          video={this.props.location.query.src} height={this.state.height} width={this.state.width} className="w-100 bg-black align-items-center h-100"
          

          onError={() => {  
          }}

          onLoaded={() => { 
          }}
        /> */}
      </div>
    )
  }
}

export default VimeoPlayer
