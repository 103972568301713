import React, { Component } from 'react'

export class WholeBodyloader extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        //type = spinner, progress
        const { progress, message="Loading! Please wait...", type="spinner" } = this.props
        if (progress > 0) {
            if (type == 'spinner') {
                return (
                    <div className="wholebody-loader">
                        <div className="w-100 text-center mb20 d-flex flex-column text-white font-weight-bold animated">
                            {message}
                        </div>
                        <div class="spinner-border text-light" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div> 
                )
            }else if(type == 'progress'){

                return (
                  <div className="wholebody-loader">
                    <div className="w-100 text-center mb20 d-flex flex-column text-white font-weight-bold">
                      {message}
                    </div>
                    <div className="container d-flex justify-content-center align-items-center">
                        
                        <div class="progress w-100">
                        <div
                            class="progress-bar progress-bar-striped progress-bar-animated"
                            role="progressbar"
                            style={{width: `${progress}%`}}
                            aria-valuenow={progress}
                            aria-valuemin="0"
                            aria-valuemax="100"
                        >
                            {progress}%
                        </div>
                        </div>
                    </div>   
                  </div>
                )
            }
        } else {
            return null;
        }

    }
}

export default WholeBodyloader
