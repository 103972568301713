import axios from 'axios'
import $ from 'jquery'
import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { decrementItemQty, incrementItemQty, removeItem } from '../actions/QuicknotesActions'
import DataCard from './commons/cards/DataCard'
import ViewItemCard from './commons/cards/ViewItemCard'
import { Button } from './commons/forms'
import Collapsible from './commons/panel/Collapsible'
import { Table, TData, THead, TRow } from './commons/table'
import AssignItemsStandard from './modals/AssignItemsStandard'

export class StandardItemList extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      change: true,
      item_list: [],
      key: this.makeid(15),
      selected_category: ''
    }

    this.modal = React.createRef()
  }
  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


  componentDidMount = () => {
    this.setState({
      item_list: this.props.item_list,
    })

    function UpdateTableHeaders() {
      $(".persist-area").each(function () {

        var el = $(this),
          offset = el.offset(),
          scrollTop = $(window).scrollTop(),
          floatingHeader = $(".floatingHeader", this)

        if ((scrollTop > offset.top) && (scrollTop < offset.top + el.height() - 50)) {
          floatingHeader.css({
            "visibility": "visible"
          });
        } else {
          floatingHeader.css({
            "visibility": "hidden"
          });
        };
      });
    }

    var clonedHeaderRow;

    $(".persist-area").each(function () {
      clonedHeaderRow = $(this).find(".persist-header");
      var w = clonedHeaderRow.width()
      clonedHeaderRow
        .before(clonedHeaderRow.clone())
        .css("width", '100%')
        .addClass("floatingHeader bg-white");
    });

    $(window)
      .scroll(UpdateTableHeaders)
      .trigger("scroll");
  }
  
  componentWillReceiveProps = (nextProps) => {
    if(this.props.item_list != nextProps.item_list){
      this.setState({
        item_list: nextProps.item_list,
        change: false, 
        key: this.makeid(16)
      })
    }
  }
  
  componentDidUpdate = () => {
    $('[data-toggle="popover"]').popover()
    $('[data-toggle="popover"]').popover('hide') 
  }

  onSubmit = item => {
    const data = { qty: item.qty, _method: 'PATCH' }
    axios
      .post(`quicknotes-item-list/${item.id}`, data)
      .then(result => {
        // console.log(result)
      })
      .catch(error => {
        // console.log(error)
      })
  }

  render() {
    const { item_list } = this.state 

    let item_count = 0;
    item_list.map((category) => {
      item_count += category.details.length;
    })
    console.log('item_count', item_count)

    let categories = item_list.map((category, index) => { 
      if(!category.details.length > 0 && !this.props.edit)
        return ''

      return (
        
        <DataCard title={category.category_name} className="mb20" stickyHeader headerAction={() => {
          return (<div className="d-flex ml-auto">
            {this.props.edit ? 
            <Button secondary
              onClick={() => {
                this.setState({
                  change: !this.state.change,
                  selected_category: category.category_name
                }, () => {
                  $('#assignItemsModalCenter').modal({
                    show: true,
                    keyboard: false
                  })
                  $('#assignItemsModalCenter').on('shown.bs.modal', function () {
                    document.querySelectorAll('.tippy-popper').forEach(popper => {
                      popper._tippy.hide()
                    })
                  })
                })
              }}
            >
              Assign items
              </Button> 
              :null
            }
          </div>)
        }}>
        
          <Table minWidth={655}
            noResults={category.details.length > 0 ? 0 : 1}

            renderModal={() => {
              return (<ViewItemCard id={`preview-cons-modal-2-${this.state.unique}`} onRef={ref => (this.viewItemRef = ref)} item={this.state.selectedItem} ></ViewItemCard>)
            }}

            renderHeaders={() => {
              return (
                <TRow>
                  <THead className="w120" text="Catalogue #" />
                  <THead className="w250" text="Name" />
                  <THead className="w120" text="Company" />
                  <THead className="w80" text="Qty" />
                  <THead className="w80" text="Size" /> 
                  {this.props.edit ? <THead className="w80" text=" " /> : null}
                </TRow>
              )
            }}
            renderRows={() => {
              let rows = category.details.map(item => {
 

                return (
                  <TRow>
                    <TData className="w120">{item.catalog_number}</TData>
                    <TData className="w250 has-action" item={item} popover onClick={() => {
                      this.setState({ selectedItem: item })
                      $(`#preview-cons-modal-2-${this.state.unique}`).modal('show')
                    }}>{item.name}</TData>
                    <TData className="w120"> {item.company} </TData>
                    <TData className="w80">{item.qty}</TData>
                    <TData className="w80">{item.size}</TData>
                    {this.props.edit ? <TData className="w80">
                      <Button tertiary onClick={(event) => {
                          event.preventDefault() 
                          let temp = _.remove(category.details, (e) => {
                            return e.id != item.id
                          })
                          item_list[index].details = temp 
                          this.setState({
                            change: !this.state.change,
                            item_list: item_list
                          })
                        }}
                      >
                        Remove
                      </Button>
                    </TData> : null}
                  </TRow>
                )
              })
              return rows
            }}
          />
        </DataCard>
      
      )
    })
    
    if (item_count == 0 && !this.props.edit){
      categories = (<div className="data-card">
        <div className="card-body pt20">
          <p className="m0">
            No item(s) added. Add new items via <a href="javascript:;" onClick={() => {
              $("#edit-standard-btn").click();
            }}>Edit database</a>.
                </p>
        </div>
      </div>)
    }

    return (
      <>
        <Collapsible className="mb20" title="Show item needed" key={`notes-${this.state.key}`}>
          {categories}
        </Collapsible>

          <AssignItemsStandard 
            change={this.state.change} 
            stage_master={this.props.stage_master} 
            selected_category={this.state.selected_category} 
            item_list={this.state.item_list} 
            setSelected={(items) => { this.setSelected(items) }}
            loading={this.props.loading} 
            />
      </>
    )
  }

  setSelected = (items) => {
    let { item_list } = this.state

    let prn_items = []
    let or_items = []

    // console.log('object1', items)

    items.map((item) => {
     
      if(item.prn){
        prn_items.push(item)
      }else{
        or_items.push(item)
      }
    })

    item_list[0].details = prn_items
    item_list[1].details = or_items


    this.setState({
      item_list: item_list
    }, () => {this.props.setNewState({item_list: item_list})})
    
  }
}

const mapStateToProps = state => {
  return {
    loading: state.item.loading,
  }
}

export default connect(
  mapStateToProps,
  { incrementItemQty, decrementItemQty, removeItem }
)(StandardItemList)
