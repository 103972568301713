import arrayMove from 'array-move'
import axios from 'axios'
import $ from 'jquery'
import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { getItemsPerCategory, getStandardItems } from '../../actions/ItemAction'
import MediaEditor from '../../components/commons/media_elements/MediaEditor'
import StandardEditor from '../../components/commons/media_elements/StandardEditor'
import TextEditor from '../../components/commons/media_elements/TextEditor'
import StandardItemList from '../../components/StandardItemList'
import { Event } from '../../utilities/analytics'
import { procedureAction } from '../../utilities/notifier.js'
import { Button } from '../commons/forms/index.js'
import AddNewSection from '../commons/media_elements/AddNewSection.js'
import MediaElement from '../commons/media_elements/MediaElement.js'
import StandardElement from '../commons/media_elements/StandardElement.js'
import TextElement from '../commons/media_elements/TextElement.js'
import EditMenu from '../commons/misc/EditMenu.js'
import { ConfirmationModal } from '../commons/modals'
import Notifier from '../commons/notifier/Notifier.js'
import { hideLoader, setLoaderProg, showLoader } from '../loader/loading_screen'
import ConsumableSkeleton from '../skeletons/ConsumableSkeleton'
import StandardSkeleton from '../skeletons/StandardSkeleton'

export class StandardLayout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected_items: [],
      change: false,
      standardData: [],
      item_list: [],
      editMode: false,
      draft_success: false,
      showUpdateSuccess: false,
      errors: [],
      progress: 0,
      common_items:[],
      progressData:[]
    }

    this.elementsRef = []

    const SortableItem = SortableElement(({ standard, index2 }) => {
      const index = index2
      switch (standard.type) {
        case 'text':
          return (
            <li>
              <TextEditor
                elementRef={(e) => { this.elementsRef[index2] = e }}
                data={{
                  title: standard.title,
                  content: standard.contents.caption,
                }}
                mentions={this.state.common_items}
                validation={{
                  errors: this.state.errors,
                  prefix: 'elements',
                  index: index,
                  field: 'title'
                }}
                onTitleChange={event => {
                  this.titleChange(event, index)
                }}
                textContentChange={event => {
                  this.textContentChange(event, index)
                }}
                onDelete={event => this.deleteElement(event, index)}
                onDuplicate={event => this.duplicateElement(event, index)}
              />
            </li>
          )
          break
        case 'media':
          return (
            <li>
              <MediaEditor
                elementRef={(e) => { this.elementsRef[index2] = e }}
                data={{
                  title: standard.title,
                  medias: standard.contents,
                }}
                mentions={this.state.common_items}
                validation={{
                  errors: this.state.errors,
                  prefix: 'elements',
                  index: index,
                  field: 'title'
                }}
                onTitleChange={event => {
                  this.titleChange(event, index)
                }}
                onContentTitleChange={(event, i) => {
                  this.onContentTitleChange(event, i, index)
                }}
                onContentCaptionChange={(event, i) => {
                  this.onContentCaptionChange(event, i, index)
                }}
                onContentMediaChange={(event, i) => {
                  this.onContentMediaChange(event, i, index)
                }}
                onContentThumbnailChange={(event, i) => {
                  this.onContentThumbnailChange(event, i, index)
                }}
                onContentSortChange={(contents, i) => {this.onContentSortChange(contents, index)}}
                addNewMedia={event => this.addNewMedia(event, index)}
                onDuplicate={event => this.duplicateElement(event, index)}
                onDelete={event => this.deleteElement(event, index)}
                onDeleteMediaElement={this.deleteMediaElement}
                progressMedia={() => {
                  console.log('progressMediaprogressMedia')
                  this.setState({
                    progressData: [...this.state.progressData, true],
                  })
                }}
                progressComplete={() => {
                  this.setState({
                    progressData: [...this.state.progressData, true],
                  })
                }}
              />
            </li>
          )
          break
        case 'standard':
          const contentMedia = standard.contents
        
          return (
            <li>
              <StandardEditor
                elementRef={(e) => { this.elementsRef[index2] = e }}
                data={{
                  title: standard.title,
                  text: contentMedia ? contentMedia.caption:'',
                  media: {
                    thumbnail: contentMedia ? contentMedia.gallery ? contentMedia.gallery.thumbnail:'':'',
                    source: contentMedia ? contentMedia.gallery ? contentMedia.gallery.source:'':'',
                  },
                }}
                mentions={this.state.common_items}
                validation={{
                  errors: this.state.errors,
                  prefix: 'elements',
                  index: index,
                  field: 'title'
                }}
                onTitleChange={event => {
                  this.titleChange(event, index)
                }}
                onStandardTextChange={event => {
                  this.standardTextChange(event, index)
                }}
                thumbnailChange={thumbnail => {
                  this.standardThumbnailChange(thumbnail, index)
                }}
                onMediaChange={event => {
                  this.mediaStandardChange(event, index)
                }}
                onDelete={event => this.deleteElement(event, index)}
                onDuplicate={event => this.duplicateElement(event, index)}
                progressMedia={() => {
                  console.log('progressMediaprogressMedia')
                  this.setState({
                    progressData: [...this.state.progressData, true],
                  })
                }}
                progressComplete={() => {
                  this.setState({
                    progressData: [...this.state.progressData, true],
                  })
                }}
              />
            </li>
          )
          break
        default:
          break
      }
    })

    this.SortableList = SortableContainer(({ items }) => {
      return (
        <ul className="list-unstyled">
          {items.map((value, index) => (
            <SortableItem
              key={`item-${index}`}
              index={index}
              index2={index}
              standard={value}
            />
          ))}
        </ul>
      )
    })
  }

  componentDidMount = () => {
    this.setState({
      standardData: this.props.stage_details,
      item_list: this.props.item_list
    })

    this.props.getStandardItems(this.props.stage_master.procedure_id, this.props.stage_master.surgeon_id)
    axios
      .get(`/api/client/client-stage-common-items?surgeon_id=${this.props.stage_master.surgeon_id}&procedure_id=${this.props.stage_master.procedure_id}`)
      .then(res => {
        this.setState({
          common_items: res.data.data
        })
      })
    if(this.props.enableDraft){
      if (this.props.stage_master.stage_type == 'custom') {
        this.props.getProcedureDetail(1, 'custom')
      } else {
        this.props.getProcedureDetail(1) 
      } 
      this.setState({ editMode: true, errors: [] }) 
    }
    this.props.setNewState({
      settings: false
    }) 
  }

  componentWillReceiveProps = nextProps => {
    if(this.props.stage_master != nextProps.stage_master){
      if(!nextProps.stage_master.draft){
        this.setState({
          editMode: false,
          item_list: nextProps.item_list,
          draft_success: false
        })
        this.refNotifier.hideThis()
      }
    }

    if (nextProps.stage_details != this.props.stage_details) {
      this.setState({
        standardData: nextProps.stage_details,
        item_list: nextProps.item_list,
        draft_success: false
      })
      this.refNotifier.hideThis()
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ standardData }) => ({
      standardData: arrayMove(standardData, oldIndex, newIndex),
      errors: [],
    }))
  }

  onContentSortChange = (contents, index) => {
    const { standardData } = this.state
    standardData[index].contents = contents

    this.setState({
      errors: [],
      standardData: standardData.splice(0),
    })
  }

  newSectionMenu = () => {
    return (
      <div className="row mt30">
        <div className="col-12">

          <AddNewSection
            addTextOnly={() => {
              this.newTextSection()
            }}
            addMediaGallery={() => {
              this.newMediaSection()
            }}
            addTextAndMedia={() => {
              this.newStandardSection()
            }}
          />

        </div>
      </div>
    )
  }

  newTextSection = () => {
    const { standardData } = this.state
    let textsection = {
      title: null,
      type: 'text',
      contents: {
        caption: '',
      },
    }
    standardData.push(textsection)
    this.setState({
      standardData,
    })
  }

  deleteElement = (event, index) => {
    const { standardData } = this.state
    event.preventDefault()
    standardData.splice(index, 1)
    this.setState({ 
      errors: [],
      standardData: standardData.splice(0) 
    })
  }

  deleteMediaElement = (event, index, subIndex) => {
    event.preventDefault()
    const { standardData } = this.state

    standardData[index].contents.splice(subIndex, 1)

    this.setState({ 
      errors: [],
      standardData: standardData.splice(0) 
    })
  }

  duplicateElement = (event, index) => {
    const { standardData } = this.state
    event.preventDefault()
    standardData.push(_.cloneDeep(standardData[index]))
    this.setState({ standardData: standardData.splice(0) }, () => {
      this.elementsRef[this.state.standardData.length - 1].scrollIntoView({behavior: 'smooth'});
    }) 
  }

  newMediaSection = () => {
    const { standardData } = this.state
    let mediasection = {
      title: null,
      type: 'media',
      contents: [
        {
          title: null,
          caption: null,
          gallery: {},
        },
      ],
    }
    standardData.push(mediasection)
    this.setState({
      standardData,
    })
  }

  newStandardSection = () => {
    const { standardData } = this.state
    let standardsection = {
      title: null,
      type: 'standard',
      contents: {
        caption: '',
        gallery: {
          source: null,
          thumbnail: null,
        },
      },
    }

    standardData.push(standardsection)
    this.setState({
      standardData,
    })
  }

  titleChange = (event, index) => {
    console.log('title changing')
    const { standardData } = this.state
    standardData[index].title = event.target.value

    this.setState({
      standardData: standardData.splice(0),
    })
  }

  textContentChange = (event, index) => {
    const { standardData } = this.state
    standardData[index].contents.caption = event.editor.getData()

    this.setState({
      standardData: standardData.splice(0),
    })
  }

  standardTextChange = (event, index) => {
    const { standardData } = this.state
    standardData[index].contents.caption = event.editor.getData()

    this.setState({
      standardData: standardData.splice(0),
    })
  }

  onSaveData = draft => {
    const { standardData } = this.state
    const { item_list } = this.state
    if(draft == 0){
      Event('Procedure', 'Update', this.props.title)
    }
    console.log('list', item_list)

    const data = new FormData()
    data.append('draft', draft)
    data.append('_method', 'PATCH')

    item_list.map((category) => {
      category.details.map((item) => {
        data.append(`item_ids[]`, item.id)
        data.append(`qty[]`, item.qty)
        data.append('type[]', category.category_name)
      })
    })

    standardData.map((standard, index) => {
      data.append(`elements[${index}][title]`, standard.title ? standard.title : '')
      data.append(`elements[${index}][type]`, standard.type)
      switch (standard.type) {
        case 'text':
          data.append(`elements[${index}][values]`, standard.contents.caption)
          break
        case 'standard':
          data.append(
            `elements[${index}][media-text]`,
            standard.contents.caption
          )
          data.append(`elements[${index}][media-id]`, 0)
          data.append(`elements[${index}][media-updated]`, 1)
    
          data.append(
            `elements[${index}][media-value]`,
            standard.contents.gallery.source ? standard.contents.gallery.source:''
          )
          data.append(
            `elements[${index}][media-original]`,
            standard.contents.gallery.originSource
          )
          data.append(
            `elements[${index}][media-type]`,
            standard.contents.gallery.type
          )
          break
        case 'media':
          standard.contents.map((content, i) => {
            data.append(
              `elements[${index}][media-text][]`,
              content.gallery.caption ? content.gallery.caption : ''
            )
            data.append(
              `elements[${index}][media-title][]`,
              content.gallery.title ? content.gallery.title : ''
            )
            data.append(
              `elements[${index}][media-type][]`,
              content.gallery.type
            )
            data.append(`elements[${index}][media-id][]`, 0)
            data.append(`elements[${index}][media-updated][]`, 1)
            data.append(
              `elements[${index}][media-value][${i}]`,
              content.gallery.source ? content.gallery.source:''
            )
            data.append(
              `elements[${index}][media-original][${i}]`,
              content.gallery.originSource
            )
            
           
          })

          break
        default:
          break
      }
    })

    const { stage_master } = this.props
    procedureAction(stage_master.id,draft)
    let url = `api/client/stage-default-element/${stage_master.id}`

    
    let config = {
      headers: {
        'content-type': 'multipart/form-data',
      },

      onUploadProgress: progressEvent => {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        showLoader(percentCompleted >= 95 ? 95 : percentCompleted);
        setLoaderProg(percentCompleted >= 95 ? 95 : percentCompleted)
      },
    }
    axios
      .post(url, data, config)
      .then(result => {
        if (!draft){
          this.setState({
            editMode: false,
            preview: false,
            loadFrom: false,
            draft_success: false,
            showUpdateSuccess: true,
            progress: 0
          }, () => { 
            if(this.props.stage_master.stage_type == 'custom'){
              this.props.getProcedureDetail(0, 'custom')
            }else{
              this.props.getProcedureDetail(0)
            }})
        }else{
          this.setState({
            progress: 0,
            draft_success: true
          })
        }
        setLoaderProg(99)
        setTimeout(() => {
          hideLoader()
        }, 800);
      })
      .catch(errors => {
        console.log(errors)
        console.log(errors.response)
        console.log(errors.response)
        if (errors.response.status != 422) {
          this.setState({
            progress: 0,
          })
          return
        }
        this.setState({
          progress: 0,
          errors: errors.response.data.errors
        }, () => {
          let targetOffset = $('.is-invalid').offset() ? $('.is-invalid').offset().top - 20:null;
            if (targetOffset){
              $('html, body').animate({scrollTop: targetOffset}, 100);
            }
        })
        setLoaderProg(99)
        setTimeout(() => {
          hideLoader()
          setLoaderProg(99)
        }, 800);
      })
  }

  mediaStandardChange = (event, index) => {
    const { standardData } = this.state
    standardData[index].contents.gallery.source = event.data
    standardData[index].contents.gallery.type = event.type
    if (event.type == 'image')
      standardData[index].contents.gallery.thumbnail = event.data

    this.setState({
      standardData: standardData.splice(0),
    })
  }

  standardThumbnailChange = (thumbnail, index) => {
    const { standardData } = this.state
    standardData[index].contents.gallery.thumbnail = thumbnail
    this.setState({
      standardData: standardData.splice(0),
    })
  }

  addNewMedia = (event, index) => {
    console.log('add media', event, index)
    event.preventDefault()
    const { standardData } = this.state
    standardData[index].contents.push({
      gallery: {
        show: true,
        thumbnail: null,
        source: null,
        caption: null,
        title: null,
        id: 0,
      },
    })
    this.setState(
      {
        standardData: standardData.splice(0),
      },
      () => {
        console.log(this.state.standardData)
      }
    )
  }

  onContentTitleChange = (event, item_index, index) => {
    const { standardData } = this.state
    standardData[index].contents[item_index].gallery.title = event.target.value
    this.setState({
      standardData: standardData.splice(0),
    })
  }

  onContentCaptionChange = (event, item_index, index) => {
    const { standardData } = this.state
    standardData[index].contents[
      item_index
    ].gallery.caption = event.editor.getData()
    this.setState({
      standardData: standardData.splice(0),
    })
  }

  onContentMediaChange = (event, item_index, index) => {
    console.log(event, item_index, index)
    const { standardData } = this.state
    standardData[index].contents[item_index].gallery.source = event.data
    standardData[index].contents[item_index].gallery.type = event.type
    if (event.type == 'image')
      standardData[index].contents[item_index].gallery.thumbnail = event.data
    this.setState({
      standardData: standardData.splice(0),
    })
  }

  onContentThumbnailChange = (event, item_index, index) => {
    console.log('event', event)
    const { standardData } = this.state
    standardData[index].contents[item_index].gallery.thumbnail = event
    this.setState({
      standardData: standardData.splice(0),
    })
  }

  editAction = () => {
    const progressList = $('.pie-wrapper').length
    console.log('progressLisst',progressList,this.props.video_progress)
    if(this.state.preview){
      return(
        <div className="row">
          <div className="col-md-12">

            <Notifier show={true} onRef={ref => (this.refNotifier2 = ref)} type="warning" styleClass="mb30 mt0" hideThisNotif={() => {
              this.refNotifier2.hideThis(); 
              this.setState({
                edit: true,
                preview: false,
              }) }}>
              <p className="m-0">
                <b>Preview mode</b> - If you like what you see, you can{' '}
                <u
                  className="font-weight-bold c-pointer"
                  onClick={() => {
                    this.refNotifier2.hideThis()
                    this.onSaveData(0)
                    this.props.setNewState({
                      procedure_stage_edit: false
                    })
                  }}
                >
                  Update Notes
                </u>{' '}
                or make edits by{' '}
                <u
                  className="font-weight-bold c-pointer"
                  onClick={() => {
                    this.refNotifier2.hideThis()
                    this.setState({
                      edit: true,
                      preview: false,
                    })
                  }}
                >
                  Closing preview
            </u>
              </p>
            </Notifier>
          </div>
        </div> 
      )
    }
    return(
      <div className="row mb0">
        
        <ConfirmationModal
            title="Save to draft"
            message="Do you want to save draft?"
            confirmText="Yes"
            cancelText="Cancel"
            confirm={()=>{  
              this.onSaveData(1)
            }}
            idModal="save-to-draftmodal"
            cancel={() => {
              $('.modal').modal('hide')
            }}
          />
        <div className="col-md-12 text-right ">

          <EditMenu>
            <li>
              <Button primary onClick={() => {
                this.onSaveData(0)
                this.props.setNewState({
                  procedure_stage_edit: false
                })
              }}
              disabled={progressList}
              >
                Update
              </Button>
            </li>
            <li>
              <Button tertiary onClick={() => {
                $("#save-to-draftmodal").modal('show')
              }}
              disabled={progressList}
              >Save Draft</Button>
            </li>
            <li>
              <Button tertiary onClick={() => {
                this.setState({
                  preview: true,
                  draft_success: false
                })
              }}
              disabled={progressList}
              >Preview</Button>
            </li>
            
            <li className="has-line">
              <Button tertiary
                disabled={progressList}
                onClick={() => {
                  if (this.props.stage_master.stage_type == 'custom') {
                    this.props.getProcedureDetail(0, 'custom')
                  } else {
                    this.props.getProcedureDetail(0)

                  }

                  this.setState({
                    editMode: false,
                    draft_success: false
                  })
                  this.props.setNewState({
                    procedure_stage_edit: false
                  })
                }}>Cancel</Button>
            </li>
          </EditMenu> 
        </div>
      </div>
    )
  }

  preview = () => {
    const { standardData } = this.state
    const viewData = standardData.map(item => {
      switch (item.type) {
        case 'text':
          return (
            <TextElement
              className="mb20" 
              data={{
                title: item.title,
                contents: {
                  caption: item.contents.caption,
                }
              }}
            />
          )
        case 'media':
          return (
            <MediaElement data={item.contents} title={item.title} className="mb20" />
          )
        case 'standard':
          let media = item.contents
          return ( 
            <StandardElement className="mb20" data={{ title: item.title, contents: media }} />
          )
        default:
          return
      }
    })

    return (
      <div className="col-md-12 px0">
        <div className="" id="itemListAccordion">
          {viewData}
        </div>
      </div>
    )
  }

  renderEditMode = () => {
    const { standardData } = this.state
    if(this.state.preview){
      return this.preview()
    }else{
      return (
        <div className="col-md-12 px0">
          <div className="" id="itemListAccordion">
            {
              <this.SortableList
                items={standardData}
                onSortEnd={this.onSortEnd}
                distance={1}
                useDragHandle
                useWindowAsScrollContainer={true}
              />
            }
            {this.newSectionMenu()}
          </div>
        </div>
      )
    }
   
  }

  renderView = () => {
    const { standardData } = this.state
    const viewData = standardData.map(item => {
      switch (item.type) {
        case 'text':
          return (
            <TextElement
              className="mb20"
              data={{
                title: item.title,
                contents: {
                  content: item.contents.caption,
                }
               
              }}
              procedure={true}
            />
          )
        case 'media':
          // return <StandarMediaView data={item.contents} title={item.title} innerProps={`gallery`} classStyle="mb30 rounded-0" />
          return <MediaElement data={item.contents} title={item.title} innerProps={`gallery`} className="mb20" />
        case 'standard':
          return <StandardElement className="mb20" data={{ title: item.title, contents: item.contents }} />
          {/* <StandardElement
            className="mb20" 
            data={{ 
              title: item.title, 
              caption: item.contents.caption, 
              source: item.contents.gallery.source
            }}
          /> */}
        default:
          return
      }
    })

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="" id="itemListAccordion">
            {viewData}
          </div>
        </div>
      </div>
    )
  }

  updateData = (draft = 0) => {
    const { stage_master } = this.props
    axios
      .get(`api/client/stage-default-element/${stage_master.id}?draft=${draft}`)
      .then(result => {
        this.setState({
          standardData: result.data.data,
          editMode: draft,
        })
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
      })
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.stage != this.props.stage){
      this.refNotifier.hideThis() 
      this.setState({
        showUpdateSuccess: false
      })
    }
  }
  render() {
    const { stage } = this.props
    let name = ''
    if(stage && stage.name){
      name = stage.name;
    }else if(stage && stage.stage && stage.stage.name){
      name = stage.stage.name;
    }
    console.log(stage)
    console.log('sdsdsd', this.state, this.props)
    console.log('stagestagestagestage',stage)
    return (
      <div className="float-left w-100">
        <div className="row mt32">
          <div className="col-md-12 mx-auto d-flex justify-content-between align-items-center page-description">
            <h2 className="bold mb-2 mb-sm-0 bold">
            {this.props.title} 
            </h2>
            {this.state.editMode ? null : ( 
              <Button tertiary onClick={() => { 
                this.props.setNewState({
                  loading: true
                })
                if (this.props.stage_master.stage_type == 'custom') {
                  this.props.getProcedureDetail(1, 'custom')
                } else {
                  this.props.getProcedureDetail(1) 
                } 
                this.setState({ editMode: true, errors: [] }) 
                this.props.setNewState({
                  procedure_stage_edit: true
                })
              }} className="ml-0 mr-2 mr-sm-0" id="edit-standard-btn">
                Edit {this.props.title} 
            </Button>
            )}
          </div>
          <div className="col-md-12">

            <Notifier show={this.state.showUpdateSuccess} message={`${name } successfully updated!`} onRef={ref => (this.refNotifier = ref)} type="success" styleClass="mb30 mt0" hideThisNotif={() => {
              this.refNotifier.hideThis(); 
              this.setState({
                showUpdateSuccess: false
              })
            }} ></Notifier>
          </div>
        </div> 
        <div className="row">
          <div className="col-md-12"> 
            {this.state.editMode ? this.editAction() : null}
            {this.state.draft_success ?  
              <div className="row">
                <div className="col-md-12 mt10">
                  <Notifier show={true} message='Draft has been saved!' onRef={ref => (this.refNotifier3 = ref)} type="success" styleClass="mb30 mt0" hideThisNotif={() => {
                    this.refNotifier3.hideThis();
                  }} ></Notifier>
                </div>
              </div>
             : null}
            {this.props.loading ? <StandardSkeleton /> : 
              <StandardItemList 
                edit={this.state.preview ? false : this.state.editMode} 
                stage_master={this.props.stage_master} 
                item_list={this.state.item_list}  
                setNewState={(states) => {this.setState(states)}}/> 
            }
            {this.props.loading ? '': this.state.editMode ? this.renderEditMode() : this.renderView()}
            {/* <WholeBodyloader message="Saving! please wait..." progress={this.state.progress} type="spinner" /> */}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return({
    // item_list: state.quicknotes.item_list
    standard_items: state.item.standard_items, 
    video_progress: state.video.progress

  })
}

export default connect(
  mapStateToProps,
  { getItemsPerCategory, getStandardItems }
)(StandardLayout)
