import {
  PAYMENT_UPDATE,
  FETCH_PAYMENT_DETAIL,
  HAS_OLD_SUBSCRIPTION,
  NO_SUBSCRIPTION
} from './types'
import axios from 'axios'
import $ from 'jquery'
export const getPaymentDetail = (callback = () => {}) => {
  return dispatch => {
    dispatch({
      type: FETCH_PAYMENT_DETAIL,
    })
    const url = `api/client/subscribe/details`

    axios
      .get(url)
      .then(result => {

        dispatch({
          type: PAYMENT_UPDATE,
          details: result.data,
        })
        callback()
      })
      .catch(error => {
        if (
          error.response.status == 404 &&
          error.response.data.hasOldSubscription
        ) {
          dispatch({
            type: HAS_OLD_SUBSCRIPTION,
          })
        }else{
          dispatch({
            type: NO_SUBSCRIPTION,
          })
        }
      })
  }
}
