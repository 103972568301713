import React, { Component } from 'react'

export default class Text extends Component {
 

  render() {
    const { data, classStyle } = this.props
    return (
      <div className={`card p-0 shadow-sm border-0 w-100 float-left ${classStyle}`}>
        <div className="card-header bg-white p15 border-0" id="note-headeing-1">
          <h5 className="mb-0 d-flex align-items-center">{data.title}</h5>
        </div>
        <div className="card-body pt-0 mt5 px15">
          {' '}
          <div
            dangerouslySetInnerHTML={{
              __html: data.caption,
            }}
          />
        </div>
      </div>
    )
  }
}
