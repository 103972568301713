import React, { Component } from 'react'

export class InfoLabel extends Component {
  render() {
    const { message, onClick, children, style, changeable, className='' } = this.props
    return (
      <label className={`info-label ${changeable ? 'changeable' : ''} ${className}`} onClick={onClick} style={style}>
        {this.props.children}
      </label>
    )
  }
}

export default InfoLabel
