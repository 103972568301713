import React, { Component } from 'react'

export class ErrorText extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        const { error } = this.props
        if (error) {
          return (
            <span class="error-state-icon error">
              <i class="mdi mdi-information mdi-rotate-180" /> {error}
            </span>
          )
        } else {
          return null
        }

    }
}

export default ErrorText
