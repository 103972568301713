import React, { Component } from 'react'
import $ from 'jquery'
export class Modal extends Component {
    render() {
        const { title, idModal, confirmText, cancelText, bodyClass, 
            size, closeModalOnSubmit, noFooter, className='',
            midButtonText, midButtonAction, confirmDisabled=false,
            modalContentClass='',
            headerClass = '', noCloseBtn=false, closeAction, footerClass="" } = this.props

        /* 
            Available sizes are
                modal-xs = width 372px
                modal-sm = width 522px
                modal-md = width 598px
                modal-lg = width 810px
                modal-xl = width 1040px
                modal-full = fullscreen
        */
       
        return (
            <div className={`modal fade ${size} ${className}`} id={idModal ? idModal:'myModal'} tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className={`modal-content ${modalContentClass}`}>
                        <div className={`modal-header ${headerClass}`}>
                            
                            <h3 className="modal-title">{title}</h3>
                            {
                                noCloseBtn ? null : 
                                <button type="button" className="close" onClick={() => {
                                    $(`#${idModal}`).modal('hide')
                                    if(closeAction){
                                        closeAction()
                                    }
                                }} aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            }
                        </div>
                        <div className={`modal-body ${bodyClass}`}>
                            {this.props.children}
                        </div>
                        <div className={`modal-footer text-left ${noFooter ? 'd-none':''} ${footerClass}`}> 
                            {
                                confirmText ? <div className={`cta cta-primary ${confirmDisabled ? 'disabled' : ''}`} data-dismiss={closeModalOnSubmit ? 'modal' : ''} onClick={this.props.confirm} >{confirmText}</div> : null
                            }
                            

                            {
                                midButtonText ? <div className="cta cta-secondary" onClick={this.props.midButtonAction}>{midButtonText}</div>:null
                            }
 
                            
                            <div className={`cta cta-${midButtonText ? 'tertiary':'secondary'}`} onClick={()=>{
                                $(`#${idModal}`).modal('hide')
                                // this.props.cancel();                              
                            }}>{cancelText}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Modal
