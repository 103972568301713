import React, { Component } from 'react'
import $ from 'jquery'

export class QtyInput extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
       qty: 1
    }
  } 
  render() {
    const { qty } = this.props
    return (
      <input type="number" min="1" name className="form-control text-center p-0  bg-white"
        value={this.state.qty}
        onChange={(e)=>{ 
          const re = /^[0-9\b]+$/;
          if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ value: e.target.value })
          }
        }} 
      />
    )
  }
}

export default QtyInput
