import arrayMove from 'array-move'
import axios from 'axios'
import $ from 'jquery'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getSurgeon } from '../../actions/SurgeonActions'
import { TextInput } from '../../components/commons/forms'
import { ConfirmationModal, Modal } from '../../components/commons/modals'
import SidePanel from '../../components/commons/panel/SidePanel'
import {
  TabBody,
  TabContent,
  TabHead,
  TabNavigation,
  Tabs,
} from '../../components/commons/tabs'
import Confirmation from '../../components/Confirmation'
import {
  hideLoader,
  setLoaderProg,
  showLoader,
} from '../../components/loader/loading_screen'
import ProcedureSettings from '../../components/ProcedureSettings'
import ConsumableLayout from '../../components/stages/ConsumableLayout'
import InstrumentsLayout from '../../components/stages/InstrumentsLayout'
import PositioningEquipmentLayout from '../../components/stages/PositioningEquipmentLayout'
import PositioningLayout from '../../components/stages/PositioningLayout'
import RoomSetupLayout from '../../components/stages/RoomSetupLayout'
import StandardLayout from '../../components/stages/StandardLayout'
import { getError } from '../../utilities'
import { Event } from '../../utilities/analytics'

class Procedure extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      settings: true,
      progressLoading: 0,
      procedure: {},
      errors: [],
      restriction: -1,
      notifications: [],
      notifications_meta: [],
      progress_loading: 0,
      surgical_trees: [],
      surgeon_id:'',
      stage_name: '',
      stage_types: [
        { id: '', name: 'Please Select' },
        { id: 'standard', name: 'Standard' },
      ],
      surgical_tree_first: [],
      surgical_tree_second: [],
      custom_stages: [],
      stage_details: {
        stage: {
          draft: 0,
        },
      },
      name: '',
      stage: {
        id: '',
      },
      enableDraft: false,
      unique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      key_load:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      swiperunique:
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15),
      key: this.makeKey(),
      showSuccess: false,
      scrollTab: 0,
      procedure_stage_edit: false,
      active_stage_id: 0,
      savingStage: false
    }
  }

  makeKey() {
    return (
      Math.random()
        .toString(36)
        .substring(2, 15) +
      Math.random()
        .toString(36)
        .substring(2, 15)
    )
  }
  componentWillMount() {
    if (this.props.router.location.query.scroll) {
      this.setState({
        scrollTab: true,
      })
    }
  }
  componentDidMount() {
    this.props.router.setRouteLeaveHook(
      this.props.route,
      this.routerWillLeave.bind(this)
    )
    window.onbeforeunload = this.handleOnBeforeUnload
    let enable = true
    console.log('this.props.router.location', this.props.router.location)
    this.getProcedure(this.props)
    if (this.props.router.location.query.surgeon_id) {
      this.props.getSurgeon(this.props.router.location.query.surgeon_id)
      if (this.props.router.location.query.stage) {
        this.getStage(this.props.router.location.query.stage)
      }
      this.getRestriction()
    }

    this.getSettings(enable)

    $('main').on('classChange', () => {
      // this.tabsRef.reInit()
    })
    // this.setState({
    //   key:v this.makeKey(),
    // })
  }

  routerWillLeave(nextLocation) {
    // if (this.state.stage_details){
    //   if (this.state.stage_details.stage.draft == 1)
    //     return 'Your work is not saved! Are you sure you want to leave?'
    // }
  }

  handleOnBeforeUnload = e => {
    // if (this.state.stage_details.stage.draft == 1) {
    //   const message = 'Your work is not saved! Are you sure you want to leave?'
    //   e.returnValue = message
    //   return message
    // }
  }

  componentWillReceiveProps = nextProps => {
    if (this.props.params.id != nextProps.params.id) {
      this.setState({
        stage: {
          id: '',
        },
        surgical_trees: [],
        surgical_tree_first: [],
        surgical_tree_second: [],
        // key: this.makeKey(),
      })
      this.getProcedure(nextProps)
      this.getSettings()
    }
  }

  getStage = id => {
    axios
      .get(`api/client/stage-data/${id}`)
      .then(result => {
        console.log('result.data', result.data)
        this.setState(
          {
            stage: result.data.detail,
            settings: false,
            name:
              result.data.stage.stage_type == 'main'
                ? result.data.detail.name
                : result.data.detail.stage.name,
          },
          () => {
            this.getSettings(false)
            for (let i = 1; i <= 5; i++) {
              setTimeout(() => {
                showLoader(i * 20)
              }, i * 200)
            }
            this.getProcedureDetail(0, result.data.stage.stage_type)
          }
        )
      })
      .catch(error => {})
  }

  setNewState = states => {
    this.setState(states)
  }

  getProcedure = props => {
    let procedure_id = props.params ? props.params.id : ''

    let url = `/api/specialty-procedure/${procedure_id}`
    axios
      .get(url)
      .then(res => {
        this.setState({
          procedure: res.data.data,
          stage_details: [],
          // key: this.makeKey(),
        })
        this.getRestriction()
        this.getCustomStages(this.state.procedure.id)
      })
      .catch(err => {
        console.log(err)
        console.log(err.response)
      })
  }

  saveSorting = () => {
    const { custom_stages, procedure } = this.state
    let data = new FormData()
    custom_stages.map(stage => {
      data.append('stage_ids[]', stage.id)
    })
    data.append('procedure_id', procedure.id)
    data.append('_method', 'PATCH')

    let url = '/api/client/custom-stage-sort'

    axios
      .post(url, data)
      .then(res => {
        this.setState({
          showSuccess: true,
        })
        this.getCustomStages(procedure.id)
        if (this.refTabsProcedure) {
          this.refTabsProcedure.getWidth()
        }
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
      })
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ custom_stages }) => ({
      custom_stages: arrayMove(custom_stages, oldIndex, newIndex),
    }))
  }

  getCustomStages = id => {
    let url = `/api/client/custom-stage?procedure_id=${id}&surgeon_id=${this.props.router.location.query.surgeon_id}`

    axios
      .get(url)
      .then(res => {
        this.setState({
          custom_stages: res.data.data,
          loading: false,
        })
        hideLoader()

        if (this.state.scrollTab) {
          setTimeout(() => {
            this.refTabsProcedure.goToScroll()
            this.setState({
              scrollTab: false,
            })
          }, 700)
        }
      })
      .catch(error => {
        hideLoader()
        console.log(error)
        console.log(error.response)
      })
  }

  getProcedureDetail = (
    draft = 0,
    type = 'main',
    loading = false,
    noloading = false,
    callback = () => {}
  ) => {
    
    const { stage } = this.state
    const { surgeon } = this.props
    const data = new FormData()
    data.append('procedure_id', this.props.params ? this.props.params.id : '')
    if (type == 'custom') {
      data.append('stage_id', stage.stage.id)
    } else {
      data.append('stage_id', stage.id)
    }
    data.append('surgeon_id', surgeon.id)
    data.append('stage_type', type)
    data.append('draft', draft)

    let config = {}
    if (loading) {
      config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
        onUploadProgress: progressEvent => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          showLoader(percentCompleted >= 95 ? 99 : percentCompleted)
          setLoaderProg(percentCompleted >= 95 ? 99 : percentCompleted)
        },
      }
    } else {
      config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
    }
    if (!noloading)
      this.setState({
        loading: true,
      })

    axios
      .post(`api/client/stage`, data, config)
      .then(res => {
        if (!noloading) this.getRestriction()
        this.setState(
          {
            stage_details: res.data,
            settings: false,
          },
          () => {
            callback()
          }
        )
        if (!draft) {
          this.getNotifications()
          this.getCustomStages(this.state.procedure.id)
        }
      })
      .catch(err => {})
  }

  getRestriction = () => {
    let surgeon_id = 0
    let { surgeon } = this.props
    if(surgeon && surgeon.id){
      surgeon_id = surgeon.id
    }else{
      if(this.props.router.location.query.surgeon_id){
        surgeon_id = this.props.router.location.query.surgeon_id
      }
    }
    this.setState({
      loading: true,
    })
    let url = `/api/client/surgeon-procedure?procedure_id=${
      this.props.params ? this.props.params.id : ''
    }&surgeon_id=${surgeon_id}`

    axios
      .get(url)
      .then(res => {
        this.setState({
          restriction: res.data.current_active,
          loading: false,
        })
      })
      .catch(err => {
        console.log(err)
        this.props.router.push(`/quicknotes/${surgeon_id}`)
      })
  }

  loadSurgeonProcedure = selected_surgeon_id => {
    const { surgeon } = this.props
    showLoader(0, true)

    if (selected_surgeon_id) {
      let data = new FormData()
      data.append('surgeon_id', surgeon.id)
      data.append('surgeon_to_copy_id', selected_surgeon_id)
      data.append('procedure_id', this.props.params ? this.props.params.id : '')

      let url = '/api/client/surgeon-procedure-copy'

      axios
        .post(url, data)
        .then(res => {
          console.log('popo1', this.props)
          this.getSettings()
          this.getCustomStages(this.props.params ? this.props.params.id : '')
          this.getRestriction()
          // this.props.getProcedureDetail()
          $('#loadFromModalCenter').modal('hide')
          $('#loadFromSuccessModal').modal('show')
          hideLoader()
        })
        .catch(err => {
          console.log(err)
          console.log(err.response)
          hideLoader()
        })
    }
  }

  getSettings(enable = true) {
    this.setState({ loading: 1 })

    let url = `/api/surgical-trees`
    axios
      .get(url)
      .then(res => {
        let surgical_trees = res.data.data
        let out = surgical_trees.findIndex(stage => {
          return stage.name == 'Draping'
        })
        let temp = surgical_trees

        this.setState(
          {
            surgical_trees: surgical_trees,
            surgical_tree_first: temp.slice(0, out + 1),
            surgical_tree_second: temp.slice(out + 1, temp.length),
          },
          () => {
            this.setState({
              // key: this.makeKey()
            })
            // this.tabsRef.reInit()

            if (this.refTabsProcedure) {
              this.refTabsProcedure.getWidth()
            }

            if (!enable) {
            }
          }
        )

        this.getNotifications()
      })
      .catch(error => {
        console.log(error)
      })

    setTimeout(() => {
      this.setState({
        settings: enable,
        loading: 0,
      })
    }, 1000)
  }

  getNotifications = () => {
    const { procedure } = this.state
    const { surgeon } = this.props

    this.setState({
      loading: true,
    })
    let url = `/api/client/setting-notification?surgeon_id=${
      surgeon.id
    }&procedure_id=${this.props.params ? this.props.params.id : ''}`

    axios
      .get(url)
      .then(res => {
        this.setState({
          notifications: res.data.data,
          notifications_meta: res.data,
        })
      })
      .catch(err => {
        console.log('popop', err)
        console.log('popop', err.response)
      })
  }

  deleteProcedure = () => {
    const { surgeon } = this.props

    let data = new FormData()
    data.append('surgeon_id', surgeon.id)
    data.append('procedure_id', this.props.params ? this.props.params.id : '')
    data.append('_method', 'DELETE')

    let url = '/api/client/setting-procedure-delete'

    axios
      .post(url, data)
      .then(res => {
        console.log('popo', this.props.router)
        this.props.router.replace(`/quicknotes/${surgeon.id}`)
      })
      .catch(err => {
        console.log('popo', err)
        console.log('popo', err.response)
      })
  }

  dataLayout = () => {
    const { stage_details } = this.state

    let type = stage_details.stage ? stage_details.stage.layout_type : ''

    switch (type) {
      case 'consumable':
        return (
          <ConsumableLayout
            setNewstate={this.setNewstate}
            getProcedureDetail={this.getProcedureDetail}
            stage_details={stage_details.detail}
            stage_master={stage_details.stage}
            enableDraft={this.state.enableDraft}
            title={this.state.name}
            setNewState={this.setNewState}
            loading={this.state.loading}
          />
        )
      case 'instruments':
        return (
          <InstrumentsLayout
            setNewstate={this.setNewstate}
            getProcedureDetail={this.getProcedureDetail}
            stage_details={stage_details.detail}
            stage_master={stage_details.stage}
            trays={stage_details.options.trays}
            enableDraft={this.state.enableDraft}
            title={this.state.name}
            setNewState={this.setNewState}
            loading={this.state.loading}
          />
        )
      case 'positioning':
        return (
          <PositioningLayout
            stage_details={stage_details.detail}
            stage_master={stage_details.stage}
            getProcedureDetail={this.getProcedureDetail}
            enableDraft={this.state.enableDraft}
            title={this.state.name}
            setNewState={this.setNewState}
            loading={this.state.loading}
          />
        )
      case 'positioning-equipment':
        return (
          <PositioningEquipmentLayout
            stage_details={stage_details.detail}
            stage_master={stage_details.stage}
            getProcedureDetail={this.getProcedureDetail}
            enableDraft={this.state.enableDraft}
            title={this.state.name}
            setNewState={this.setNewState}
            loading={this.state.loading}
          />
        )
      case 'room-setup':
        return (
          <RoomSetupLayout
            stage={this.state.stage}
            setNewstate={this.setNewstate}
            edit={this.state.edit}
            stage_details={stage_details.detail}
            stage_master={stage_details.stage}
            getProcedureDetail={this.getProcedureDetail}
            enableDraft={this.state.enableDraft}
            title={this.state.name}
            setNewState={this.setNewState}
            loading={this.state.loading}
          />
        )
      case 'standard':
        return (
          <StandardLayout
            stage={this.state.stage}
            setNewstate={this.setNewstate}
            stage_details={stage_details.detail}
            stage_master={stage_details.stage}
            getProcedureDetail={this.getProcedureDetail}
            item_list={this.generateItemList()}
            enableDraft={this.state.enableDraft}
            title={this.state.name}
            setNewState={this.setNewState}
            loading={this.state.loading}
          />
        )
      default:
        return ''
    }
  }

  generateItemList = () => {
    const { stage_details } = this.state

    let item_list = []
    let temp = {}
    temp.id = 1
    temp.category_name = 'PRN'
    temp.details = stage_details.options['PRN']
      ? stage_details.options['PRN']
      : []

    item_list.push(temp)

    let temp2 = {}
    temp2.id = 2
    temp2.category_name = 'Basic OR'
    temp2.details = stage_details.options['Basic OR']
      ? stage_details.options['Basic OR']
      : []

    item_list.push(temp2)

    return item_list
  }

  changeTabsKey() {
    // this.setState({
    //   key: this.makeKey(11)
    // })
  }

  checkTabActive(condition, el) {
    if (condition) {
      return true
    }
    return false
  }

  saveStage = () => {
    this.setState({
      savingStage: true
    })
    const { icon, stage_name, stage_type, procedure } = this.state
    const { surgeon } = this.props
    console.log('stateeeeeee', this.state, 'proppppppppppsss', this.props)
    let data = new FormData()
    data.append('procedure_id', procedure.id)
    data.append('surgeon_id', surgeon.id)
    data.append('name', stage_name)
    if (icon && icon.source) {
      data.append('icon', icon.source)
    }
    data.append('type', stage_type)

    let url = '/api/client/custom-stage'

    this.setState({
      loading: true,
    })
    axios
      .post(url, data)
      .then(res => {
        this.getCustomStages(procedure.id, 1)
        this.changeTabsKey()
        $('#addStageModalProc').modal('hide')
        this.setState({
          loading: false,
          savingStage: false,
        })
        Event('Procedure', 'Add Custom Stage', stage_name)
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
        this.setState({
          errors: error.response.data.errors,
          loading: false,
          savingStage: false,
        })
      })
  }

  render() {
    const { surgeon } = this.props

    return (
      <div className="container pt-0 mb30 pb30">
        <ConfirmationModal
          idModal="loadFromSuccessModal"
          title="Procedure Loaded"
          message="You have successfully loaded a procedure from surgeon."
          confirmText="Okay"
          confirm={() => {
            $('#loadFromSuccessModal').modal('hide')
          }}
        />
        <ConfirmationModal
          idModal="unlockModal"
          title="Action not possible!"
          message={
            this.state.procedure_stage_edit
              ? 'You need switch back from edit mode.'
              : 'You need to complete previous stage to unlock this stage.'
          }
          confirmText="Okay"
          confirm={() => {
            $('#unlockModal').modal('hide')
          }}
        />
        <Modal
          idModal="addStageModalProc"
          size="modal-sm"
          title="Add new stage"
          confirmText="Add stage"
          confirmDisabled={this.state.stage_type == ''  || this.state.savingStage}
          cancelText="Cancel"
          bodyClass="add-glove-modal-body"
          confirm={e => {
            this.saveStage()
          }}
          cancel={e => {
            //fn here
          }}
        >
          <div className="form-group mt40">
            <TextInput
              label={'Name new stage'}
              value={this.state.stage_name}
              placeholder={'Name'}
              errorMessage={getError(this.state.errors, 'name')}
              containerClass={`mb20`}
              onValueChange={event => {
                this.setState({ stage_name: event.target.value })
              }}
            />
          </div>
        </Modal>
        <SidePanel surgeon={surgeon}></SidePanel>

        {/* <WholeBodyloader progress={this.state.progress_loading} /> */}
        <Confirmation
          title="Update Elements"
          message="This updates the elements."
          confirmText="Yes"
          cancelText="Cancel"
          idModal="update-confirmation"
          cancel={() => {
            $('.modal').modal('hide')
          }}
        />

        <Confirmation
          title="Save to draft"
          message="This Save to draft."
          confirmText="Yes"
          cancelText="Cancel"
          idModal="save-to-draft"
          cancel={() => {
            $('.modal').modal('hide')
          }}
        />
        <div className="row">
          {/* <WholeBodyloader
            message="Saving! please wait..."
            progress={this.state.progressLoading}
            type="progress"
          /> */}

          {/* <div className="col-lg-3 col-md-4 mb30 order-2 order-md-1 m-xst20">
            {surgeon.id ? <SurgeonInfo procedure={true} /> : null}
            {surgeon.id ? <SurgeonSpecialties setNewState={(states) => {this.setState(states)}} /> : null}
          </div> */}
          <div className="col-md-12 order-1 order-md-2" id="home">
            <div className="row">
              <div className="col-md-12 d-none d-md-block">
                <h1 className="page-title">
                  {this.state.procedure
                    ? this.state.procedure.name
                    : 'Procedure name'}
                  <a
                    href="#settings"
                    onClick={e => {
                      e.preventDefault()
                      this.getSettings()
                      this.setState({
                        settings: true,
                        enableDraft: false,
                        stage: {},
                        stage_details: [],
                        procedure_stage_edit: false
                      })
                    }}
                  >
                    <i className="mdi mdi-settings ml15" />{' '}
                  </a>{' '}
                </h1>
              </div>

              {/* <div className="col-md-12 mt0 profile-card">
                <div className="col-md-12 card-swiper px0">
                  <Swiper
                    {...swiperParams}
                    key={this.state.swiperunique}
                    shouldSwiperUpdate={true}
                  >
                    {firstlist}
                    {custom_stages}
                    {secondlist}
                  </Swiper>
                </div>
              </div> */}
              <div className="col-md-10 mt8">
                <Tabs
                  className=""
                  key={this.state.key}
                  onRef={ref => (this.refTabsProcedure = ref)}
                  navLinks={() => {
                    return (
                      <TabHead className="col-md-12 px0">
                        {this.state.surgical_tree_first.map(
                          (surgical_tree, index) => {
                            return (
                              <TabNavigation
                                target="my-profile"
                                key={`surgical-main-${index}`}
                                id={`surgical_tree_first-${index}`}
                                active={this.checkTabActive(
                                  surgical_tree.id == this.state.stage.id,
                                  `surgical_tree_first-${index}`
                                )}
                                onClick={e => {
                                  // this.tabsRef.scrollOnclick()
                                  if ($(e.target).hasClass('disabled')) {
                                    $('#unlockModal').modal('show')
                                    return
                                  }
                                  $(e.target)
                                    .closest(`.scrollable-tabs`)
                                    .animate(
                                      {
                                        scrollLeft: `${e.target.offsetLeft -
                                          55}px`,
                                      },
                                      150
                                    )
                                  this.setState(
                                    {
                                      stage: surgical_tree,
                                      loading: true,
                                      settings: false,
                                      enableDraft: false,
                                      name: surgical_tree.name,
                                    },
                                    () => {
                                      for (let i = 1; i <= 5; i++) {
                                        setTimeout(() => {
                                          showLoader(i * 20)
                                        }, i * 200)
                                      }
                                      this.getProcedureDetail()
                                    }
                                  )
                                }}
                                disabled={
                                  (this.state.restriction < index &&
                                  this.state.restriction < 5
                                    ? true
                                    : false) ||
                                  (this.state.procedure_stage_edit
                                    ? surgical_tree.id == this.state.stage.id
                                      ? false
                                      : true
                                    : false)
                                }
                              >
                                {/* <span className="icon">
                                  <img
                                    src={surgical_tree.icon.replace(
                                      'public',
                                      ''
                                    )}
                                    className="h28 w28"
                                  />
                                </span> */}
                                {surgical_tree.name}
                              </TabNavigation>
                            )
                          }
                        )}
                        {this.state.custom_stages.map(
                          (surgical_tree, index) => {
                            return (
                              <TabNavigation
                                target="my-profile"
                                key={`surgical-main-${index}`}
                                id={`custom_stages-${index}`}
                                active={this.checkTabActive(
                                  this.state.stage &&
                                    this.state.stage.stage &&
                                    surgical_tree.stage.id ==
                                      this.state.stage.stage.id,
                                  `custom_stages-${index}`
                                )}
                                onClick={e => {
                                  // this.tabsRef.scrollOnclick()
                                  if ($(e.target).hasClass('disabled')) {
                                    $('#unlockModal').modal('show')
                                    return
                                  }
                                  $(e.target)
                                    .closest(`.scrollable-tabs`)
                                    .animate(
                                      {
                                        scrollLeft: `${e.target.offsetLeft -
                                          55}px`,
                                      },
                                      150
                                    )
                                  this.setState(
                                    {
                                      stage: surgical_tree,
                                      name: surgical_tree.stage.name,
                                      enableDraft: false,
                                      settings: false,
                                    },
                                    () => {
                                      this.getProcedureDetail(0, 'custom', true)

                                      $('#specialty-card').removeClass('active')
                                      $(`#specialty-card-${index}`).addClass(
                                        'active'
                                      )
                                    }
                                  )
                                }}
                                disabled={
                                  (this.state.restriction >= 5
                                    ? false
                                    : true) ||
                                  (this.state.procedure_stage_edit
                                    ? surgical_tree.id == this.state.stage.id
                                      ? false
                                      : true
                                    : false)
                                }
                              >
                                {/* <span className="icon">
                                    <img
                                      src={
                                        surgical_tree.stage.icon
                                          ? surgical_tree.stage.icon.replace(
                                              'public',
                                              ''
                                            )
                                          : ''
                                      }
                                      className="h28 w28"
                                    />
                                  </span> */}
                                {surgical_tree.stage.name}
                              </TabNavigation>
                            )
                          }
                        )}

                        {this.state.surgical_tree_second.map(
                          (surgical_tree, index) => {
                            return (
                              <TabNavigation
                                target="my-profile"
                                key={`surgical-main-${index}`}
                                id={`surgical_tree_second-${index}`}
                                active={this.checkTabActive(
                                  surgical_tree.id == this.state.stage.id,
                                  `surgical_tree_second-${index}`
                                )}
                                onClick={e => {
                                  // this.tabsRef.scrollOnclick()
                                  if ($(e.target).hasClass('disabled')) {
                                    $('#unlockModal').modal('show')
                                    return
                                  }
                                  $(e.target)
                                    .closest(`.scrollable-tabs`)
                                    .animate(
                                      {
                                        scrollLeft: `${e.target.offsetLeft -
                                          55}px`,
                                      },
                                      150
                                    )
                                  this.setState(
                                    {
                                      stage: surgical_tree,
                                      settings: false,
                                      enableDraft: false,
                                      name: surgical_tree.name,
                                    },
                                    () => {
                                      this.getProcedureDetail(0, 'main', true)
                                    }
                                  )
                                }}
                                disabled={
                                  (this.state.restriction >= 5
                                    ? false
                                    : true) ||
                                  (this.state.procedure_stage_edit
                                    ? surgical_tree.id == this.state.stage.id
                                      ? false
                                      : true
                                    : false)
                                }
                              >
                                {/* <span className="icon">
                                  <img
                                    src={surgical_tree.icon.replace(
                                      'public',
                                      ''
                                    )}
                                    className="h28 w28"
                                  />
                                </span> */}
                                {surgical_tree.name}
                              </TabNavigation>
                            )
                          }
                        )}
                      </TabHead>
                    )
                  }}
                  contents={() => {
                    return (
                      <TabBody className="col-md-12">
                        <TabContent
                          idTab="my-profile"
                          active={true}
                        ></TabContent>
                      </TabBody>
                    )
                  }}
                />
              </div>

              <div className="col-md-2 mt-8 mt-3 mt-md-0">
                <div
                  className="list-item add-new-secion"
                  data-toggle="modal"
                  data-target="#addStageModalProc"
                  onClick={() => {
                    this.setState({
                      icon: '',
                      stage_name: '',
                      stage_type: 'standard',
                    })
                  }}
                >
                  + Add new stage
                </div>
              </div>
            </div>
            {this.dataLayout()}

            {this.state.settings ? (
              <ProcedureSettings
                surgical_tree_first={this.state.surgical_tree_first}
                surgical_tree_second={this.state.surgical_tree_second}
                getSettings={this.getSettings}
                getRestriction={this.getRestriction}
                custom_stages={this.state.custom_stages}
                loadSurgeonProcedure={this.loadSurgeonProcedure}
                procedure={this.state.procedure}
                procedure_id={this.props.params ? this.props.params.id : ''}
                surgeon={this.props.surgeon}
                getCustomStages={this.getCustomStages}
                onSortEnd={this.onSortEnd}
                saveSorting={this.saveSorting}
                notifications={this.state.notifications}
                notifications_meta={this.state.notifications_meta}
                deleteProcedure={this.deleteProcedure}
                changeTabsKey={this.changeTabsKey.bind(this)}
              />
            ) : null}
            {/* <WholeBodyloader progress={this.state.loading} show={this.state.loading}/> */}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    surgeon: state.surgeon.surgeon,
    loading: state.loading,
  }
}

export default connect(mapStateToProps, { getSurgeon })(Procedure)
