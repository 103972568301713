import axios from 'axios';
import $ from 'jquery';
import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Link } from 'react-router';
import tippy from 'tippy.js';
import ViewItemCard from './commons/cards/ViewItemCard';
import { Pills } from './commons/misc';

class Footer extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
             selectedItem: null,
            unique: this.makeid(15)
        }
    }
    

    componentDidMount() { 
        $('body').remove('light-theme')
        $('body').remove('dark-theme')

        const active_theme = window.localStorage.getItem('darkmode') == 'true' ? 'dark-theme':'light-theme';
        $(document).on('hidden.bs.modal', '.modal', function () {
            if ($('.modal.show').length) {
                $('body').addClass('modal-open');
            } 
        });
/*         $(document).on('focusin', '.line-form-group input', (e) => { 
            $(e.currentTarget).closest('.line-form-group').addClass('active')
        }) */
        $(document).on('blur', '.line-form-group input', (e) => {
            if (!$(e.currentTarget).val()){
                $(e.currentTarget).closest('.line-form-group').removeClass('active')
            }else{
                $(e.currentTarget).closest('.line-form-group').addClass('active')
            }
        })

        $(document).on('focusout', '.line-form-group-v2 input', (e) => {
            if (!$(e.currentTarget).val()) {
                $(e.currentTarget).removeClass('has-val')
            } else {
                $(e.currentTarget).addClass('has-val')
            }
        })
        $(document).on('keyup', '.line-form-group-v2 input', (e) => { 
            if (!$(e.currentTarget).val()) {
                $(e.currentTarget).removeClass('has-val')
            } else {
                $(e.currentTarget).addClass('has-val')
            }
        })

        $(document).on('focusout', '.line-form-group-v2 select', (e) => {
            if (!$(e.currentTarget).val()) {
                $(e.currentTarget).removeClass('has-val')
            } else {
                $(e.currentTarget).addClass('has-val')
            }
        })
        

        $('textarea').autoResize();
        $('textarea').change()
        $('input').keyup()

        $('body').addClass(`${active_theme}`);
 
        console.log("window.localStorage.getItem('darkmode')",window.localStorage.getItem('darkmode'))

        $(document).on('mouseenter', '.tippy-hover', (e) => { 
            this.createTippy(e.target)
            console.log('mouseover', e.target.dataset.id)
        })

        $(document).on('click', `.tippy-hover`, (e) => {
            e.preventDefault()
            if($(e.target).hasClass("had-item")){
                $(`#preview-footer-item-${this.state.unique}`).modal('show')
            }
        })

    }
 
    popOverItem(item) {
        let imgContent = '', usedForContent = '', tagsContent = '';
        if (item.icon) {
            imgContent = `<div class="row item-img ">
                            <div class="col-md-12">
                                <div class="ar32">
                                <img src="${item.icon}" class="bg-white" />
                                </div>
                            </div>
                        </div>`;
        }
        if (item.used_for) {
            usedForContent = `<div class="row">
                                <div class="col-12">
                                    <h3 class="title">Used for</h3>
                                    <div class="description">
                                    <p>
                                        ${item.used_for}
                                    </p> 
                                    </div>
                                </div>
                                </div>`;
        }
        if (item.tags) {
            /* let tags = item.tags.map(tag => {
              return (<Pills className="tags-pill">{tag.name}</Pills>)
            }) */

            let tags = ReactDOMServer.renderToStaticMarkup(
                item.tags.map(tag => {
                    if (tag.name.length) {
                        return (<Pills className="tags-pill">{tag.name}</Pills>)
                    }
                    return null;
                })
            )
            tagsContent = `<div class="row"> 
                                <div class="col-12"> 
                                  <h4 class="sub-title">Tags</h4>
                                </div>
                                <div class="col-12 tags"> 
                                    ${tags}
                                </div>
                            </div>`;
        }

        return `<div class="popover-item-card" style="width: 300px">
              ${imgContent}
              ${usedForContent}
              ${tagsContent}
            </div>`;
    }

    createTippy(target) {
        if (!$(target).hasClass('had-item')) {
            axios
                .get(`api/items/${target.dataset.id}`)
                .then(result => {
                    console.log('resultresult', result.data.data)
                    this.setState({
                        selectedItem: result.data.data,
                        unique: this.makeid(15)
                    })
                    tippy(target, {
                        trigger: 'mouseenter focus',
                        placement: 'right',
                        arrow: true,
                        boundary: "window",
                        duration: [100, 100],
                        distance: 20,
                        theme: '',
                        content: this.popOverItem(result.data.data),
                    })
                    setTimeout(() => {
                        $(target).addClass('had-item')
                        $(target).focus()
                    }, 100);
                })
        }
         
    }

    makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
    render() {
        
        return (
            <>

                <ViewItemCard id={`preview-footer-item-${this.state.unique}`} onRef={ref => (this.viewItemRef = ref)} item={this.state.selectedItem} ></ViewItemCard>
                <nav class="navbar navbar-expand-lg navbar-light zindex-1k corh-foot">
                    <div class="container-xl">
                        {/* <a class="navbar-brand" href="#">CORH</a> */}
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#corhnavbar-footer" aria-controls="corhnavbar-footer" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                        </button>

                        <div class="collapse navbar-collapse" id="corhnavbar-footer">
                            <ul class="navbar-nav mr-auto">
                                <li class="nav-item">
                                    <Link className="nav-link text-white mx20" to="/privacy-policy">Our Privacy policy</Link>
                                </li>
                                <li class="nav-item">
                                    <Link className="nav-link text-white mx20" to="/terms-of-use">Terms of use</Link>
                                </li>
                                <li class="nav-item">
                                    <Link className="nav-link text-white mx20" to="/cookie-policy">Cookie policy</Link>
                                </li>
                                <li class="nav-item">
                                    <Link className="nav-link text-white mx20" to="/manual"> User Manual </Link>
                                </li>                                
                            </ul> 
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <a className="nav-link mx20" href="https://www.corhsystem.com">  by GlobalOR</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    </nav>
            </>
        );
    }
}




export default Footer;