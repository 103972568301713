import React, { Component } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import _ from 'lodash'
import $ from 'jquery'

export class ProcedureSkeleton extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      counter: _.range(_.random(1, 3)),
      color: '#d2d2d2',
      highlightColor: '#dedede'
    }
  }
  componentWillMount() {
    if ($('body').hasClass('dark-theme')) {
      this.setState({
        color: '#1d212e',
        highlightColor: '#151821'
      })
    }
  }
  
  titleElement = () => {
    return (
      <Skeleton width={'30%'} height={30} />
    )
  }

  textElement = () => {
    return (
    <div className={'card text-preview w-100 mb20 skeletoncard'}>
        <div className="card-header text-capitalize pb5 ">
          <Skeleton width={'50%'} height={30} />
        </div>
      </div>
    )
  }

  render() {
    return (
      <SkeletonTheme color={this.state.color} highlightColor={this.state.highlightColor}> 
        <div className="col-md-12 d-none d-md-block mb24">
          <Skeleton width={'30%'} height={34} />
        </div>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-10">
              <Skeleton height={32} /> 
            </div>
            <div className="col-md-2">
              <Skeleton height={32} /> 
            </div>
          </div>
        </div>
      </SkeletonTheme> 
    )
  }

}

export default ProcedureSkeleton
